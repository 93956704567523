//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";
import TermsAndConditionsDialog from './TermsAndConditionsDialog.web'

export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  email: any,
  password: any,
  token: any,
  visibilityPassword: boolean
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: String;
  password: String;
  unique_auth_id?: String;
};
export default class SignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  signUpApiCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      email: "",
      password: "",
      token: "",
      visibilityPassword: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.signUpApiCallId) {
        this.props.hideLoader();
        if (this.props.dataToPass.role === 'Admin') {
          if (responseJson && responseJson?.account?.meta?.token) {
            this.props.onSubmit('signUpSuccessFull')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else {
          if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
            this.props.onSubmit('signUpSuccessFull')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      // this.props.showAlert({
      //   title: "Alert",
      //   message: AlertBodyMessage,
      // });
    }
  }

  handleSignUp = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApiCallId = requestMessage.messageId;
    const httpBody = this.props.dataToPass.role === 'Admin' ? {
      data: {
        full_name: values.first_name + " " + values.last_name,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        confirm_password: values.password
      },
    } : {
      data: {
        "type": "email_account",
        "attributes": {
          "full_name": values.first_name + " " + values.last_name,
          "first_name": values.first_name,
          "last_name": values.last_name,
          "email": values.email,
          "password": values.password,
          "confirm_password": values.confirmPassword,
          // "activated":"true"
        }
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props.dataToPass.role === 'Admin' ? `${configJSON.adminSignupUrl}` : `${configJSON.userSignUpEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openTermsAndConditions = (values: any) => {

    this.props
      .openDialogBox({
        title: "Flight Policy",
        dataToPass: { values: values },
        renderedComponent: TermsAndConditionsDialog,
        withCustomDialog: true,
        catchOnCancel: true,
        scroll: "paper"
      })
  };
}
