//@ts-nocheck
import React from "react";
import myBookedHotelDetailController, {
  Props,
} from "./myBookedHotelDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
  Avatar,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import InputFiled from "../../../components/src/InputFiled.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import PhoneIcon from "@material-ui/icons/Phone";
import { hotelImage } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import MyBookings from "./myBookings.web";
import { NavLink } from "react-router-dom";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      Crafted with love.
    </Typography>
  );
}
const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
})(Rating);
class AdminDashboard extends myBookedHotelDetailController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          token: authToken,
        },
        () => {
          // this.getBoatListing();
        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push("yourroute");
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url('https://source.unsplash.com/random')`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={"https://source.unsplash.com/random"}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <AppBar
            position="relative"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Grid container spacing={5}>
                <Grid xs={2} md={3} item>
                  <Typography align="center" className={classes.logo}>
                    YTravels
                  </Typography>
                </Grid>

                <Grid xs={12} md={6} item>
                  <Grid container justify="space-between">
                    <Grid item xs={2}>
                      <Typography align="center" className={classes.link}>
                        Home
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="center" className={classes.link}>
                        Flights
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="center" className={classes.link}>
                        Hotels
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="center" className={classes.link}>
                        My Trips
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid md={3} xs={12} item>
                  <Box px={4} alignSelf="center" textAlign="center">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#fff",
                        color: "blue",
                        borderRadius: "50px",
                        fontFamily: "OpenSans",
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        color: "#1565d8",
                      }}
                    >
                      My Account
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.hotelName}
                  align="center"
                  gutterBottom
                >
                  Marriott Hotel Downtown
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <StyledRating name="read-only" value={4} readOnly />
                </div>
                <Typography
                  className={classes.hotelAddress}
                  align="center"
                  gutterBottom
                >
                  Palm Jumeriah, Dubai
                </Typography>
                <Typography
                  className={classes.travellerName}
                  align="center"
                  gutterBottom
                >
                  Subagency Thomas Cook - Kathmandu Booking #123123211
                </Typography>
                <Typography
                  className={classes.pnrValue}
                  align="center"
                  gutterBottom
                >
                  PNR: XSSLDS
                </Typography>
                <div style={{ textAlign: "center", margin: "24px auto" }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#fff",
                      color: "blue",
                      borderRadius: "50px",
                      fontFamily: "OpenSans",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      letterSpacing: "normal",
                      color: "#1565d8",
                      marginRight: "24px",
                    }}
                  >
                    Call Hotel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#fff",
                      color: "blue",
                      borderRadius: "50px",
                      fontFamily: "OpenSans",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      letterSpacing: "normal",
                      color: "#1565d8",
                    }}
                  >
                    Get Help
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="center"
          style={{ marginTop: "24px" }}
        >
          <Grid md={10} xs={12} item>
            <Grid
              container
              className={classes.hotelDetailContainer}
              spacing={2}
              justify="space-around"
            >
              <Grid item md={5} xs={5}>
                <Grid container spacing={4} justify="space-between">
                  <Grid item xs={12} className={classes.hotelMainImage} />
                  <Grid xs={3} item className={classes.hoteloptionalImage} />
                  <Grid xs={3} item className={classes.hoteloptionalImage} />
                  <Grid xs={3} item className={classes.hoteloptionalImage} />
                </Grid>
              </Grid>
              <Grid item md={5} xs={5}>
                <Grid container spacing={2} justify="space-around">
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Grid item xs={6}>
                        <Typography
                          className={classes.date}
                          color="textPrimary"
                          align="left"
                        >
                          {" "}
                          Fri, 12 Feb{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.date}
                          color="textPrimary"
                          align="right"
                        >
                          {" "}
                          Fri, 12 Feb{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify="center">
                          <Grid item xs={6}>
                            <div className={classes.linewraper}>
                              <Typography className={classes.lineHeading}>
                                2 Nights
                              </Typography>
                              <ul className={classes.container}>
                                <li className={classes.linkconnector} />
                                <li className={classes.linkconnector} />
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          className={classes.checkIn}
                          color="textPrimary"
                          align="left"
                        >
                          {" "}
                          Check-In{" "}
                        </Typography>
                        <Typography
                          className={classes.time}
                          color="textSecondary"
                          align="left"
                        >
                          {" "}
                          09:00 AM{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.checkIn}
                          color="textPrimary"
                          align="right"
                        >
                          {" "}
                          Check-Out{" "}
                        </Typography>
                        <Typography
                          className={classes.time}
                          color="textSecondary"
                          align="right"
                        >
                          {" "}
                          09:00 AM{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="left"
                      color="textPrimary"
                      className={classes.travellName}
                      gutterBottom
                    >
                      {" "}
                      Sandard Room Only For 2 Adults
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.bookingDetail}
                      align="left"
                    >
                      — Breakfast Available, Pay At Propert
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className={classes.bookingDetail}
                      align="left"
                    >
                      — This booking is non refundable
                    </Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <ListItem>
                      <ListItemText
                        primary="Rs. 4000"
                        secondary="Cost to you: Rs. 3500"
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid xs={12}> */}
              <Grid
                container
                style={{ marginTop: "16px" }}
                spacing={1}
                justify={"center"}
              >
                <Grid item xs={12} md={6}>
                  <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary">
                      Modify Or Cancel
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary">
                      Get Invoice
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary">
                      Share Booking
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Grid>
          <Grid md={10} xs={12} item>
            <Grid
              container
              spacing={2}
              className={classes.hotelDetailContainer}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography>Travellers (1)</Typography>
              </Grid>
              <Grid md={12} xs={12} item>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12}>
                <Typography>Travellers (1)</Typography>
                <Grid container justify={"space-between"} spacing={2}>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={7} xs={12} item>
            <Grid
              container
              spacing={2}
              className={classes.hotelDetailContainer}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography>About Hotel</Typography>
              </Grid>
              <Grid md={12} xs={12} item>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Sunt autem quidam e nostris, qui studiose antiqua persequeris,
                  claris et fortibus viris. Quae fuerit causa, mox videro;
                  interea hoc tenebo, si ob rem voluptas sit. Sed ut de homine
                  sensibus reliqui nihil ut alterum aspernandum sentiamus alii
                  autem.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={3} xs={12} item>
            <Grid
              container
              spacing={2}
              className={classes.hotelDetailContainer}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography>Travellers (1)</Typography>
              </Grid>
              <Grid md={12} xs={12} item>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12}>
                <Typography>Travellers (1)</Typography>
                <Grid container justify={"space-between"} spacing={2}>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={10} xs={12} item>
            <Grid
              container
              spacing={2}
              className={classes.hotelDetailContainer}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography>Travellers (1)</Typography>
              </Grid>
              <Grid md={12} xs={12} item>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12}>
                <Typography>Travellers (1)</Typography>
                <Grid container justify={"space-between"} spacing={2}>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem>
                      <ListItemText primary="Name" secondary="Amit" />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid md={7} xs={12} item>
            <Grid
              container
              spacing={2}
              className={classes.hotelDetailContainer}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography>About Hotel</Typography>
              </Grid>
              <Grid md={12} xs={12} item>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Sunt autem quidam e nostris, qui studiose antiqua persequeris,
                  claris et fortibus viris. Quae fuerit causa, mox videro;
                  interea hoc tenebo, si ob rem voluptas sit. Sed ut de homine
                  sensibus reliqui nihil ut alterum aspernandum sentiamus alii
                  autem.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={3} xs={12} item>
          <Grid
              container
              spacing={2}
              className={classes.hotelDetailContainer}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Typography>Fare Summary</Typography>
              </Grid>
              <Grid md={12} xs={12} item>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify={"space-between"} spacing={2}>
                  <Grid item xs={12}>
                    <List>
                      <ListItem dense={true}>
                        <ListItemText
                          primary="Adult(s) (1 X Rs 3,943)"
                          secondary="Base Fare"
                        />
                        <ListItemSecondaryAction>
                          INR 4,999
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem dense={true}>
                        <ListItemText
                          primary="Fee & Surcharges"
                          secondary="Total fee & surcharges"
                        />
                        <ListItemSecondaryAction>
                          INR 4,999
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="middle" />
                      <ListItem dense={true}>
                        <ListItemText primary="Total Paid" />
                        <ListItemSecondaryAction>
                          INR 8,999
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
           
          </Grid>
        </Grid>

        <footer className={classes.footer}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={3} container justify="center">
              <Grid item xs={6}>
                aaaaaaa
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  Products
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Landing Page
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Features
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Documentation
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Pricing
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  Services
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Landing Page
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Features
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Documentation
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Pricing
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  Company
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  About
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Term And Conditions
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Privacy Policy
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Carrers
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              container
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  Company
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  About
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Term And Conditions
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Privacy Policy
                </Typography>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                >
                  Carrers
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Container maxWidth="lg">
            <Copyright />
          </Container>
        </footer>
      </div>
    );
  }
}
const AdminDashboardControllerWithRouter = withRouter(AdminDashboard);
const AdminDashboardControllerAlertBox = withAlertBox(
  AdminDashboardControllerWithRouter
);
const AdminDashboardControllerLoader = withLoader(
  AdminDashboardControllerAlertBox
);
const AdminDashboardControllerToast = withToast(AdminDashboardControllerLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: "url(https://source.unsplash.com/random)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "350px",
      [theme.breakpoints.down("xs")]: {
        height: "450px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    appBar: {
      background: "transparent",
    },
    link: {
      fontFamily: "HKGrotesk",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    cardGrid: {
      padding: theme.spacing(8),
      // paddingBottom: theme.spacing(8),
    },
    card: {
      display: "flex",
      flexDirection: "column",
    },
    cardMedia: {
      paddingTop: "100%", // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    hotelName: {
      fontFamily: "HKGrotesk",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    hotelAddress: {
      fontFamily: "OpenSans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    travellerName: {
      fontFamily: "HKGrotesk",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    pnrValue: {
      fontFamily: "HKGrotesk",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(25),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(15),
      },
      margin: "16px",
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    hoteloptionalImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(12),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(6),
      },
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    hotelNameHeadingContainer: {
      flexWrap: "wrap",
    },
    hotelNameTitle: {
      flexGrow: 1,
    },
    footerLinkHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    hotelName: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    hotelAddress: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    date: {
      fontFamily: "OpenSans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "HKGrotes",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "OpenSans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "HKGrotesk",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "HKGrotesk",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    bookedByAdmin: {
      fontFamily: "HKGrotesk",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: "OpenSans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: "OpenSans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "HKGrotesk",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    logo: {
      fontFamily: "HKGrotesk",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    lineHeading: {
      fontFamily: "HKGrotesk",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "HKGrotesk",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
  })
)(AdminDashboardControllerToast);
