import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { notification } from "./assets";
import NotificationPopUpWeb from "./NotificationPopUp.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  selectedType: String;
  token: string;
  bookingList: Array<any>;
  selectedTime: string;
  recordsPerPage: number;
  pageCount: number;
  bookingFlightsTotalCount: number;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CancelledBookingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  allBookingEndPointCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      selectedType: "Flights",
      token: "",
      bookingList: [],
      selectedTime: "cancelled",
      recordsPerPage: 10,
      pageCount: 0,
      bookingFlightsTotalCount: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.allBookingEndPointCallId) {
        this.props.hideLoader();
        if (
          responseJson &&
          responseJson?.data && responseJson?.data.length > 0
        ) {
          const bookings = [];
          for (let i = 0; i < responseJson.data.length; i++) {
            const booking = responseJson.data[i];
            if (booking?.data?.attributes?.air_segment) {
              const flightList = [...booking?.data?.attributes?.air_segment];
              const flightPolicyDetails = booking?.data?.attributes?.flight_details_hash ? [...booking?.data?.attributes?.flight_details_hash] : [];
              const flights = this.processData1(flightList, flightPolicyDetails);
              const oneWayFlightList = flights?.onewayflights;
              const oneWayStops = flights?.onewayflights.length > 0 ? this.stopCalculation(flights?.onewayflights) : [];
              const twoWayFlightList = flights?.twoWayFlights;
              const twoWayStops = flights?.twoWayFlights.length > 0 ? this.stopCalculation(flights?.twoWayFlights) : [];

              // let onewayflightDetail = {}
              // let twowayflightDetail = {}
              // const flightListnew: any = []
              // if (oneWayFlightList.length > 0) {
              //   onewayflightDetail = this.processData(oneWayFlightList);
              //   flightListnew.push(onewayflightDetail);
              // }
              // if (twoWayFlightList.length > 0) {
              //   twowayflightDetail = this.processData(twoWayFlightList);
              //   flightListnew.push(twowayflightDetail);
              // }
              bookings.push({ ...booking?.data?.attributes, flights: { oneWayFlightList, oneWayStops, twoWayFlightList, twoWayStops } })
            }
          }
          this.setState({ bookingList: bookings, bookingFlightsTotalCount: responseJson?.meta?.pagination?.total_count })
        } else {
          await this.setState({ bookingList: [] })
          // this.parseApiErrorResponse(responseJson)
          this.props.openDialogBox({
            dataToPass: { title: "Alert!", message: responseJson?.message, image: notification },
            renderedComponent: NotificationPopUpWeb,
            title: "Alert",
            withCustomDialog: true,
            width: '30%'
          })
        }
      }

    }
  }
  getBookings(filterData: any) {
    this.props.showLoader();
    let url = "";
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );;
    requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allBookingEndPointCallId = requestMessage.messageId;
    let type = 'all';
    if (this.state.selectedType === 'Flights') {
      type = 'flight'
    } else if (this.state.selectedType === 'Hotels') {
      type = 'hotel'
    }
    url = `${configJSON.allBookingEndPoint}?search=${this.state.selectedTime}&type=${type}&page=${this.state?.pageCount}&per=${this.state?.recordsPerPage}&pagination=true&new_api=true`

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage?.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage?.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage?.id || "1", requestMessage);

  }
  // processData = (flights: any, a: any = {}) => {
  //   const flightnew = { ...a };
  //   let flightIDs = flights.map((flight: any) => { return flight.flight_number });
  //   flightIDs = Array.from(new Set(flightIDs)).join(",")
  //   flightnew.flight_number = flightIDs;
  //   flightnew.flight_carrier = Array.from(new Set(flights.map((flight: any) => { return flight.flight_carrier }))).join(",");
  //   flightnew.flight_logo = Array.from(new Set(flights.map((flight: any) => { return flight.flight_logo }))).join(",");
  //   flightnew.flight_name = Array.from(new Set(flights.map((flight: any) => { return flight.flight_name }))).join(",");
  //   const departueFlight = this.getFlightDepartureInfo(flights);
  //   flightnew.boarding_terminal = departueFlight?.boarding_terminal
  //   flightnew.depature_city = departueFlight?.depature_city
  //   flightnew.depature_date = departueFlight?.depature_date
  //   flightnew.depature_datetime = departueFlight?.depature_datetime
  //   flightnew.depature_iata = departueFlight?.depature_iata
  //   flightnew.depature_time = departueFlight?.depature_time;

  //   const returnFlight = this.getFlightRetrunInfo(flights);
  //   flightnew.arriving_terminal = returnFlight?.arriving_terminal
  //   flightnew.arrival_city = returnFlight?.arrival_city
  //   flightnew.arrival_date = returnFlight?.arrival_date
  //   flightnew.arrival_datetime = returnFlight?.arrival_datetime
  //   flightnew.arrival_iata = returnFlight?.arrival_iata
  //   flightnew.arrival_iata = returnFlight?.arrival_iata;

  //   if (flights.length > 1) {
  //     flightnew.stops = this.stopCalculation(flights);
  //   } else {
  //     flightnew.stops = []
  //   }
  //   return (flightnew);
  // }
  // getFlightDepartureInfo = (airsagment: any) => {
  //   const flight = { ...airsagment[0] };
  //   return flight;
  // }
  // getFlightRetrunInfo = (airsagment: any) => {
  //   const flight = { ...airsagment[airsagment.length - 1] };
  //   return flight;
  // }
  processData1 = (flights: any, flightPolicy: any) => {
    const processFlightSearchData = [];
    for (let i = 0; i < flights.length; i++) {
      const flight = { ...flights[i] };
      let airSagmentInfo = {};
      airSagmentInfo = flightPolicy.find((newflight: any) => { return flight.flight_number == newflight.flight_id })
      processFlightSearchData.push({ ...flight, ...airSagmentInfo });
    }
    return {
      onewayflights: processFlightSearchData.filter((flight: any) => { return flight?.flight_type === 'departure' && flight }),
      twoWayFlights: processFlightSearchData.filter((flight: any) => { return flight?.flight_type === 'return' && flight })
    };
  }
  stopCalculation = (sagmentInfo: any) => {
    const stops = [];
    for (let i = 0; i < sagmentInfo.length - 1; i++) {
      const a = sagmentInfo[i];
      const b = sagmentInfo[i + 1];
      const stop = {
        stopName: '',
        stopDuration: '',
        stopType: '',
        flight_type: a.flight_type
      }
      stop.stopName = a.arrival_city;
      stop.stopDuration = this.timeDiff(b.depature_datetime, a.arrival_datetime)
      stop.stopType = "Change Of Plane"
      stops.push(stop);
    }
    return stops
  }
  timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m"
    } else {
      return Math.floor(diff.hours) + " h"
    }
  }
}
