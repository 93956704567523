//@ts-nocheck
import React from "react";
import ProfileDetailsController, { Props } from "./ProfileDetailsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import moment from 'moment';

class ProfileDetails extends ProfileDetailsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const profileData = await StorageProvider.get("profileData");
    if (profileData) {
      this.setState(
        {
          profileData: JSON.parse(profileData),
        }
      );
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;
    return <>
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={12}>
          <Grid container justify='center' alignItems='center' className={classes.userProfileBox}>
            <Grid item md={11}>
              <Typography className={classes.userProfileTitle}>
                My Profile
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.userProfileContainer}>
            <Grid xs={12} item>
              <ListItem>
                <ListItemText
                  primary={`${this.state?.profileData?.full_name || "Not Available"}`}
                  secondary={`${this.state?.profileData?.email || "Not Available"}`}
                  classes={{
                    primary: classes.agencyName,
                    secondary: classes.agencyEmail,
                  }}
                ></ListItemText>
              </ListItem>
            </Grid>
            <Grid xs={12} item>
              <Divider className={classes.dividerStyle}></Divider>
            </Grid>
            <Grid xs={12} item>
              <Grid container spacing={3} justify={"space-between"}>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Gender"
                      secondary={`${this.state?.profileData?.gender || "NA"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Date of Birth"
                      secondary={`${moment(this.state?.profileData?.dob).format("DD/MM/YYYY") || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Contact Details"
                      secondary={`${this.state?.profileData?.full_phone_number || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Address"
                      secondary={`${this.state?.profileData?.first_address || "NA"}, ${this.state?.profileData?.second_address || "NA"}, ${this.state?.profileData?.city || "NA"}, ${this.state?.profileData?.nationality || "NA"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="ZIP/PIN Code"
                      secondary={`${this.state?.profileData?.zip_pin || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Currency"
                      secondary={`${this.state?.profileData?.currency || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Passport Number"
                      secondary={`${this.state?.profileData?.passport_number || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Passport Expiry Date"
                      secondary={`${moment(this.state?.profileData?.passport_expiry).format("DD/MM/YYYY") || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                {!(this.state?.profileData?.passport_available) &&
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem>
                        <ListItemText
                          primary="Citizen Number"
                          secondary={`${this.state?.profileData?.citizenship_number || "Not Available"}`}
                          classes={{
                            primary: classes.primaryName,
                            secondary: classes.secondaryName,
                          }}
                        ></ListItemText>
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem>
                        <ListItemText
                          primary="Citizen Expiry Date"
                          secondary={`${moment(this.state?.profileData?.citizenship_issue_date).format("DD/MM/YYYY") || "Not Available"}`}
                          classes={{
                            primary: classes.primaryName,
                            secondary: classes.secondaryName,
                          }}
                        ></ListItemText>
                      </ListItem>
                    </Grid>
                  </>}
              </Grid>
            </Grid>
            <Grid xs={12} item className={classes.buttonGridItem}>
              <Grid container spacing={1} justify="flex-end" alignItems="center">
                <Grid item md={4} xs={5}>
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.changePasswordText}
                    fullWidth
                    onClick={() => { this.goToSelectedRoute("/myAccount/changePassword") }}
                  >
                    Change Password
                  </Button>
                </Grid>
                <Grid item md={4} xs={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => this.goToSelectedRoute("/myAccount/settings")}
                  >
                    Edit Profile
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>


        {/* <Grid item md={12}>
          <Grid container className={classes.card} spacing={2} justify="space-around">
            <Grid item md={3} xs={12}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <Typography variant="h2" gutterBottom>
                    {this.props.data?.full_name}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  {this.props.data?.email}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  {this.props.data?.full_phone_number}
                  </Typography>
                  <Divider variant="fullWidth" light />
                </Grid>
                <Grid item md={6}>
                  <Button variant="contained" onClick={()=>{this.goToSelectedRoute("/myAccount/settings")}} fullWidth color="primary">
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h3" gutterBottom>
                    Gender
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  {this.props.data?.gender}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h3" gutterBottom>
                    Date of Birth
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  {this.props.data?.dob}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h3" gutterBottom>
                    Address
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    {`${this.props.data?.first_address},${this.props.data?.city},${this.props.data?.nationality}`}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h3" gutterBottom>
                    Passport Expiry
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  {this.props.data?.passport_expiry}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h3" gutterBottom>
                    Passport Number
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  {this.props.data?.passport_number}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid >
    </>
  }
}

const ProfileDetailsWithRouter = withRouter(ProfileDetails);
const ProfileDetailsAlertBox = withAlertBox(ProfileDetailsWithRouter);
const ProfileDetailsLoader = withLoader(ProfileDetailsAlertBox);
const ProfileDetailsToast = withToast(ProfileDetailsLoader);
const ProfileDetailsWithDialog = withDialog(ProfileDetailsToast);

export default withStyles((theme) =>
  createStyles({
    userProfileBox: {
      padding: "20px 0px",
      marginBottom: "20px",
      borderRadius: "5px",
      backgroundColor: "#EBEBEB",
    },
    userProfileTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    formHeadingName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    agencyName: {
      fontSize: "26px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "0.27px",
    },
    agencyEmail: {
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#91A3B6",
    },
    userProfileContainer: {
      borderRadius: "5px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: "#ffffff",
      padding: "30px",
      // padding: '38px 39px 20px 44px',
      marginBottom: "50px",
    },
    primaryName: {
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#1E394E",
    },
    secondaryName: {
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#91A3B6",
    },
    buttonGridItem: {
      paddingTop: "20px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9",
    },
    changePasswordText: {
      fontSize: "16px",
      fontWeight: 600,
    }
  })
)(ProfileDetailsWithDialog);
