import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : string;
  allPendIngInvites : Array<any>,
  allAcceptedInvites : Array<any>,
  allRejectedInvites : Array<any>,
  selectedTab : string;
  pendingCount : number,
  pendingrecordsPerPage : number
  totalPendingRequest : number
  totalActiveRequest : number
  activeCount : number
  activerecordsPerPage : number
  totalRejectedRequest : number,
  rejectedCount : number,
  rejectedrecordsPerPage : number,
  searchText : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AllSubAgenciesController extends BlockComponent<
  Props,
  S,
  SS
> {
  allpendingRequestCallId: String = "";
  allAcceptedRequestCallId : String = "";
  allRejectedRequestCallId : String = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      allPendIngInvites : [],
      allAcceptedInvites : [],
      allRejectedInvites : [],
      selectedTab : 'Pending',
      pendingCount : 0,
      pendingrecordsPerPage : 25,
      totalPendingRequest : 0,
      totalActiveRequest : 0,
      activeCount : 0,
      activerecordsPerPage : 25,
      totalRejectedRequest : 0,
      rejectedCount : 0,
      rejectedrecordsPerPage : 25,
      searchText : ""

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.allpendingRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.agencies && responseJson?.agencies?.data && responseJson?.agencies?.data.length > 0) {
          this.setState({
            allPendIngInvites : responseJson?.agencies?.data,
            totalPendingRequest : responseJson?.meta?.pagination?.total_count
          })
        } else {
          this.setState({
            allPendIngInvites : [],
            totalPendingRequest : 0
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.allAcceptedRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.agencies && responseJson?.agencies?.data && responseJson?.agencies?.data.length > 0) {
          this.setState({
            allAcceptedInvites : responseJson?.agencies?.data,
            totalActiveRequest : responseJson?.meta?.pagination?.total_count
          })
        } else {
          this.setState({
            allAcceptedInvites : [],
            totalActiveRequest : 0
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.allRejectedRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.agencies && responseJson?.agencies?.data && responseJson?.agencies?.data.length > 0) {
          this.setState({
            allRejectedInvites : responseJson?.agencies?.data,
            totalRejectedRequest : responseJson?.meta?.pagination?.total_count
          })
        } else {
          this.setState({
            allRejectedInvites : [],
            totalRejectedRequest : 0
          })
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }
  geAllPendingRequest() {
    this.props.showLoader();
    let url = "";
    url = `bx_block_dashboard/get_agencies?data[category]=pending&token=${this.state.authToken}&per=${this.state.pendingrecordsPerPage}&page=${this.state.pendingCount+1}`
    if(this.state?.searchText){
      url += `&search=${this.state?.searchText}`
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allpendingRequestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  geAllAcceptedRequest() {
    this.props.showLoader();
    let url = "";
      url = `bx_block_dashboard/get_agencies?data[category]=accepted&per=${this.state.activerecordsPerPage}&page=${this.state.activeCount+1}`
      if(this.state?.searchText){
        url += `&search=${this.state?.searchText}`
      }
      const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allAcceptedRequestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  geAllRejectedRequest() {
    this.props.showLoader();
    let url = "";
      url = `bx_block_dashboard/get_agencies?data[category]=rejected&per=${this.state.rejectedrecordsPerPage}&page=${this.state.rejectedCount+1}`
      if(this.state?.searchText){
        url += `&search=${this.state?.searchText}`
      }
      const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allRejectedRequestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
