import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";
import NotificationPopUpWeb from "./NotificationPopUp.web";
import { notification } from "./assets";
import { dialogBoxProps } from "../../../components/src/withDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  email: any,
  password: any,
  token: any,
  visibilityPassword: boolean
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: String;
  password: String;
  unique_auth_id?: String;
};
export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  loginApiSocialEmailCallId: String = "";
  getProfileDetailApiCallID: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    this.state={
      email: "",
      password: "",
      token: "",
      visibilityPassword: false

    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiEmailCallId) {
        this.props.hideLoader();
        if (this.props.dataToPass.role === 'Agency') {
          if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
            const token = responseJson.meta.token;
            await StorageProvider.remove("authToken");
            await StorageProvider.remove("adminToken");
            await StorageProvider.set("agancyToken", token);
            await StorageProvider.set("role", responseJson?.role);
            await StorageProvider.set("permissions", JSON.stringify(responseJson?.permissions));
            this.props.onSubmit('loginSuccessFull')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else {
          if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
            const token = responseJson.meta.token;
            await StorageProvider.remove("agancyToken");
            await StorageProvider.remove("adminToken");
            await StorageProvider.set("authToken", token);
            this.getProfileDetail(token);
          } else {
            this.props.openDialogBox({
              dataToPass: { image: notification, title: "Alert", message:responseJson.message },
              renderedComponent: NotificationPopUpWeb,
              title: "Alert",
              withCustomDialog: true,
              width: '30%'
            })
            // this.parseApiErrorResponse(responseJson);
          }
        }
      } else if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.props.hideLoader();
        if (responseJson?.data) {
          await StorageProvider.set("profileData", JSON.stringify(responseJson?.data?.attributes));
          this.props.onSubmit('loginSuccessFull')
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if (msg === 'Token has Expired' || msg === 'Invalid token') {
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }
  getProfileDetail = (token: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDetailApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userEditProfile}?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLogin = (values: any) => {
    let url = ''
    let httpBody = {}
    if (this.props.dataToPass.role === 'Agency') {
      console.log('Agency Login')
      url = `${configJSON.agencyLogin}`
      httpBody = {
        data: {
          email: values.email,
          password: values.password,
          //confirm_password: values.password
        },
      }
    } else if (this.props.dataToPass.role === 'Admin') {
      url = `${configJSON.adminloginUrl}`
      httpBody = {
        data: {
          email: values.email,
          password: values.password,
          //confirm_password: values.password
        },
      }
    } else {
      console.log('user login')
      url = `${configJSON.userSignInEndpoint}`
      httpBody = {
        data: {
          "type": "email_account",
          "attributes": {
            "email": values.email,
            "password": values.password
          }
        },
      }
    }

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiEmailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
