//@ts-nocheck
import React from "react";
import MyProfileContainerController, {
  Props,
} from "./MyProfileContainerController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  ListItemText,
  Button,
  Paper,
  Divider,
  MenuItem,
  Container,
  Link,
  Hidden,
  Drawer,
  IconButton
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import * as Yup from "yup";
import Header from "./Header.web";
import Footer from "./Footer.web"
import { NavLink } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router";
import EditProfile from "./EditProfile.web";
import ProfileChangePassword from "./ProfileChangePassword.web";
import ProfileDetails from "./ProfileDetails.web";
import PrivateRoute from "../../../components/src/PrivateRoute.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import { agancyCover, userSuccess } from "./assets";
import withFontStyles from './withFontStyles.web'
import NotificationPopUpWeb from "./NotificationPopUp.web";

const drawerWidth = 240;
class MyProfileContainer extends MyProfileContainerController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const profileData = await StorageProvider.get("profileData");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          profileData: JSON.parse(profileData),
        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  openLogoutConfimrBox = (memberId) => {
    debugger;
    const onClose = async (result) => {
      if (result === 'Yes') {
        await StorageProvider.clearStorage();
        this.props.history.push("/")
        this.props.openDialogBox({
          dataToPass: { title: "Logged out Successful", message: 'You have been succesfully logged out in Doolwa.com', image: userSuccess },
          renderedComponent: NotificationPopUpWeb,
          withCustomDialog: true,
          width: "30%"
        })
      }
    }
    this.props.openConfirmDialog(
      'Logout', 'are you sure you want to logout', onClose
    )
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${agancyCover})`,
            backgroundSize: "cover",
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={agancyCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          {/* <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton> */}
          <Header role="Customer" />
          {/* <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              SlideProps={{ unmountOnExit: true }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              <Paper elevation={0} className={classes.sidebarAboutBox}>
                {[
                  { title: "My Profile", url: "/myAccount/profile" },
                  {
                    title: "Settings",
                    url: "/myAccount/settings",
                  },
                  {
                    title: "Change Password",
                    url: "/myAccount/changePassword",
                  }
                ].map((archive) => (
                  <NavLink
                    to={archive.url}
                    className={classes.sideLink}
                    activeClassName={classes.activeSideLink}
                    key={archive.title}
                  >
                    {archive.title}
                  </NavLink>
                ))}
                <Typography className={classes.sideLink} onClick={() => { this.openLogoutConfimrBox() }}>
                  Logout
                </Typography>
              </Paper>
            </Drawer>
          </Hidden> */}

          <Grid container justify="center" alignItems="center">
            <Grid item md={10}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  My Profile
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={9} xs={10}>
              <Grid
                container
                spacing={2}
                justify="space-between"
                alignItems='center'
                className={classes.mainPostSecondaryContent}
              >
                <Grid item xs={12} sm={9} md={9}>
                  <ListItem>
                    <ListItemText
                      primary={this.state?.profileData?.full_name || "NA"}
                      secondary={this.state?.profileData?.email || "NA"}
                      classes={{
                        primary: classes.userEmail,
                        secondary: classes.userName,
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={7} sm={3} md={3} style={{ alignSelf: "center", textAlign: "center" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      this.goToSelectedRoute('/userBookings')
                    }}
                  >
                    My Bookings
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="center"
          style={{ paddingTop: "120px", background: "#F4F4F4" }}
        >
          <Grid item md={10} xs={11}>
            <Grid container spacing={2} justify="space-around">
              {/* <Hidden xsDown> */}
              <Grid md={3} xs={10} item style={{ padding: "10px", marginBottom: "50px" }}>
                <Paper elevation={0} className={classes.sidebarAboutBox}>
                  {[
                    { title: "My Profile", url: "/myAccount/profile" },
                    {
                      title: "Settings",
                      url: "/myAccount/settings",
                    },
                    {
                      title: "Change Password",
                      url: "/myAccount/changePassword",
                    },
                  ].map((archive) => (
                    <NavLink
                      to={archive.url}
                      className={classes.sideLink}
                      activeClassName={classes.activeSideLink}
                      key={archive.title}
                    >
                      {archive.title}
                    </NavLink>
                  ))}
                  <Typography className={classes.sideLink} onClick={() => { this.openLogoutConfimrBox() }}>
                    Logout
                  </Typography>
                </Paper>
              </Grid>
              {/* </Hidden> */}

              <Grid item md={9} xs={11} style={{ padding: "10px" }}>
                <Switch>
                  <PrivateRoute path="/myAccount/profile" exact>
                    <ProfileDetails />
                  </PrivateRoute>
                  <PrivateRoute path="/myAccount/settings" exact>
                    <EditProfile />
                  </PrivateRoute>
                  <PrivateRoute path="/myAccount/changePassword" exact>
                    <ProfileChangePassword dataToPass={this.state.profileData} />
                  </PrivateRoute>
                  <PrivateRoute path="/myAccount/Logout" exact>
                    <EditProfile />
                  </PrivateRoute>
                  <Redirect exact from="/myAccount" to="/myAccount/profile" />
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer marginTop={0}></Footer>
      </div>
    );
  }
}

const MyProfileContainerWithRouter = withRouter(MyProfileContainer);
const MyProfileContainerAlertBox = withAlertBox(MyProfileContainerWithRouter);
const MyProfileContainerLoader = withLoader(MyProfileContainerAlertBox);
const MyProfileContainerToast = withToast(MyProfileContainerLoader);
const MyProfileContainerWithDialog = withDialog(MyProfileContainerToast);
const MyProfileContainerWithFontStyles = withFontStyles(MyProfileContainerWithDialog)
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "#EBEBEB",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      // marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // [theme.breakpoints.down("xs")]: {
      //   height: "440px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   height: "345px",
      // },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(2),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "220px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    sideLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      padding: theme.spacing(1, 1.5)
    },
    sideLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: 'left',
      display: 'block',
      textDecoration: 'none',
      padding: theme.spacing(1.5),
    },
    activeSideLink: {
      backgroundColor: "#fff",
      color: "#1565d8",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    footerLinkHeading: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      margin: theme.spacing(0, 1),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    userEmail: {
      fontFamily: "Open Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    userName: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#91A3B6",
    },
  })
)(withConfirmBox(MyProfileContainerWithDialog));
