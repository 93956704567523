//@ts-nocheck
import React from "react";
import HeaderController, { Props } from "./HeaderController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  Button,
  IconButton,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { withRouter } from "react-router";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {
  companylogo1
} from "./assets";

const drawerWidth = 240;
class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { pathname, search } = location;
    const { childRef } = this.props;
    if (childRef) {
      childRef(this);
    }

    if (pathname === "/verifyAccount") {
      const query = new URLSearchParams(search);
      const token = query.get("token");
      this.setState(
        {
          token: token,
        },
        () => {
          this.verifyAccount(token);
        }
      );
    } else if (pathname === "/resetPassword") {
      const query = new URLSearchParams(search);
      const token = query.get("token");
      if (token) {
        this.openResetPassword(token);
      }
    } else if (pathname === "/forgotPassword") {
      this.openForgotPassword();
    } else if (pathname === "/enterOtp") {
      this.openEnterOtp();
    } else if (pathname === "/AdminLogin") {
      this.openAdminLogin();
    }
    let tokenKeyName = ""
    if (this.props.role === 'Admin') {
      tokenKeyName = "adminToken"
    } else if (this.props.role === 'Agency') {
      tokenKeyName = "agancyToken"
    } else {
      tokenKeyName = "authToken"
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    if (authToken) {
      this.setState({
        authToken: authToken,
      });
    }
  }

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    if (data) {
      history.push({
        state: data,
        pathname: routeName,
      });
    } else {
      history.push({
        pathname: routeName,
      });
    }

  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="relative"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid xs={2} md={3} item>
                {/* <Typography
                  onClick={() => {
                    if (this.props.role === 'Admin') {
                      this.goToSelectedRoute("/AdminDashboard");
                    } else if (this.props.role === 'Agency') {
                      this.goToSelectedRoute("/AgancyDashborad");
                    } else {
                      this.goToSelectedRoute("/");
                    }
                  }} className={classes.logo}>DOOLWA.com</Typography> */}
                  
                          
                            <img
                              src={companylogo1}
                              style={{ widht: "20px", height: "60px" }}
                            />
                            
                       
              </Grid>
              <Grid xs={10} md={9} item>
                <Box textAlign="end">
                  <Grid container direction="row" alignItems="center" spacing={3} style={{ justifyContent: 'end' }}>
                    <Hidden smUp implementation="css">
                      <Drawer
                        variant="persistent"
                        anchor={"right"}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        SlideProps={{ unmountOnExit: true }}
                      >
                        <IconButton
                          onClick={this.handleDrawerToggle}
                          className={classes.closeMenuButton}
                        >
                          <CloseIcon />
                        </IconButton>
                        <List component="nav" aria-label="main mailbox folders">
                          <ListItem
                            button
                            divider
                          >
                            <ListItemText primary="Flights" onClick={() => {
                              if (this.props.role === 'Admin') {
                                this.props.history.push("/flightSearchResult", { role: 'Admin' });
                              } else if (this.props.role === 'Agency') {
                                this.props.history.push("/flightSearchResult", { role: 'Agency' });
                              } else {
                                this.props.history.push("/flightSearchResult", { role: 'Customer' });
                              }
                            }} classes={{ primary: classes.mobileLink }} />
                          </ListItem>
                          <ListItem
                            button
                            divider
                          >
                            <ListItemText primary="Hotels" onClick={() => {
                              if (this.props.role === 'Admin') {
                                this.props.history.push("/hotelSearchResult", { role: 'Admin' });
                              } else if (this.props.role === 'Agency') {
                                this.props.history.push("/hotelSearchResult", { role: 'Agency' });
                              } else {
                                this.props.history.push("/hotelSearchResult", { role: 'Customer' });
                              }
                            }} classes={{ primary: classes.mobileLink }} />
                          </ListItem>
                          {this.state.authToken && (<ListItem
                            button
                            divider
                          >
                            <ListItemText primary="My Trips" onClick={() => {
                              this.goToSelectedRoute("/userBookings/currentBookings");
                            }} classes={{ primary: classes.mobileLink }} />
                          </ListItem>)}
                        </List>
                      </Drawer>
                    </Hidden>
                    <Hidden xsDown>
                      <Grid item>
                        <Grid container spacing={5}>
                          <Grid item >
                            <Typography
                              align="center"
                              onClick={() => {
                                if (this.props.role === 'Admin') {
                                  this.props.history.push("/flightSearchResult", { role: 'Admin' });
                                } else if (this.props.role === 'Agency') {
                                  this.props.history.push("/flightSearchResult", { role: 'Agency' });
                                } else {
                                  this.props.history.push("/flightSearchResult", { role: 'Customer' });
                                }
                              }}
                              className={classes.link}
                            >
                              Flights
                            </Typography>
                          </Grid>
                          <Grid item >
                            <Typography
                              align="center"
                              onClick={() => {
                                if (this.props.role === 'Admin') {
                                  this.props.history.push("/hotelSearchResult", { role: 'Admin' });
                                } else if (this.props.role === 'Agency') {
                                  this.props.history.push("/hotelSearchResult", { role: 'Agency' });
                                } else {
                                  this.props.history.push("/hotelSearchResult", { role: 'Customer' });
                                }
                              }}
                              className={classes.link}
                            >
                              Hotels
                            </Typography>
                          </Grid>
                          <Grid item >
                            {this.state.authToken ? (
                              <Typography
                                align="center"
                                onClick={() => {
                                  if (this.props.role === 'Admin') {
                                    this.goToSelectedRoute("/AdminDashboard/myBooking");
                                  } else if (this.props.role === 'Agency') {
                                    this.goToSelectedRoute("/AgancyDashborad/myBooking");
                                  } else {
                                    this.goToSelectedRoute("/userBookings/currentBookings");
                                  }
                                }}
                                className={classes.link}
                              >
                                My Trips
                              </Typography>
                            ) : null}
                          </Grid>

                        </Grid>
                      </Grid>
                    </Hidden>
                    <Grid item>
                      {this.props.role === 'Agency' ?
                        <Button
                          variant="contained" className={classes.headerBtn}
                          onClick={() => {
                            if (this.props.role === 'Admin') {
                            } else if (this.props.role === 'Agency') {
                              if (this.state.authToken) {
                                this.goToSelectedRoute("/AgancyDashborad/profileDetails");
                              } else {
                                this.openAgencyLogin();
                              }
                            } else {
                              if (this.state.authToken) {
                                this.goToSelectedRoute("/myAccount");
                              } else {
                                this.openLogin();
                              }
                            }
                          }}

                        >
                          {this.state.authToken ? "My Account" : "AGENCY LOGIN"}
                        </Button> : <>
                          <Button
                            variant="contained" className={classes.headerBtn}
                            onClick={() => {
                              if (this.props.role === 'Admin') {
                              } else if (this.props.role === 'Agency') {
                                if (this.state.authToken) {
                                  this.goToSelectedRoute("/AgancyDashborad/profileDetails");
                                } else {
                                  this.openAgencyLogin();
                                }
                              } else {
                                if (this.state.authToken) {
                                  this.goToSelectedRoute("/myAccount");
                                } else {
                                  this.openLogin();
                                }
                              }
                            }}
                          >
                            {this.state.authToken ? "My Account" : "Login"}</Button>
                        </>}
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        className={classes.notificationButton}
                      >
                        <NotificationsNoneIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar >

      </>
      // <AppBar
      //   position="relative"
      //   color="default"
      //   elevation={0}
      //   className={classes.appBar}
      // >
      //   <Toolbar className={classes.toolbar}>
      //     <Grid container spacing={5}>
      //       <Grid xs={2} md={3} item>
      //         <Typography  className={classes.logo}>
      //           YTravels
      //         </Typography>
      //       </Grid>
      //       <Hidden xsDown>
      //         <Grid xs={12} md={6} item>
      //           <Grid container justify="space-between">
      //             <Grid item xs={2}>
      //               <Typography
      //                 align="center"
      //                 onClick={() => {
      //                   if(this.props.role === 'Admin'){
      //                     this.goToSelectedRoute("/AdminDashboard");
      //                   } else if(this.props.role === 'Agency'){
      //                     this.goToSelectedRoute("/AgancyDashborad");
      //                   } else {
      //                     this.goToSelectedRoute("/");
      //                   }
      //                 }}
      //                 className={classes.link}
      //               >
      //                 Home
      //               </Typography>
      //             </Grid>
      //             <Grid item xs={2}>
      //               <Typography
      //                 align="center"
      //                 onClick={() => {
      //                   if(this.props.role === 'Admin'){
      //                     this.props.history.push("/flightSearchResult",{role : 'Admin'});
      //                   } else if(this.props.role === 'Agency'){
      //                     this.props.history.push("/flightSearchResult",{role : 'Agency'});
      //                   } else {
      //                     this.props.history.push("/flightSearchResult",{role : 'Customer'});
      //                   }
      //                 }}
      //                 className={classes.link}
      //               >
      //                 Flights
      //               </Typography>
      //             </Grid>
      //             <Grid item xs={2}>
      //               <Typography
      //                 align="center"
      //                 onClick={() => {
      //                   if(this.props.role === 'Admin'){
      //                     this.props.history.push("/hotelSearchResult",{role : 'Admin'});
      //                   } else if(this.props.role === 'Agency'){
      //                     this.props.history.push("/hotelSearchResult",{role : 'Agency'});
      //                   } else {
      //                     this.props.history.push("/hotelSearchResult",{role : 'Customer'});
      //                   }
      //                 }}
      //                 className={classes.link}
      //               >
      //                 Hotels
      //               </Typography>
      //             </Grid>
      //             <Grid item xs={2}>
      //               {this.state.authToken && (
      //                 <Typography
      //                   align="center"
      //                   onClick={() => {
      //                     if(this.props.role === 'Admin'){
      //                       this.goToSelectedRoute("/AdminDashboard/myBooking");
      //                     } else if(this.props.role === 'Agency'){
      //                       this.goToSelectedRoute("/AgancyDashborad/myBooking");
      //                     } else {
      //                       this.goToSelectedRoute("/userBookings/currentBookings");
      //                     }
      //                   }}
      //                   className={classes.link}
      //                 >
      //                   My Trips
      //                 </Typography>
      //               )}
      //             </Grid>
      //             <Grid item xs={2}>
      //               {this.state.authToken && (
      //                 <Typography
      //                   align="center"
      //                   onClick={async () => {
      //                     await StorageProvider.clearStorage();
      //                     if(this.props.role === 'Admin'){
      //                       this.goToSelectedRoute("/AdminLogin");
      //                     } else if(this.props.role === 'Agency'){
      //                       this.goToSelectedRoute("/AgencyMarketing");
      //                     } else {
      //                       this.goToSelectedRoute("/");
      //                     }
      //                   }}
      //                   className={classes.link}
      //                 >
      //                   Logout
      //                 </Typography>
      //               )}
      //             </Grid>

      //           </Grid>
      //         </Grid>
      //       </Hidden>
      //       <Hidden smUp implementation="css">
      //         <Drawer
      //           variant="persistent"
      //           anchor={"right"}
      //           open={this.state.mobileOpen}
      //           onClose={this.handleDrawerToggle}
      //           classes={{
      //             paper: classes.drawerPaper,
      //           }}
      //           SlideProps={{ unmountOnExit: true }}
      //         >
      //           <IconButton
      //             onClick={this.handleDrawerToggle}
      //             className={classes.closeMenuButton}
      //           >
      //             <CloseIcon />
      //           </IconButton>
      //           <List component="nav" aria-label="main mailbox folders">
      //           <ListItem
      //             button
      //             divider
      //           >
      //             <ListItemText primary="Home" onClick={() => {
      //                   if(this.props.role === 'Admin'){
      //                     this.goToSelectedRoute("/AdminDashboard");
      //                   } else if(this.props.role === 'Agency'){
      //                     this.goToSelectedRoute("/AgancyDashborad");
      //                   } else {
      //                     this.goToSelectedRoute("/");
      //                   }
      //                 }} classes={{primary:classes.mobileLink}} />
      //           </ListItem>
      //           <ListItem
      //             button
      //             divider
      //           >
      //             <ListItemText primary="Flights" onClick={() => {
      //                   if(this.props.role === 'Admin'){
      //                     this.props.history.push("/flightSearchResult",{role : 'Admin'});
      //                   } else if(this.props.role === 'Agency'){
      //                     this.props.history.push("/flightSearchResult",{role : 'Agency'});
      //                   } else {
      //                     this.props.history.push("/flightSearchResult",{role : 'Customer'});
      //                   }
      //                 }} classes={{primary:classes.mobileLink}} />
      //           </ListItem>
      //           <ListItem
      //             button
      //             divider
      //           >
      //             <ListItemText primary="Hotels" onClick={() => {
      //                   if(this.props.role === 'Admin'){
      //                     this.props.history.push("/hotelSearchResult",{role : 'Admin'});
      //                   } else if(this.props.role === 'Agency'){
      //                     this.props.history.push("/hotelSearchResult",{role : 'Agency'});
      //                   } else {
      //                     this.props.history.push("/hotelSearchResult",{role : 'Customer'});
      //                   }
      //                 }} classes={{primary:classes.mobileLink}} />
      //           </ListItem>
      //           {this.state.authToken && ( <ListItem
      //             button
      //             divider
      //           >
      //             <ListItemText primary="My Trips" onClick={() => {
      //                   this.goToSelectedRoute("/userBookings/currentBookings");
      //                 }} classes={{primary:classes.mobileLink}} />
      //           </ListItem>)}
      //         </List>
      //         </Drawer>
      //       </Hidden>
      //       <Grid md={3} xs={10} item>
      //         <Box textAlign="end">
      //         <Button
      //           variant="contained"
      //           onClick={() => {
      //             if(this.props.role === 'Admin'){
      //             } else if(this.props.role === 'Agency'){
      //               if (this.state.authToken) {
      //                 this.goToSelectedRoute("/AgancyDashborad/profileDetails");
      //               } else {
      //                 this.openAgencyLogin();
      //               }
      //             } else {
      //               if (this.state.authToken) {
      //                 this.goToSelectedRoute("/myAccount");
      //               } else {
      //                 this.openLogin();
      //               }
      //             }
      //           }}
      //           style={{
      //             backgroundColor: "#fff",
      //             color: "blue",
      //             borderRadius: "50px",
      //             fontFamily: "Open Sans",
      //             fontSize: "12px",
      //             fontWeight: "bold",
      //             fontStretch: "normal",
      //             fontStyle: "normal",
      //             letterSpacing: "normal",
      //             color: "#1565d8",
      //           }}
      //         >
      //           {this.state.authToken ? "My Account" : "Login"}
      //         </Button>
      //         <IconButton
      //           color="inherit"
      //           aria-label="Open drawer"
      //           edge="start"
      //           onClick={this.handleDrawerToggle}
      //           className={classes.menuButton}
      //         >
      //           <MenuIcon />
      //         </IconButton>

      //         </Box>
      //         </Grid>
      //     </Grid>
      //   </Toolbar>
      // </AppBar>
    );
  }
}
const HeaderWithRouter = withRouter(Header);
const HeaderAlertBox = withAlertBox(HeaderWithRouter);
const HeaderLoader = withLoader(HeaderAlertBox);
const HeaderToast = withToast(HeaderLoader);
export default withStyles((theme) =>
  createStyles({
    appBar: {
      background: "transparent",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    mobileLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: 'center'
    },
    logo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#ffffff",
      cursor: "pointer",
      paddingLeft: '3rem',
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        paddingLeft: '0rem',

      },
    },
    toolbar: {
      flexWrap: "wrap",
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginLeft: theme.spacing(1),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    headerBtn: {
      backgroundColor: "#fff",
      color: "blue",
      borderRadius: "50px",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1565d8",
    },
    notificationButton: {
      marginLeft: theme.spacing(1),
      color: "#fff",
    }
  })
)(withDialog(HeaderToast));
