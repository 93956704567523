//@ts-nocheck
import React from "react";
import AgancyDashboradController, {
  Props,
} from "./AgancyDashboradController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Paper,
  Divider,
  Hidden,
  IconButton,
  Drawer,
  Popper,
  Fade
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { hotelImage,agancyCover } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import MyBookings from "./myBookings.web";
import  AllSubagancyList   from "./AllSubagancyList.web";
import AddNewSubAgancy from "./AddNewSubAgancy.web";
import { NavLink } from "react-router-dom";
import Header from "./Header.web";
import Footer from "./Footer.web";
import AgancyPrivateRoute from "../../../components/src/AgancyPrivateRoute.web";
import NewSearch from "./NewSearch.web";
import EditAgancyProfile from "./EditAgancyProfile.web";
import AgancyProfileDetail from "./AgancyProfileDetail.web";
import TeamMemberlist from "./TeamMemberlist.web";
import Report from "./Report.web";
import CreadiTransaction from "./CreadiTransaction.web";
const drawerWidth = 240;
class AgancyDashborad extends AgancyDashboradController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("agancyToken");
    const role = await StorageProvider.get("role");
    const permissions = await StorageProvider.get("permissions")
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          role : role,
          permissions : JSON.parse(permissions)
        },
        () => {
          this.getProfileDetail();
        }
      );
    }
  }
  //sub_agency
  //agency
  //team_member
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  openPopover = (event,stops) => {
    this.setState({
      openpoper : !this.state.openpoper,
      poperAchor : event.currentTarget,
      stops : stops
    })
  }
  render() {
    const { classes } = this.props;
    
    return !this.state.loading ? (
      this.state.profileDataWithId === null ? (<></>) : ((
        <div className={classes.root} onClick={()=>{this.setState({
          openpoper : false
        })}}>
          <Popper open={this.state.openpoper} anchorEl={this.state.poperAchor} placement={'top-start'} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{backgroundColor : '#1565d8',padding : '16px'}}>
                {this.state.stops.map((stop)=>{
                  return <>
                    <Typography style={{color:'#fff'}} sx={{ p: 2 }}>{stop.stopType}</Typography>
                    <Typography style={{color:'#fff'}} sx={{ p: 2 }} align="center">{stop.stopName} | {stop.stopDuration}</Typography>
                  </>
                })}
                
              </Paper>
            </Fade>
          )}
         </Popper>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${agancyCover})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={agancyCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header role="Agency"/>
          <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              SlideProps={{ unmountOnExit: true }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              <Grid container>
                <Grid item xs={10}>
                  <Paper elevation={0} className={classes.sidebarAboutBox}>
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                    >
                      New Search
                    </Typography>
                    <Divider />
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                    >
                      Your Account
                    </Typography>
                    {[
                      {
                        title: "My Bookings",
                        url: "/AgancyDashborad/myBooking",
                      },
                      { title: "Reports", url: "/AgancyDashborad/reports" },
                      {
                        title: "Profile Details",
                        url: "/AgancyDashborad/profileDetails",
                      },
                      { title: "Invoices", url: "/AgancyDashborad/Invoices" },
                      { title: "Team", url: "/AgancyDashborad/Team" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                    <Divider />
                    <Typography
                      gutterBottom
                      className={classes.sideLinkHeading}
                    >
                      Subagencies
                    </Typography>
                    {[
                      {
                        title: "All Subagencies",
                        url: "/AgancyDashborad/AllSubagencies",
                      },
                      { title: "Bookings", url: "/AgancyDashborad/subagancymyBooking" },
                      { title: "Reports", url: "/AgancyDashborad/subagancyreports" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>

          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  Agency Panel
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={10}>
              <Grid
                container
                spacing={2}
                justify="space-between"
                alignItems="center"
                className={classes.mainPostSecondaryContent}
              >
                <Grid item xs={6} md={2}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Agency Name"
                      secondary={`${this.state?.profileData.agency_name || "Not Available"}`}
                      classes={{primary : classes.listItemPrimaryText,secondary : classes.listItemSecondaryText}}
                    />
                  </ListItem>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem></Divider>
                <Grid item xs={6} md={2}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Credit Balance"
                      secondary={`${this.state?.profileData?.balance || "Not Available"}`}
                      classes={{primary : classes.listItemPrimaryText1,secondary : classes.listItemSecondaryText1}}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={2}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Contact Details"
                      secondary={`${this.state?.profileData?.agency_phone_numbeer  || "Not Available"}`}
                      classes={{primary : classes.listItemPrimaryText1,secondary : classes.listItemSecondaryText1}}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={2}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Address"
                      secondary={`${this.state?.profileData?.agency_address  || "Not Available"}`}
                      classes={{primary : classes.listItemPrimaryText1,secondary : classes.listItemSecondaryText1}}
                    />
                  </ListItem>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={8}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={()=>{this.props.history.push("/AgancyDashborad/profileDetails")}}
                  >
                    Show Profile
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="center"
          style={{ marginTop: "120px" }}
        >
          <Hidden xsDown>
            <Grid xs={3} item>
              <Grid container>
                <Grid item xs={10}>
                  <Paper elevation={0} className={classes.sidebarAboutBox}>
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                      style={{cursor : 'pointer'}}
                      onClick={()=>{this.props.history.push("/AgancyDashborad/NewSearch")}}
                    >
                      New Search
                    </Typography>
                    <Divider />
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                    >
                      Your Account
                    </Typography>
                    {[
                      {
                        title: "My Bookings",
                        url: "/AgancyDashborad/myBooking",
                      },
                      { title: "Reports", url: "/AgancyDashborad/reports" },
                      {
                        title: "Profile Details",
                        url: "/AgancyDashborad/profileDetails",
                      },
                      { title: "Team", url: "/AgancyDashborad/Team" },
                      { title: "Credits", url: "/AgancyDashborad/credits" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                    <Divider />
                 {this.state.role !== 'sub_agency' && <Typography
                      gutterBottom
                      className={classes.sideLinkHeading}
                    >
                      Subagencies
                    </Typography>
                  }
                    {this.state.role !== 'sub_agency' && [
                      {
                        title: "All Subagencies",
                        url: "/AgancyDashborad/AllSubagencies",
                      },
                      { title: "Bookings", url: "/AgancyDashborad/subagancymyBooking" },
                      { title: "Reports", url: "/AgancyDashborad/subagancyreports" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}   
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid xs={10} md={8} item>
            <Switch>
              <AgancyPrivateRoute path="/AgancyDashborad/myBooking" exact>
                <MyBookings openPopover={this.openPopover} tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              <AgancyPrivateRoute path="/AgancyDashborad/NewSearch" exact>
                <NewSearch tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              <AgancyPrivateRoute path="/AgancyDashborad/profileDetails" exact>
                <AgancyProfileDetail />
              </AgancyPrivateRoute>
              <AgancyPrivateRoute path="/AgancyDashborad/editProfileDetails" exact>
                <EditAgancyProfile />
              </AgancyPrivateRoute>
              <AgancyPrivateRoute path="/AgancyDashborad/invoices" exact>
                <CreadiTransaction profileData={this.state.profileData} tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              <AgancyPrivateRoute path="/AgancyDashborad/team" exact>
                <TeamMemberlist tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              
              <AgancyPrivateRoute path="/AgancyDashborad/reports" exact>
                <Report tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              
              <AgancyPrivateRoute
                path="/AgancyDashborad/AllSubagencies"
                exact
              >
                <AllSubagancyList tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              <AgancyPrivateRoute
                  path="/AgancyDashborad/AddNewSubAgancy"
                  exact>
                 <AddNewSubAgancy></AddNewSubAgancy>
              </AgancyPrivateRoute>
              <AgancyPrivateRoute
                path="/AgancyDashborad/subagancymyBooking"
                exact
              >
                <MyBookings openPopover={this.openPopover} tokenName="agancyToken" role="Agency" apiType="subagancymyBooking" />
              </AgancyPrivateRoute>
              <AgancyPrivateRoute
                path="/AgancyDashborad/subagancyreports"
                exact
              >
                <Report tokenName="agancyToken" role="SubAgencies" apiType="subAgencyReport"/>
              </AgancyPrivateRoute>
              <AgancyPrivateRoute
                path="/AgancyDashborad/credits"
                exact
              >
                <CreadiTransaction profileData={this.state.profileDataWithId} tokenName="agancyToken" role="Agency"/>
              </AgancyPrivateRoute>
              <Redirect
                exact
                from="/AgancyDashborad"
                to="/AgancyDashborad/myBooking"
              />
            </Switch>
          </Grid>
        </Grid>
        <Footer></Footer>
      </div>
    
        ))
  ) : null 

    
  }
}
const AgancyDashboradControllerWithRouter = withRouter(AgancyDashborad);
const AgancyDashboradControllerAlertBox = withAlertBox(
  AgancyDashboradControllerWithRouter
);
const AgancyDashboradControllerLoader = withLoader(
  AgancyDashboradControllerAlertBox
);
const AgancyDashboradControllerToast = withToast(
  AgancyDashboradControllerLoader
);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [theme.breakpoints.down("xs")]: {
        height: "440px",
      },
      [theme.breakpoints.up("md")]: {
        height: "345px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(2),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "220px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      margin: theme.spacing(0, 1),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },

    sideLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      padding: theme.spacing(1, 1.5),
    },
    sideLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: "left",
      display: "block",
      textDecoration: "none",
      padding: theme.spacing(1.5),
    },
    activeSideLink: {
      backgroundColor: "#fff",
      color: "#1565d8",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    listItemPrimaryText : {
      fontFamily: "Public Sans",
      fontSize: "19px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemPrimaryText1 : {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemSecondaryText : {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    listItemSecondaryText1 : {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#5a7184",
    }
  })
)(AgancyDashboradControllerToast);
