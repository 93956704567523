export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const hotelImage = require("../assets/hotelImage.jpg")


export const imgLogo = require("../assets/960cd18f67d037fe3b05a87f401cd90e5080dcff.png");
// export const splashImage = require("../../../assets/Splash.png");
// export const backgroundImage = require("../../../assets/backgroundImage.png");
// export const appLogo = require("../../../assets/appLogo.png");
// export const unCheckIcon = require("../../../assets/uncheckIcon.png");
// export const checkIcon = require("../../../assets/checkBox.png");
// export const visibleIcon = require("../../../assets/visibleIcon.png");
// export const invisibileIcon = require("../../../assets/invisibleLogo.png");









