//@ts-nocheck
import React from "react";
import CompleteProfileDataController, {
  Props,
} from "./CompleteProfileDataController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  ListItemText,
  Button,
  Paper,
  IconButton,
  Divider,
  Switch,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import Header from "./Header.web";
import Footer from "./Footer.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import moment from "moment";
import "yup-phone";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();
const Schema = Yup.object().shape({
  gender: Yup.string().required("This field is required."),
  dob: Yup.string().required("This field is required."),
  addressLineOne: Yup.string().required("This field is required."),
  addressLineTwo: Yup.string().required("This field is required."),
  country: Yup.string().required("This field is required."),
  city: Yup.string().required("This field is required."),
  pincode: Yup.number().required("This field is required"),
  phoneNumber: Yup.string()
    .phone()
    .required("This field is required"),
  isViber: Yup.boolean(),
  isWhatsApp: Yup.boolean(),
  currency: Yup.string().required("This field is required."),
  isPassport: Yup.boolean(),
  passportNumber: Yup
    .string()
    .when("isPassport", {
      is: true,
      then: Yup.string().required("This field is required"),
    }),
  passportExpiryDate: Yup
    .date()
    .when("isPassport", {
      is: true,
      then: Yup.date().required("This field is required")
    }),
  citizenNumber: Yup
    .string()
    .when("isPassport", {
      is: false,
      then: Yup.string().required("This field is required"),
    }),
  citizenNumberExpiryDate: Yup
    .date()
    .when("isPassport", {
      is: false,
      then: Yup.date().required("This field is required")
    })
});

class CompleteProfile extends CompleteProfileDataController {
  constructor(props: Props) {
    super(props);
  }
  headerRef = React.createRef();
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { search } = location;
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (token) {
      this.setState(
        {
          authToken: token,
        },
        () => {
          this.getCountriesList();
          this.getProfileDetail();

        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url('https://source.unsplash.com/random')`,
            backgroundSize: "cover",
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={"https://source.unsplash.com/random"}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <Header childRef={(ref) => (this.headerRef = ref)} />
          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  Before We Continue
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={10}>
              <Grid
                container
                spacing={2}
                justify="space-evenly"
                className={classes.mainPostSecondaryContent}
              >
                <Grid item xs={12} md={12}>
                  <ListItem>
                    <ListItemText
                      primary={this.state?.profileData?.attributes?.email || "-"}
                      secondary={this.state?.profileData?.attributes?.full_name || "-"}
                      classes={{
                        primary: classes.userEmail,
                        secondary: classes.userName,
                      }}
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="center"
          style={{ marginTop: "120px" }}
        >
          <Grid item xs={10} md={10}>
            <Formik
              initialValues={{
                gender: "Male",
                dob: "",
                addressLineOne: "",
                addressLineTwo: "",
                city: "",
                pincode: "",
                country: "",
                phoneNumber: "",
                isViber: false,
                isWhatsApp: false,
                currency: 'USD',
                isPassport: true,
                passportNumber: "",
                passportExpiryDate: "",
                citizenNumber: "",
                citizenNumberExpiryDate: "",
              }}
              validationSchema={Schema}
              onSubmit={(values, actions) => {
                const onClose = (result) => {
                  if (result === 'Yes') {
                    this.handleProfileSave(values);
                  }
                }
                this.props.openConfirmDialog(
                  'Save', 'Are you sure you want to save this info', onClose
                )
              }}
            >
              {(formikProps) => {
                const { values, errors, setFieldValue, handleChange } = formikProps;
                return (
                  <Form noValidate autoComplete="off">
                    <Grid
                      container
                      spacing={3}
                      className={classes.EditProfileContainer}
                    >
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.formSectionHeading}
                              gutterBottom
                            >
                              Complete Your Profile
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.dividerStyle}></Divider>
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <RadioGroup
                              row
                              aria-label="gender"
                              name="gender"
                              value={values.gender}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="Male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                value="Female"
                                control={<Radio />}
                                label="Female"
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              InputProps={{
                                inputProps: {
                                  max: curDate,
                                },
                              }}
                              fullWidth
                              required
                              type="date"
                              label="Date of Birth"
                              name="dob"
                              placeholder="dd/mm/yyyy"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.formSectionHeading}
                              gutterBottom
                            >
                              Address Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.dividerStyle}></Divider>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="Address Line 1"
                              name="addressLineOne"
                              placeholder="House/Flat Number, Street Name"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="Address Line 2"
                              name="addressLineTwo"
                              placeholder="Landmark Name"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="City"
                              name="city"
                              placeholder="City Name"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="ZIP/PIN Code"
                              name="pincode"
                              placeholder="Zip or Pin code"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              select
                              required
                              fullWidth
                              label="Country"
                              name="country"
                              component={InputField}
                            >
                              <MenuItem value="">Select Country</MenuItem>
                              {this.state.countriesList.map((country) => {
                                return <MenuItem key={country} value={country}>{country}</MenuItem>
                              })}
                            </Field>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.formSectionHeading}
                              gutterBottom
                            >
                              Currency Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.dividerStyle}></Divider>
                          </Grid>

                          <Grid container item xs={12}>
                            <FormControl component="fieldset">
                              <FormLabel
                                className={classes.radioGroupTitle}
                                component="legend"
                              >
                                Currency
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-label="position"
                                name="currency"
                                onChange={handleChange}
                                defaultValue={values.currency}
                              >
                                <FormControlLabel
                                  classes={{
                                    label: classes.radioButtonLabel,
                                  }}
                                  value="USD"
                                  control={
                                    <Radio
                                      color="primary"
                                    />
                                  }
                                  label="USD"
                                />
                                <FormControlLabel
                                  classes={{
                                    label: classes.radioButtonLabel,
                                  }}
                                  value="NPR"
                                  control={
                                    <Radio
                                      color="primary"
                                    />
                                  }
                                  label="NPR"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.formSectionHeading}
                              gutterBottom
                            >
                              Contact Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.dividerStyle}></Divider>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="Phone Number"
                              name="phoneNumber"
                              placeholder="Phone Number With Country Code"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <FormControlLabel
                              control={<Checkbox />}
                              name="isWhatsApp"
                              checked={values.isWhatsApp}
                              onChange={handleChange}
                              label="Yes, I am on WhatsApp with above number"
                              labelPlacement="end"
                              classes={{
                                label: classes.labelText,
                              }}
                            />
                            <FormControlLabel
                              control={<Checkbox />}
                              name="isViber"
                              checked={values.isViber}
                              onChange={handleChange}
                              label="Yes, I am on Viber with above number"
                              labelPlacement="end"
                              classes={{
                                label: classes.labelText,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography className={classes.formSectionHeading} gutterBottom>
                              Passport Details / Citizenship Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.dividerStyle}></Divider>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<Switch />}
                              name="isPassport"
                              checked={values.isPassport}
                              onChange={handleChange}
                              label="Use Passport instead of citizenship"
                              labelPlacement="start"
                              classes={{
                                label: classes.labelText,
                              }}
                            />
                          </Grid>
                          {values.isPassport && <>
                            <Grid item md={6} xs={12}>
                              <Field
                                component={InputField}
                                required
                                fullWidth
                                label="Passport Number"
                                name="passportNumber"
                                placeholder="Enter Passport Number"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Field
                                component={InputField}
                                required
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    min: curDate,
                                  },
                                }}
                                type="date"
                                label="Passport Expiry Date"
                                name="passportExpiryDate"
                                placeholder="dd/mm/yyyy"
                              />
                            </Grid></>}
                          {!values.isPassport && <>
                            <Grid item md={6} xs={12}>
                              <Field
                                component={InputField}
                                required
                                fullWidth
                                label="Citizen Number"
                                name="citizenNumber"
                                placeholder="Enter Citizen Number"
                              />
                            </Grid>
                            <Grid item md={5} xs={12}>
                              <Field
                                component={InputField}
                                required
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    min: curDate,
                                  },
                                }}
                                type="date"
                                label="Citizen Expiry Date"
                                name="citizenNumberExpiryDate"
                                placeholder="12/12/2020"
                              />
                            </Grid></>}
                          <Grid item container md={6} xs={10} spacing={3} className={classes.buttonGrid}>
                            <Grid item md={6}>
                              <Button type="submit" fullWidth color="primary" variant="contained">
                                SAVE
                              </Button>
                            </Grid>
                            <Grid item md={6}>
                              <span className={classes.skipText} onClick={() => { this.props.history.push("/");this.headerRef?.openLogin(); }}>Skip For Now ?</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const CompleteProfileWithRouter = withRouter(CompleteProfile);
const CompleteProfileAlertBox = withAlertBox(CompleteProfileWithRouter);
const CompleteProfileLoader = withLoader(CompleteProfileAlertBox);
const CompleteProfileToast = withToast(CompleteProfileLoader);
const CompleteProfileWithDialog = withDialog(CompleteProfileToast);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: "url(https://source.unsplash.com/random)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // height: "348px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      // [theme.breakpoints.up("md")]: {
      //   padding: theme.spacing(7),
      //   paddingRight: 0,
      // },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      // height: "150px",
      // [theme.breakpoints.down("xs")]: {
      //   height: "220px",
      // },
      borderRadius: "5px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    EditProfileContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(2, 4),
    },
    formSectionHeading: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    userEmail: {
      fontFamily: "Open Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#545454",
    },
    userName: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9",
    },
    skipText: {
      color: "#1565D8",
      fontSize: "15px",
      fontWeight: "700",
      cursor: "pointer",
    },
    buttonGrid: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
)(withConfirmBox(CompleteProfileWithDialog));
