import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#1565d8',
      contrastText: '#fff'
    },
    secondary: {
      main: '#1565d8',
      contrastText: '#fff'
    },
    background: {
      default: '#fff',
      paper: '#fff'
    },
    text: {
      primary: '#183b56',
      secondary: '#5a7184'
    },
    error: {
      main: '#ef3e22'
    }
  }
};

const theme = createMuiTheme({
  props: {
    MuiDivider: {
      light: true
    }
  },
  ...themeOptions,
  typography: {
    fontFamily: ['Public Sans', 'Open Sans'].join(','),
    textTransform: 'none',
    h1: {
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: 1.25,
      letterSpacing: '0.27px'
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1.33,
      letterSpacing: '0.2px'
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: 1.2,
      letterSpacing: '0.2px'
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: 1.78,
      letterSpacing: 'normal'
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: 1.38,
      letterSpacing: '0.2px'
    }
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontFamily: 'Public Sans',
        fontSize: '12px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        color: '#000000'
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'Public Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: '0.2px',
        color: '#183B56',
        textTransform: 'capitalize'
      }
    },
    MuiButton: {
      root:{
        textTransform:'none',
      },
      containedPrimary: {
        height: '48px',
        width:'90%',
        borderRadius: '8px',
        padding: '8px 20px',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        // textTransform: 'uppercase',
        textAlign: 'center',
        color: '#FFFFFF'
      },
      outlinedPrimary: {
        height: '48px',
        borderRadius: '8px',
        padding: '8px 20px',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        // textTransform: 'uppercase',
        textAlign: 'center',
        color: '#1565D8'
      },
      endIcon: {
        marginLeft: '12px'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&&[class*="MuiOutlinedInput-root"]': {
          padding: '5px 2px 5px 0px'
        }
      }
    },
    MuiSelect: {
      outlined: {
        borderRadius: '8px'
      }
    },
    MuiOutlinedInput: {
      input: {
        borderRadius: '8px',
        position: 'relative',
        backgroundColor: '#FFF',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '14px 14px',
      },
      root: {
        borderRadius: '8px',
        borderColor: '#C3CAD9',
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        position: 'relative',
        backgroundColor: '#FFF',
        border: 'none',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '14px 14px'
      }
    },
    MuiListItem: {
      root: {
        padding: 0
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiMenuItem: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px',
      }
    }
  }
});

export default responsiveFontSizes(theme);