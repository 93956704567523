//@ts-nocheck

//muspamirdo@yevme.com

// Test@1234
// yowinow136@64ge.com
import React from "react";
import LoginController, { Props } from "./LoginController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  DialogContent,
  Button,
  Divider
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required."),
  contactNumber: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required.")
});



class ContactDetails extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };


  render() {
    console.log("ContactDetails");

    const { classes } = this.props;
    return (
      <>
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <Formik
            initialValues={{
              ContactNo: "",
              email: "",

            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              ;
            }}
          >
            {(formikProps) => {
              const { values, setFieldValue, isValid, dirty } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  {/* <Grid container justify="space-around" alignItems="center" style={{ padding: "0px", overflow: "hidden" }}> */}
                  <Grid item xs={12}>
                    <Grid container justify="center" >
                      <Grid item xs={10}>
                        <Typography
                          variant="h1"
                          className={classes.welcomeBack}
                        >
                          Contact Details!
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="h1"
                          className={classes.labelText}>
                          Your ticket and flights information will be sent here.
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Divider />
                      </Grid>
                      <Grid item xs={10}>
                        <PhoneInput style={{ padding: '3rem' }}
                          placeholder="Enter contact number"
                          country={'np'}
                        value={this.state.contactNo}
                        // onChange={phone => this.setState({ phone })}

                        // value={value}
                        // onChange={setValue}
                        />

                      </Grid>
                      <Grid item xs={10}>
                        <Field
                          component={InputField}
                          fullWidth
                          label="Email Address"
                          name="email"
                          placeholder="Enter Email Address"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          fullWidth
                          disabled={!(isValid && dirty)}
                        >
                          Sign Up
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* </Grid> */}

                </Form>
              );
            }}
          </Formik>
        </DialogContent>

      </>

    );
  }
}

const ContactDetailsWithRouter = withRouter(ContactDetails);
const ContactDetailsWithToast = withToast(ContactDetailsWithRouter);
const ContactDetailsWithLoader = withLoader(ContactDetailsWithToast);
const ContactDetailsWithAlertBox = withAlertBox(ContactDetailsWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "#68819A",
    },
  })
)(ContactDetailsWithAlertBox);
