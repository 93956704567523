//@ts-nocheck
import React from "react";
import AllSubagancyListController, {
  Props,
} from "./AllSubagancyListController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";


// // import withBoatIdComponent from "./withBoatIdComponent.js";

class AllSubagancyList extends AllSubagancyListController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "authToken";
    const authToken = await StorageProvider.get(tokenName);
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          if(this.props.apiType === 'accountId'){
            this.getSubAgancyListbyAccountId(this.props.history.location.state.id);
          } else {
            this.getSubAgancyList();
          }
          
        }
      );
    }
  }
  goToSelectedRoute = async (routeName,data) => {
    const { history } = this.props;
    await StorageProvider.set("agancy",JSON.stringify({...data,tokenName : this.props.tokenName}));
    history.push({pathname : routeName});
  };
  addNewSubAgancy = ()=>{
      this.props.history.push("/AgancyDashborad/AddNewSubAgancy")
  }
  render() {
    const { classes } = this.props;
    return (
        <Grid container>
          <Grid item md={12} xs={11} style={{padding: '17.5px 0px'}}>
              <Grid container justify='center' alignItems='center' className={classes.pendIngRequestBox}>
                  <Grid item md={9} xs={9}>
                    <Typography className={classes.pendIngTite}>
                        All Subagencies
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    {this.props.tokenName === 'agancyToken' && <Button variant="contained" fullWidth color="primary" onClick={()=>{this.addNewSubAgancy()}}> Add New </Button>}
                    
                  </Grid>
                </Grid>
            </Grid>
          {this.state.subAgencyList.length > 0 ? 
          this.state.subAgencyList.map((agency) => {
            return (
              <Grid item key={agency.id} md={12} xs={11} style={{padding: '17.5px 0px'}}>
                <Grid
                  container
                  justify="center"
                  alignItems='center'
                  className={classes.subagencyContainer}
                >
                  <Grid md={9} xs={11}>
                            <Typography gutterBottom className={classes.agencyName}>
                                 {agency?.attributes?.agency_name}
                            </Typography>
                  </Grid>
                  <Grid md={3} xs={11}>
                        <Typography align='right' className={classes.anancyInfo}>
                            {agency?.attributes?.email}
                        </Typography>
                  </Grid>
                      <Grid item md={3} xs={4}>
                        <ListItem style={{padding: 0}}>
                          <ListItemText
                            primary="Bookings Today"
                            secondary={`${agency?.attributes?.flight_booking_today} flights`}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={3} xs={2}>
                        <ListItem style={{padding: 0}}>
                          <ListItemText
                            primary="Status"
                            secondary={`${agency?.attributes?.status || "Not Available"}`}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={3} xs={2}>
                        <ListItem style={{padding: 0}}>
                          <ListItemText
                            primary="Credits"
                            secondary={`${agency?.attributes?.balance || "Not Available"}`}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item container justify='flex-end' md={3} xs={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={()=>{this.goToSelectedRoute("/subAgencyDashboard",agency)}}
                            style={{width: '142px'}}
                          >
                            View
                          </Button>
                      </Grid>
                   
                </Grid>
              </Grid>
            );
          }): 
          <Grid item  xs={11} style={{padding: '17.5px 0px'}}>
          <Grid
            container
            justify="space-around"
            className={classes.subagencyContainer}
          >
            <Grid item xs={11}>
               <Typography align="center">{"No SubAgency found"}</Typography>
               </Grid>
            </Grid>
            </Grid>
          }
        </Grid>
    );
  }
}

const AllSubagancyListWithRouter = withRouter(AllSubagancyList);
const AllSubagancyListAlertBox = withAlertBox(AllSubagancyListWithRouter);
const AllSubagancyListLoader = withLoader(AllSubagancyListAlertBox);
const AllSubagancyListToast = withToast(AllSubagancyListLoader);
const AllSubagancyListWithDialog = withDialog(AllSubagancyListToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: theme.spacing(12.5),
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: '30px 50px 33px 46px',
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "32px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.2px",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy : {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor : 'pointer'
      
    }
  })
)(AllSubagancyListWithDialog);