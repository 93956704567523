import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : string;
  profiieData : any
  agencyData : any
  profileData: any
  agencyDataWithId : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AcceptAgencyRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  subagencySignUpApiCallId: String = "";
  saveProfileApiCallId : String = "";
  getProfileDetailApiCallID : String = '';
  subagencyEditApiCallId : String = ""
  getAgencyStatusApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      profiieData : {},
      agencyData: {},
      profileData: {},
      agencyDataWithId : {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ); 
      if (apiRequestCallId === this.subagencySignUpApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.account && responseJson?.account?.data) {
            this.props.showToast({type : 'success',message : 'SubAgency created successfully'})
            this.props.history.push("/AgancyDashborad/AllSubagencies");
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.props.hideLoader();
        if (responseJson?.agencies) {
            console.log('profile data of agency', responseJson)
            this.setState({...this.state,
                profileData : responseJson?.agencies?.data?.attributes
            })
        } else {
            this.parseApiErrorResponse(responseJson)
        }
    } else if (apiRequestCallId === this.subagencyEditApiCallId) {
      this.props.hideLoader();
      if (responseJson?.data?.data?.id) {
          this.props.showToast({
            type : 'success',
            message : 'Sub agency updated succssfully'
          })
          this.props.history.goBack();
      } else {
          this.parseApiErrorResponse(responseJson)
      }
    } else if (apiRequestCallId === this.getAgencyStatusApiCallId) {
      this.props.hideLoader();
      if (responseJson?.agencies?.data?.id) {
          this.setState({
            agencyData : responseJson?.agencies?.data.attributes
          })
      } else {
          this.parseApiErrorResponse(responseJson)
      }
    }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }
  
  handleSubAgencyEdit = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subagencyEditApiCallId = requestMessage.messageId;
    const httpBody = {
        account_type:"sub_agency",
        data: {
          full_name:values.adminName,
          email: values.adminEmail,
          member_id : this.state.agencyDataWithId.id,
          password: "values.password",
          confirm_password: "values.password",
          balance : values.credit,
          full_phone_number:(values.adminPhoneNumber),
          business_registration_number:(values.businessRegistrationNumber),
          permission_ntb_number:(values.ntbNumber),
          agency_name:values.subAgencyName,
          agency_address:values.subAgencyAddress,
          agency_phone_numbeer:(values.subAgencyPhoneNumber),
          pan_number:values.panNumber,
      },
      token : this.state.authToken
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `${"bx_block_dashboard/update_team_member"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleProfileSave = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      'token':this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveProfileApiCallId = requestMessage.messageId;
    const httpBody ={
      "data": {
        "full_name": values.fullName,
        "email": values.email,
        "full_phone_number": values.phoneNumber,
        "business_registration_number": values.registrationNumber,
        "permission_ntb_number": values.ntbNumber,
        "agency_name": values.agencyName,
        "agency_address": values.agencyAddress,
        "agency_phone_numbeer": values.agencyPhoneNumber,
        "pan_number": values.panNumber,
        "credit_limit": values.credit
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.agencyEditProfile}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileDetail = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getProfileDetailApiCallID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.agencyGetProfileData}?token=${this.state.authToken}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
   getSubAgancyDetial = (id:any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAgencyStatusApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/get_agency_credit_details?agency_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSubAgencySignUp = (values: any, type: string) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subagencySignUpApiCallId = requestMessage.messageId;
    const httpBody = {
        account_type:"sub_agency",
        data: {
          full_name:values.adminName,
          email: values.adminEmail,
          full_phone_number:(values.adminPhoneNumber),
          business_registration_number:(values.businessRegistrationNumber),
          permission_ntb_number:(values.ntbNumber),
          agency_name:values.subAgencyName,
          agency_address:values.subAgencyAddress,
          agency_phone_numbeer:(values.subAgencyPhoneNumber),
          pan_number:values.panNumber,
          credit_limit: values.credit,
          balance: values.credit
      },
      token : this.state.authToken
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `${configJSON.agencySignUp}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


}
