//@ts-nocheck
import React from "react";
import AgencyMarketingController, {
  Props,
} from "./AgencyMarketingController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Hidden,
  Avatar,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withFontStyles from "./withFontStyles.web";

import Header from "./Header.web";
import Footer from "./Footer.web";
import {
  hotelImage,
  CustomerlandingPage,
  Landingpage,
  // AgencyMarketingBackground,
  AgentBanner,
  cheapThanOtherIcon,
  securePaymentIcon,
  bestOfferIcon,
  easyBookIcon,
  greatAmenitiesIcon,
  topRatedIcon,
  fastRefundIcon,
  supportIcon,
} from "./assets";
import { withRouter } from "react-router";
import moment from "moment";

class AgencyMarketing extends AgencyMarketingController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          // this.getBoatListing();
          // this.getBookings();
        }
      );
    }
  }

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.push({
      state: { searchData: { ...data }, ...this.state },
      pathname: routeName,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${"/static/media/Landingpage.c6bdf1e7.png"})`,
            backgroundSize: "cover",
           
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={"/static/media/Landingpage.c6bdf1e7.png"}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          {/* <div className={classes.overlay} /> */}
          <Header role="Agency" />

          <Grid container justify="center">
            <Grid item md={8} xs={10}>
              <Grid
                container
                spacing={4}
                // justify="center"
                alignItems="center"
                direction="column"
                className={classes.mainPostContent}
              >
                <Grid item md={6}>
                  <Typography className={classes.helloAdmin} align="center">
                    Join As An Agency And Grow Your Business
                  </Typography>
                </Grid>
                <Grid item md={10} xs={12}>
                  <Typography
                    align="center"
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "20px",
                      lineHeight: 1.4,
                      color: "#ffffff",
                    }}
                  >
                    Lost yourself with Vacation and Travel into the place that
                    you never visit before. We have thousand places in our
                    database for you.
                  </Typography>
                </Grid>
                {/* <Grid
                  item
                  xs={11}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => this.openAgencyLogin()}
                    className={classes.AgencyLogin}                   
                  >
                    Agency Login
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={8} xs={10}>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                className={classes.mainPostSecondaryContent}
              >
                <Grid item xs={12} md={12}>
                  <Typography
                    className={classes.groteskBold36}
                    color="textPrimary"
                    align="center"
                  >
                    Ready To Get Started?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    align="center"
                    className={classes.openSans16}
                    color="textSecondary"
                  >
                    Apply for Agency Portal
                  </Typography>
                </Grid>
                <Grid
                  item
                  md="auto"
                  xs={8}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.openApplyForAgency();
                    }}
                    style={{
                      width: "182px",
                      height: "58px",
                    }}
                  >
                    Apply Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="space-around"
          style={{ marginTop: "200px"}}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid md={7} xs={12} item>
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url(${AgentBanner})`,
                    height: "608px",
                    backgroundSize: 'cover',                    
                    // width: "791px",
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div style={{ height: "300px" }} />
                  <div className={classes.agentFloatingSmallCards} style={{
                    position: 'absolute',
                    top: '289px',
                    right: '-20px',
                  }}>
                    <Typography color='textPrimary' className={classes.openSans16}>
                      Full Refunds & Much More
                    </Typography>
                  </div>
                  <div className={classes.agentFloatingSmallCards} style={{
                    position: 'absolute',
                    top: '368px',
                    right: '-50px',
                  }}>
                    <Typography color='textPrimary' className={classes.openSans16}>
                      Full Refunds & Much More
                    </Typography>
                  </div>
                  <div className={classes.agentFloatingSmallCards} style={{
                    position: 'absolute',
                    top: '447px',
                    right: '10px',
                  }}>
                    <Typography color='textPrimary' className={classes.openSans16}>
                      Full Refunds & Much More
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid md={5} xs={12} item container alignItems='stretch' style={{ padding: "50px 80px" }}>
                <Typography gutterBottom variant="h1" className={classes.JoinAgent} >
                  Join As An Agent & Grow Your Business
                </Typography>
                <Typography gutterBottom className={classes.LaudemText}>
                  Laudem et dolorem eum fugiat, quo pertineant non emolumento
                  aliquo, sed ut summo.
                </Typography>
                <List dense={true}>
                  <ListItem>
                    <ListItemText
                      primary="Heading One"
                      secondary="Certe, inquam, pertinax non numquam eius modi tempora incidunt, ut aut fugit, sed. At"
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listSecondary,
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listSecondary,
                      }}
                      primary="Heading One"
                      secondary="Certe, inquam, pertinax non numquam eius modi tempora incidunt, ut aut fugit, sed. At"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      classes={{
                        primary: classes.listItemPrimary,
                        secondary: classes.listSecondary,
                      }}
                      primary="Heading One"
                      secondary="Certe, inquam, pertinax non numquam eius modi tempora incidunt, ut aut fugit, sed. At"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid container justify="center" className={classes.benefitForAgenciesContainer}>
              <Grid xs={8} item>
                <Typography
                  align="center"
                  gutterBottom
                  className={classes.TravelBenifits}
                  
                >
                  Traveling Benifits For User
                </Typography>
                <Typography
                  align="center"
                  gutterBottom
                  className={classes.LaudemText}
                  color="textSecondary"
                >
                  At vero eos et aut contra sit, voluptatem ut summum bonum esse
                  albam. At vero eos et fortibus viris commemorandis eorumque
                  factis non possim accommodare torquatos.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={10} item>
            <Grid container justify="space-between" spacing={3}>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={cheapThanOtherIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Cheap than Other
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      YTravels is cheaper than other travel agency.
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={securePaymentIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Secure Payment
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      You can pay your book without doubt again.
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={bestOfferIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Best Offers
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      Inform you about all best offers for all destination
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={easyBookIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Easy Book
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      Follow flow, Click, Pay. Wait. Just wait e-ticket
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={greatAmenitiesIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Great Amenities
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      We guarantee you can give free amenities wherever you are.
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={topRatedIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Top Rated
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      Only show best destination with 1000+ superb review user
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={fastRefundIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      Fast Refund
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      If you canceled. We can refund your money 24x7
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item container justify='center' md={3} xs={12}>
                <div className={classes.OfferItemContainer}>
                  <Box py={3} textAlign="center">
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{
                          display: "inline-block",
                          height: "64px",
                          width: "64px",
                          marginBottom: '20px'
                        }}
                        src={supportIcon}
                      />
                    </div>
                    <Typography
                      align="center"
                      gutterBottom
                      className={classes.groteskBold18}
                      color="textPrimary"
                    >
                      24/7 Support
                    </Typography>
                    <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                      We’re ready help you anytime and anywhere you are.
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}
const AgencyMarketingWithRouter = withRouter(AgencyMarketing);
const AgencyMarketingAlertBox = withAlertBox(AgencyMarketingWithRouter);
const AgencyMarketingLoader = withLoader(AgencyMarketingAlertBox);
const AgencyMarketingToast = withToast(AgencyMarketingLoader);
const AgencyMarketingWithFontStyles = withFontStyles(AgencyMarketingToast);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
      backgroundColor: "black"
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${CustomerlandingPage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(6, 3),
      color: "#fff",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1),
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "100px",
      height: "239px",
      [theme.breakpoints.down("xs")]: {
        height: "220px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
      marginTop: "100px",
      
    },
    helloAdminSubheading: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
    OfferItemContainer: {
      borderRadius: "8px",
      boxShadow: "2px 4px 34px 0 rgba(15, 24, 44, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: '16px',
      width: '263px',
      height: '249px',
    },
    offerImage: {
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: theme.spacing(1),
    },
    carouselImage: {
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: theme.spacing(1),
    },
    carouselCard: {
      padding: theme.spacing(5),
      // borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      width: "284px",
      height: "284px",
    },
    addNewCity: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    inputfieledroot: {
      "label + &": {
        marginTop: 30,
      },
    },
    input: {
      position: "relative",
      backgroundColor: "#FFF",
      border: "none!important",
      height: "35px!important",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#959ead",
      padding: "11.5px 14px",
    },
    astriklabel: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#959ead",
    },
    lableroot: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    outlinedLabel: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    shrink: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    benefitForAgenciesContainer: {
      [theme.breakpoints.up('md')]: {
        marginTop: '225px',
        marginBottom: '40px'
      }
    },
    agentFloatingSmallCards: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 18px',
      height: '52px',
      borderRadius: '8px',
      boxShadow: '0 12px 16px 0 rgba(0, 0, 0, 0.08)',
      backgroundColor: '#ffffff',
    },
    AgencyLogin:{
      backgroundColor: "#fff",
      color: "blue",
      borderRadius: "50px",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1565d8",
      width: "237px",
      height: "48px",
  },
  JoinAgent:{
    color:"#ffff"
 },
 TravelBenifits:{
  color:"#ffff",
  fontSize:"3rem"
 },
 LaudemText:{
  color:"#dfd7d7"
 },
 listSecondary:{
  color:"#dfd7d7"
 },
    listItemPrimary: {
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 'bold',
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffff",
      paddingBottom: '10px',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '-35px',
        width: '20px',
        height: '20px',
        display: 'block',
        backgroundColor: '#36b37e',
        borderRadius: '50%'
    }
    }
  })
)(withDialog(AgencyMarketingWithFontStyles));