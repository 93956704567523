//@ts-nocheck
import React from "react";
import { Popover, Typography, AppBar, Toolbar, Grid,Divider,Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    width : theme.spacing(40)
  },
}));
export default function Seat({
  seatID,
  occupied,
  setSelectedSeat,
  isSelected,
  seatInfo,
}) {
  const appliedClassName = occupied
    ? "occupied"
    : isSelected
    ? "selected"
    : "avail";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 0);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <label
        className="seat"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        //  onClick={handleClick}
      >
        <span
          id={seatID}
          className={appliedClassName}
          onClick={() => {
            if (occupied) {
              return;
            }
            if (isSelected) {
              setSelectedSeat(seatID);
              return;
            }
            setSelectedSeat(seatID);
          }}
        >
          {seatID}
        </span>
      </label>
      <Popover
        id={`mouse-over-popover-${seatID}`}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        style={{
          zIndex: 1400,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{display : 'flex',background : '#1565d8',color : '#fff',justifyContent:'center'}}>
                   <div style={{flexGrow:1}}>
                   <div style={{textAlign : 'center'}}>
                  <Typography display="inline" color="initial" align="center">
                    Seat : {seatInfo.SeatCode}
                  </Typography>
                </div>
                   </div>
                   </div>
          </Grid>
          <Grid item xs={12}>
            <Typography display="inline" variant="h4" color="primary">
              Availablity :
            </Typography>
            <Typography display="inline" variant="h4" color="textPrimary">
              {seatInfo.Availability}
            </Typography>
          </Grid>
          <Box px={1}>
              {seatInfo.Characteristic &&
                  seatInfo.Characteristic.length > 0 && <Grid item xs={12}>
                <Typography variant="h3" color="primary">
                  Characteristic:
                </Typography>
                {seatInfo.Characteristic &&
                  seatInfo.Characteristic.length > 0 &&
                  seatInfo.Characteristic.map((Character) => {
                    return (
                      <Typography color="textPrimary">{Character.Value}</Typography>
                    );
                  })}
              </Grid>
            }
          </Box>
         {/* <Grid item xs={12}>
         
                <div style={{display : 'flex',background : '#1565d8',color : '#fff'}}>
                   <div style={{flexGrow:1}}>
                       <Typography> Paid Seat : Available</Typography>
                       <Typography>After reservation is purchased</Typography>
                   </div>
                   <Typography>
                      100 usd
                   </Typography>
                </div>
              
         </Grid> */}
        </Grid>
      </Popover>
    </>
  );
}
