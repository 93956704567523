import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    tokenName : string; 
    role : string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  selectedSearchType : 'Hotels' | 'Flights',
  selectedTravellingType : string;
  authToken : any;
  sourceSuggestionloading : boolean,
  destinationSuggestionloading : boolean,
  sourcesearchSuggestionList : Array<any>
  destinationsearchSuggestionList : Array<any>,
  fromCity : any;
  toCity : any;
  
  
//   selectedType : String;
//   token : string;
//   selectedTime : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NewSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  verifyAccountApiCallId : String = "";
  getLocationSearchApiCallId : String = ""
  getDestinationLocationSearchApiCallId : String = ""
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
        selectedSearchType : 'Hotels',
        selectedTravellingType : 'oneway',
        authToken : '',
        sourceSuggestionloading : false,
        destinationSuggestionloading : false,
        destinationsearchSuggestionList : [],
        sourcesearchSuggestionList : [],
        fromCity : null,
        toCity : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiEmailCallId) {
        // this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
        }
      } else if (apiRequestCallId === this.getLocationSearchApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              sourceSuggestionloading : false
            },()=>{
              setTimeout(()=>{
                this.setState({
                  sourcesearchSuggestionList : responseJson.data,
                })  
              },100)
              
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      } else if (apiRequestCallId === this.getDestinationLocationSearchApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              destinationsearchSuggestionList : responseJson.data,
              destinationSuggestionloading : false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }
  
  getFromLocationSuggestion = (location : any) => {
    this.setState({
      sourceSuggestionloading : true
    },()=>{
      if(location){
        const headers = {
          // "content-type": configJSON.ApiContentType,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.flightSearch}?search=${location}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })
  }
  getDestinationLocationSuggestion = (location : any) => {
    this.setState({
      destinationSuggestionloading : true
    },()=>{
      if(location){
        const headers = {
          // "content-type": configJSON.ApiContentType,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDestinationLocationSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.flightSearch}?search=${location}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })
  }
}
