//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
  TextField,
  Box,
  DialogActions,
  Tabs,
  Tab,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import { success, arrow } from "./assets";
import SeatMapController, {
  Props,
} from "./SeatMapController.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { DialogTitle } from "../../../components/src/DialogContext";

import InputFiled from "../../../components/src/InputFiled.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import SeatRow from "./SeatRow.web";
import "./styles.css";
import { flighPlaceHolder } from "./assets";


const seatMap = {};
for (let i = 0; i < 17; i++) {
  ["A", "B", "C", "D", "E", "F"].forEach(letter => {
    if (seatMap[i]) {
      seatMap[i][letter] = Math.random() < 0.3;
    } else {
      seatMap[i] = { [letter]: Math.random() < 0.3 };
    }
  });
}




class SeatMap extends SeatMapController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    let tokenKeyName = "";
    if (this.props.dataToPass.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (this.props.dataToPass.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    if (authToken) {
      this.setState({
        authToken: authToken,
        selectedFlightTab: this.props.dataToPass.selectedFlight?.tabName,
        selectedFlight: this.props.dataToPass.selectedFlight,
        selectedTraveller: this.props.dataToPass.selectedFlight.travellerInfo.length > 0 ? this.props.dataToPass.selectedFlight.travellerInfo[0] : {},
        flights: this.props.dataToPass.flights
      }, () => {
        const seatTravellInfo = []
        if (this.state?.selectedFlight?.selectedSeat && this.state?.selectedFlight?.selectedSeat?.length > 0) {
          for (var i = 0; i < this.state.selectedFlight?.selectedSeat.length; i++) {
            var selectedSeat = this.state.selectedFlight?.selectedSeat[i];
            const travellerInfo = this.props.dataToPass.selectedFlight.travellerInfo.find((traveller) => { return traveller.key === selectedSeat.BookingTravelerRef })
            seatTravellInfo.push({ ...travellerInfo, ...selectedSeat, selectedSeat: selectedSeat.SeatId })
          }
          this.setState({
            selectedFlight: { ...this.state.selectedFlight, travellerInfo: seatTravellInfo }
          })
        }

        // this.selecttravller([...this.state.selectedFlight?.travellerInfo][0],0);
      })
    }
  }
  goToSelectedRoute = (routeName) => {
    this.props.onCancel();
  };
  confirmSeat = () => {
    const selectedSeats = this.state.selectedFlight?.travellerInfo?.map((traveller) => { return traveller?.selectedSeat != null && traveller?.selectedSeat != undefined && traveller?.selectedSeat != '' });
    if (this.state.selectedFlight?.travellerInfo?.length === selectedSeats?.filter((val)=> val === true)?.length) {
      this.props.onSubmit(this.state.flights);
    } else {
      this.props.dataToPass.openToast({type:'error',message : "please select seat for all passenger!"})
    }
  }
  handleSeatSelection = (seatID) => {
    const selectedTravellers = [...this.state.selectedFlight?.travellerInfo];
    const selectedTravellerIndex = selectedTravellers.findIndex((temp) => { return temp.key === this.state.selectedTraveller.key });
    if (selectedTravellers[selectedTravellerIndex]?.selectedSeat && selectedTravellers[selectedTravellerIndex]?.selectedSeat === seatID) {
      this.setState(
        {
          selectedTraveller: { ...this.state?.selectedTraveller, selectedSeat: null }
        }, () => {
          const selectedTravellers = [...this.state.selectedFlight?.travellerInfo]
          selectedTravellers[selectedTravellerIndex] = this.state?.selectedTraveller
          this.setState(
            {
              selectedFlight: { ...this.state.selectedFlight, travellerInfo: selectedTravellers }
            }, () => {
              const flightIndex = this.state.flights.findIndex((flight) => { return flight.Flight_Id === this.state?.selectedFlight?.Flight_Id })
              const flights = [...this.state.flights];
              flights[flightIndex] = { ...this.state.selectedFlight };
              this.setState({
                flights: flights
              })
            }
          );
        });
    } else {
      const selectedSeats = this.state.selectedFlight?.travellerInfo?.map((traveller) => { return traveller?.selectedSeat && traveller?.selectedSeat })
      if (selectedSeats?.includes(seatID)) {
        this.props.dataToPass.openToast({type:'error',message : "please select different seat. it's already selected!"})
      } else {
        this.setState(
          {
            selectedTraveller: { ...this.state?.selectedTraveller, selectedSeat: seatID }
          }, () => {
            const selectedTravellers = [...this.state.selectedFlight?.travellerInfo];
            selectedTravellers[selectedTravellerIndex] = this.state?.selectedTraveller;
            this.setState(
              {
                selectedFlight: { ...this.state.selectedFlight, travellerInfo: selectedTravellers }
              }, () => {
                const flightIndex = this.state.flights.findIndex((flight) => { return flight.Flight_Id === this.state?.selectedFlight?.Flight_Id })
                const flights = [...this.state.flights];
                flights[flightIndex] = { ...this.state.selectedFlight };
                this.setState({
                  flights: flights
                })
              }
            );
          });

      }
    }
  }
  selecttravller = (traveller, index) => {
    // let travellerInfo = [...this.state.selectedFlight?.travellerInfo]
    // travellerInfo[index] = travellerInfo[index] ? travellerInfo[index] : traveller
    this.setState({
      // selectedFlight: {...this.state.selectedFlight,travellerInfo : travellerInfo},
      selectedTraveller: traveller
    }, () => {
      const flightIndex = this.state.flights.findIndex((flight) => { return flight.Flight_Id === this.state?.selectedFlight?.Flight_Id })
      const flights = [...this.state.flights];
      flights[flightIndex] = { ...this.state.selectedFlight };
      this.setState({
        flights: flights
      })
    })
  }


  render() {
    const { classes } = this.props;
    const selectedSeats = this.state.selectedFlight?.travellerInfo?.map((traveller) => { return traveller?.selectedSeat && traveller?.selectedSeat });
    return (
      <>
        <DialogTitle id="simple-dialog-title" className={classes.seatMapTitle} onCancel={this.props.onCancel}>Seat Selection</DialogTitle>
        <Grid md={12} xs={12} item>
          <Divider className={classes.dividerStyle} />
        </Grid>
        <DialogContent className={classes.dialogStyles}>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12}>
              <Tabs
                value={this.state.selectedFlightTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
                onChange={(evt, value) => {
                  this.setState(
                    {
                      selectedFlightTab: value,
                    }, () => {
                      const selectedFlight = this.state?.flights.find((flight) => { return flight.tabName === this.state.selectedFlightTab });
                      this.setState({
                        selectedFlight: selectedFlight,
                        selectedTraveller: selectedFlight.travellerInfo.length > 0 ? selectedFlight.travellerInfo[0] : {}
                      }, () => {
                        if (!(this.state.selectedFlight.seatData.length > 0)) {
                          this.getSeatData(this.state.selectedFlight);
                        }
                      })
                    }
                  );
                }}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {this.state.flights.map((flight: any) => {
                  return <Tab
                    key={flight?.tabName}
                    label={flight.tabName}
                    value={flight.tabName}
                  />
                })}

              </Tabs>
            </Grid>
            <Grid item lg={3} md={3} sm={12}>
              <Grid container spacing={2}>
                {
                  this.state.selectedFlight && this.state.selectedFlight?.travellerInfo?.map((traveller, index) => {
                    return <>
                      {traveller?.type != 'INF' && <Grid item xs={12} key={traveller.key}>
                        <Box className={this.state.selectedTraveller.key === traveller.key ? (traveller.selectedSeat ? classes.travellersSeatSelected : classes.travellersSelected) : classes.travellers}
                          onClick={() => {
                            this.selecttravller(traveller, index)
                          }}>
                          <Typography>{`${traveller.first_name} ${traveller.last_name}`} ({traveller?.type})</Typography>
                          {traveller.selectedSeat && <Typography variant="h5">{traveller.selectedSeat}</Typography>}
                        </Box>
                      </Grid>}
                    </>
                  })
                }
              </Grid>
            </Grid>
            <Grid item lg={9} md={9} sm={12}>
              {this.state?.selectedFlight?.seatData.length > 0 ? <>

                <div className="App">
                  {/* <div className="plane-map-header">
                <h1>Miami (MIA) to Havana (HAV)</h1>
                <h2>Select your preferred seat</h2>
                </div> */}
                  <div className="plane">
                    <div className="cockpit" />
                    <div className="exit exit--front fuselage" />
                    {(
                      this.state?.selectedFlight?.seatData?.map((row, index) => {
                        const Facility = row.Facility.filter((seat) => { return seat.Type !== 'Aisle' })
                        return <SeatRow
                          key={row.Number}
                          rowNum={row.Number}
                          rowMap={Facility}
                          setSelectedSeat={(data) => {
                            this.handleSeatSelection(data)
                          }}
                          selectedSeat={selectedSeats}
                        />
                      })
                    )}
                    <div className="exit exit--front fuselage" />
                  </div>
                </div>
              </> : <Box style={{ textAlign: 'center' }}>No Seat Map Available</Box>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.seatMapFooter}>
          <Grid container justify="flex-end">
            <Button onClick={this.props.onCancel} className={classes.seatMapCancel} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button onClick={() => { this.confirmSeat() }} className={classes.seatMapConfirm} variant="contained" color="primary">
              Confirm
            </Button>
          </Grid>
        </DialogActions>
      </>
    );
  }
}
const SeatMapAlertBox = withAlertBox(SeatMap);
const SeatMapLoader = withLoader(SeatMapAlertBox);
const SeatMapToast = withToast(SeatMapLoader);
const SeatMapWithRouter = withRouter(SeatMapToast);

export default withStyles((theme) =>
  createStyles({
    confirmationText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.15,
      color: "#1b4050",
    },
    confirmationSubTitle: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: 1.19,
      color: "#1b4050",
    },
    boxLarge: {
      borderRadius: "6px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: "#ffffff",
      border: "solid 1px #e5eaf4",
    },
    checkBoxLabel: {
      fontSize: "12px",
      fontFamily: "Open Sans",
    },
    dialogStyles: {
      padding: "0px 32px 20px 32px",
    },
    flightImage: {
      width: theme.spacing(7),
      height: theme.spacing(6.5),
    },
    travellers: {
      borderRadius: "6px",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1),
      cursor: "pointer",
      textAlign: 'center',
    },
    travellersSelected: {
      borderRadius: "6px",
      boxShadow: "0 20px 24px 0 rgba(0, 0, 0, 0.08)",
      border: "solid 1px #1565d8",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1),
      cursor: "pointer",
      textAlign: 'center',
    },
    travellersSeatSelected: {
      borderRadius: "6px",
      boxShadow: "0 20px 24px 0 rgba(0, 0, 0, 0.08)",
      border: "solid 1px #1565d8",
      backgroundColor: "#1565d8",
      color: '#fff',
      padding: theme.spacing(1),
      cursor: "pointer",
      textAlign: 'center',
    },
    seatMapTitle: {
      marginBottom: "0px",
    },
    seatMapFooter: {
      padding: "0px 30px 20px 20px",
    },
    seatMapCancel: {
      width: "120px",
    },
    seatMapConfirm: {
      marginLeft: "10px",
      width: "120px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9",
      margin: "0px 20px 0px 20px",
    },
  })
)(SeatMapWithRouter);
