
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";

import CustomerLogin from "./Login.web";
import CustomerSignup from "./SignUp.web";
import ForgotPassword from "./ForgotPassword.web";
import EnterOTP from "./EnterOTP.web";
import ResetPassword from "./ResetPassword.web";
import SignUpVerifyLinkDialog from "./SignUpVerifyLinkDialog.web";
import AdminLogin from "./AdminLogin.web";

import { notification, userSuccess } from "./assets";
import NotificationPopUpWeb from "./NotificationPopUp.web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    role: string
    setToken: Function
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken: any;
  token: any;
  //   selectedType : String;
  //   token : string;
  //   selectedTime : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  verifyAccountApiCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken: '',
      token: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.verifyAccountApiCallId) {
        this.props.hideLoader();
        if (this.props.role === 'Admin') {
          if (responseJson && responseJson.messages === 'account activated successfully') {
            this.openVerifyAccount();
          } else {
            // this.openVerifyAccount();
            this.props.showAlert({
              title: "Alert",
              message: responseJson.messages,
            });
          }
        } else {
          if (responseJson && responseJson?.data?.attributes.activated && responseJson?.meta?.message) {
            this.openVerifyAccount();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
    }
  }
  openLogin = () => {
    this.props
      .openDialogBox({
        dataToPass: { role: this.props.role },
        renderedComponent: CustomerLogin,
        title: "Login",
        withCustomDialog: true,


      })
      .then(async (type: any) => {
        if (type === "signUp") {
          this.openSignUp();
        } else if (type === "forgotPassword") {
          this.openForgotPassword();
        } else if (type === "loginSuccessFull") {
          this.props.openDialogBox({
            dataToPass: { title: "Logged In Successfully!", message: "You have been successfully logged in to Doolwa.com", image: userSuccess },
            renderedComponent: NotificationPopUpWeb,
            title: "Alert",
            withCustomDialog: true,
            width: '30%'
          })
          // this.props.showToast({
          //   type: "success",
          //   message: "Logged in successfully.",
          // });
          const authToken = await StorageProvider.get("authToken");
          this.setState({
            authToken: authToken
          }, () => {
            this.props.setToken && this.props.setToken(this.state.authToken)
          })
        }
      });
  };
  openAdminLogin = () => {
    this.props
      .openDialogBox({
        dataToPass: { role: 'Admin' },
        renderedComponent: AdminLogin,
        title: "Login",
        withCustomDialog: true,
        disableBackdropClick: true,
        disableEscapeKeyDown: true
      })
      .then(async (type: any) => {
        if (type === "loginSuccessFull") {
          this.props.showToast({
            type: "success",
            message: "Logged in successfully.",
          });
          this.props.history.push("/AdminDashboard/Allagenciesandsubagencies")
        }
      });
  };
  openAgencyLogin = () => {
    this.props
      .openDialogBox({
        dataToPass: { role: 'Agency' },
        renderedComponent: CustomerLogin,
        title: "Login",
        withCustomDialog: true,
      })
      .then(async (type: any) => {
        if (type === "signUp") {
          this.openSignUp();
        } else if (type === "forgotPassword") {
          this.openForgotPassword();
        } else if (type === "loginSuccessFull") {
          this.props.openDialogBox({
            dataToPass: { title: "Alert", Message: "Login SuccessFull" },
            renderedComponent: NotificationPopUpWeb,
            title: "Alert",
            withCustomDialog: true,
            width: '30%'
          })
          // this.props.showToast({
          //   type: "success",
          //   message: "Logged in successfully.",
          // });
          const authToken = await StorageProvider.get("agancyToken");
          this.setState({
            authToken: authToken
          }, () => {
            this.props.history.push("/AgancyDashborad")
          })
        }
      });
  };
  openSignUp = () => {
    this.props
      .openDialogBox({
        dataToPass: { role: this.props.role },
        renderedComponent: CustomerSignup,
        title: "Sign Up",
        withCustomDialog: true,
      })
      .then((type: any) => {
        if (type === "signIN") {
          this.openLogin();
        } else if (type === "signUpSuccessFull") {
          this.openPopup()

          // this.props.showToast({
          //   type: "success",
          //   message: "Verification link sent successfully.",
          // });
        }
      });
  };
  openForgotPassword = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: ForgotPassword,
        title: "Forgot Password",
        withCustomDialog: true,
      })
      .then((data: any) => {
        if (data?.type === "forgotSuccessfull") {
          this.openEnterOtp(data?.email);
          // this.props.openDialogBox({
          //   dataToPass: { image:userSuccess, title:"Alert",message:"OTP sent to your email successfully." },
          //   renderedComponent: NotificationPopUpWeb,
          //   title: "Alert",
          //   withCustomDialog: true,
          //   width:'30%'
          // })
          this.props.showToast({
            type: "success",
            message: "OTP sent to your email successfully.",
          });
        } else if (data?.type === "signIN") {
          this.openLogin();
        }
      });
  };
  openEnterOtp = (email: any) => {
    this.props
      .openDialogBox({
        dataToPass: { email: email },
        renderedComponent: EnterOTP,
        title: "Enter OTP",
        withCustomDialog: true,
      })
      .then((type: any) => {
        if (type === "resendOtp") {
          this.openEnterOtp(email);
          this.props.showToast({
            type: "success",
            message: "OTP sent to your email successfully.",
          });
        } else if (type === "enterOtpSuccessfull") {
          this.openResetPassword(email);
          this.props.showToast({
            type: "success",
            message: "OTP Entered successfully.",
          });
        } else if (type === "signIN") {
          this.openLogin();
        }
      });
  };
  openResetPassword = (email: any) => {
    this.props
      .openDialogBox({
        dataToPass: { email: email },
        renderedComponent: ResetPassword,
        title: "Reset Password",
        withCustomDialog: true,
      })
      .then((type: any) => {
        if (type === "signUp") {
          this.openSignUp();
        } else if (type === "forgotPassword") {
          this.openForgotPassword();
        } else if (type === "changedPasswordSuccessfully") {
          this.props.showToast({
            type: "success",
            message: "Password changed successfully.",
          });
          this.openLogin();
        }
      });
  };
  openVerifyAccount = () => {
    this.props
      .openDialogBox({
        dataToPass: { role: this.props.role },
        renderedComponent: SignUpVerifyLinkDialog,
        title: "Verify Accout",
        withCustomDialog: true,
        width: '560px'
      })
      .then((data: any) => {
        if (data?.type === "verified") {
          this.props.history.push('/completeProfile?token=' + this.state.token);
        } else if (data?.type === 'signIN') {
          this.openLogin();
        }
      });
  }

  openPopup = () => {
    this.props
      .openDialogBox({
        dataToPass: { image: notification, title: "Alert", message: " User verification link has been sent to your mail, Please activate your account." },
        renderedComponent: NotificationPopUpWeb,
        title: "Alert",
        withCustomDialog: true,
        width: '30%'
      })
  }
  verifyAccount = (token: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/email_confirmations?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}