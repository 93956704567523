//@ts-nocheck
import React from "react";
import ResetPasswordController, {
  Props,
} from "./ResetPasswordController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  DialogContent,
  Divider,
  Avatar
} from "@material-ui/core";
import { bell, forgotBackground, forgotLogo, loading, signUPPoster } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";



class NotificationPopUp extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;

    return (<>
      <div className={classes.icon}>
        <img src={this.props?.dataToPass?.image} className={classes.iconImg} />
      </div>
      <DialogContent style={{ padding: "0px", overflow: "hidden" }}>

        <Grid container alignItems="center" justify="center" spacing={3} className={classes.dailogGrid}>
          <Grid item xs={12} >
            <Typography className={classes.AlertText}>
              {this.props?.dataToPass?.title}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography className={classes.messageText}>
              {this.props?.dataToPass?.message}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth color="primary" variant="contained"
              onClick={() => { this.props.onCancel() }}
            >Ok</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
    );
  }
}

const NotificationPopUpWithRouter = withRouter(NotificationPopUp);
const NotificationPopUpWithToast = withToast(NotificationPopUpWithRouter);
const NotificationPopUpWithLoader = withLoader(NotificationPopUpWithToast);
const NotificationPopUpWithAlertBox = withAlertBox(NotificationPopUpWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
      position: 'relative',
    },
    dailogGrid: {
      padding: '2rem',
    },
    icon: {
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      padding: "1rem",
      borderRadius: "50%"
    },
    iconImg: {
      objectFit: 'none !important'
    },
    messageText: {
      opacity: 1,
      color: "rgba(96, 108, 120, 1)",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "center",
    },
    AlertText: {
      opacity: 1,
      color: "rgba(30,57,78,1)",
      fontFamily: 'Public Sans',
      fontSize: "20px",
      fontWeight: 700,
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "center"
    }

  })
)(NotificationPopUpWithAlertBox);
