//@ts-nocheck


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  todayAndTotalBookingsData: any;
  bookedAirlines: any;
  bookingStartDate: any;
  bookingEndDate: any;
  bookingAirline: any;
  cancellationStartDate: any;
  cancellationEndDate: any;
  totalMonthlyBookings: any;
  totalCancelledBookings: any;
  cancelledAirlineInput: any;
  customerReports: any;
  showBarChart : boolean;
  showLineChart : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class agancyReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTodayAndTotalBookingsApiCallId: String = "";
  getBookedAirlinesApiCallId: String = "";
  getTotalMonthlyBookingsApiCallId: String = "";
  getCancelledBookingsApiCallId: String = "";
  getAgencyReportsApiCallId: String = "";
  getCustomerReportsApiCallId: String = "";
  lineChart = null;
  barChart = null;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      todayAndTotalBookingsData: {},
      bookedAirlines: {},
      bookingStartDate: null,
      bookingEndDate: null,
      bookingAirline: null,
      cancellationStartDate: null,
      cancellationEndDate: null,
      totalMonthlyBookings: {},
      totalCancelledBookings: {},
      cancelledAirlineInput: '',
      customerReports: {},
      showBarChart : true,
      showLineChart : true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getTodayAndTotalBookingsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.success) {
          this.setState({ todayAndTotalBookingsData: responseJson });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getBookedAirlinesApiCallId) {
        this.props.hideLoader();
        if (responseJson) {
          this.setState({ bookedAirlines: responseJson });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getTotalMonthlyBookingsApiCallId) {
        this.props.hideLoader();
        if (responseJson) {
          this.setState({ totalMonthlyBookings: responseJson }, () => {
            let label = []
            let values = []
            if(this.props.role === 'Agency') {
              this.state.totalMonthlyBookings?.agency_stats?.total_monthly_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            } else if(this.state.role === 'Admin') {
              this.state.totalMonthlyBookings?.agencies_and_sub_agencies_stats?.total_monthly_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            } else if(this.state.role === 'SubAgencies') {
              this.state.totalMonthlyBookings?.agency_stats?.sub_agencies_stats?.total_monthly_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            } else if(this.state.role === 'SubAgency') {
              this.state.totalMonthlyBookings?.sub_agency_stats?.total_monthly_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            }
            if(label.length > 0){
              this.setState({
                showBarChart : true
              },()=>{
                if(!this.barChart){
                  this.createBarChart(label, values);
                } else {
                  this.updateBarChart(label, values)
                }
              })
            } else {
              this.setState({
                showBarChart : false
              })
            }
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getCancelledBookingsApiCallId) {
        this.props.hideLoader();
        if (responseJson) {
          this.setState({ totalCancelledBookings: responseJson }, () => {
            let label = []
            let values = []
            if(this.props.role === 'Agency') {
              this.state.totalCancelledBookings?.agency_stats?.cancelled_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            } else if(this.state.role === 'Admin') {
              this.state.totalCancelledBookings?.agencies_and_sub_agencies_stats?.cancelled_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            } else if(this.state.role === 'SubAgencies') {
              this.state.totalCancelledBookings?.agency_stats?.sub_agencies_stats?.cancelled_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            } else if(this.state.role === 'SubAgency') {
              this.state.totalCancelledBookings?.sub_agency_stats?.cancelled_flight_bookings.forEach((item)=>{
                label.push(Object.keys(item)[0])
                values.push(Object.values(item)[0])
              })
            }
            
            if(label.length > 0){
              this.setState({
                showLineChart : true
              },()=>{
                if(!this.lineChart){
                  this.createLineChart(label, values)
                } else {
                  this.updateLineChart(label, values)
                }
              })
            } else {
              this.setState({
                showLineChart : false
              })
            }
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getCustomerReportsApiCallId) {
        this.props.hideLoader();
        if (responseJson) {
          this.setState({ customerReports: responseJson }, () => {
              let barLabel = []
              let barValues = []
              this.state.customerReports?.customers_stats?.total_monthly_flight_bookings.forEach((item)=>{
                barLabel.push(Object.keys(item)[0])
                barValues.push(Object.values(item)[0])
              })
              let lineLabel = []
              let lineValues = []
              this.state.customerReports?.customers_stats?.cancelled_flight_bookings.forEach((item)=>{
                lineLabel.push(Object.keys(item)[0])
                lineValues.push(Object.values(item)[0])
              })
              if(barLabel.length > 0){
                this.setState({
                  showBarChart : true
                },()=>{
                  if(!this.barChart){
                    this.createBarChart(barLabel, barValues);
                  } else {
                    this.updateBarChart(barLabel, barValues)
                  }
                })
              } else {
                this.setState({
                  showBarChart : false
                })
              }
              if(lineLabel.length > 0){
                this.setState({
                  showLineChart : true
                },()=>{
                  if(!this.lineChart){
                    this.createLineChart(lineLabel, lineValues)
                  } else {
                    this.updateLineChart(lineLabel, lineValues)
                  }
                })
              } else {
                this.setState({
                  showLineChart : false
                })
              }  
              
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }

  createBarChart(label:any, values:any) {
    const data = {
      labels: label,
      datasets: [
        {
          label: "Flight",
          backgroundColor: "blue",
          data: values,
          borderWidth: 1,
        },
      ],
    };
    const barConfig = {
      type: "bar",
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };
    this.barChart = new Chart(document.getElementById("barChart"), barConfig);
  }

  createLineChart(label:any, values:any) {
    const data = {
      labels: label,
      datasets: [
        {
          label: "Flight",
          backgroundColor: "blue",
          data: values,
          borderWidth: 1,
        },
      ],
    };
    const lineConfig = {
      type: "line",
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };
    this.lineChart = new Chart(
      document.getElementById("lineChart"),
      lineConfig
    );
  }
  updateBarChart(label:any, values:any) {
    const data = {
      labels: label,
      datasets: [
        {
          label: "Flight",
          backgroundColor: "blue",
          data: values,
          borderWidth: 1,
        },
      ],
    };
    this.barChart.data = data;
    this.barChart.update();
  }
  updateLineChart = (labels:any, values:any) => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Flight",
          backgroundColor: "blue",
          data: values,
          borderWidth: 1,
        },
      ],
    };
    this.lineChart.data = data;
    this.lineChart.update();
  }

  getTodayAndTotalBookings = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let url = configJSON.getTodayAndTotalBookingsEndPoint;
    this.getTodayAndTotalBookingsApiCallId = requestMessage.messageId;
    if(this.props.accountId || this.state.accountId){
      url += url.indexOf("?") > -1 ? `&account_id=${this.props.accountId || this.state.accountId}` : `?account_id=${this.props.accountId || this.state.accountId}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBookedAirlines = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let url = configJSON.getBookedAirlinesEndPoint;
    this.getBookedAirlinesApiCallId = requestMessage.messageId;
    if(this.props.accountId || this.state.accountId){
      url += url.indexOf("?") > -1 ? `&account_id=${this.props.accountId || this.state.accountId}` : `?account_id=${this.props.accountId || this.state.accountId}`
    } 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTotalMonthlyBookings = (startDate,endDate,airlineName) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTotalMonthlyBookingsApiCallId = requestMessage.messageId;
    let url = configJSON.getTotalMonthlyBookingsEndPoint;
    if(startDate){
      url += url.indexOf("?") > -1 ? `&search_filters[from]=${startDate}` : `?search_filters[from]=${startDate}`
    }
    if(endDate){
      url += url.indexOf("?") > -1 ? `&search_filters[to]=${endDate}` : `?search_filters[to]=${endDate}`
    }
    if(airlineName){
      url += url.indexOf("?") > -1 ? `&search_filters[airline]=${airlineName}` : `?search_filters[airline]=${airlineName}`
    }
    if(this.props.accountId || this.state.accountId){
      url += url.indexOf("?") > -1 ? `&account_id=${this.props.accountId || this.state.accountId}` : `?account_id=${this.props.accountId || this.state.accountId}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCancelledBookings = (startDate,endDate,airlineName) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCancelledBookingsApiCallId = requestMessage.messageId;
    let url = configJSON.getCancelledBookingsEndPoint;
    if(startDate){
      url += url.indexOf("?") > -1 ? `&search_filters[from]=${startDate}` : `?search_filters[from]=${startDate}`
    }
    if(endDate){
      url += url.indexOf("?") > -1 ? `&search_filters[to]=${endDate}` : `?search_filters[to]=${endDate}`
    }
    if(airlineName){
      url += url.indexOf("?") > -1 ? `&search_filters[airline]=${airlineName}` : `?search_filters[airline]=${airlineName}`
    }
    if(this.props.accountId || this.state.accountId){
      url += url.indexOf("?") > -1 ? `&account_id=${this.props.accountId || this.state.accountId}` : `?account_id=${this.props.accountId || this.state.accountId}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomerReports = (startDate,endDate,airlineName) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerReportsApiCallId = requestMessage.messageId;
    let url = configJSON.getCustomerReportsEndPoint;
    if(startDate){
      url += url.indexOf("?") > -1 ? `&search_filters[from]=${startDate}` : `?search_filters[from]=${startDate}`
    }
    if(endDate){
      url += url.indexOf("?") > -1 ? `&search_filters[to]=${endDate}` : `?search_filters[to]=${endDate}`
    }
    if(airlineName){
      url += url.indexOf("?") > -1 ? `&search_filters[airline]=${airlineName}` : `?search_filters[airline]=${airlineName}`
    }
    if(this.props.accountId){
      url += url.indexOf("?") > -1 ? `&account_id=${this.props.accountId}` : `?account_id=${this.props.accountId}`
    } 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
