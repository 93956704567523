import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import SeatMap from "./SeatMap.web";
import { notification, userSuccess } from "./assets";
import NotificationPopUpWeb from "./NotificationPopUp.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
{
  id: string;
  // Customizable Area Start
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  authToken: any,
  selectedInsurance: Array<any>,
  selectedUpgrades: Array<any>,
  searchData: any,
  entityDetail: any,
  fromCity: any,
  toCity: any,
  entityType: any,
  booking_id: string,
  travaller_id: string,
  loading: boolean,
  role: string
  selectedTravellingType: string
  selectedFlight2: any,
  fareSummaryDetail: any,
  profileData: any;
  fareSummaryLst: Array<any>,
  currency: string
  travellerInfo: any,
  seatData: any,
  seatDataReturnFlight: any,
  paymentModeValue: any;
  yourMarkupAmountInput: any;
  remarkToAirlineInput: any;
  remarkToItineraryInput: any;
  seletedSeats: any[],
  oneWayFlightList: Array<any>,
  AirPriceInfo: any,
  couponDiscount: any,
  twoWayFlightList: Array<any>,
  selectedFlight: any
  paymentStatus: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddAddonsController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateTravellApiCallId: String = "";
  getflightDetailApiCallId: String = "";
  completeBookingAPICallId: string = "";
  getreturnflightDetailApiCallId: String = "";
  faresummaryApiCallId: string = "";
  bookSummaryApiCallId: string = "";
  getSeatDataApiCallId: String = "";
  getSeatDataReturnFlightApiCallId: String = "";
  getProfileDetailApiCallID: string = "";
  makePaymentApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken: '',
      selectedInsurance: [],
      selectedUpgrades: [],
      searchData: {},
      entityDetail: {},
      fromCity: {},
      toCity: {},
      entityType: {},
      booking_id: "",
      travaller_id: "",
      loading: true,
      role: '',
      selectedTravellingType: "",
      selectedFlight2: {},
      fareSummaryDetail: {},
      fareSummaryLst: [],
      currency: 'USD',
      profileData: {},
      seatData: [],
      seatDataReturnFlight: [],
      travellerInfo: {},
      paymentModeValue: 'payByCredits',
      yourMarkupAmountInput: '',
      remarkToAirlineInput: '',
      remarkToItineraryInput: '',
      seletedSeats: [],
      oneWayFlightList: [],
      AirPriceInfo: {},
      couponDiscount: null,
      twoWayFlightList: [],
      selectedFlight: null,
      paymentStatus: [],

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getreturnflightDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.length > 0) {
          this.setState({
            selectedFlight2: {
              ...this.state.selectedFlight2,
              ...responseJson?.data?.attributes[0],
            },
          });
        }
      } else if (apiRequestCallId === this.faresummaryApiCallId) {
        this.props.hideLoader();
        if (responseJson?.AirPriceInfo) {

          const dataAirPrice = responseJson;
          const fareSummaryLst = [];
          let isAdult = false;
          for (let key in dataAirPrice?.AirPriceInfo) {
            const AirPriceInfo = dataAirPrice?.AirPriceInfo[key]
            const temp = {
              type: '',
              count: 0,
              toalPrice: 0,
              basePrice: 0,
              name: '',
            };

            if (AirPriceInfo.type === 'ADT' && dataAirPrice.adult) {
              temp.name = "Adults"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.adult;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.adult;
              temp.basePrice = AirPriceInfo.base_rate;
              isAdult = true
            } else if (AirPriceInfo.type === 'INF' && dataAirPrice.infents) {
              temp.name = "Infants"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.infents;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.infents;
              temp.basePrice = AirPriceInfo.base_rate;
            } else if (AirPriceInfo.type === 'CNN' && dataAirPrice.child) {
              temp.name = "Children"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.child;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.child;
              temp.basePrice = AirPriceInfo.base_rate;
            }
            fareSummaryLst.push(temp)
          }
          const returnFlights: any = [];
          const departureFlights: any = []
          if (this.state.selectedTravellingType === 'twoway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight: any) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
              const rf = this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (rf) {
                returnFlights.push({ ...rf, ...flight });
              }
            })
          } else if (this.state.selectedTravellingType === 'oneway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight: any) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
            })
          }
          this.setState({
            fareSummaryDetail: dataAirPrice,
            AirPriceInfo: dataAirPrice?.AirPriceInfo,
            fareSummaryLst: fareSummaryLst,
          }, () => {
            if (this.state.selectedTravellingType === 'twoway') {
              this.setState({
                oneWayFlightList: departureFlights,
                twoWayFlightList: returnFlights,
              }, () => {
                if (this.state?.couponDiscount) {
                  this.setState({
                    fareSummaryDetail: {
                      ...this.state.fareSummaryDetail, total_price: (Number(this.state?.couponDiscount?.after_discount_price))
                    }
                  })
                }
              })
            } else if (this.state.selectedTravellingType === 'oneway') {
              this.setState({
                oneWayFlightList: departureFlights
              }, () => {
                if (this.state?.couponDiscount) {
                  this.setState({
                    fareSummaryDetail: {
                      ...this.state.fareSummaryDetail, total_price: (Number(this.state?.couponDiscount?.after_discount_price))
                    }
                  })
                }
              })
            }
          })

        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.updateTravellApiCallId) {
        this.props.hideLoader();
        if (responseJson?.booking_id) {
          this.props.showToast({ type: 'success', message: 'Adons Added succssfully' })
          this.completeBooking();
        }
      } else if (apiRequestCallId === this.getflightDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.length > 0) {
          this.setState({
            entityDetail: { ...this.state.entityDetail, ...responseJson?.data?.attributes[0] }
          }, () => {
          })
        }
      } else if (apiRequestCallId === this.completeBookingAPICallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.status) {
          if (this.state.selectedTravellingType === 'oneway') {
            this.bookFlight(this.state.searchData, this.state.entityDetail?.mergeAirSagmentBookingFlightInfo, this.state.entityDetail?.FareBasisCode);
          }
          else if (this.state.selectedTravellingType === 'twoway') {
            this.bookFlight(this.state.searchData, [...this.state.entityDetail?.mergeAirSagmentBookingFlightInfo, ...this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo], this.state.entityDetail?.FareBasisCode);
          }
        }
      } else if (apiRequestCallId === this.bookSummaryApiCallId) {
        this.props.hideLoader();
        if (responseJson?.status === 'booked') {
          this.props.showToast({
            type: 'success',
            message: 'Booking created successfully'
          })
          const returnFlights: any = [];
          const departureFlights: any = []
          if (this.state.selectedTravellingType === 'twoway') {
            const Flight_Details = responseJson?.flight?.data?.attributes?.payment?.data?.attributes?.note?.Flight_Details || [];
            Flight_Details.forEach((flight: any) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
              const rf = this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (rf) {
                returnFlights.push({ ...rf, ...flight });
              }
            })
          } else if (this.state.selectedTravellingType === 'oneway') {
            const Flight_Details = responseJson?.flight?.data?.attributes?.payment?.data?.attributes?.note?.Flight_Details || [];
            Flight_Details.forEach((flight: any) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
            })
          }
          if (this.state.selectedTravellingType === 'twoway') {
            this.setState({
              oneWayFlightList: departureFlights,
              twoWayFlightList: returnFlights,
            }, () => {
              if (this.state?.couponDiscount) {
                this.setState({
                  fareSummaryDetail: {
                    ...this.state.fareSummaryDetail, total_price: (Number(this.state?.couponDiscount?.after_discount_price))
                  }
                })
              }
            })
          } else if (this.state.selectedTravellingType === 'oneway') {
            this.setState({
              oneWayFlightList: departureFlights
            }, () => {
              if (this.state?.couponDiscount) {
                this.setState({
                  fareSummaryDetail: {
                    ...this.state.fareSummaryDetail, total_price: (Number(this.state?.couponDiscount?.after_discount_price))
                  }
                })
              }
            })
          }
          this.props.history.push({
            pathname: '/paymentSuccessfull',
            state: {
              searchData: { ...this.state.searchData },
              entityDetail: { ...this.state.entityDetail },
              fromCity: this.state.fromCity,
              toCity: this.state.toCity,
              entityType: this.state.entityType,
              booking_id: responseJson.flight.data.attributes.id,
              role: this.state.role,
              locator_code: responseJson.locator_code,
              travaller_id: responseJson.flight.data.attributes?.travel_detail_id,
              paymentStatus: responseJson?.flight?.data?.attributes?.payment?.data?.attributes?.amount,
              oneWayFlightList: this.state?.oneWayFlightList,
              twoWayFlightList: this.state?.twoWayFlightList,
              selectedFlight2: this.state?.selectedFlight2,
              selectedTravellingType: this.state?.selectedTravellingType,
            }
          })
        } else {
          this.props.openDialogBox({
            dataToPass: { title: "Alert!", message: responseJson.message, image: notification },
            renderedComponent: NotificationPopUpWeb,
            title: "Alert",
            withCustomDialog: true,
            width: '30%'
          })
        }
      } else if (apiRequestCallId === this.getSeatDataApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes && responseJson?.data?.attributes?.seats && responseJson?.data?.attributes?.seats.length > 0) {
          const data = responseJson?.data;
          for (let i = 0; i < data.attributes.seats.length; i++) {
            const row = data.attributes.seats[i];
            const Facility = row.Facility?.filter((seat: any) => { return seat.Type !== 'Aisle' })
            for (let j = 0; j < Facility.length; j++) {
              const OptionalServices = data.attributes?.optional_services?.OptionalService || [];
              const OptionalService = OptionalServices.find((serive: any) => {
                if (Facility[j]?.OptionalServiceRef && serive?.Key) {
                  return serive?.Key === Facility[j]?.OptionalServiceRef
                }
                else
                  return false
              })
              if (OptionalService)
                data.attributes.seats[i].Facility[j].OptionalServices = OptionalService
            }
          }
          this.setState({
            seatData: data.attributes?.seats
          }, () => {
            this.openSeatSelectionDialog(this.state.seatData, {
              ...this.state.selectedFlight, 
              seatData: this.state.seatData,
              travellerInfo: Object.values(this.state.travellerInfo),
              'tabName': this.state.selectedFlight?.Flight_Name + "(" + this.state.selectedFlight?.Flight_Id + ")" + "(" + this.state.selectedFlight?.Depature_Iata + "→" + this.state.selectedFlight?.Arrival_Iata + ")",
            })
          });
        } else {
          this.props.showToast({ type: 'error', message: responseJson?.data?.attributes?.message })
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.props.hideLoader();
        if (responseJson?.agencies) {
          this.setState({
            ...this.state,
            profileData: responseJson?.agencies?.data?.attributes,
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.makePaymentApiCallId) {
        this.props.hideLoader();
        if (responseJson?.status) {
          if (this.state.selectedTravellingType === "oneway") {
            this.bookFlight(this.state.searchData, [
              ...this.state.entityDetail?.mergeAirSagmentBookingFlightInfo
            ], this.state.entityDetail?.FareBasisCode);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.bookFlight(this.state.searchData, [
              ...this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              ...this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo,
            ], this.state.entityDetail?.FareBasisCode);
          }
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if (msg === 'Token has Expired' || msg === 'Invalid token') {
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }



  handleTravellsInfoSave = (values: any, contactNo: any, email: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTravellApiCallId = requestMessage.messageId;
    const httpBody = {
      "booking_id": this.state.booking_id,
      "travel_detail_id": this.state.travaller_id,
      "data": {
        "flight_canceling": { "key": "value" },
        "add_upgrades": { "key": "value" },
        "meals": { "key": "value" },
        "wheel_cahirs": { "key": "value" },
        "flight_id": "8"
      },
      "token": this.state.authToken
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/addons`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFlightDetail = () => {
    this.props.showLoader();
    this.setState({
      entityDetail: { ...this.state.entityDetail, Depature_DateTime: this.state.entityDetail?.Depature_Time, Arrival_DateTime: this.state.entityDetail?.Arrival_Time }
    }, () => {
      let url = `${configJSON.get_flight_details}?carrier=${this.state.entityDetail?.flight_Carrier}&flight_number=${this.state.entityDetail?.flight_Id}&origin=${this.state.entityDetail?.Depature_Iata}&destination=${this.state.entityDetail?.Arrival_Iata}&departure_datetime=${this.state.entityDetail?.Depature_DateTime}&arrival_datetime=${this.state.entityDetail?.Arrival_DateTime}&class_of_service=Y&baggage_type=${this.state.entityDetail?.Baggage_Type}&baggage_cabin=${this.state.entityDetail?.Baggage_Cabin}&baggage_check_in=${this.state.entityDetail?.Baggage_Check_In}&mode_of_flight=${this.state.entityDetail?.Mode_Of_Flight}&fare_basis_code=${this.state.entityDetail?.FareBasisCode}&booking_code=${this.state.entityDetail?.BookingCode}&fare_rule_key=${this.state.entityDetail?.Fare_rule_Key}&fare_info_key=${this.state.entityDetail?.fare_info_key}`;
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getflightDetailApiCallId = requestMessage.messageId;

      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  completeBooking = () => {
    let entityDetailSmall = {
      arrival_city: this.state.entityDetail.Arrival_City,
      arrival_date: this.state.entityDetail.Arrival_Date,
      arrival_datetime: this.state.entityDetail.Arrival_DateTime,
      arrival_iata: this.state.entityDetail.Arrival_Iata,
      arrival_time: this.state.entityDetail.Arrival_Time,
      arriving_terminal: this.state.entityDetail.Arriving_Terminal,
      baggage_cabin: this.state.entityDetail.Baggage_Cabin,
      baggage_check_in: this.state.entityDetail.Baggage_Check_In,
      baggage_type: this.state.entityDetail.Baggage_Type,
      boarding_terminal: this.state.entityDetail.Boarding_Terminal,
      coemisson: this.state.entityDetail.Coemisson,
      depature_city: this.state.entityDetail.Depature_City,
      depature_date: this.state.entityDetail.Depature_Date,
      depature_datetime: this.state.entityDetail.Depature_DateTime,
      depature_iata: this.state.entityDetail.Depature_Iata,
      depature_time: this.state.entityDetail.Depature_Time,
      duration: this.state.entityDetail.Duration,
      flight_id: this.state.entityDetail.Flight_Id,
      flight_image: this.state.entityDetail.Flight_Image,
      flight_name: this.state.entityDetail.Flight_Name,
      flight_carrier: this.state.entityDetail.Flight_carrier,
      inflightservices: this.state.entityDetail.InFlightServices,
      meals: this.state.entityDetail.Meals,
      mode_of_flight: this.state.entityDetail.Mode_Of_Flight,
      sagment_key: this.state.entityDetail.Sagment_key,
      total_amount: this.state.entityDetail.Total_Amount,
      flight_key: this.state.entityDetail.flight_key,
    }

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.completeBookingAPICallId = requestMessage.messageId;
    const httpBody = {
      "data": {
        "travel_date": this.state.searchData.depratureDate,
        "travel_detail_id": this.state.travaller_id,
        "booking_id": this.state.booking_id,
        "flight_id": this.state.entityDetail.Flight_Id,
        "amount": this.state.fareSummaryDetail.total_price,
        "payment_type": "CASH",
        "card_type": "",
        "note": {
          ...this.state.fareSummaryDetail, couponDisCount: this.state.couponDiscount
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_payments/make_flight_payment`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getReturnFlightDetail = () => {
    this.props.showLoader();
    this.setState({
      selectedFlight2: { ...this.state.selectedFlight2, Depature_DateTime: this.state.selectedFlight2?.Depature_Time, Arrival_DateTime: this.state.selectedFlight2?.Arrival_Time }
    }, () => {
      let url = `${configJSON.get_flight_details}?carrier=${this.state.selectedFlight2?.flight_Carrier}&flight_number=${this.state.selectedFlight2?.flight_Id}&origin=${this.state.selectedFlight2?.Depature_Iata}&destination=${this.state.selectedFlight2?.Arrival_Iata}&departure_datetime=${this.state.selectedFlight2?.Depature_DateTime}&arrival_datetime=${this.state.selectedFlight2?.Arrival_DateTime}&class_of_service=Y&baggage_type=${this.state.selectedFlight2?.Baggage_Type}&baggage_cabin=${this.state.selectedFlight2?.Baggage_Cabin}&baggage_check_in=${this.state.selectedFlight2?.Baggage_Check_In}&mode_of_flight=${this.state.selectedFlight2?.Mode_Of_Flight}&fare_basis_code=${this.state.selectedFlight2?.FareBasisCode}&booking_code=${this.state.selectedFlight2?.BookingCode}&fare_rule_key=${this.state.selectedFlight2?.Fare_rule_Key}&fare_info_key=${this.state.selectedFlight2?.fare_info_key}`;
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getreturnflightDetailApiCallId = requestMessage.messageId;

      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })

  };

  getFareSummary = (searchData: any, flightData: any = [], FareBasisCode: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.faresummaryApiCallId = requestMessage.messageId;
    const air_attributes = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "air_segment_ref": flight.Key,
        "carrier": flight.flight_Carrier,
        "group": flight.Group || 0,
        "flight_number": flight.flight_Id,
        "origin": flight.Origin,
        "destination": flight.Destination,
        "departure_time": flight.DepartureTime,
        "arrival_time": flight.ArrivalTime,
        "class_of_service": flight.ClassOfService
      }
      return temp;
    })
    const pricing_modifiers = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "fare_basic_code": FareBasisCode,
        "air_segment_ref": flight.Key,
        "code": flight.BookingCode
      }
      return temp;
    })
    const httpBody = {
      "air_price": {
        "type": this.state.selectedTravellingType,
        "adult": searchData.adults || 0,
        "child": searchData.children || 0,
        "infents": searchData.infants || 0,
        "air_attributes": air_attributes,
        "currency": this.state.currency,
        "pricing_modifiers": pricing_modifiers
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/travel_search/air_price`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  bookFlight = (searchData: any, flightData: any = [], FareBasisCode?: any) => {

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bookSummaryApiCallId = requestMessage.messageId;

    const air_attributes = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "air_segment_ref": flight.Key,
        "carrier": flight.flight_Carrier,
        "group": flight.Group || 0,
        "flight_number": flight.flight_Id,
        "origin": flight.Origin,
        "destination": flight.Destination,
        "departure_time": flight.DepartureTime,
        "arrival_time": flight.ArrivalTime,
        "class_of_service": flight.ClassOfService
      }
      return temp;
    })
    const pricing_modifiers = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "fare_basic_code": FareBasisCode,
        "air_segment_ref": flight.Key,
        "code": flight.BookingCode
      }
      return temp;
    })
    const air_pricing_data = {
      "air_price": {
        "type": this.state.selectedTravellingType,
        "adult": searchData.adults || 0,
        "child": searchData.children || 0,
        "infents": searchData.infants || 0,
        "air_attributes": air_attributes,
        "currency": this.state.currency,
        "pricing_modifiers": pricing_modifiers
      }
    }
    const httpBody = {
      "booking_id": this.state.booking_id,
      "travel_detail_id": Number(this.state.travaller_id),
      "seat_selections": this.state.seletedSeats,
      "token": this.state.authToken,
      "air_pricing_data": air_pricing_data
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/flight_booking/booking_confirmation`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSeatData = (flight: any, selectedSeat: any) => {
    this.setState({
      selectedFlight: selectedSeat ? { ...flight, selectedSeat } : { ...flight }
    }, () => {
      if (true) {
        this.props.showLoader();
        const headers = {
          "Content-Type": configJSON.ApiContentType,
          "token": this.state?.authToken
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSeatDataApiCallId = requestMessage.messageId;
        const url = `?carrier=${flight?.flight_Carrier}&flight_number=${flight?.flight_Id}&origin=${flight?.Depature_Iata}&destination=${flight?.Arrival_Iata}&departure_datetime=${flight?.Depature_DateTime}&arrival_datetime=${flight?.Arrival_DateTime}&class_of_service=${flight?.ClassOfService}&air_ref_key=${flight?.Key}&group=${flight?.Group}`
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_advanced_search/travel_search/seat_map${url}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })
  }

  getProfileDetail = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDetailApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.agencyGetProfileData}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openSeatSelectionDialog = (seatData: any, selectedFlight: any) => {
    const { searchData } = this.state;

    const noOfTravellers =
      (+searchData.adults || 0) +
      (+searchData.children || 0) +
      (+searchData.infants || 0);
    const travellerInfo = Object.values(this.state.travellerInfo)
    const self = this;
    let twoWayFlightList: any = [];
    let oneflightDetail: any = []
    if (this.state.oneWayFlightList && this.state.oneWayFlightList.length > 0) {
      oneflightDetail = this.state.oneWayFlightList.map((flight) => {
        return {
          ...flight,
          seatData: selectedFlight.Flight_Id === flight?.Flight_Id ? seatData : [],
          travellerInfo: travellerInfo,
          'tabName': flight?.Flight_Name + "(" + flight?.Flight_Id + ")" + "(" + flight?.Depature_Iata + "→" + flight?.Arrival_Iata + ")",
        }
      });
    }

    if (this.state.twoWayFlightList && this.state.twoWayFlightList.length > 0) {
      twoWayFlightList = this.state.twoWayFlightList.map((flight) => {
        return {
          ...flight,
          seatData: selectedFlight.Flight_Id === flight?.Flight_Id ? seatData : [],
          travellerInfo: travellerInfo,
          'tabName': flight?.Flight_Name + "(" + flight?.Flight_Id + ")" + "(" + flight?.Depature_Iata + "→" + flight?.Arrival_Iata + ")",
        }
      });
    }
    let flights = [...oneflightDetail, ...twoWayFlightList]
    this.props
      .openDialogBox({
        title: "",
        dataToPass: {
          flights: flights,
          seatData: seatData,
          noOfTravellers: noOfTravellers,
          openToast: this.props.showToast,
          travellers: travellerInfo,
          selectedFlight: selectedFlight,
          role: this.state.role
        },
        renderedComponent: SeatMap,
        // fullScreen: true,
        withCustomDialog: true,
        catchOnCancel: true,
        disableBackdropClick: true,
        scroll: 'body',
        width:'80%',
      }).then((data: []) => {
        if (data) {
          const selectedSeat: any = []
          for (let index = 0; index < data.length; index++) {
            const temp = { SegmentRef: null, SeatId: null, BookingTravelerRef: null };
            let flight: any = data[index];
            temp.SegmentRef = flight.Key;
            for (var i = 0; i < flight.travellerInfo.length; i++) {
              if (flight.travellerInfo[i].selectedSeat) {
                const BookingTravelerRef = flight.travellerInfo[i].key;
                const SeatId = flight.travellerInfo[i].selectedSeat;
                selectedSeat.push({ ...temp, SeatId, BookingTravelerRef });
              }
            }
          }
          if (self.state.seletedSeats.length > 0) {
            this.setState({
              seletedSeats: [...this.mergeByProperty(self.state.seletedSeats, selectedSeat)]
            })
          } else {
            this.setState({
              seletedSeats: [...selectedSeat]
            })
          }
          this.props.showToast({
            type: 'success',
            message: 'Seat selection done successfully'
          })
        }
      })
  }

  mergeByProperty = (target: Array<any>, source: Array<any>) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement["SegmentRef"] + sourceElement["BookingTravelerRef"] === targetElement["SegmentRef"] + targetElement["BookingTravelerRef"];
      })
      if (targetElement)
        targetElement.SeatId = sourceElement.SeatId;
      else
        target.push(sourceElement)
    })
    return [...target];
  }
  
  makePaymentbyCreadit = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.makePaymentApiCallId = requestMessage.messageId;

    const httpBody = {  //need to send the params for flight booking on travel port 
      "data": {
        "booking_id": this.state.booking_id,
        "flight_id": this.state.entityDetail?.flight_Id,
        "amount": this.state.fareSummaryDetail.total_price,
        "payment_type": this.state.paymentModeValue,
        "note": {
          ...this.state.fareSummaryDetail, couponDisCount: this.state.couponDiscount
        }
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_payments/make_flight_payment`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

