//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { success, successVerify } from "./assets";
import SignUpVerifyLinkDialogController, {
  Props,
} from "./SignUpVerifyLinkDialogController.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withFontStyles from "./withFontStyles.web";



class SignUpVerifyLinkDialog extends SignUpVerifyLinkDialogController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    this.props.onSubmit({ type: routeName });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogContent className={classes.dialogStyles}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={10}>
              <Grid container justify="center">
                <img src={successVerify} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                align="center"
                color="textPrimary"
                className={classes.groteskBold32}
              >
                Account Verified Successfully!
              </Typography>
            </Grid>

            <Grid item xs={10} >
              <Grid container justify="center" alignItems="center" className={classes.buttonGrid}   >
                <Button fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.props.dataToPass.role === 'Admin' ? this.goToSelectedRoute("signIN") : this.goToSelectedRoute("verified");
                  }}
                >
                  {this.props.dataToPass.role === 'Admin' ? 'Login' : 'Complete Profile'}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={10} >
              <Typography align="center" color="primary"onClick={() => { this.goToSelectedRoute("signIN") }} className={classes.confirmationSubTitle}>
                Login to continue
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        {/* old code */}
        {/* <DialogContent className={classes.dialogStyles}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={success} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                color="textPrimary"
                className={classes.confirmationText}
              >
                Account Verified Successfully!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" color="primary" style={{ cursor: 'pointer' }} onClick={() => { this.goToSelectedRoute("signIN") }} className={classes.confirmationSubTitle}>
                Login to continue
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.props.dataToPass.role === 'Admin' ? this.goToSelectedRoute("signIN") : this.goToSelectedRoute("verified");
                  }}
                >
                  {this.props.dataToPass.role === 'Admin' ? 'Login' : 'Complete Profile'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent> */}

      </>

    );
  }
}
const SignUpVerifyLinkDialogAlertBox = withAlertBox(SignUpVerifyLinkDialog);
const SignUpVerifyLinkDialogLoader = withLoader(SignUpVerifyLinkDialogAlertBox);
const SignUpVerifyLinkDialogToast = withToast(SignUpVerifyLinkDialogLoader)
const SignUpVerifyLinkDialogWithRouter = withRouter(SignUpVerifyLinkDialogToast);
const SignUpVerifyLinkDialogWithFontStyles = withFontStyles(SignUpVerifyLinkDialogWithRouter);

export default withStyles((theme) =>
  createStyles({
    confirmationText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Open Sans",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.15,
    },
    confirmationSubTitle: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: 1.19,
      cursor: 'pointer',
      paddingBottom:'1rem'
    },
    confirmationBtnText: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem'
    },
    buttonGrid:{
      paddingTop:'8rem',
      [theme.breakpoints.down('sm')]: {
        paddingTop:'4rem',
      }
    },
    loginGrid:{
      padding:'2rem'
    }
  })
)(SignUpVerifyLinkDialogWithFontStyles);
