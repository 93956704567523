//@ts-nocheck
import React from "react";
import AllOffersListController, {
  Props,
} from "./AllOffersListController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  IconButton
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { hotelImage, agancyCover } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withFontStyles from "./withFontStyles.web";
import AddOfferDialog from './AddOfferDialog.web'
import moment from "moment";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
class AllOffersList extends AllOffersListController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getOfferList();
        }
      );
    }
  }

  openAddOfferDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: AddOfferDialog,
        title: "",
        withCustomDialog: true,
        scroll : 'body',
        disableBackdropClick : true,
        catchOnCancel : true
      })
      .then((type : any) => {
         if (type === "SuccessFull") {
          this.props.showToast({
              type: "success",
              message: "Offer created sucessfully",
            });
            this.getOfferList();
        }
      });
  };
  deleteOfferUI = (memberId) => {
    const onClose = (result) => {
      if(result === 'Yes'){
        this.deleteOffer(memberId)
      }
    }
    this.props.openConfirmDialog(
      'Delete','Are you sure you want to delete this offer',onClose
    )
  }
  updateOffer = (offerDetail) => {
    this.props
      .openDialogBox({
        dataToPass: {tokenName : "adminToken",offerDetail : offerDetail},
        renderedComponent: AddOfferDialog,
        title: "Edit Offer",
        withCustomDialog: true,
      })
      .then(async(type : any) => {
         if (type) {
          this.props.showToast({
            type: "success",
            message: "Offer updated successfully.",
          });
          this.getOfferList();
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="space-around" spacing={4}>
        <Grid item xs={11} >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pendIngRequestBox}
          >
            <Grid item md={9}>
              <Typography className={classes.pendIngTite}>
                All Offers
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.openAddOfferDialog();
                }}
              >
                {" "}
                Add Offer{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {this.state.allOfferList.length > 0 ? (
          this.state.allOfferList.map((Offer) => {
            return (
              <Grid item key={Offer.id} xs={11}>
                <Grid
                  container
                  spacing={3}
                  className={classes.subagencyContainer}
                >
                  <Grid item xs={3}> 
                     <div className={classes.offerImage} style={{backgroundImage : `url(${Offer?.attributes?.images.length > 0 ? Offer?.attributes?.images[0] : hotelImage})`}}>
                         
                     </div>
                   </Grid>
                   <Grid item xs={9}>
                      <Grid container>
                      <Grid xs={11} item>
                    <div style={{display : 'flex'}}>
                       <div style={{flexGrow : 1}}>
                       <Typography className={classes.agencyName}>
                          {Offer.attributes.title}
                        </Typography>
                        <Typography className={classes.anancyInfo}>
                          {Offer.attributes.description}
                        </Typography>         
                       </div>
                       <div className={classes.iconroot}>
                          <IconButton onClick={()=>{this.deleteOfferUI(Offer.id)}} aria-label="delete" color="default">
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                          <IconButton color="default" onClick={()=>{this.updateOffer(Offer)}} aria-label="add to shopping cart">
                            <CreateOutlinedIcon />
                          </IconButton>
                        </div>
                    </div>
                  </Grid>
                  <Grid xs={11} item>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Offer Applicable for"
                            secondary={Offer.attributes.advertisement_type}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      {/* <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Discount"
                            secondary={Offer.attributes.discount}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid> */}
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Valid From"
                            secondary={
                              (Offer.attributes.start_date &&
                                moment(
                                  new Date(Offer.attributes.start_date)
                                ).format("DD/MM/yyyy")) ||
                              "NA"
                            }
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Valid Till"
                            secondary={
                              (Offer.attributes.end_date &&
                                moment(
                                  new Date(Offer.attributes.end_date)
                                ).format("DD/MM/yyyy")) ||
                              "NA"
                            }
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
               
                      
                      </Grid> 
                   </Grid>
                  </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={11}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.subagencyContainer}
              style={{ height: "70px" }}
            >
              <Grid item md={11}>
                <Typography className={classes.pendIngTite}>
                  No Offers Available
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const AllOffersListWithRouter = withRouter(AllOffersList);
const AllOffersListAlertBox = withAlertBox(AllOffersListWithRouter);
const AllOffersListLoader = withLoader(AllOffersListAlertBox);
const AllOffersListToast = withToast(AllOffersListLoader);
const AllOffersListWithDialog = withDialog(AllOffersListToast);
const AllOffersListWithFontStyles = withFontStyles(AllOffersListWithDialog);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: theme.spacing(12.5)
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    offerImage: {
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
        height: 128,
    }
  })
)(withConfirmBox(AllOffersListWithFontStyles));
