//@ts-nocheck
import React from "react";
import FooterController, { Props } from "./HeaderController.web";
import {
  withStyles,
  Grid,
  Container,
  Link,
  Typography,
  createStyles,
} from "@material-ui/core";
import { withRouter } from "react-router";
import withFontStyles from './withFontStyles.web'

import {heartIcon} from './assets'
import {
  companylogo
} from "./assets";

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.push({
      state: data,
      pathname: routeName,
    });
  };
  render() {
    const { classes } = this.props;
    const marginStyle = {
      marginTop: this.props.marginTop ?? '120px'
    }
    return (
      <footer className={classes.footer} style={{...marginStyle}}>
        <Grid container justify="center" style={{padding: '100px 0px 20px'}}>
          <Grid item xs={8} md={3}>
            <Grid container justify="center">
              <Grid item xs={6}>
                {/* <Typography gutterBottom className={[classes.groteskBold32, classes.whiteText]}>Doolwa</Typography> */}
                <img
                              src={companylogo}
                              style={{ height: "60px" }}
                            />
                {/* <Typography color='textSecondary' className={[classes.openSans16, classes.gutterTop2]}>Some Punchline goes here</Typography> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} md={2}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={classes.groteskBold18}
                  gutterBottom
                >
                  Products
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Landing Page
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Features
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Documentation
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Pricing
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} md={2}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={classes.groteskBold18}
                  gutterBottom
                >
                  Services
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Landing Page
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Features
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Documentation
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Pricing
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} md={2}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={classes.groteskBold18}
                  gutterBottom
                >
                  Company
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  About
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Term And Conditions
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Privacy Policy
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Carrers
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} md={2}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={classes.groteskBold18}
                  gutterBottom
                >
                  Company
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  About
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Term And Conditions
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Privacy Policy
                </Typography>
                <Typography
                  display="block"
                  color='textSecondary'
                  className={[classes.openSans16, classes.gutterVerticle3]}
                  
                >
                  Carrers
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container justify='center' alignItems='center'>
          <Grid item md={12} style={{textAlign: 'center'}}>
            <img src={heartIcon} style={{height: '100px', width: '100px'}}/>
            <Typography align="center" className={[classes.openSans20, classes.whiteText]}>
              {"Copyright © "}
              <Link color="inherit" href="https://material-ui.com/">
                Your Website
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
              Crafted with love.
            </Typography>
          </Grid>
        </Grid> */}
      </footer>
    );
  }
}
const FooterWithFontStyles = withFontStyles(Footer);

export default withStyles((theme) =>
  createStyles({
    footerLinkHeading: {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
      textAlign : 'center'
    },
    footerLink: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
      marginBottom: '20px',
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
  })
)(FooterWithFontStyles);