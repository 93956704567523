//@ts-nocheck
import React from "react";
import CustomerFlowLandingPageController, {
  Props,
} from "./CustomerFlowLandingPageController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Hidden,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  Container,
  Avatar,
  InputAdornment,
  IconButton,
  TextField,
  MenuItem,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Rating from "@material-ui/lab/Rating";
import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import Header from "./Header.web";
import Footer from "./Footer.web";
import Close from "@material-ui/icons/Close";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Remove';
import debounce from "lodash.debounce";
import {
  hotelImage,
  CustomerlandingPage,
  hotelIcon,
  hotelIcon1,
  flightIcon,
  flightIcon1,
  AgentBanner,
  landingPage,
} from "./assets";
import {
  cheapThanOtherIcon,
  securePaymentIcon,
  bestOfferIcon,
  easyBookIcon,
  greatAmenitiesIcon,
  topRatedIcon,
  fastRefundIcon,
  supportIcon,
} from "./assets";
import { withRouter } from "react-router";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { format } from 'date-fns'
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
// import SearchIcon from "@material-ui/icons/Search";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import withFontStyles from './withFontStyles.web'
import { relative } from "path";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// import useDebounce from "./useDbouncing";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 2,
  //   slidesToSlide: 2 // optional, default to 1.
  // },
  // mobile: {
  //   breakpoint: { max: 464, min: 0 },
  //   items: 1,
  //   slidesToSlide: 1 // optional, default to 1.
  // }
};

const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
// console.log("toLocaleDateString", new Date().toISOString().replace(/T.*/,'').split('-').reverse().join('-'))
const StyledRating = withStyles({
  iconFilled: {
    color: "#FF8B00",
  },
  iconHover: {
    color: "#FF8B00",
  },
  // iconFilled: {
  //   color: "#1565d8",
  // },
  // iconHover: {
  //   color: "#1565d8",
  // },
})(Rating);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const hotelSchema = Yup.object().shape({
  hotelName: Yup.string().required("This field is required."),
  noOfRooms: Yup.number().required("This field is required"),
  noOfGuests: Yup.number().required("This field is required"),
  checkIn: Yup.date()
    .max(Yup.ref("checkOut"), "Checkin date can't be after checkout date")
    .required("This field is required."),
  checkOut: Yup.date()
    .min(Yup.ref("checkIn"), "Checkout date can't be before checkin date")
    .required("This field is required."),
});

const flightOneWaySchema = Yup.object().shape({
  from: Yup.string().required("Please Select a source."),
  to: Yup.string().required("Please Select a destination"),
  depratureDate: Yup.date().required("Departure Date is required."),
  adults: Yup.number().required("This field is required."),
  children: Yup.number(),
  infants: Yup.number(),
  class: Yup.string().required("This field is required."),
});

const flightTwoWaySchema = Yup.object().shape({
  from: Yup.string().required("Please Select a source."),
  to: Yup.string().required("Please Select a destination"),
  depratureDate: Yup.date().required("Departure Date is required."),
  returnDate: Yup.date()
    .min(Yup.ref("depratureDate"), "End date can't be before Start date")
    .required("This field is required."),
  adults: Yup.number().required("This field is required."),
  children: Yup.number(),
  infants: Yup.number(),
  class: Yup.string().required("This field is required."),
});

const flightMultiCitySchema = Yup.object().shape({
  // from: Yup.string().required("This field is required."),
  // to: Yup.string().required("This field is required"),
  // depratureDate: Yup.date().required("This field is required."),
  newCity: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required("Please Select a source."),
      to: Yup.string().required("Please Select a destination"),
      depratureDate: Yup.date().required("Departure Date is required."),
    })
  ),
  adults: Yup.number().required("This field is required."),
  children: Yup.number(),
  infants: Yup.number(),
  class: Yup.string().required("This field is required."),
});


class CustomerFlowLandingPage extends CustomerFlowLandingPageController {
  TOTAL_PASSENGERS_ALLOWED = 9;
  constructor(props: Props) {
    super(props);
    this.state.searchTerm = ''
    this.SearchFlightFromCity = debounce(this.SearchFlightFromCity.bind(this), 1000);
    this.SearchFlightToCity = debounce(this.SearchFlightToCity.bind(this), 1000);
  }



  ClearDataForm = (flightType, setfieldVlaues) => {
    if (flightType === 'oneway') {
      this.setState({
        counter: 1,
        counter1: 0,
        counter2: 0,
        fromCity: {},
        sourcesearchSuggestionList: [],
        toCity: {},
        depratureDate: ''
      }, () => {
        setfieldVlaues('from', '', 'to', '', 'depratureDate', '')
      })
    }
    else if (flightType === 'twoway') {
      this.setState({
        twoWayCounterInfant: 0,
        twoWayCounterAdult: 1,
        twoWayCounterChild: 0,
        fromCity: {},
        sourcesearchSuggestionList: [],
        toCity: {},
        depratureDate: ''
      }, () => {
        setfieldVlaues('from', '', 'to', '', 'depratureDate', '', 'returnDate', "",)
      })
    }
    else if (flightType === 'multicity') {
      this.setState({
        twoWayCounterInfant: 0,
        twoWayCounterAdult: 1,
        twoWayCounterChild: 0,
        fromCity: {},
        sourcesearchSuggestionList: [],
        toCity: {},
        depratureDate: ''
      }, () => {
        setfieldVlaues('from', '', 'to', '', 'depratureDate', '', 'returnDate', "",)
      })
    }

  }


  dropDownSelect = () => {
    this.setState({
      SelectOption: !this.state.SelectOption
    })
  }


  handleIncrement = (value, flighWays) => {

    if (flighWays === 'oneWay') {
      const { counter, counter1, counter2 } = this.state;
      let status = this.validateCount(counter, counter1, counter2);
      if (status) {
        if (value === 'Adult') {
          this.setState({
            counter: this.state.counter + 1
          }, () => {
            console.log("counter for adult", this.state.counter)
          })
        }
        else if (value === 'Child') {
          this.setState({
            counter1: this.state.counter1 + 1
          })
        }
        else if (value === 'Infant') {
          this.setState({
            counter2: this.state.counter2 + 1
          })
        }
        this.setState({ travellerCountStatus: false })

      } else {
        this.setState({ travellerCountStatus: true })
      }
    }

    else if (flighWays === 'twoWay') {
      const { twoWayCounterAdult, twoWayCounterChild, twoWayCounterInfant } = this.state;
      let status = this.validateCount(twoWayCounterAdult, twoWayCounterChild, twoWayCounterInfant);
      if (status) {
        if (value === 'Adult') {

          this.setState({
            twoWayCounterAdult: this.state.twoWayCounterAdult + 1
          })
        }
        if (value === 'Child') {
          this.setState({
            twoWayCounterChild: this.state.twoWayCounterChild + 1
          })
        }
        else if (value === 'Infant') {
          this.setState({
            twoWayCounterInfant: this.state.twoWayCounterInfant + 1
          })
        }
        this.setState({ travellerCountStatus: false })

      } else {
        this.setState({ travellerCountStatus: true })

      }


    }

    else if (flighWays === 'multiWay') {
      const { multiWayCounterAdult, multiWayCounterChild, multiWayCounterInfant } = this.state;
      let status = this.validateCount(multiWayCounterAdult, multiWayCounterChild, multiWayCounterInfant);
      if (status) {
        if (value === 'Adult') {
          this.setState({
            multiWayCounterAdult: this.state.multiWayCounterAdult + 1
          })
        }
        if (value === 'Child') {
          this.setState({
            multiWayCounterChild: this.state.multiWayCounterChild + 1
          })
        }
        else if (value === 'Infant') {
          this.setState({
            multiWayCounterInfant: this.state.multiWayCounterInfant + 1
          })
        }
        this.setState({ travellerCountStatus: false })

      } else {
        this.setState({ travellerCountStatus: true })

      }
    }

    // this.setState({ counter: this.state.counter + 1 });
  };

  handleDecrement = (value, flighWays) => {

    if (flighWays === 'oneWay') {

      if (value === 'Adult') {
        this.setState({
          counter: this.state.counter - 1,
        })
      }
      else if (value === 'Child') {
        this.setState({
          counter1: this.state.counter1 - 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          counter2: this.state.counter2 - 1
        })
      }
      this.setState({ travellerCountStatus: false })
    }

    else if (flighWays === 'twoWay') {

      if (value === 'Adult') {
        this.setState({
          twoWayCounterAdult: this.state.twoWayCounterAdult - 1
        })
      }
      if (value === 'Child') {
        this.setState({
          twoWayCounterChild: this.state.twoWayCounterChild - 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          twoWayCounterInfant: this.state.twoWayCounterInfant - 1
        })
      }
      this.setState({ travellerCountStatus: false })

    }

    else if (flighWays === 'multiWay') {
      if (value === 'Adult') {
        this.setState({
          multiWayCounterAdult: this.state.multiWayCounterAdult - 1
        })
      }
      if (value === 'Child') {
        this.setState({
          multiWayCounterChild: this.state.multiWayCounterChild - 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          multiWayCounterInfant: this.state.multiWayCounterInfant - 1
        })
      }
      this.setState({ travellerCountStatus: false })

    }

  };
  validateCount = (c1, c2, c3) => {
    const totalCount = c1 + c2 + c3;
    return totalCount < this.TOTAL_PASSENGERS_ALLOWED;
  };





  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getBestOffer()
          // this.getBoatListing();
          // this.getBookings();
        }
      );
    } else {
      this.getBestOffer()
    }
  }

  dropDowmalues = (event, name) => {
    event.preventDefault()
    if (name === 'Adult') {
      this.setState({
        Pasanger: 'Adult',
      })
    }
    else if (name === 'Child') {
      this.setState({
        Pasanger: 'Child',
      })
    }
    else {
      this.setState({
        Pasanger: 'Infant',
      })
    }

  }
  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.push({
      state: {
        data: { ...data },
        selectedTravellingType: this.state.selectedTravellingType,
        fromCity: this.state.fromCity,
        toCity: this.state.toCity,
        role: 'Customer'
      },
      pathname: routeName,
    });
  };

  SearchFlightFromCity = (value) => {
    this.getFromLocationSuggestion(
      value
    );
  }
  SearchFlightToCity = (value) => {
    this.getDestinationLocationSuggestion(
      value
    );
  }

  render() {
    const { classes } = this.props;
    const displayCounter = this.state.counter >= 0;
    let valueAdult = this.state.counter
    // const debouncedSearchTerm = useDebounce(this.state.searchTerm, 1000);
    return (
      <div className={`${classes.root} ${classes.bodyBackground}`}>
        <Paper
          className={`${classes.mainPost} ${classes.mainPostHeight}`}
          style={{
            // backgroundImage: `url(${CustomerlandingPage})`,
            backgroundImage: `url(${landingPage})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              // src={CustomerlandingPage}
              src={landingPage}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <Header />
          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={10}>
              <div className={classes.mainPostContent}>
                <Grid container justify="space-between" direction="column" alignItems="center">
                  <Grid item md={7} xs={12} sm={12} lg={7} xl={6}>
                    <Box p={2}>
                      <Typography
                        gutterBottom
                        className={classes.helloAdmin}
                        align="center"
                      >
                        Start Exploring to Find Inner Peace by Traveling
                      </Typography>
                      <Typography
                        gutterBottom
                        className={classes.helloAdminSubheading}
                        align="center"
                      >
                        Lost yourself with Vacation and Travel into the place
                        that you never visit before. We have thousand places in
                        our database for you.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={8} sm={8} lg={4} xl={4} className={classes.CenterSpacing}>
                    <Paper
                      elevation={0}
                      style={{
                        borderRadius: "40px",
                        backgroundColor: "#f8f8fa",
                        padding: '4px',

                      }}
                    >
                      <Tabs
                        value={this.state.selectedSearchType}
                        onChange={(evt, value) => {
                          this.setState(
                            {
                              selectedSearchType: value,
                            },
                            () => {
                              //   this.getBookings();
                            }
                          );
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        className={classes.MuiSelectedTab}                      >
                        <Tab
                          label="Hotels"
                          value={"Hotels"}
                          icon={
                            this.state.selectedSearchType == "Hotels" ?
                              <img
                                src={hotelIcon1}
                                style={{ widht: "20px", height: "20px" }}
                              />
                              : <img
                                src={hotelIcon}
                                style={{ widht: "20px", height: "20px" }}
                              />
                          }
                          classes={{ root: classes.tabLabel }}
                          className={classes.SelectedTab}
                        />
                        <Tab
                          label="Flights"
                          value={"Flights"}
                          icon={
                            this.state.selectedSearchType == "Flights" ?
                              <img
                                src={flightIcon1}
                                style={{ widht: "20px", height: "20px" }}
                              />
                              : <img
                                src={flightIcon}
                                style={{ widht: "20px", height: "20px" }}
                              />
                          }
                          // icon={
                          //   <img
                          //     style={{ widht: "20px", height: "20px" }}
                          //     src={flightIcon}
                          //   />
                          // }
                          classes={{ root: classes.tabLabel }}
                          className={classes.SelectedTab}
                        />
                      </Tabs>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={10}>
              <div className={classes.mainPostContent}>
                <Grid container justify="space-between">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                  >
                    <Paper elevation={0}
                      className={classes.paperClass}
                    >
                      <TabPanel
                        value={this.state.selectedSearchType}
                        index={"Hotels"}
                      >
                        {" "}
                        <Formik
                          initialValues={{
                            hotelName: "",
                            checkIn: "",
                            checkOut: "",
                            noOfRooms: "",
                            noOfGuests: "",
                          }}
                          validationSchema={hotelSchema}
                          onSubmit={(values) => {
                            this.goToSelectedRoute(
                              "/hotelSearchResult",
                              values
                            );
                          }}
                        >
                          {(formikProps) => {
                            const {
                              values,
                              handleBlur,
                              handleChange,
                              setFieldValue,
                            } = formikProps;
                            return (
                              <Form autoComplete="off" noValidate>
                                <Grid container justify="center" spacing={2}>
                                  <Grid xs={12} md={4} item>
                                    <Field
                                      component={InputFieled}
                                      name="hotelName"
                                      placeholder="Enter City / Hotel / Area / Building"
                                      // onChange={handleChange}
                                      value={values.hotelName}
                                      fullWidth
                                      label="City / Hotel / Area / Building "
                                    />
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <Field
                                      component={InputFieled}
                                      name="checkIn"
                                      type="date"
                                      placeholder="Enter Check-In Date"
                                      // onChange={handleChange}
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      value={values.checkIn}
                                      fullWidth
                                      label="Check In"
                                      onfocus="(this.type='date')"
                                    />
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <Field
                                      component={InputFieled}
                                      name="checkOut"
                                      type="date"
                                      placeholder="Enter Check In Date"
                                      // onChange={handleChange}
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      value={values.checkOut}
                                      fullWidth
                                      label="Check Out"
                                    />
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <Field
                                      component={InputFieled}
                                      name="noOfGuests"
                                      type="number"
                                      placeholder="Enter No. of Guest"
                                      // onChange={handleChange}
                                      value={values.noOfGuests}
                                      fullWidth
                                      label="Guests"
                                    />
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <Field
                                      component={InputFieled}
                                      name="noOfRooms"
                                      type="number"
                                      placeholder="Enter No. of Rooms"
                                      // onChange={handleChange}
                                      value={values.noOfRooms}
                                      fullWidth
                                      label="Rooms"
                                    />
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <Box py={3}>
                                      <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                      >
                                        Search
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      </TabPanel>

                      <TabPanel
                        value={this.state.selectedSearchType}
                        index={"Flights"}
                      >
                        <Box className={classes.borderedBottom}>
                          <Tabs
                            value={this.state.selectedTravellingType}
                            onChange={(evt, value) => {
                              this.setState({
                                selectedTravellingType: value,
                                fromCity: null,
                                toCity: null,
                                destinationSuggestionloading: false,
                                destinationsearchSuggestionList: [],
                                sourceSuggestionloading: false,
                                sourcesearchSuggestionList: [],
                              });
                            }}
                            indicatorColor="primary"
                            textColor="primary"

                          >

                            <Tab value={"oneway"} label="One Way" classes={{ root: classes.tabLabel }} />
                            <Tab value={"twoway"} label="Two Way" classes={{ root: classes.tabLabel }} />
                            <Tab value={"multicity"} label="Multicity" classes={{ root: classes.tabLabel }} />

                          </Tabs>
                        </Box>
                        <TabPanel
                          value={this.state.selectedTravellingType}
                          index={"oneway"}
                        >
                          <Formik
                            initialValues={{
                              from: "",
                              to: "",
                              depratureDate: "",
                              adults: 1,
                              children: 0,
                              infants: 0,
                              class: "Economy",
                            }}
                            enableReinitialize
                            validationSchema={flightOneWaySchema}
                            onSubmit={(values) => {
                              values.adults = this.state.counter
                              values.children = this.state.counter1
                              values.infants = this.state.counter2
                              this.goToSelectedRoute(
                                "/flightSearchResult",
                                values
                              );
                            }}
                          >
                            {(formikProps) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                              } = formikProps;
                              return (
                                <Form autoComplete="off">
                                  <Grid container justify="center" spacing={2} style={{ marginBottom: '10px' }}>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Autocomplete
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        freeSolo
                                        style={{ width: "100%" }}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) => {
                                          if (Object.keys(value).length > 0) {
                                            return `${value.city || ''},${value.country || ''} (${value.iata || ''})`
                                          }
                                          else {
                                            return ''
                                          }
                                        }

                                        }
                                        options={
                                          this.state.sourcesearchSuggestionList
                                        }
                                        loading={false}
                                        onInputChange={(evt) => {
                                          if (evt?.target?.value) {
                                            setFieldTouched("from", true);
                                            setFieldValue("from", "");
                                            this.SearchFlightFromCity(evt?.target?.value)
                                          }
                                          else {
                                            setFieldTouched("from", false);
                                          }
                                        }}
                                        onChange={(evt, value) => {
                                          if (value) {
                                            setFieldValue(
                                              "from",
                                              `${value?.city},(${value?.name})`
                                            );
                                            this.setState({
                                              sourceSuggestionloading: false,
                                              fromCity: value,
                                            });
                                          }
                                        }}
                                        value={this.state.fromCity}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="From"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            // required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .sourceSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {/* {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  } */}
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.from && touched.from && (
                                        <Typography color="error">
                                          {errors.from}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        style={{ width: "100%" }}
                                        options={
                                          this.state
                                            .destinationsearchSuggestionList
                                        }
                                        loading={false}
                                        // onInputChange={(evt) => {
                                        //   this.getDestinationLocationSuggestion(
                                        //     evt.target.value
                                        //   );
                                        // }}
                                        onInputChange={(evt) => {
                                          if (evt?.target?.value) {
                                            setFieldTouched("to", true);
                                            setFieldValue("to", "");
                                            // this.getDestinationLocationSuggestion(
                                            //   evt.target.value
                                            // );
                                            this.SearchFlightToCity(evt?.target?.value)
                                          }

                                        }}
                                        onChange={(evt, value) => {
                                          if (value) {
                                            setFieldValue(
                                              "to",
                                              `${value?.city},(${value?.name})`
                                            );
                                            this.setState({
                                              destinationSuggestionloading: false,
                                              toCity: value,
                                            });
                                          }
                                        }}
                                        value={this.state.toCity}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) => {
                                          if (Object.keys(value).length > 0) {
                                            return `${value.city || ''},${value.country || ''} (${value.iata || ''})`
                                          }
                                          else {
                                            return ''
                                          }
                                        }
                                        }
                                        // onBlur={() => {
                                        //   setFieldTouched("to", true);
                                        // }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="To"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            // required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .destinationSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {/* {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  } */}
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.to && touched.to && (
                                        <Typography color="error">
                                          {errors.to}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Field
                                        component={InputFieled}
                                        name="depratureDate"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: curDate,
                                          },
                                        }}
                                        placeholder="Enter Departure Date"
                                        onChange={handleChange}
                                        value={values.depratureDate}
                                        fullWidth
                                        className={classes.errorhandle}
                                        label="Departure"
                                      />
                                    </Grid>
                                    {/* <Grid xs={10} item>
                                      <Grid container spacing={1}>
                                        <Grid xs={4} item>
                                          <Field
                                            component={InputFieled}
                                            select
                                            name="adults"
                                            onChange={handleChange}
                                            value={values.adults}
                                            fullWidth
                                            label="Adults(> 12yr)"
                                          >
                                            {[
                                              {
                                                value: "1",
                                              },
                                              {
                                                value: "2",
                                              },
                                              {
                                                value: "3",
                                              },
                                              {
                                                value: "4",
                                              },
                                              {
                                                value: "5",
                                              },
                                              {
                                                value: "6",
                                              },
                                              {
                                                value: "7",
                                              },
                                              {
                                                value: "8",
                                              },
                                              {
                                                value: "9",
                                              },
                                            ].map((value) => {
                                              return (
                                                <MenuItem
                                                  key={value.value}
                                                  value={value.value}
                                                >
                                                  {value.value}
                                                </MenuItem>
                                              );
                                            })}
                                          </Field>
                                        </Grid>
                                        <Grid xs={5} item>
                                          <Field
                                            select
                                            component={InputFieled}
                                            name="children"
                                            onChange={handleChange}
                                            value={values.children}
                                            fullWidth
                                            label="Children(2yr - 12yr)"
                                          >
                                            {[
                                              {
                                                value: "0",
                                              },
                                              {
                                                value: "1",
                                              },
                                              {
                                                value: "2",
                                              },
                                              {
                                                value: "3",
                                              },
                                              {
                                                value: "4",
                                              },
                                              {
                                                value: "5",
                                              },
                                              {
                                                value: "6",
                                              },
                                            ].map((value) => {
                                              return (
                                                <MenuItem
                                                  key={value.value}
                                                  value={value.value}
                                                >
                                                  {value.value}
                                                </MenuItem>
                                              );
                                            })}
                                          </Field>
                                        </Grid>
                                        <Grid xs={3} item>
                                          <Field
                                            component={InputFieled}
                                            name="infants"
                                            placeholder="No. of Children"
                                            onChange={handleChange}
                                            select
                                            value={values.infants}
                                            fullWidth
                                            label="Infant(< 2yr)"
                                          >
                                            {" "}
                                            {[
                                              {
                                                value: "0",
                                              },
                                              {
                                                value: "1",
                                              },
                                              {
                                                value: "2",
                                              },
                                              {
                                                value: "3",
                                              },
                                              {
                                                value: "4",
                                              },
                                              {
                                                value: "5",
                                              },
                                              {
                                                value: "6",
                                              },
                                            ].map((value) => {
                                              return (
                                                <MenuItem
                                                  key={value.value}
                                                  value={value.value}
                                                >
                                                  {value.value}
                                                </MenuItem>
                                              );
                                            })}
                                          </Field>
                                        </Grid>

                                      </Grid>
                                    </Grid> */}

                                    <Grid xs={12} md={4} item>
                                      <div className={classes.customSelect}>
                                        <Typography className={classes.travellerheading}>Travellers</Typography>
                                        <Button onClick={this.dropDownSelect} className={classes.InputFieldSelect}>Adult {this.state.counter} | Child {this.state.counter1} | Infant {this.state.counter2}</Button>
                                        <Box className={classes.dropdownIcon}><ArrowDropDownIcon /></Box>
                                        {this.state.SelectOption &&
                                          <ul className={classes.DropdownMenu}>
                                            {this.state.PasangerValus.map((data, index) => {
                                              return (
                                                <li className={classes.Dropdown}>
                                                  <div className={classes.DropDownList} onClick={(event) => this.dropDowmalues(event, data.name)}>
                                                    <p className={classes.DropDownMenu1}>{data.name}</p>
                                                    <ButtonGroup size="small" aria-label="small outlined button group" className={classes.DropDownMenu2}>
                                                      <Button onClick={(e) => this.handleDecrement(data.name, 'oneWay')}
                                                        disabled={data.name === 'Adult' && this.state.counter <= 1 ? true :
                                                          data.name === 'Child' && this.state.counter1 <= 0 ? true :
                                                            data.name === 'Infant' && this.state.counter2 <= 0 ? true : false} style={{ fontSize: '15px' }} style={{ backgroundColor: '#ddede9' }}><RemoveIcon style={{ fontSize: "1rem" }} /></Button>
                                                      <Button disabled >
                                                        {data.name === 'Adult' ? this.state.counter : data.name === 'Child' ? this.state.counter1 : this.state.counter2}
                                                      </Button>
                                                      <Button onClick={() => this.handleIncrement(data.name, 'oneWay')}
                                                        disabled={data.name === 'Infant' && this.state.counter2 >= this.state.counter ? true : false} style={{ backgroundColor: '#ddede9' }}><AddIcon style={{ fontSize: "1rem" }} /></Button>
                                                    </ButtonGroup>
                                                  </div>
                                                </li>
                                              )
                                            })}
                                            {
                                              this.state.travellerCountStatus ?
                                                <li className={classes.Dropdown}>
                                                  <Typography className={classes.countText}>you can book for a maximum of 9 passangers</Typography >
                                                </li>
                                                :
                                                null
                                            }
                                          </ul>}
                                      </div>
                                      {/* <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Travellers"
                                      >
                                        {[
                                         
                                          //   <ButtonGroup size="small" aria-label="small outlined button group">
                                          //     <Button onClick={this.handleIncrement}>+</Button>
                                          //     {displayCounter && <Button disabled>{this.state.counter}</Button>}
                                          //     {displayCounter && <Button onClick={this.handleDecrement}>-</Button>}
                                          //   </ButtonGroup>
                                          // ,
                                          {
                                            value: "Adult",
                                          },
                                          {
                                            value: "Child",
                                          },
                                          {
                                            value: "Infant",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field> */}
                                    </Grid>

                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Category"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={12} md={4} item>
                                    </Grid>

                                    <Grid container className={classes.boederedTop} spacing={2} onClick={() => this.setState({ SelectOption: false })}>
                                      <Grid xs={12} md={6} item>
                                      </Grid>
                                      <Grid xs={12} md={3} item>
                                        {/* <Box py={3}> */}
                                        <Button
                                          type="reset"
                                          fullWidth
                                          variant="outlined"
                                          color="primary"
                                          onClick={() => this.ClearDataForm('oneway', setFieldValue)}
                                        >
                                          Clear
                                        </Button>
                                        {/* </Box> */}
                                      </Grid>
                                      <Grid xs={12} md={3} item>
                                        {/* <Box py={3}> */}
                                        <Button
                                          type="submit"
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                        >
                                          Search
                                        </Button>
                                        {/* </Box> */}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </TabPanel>
                        <TabPanel
                          value={this.state.selectedTravellingType}
                          index={"twoway"}
                        >
                          <Formik
                            initialValues={{
                              from: "",
                              to: "",
                              depratureDate: "",
                              returnDate: "",
                              adults: 1,
                              children: 0,
                              infants: 0,
                              class: "Economy",
                            }}
                            validationSchema={flightTwoWaySchema}
                            onSubmit={(values) => {
                              values.adults = this.state.twoWayCounterAdult,
                                values.children = this.state.twoWayCounterChild,
                                values.infants = this.state.twoWayCounterInfant,
                                console.log("two ways values", values)
                              this.goToSelectedRoute(
                                "/flightSearchResult",
                                values
                              );
                            }}
                          >
                            {(formikProps) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                              } = formikProps;
                              return (
                                <Form autoComplete="off">
                                  <Grid container justify="center" spacing={2}>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        style={{ width: "100%" }}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) => {
                                          if (Object.keys(value).length > 0) {
                                            return `${value.city || ''},${value.country || ''} (${value.iata || ''})`
                                          }
                                          else {
                                            return ''
                                          }
                                        }
                                        }
                                        options={
                                          this.state.sourcesearchSuggestionList
                                        }
                                        loading={false}
                                        onInputChange={(evt) => {
                                          if (evt?.target?.value) {
                                            setFieldTouched("from", true);
                                            setFieldValue("from", "");
                                            // this.getFromLocationSuggestion(
                                            //   evt.target.value
                                            // );
                                            this.SearchFlightFromCity(evt?.target?.value)
                                          }
                                          else {
                                            setFieldTouched("from", false)
                                          };
                                        }}
                                        onChange={(evt, value) => {
                                          setFieldValue(
                                            "from",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            sourceSuggestionloading: false,
                                            fromCity: value,
                                          });
                                        }}
                                        value={this.state.fromCity}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="From"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            // required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .sourceSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {/* {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  } */}
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.from && touched.from && (
                                        <Typography color="error">
                                          {errors.from}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        style={{ width: "100%" }}
                                        options={
                                          this.state
                                            .destinationsearchSuggestionList
                                        }
                                        loading={false}
                                        // onInputChange={(evt) => {
                                        //   this.getDestinationLocationSuggestion(
                                        //     evt.target.value
                                        //   );
                                        // }}
                                        onInputChange={(evt) => {
                                          if (evt?.target?.value) {
                                            setFieldTouched("to", true);
                                            setFieldValue("to", "");
                                            // this.getDestinationLocationSuggestion(
                                            //   evt.target.value
                                            // );
                                            this.SearchFlightToCity(evt?.target?.value)
                                          }

                                        }}
                                        onChange={(evt, value) => {
                                          setFieldValue(
                                            "to",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            destinationSuggestionloading: false,
                                            toCity: value,
                                          });
                                        }}
                                        value={this.state.toCity}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) => {
                                          if (Object.keys(value).length > 0) {
                                            return `${value.city || ''},${value.country || ''} (${value.iata || ''})`
                                          }
                                          else {
                                            return ''
                                          }

                                        }

                                        }
                                        onBlur={() => {
                                          setFieldTouched("to", true);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="To"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            // required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .destinationSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {/* {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  } */}
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.to && touched.to && (
                                        <Typography color="error">
                                          {errors.to}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Field
                                        component={InputFieled}
                                        name="depratureDate"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: curDate,
                                          },
                                        }}
                                        placeholder="Enter Departure Date"
                                        onChange={handleChange}
                                        value={values.depratureDate}
                                        fullWidth
                                        className={classes.errorhandle}
                                        label="Departure Date"
                                      />
                                    </Grid>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Field
                                        component={InputFieled}
                                        name="returnDate"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: curDate,
                                          },
                                        }}
                                        placeholder="Enter Return Date"
                                        onChange={handleChange}
                                        value={values.returnDate}
                                        fullWidth
                                        className={classes.errorhandle}
                                        label="Return"
                                      />
                                    </Grid>
                                    <Grid xs={12} md={4} item>
                                      {/* <Grid container spacing={1}>
                                      <Grid xs={4} item>
                                      <Field
                                        component={InputFieled}
                                        select
                                        name="adults"
                                        onChange={handleChange}
                                        value={values.adults}
                                        fullWidth
                                        label="Adults(> 12yr)"
                                      >
                                        {[
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                          {
                                            value: "7",
                                          },
                                          {
                                            value: "8",
                                          },
                                          {
                                            value: "9",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={10} md={4} item>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Category"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <Field
                                        component={InputFieled}
                                        name="infants"
                                        placeholder="No. of Children"
                                        onChange={handleChange}
                                        select
                                        value={values.infants}
                                        fullWidth
                                        label="Infant(< 2yr)"
                                      >
                                        {" "}
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                                      </Grid> */}

                                      <div className={classes.customSelect}>
                                        <Typography className={classes.travellerheading}>Travellers</Typography>
                                        <Button onClick={this.dropDownSelect} className={classes.InputFieldSelect}>Adult {this.state.twoWayCounterAdult} | Child {this.state.twoWayCounterChild} | Infant {this.state.twoWayCounterInfant}</Button>
                                        <Box className={classes.dropdownIcon}><ArrowDropDownIcon /></Box>
                                        {this.state.SelectOption &&
                                          <ul className={classes.DropdownMenu}>
                                            {this.state.PasangerValus.map((data, index) => {
                                              return (
                                                <li className={classes.Dropdown}>
                                                  <div className={classes.DropDownList} onClick={(event) => this.dropDowmalues(event, data.name)}>
                                                    <p className={classes.DropDownMenu1}>{data.name}</p>
                                                    <ButtonGroup size="small" aria-label="small outlined button group" className={classes.DropDownMenu2}>
                                                      <Button onClick={() => this.handleDecrement(data.name, 'twoWay')}
                                                        disabled={data.name === 'Adult' && this.state.twoWayCounterAdult <= 1 ? true :
                                                          data.name === 'Child' && this.state.twoWayCounterChild <= 0 ? true :
                                                            data.name === 'Infant' && this.state.twoWayCounterInfant <= 0 ? true : false}><RemoveIcon style={{ fontSize: "1rem" }} /></Button>
                                                      <Button disabled>
                                                        {data.name === 'Adult' ? this.state.twoWayCounterAdult : data.name === 'Child' ? this.state.twoWayCounterChild : this.state.twoWayCounterInfant}
                                                      </Button>

                                                      <Button onClick={() => this.handleIncrement(data.name, 'twoWay')}
                                                        disabled={data.name === 'Infant' && this.state.twoWayCounterInfant >= this.state.twoWayCounterAdult ? true : false}><AddIcon style={{ fontSize: "1rem" }} /></Button>
                                                    </ButtonGroup>
                                                  </div>
                                                </li>
                                              )
                                            })}
                                            {
                                              this.state.travellerCountStatus ?
                                                <li className={classes.Dropdown}>
                                                  <Typography className={classes.countText}>you can book for a maximum of 9 passangers</Typography >
                                                </li>
                                                :
                                                null
                                            }
                                          </ul>}
                                      </div>
                                    </Grid>
                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Category"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                  </Grid>

                                  <Grid container className={classes.boederedTop} spacing={2} onClick={() => this.setState({ SelectOption: false })}>
                                    <Grid xs={12} md={6} item>
                                    </Grid>
                                    <Grid xs={12} md={3} item>
                                      {/* <Box py={3}> */}
                                      <Button
                                        type="reset"
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => this.ClearDataForm('twoway', setFieldValue)}
                                      >
                                        Clear
                                      </Button>
                                      {/* </Box> */}
                                    </Grid>
                                    <Grid xs={12} md={3} item>
                                      {/* <Box py={3}> */}
                                      <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                      >
                                        Search
                                      </Button>
                                      {/* </Box> */}
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </TabPanel>
                        <TabPanel
                          value={this.state.selectedTravellingType}
                          index={"multicity"}
                        >
                          <Formik
                            initialValues={{
                              newCity: [
                                { from: "", to: "", depratureDate: "" },
                              ],
                              adults: this.state.multiWayCounterAdult,
                              children: this.state.multiWayCounterChild,
                              infants: this.state.multiWayCounterInfant,
                              class: "Economy",
                            }}
                            enableReinitialize
                            validationSchema={flightMultiCitySchema}
                            onSubmit={(values) => {
                              // this.goToSelectedRoute(
                              //   "/flightSearchResult",
                              //   values
                              // );
                            }}
                          >
                            {(formikProps) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError,
                              } = formikProps;
                              return (
                                <Form autoComplete="off">
                                  <Grid container justify="center" spacing={2}>
                                    <FieldArray
                                      name="newCity"
                                      render={(arrayHelpers) => (
                                        <>
                                          {values.newCity &&
                                            values.newCity.length > 0 ? (
                                            values.newCity.map(
                                              (newcity, index) => (
                                                <React.Fragment key={index}>
                                                  <Grid xs={12} item>
                                                    {values?.newCity?.length > 1 &&
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignSelf: "center",
                                                          alignItems: "center"

                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            flexGrow: 1,
                                                            alignSelf: "center",
                                                            alignItems: "center"
                                                          }}
                                                        ><Divider></Divider></div>
                                                        <IconButton
                                                          onClick={() => {
                                                            if (
                                                              values?.newCity
                                                                .length > 1
                                                            ) {
                                                              arrayHelpers.remove(
                                                                index
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          <HighlightOffIcon></HighlightOffIcon>
                                                        </IconButton>
                                                      </div>}
                                                  </Grid>
                                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                                    <Autocomplete
                                                      freeSolo
                                                      filterOptions={(
                                                        options,
                                                        state
                                                      ) => options}
                                                      style={{ width: "100%" }}
                                                      renderOption={(value) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                flexGrow: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                gutterBottom
                                                                color="textPrimary"
                                                              >
                                                                {value.city}
                                                              </Typography>
                                                              <Typography color="textSecondary">
                                                                {value.name}
                                                              </Typography>
                                                            </div>
                                                            <Typography>
                                                              {value.iata}
                                                            </Typography>
                                                          </div>
                                                        );
                                                      }}
                                                      getOptionLabel={(value) => {
                                                        if (Object.keys(value).length > 0) {
                                                          return `${value.city || ''},${value.country || ''} (${value.iata || ''})`
                                                        }
                                                        else {
                                                          return ''
                                                        }
                                                      }
                                                      }
                                                      options={
                                                        this.state
                                                          .sourcesearchSuggestionList
                                                      }
                                                      loading={false}
                                                      onInputChange={(evt) => {
                                                        if (evt?.target?.value) {
                                                          setFieldTouched(
                                                            `newCity[${index}].from`,
                                                            true
                                                          );
                                                          setFieldValue(
                                                            `newCity[${index}].from`,
                                                            ""
                                                          );
                                                          // this.getFromLocationSuggestion(
                                                          //   evt.target.value
                                                          // );
                                                          this.SearchFlightFromCity(evt?.target?.value)
                                                        }
                                                      }}
                                                      onChange={(
                                                        evt,
                                                        value
                                                      ) => {
                                                        setFieldValue(
                                                          `newCity[${index}].from`,
                                                          JSON.stringify(value)
                                                        );
                                                        this.setState({
                                                          sourceSuggestionloading: false,
                                                        });
                                                      }}
                                                      onBlur={() => {
                                                        setFieldTouched(
                                                          `newCity[${index}].from`,
                                                          true
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          fullWidth
                                                          placeholder="Enter Airport or City Name"
                                                          variant="outlined"
                                                          label="From"
                                                          InputLabelProps={{
                                                            ...params.InputLabelProps,
                                                            shrink: true,
                                                            classes: {
                                                              asterisk:
                                                                classes?.astriklabel,
                                                              outlined:
                                                                classes?.outlinedLabel,
                                                              shrink:
                                                                classes?.shrink,
                                                              root: classes?.lableroot,
                                                            },
                                                          }}
                                                          // required
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            classes: {
                                                              input:
                                                                classes?.input,
                                                              root: classes?.inputfieledroot,
                                                            },
                                                            notched: false,
                                                            endAdornment: (
                                                              <React.Fragment>
                                                                {this.state
                                                                  .sourceSuggestionloading ? (
                                                                  <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                  />
                                                                ) : null}
                                                                {/* {
                                                                  <InputAdornment position="end">
                                                                    <IconButton aria-label="search">
                                                                      <SearchIcon />
                                                                    </IconButton>
                                                                  </InputAdornment>
                                                                }  */}
                                                              </React.Fragment>
                                                            ),
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                    {errors?.newCity &&
                                                      touched?.newCity &&
                                                      errors?.newCity[index]
                                                        ?.from &&
                                                      touched?.newCity[index]
                                                        ?.from && (
                                                        <Typography color="error">
                                                          {
                                                            errors?.newCity[
                                                              index
                                                            ]?.from
                                                          }
                                                        </Typography>
                                                      )}
                                                  </Grid>
                                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                                    <Autocomplete
                                                      freeSolo
                                                      filterOptions={(
                                                        options,
                                                        state
                                                      ) => options}
                                                      style={{ width: "100%" }}
                                                      options={
                                                        this.state
                                                          .destinationsearchSuggestionList
                                                      }
                                                      loading={false}
                                                      // onInputChange={(evt) => {
                                                      //   this.getDestinationLocationSuggestion(
                                                      //     evt.target.value
                                                      //   );
                                                      // }}
                                                      onInputChange={(evt) => {
                                                        setFieldTouched(
                                                          `newCity[${index}].to`,
                                                          true
                                                        );
                                                        setFieldValue(
                                                          `newCity[${index}].to`,
                                                          ""
                                                        );
                                                        // this.getDestinationLocationSuggestion(
                                                        //   evt.target.value
                                                        // );
                                                        this.SearchFlightToCity(evt?.target?.value)
                                                      }}
                                                      onChange={(
                                                        evt,
                                                        value
                                                      ) => {
                                                        setFieldValue(
                                                          `newCity[${index}].to`,
                                                          JSON.stringify(value)
                                                        );
                                                        this.setState({
                                                          destinationSuggestionloading: false,
                                                        });
                                                      }}
                                                      renderOption={(value) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                flexGrow: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                gutterBottom
                                                                color="textPrimary"
                                                              >
                                                                {value.city}
                                                              </Typography>
                                                              <Typography color="textSecondary">
                                                                {value.name}
                                                              </Typography>
                                                            </div>
                                                            <Typography>
                                                              {value.iata}
                                                            </Typography>
                                                          </div>
                                                        );
                                                      }}
                                                      getOptionLabel={(value) =>
                                                        `${value.city},${value.country} (${value.iata})`
                                                      }
                                                      onBlur={() => {
                                                        setFieldTouched(
                                                          `newCity[${index}].to`,
                                                          true
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          fullWidth
                                                          placeholder="Enter Airport or City Name"
                                                          variant="outlined"
                                                          label="To"
                                                          InputLabelProps={{
                                                            ...params.InputLabelProps,
                                                            shrink: true,
                                                            classes: {
                                                              asterisk:
                                                                classes?.astriklabel,
                                                              outlined:
                                                                classes?.outlinedLabel,
                                                              shrink:
                                                                classes?.shrink,
                                                              root: classes?.lableroot,
                                                            },
                                                          }}
                                                          // required
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            classes: {
                                                              input:
                                                                classes?.input,
                                                              root: classes?.inputfieledroot,
                                                            },
                                                            notched: false,
                                                            endAdornment: (
                                                              <React.Fragment>
                                                                {this.state
                                                                  .destinationSuggestionloading ? (
                                                                  <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                  />
                                                                ) : null}
                                                                {/* {
                                                                  <InputAdornment position="end">
                                                                    <IconButton aria-label="search">
                                                                      <SearchIcon />
                                                                    </IconButton>
                                                                  </InputAdornment>
                                                                } */}
                                                              </React.Fragment>
                                                            ),
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                    {errors?.newCity &&
                                                      touched?.newCity &&
                                                      errors?.newCity[index]
                                                        ?.to &&
                                                      touched?.newCity[index]
                                                        ?.to && (
                                                        <Typography color="error">
                                                          {
                                                            errors?.newCity[
                                                              index
                                                            ]?.to
                                                          }
                                                        </Typography>
                                                      )}
                                                  </Grid>
                                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                                    <Field
                                                      component={InputFieled}
                                                      name={`newCity[${index}].depratureDate`}
                                                      type="date"
                                                      InputProps={{
                                                        inputProps: {
                                                          min: curDate,
                                                        },
                                                      }}
                                                      placeholder="Enter Departure Date"
                                                      fullWidth
                                                      className={classes.errorhandle}
                                                      label="Departure Date"
                                                    />
                                                  </Grid>
                                                  {index ===
                                                    values?.newCity.length -
                                                    1 && (
                                                      <Grid xs={10} item>
                                                        <Typography
                                                          className={
                                                            classes.addNewCity
                                                          }
                                                          gutterBottom
                                                          onClick={() => {
                                                            if (
                                                              values?.newCity
                                                                .length < 3
                                                            ) {
                                                              arrayHelpers.push({
                                                                from: "",
                                                                to: "",
                                                                depratureDate: "",
                                                              });
                                                            } else {
                                                              this.props.showAlert(
                                                                {
                                                                  title: "Error",
                                                                  message:
                                                                    "Maximum 3 city can be added",
                                                                }
                                                              );
                                                            }
                                                          }}
                                                          align="center"
                                                        >
                                                          Add Another City
                                                        </Typography>
                                                      </Grid>
                                                    )}
                                                </React.Fragment>
                                              )
                                            )
                                          ) : (
                                            <Typography
                                              className={classes.addNewCity}
                                              gutterBottom
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  from: "",
                                                  to: "",
                                                  depratureDate: "",
                                                })
                                              }
                                              align="center"
                                            >
                                              Add Another City
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    />
                                  </Grid>
                                  <Grid container justify="center" spacing={2}>
                                    <Grid xs={12} md={4} item>
                                      {/* <Grid container spacing={1}>
                                      <Grid xs={4} item>
                                      <Field
                                        component={InputFieled}
                                        select
                                        name="adults"
                                        onChange={handleChange}
                                        value={values.adults}
                                        fullWidth
                                        label="Adults(> 12yr)"
                                      >
                                        {[
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                          {
                                            value: "7",
                                          },
                                          {
                                            value: "8",
                                          },
                                          {
                                            value: "9",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={5} item>
                                      <Field
                                        select
                                        component={InputFieled}
                                        name="children"
                                        onChange={handleChange}
                                        value={values.children}
                                        fullWidth
                                        label="Children(2yr - 12yr)"
                                      >
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <Field
                                        component={InputFieled}
                                        name="infants"
                                        placeholder="No. of Children"
                                        onChange={handleChange}
                                        select
                                        value={values.infants}
                                        fullWidth
                                        label="Infant(< 2yr)"
                                      >
                                        {" "}
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                                      </Grid> */}
                                      <div className={classes.customSelect}>
                                        <Typography className={classes.travellerheading}>Travellers</Typography>
                                        <Button onClick={this.dropDownSelect} className={classes.InputFieldSelect}>Adult {this.state.multiWayCounterAdult} | Child {this.state.multiWayCounterChild} | Infant {this.state.multiWayCounterInfant}</Button>
                                        <Box className={classes.dropdownIcon}><ArrowDropDownIcon /></Box>
                                        {this.state.SelectOption &&
                                          <ul className={classes.DropdownMenu}>
                                            {this.state.PasangerValus.map((data, index) => {
                                              return (
                                                <li className={classes.Dropdown}>
                                                  <div className={classes.DropDownList} onClick={(event) => this.dropDowmalues(event, data.name)}>
                                                    <p className={classes.DropDownMenu1}>{data.name}</p>
                                                    <ButtonGroup size="small" aria-label="small outlined button group" className={classes.DropDownMenu2}>
                                                      <Button onClick={() => this.handleDecrement(data.name, 'multiWay')}
                                                        disabled={data.name === 'Adult' && this.state.multiWayCounterAdult <= 1 ? true :
                                                          data.name === 'Child' && this.state.multiWayCounterChild <= 0 ? true :
                                                            data.name === 'Infant' && this.state.multiWayCounterInfant <= 0 ? true : false}><RemoveIcon style={{ fontSize: "1rem" }} /></Button>
                                                      <Button disabled>
                                                        {data.name === 'Adult' ? this.state.multiWayCounterAdult : data.name === 'Child' ? this.state.multiWayCounterChild : this.state.multiWayCounterInfant}
                                                      </Button>
                                                      <Button onClick={() => this.handleIncrement(data.name, 'multiWay')}
                                                        disabled={data.name === 'Infant' && this.state.multiWayCounterInfant >= this.state.multiWayCounterAdult ? true : false}><AddIcon style={{ fontSize: "1rem" }} /></Button>
                                                    </ButtonGroup>
                                                  </div>
                                                </li>
                                              )
                                            })}
                                            {
                                              this.state.travellerCountStatus ?
                                                <li className={classes.Dropdown}>
                                                  <Typography className={classes.countText}>you can book for a maximum of 9 passangers</Typography >
                                                </li>
                                                :
                                                null
                                            }


                                          </ul>}
                                      </div>
                                    </Grid>

                                    <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Class"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={12} md={4} item>
                                    </Grid>
                                    <Grid container className={classes.boederedTop} spacing={2} onClick={() => this.setState({ SelectOption: false })}>
                                      <Grid xs={10} md={6} item>
                                      </Grid>
                                      <Grid xs={10} md={3} item>
                                        {/* <Box py={3}> */}
                                        <Button
                                          type="reset"
                                          fullWidth
                                          variant="outlined"
                                          color="primary"
                                          onClick={() => this.ClearDataForm('multicity', setFieldValue)}
                                        >
                                          Clear
                                        </Button>
                                        {/* </Box> */}
                                      </Grid>
                                      <Grid xs={10} md={3} item>
                                        {/* <Box py={3}> */}
                                        <Button
                                          type="submit"
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                        >
                                          Search
                                        </Button>
                                        {/* </Box> */}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </TabPanel>
                      </TabPanel>
                    </Paper>
                  </Grid>

                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        {/* Offers List */}
        <Grid container spacing={4} direction='row' alignItems="flex-start" justify="space-around" className={classes.CustomerFlowLandingPage}>
          <Hidden smDown  >
            <Grid item xs={10}>

            </Grid>
            <Grid item xs={10}>

            </Grid>
            <Grid item xs={10}>

            </Grid>
            {this.state.selectedSearchType == "Flights" &&
              <>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
              </>
            }
          </Hidden>
          <Grid item xs={10}>
            <div style={{ flexWrap: "nowrap", display: "flex" }}>
              <Typography style={{ flexGrow: 1 }} display="inline" variant="h1" gutterBottom>
                Best Offers
              </Typography>
              {/* <Button variant="text" color="primary">
                See All Offer
              </Button> */}
            </div>
            <Typography variant="h4" className={classes.subText}>Find best offers and daily deals</Typography>
          </Grid>
          {/* <Grid item xs={10}>
            <Carousel
              swipeable={false}
              draggable={false}
              // showDots={true}
              responsive={responsive}
              ssr={false} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1500}
              keyBoardControl={true}
              // customTransition="all .5"
              transitionDuration={500}
              // containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
            >
              <Box className={classes.carouselCard}>
                <div className={classes.carouselImage} />
                <Typography gutterBottom>
                  Premium Getaways @ Rs. 2,999!
                </Typography>
                <Typography>Get flexible check-in Check out Date</Typography>
              </Box>

              <Box className={classes.carouselCard}>
                <div className={classes.carouselImage} />
                <Typography gutterBottom>
                  Premium Getaways @ Rs. 2,999!
                </Typography>
                <Typography>Get flexible check-in Check out Date</Typography>
              </Box>

              <Box className={classes.carouselCard}>
                <div className={classes.carouselImage} />
                <Typography gutterBottom>
                  Premium Getaways @ Rs. 2,999!
                </Typography>
                <Typography>Get flexible check-in Check out Date</Typography>
              </Box>
              <Box className={classes.carouselCard}>
                <div className={classes.carouselImage} />
                <Typography gutterBottom>
                  Premium Getaways @ Rs. 2,999!
                </Typography>
                <Typography>Get flexible check-in Check out Date</Typography>
              </Box>
              <Box className={classes.carouselCard}>
                <div className={classes.carouselImage} />
                <Typography gutterBottom>
                  Premium Getaways @ Rs. 2,999!
                </Typography>
                <Typography>Get flexible check-in Check out Date</Typography>
              </Box>
            </Carousel>
          </Grid>
           */}
          <Grid item xs={10} >
            <Grid container justify="space-between" spacing={2}>
              {this.state.offerList.length > 0 && this.state.offerList.map((offer) => {
                return <Grid key={offer.id} item md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Grid container direction="row" justifyContent="center" alignItems='center'>
                      <Grid item xs={12} md={5}>

                        <div className={classes.offerImage} style={{ backgroundImage: `url(${offer?.attributes?.images[0]})`, flex: 'auto' }} />
                      </Grid>
                      <Grid item xs={12} md={5} />
                    </Grid>
                    <Box>
                      <Typography variant="h5" className={classes.offerTitle}>
                        {offer?.attributes?.title || "Not Avaialble"}
                      </Typography>
                      <Typography variant="h5" className={classes.offerPrice}>@{offer?.attributes?.price || null}</Typography>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <Typography className={classes.OfferDescription}>
                            {offer?.attributes?.description || "Not Avaialble"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.arrowDisplay} >
                          <ArrowForwardIcon className={classes.arrowIcon}
                            onClick={() => {
                              this.props.showToast({
                                type: "success",
                                // message: "this is success msg",
                                message: "Will Update Soon",
                              })
                            }} />
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Grid>

              })}

            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid container justify="space-between" spacing={6}>
              <Grid item md={4} xs={12}>
                <Paper elevation={1} className={classes.PaperSize}>
                  <div style={{ textAlign: "center" }} className={classes.PaperSizeImage}>
                    <Avatar
                      style={{ display: "inline-block" }}
                      src={hotelImage}
                    />
                    <Avatar
                      style={{ display: "inline-block" }}
                      src={hotelImage}
                    />
                    <Avatar
                      style={{ display: "inline-block" }}
                      src={hotelImage}
                    />
                  </div>
                  <Typography align="center">
                    <b>Friendly</b> customer support team
                  </Typography>
                  <div></div>
                  <br />
                </Paper>
              </Grid>
              <Grid item md={4} xs={12}>
                <Paper elevation={1} className={classes.PaperSize}>

                  <div style={{ textAlign: "center" }}>
                    <Typography gutterBottom>Trust Piolet</Typography>
                    <StyledRating value={3} />
                    <Typography gutterBottom>
                      <b>4.6 out of 5 stars</b> from 10k total reviews
                    </Typography>
                  </div>
                </Paper>

              </Grid>
              <Grid item md={4} xs={12}>
                <Paper elevation={1} className={classes.PaperSize}>
                  <div style={{ textAlign: "center" }} className={classes.PaperSizeImage}>
                    <Avatar
                      style={{ display: "inline-block" }}
                      src={hotelImage}
                    />
                  </div>
                  <Typography align="center">
                    We’re make sure <b>refund 100%</b> your money
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          {/* POPULAR DESTINATIONS */}
          <Grid xs={10} item>
            <Typography gutterBottom variant="h1">Popular Destinations</Typography>
            <Typography variant="h4" className={classes.subText}>
              Find best offers on the popular destinations
            </Typography>
          </Grid>
          <Grid xs={10} item>
            <Grid container justify="space-between" spacing={3}>
              <Grid item xs={12} md={4}>
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div className={classes.overlay} />
                  <Box px={2} pt={10} pb={2}>
                    <div className={classes.mainPostContent}>
                      <Grid container>
                        <Grid xs={12}>
                          <Typography display="inline" gutterBottom variant="h2">Chennai Flights</Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={7}>
                          <Typography >Get flexible Check-In & Check-Out date</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.seeOffer}>
                          <Button
                            variant="contained" className={classes.seeOfferBtn}
                            onClick={() => {
                              this.props.showToast({
                                type: "success",
                                // message: "this is success msg",
                                message: "Will Update Soon",
                              });
                            }}>See Offer
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div className={classes.overlay} />
                  <Box px={2} pt={10} pb={2}>
                    <div className={classes.mainPostContent}>
                      <Grid container>
                        <Grid xs={12}>
                          <Typography display="inline" gutterBottom variant="h2">Chennai Flights</Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={7}>
                          <Typography >Get flexible Check-In & Check-Out date</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.seeOffer}>
                          <Button
                            variant="contained" className={classes.seeOfferBtn}
                            onClick={() => {
                              this.props.showToast({
                                type: "success",
                                // message: "this is success msg",
                                message: "Will Update Soon",

                              });
                            }}>
                            See Offer
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} >
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div className={classes.overlay} />
                  <Box px={2} pt={10} pb={2}>
                    <div className={classes.mainPostContent}>
                      <Grid container>
                        <Grid xs={12}>
                          <Typography display="inline" gutterBottom variant="h2">Chennai Flights</Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={7}>
                          <Typography >Get flexible Check-In & Check-Out date</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.seeOffer}>
                          <Button
                            variant="contained" className={classes.seeOfferBtn}
                            onClick={() => {
                              this.props.showToast({
                                type: "success",
                                // message: "this is success msg",
                                message: "Will Update Soon",
                              });
                            }}>
                            See Offer
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              {/* data old code */}
              {/* <Grid item xs={12} md={4}>
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div className={classes.overlay} />
                  <Box px={2} pt={10} pb={2}>
                    <div className={classes.mainPostContent}>
                      <div style={{ flexWrap: "nowrap", display: "flex" }}>
                        <Typography
                          style={{ flexGrow: 1 }}
                          display="inline"
                          gutterBottom
                        >
                          Best Offers
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.props.showToast({
                              type: "success",
                              message: "this is success msg",
                            });
                          }}
                          style={{
                            backgroundColor: "#fff",
                            color: "blue",
                            borderRadius: "50px",
                            fontFamily: "Open Sans",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            color: "#1565d8",
                          }}
                        >
                          See Offer
                        </Button>
                      </div>
                      <Typography>Find best offers and daily deals</Typography>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div className={classes.overlay} />
                  <Box px={2} pt={10} pb={2}>
                    <div className={classes.mainPostContent}>
                      <div style={{ flexWrap: "nowrap", display: "flex" }}>
                        <Typography
                          style={{ flexGrow: 1 }}
                          display="inline"
                          gutterBottom
                        >
                          Best Offers
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.props.showToast({
                              type: "success",
                              message: "this is success msg",
                            });
                          }}
                          style={{
                            backgroundColor: "#fff",
                            color: "blue",
                            borderRadius: "50px",
                            fontFamily: "Open Sans",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            color: "#1565d8",
                          }}
                        >
                          See Offer
                        </Button>
                      </div>
                      <Typography>Find best offers and daily deals</Typography>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  className={classes.mainPost}
                  style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}
                >
                  {
                    <img
                      style={{ display: "none" }}
                      src={"https://source.unsplash.com/random"}
                      alt={"aaaaaaaaaaaaaaaa"}
                    />
                  }
                  <div className={classes.overlay} />
                  <Box px={2} pt={10} pb={2}>
                    <div className={classes.mainPostContent}>
                      <div style={{ flexWrap: "nowrap", display: "flex" }}>
                        <Typography
                          style={{ flexGrow: 1 }}
                          display="inline"
                          gutterBottom
                        >
                          Best Offers
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.props.showToast({
                              type: "success",
                              message: "this is success msg",
                            });
                          }}
                          style={{
                            backgroundColor: "#fff",
                            color: "blue",
                            borderRadius: "50px",
                            fontFamily: "Open Sans",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            color: "#1565d8",
                          }}
                        >
                          See Offer
                        </Button>
                      </div>
                      <Typography>Find best offers and daily deals</Typography>
                    </div>
                  </Box>
                </Paper>
              </Grid> */}
            </Grid>
          </Grid>
          {/* Agent Section  */}
          <Grid container className={classes.AgentBackGround}>
            <Grid item xs={12} >
              <Grid container spacing={2}>
                <Grid xs={12} md={7} item style={{ padding: '5rem' }}>
                  <Paper
                    className={classes.mainPost}
                    style={{
                      backgroundImage: `url(${AgentBanner})`,
                      height: "608px",
                      backgroundSize: 'cover'
                      // width: "791px",
                    }}
                  >
                    {
                      <img
                        style={{ display: "none" }}
                        src={"https://source.unsplash.com/random"}
                        alt={"aaaaaaaaaaaaaaaa"}
                      />
                    }
                    <div style={{ height: "300px" }} />
                    <div className={classes.agentFloatingSmallCards} style={{
                      position: 'absolute',
                      top: '289px',
                      right: '-20px',
                    }}>
                      <Typography color='textPrimary' className={classes.openSans16}>
                        Full Refunds & Much More
                      </Typography>
                    </div>
                    <div className={classes.agentFloatingSmallCards} style={{
                      position: 'absolute',
                      top: '368px',
                      right: '-50px',
                    }}>
                      <Typography color='textPrimary' className={classes.openSans16}>
                        Full Refunds & Much More
                      </Typography>
                    </div>
                    <div className={classes.agentFloatingSmallCards} style={{
                      position: 'absolute',
                      top: '447px',
                      right: '-20px',
                    }}>
                      <Typography color='textPrimary' className={classes.openSans16}>
                        Full Refunds & Much More
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
                <Grid md={5} xs={12} item container alignItems='center' style={{ padding: "5rem" }}>
                  <Typography gutterBottom variant="h1" className={classes.textColor}>
                    Join As An Agent & Grow Your Business
                  </Typography>
                  <Typography gutterBottom className={classes.textColor}>
                    Laudem et dolorem eum fugiat, quo pertineant non emolumento
                    aliquo, sed ut summo.
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        primary="Heading One"
                        secondary="Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown."
                        // className={classes.textColor}
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: `${classes.openSans16}${classes.whiteText}`
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.openSans16,
                        }}
                        primary="Heading One"
                        secondary="Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown."

                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.openSans16,
                        }}
                        primary="Heading One"
                        secondary="Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown."
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            {/* old code */}
            {/* Agent Section  */}
            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid md={7} xs={12} item>
                  <Paper
                    className={classes.mainPost}
                    style={{
                      backgroundImage: `url(${AgentBanner})`,
                      height: "608px",
                      backgroundSize: 'cover'
                      // width: "791px",
                    }}
                  >
                    {
                      <img
                        style={{ display: "none" }}
                        src={"https://source.unsplash.com/random"}
                        alt={"aaaaaaaaaaaaaaaa"}
                      />
                    }
                    <div style={{ height: "300px" }} />
                    <div className={classes.agentFloatingSmallCards} style={{
                      position: 'absolute',
                      top: '289px',
                      right: '-20px',
                    }}>
                      <Typography color='textPrimary' className={classes.openSans16}>
                        Full Refunds & Much More
                      </Typography>
                    </div>
                    <div className={classes.agentFloatingSmallCards} style={{
                      position: 'absolute',
                      top: '368px',
                      right: '-50px',
                    }}>
                      <Typography color='textPrimary' className={classes.openSans16}>
                        Full Refunds & Much More
                      </Typography>
                    </div>
                    <div className={classes.agentFloatingSmallCards} style={{
                      position: 'absolute',
                      top: '447px',
                      right: '-10px',
                    }}>
                      <Typography color='textPrimary' className={classes.openSans16}>
                        Full Refunds & Much More
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
                <Grid md={5} xs={12} item container alignItems='stretch' style={{ padding: "50px 80px" }}>
                  <Typography gutterBottom variant="h1" color="textPrimary">
                    Join As An Agent & Grow Your Business
                  </Typography>
                  <Typography gutterBottom color="textPrimary">
                    Laudem et dolorem eum fugiat, quo pertineant non emolumento
                    aliquo, sed ut summo.
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        primary="Heading One"
                        secondary="Certe, inquam, pertinax non numquam eius modi tempora incidunt, ut aut fugit, sed. At"
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.openSans16,
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.openSans16,
                        }}
                        primary="Heading One"
                        secondary="Certe, inquam, pertinax non numquam eius modi tempora incidunt, ut aut fugit, sed. At"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.openSans16,
                        }}
                        primary="Heading One"
                        secondary="Certe, inquam, pertinax non numquam eius modi tempora incidunt, ut aut fugit, sed. At"
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid> */}
            {/* Become An Agent Button */}
            <Grid xs={12}>
              <Grid container direction="row" justify="center" alignItems="center">
                <Button
                  variant="contained"
                  className={classes.AgentBtn}
                  onClick={() => {
                    this.openApplyForAgency();
                  }}
                >BECOME AN AGENT
                </Button>
              </Grid>
            </Grid>
            {/* Travelling Benefit Section */}
            <Grid item xs={10} style={{ margin: 'auto' }}>
              <Grid container justify="center" className={classes.benefitForAgenciesContainer}>
                <Grid xs={8} item>
                  <Typography
                    align="center"
                    gutterBottom
                    className={`${classes.groteskBold36} ${classes.whiteText} `}
                  >
                    Traveling Benefit for User
                  </Typography>
                  <Typography
                    align="center"
                    gutterBottom
                    className={classes.openSansn18}
                    color="textSecondary"
                  >
                    At vero eos et aut contra sit, voluptatem ut summum bonum esse
                    albam. At vero eos et fortibus viris commemorandis eorumque
                    factis non possim accommodare torquatos.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={10} item style={{ margin: 'auto', marginBottom: '3rem' }}>
              <Grid container justify="space-between" spacing={3}>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={cheapThanOtherIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Cheap than Other
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        YTravels is cheaper than other travel agency.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={securePaymentIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Secure Payment
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        You can pay your book without doubt again.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={bestOfferIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Best Offers
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        Inform you about all best offers for all destination
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={easyBookIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Easy Book
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        Follow flow, Click, Pay. Wait. Just wait e-ticket
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={greatAmenitiesIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Great Amenities
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        We guarantee you can give free amenities wherever you are.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={topRatedIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Top Rated
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        Only show best destination with 1000+ superb review user
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={fastRefundIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Fast Refund
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        If you canceled. We can refund your money 24x7
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={supportIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        24/7 Support
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        We’re ready help you anytime and anywhere you are.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* old code */}
            {/* Travelling Benefit Section */}
            {/* <Grid item xs={10}>
              <Grid container justify="center"  className={classes.benefitForAgenciesContainer}>
                <Grid xs={8} item>
                  <Typography
                    align="center"
                    gutterBottom
                    className={classes.groteskBold36}
                  // color="textPrimary"
                  >
                    Traveling Benefit for User
                  </Typography>
                  <Typography
                    align="center"
                    gutterBottom
                    className={classes.openSansn18}
                    color="textSecondary"
                  >
                    At vero eos et aut contra sit, voluptatem ut summum bonum esse
                    albam. At vero eos et fortibus viris commemorandis eorumque
                    factis non possim accommodare torquatos.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={10} item>
              <Grid container justify="space-between" spacing={3}>
                <Grid item container justify='center'  md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={cheapThanOtherIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Cheap than Other
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        YTravels is cheaper than other travel agency.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={securePaymentIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Secure Payment
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        You can pay your book without doubt again.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={bestOfferIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Best Offers
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        Inform you about all best offers for all destination
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={easyBookIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Easy Book
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        Follow flow, Click, Pay. Wait. Just wait e-ticket
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={greatAmenitiesIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Great Amenities
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        We guarantee you can give free amenities wherever you are.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={topRatedIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Top Rated
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        Only show best destination with 1000+ superb review user
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={fastRefundIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        Fast Refund
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        If you canceled. We can refund your money 24x7
                      </Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid item container justify='center' md={3} xs={12}>
                  <div className={classes.OfferItemContainer}>
                    <Box py={3} textAlign="center">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            display: "inline-block",
                            height: "64px",
                            width: "64px",
                            marginBottom: '20px'
                          }}
                          src={supportIcon}
                        />
                      </div>
                      <Typography
                        align="center"
                        gutterBottom
                        className={classes.groteskBold18}
                        color="textPrimary"
                      >
                        24/7 Support
                      </Typography>
                      <Typography align="center" gutterBottom className={classes.openSans16} color='textSecondary'>
                        We’re ready help you anytime and anywhere you are.
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid> */}

          </Grid>
        </Grid>
        <Footer />
      </div >
    );
  }
}
const CustomerFlowLandingPageWithRouter = withRouter(CustomerFlowLandingPage);
const CustomerFlowLandingPageAlertBox = withAlertBox(
  CustomerFlowLandingPageWithRouter
);
const CustomerFlowLandingPageLoader = withLoader(
  CustomerFlowLandingPageAlertBox
);
const CustomerFlowLandingPageToast = withToast(CustomerFlowLandingPageLoader);

const CustomerFlowLandingPageWithFontStyles = withFontStyles(CustomerFlowLandingPageToast)
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(2),
      backgroundImage: `url(${CustomerlandingPage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(6, 3),
      color: "#fff",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1),
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    helloAdminSubheading: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
    OfferItemContainer: {
      borderRadius: "8px",
      boxShadow: "2px 4px 34px 0 rgba(15, 24, 44, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: '16px',
      cursor: 'pointer'
    },
    // OfferItemContainer1: {
    //   boxShadow: "2px 4px 34px 0 rgba(15, 24, 44, 0.05)",
    // },
    offerImage: {
      // backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: theme.spacing(1),
    },
    carouselImage: {
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: theme.spacing(1),
    },
    carouselCard: {
      padding: theme.spacing(5),
      // borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      width: "284px",
      height: "284px",
    },
    addNewCity: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    inputfieledroot: {
      "label + &": {
        marginTop: 30,
      },
    },
    input: {
      position: "relative",
      backgroundColor: "#FFF",
      border: "none!important",
      height: "35px!important",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#959ead",
      padding: "1.5px 14px!important",
    },
    astriklabel: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#959ead",
      borderBottom: "none"
    },
    lableroot: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    PasangerValue: {
      position: 'absolute',
      right: '30px'
    },
    outlinedLabel: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    shrink: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    tabLabel: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: 'bold',
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: "#183b56",
      textTransform: 'capitalize'
    },
    benefitForAgenciesContainer: {

      [theme.breakpoints.up('md')]: {
        // marginTop: '225px',
        marginBottom: '40px'
      }
    },
    agentFloatingSmallCards: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 18px',
      height: '52px',
      borderRadius: '8px',
      boxShadow: '0 12px 16px 0 rgba(0, 0, 0, 0.08)',
      backgroundColor: '#ffffff',
    },
    // listItemPrimary: {
    //   fontSize: "16px",
    //   fontFamily: "Open Sans",
    //   fontWeight: 'bold',
    //   fontStretch: "normal",
    //   fontStyle: "normal",
    //   lineHeight: 1.22,
    //   letterSpacing: "normal",
    //   color: "#183b56",
    //   paddingBottom: '10px',
    //   '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     left: '-35px',
    //     width: '20px',
    //     height: '20px',
    //     display: 'block',
    //     backgroundColor: '#36b37e',
    //     borderRadius: '50%'
    //   }
    // },
    PaperSize: {
      height: "100%",
      padding: "3rem",
      border: "1px solid rgba(195,202,217, 1)",
      borderRadius: "20px",
      backgroundColor: "#F4F4F4",
      boxShadow: "none",
    },
    PaperSizeImage: {
      paddingBottom: '1rem',

    },
    seeOffer: {
      textAlign: 'end'
    },
    seeOfferBtn: {
      backgroundColor: "#fff",
      color: "#2762BD",
      borderRadius: "50px",
      fontFamily: "Open Sans",
      fontSize: "15px",
      fontWeight: "bolder",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1565d8",
      textAlign: 'end'
    },
    subText: {
      color: '#606C78'
    },
    textColor: {
      color: "#FFFFFF"
    },
    listItemPrimary: {
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 'bold',
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#FFFFFF",
      paddingBottom: '10px',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '-35px',
        width: '20px',
        height: '20px',
        display: 'block',
        backgroundColor: '#36b37e',
        borderRadius: '50%'
      }
    },
    customSelect: {
      position: 'relative',
      '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
        panddingTop: '8px'
      },
    },
    dropdownIcon: {
      position: 'absolute',
      bottom: '6px',
      right: '8px',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    InputFieldSelect: {
      textTransform: 'none',
      width: '100%',
      margin: '6px 0px 0px 0px',
      padding: '.6rem 7.5rem .6rem 0rem',
      padding: '10px',
      fontSize: '15px',
      fontWeight: '600',
      border: '1px solid #C3CAD9',
      borderRadius: '8px',
      backgroundColor: '#ffffff',
      paddingLeft: '10px',
      '&:hover': {
        border: "1px solid black",
        backgroundColor: '#ffffff',
      },
      '&:focus': {
        border: "2px solid #1565d8",
      },
      '& .MuiButton-label': {
        justifyContent: 'start'
      }
    },
    DropdownMenu: {
      padding: '0px',
      position: 'absolute',
      width: '100%',
      zIndex: '11',
      background: '#fff',
      boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      border: '1px solid #C3CAD9',
      padding: '10px',
      top: '66px',
      backgroundColor: '#ffffff'
    },
    Dropdown: {
      listStyle: 'none',
      marginBottom: '10px',
      backgroundColor: '#ffffff'
    },
    DropDownList: {
      display: 'flex'
    },
    DropDownMenu1: {
      flexBasis: '50%',
      display: "flex",
      alignItems: "center",
      margin: "0px",
    },
    DropDownMenu2: {
      flexBasis: '50%',
      justifyContent: 'end',
    },
    AgentBackGround: {
      background: '#131415', marginBottom: "-104px"
    },
    AgentBtn: {
      fontSize: '16px',
      color: '#131415',
      backgroundColor: '#FFFFFF',
      marginBottom: '2rem',
      '&:hover': {
        backgroundColor: '#131415',
        color: '#FFFFFF',
      },
    },
    CenterSpacing: {
      margin: '1.5rem auto'
    },
    boederedTop: {
      borderTop: '1px solid #cdcdcd',
      width: '100%',
      marginTop: '35px'
    },
    borderedBottom: {
      borderBottom: '1px solid #cdcdcd'
    },

    SelectedTab: {
      width: '50%',
    },
    paperClass: {
      background: "linear-gradient(to bottom, #dbeae7 0%, #ffffff 100%)",
      boxShadow: '0px 0px 36px 0px rgba(0,0,0,0.75)'
    },
    MuiSelectedTab: {
      '& .Mui-selected': {
        background: '#1565d8',
        borderRadius: '37px',
        border: '0px',
        color: '#fff',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent'
      }
    },
    arrowIcon: {
      color: '#1565D8',
    },
    countText: {
      color: "red"
    },
    arrowDisplay: {
      textAlign: 'end'
    },
    offerTitle: {
      color: "rgba(30,57,78,1)",
      fontSize: "16px",
      fontWeight: "700",
      margin: "10px 0px",
    },
    offerPrice: {
      color: "#1E394E",
      fontSize: "16px",
      fontWeight: "700",
      margin: "10px 0px",
    },
    errorhandle: {
      '& .MuiFormHelperText-root': {
        fontSize: '1rem'
      },
      '& .MuiFormHelperText-contained': {
        margin: '0'
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: '#5a7184'
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#959ead'
      }
    },
    OfferDescription: {
      color: "#606C78",
      fontSize: "14px",
      fontWeight: "500",
    },
    travellerheading: {
      color: '#5a7184',
      fontSize: '14px',
      fontWeight: '600',
      '&:focus': {
        // backgroundColor: '#131415',
        color: "1px solid blue !important",
        // color: '#FFFFFF',
      },
    },
    CustomerFlowLandingPage: {
      backgroundColor: "#F4F4F4",
    },
    mainPostHeight: {
      [theme.breakpoints.up('md')]: {
        height: '38rem'
      },
    }
  })
)(withDialog(CustomerFlowLandingPageWithFontStyles));