//@ts-nocheck
import React from "react";
import AgancyProfileDetailController, {
  Props,
} from "./AgancyProfileDetailController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";

class AgancyProfileDetail extends AgancyProfileDetailController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("agancyToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getProfileDetail()
        }
      );
    }
  }
  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify='center' alignItems='center' className={classes.pendIngRequestBox}>
            <Grid item md={11}>
              <Typography className={classes.pendIngTite}>
                My Profile
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.agencyPendingRequestContainer}>
            <Grid xs={11} item>
              <ListItem>
                <ListItemText
                  primary={`${this.state?.profileData?.agency_name  || "Not Available"}`}
                  secondary={`${this.state?.profileData?.email  || "Not Available"}`}
                  classes={{
                    primary: classes.agancyName,
                    secondary: classes.agancyEmail,
                  }}
                ></ListItemText>
              </ListItem>
            </Grid>
            <Grid xs={11} item style={{margin: '20px 0'}}>
              <Divider variant="middle"></Divider>
            </Grid>
            <Grid xs={11} item>
              <Grid container spacing={3} justify={"space-between"}>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Bookings Today"
                      secondary={`${this.state?.profileData?.flight_booking_today || 0} flights`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Status"
                      secondary={`${this.state?.profileData?.status  || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Credits"
                      secondary={`${this.state?.profileData?.balance  || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Contact Details"
                      secondary={`${this.state?.profileData?.agency_phone_numbeer  || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Address"
                      secondary={`${this.state?.profileData?.agency_address  || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Company Registration"
                      secondary={`${this.state?.profileData?.business_registration_number || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="VAT/PAN"
                      secondary={`${this.state?.profileData?.pan_number  || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Permission (NTB/) License No."
                      secondary={`${this.state.profileData?.permission_ntb_number || "Not Available"}`}
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
                <Grid item xs={6} md={4}>
                  <ListItem>
                    <ListItemText
                      primary="Member Since"
                      secondary='not Available'
                      classes={{
                        primary: classes.primaryName,
                        secondary: classes.secondaryName,
                      }}
                    ></ListItemText>
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={11} item className={classes.buttonGridItem}>
            <Grid container spacing={1} justify="flex-end">
                    <Grid item md={3} xs={6}>
                      <Button
                       variant="text"
                       color="primary"
                       fullWidth
                       onClick={()=>{this.props.history.push("/resetAgencyPassword?email="+this.state.profileData?.email)}}
                      >
                        Change Password
                      </Button>
                    </Grid>
                    <Grid item md={3} xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={()=>this.props.history.push("/AgancyDashborad/editProfileDetails")}
                      >
                        Edit Profile
                      </Button>
                    </Grid>
                  </Grid>
                 
              {/* <Grid container spacing={1} justify={"flex-end"}>
                  <Grid item xs={5}>
                  <Button color="primary" variant="text">Change Password</Button>
                        <Button color="primary" variant="contained" onClick={()=>{this.props.history.push("/AgancyDashborad/editProfileDetails")}}>Edit Profile</Button>
                  </Grid>
              </Grid> */}
              </Grid>
           
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const AgancyProfileDetailWithRouter = withRouter(AgancyProfileDetail);
const AgancyProfileDetailAlertBox = withAlertBox(AgancyProfileDetailWithRouter);
const AgancyProfileDetailLoader = withLoader(AgancyProfileDetailAlertBox);
const AgancyProfileDetailToast = withToast(AgancyProfileDetailLoader);
const AgancyProfileDetailWithDialog = withDialog(AgancyProfileDetailToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: theme.spacing(12)
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    formHeadingName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    agancyName: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "0.27px",
      color: "#183b56",
    },
    agancyEmail: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    agencyPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: '38px 39px 20px 44px',
    },
    primaryName: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryName: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    buttonGridItem: {
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(12),
      },
      // [theme.breakpoints.down("xs")]: {
      //   padding: theme.spacing(3),
      // },
    }
  })
)(AgancyProfileDetailWithDialog);