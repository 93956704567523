
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("../config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    openToast : any
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : string,
  seatData : Array<any>,
  selectedSeats : Array<any>
  selectedFlightTab: any;
  selectedFlight : any;
  selectedTraveller : number,
  flights : Array<any>
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SeatMapDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSeatDataApiCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : "",
      seatData : [],
      selectedSeats : [],
      selectedFlightTab: '',
      selectedFlight : null,
      selectedTraveller : 0,
      flights : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(this.getSeatDataApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes && responseJson?.data?.attributes?.seats?.length > 0) {
          const data = responseJson?.data;
          for(let i=0;i<data.attributes.seats.length; i++){
            const row = data.attributes.seats[i];
            const Facility = row.Facility?.filter((seat : any)=>{ return seat.Type !== 'Aisle'})
            for(let j=0;j<Facility.length;j++){
              const OptionalServices = data.attributes?.optional_services?.OptionalService || [];
              const OptionalService = OptionalServices.find((serive : any)=>{
                if(Facility[j]?.OptionalServiceRef && serive?.Key){
                  return serive?.Key === Facility[j]?.OptionalServiceRef
                }
                else 
                return false
              })
              if(OptionalService)
              data.attributes.seats[i].Facility[j].OptionalServices =  OptionalService
            }
          }
          await this.updateFlightsList(data?.attributes?.seats);
          this.setState({
            selectedFlight : {...this.state.selectedFlight,seatData: data?.attributes?.seats}
          })
        } else {

          this.props.dataToPass.openToast({type:'error',message : responseJson?.data?.attributes?.message})
          this.setState({
            selectedFlight : {...this.state.selectedFlight,seatData: []}
          })
          this.parseApiErrorResponse(responseJson)
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
    }
  }

  updateFlightsList = async (seatData : any) => {
    const flightIndex = this.state.flights.findIndex((flight)=>{return flight.Flight_Id === this.state?.selectedFlight?.Flight_Id})
    const flights = [...this.state.flights];
    flights[flightIndex] = {...flights[flightIndex],seatData : seatData}
    await this.setState({
      flights : [...flights]
    })
  }

  getSeatData = (flight: any) => { 
     this.props.showLoader();
     const headers = {
       "Content-Type": configJSON.ApiContentType,
       "token": this.state?.authToken
     };
   
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.getSeatDataApiCallId = requestMessage.messageId;
     const url = `?carrier=${flight?.flight_Carrier}&flight_number=${flight?.flight_Id}&origin=${flight?.Depature_Iata}&destination=${flight?.Arrival_Iata}&departure_datetime=${flight?.Depature_DateTime}&arrival_datetime=${flight?.Arrival_DateTime}&class_of_service=${flight?.ClassOfService}&air_ref_key=${flight?.Key}&group=${flight?.Group}`
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_advanced_search/travel_search/seat_map${url}`
     );
   
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(headers)
     );
   
     // requestMessage.addData(
     //   getName(MessageEnum.RestAPIRequestBodyMessage),
     //   JSON.stringify(httpBody)
     // );
   
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       'GET'
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
