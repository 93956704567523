//@ts-nocheck
import React from "react";
import AgancyResetPasswordController, {
  Props,
} from "./AgancyResetPasswordController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { signUPPoster, agancyCover } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { TextInput } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Footer from "./Footer.web";

const Schema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("This field is required"),
  confirmPassword: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

class AgancyResetPassword extends AgancyResetPasswordController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { pathname, search } = location;
    if (pathname === "/resetAgencyPassword") {
      const query = new URLSearchParams(search);
      const token = query.get("token");
      if (token) {
        this.setState({
          token: token,
        });
      }
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${agancyCover})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={agancyCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />

          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  Welcome Back
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid container spacing={4} justify="center">
          <Grid item xs={12}>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validationSchema={Schema}
              onSubmit={(values, actions) => {
                this.handlePasswordChange(values);
              }}
            >
              {(formikProps) => {
                const { isValid, dirty } = formikProps;
                return (
                  <Form noValidate autoComplete="off">
                    <Box px={12}>
                      <Grid container spacing={4}>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.welcomeBack}
                            gutterBottom
                          >
                            Reset Your Password
                          </Typography>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Grid container>
                            <Grid item md={8} xs={12}>
                              <Field
                                type="password"
                                fullWidth
                                component={InputField}
                                label="New Password"
                                name="password"
                                placeholder="Enter your new password"
                              />
                            </Grid>
                            <Grid item md={8} xs={12}>
                              <Field
                                type="password"
                                fullWidth
                                component={InputField}
                                label="Confirm New Password"
                                name="confirmPassword"
                                placeholder="Confirm your new password"
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={8} xs={12}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                          >
                            Save
                          </Button>
                          <Button style={{marginLeft : '16px'}} color="primary" onClick={()=>{this.props.history.goBack()}} variant="outlined">
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const AgancyResetPasswordWithRouter = withRouter(AgancyResetPassword);
const AgancyResetPasswordWithToast = withToast(AgancyResetPasswordWithRouter);
const AgancyResetPasswordWithLoader = withLoader(AgancyResetPasswordWithToast);
const AgancyResetPasswordWithAlertBox = withAlertBox(
  AgancyResetPasswordWithLoader
);

export default withStyles((theme) =>
  createStyles({
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: "url${flightCover}",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
      },
    },

    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
  })
)(AgancyResetPasswordWithAlertBox);
