import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import AdminDashboard from '../../blocks/dashboard/src/AdminDashboard.web';
import AcceptAgencyRequest from "../../blocks/dashboard/src/AcceptAgencyRequest.web";
import MyBookedHotelDetail from '../../blocks/dashboard/src/myBookedHotelDetail.web';
import MyBookingFlightDetails from '../../blocks/dashboard/src/MyBookingFlightDetails.web';

import CustomerFlowLandingPage from '../../blocks/dashboard/src/CustomerFlowLandingPage.web';
import FlightSearchResult from '../../blocks/dashboard/src/FlightSearchResult.web';
import HotelSearchResult from '../../blocks/dashboard/src/HotelSearchResult.web';
import MyProfileContainer from '../../blocks/dashboard/src/MyProfileContainer.web';
//agency screens
import AgencyMarketing from '../../blocks/dashboard/src/AgencyMarketing.web';
import ReviewFlight from '../../blocks/dashboard/src/ReviewFlight.web';
import AddTravellers from '../../blocks/dashboard/src/AddTravellers.web';
import MyBookingsContaier from '../../blocks/dashboard/src/MyBookingsContainer.web';
import CompleteProfileData from "../../blocks/dashboard/src/CompleteProfileData.web";
import AddAddons from "../../blocks/dashboard/src/AddAddons.web";
import AddAddonsAgency from "../../blocks/dashboard/src/AddAddonsAgency.web";
import PaymentSuccsess from "../../blocks/dashboard/src/Payment.web"
import PrivateRoute from "../../components/src/PrivateRoute.web";
import AgencyAdminPrivateDashborad from "../../components/src/AgencyAdminPrivateDashborad.web";
import AgancyDashborad from "../../blocks/dashboard/src/AgancyDashborad.web";
import AgancyPrivateRoute from "../../components/src/AgancyPrivateRoute.web";
import AdminPrivateRoute from "../../components/src/AdminPrivateRoute.web";
import SubAgencyDashboard from "../../blocks/dashboard/src/SubAgencyDashboard.web";
import AgancyResetPassword from "../../blocks/dashboard/src/AgancyResetPassword.web";
import FlightDetail from "../../blocks/dashboard/src/FlightDetail.web";
import ContactDetails from "../../blocks/dashboard/src/ContactDetails.web"
class RouteConfig extends Component {
  
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <CustomerFlowLandingPage />
        </Route>
        <Route exact path="/verifyAccount">
          <CustomerFlowLandingPage />
        </Route>
        <Route exact path="/resetPassword">
          <CustomerFlowLandingPage />
        </Route>
        <Route exact path="/forgotPassword">
          <CustomerFlowLandingPage />
        </Route>
        <Route exact path="/enterOtp">
          <CustomerFlowLandingPage />
        </Route>
        <Route exact path="/completeProfile">
          <CompleteProfileData />
        </Route>
        <PrivateRoute path="/myAccount">
          <MyProfileContainer />
        </PrivateRoute>
        <AdminPrivateRoute path="/AdminDashboard">
          <AdminDashboard />
        </AdminPrivateRoute>
        <AdminPrivateRoute path="/acceptAgancyRequest">
          <AcceptAgencyRequest />
        </AdminPrivateRoute>
        <Route path="/flightSearchResult">
          <FlightSearchResult />
        </Route>
        <Route path="/hotelSearchResult">
          <HotelSearchResult />
        </Route>
        <PrivateRoute path="/myBookings/Hotel">
          <MyBookedHotelDetail />
        </PrivateRoute>
        <Route path="/myBookings/Flight">
          <MyBookingFlightDetails />
        </Route>
        <Route path="/AgencyMarketing">
          <AgencyMarketing />
        </Route>
        <Route path="/reviewBooking">
          <ReviewFlight />
        </Route>
        <Route path="/addTravellers">
          <AddTravellers />
        </Route>
        <Route path="/addOns">
          <AddAddons />
        </Route>
        <Route path="/addOnsAgency">
          <AddAddonsAgency />
        </Route>
        <Route path="/paymentSuccessfull">
           <PaymentSuccsess>            
           </PaymentSuccsess>
        </Route>
        <PrivateRoute path="/userBookings">
          <MyBookingsContaier />
        </PrivateRoute>
        <AgancyPrivateRoute path="/AgancyDashborad">
            <AgancyDashborad></AgancyDashborad>
        </AgancyPrivateRoute>
        <Route path="/AdminLogin">
            <CustomerFlowLandingPage></CustomerFlowLandingPage>
        </Route>
        <AgencyAdminPrivateDashborad path="/subAgencyDashboard" >
            <SubAgencyDashboard/>
        </AgencyAdminPrivateDashborad>
        <Route path="/resetAgencyPassword" exact>
           <AgancyResetPassword></AgancyResetPassword>
        </Route>
        <Route path="/flightDetail" exact>
        <FlightDetail></FlightDetail>
        </Route>
        <Route path="/addTravellers" exact>
        <ContactDetails/>
        </Route>
      </Switch>
    );
  }
}
export default RouteConfig;
