//@ts-nocheck
import React from "react";
import PoliciesController, { Props } from "./PoliciesController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  MenuItem,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import InputField from "../../../components/src/InputFiled.web";
import withFontStyles from "./withFontStyles.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

class Policies extends PoliciesController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "authToken";
    const adminToken = await StorageProvider.get(tokenName);
    if (adminToken) {
      this.setState(
        {
          adminToken: adminToken,
        },
        () => {
          this.getTermsAndConditions();
        }
      );
    }
  }

  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };

  addNewPolicy = () => {
    this.props.history.push("/AdminDashboard/addNewPolicy");
  };

  editPolicy = (routeName, data) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data } });
  };

  deletePolicyUI = (id) => {
    const onClose = (result) => {
      if (result === "Yes") {
        this.deleteTermsAndCondition(id);
      }
    };
    this.props.openConfirmDialog(
      "Delete",
      "Are you sure you want to delete this Policy",
      onClose
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pendIngRequestBox}
          >
            <Grid item md={9}>
              <Typography className={classes.pendIngTite}>Policies</Typography>
            </Grid>
            <Grid item md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  this.addNewPolicy();
                }}
              >
                {" "}
                Add New{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {this.state.allTermsAndConditionsList.length > 0 ? (
          this.state.allTermsAndConditionsList.map((term) => {
            return (
              <Grid item key={term.id} xs={12}>
                <Grid
                  container
                  justify="space-around"
                  alignItems="center"
                  className={classes.subagencyContainer}
                >
                  <Grid md={8} xs={12} item>
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        primary="Description"
                        secondary={term.attributes.description || "NA"}
                        classes={{
                          primary: classes.primartText,
                          secondary: classes.secondaryText,
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid md={1} xs={12} item>
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        primary="Type"
                        secondary={term.attributes.t_and_c_type || "NA"}
                        classes={{
                          primary: classes.primartText,
                          secondary: classes.secondaryText,
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    md={2}
                    xs={12}
                    container
                    justify="flex-end"
                    item
                    style={{ alignSelf: "center" }}
                  >
                    <div className={classes.iconroot}>
                      <IconButton
                        onClick={() => {
                          this.editPolicy("editPolicy", {
                            policyData: { ...term.attributes },
                          });
                        }}
                        color="default"
                        aria-label="edit"
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          console.log(term.id);
                          this.deletePolicyUI(term.id);
                        }}
                        aria-label="delete"
                        color="default"
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item md={12}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.subagencyContainer}
              style={{ height: "70px" }}
            >
              <Grid item md={11}>
                <Typography className={classes.pendIngTite}>
                  No terms and conditions available
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const PoliciesWithRouter = withRouter(Policies);
const PoliciesAlertBox = withAlertBox(PoliciesWithRouter);
const PoliciesLoader = withLoader(PoliciesAlertBox);
const PoliciesToast = withToast(PoliciesLoader);
const PoliciesWithDialog = withDialog(PoliciesToast);
const PoliciesWithFontStyles = withFontStyles(PoliciesWithDialog);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: "100px",
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
      height: "91px",
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyemail: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    iconroot: {
      "& > *": {
        margin: theme.spacing(0.2),
      },
    },
  })
)(withConfirmBox(PoliciesWithFontStyles));
