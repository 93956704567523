//@ts-nocheck

import React from "react";
import TermsAndConditionsDialogController, {
  Props,
} from "./TermsAndConditionsDialogController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  DialogContent,
  Button,
  Box,
} from "@material-ui/core";
import { signUPPoster } from "./assets";
// import { arrow, } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { DialogTitle } from "../../../components/src/DialogContext";

class TermsAndConditionsDialog extends TermsAndConditionsDialogController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getTermsAndConditions();
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle id="simple-dialog-title" onCancel={this.props.onCancel}>
          Terms and Conditions
        </DialogTitle>
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12}>
            <Box py={8}>
              <Grid container justify="center" spacing={2}>
                

                <Grid item xs={10}>
                  <Grid container>
                    {this.state.allTermsAndConditionsList ? (
                         (
                          <Grid item  xs={12}>
                            <Typography>
                              {this.state.allTermsAndConditionsList || "NA"}
                            </Typography>
                          </Grid>
                        )
                    ) : (
                      <Grid item md={12}>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          className={classes.subagencyContainer}
                          style={{ height: "70px" }}
                        >
                          <Grid item md={11}>
                            <Typography className={classes.pendIngTite}>
                              No terms and conditions available
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {this.props.dataToPass.role !== "Agency" ? (
                  <Grid item xs={10}>
                    <Typography
                      style={{ paddingLeft: "8px" }}
                      align="center"
                      onClick={() => {
                        this.openSignUp();
                      }}
                      className={classes.buttonText}
                      display="inline"
                    >
                      Back to Sign Up
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    
      </>
      );
  }
}

const TermsAndConditionsDialogWithRouter = withRouter(TermsAndConditionsDialog);
const TermsAndConditionsDialogWithToast = withToast(
  TermsAndConditionsDialogWithRouter
);
const TermsAndConditionsDialogWithLoader = withLoader(
  TermsAndConditionsDialogWithToast
);
const TermsAndConditionsDialogWithAlertBox = withAlertBox(
  TermsAndConditionsDialogWithLoader
);
const TermsAndConditionsDialogWithDialogBox = withDialog(
  TermsAndConditionsDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundImage: `url(${signUPPoster})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: "pointer",
    },
  })
)(TermsAndConditionsDialogWithDialogBox);


