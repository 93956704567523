//@ts-nocheck
import React from "react";
import AddTravellersController, { Props } from "./AddTravellersController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  Button,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  Checkbox,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import moment, { RFC_2822 } from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import MainFlightPostWeb from "./MainFlightPost.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field, FieldArray } from "formik";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as Yup from "yup";
import Header from "./Header.web";
import Footer from "./Footer.web";
import { flighPlaceHolder, flightCover } from "./assets";
import { Redirect, RouterProps, Route } from "react-router-dom";
import withFontStyles from "./withFontStyles.web";
import FormikErrorFocus from 'formik-error-focus'
import "yup-phone";
import withDialogBox from "../../../components/src/withDialog.web";


const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
const infantDate = moment(new Date()).subtract(2, "years").format("YYYY-MM-DD").toString();
const TravvellSchema = Yup.object().shape({
  travellers: Yup.array().of(
    Yup.object().shape({
      traveling: Yup.boolean().required("This field is required."),
      first_name: Yup.string().required("First name is required as per passport.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      last_name: Yup.string().required("Last name is required as per passport.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      sex: Yup.string().required("Gender required."),
      frequentl_flayer_no: Yup.string(),
      isInfantHidden: Yup.boolean(),
      ispassportExpiry: Yup.boolean(),
      isPassport: Yup.boolean(),
      isNationality: Yup.boolean(),
      infants_dob: Yup.date().when("isInfantHidden", {
        is: false,
        then: Yup.date().required("Infants Date  is required."),
      }),
      pasport_number: Yup.string().when("isPassport", {
        is: false,
        then: Yup.string().required("Passport number required for international flights.")
          .matches(/^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$/, "Contains Uppercase and numbers ").min(8, "contains 8 minimum characters")
      }),
      passport_expiry_date: Yup.date().when("ispassportExpiry", {
        is: false,
        then: Yup.date().required("Expiry date required for international flights."),
      }),
      nationality: Yup.string().when("isNationality", {
        is: false,
        then: Yup.string().required("Nationality is required."),
      })


    })
  ),
  // contactNo: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("please enter valid email").required("This field is required."),
  clientsFullName: Yup.string(),
  businessContactNo: Yup.string()
});

class AddTravellers extends AddTravellersController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.data?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.data?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    let profileData = await StorageProvider.get("profileData");
    profileData = profileData && JSON.parse(profileData) || {};
    const authToken = await StorageProvider.get(tokenKeyName);
    if (
      authToken &&
      state?.data?.role &&
      state?.data?.entityType &&
      state?.data?.fromCity &&
      state?.data?.toCity &&
      state?.data?.searchData
    ) {
      this.setState(
        {
          authToken: authToken,
          entityType: state?.data?.entityType,
          fromCity: state?.data?.fromCity,
          toCity: state?.data?.toCity,
          searchData: state?.data?.searchData,
          entityDetail: state?.data?.entityDetail,
          loading: false,
          role: state?.data?.role,
          noOfTravellers: state?.noOfTravellers,
          selectedTravellingType: state?.data?.selectedTravellingType,
          selectedFlight2: state?.data?.selectedFlight2,
          currency: profileData.currency || "USD",
          couponDiscount: state?.data?.couponDiscount || null,
          contactNumber: "",
          phoneNumberError: false,
        },
        () => {
          this.getCountriesList();
          if (this.state.role === "Customer") {
            this.getProfileDetail();
          }
          if (this.state.selectedTravellingType === "oneway") {
            this.getFareSummary(this.state.searchData,
              this.state.entityDetail?.mergeAirSagmentBookingFlightInfo
            );
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getFareSummary(this.state.searchData, [
              ...this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              ...this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo,
            ]);
          }
        }
      );
    } else {
      this.setState({
        loading: false,
        authToken: null,
      });
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  toAPiConsumableData = (arr) => {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) rv["traveller_" + (i + 1)] = arr[i];
    return rv;
  };
  timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " hr" + " " + extraMin + " " + " min"
    } else {
      return Math.floor(diff.hours) + " hr"
    }
  }

  render() {
    const { searchData } = this.state;

    const noOfTravellers =
      (+searchData.adults || 0) +
      (+searchData.children || 0) +
      (+searchData.infants || 0);
    var trvallerList = []
    if (+searchData.adults || 0) {
      trvallerList = new Array(+searchData.adults).fill({
        type: 'ADT'
      })
    }
    if (+searchData.children || 0) {
      trvallerList = new Array(+searchData.children).fill({
        type: 'CNN'
      }).concat(trvallerList)
    }
    if (+searchData.infants || 0) {
      trvallerList = new Array(+searchData.infants).fill({
        type: 'INF'
      }).concat(trvallerList)
    }

    // trvallerList=trvallerList.sort(function(a, b) { return (a.type > b.type ? 1 : (a.type === b.type ? 0 : -1)) });
    const { classes } = this.props;
    let redirectRouteName = "/";
    if (this.state.role === "Admin") {
      redirectRouteName = "/AdminLogin";
    } else if (this.state.role === "Agency") {
      redirectRouteName = "/AgencyMarketing";
    }
    return !this.state.loading ? (
      this.state.authToken === null ? (
        <Redirect to={redirectRouteName} />
      ) : (
        <div className={classes.root}>
          <Paper
            className={classes.mainPost}
            style={{
              backgroundImage: `url(${flightCover})`,
              backgroundSize: "cover",
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={flightCover}
                alt={"aaaaaaaaaaaaaaaa"}
              />
            }
            <div className={classes.overlay} />
            {this.state.role && <Header role={this.state.role} />}
            <MainFlightPostWeb fromCity={this.state?.fromCity} toCity={this.state?.toCity} selectedTravellingType={this.state?.selectedTravellingType} searchData={this.state?.searchData} />
          </Paper>
          {/* End hero unit */}
          <Formik
            initialValues={{
              travellers: trvallerList.reverse().map((trvaller) => {
                const isInfant = trvaller.type == "INF";
                const isInternational = this.state.fromCity.country !== this.state.toCity.country;
                let allFields;
                let sharedFields = {
                  traveling: false,
                  first_name: "",
                  last_name: "",
                  sex: "",
                  frequentl_flayer_no: "",
                  type: trvaller.type,
                  key: uuidv4(),
                }
                let passportFields = {
                  ispassportExpiry: false,
                  isPassport: false,
                  isNationality: false,
                  pasport_number: "",
                  passport_expiry_date: "",
                  nationality: "",
                }
                if (isInternational) {
                  if (isInfant) {
                    allFields = {
                      ...sharedFields, ...passportFields,
                      isInfantHidden: false,
                      infants_dob: ""
                    }
                  } else {
                    allFields = { ...sharedFields, ...passportFields }
                  }
                } else {
                  if (isInfant) {
                    allFields = {
                      ...sharedFields,
                      isInfantHidden: false,
                      infants_dob: ""
                    }
                  } else {
                    allFields = { ...sharedFields }
                  }
                }
                return allFields
              }),
              contactNo: "",
              email: "",
              clientsFullName: "",
              businessContactNo: "",
            }}
            // enableReinitialize
            validationSchema={TravvellSchema}
            onSubmit={(values, actions) => {
              if (this.state?.contactNumber !== null && this.state?.contactNumber !== "" && this.state?.contactNumber !== undefined && this.state?.contactNumber.length > 5) {
                this.setState({
                  phoneNumberError: false,
                })
                const data = this.toAPiConsumableData(values.travellers);
                {
                  this.props.history?.location?.state.data?.role === 'Customer' ? (
                    this.handleTravellsInfoSave(data, values.contactNo, values.email, null, null)
                  ) : (
                    this.handleTravellsInfoSave(data, values.contactNo, values.email, values.clientsFullName, values.businessContactNo)
                  )
                }
              } else {
                this.setState({
                  phoneNumberError: true,
                })
              }
            }}
          >
            {(formikProps) => {
              const {
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              } = formikProps;
              return (
                <Form noValidate autocomplete="off">
                  <FormikErrorFocus
                    // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                    offset={0}
                    align={'top'}
                    focusDelay={200}
                    ease={'linear'}
                    duration={1000}
                  />
                  <Grid container justify="center" spacing={6} className={classes.addTravellersBackground}>
                    <FieldArray
                      name="travellers"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            <Grid item md={7} xs={11}>
                              <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <Grid container className={classes.flightBox}>
                                    <Grid item xs={12}>
                                      <Typography
                                        color="textPrimary"
                                        className={classes.reviewFlightHeading}
                                      >
                                        Flight Information
                                      </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                      <Divider className={classes.dividerStyle} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.flightBoxDetails}>
                                      {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight, index) => {
                                        return <>
                                          <Grid key={flight?.FlightNumber} item xs={12}>
                                            <Grid xs={12} item style={{ marginBottom: "20px" }}>
                                              <ListItem style={{ padding: 0 }}>
                                                <ListItemAvatar>
                                                  <img
                                                    className={classes.flightImage}
                                                    src={
                                                      flight?.Flight_Image
                                                    }
                                                  />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  classes={{
                                                    primary: classes.flightName,
                                                    secondary: classes.flightCode,
                                                  }}
                                                  primary={
                                                    flight?.Flight_Name || "NA"
                                                  }
                                                  secondary={`${flight?.Flight_carrier || "-"
                                                    }${flight?.Flight_Id || "-"}`}
                                                />
                                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state.fareSummaryDetail.total_price}` || 0} />
                                              </ListItem>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                              <Divider className={classes.dividerStyle} />
                                            </Grid>
                                            <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                                              <Grid
                                                container
                                                justify="space-between"
                                              >
                                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                                  <Typography
                                                    className={[
                                                      classes.flightDate,
                                                    ]}
                                                  >
                                                    {(flight?.Depature_DateTime &&
                                                      moment(
                                                        flight?.Depature_DateTime
                                                      ).format("ddd, DD MMM")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightPlaceMode}
                                                  >
                                                    {" "}
                                                    Departure{" "}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightTime}
                                                    color="textSecondary"
                                                  >
                                                    {(flight?.Depature_DateTime &&
                                                      moment(
                                                        (
                                                          flight?.Depature_DateTime
                                                        )
                                                      ).format("hh:mm A")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={[
                                                      classes.flightAddress,
                                                    ]}
                                                    color="textPrimary"
                                                  >
                                                    {" "}
                                                    {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                                      "NA"}{", "} {flight?.Depature_City ||
                                                        "NA"}
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={3}>
                                                  <div className={classes.linewraper}>
                                                    <Typography
                                                      color="primary"
                                                      className={classes.flightDiffTime}
                                                    >
                                                      {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                                    </Typography>
                                                    <ul className={classes.container}>
                                                      <li className={classes.linkconnector} />
                                                      <li className={classes.linkconnector} />
                                                    </ul>
                                                    <Typography
                                                      color="primary"
                                                      className={classes.flightDiffStop}
                                                    >
                                                      {/* {flight?.Mode_Of_Flight} */}
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                                  <Typography
                                                    className={[
                                                      classes.flightDate,
                                                    ]}
                                                  >
                                                    {" "}
                                                    {(flight?.Arrival_DateTime &&
                                                      moment(
                                                        flight?.Arrival_DateTime
                                                      ).format("ddd, DD MMM")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightPlaceMode}
                                                  >
                                                    {" "}
                                                    Arrival{" "}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightTime}
                                                    color="textSecondary"
                                                  >
                                                    {(flight?.Arrival_DateTime &&
                                                      moment(
                                                        (
                                                          flight?.Arrival_DateTime
                                                        )
                                                      ).format("hh:mm A")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={[
                                                      classes.flightAddress,
                                                    ]}
                                                    color="textPrimary"
                                                  >
                                                    {" "}
                                                    {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                                      "NA"}{", "} {flight?.Arrival_City ||
                                                        "NA"}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {index < (this.state.oneWayFlightList.length - 1) &&
                                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                                              <Divider className={classes.dividerStyle} />
                                              <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[index].stopName})</Typography>
                                              <Divider className={classes.dividerStyle} />
                                            </Grid>
                                          }
                                        </>
                                      })}
                                    </Grid>
                                    {this.state?.selectedTravellingType === "twoway" && <Grid item md={12} xs={12}>
                                      <Divider style={{ margin: "0px 20px", border: "1px dashed #1565d8" }} />
                                    </Grid>}
                                    {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight, index) => {
                                      return <>
                                        <Grid item xs={12} className={classes.flightBoxDetails}>
                                          <Grid key={flight?.FlightNumber} item xs={12}>
                                            <Grid xs={12} item style={{ marginBottom: "20px" }}>
                                              <ListItem style={{ padding: 0 }}>
                                                <ListItemAvatar>
                                                  <img
                                                    className={classes.flightImage}
                                                    src={
                                                      flight?.Flight_Image
                                                    }
                                                  />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  classes={{
                                                    primary: classes.flightName,
                                                    secondary: classes.flightCode,
                                                  }}
                                                  primary={
                                                    flight?.Flight_Name || "NA"
                                                  }
                                                  secondary={`${flight?.Flight_carrier || "-"
                                                    }${flight?.Flight_Id || "-"}`}
                                                />
                                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state.fareSummaryDetail.total_price}` || 0} />
                                              </ListItem>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                              <Divider className={classes.dividerStyle} />
                                            </Grid>
                                            <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                                              <Grid
                                                container
                                                justify="space-between"
                                              >
                                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                                  <Typography
                                                    className={[
                                                      classes.flightDate,
                                                    ]}
                                                  >
                                                    {(flight?.Depature_DateTime &&
                                                      moment(
                                                        flight?.Depature_DateTime
                                                      ).format("ddd, DD MMM")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightPlaceMode}
                                                  >
                                                    {" "}
                                                    Departure{" "}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightTime}
                                                    color="textSecondary"
                                                  >
                                                    {(flight?.Depature_DateTime &&
                                                      moment(
                                                        (
                                                          flight?.Depature_DateTime
                                                        )
                                                      ).format("hh:mm A")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={[
                                                      classes.flightAddress,
                                                    ]}
                                                    color="textPrimary"
                                                  >
                                                    {" "}
                                                    {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                                      "NA"}{", "} {flight?.Depature_City ||
                                                        "NA"}
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={3}>
                                                  <div className={classes.linewraper}>
                                                    <Typography
                                                      color="primary"
                                                      className={classes.flightDiffTime}
                                                    >
                                                      {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                                    </Typography>
                                                    <ul className={classes.container}>
                                                      <li className={classes.linkconnector} />
                                                      <li className={classes.linkconnector} />
                                                    </ul>
                                                    <Typography
                                                      color="primary"
                                                      className={classes.flightDiffStop}
                                                    >
                                                      {/* {flight?.Mode_Of_Flight} */}
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                                  <Typography
                                                    className={[
                                                      classes.flightDate,
                                                    ]}
                                                  >
                                                    {" "}
                                                    {(flight?.Arrival_DateTime &&
                                                      moment(
                                                        flight?.Arrival_DateTime
                                                      ).format("ddd, DD MMM")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightPlaceMode}
                                                  >
                                                    {" "}
                                                    Arrival{" "}
                                                  </Typography>
                                                  <Typography
                                                    className={classes.flightTime}
                                                    color="textSecondary"
                                                  >
                                                    {(flight?.Arrival_DateTime &&
                                                      moment(
                                                        (
                                                          flight?.Arrival_DateTime
                                                        )
                                                      ).format("hh:mm A")) ||
                                                      "NA"}
                                                  </Typography>
                                                  <Typography
                                                    className={[
                                                      classes.flightAddress,
                                                    ]}
                                                    color="textPrimary"
                                                  >
                                                    {" "}
                                                    {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                                      "NA"}{", "} {flight?.Arrival_City ||
                                                        "NA"}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {index < (this.state.twoWayFlightList.length - 1) &&
                                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                                              <Divider className={classes.dividerStyle} />
                                              <Typography align="center" className={classes.layoverText}> Layover {this.state.selectedFlight2?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.selectedFlight2?.stops[index].stopName})</Typography>
                                              <Divider className={classes.dividerStyle} />
                                            </Grid>
                                          }
                                        </Grid>
                                      </>
                                    })}
                                  </Grid>
                                </Grid>

                                {/* {this.state.selectedTravellingType === "twoway" ? (
                                  <Grid item xs={12}>
                                    <Grid container spacing={2} className={classes.flightBox}>
                                      <Grid item xs={12}>
                                        <Typography
                                          color="textPrimary"
                                          className={classes.groteskBold32}
                                        >
                                          Return Flight Information
                                        </Typography>
                                      </Grid>
                                      <Grid item md={12} xs={12}>
                                        <Divider variant="inset" />
                                      </Grid>
                                      {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight, index) => {
                                        return <>
                                          <Grid key={flight?.FlightNumber} item xs={12}>
                                            <Grid container spacing={6}>
                                              <Grid md={4} xs={12} item>
                                                <ListItem style={{ padding: 0 }}>
                                                  <ListItemAvatar>
                                                    <img
                                                      className={classes.flightImage}
                                                      src={
                                                        flight?.Flight_Image
                                                      }
                                                      style={{ marginRight: "10px" }}
                                                    />
                                                  </ListItemAvatar>
                                                  <ListItemText
                                                    classes={{
                                                      primary: classes.groteskBold24,
                                                      secondary: classes.openSans18,
                                                    }}
                                                    primary={
                                                      flight?.Flight_Name || "NA"
                                                    }
                                                    secondary={`${flight?.Flight_carrier || "-"
                                                      } | ${flight?.Flight_Id || "-"}`}
                                                  />
                                                </ListItem>
                                              </Grid>
                                              <Grid xs={12} md={8} item>
                                                <Grid
                                                  container
                                                  justify="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={4}>
                                                    <Typography
                                                      className={[
                                                        classes.openSans20,
                                                        classes.gutterBottom2,
                                                      ]}
                                                      color="textPrimary"
                                                      align="left"
                                                    >
                                                      {(flight?.Depature_DateTime &&
                                                        moment(
                                                          flight?.Depature_DateTime
                                                        ).format("ddd, DD MMM")) ||
                                                        "NA"}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.groteskBold24}
                                                      color="textPrimary"
                                                      align="left"
                                                    >
                                                      {" "}
                                                      Departure{" "}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.openSans16}
                                                      color="textSecondary"
                                                      align="left"
                                                    >
                                                      {(flight?.Depature_DateTime &&
                                                        moment(
                                                          (
                                                            flight?.Depature_DateTime
                                                          )
                                                        ).format("hh:mm A")) ||
                                                        "NA"}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <div className={classes.linewraper}>
                                                      <Typography
                                                        color="primary"
                                                        className={classes.groteskBold18}
                                                        style={{
                                                          lineHeight: 0,
                                                        }}
                                                      >
                                                        {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                                      </Typography>
                                                      <ul className={classes.container}>
                                                        <li className={classes.linkconnector} />
                                                        <li className={classes.linkconnector} />
                                                      </ul>

                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                    <Typography
                                                      className={[
                                                        classes.openSans20,
                                                        classes.gutterBottom2,
                                                      ]}
                                                      color="textPrimary"
                                                      align="right"
                                                    >
                                                      {" "}
                                                      {(flight?.Arrival_DateTime &&
                                                        moment(
                                                          flight?.Arrival_DateTime
                                                        ).format("ddd, DD MMM")) ||
                                                        "NA"}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.groteskBold24}
                                                      color="textPrimary"
                                                      align="right"
                                                    >
                                                      {" "}
                                                      Arrival{" "}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.openSans16}
                                                      color="textSecondary"
                                                      align="right"
                                                    >
                                                      {(flight?.Arrival_DateTime &&
                                                        moment(
                                                          (
                                                            flight?.Arrival_DateTime
                                                          )
                                                        ).format("hh:mm A")) ||
                                                        "NA"}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid xs={12} container justify="space-between">
                                                    <Grid item xs={6}>
                                                      <Typography
                                                        className={[
                                                          classes.openSans16,
                                                          classes.gutterTop2,
                                                        ]}
                                                        color="textPrimary"
                                                        align="left"
                                                      >
                                                        {" "}
                                                        {flight?.Boarding_Terminal ||
                                                          "NA"}
                                                      </Typography>
                                                      <Typography
                                                        className={[
                                                          classes.openSans16,
                                                          classes.gutterBottom2,
                                                        ]}
                                                        color="textPrimary"
                                                        align="left"
                                                      >
                                                        {" "}
                                                        {flight?.Depature_City ||
                                                          "NA"}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <Typography
                                                        className={[
                                                          classes.openSans16,
                                                          classes.gutterTop2,
                                                        ]}
                                                        color="textPrimary"
                                                        align="right"
                                                      >
                                                        {" "}
                                                        {flight?.Arriving_Terminal ||
                                                          "NA"}
                                                      </Typography>
                                                      <Typography
                                                        className={[
                                                          classes.openSans16,
                                                          classes.gutterBottom2,
                                                        ]}
                                                        color="textPrimary"
                                                        align="right"
                                                      >
                                                        {" "}
                                                        {flight?.Arrival_City ||
                                                          "NA"}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {index < (this.state.twoWayFlightList.length - 1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                                            <Divider variant="fullWidth" />
                                          </Grid><Grid item md={6} xs={6}>
                                              <Paper style={{ backgroundColor: '#1565d8', padding: '10px', width: '100%' }}>
                                                <Typography align="center" style={{ color: '#fff' }}>Change of plane {this.state.selectedFlight2?.stops[index].stopDuration} in  {this.state.selectedFlight2?.stops[index].stopName}</Typography>
                                              </Paper>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                              <Divider variant="fullWidth" />
                                            </Grid></Grid>}
                                        </>
                                      })}
                                    </Grid>
                                  </Grid>
                                ) : null} */}

                                <Grid item xs={12}>
                                  <Grid container >
                                    {values.travellers.map((traveller, index) => (
                                      <>
                                        <Grid item xs={12} key={index} className={classes.flightBox} style={{ margin: "10px 0px" }}
                                        >
                                          <Accordion className={classes.accordion}>
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div style={{ flexGrow: 1 }}>
                                                  <Typography
                                                    className={classes.flightInfo}
                                                  >
                                                    Traveller {index + 1} Details ({traveller.type === "INF" ? "Infant" : traveller.type === "CNN" ? "Children" : "Adult"})
                                                  </Typography>
                                                  <Typography
                                                    style={{ color: "#606C78" }}
                                                  >
                                                    {traveller.first_name && traveller.first_name} {traveller.last_name && traveller.last_name} {((traveller.first_name || traveller.last_name) && traveller.sex) && `|`} {((traveller.first_name || traveller.last_name) && traveller.sex) && (traveller.sex === `M` ? `Male` : `Female`)}
                                                  </Typography>
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Typography color="error">
                                                  {touched?.travellers &&
                                                    touched?.travellers[index] &&
                                                    errors?.travellers &&
                                                    errors?.travellers[index] &&
                                                    "Please fill all the required fields"}
                                                </Typography>
                                              </div>

                                              <Divider variant="middle" light />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Grid container spacing={1}>
                                                {this.state.role === "Customer" && traveller.type === "ADT" && (
                                                  <Grid item md={8} xs={10}>
                                                    <FormControlLabel
                                                      control={<Checkbox />}
                                                      name={`travellers[${index}].traveling`}
                                                      checked={
                                                        traveller.traveling
                                                      }
                                                      onChange={(event) => {
                                                        if (
                                                          event.target.checked
                                                        ) {
                                                          setFieldValue(
                                                            `travellers[${index}].first_name`,
                                                            this.state.profileData
                                                              .first_name || ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].last_name`,
                                                            this.state.profileData
                                                              .last_name || ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].sex`,
                                                            this.state.profileData
                                                              .gender ? this.state.profileData
                                                                .gender === 'Male' ? "M" : "F" : ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].pasport_number`,
                                                            this.state.profileData
                                                              .passport_number ||
                                                            ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].passport_expiry_date`,
                                                            this.state.profileData
                                                              .passport_expiry ||
                                                            ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].nationality`,
                                                            this.state.profileData
                                                              .nationality || ""
                                                          );
                                                        } else {
                                                          setFieldValue(
                                                            `travellers[${index}].first_name`,
                                                            ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].last_name`,
                                                            ""
                                                          );
                                                          setFieldValue(
                                                            `travellers[${index}].sex`,
                                                            "M"
                                                          );
                                                          // setFieldValue(
                                                          //   `travellers[${index}].pasport_number`,
                                                          //   ""
                                                          // );
                                                          // setFieldValue(
                                                          //   `travellers[${index}].passport_expiry_date`,
                                                          //   ""
                                                          // );
                                                          setFieldValue(
                                                            `travellers[${index}].nationality`,
                                                            ""
                                                          );
                                                        }
                                                        handleChange(event);
                                                      }}
                                                      label="I am travelling"
                                                      labelPlacement="end"
                                                      classes={{
                                                        label: classes.labelText,
                                                      }}
                                                    />
                                                  </Grid>
                                                )}
                                                <Grid item md={8} xs={10}>
                                                  <Field
                                                    component={InputField}
                                                    required
                                                    fullWidth
                                                    label="First Name & Middle Name"
                                                    name={`travellers[${index}].first_name`}
                                                    placeholder="As Per The Passport"
                                                  />
                                                </Grid>
                                                <Grid item md={8} xs={10}>
                                                  <Field
                                                    component={InputField}
                                                    required
                                                    fullWidth
                                                    label="Last Name"
                                                    name={`travellers[${index}].last_name`}
                                                    placeholder="As Per The Passport"
                                                  />
                                                </Grid>
                                                <Grid item md={8} xs={10}>
                                                  <RadioGroup
                                                    aria-label="sex"
                                                    name={`travellers[${index}].sex`}
                                                    value={traveller.sex}
                                                    onChange={handleChange}
                                                  >
                                                    <FormControlLabel
                                                      value="M"
                                                      control={<Radio />}
                                                      label="Male"
                                                    />
                                                    <FormControlLabel
                                                      value="F"
                                                      control={<Radio />}
                                                      label="Female"
                                                    />
                                                  </RadioGroup>
                                                </Grid>
                                                {traveller.type === "INF" &&
                                                  <Grid item md={8} xs={10}>
                                                    <Field
                                                      component={InputField}
                                                      required
                                                      InputProps={{
                                                        inputProps: {
                                                          min: infantDate,
                                                        },
                                                      }}
                                                      fullWidth
                                                      type="date"
                                                      label="Date of Birth"
                                                      name={`travellers[${index}].infants_dob`}
                                                    />
                                                  </Grid>}
                                                {this.state.fromCity.country !== this.state.toCity.country ?
                                                  <>
                                                    <Grid item md={8} xs={10}>
                                                      <Field
                                                        component={InputField}
                                                        required
                                                        fullWidth
                                                        label="Passport Number"
                                                        name={`travellers[${index}].pasport_number`}
                                                        placeholder="Enter Passport Number"
                                                      />
                                                    </Grid>
                                                    <Grid item md={8} xs={10}>
                                                      <Field
                                                        component={InputField}
                                                        required
                                                        InputProps={{
                                                          inputProps: {
                                                            min: curDate,
                                                          },
                                                        }}
                                                        fullWidth
                                                        type="date"
                                                        label="Passport Expiry Date"
                                                        name={`travellers[${index}].passport_expiry_date`}
                                                      />
                                                    </Grid></> : null}
                                                <Grid item md={8} xs={10}>
                                                  {/* <Field
                                                  
                                                    component={InputField}
                                                    fullWidth
                                                    label="Frequent Flyer Number"
                                                    name={`travellers[${index}].frequentl_flayer_no`}
                                                    placeholder="Enter Airlines Frequent Flyer Number (If Any)"
                                                  /> */}
                                                  <Accordion className={classes.accordionfrequent}>
                                                    <AccordionSummary className={classes.accordionsummaryfrequent}
                                                      expandIcon={<ExpandMoreIcon />}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <div style={{ flexGrow: 1 }}>
                                                          <Typography
                                                            className={classes.flightInfo}
                                                          >
                                                            Frequent Flyer Number
                                                          </Typography>
                                                        </div>
                                                      </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.frequentField}>
                                                      <Field
                                                        component={InputField}
                                                        fullWidth
                                                        // label="Frequent Flyer Number"
                                                        name={`travellers[${index}].frequentl_flayer_no`}
                                                        placeholder="Enter Airlines Frequent Flyer Number (If Any)"
                                                      />
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </Grid>
                                                {this.state.fromCity.country !== this.state.toCity.country &&

                                                  <Grid item md={6} xs={10}>
                                                    <Field
                                                      select
                                                      required
                                                      label="Nationality"
                                                      name={`travellers[${index}].nationality`}
                                                      fullWidth
                                                      component={InputField}
                                                      displayEmpty
                                                      variant="outlined"
                                                    >

                                                      {this.state.nationalityList.map(
                                                        (country) => (
                                                          <MenuItem
                                                            key={country}
                                                            value={country}
                                                          >
                                                            {country}
                                                          </MenuItem>
                                                        )
                                                      )}
                                                    </Field>
                                                  </Grid>
                                                }
                                              </Grid>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Grid>
                                        {/* <Hidden xsDown>
                                          <Grid item md={4} />
                                        </Hidden> */}
                                      </>
                                    ))}
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container className={classes.flightBox}>
                                    <Grid item xs={12} >
                                      <Typography
                                        className={classes.reviewFlightHeading}
                                      >
                                        {this.props.history?.location?.state.data?.role === 'Customer' ? 'Contact Details' : 'Client Contact Details'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0px 20px" }}>
                                      <Typography style={{ paddingBottom: "10px" }}>
                                        Your ticket and flights information will
                                        be sent here.
                                      </Typography>
                                      <Grid item xs={12} >
                                        <Divider className={classes.dividerStyle} />
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.flightBoxDetails}>
                                      <Grid container spacing={1}>
                                        {this.props.history?.location?.state.data?.role !== 'Customer' ? (
                                          <Grid item xs={12} sm={6} md={6}>
                                            <Field
                                              component={InputField}
                                              fullWidth
                                              label="Full Name"
                                              name={`clientsFullName`}
                                              placeholder="Enter Client's Full Name"
                                            />
                                          </Grid>
                                        ) : null}
                                        <Grid item xs={12} sm={6} md={6} >
                                          {/* <Field
                                              component={InputField}
                                              fullWidth
                                              label="Phone Number"
                                              autocomplete="off"
                                              name={`contactNo`}
                                              placeholder="Enter With Country Code"
                                            /> */}
                                          <Grid>
                                            <Typography className={classes.phoneNumberLabel}>Phone Number</Typography>
                                            <PhoneInput
                                              placeholder="Enter Number"
                                              className={classes.phoneNumberInput}
                                              inputProps={{
                                                // name: 'contactNo',
                                                required: true,
                                                // autoFocus: true,
                                              }}
                                              country={"in"}
                                              value={this.state?.contactNumber}
                                              onChange={(phone) => { this.setState({ contactNumber: phone }) }}
                                            />
                                            {this.state?.phoneNumberError && <span style={{ color: "red" }}>This field is required.</span>}
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <Field
                                            component={InputField}
                                            fullWidth
                                            autocomplete="off"
                                            label="Email"
                                            name={`email`}
                                            placeholder="Enter Email ID"
                                          />
                                        </Grid>
                                        {this.props.history?.location?.state.data?.role !== 'Customer' ? (
                                          <Grid item xs={12} sm={6} md={6}>
                                            <Field
                                              component={InputField}
                                              fullWidth
                                              label="Business Phone Number"
                                              name={`businessContactNo`}
                                              placeholder="Enter With Country Code"
                                            />
                                          </Grid>
                                        ) : null}
                                      </Grid>
                                    </Grid>

                                  </Grid>
                                </Grid>
                                {/* <Hidden xsDown>
                                  <Grid item md={4} />
                                </Hidden> */}
                              </Grid>
                            </Grid>
                            <Grid item md={4} xs={11}>
                              <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    className={classes.flightBox}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        color="textPrimary"
                                        className={classes.reviewFlightHeading}
                                      >
                                        Fare Summary
                                      </Typography>
                                    </Grid>
                                    <Grid md={12} xs={12} item>
                                      <Divider className={classes.dividerStyle} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.flightBoxDetails}>
                                      <Grid container justify={"space-between"}>
                                        <Grid item xs={12}>
                                          <List style={{ padding: "0px" }}>
                                            {this.state.fareSummaryLst.length > 0 && this.state.fareSummaryLst.map((fare, index) => {
                                              return <ListItem dense={true} key={index} style={{ padding: "0px", marginBottom: "20px" }}>
                                                <ListItemText style={{ margin: "0px" }}
                                                  primary={`${fare.name} (${fare.count} X ${this.state.currency} ${fare.basePrice})`}
                                                  secondary="Base Fare"
                                                  classes={{
                                                    primary: classes.flightCharge,
                                                    secondary: classes.flightCharge,
                                                  }}
                                                />
                                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                                  <Typography className={classes.flightValue}>
                                                    {fare &&
                                                      `${this.state.currency} ${fare.toalPrice}`}
                                                  </Typography>
                                                </ListItemSecondaryAction>
                                              </ListItem>
                                            })}
                                            {/* <ListItem dense={true}>
                                  <ListItemText
                                    primary={`Travellers (${noOfTravellers} X ${this.state.entityDetail.ApproximateBasePrice})`}
                                    secondary="Base Fare"
                                    classes={{
                                      primary: classes.flightCharge,
                                      secondary: classes.flightCharge,
                                    }}
                                  />
                                  <ListItemSecondaryAction>
                                    <Typography className={classes.flightValue}>
                                      {this.state.fareSummaryDetail &&
                                        this.state.fareSummaryDetail.toal_base_price}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                </ListItem> */}
                                            <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                                              <ListItemText style={{ margin: "0px" }}
                                                primary="Fee & Surcharges"
                                                secondary="Total fee & surcharges"
                                                classes={{
                                                  primary: classes.flightCharge,
                                                  secondary: classes.flightCharge,
                                                }}
                                              />
                                              <ListItemSecondaryAction style={{ right: "0px" }}>
                                                <Typography className={classes.flightValue}>
                                                  {this.state.fareSummaryDetail &&
                                                    `${this.state.currency} ${this.state.fareSummaryDetail.total_tax}`}
                                                </Typography>
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                            {this.state.couponDiscount && (
                                              <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                                                <ListItemText style={{ margin: "0px" }}
                                                //  primary="Coupon Discount"
                                                  secondary="Discount"
                                                  classes={{
                                                    primary: classes.flightCharge,
                                                    secondary: classes.flightCharge,
                                                  }}
                                                />
                                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                                  <Typography className={classes.flightValue} style={{ color: "#FF0000" }}>
                                                    {`${this.state.currency} ${this.state.couponDiscount?.discount_price || "Not Available"}`}
                                                  </Typography>
                                                </ListItemSecondaryAction>
                                              </ListItem>
                                              )}
                                            
                                            <Divider className={classes.dividerStyle} />
                                            <ListItem dense={true} style={{ marginTop: "20px" }}>
                                              <ListItemText
                                                classes={{
                                                  primary: classes.flightTotalAmountText,
                                                  // primary: classes.flightValue,
                                                }}
                                                primary={this.props.history?.location?.state.data?.role === 'Customer' ? 'Total Amount to be Paid' : 'Total Amount Without Markup'}
                                              />
                                              <ListItemSecondaryAction style={{ right: "0px" }}>
                                                {this.props.history?.location?.state.data?.role === 'Customer' ? (
                                                  <Typography className={classes.flightValue}>
                                                    {this.state.fareSummaryDetail &&
                                                      `${this.state.currency} ${this.state.fareSummaryDetail.total_price}`}
                                                  </Typography>
                                                ) : (
                                                  <Typography className={classes.flightValue}>
                                                    {this.state.fareSummaryDetail &&
                                                      `${this.state.currency} ${this.state.fareSummaryDetail.total_price}`}
                                                  </Typography>
                                                )}
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                          </List>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Hidden xsDown>
                                  <Grid item xs={12}>
                                    <Button
                                      fullWidth
                                      onClick={() => { handleSubmit(); }}
                                      variant="contained"
                                      color="primary"
                                    >
                                      Continue
                                    </Button>
                                  </Grid>
                                </Hidden>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    />
                    <Hidden smUp>
                      <Grid item xs={12} md={12}>
                        <Grid container justify="center">
                          <Grid xs={8} item>
                            <Button
                              fullWidth
                              type="submit"
                              variant={"contained"}
                              color="primary"
                            >
                              Continue
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Footer marginTop={0} />
        </div>
        // <div className={classes.root}>
        //   <Paper
        //     className={classes.mainPost}
        //     style={{
        //       backgroundImage: `url(${flightCover})`,
        //       backgroundSize: "cover",
        //     }}
        //   >
        //     {
        //       <img
        //         style={{ display: "none" }}
        //         src={flightCover}
        //         alt={"aaaaaaaaaaaaaaaa"}
        //       />
        //     }
        //     <div className={classes.overlay} />
        //     {this.state.role && <Header role={this.state.role} />}
        //     <Grid container justify="center">
        //       <Grid item md={8} xs={10}>
        //         <Grid
        //           container
        //           spacing={4}
        //           justify="center"
        //           className={classes.mainPostContent}
        //         >
        //           <Grid item xs={12}>
        //             <Typography className={classes.helloAdmin} align="center">
        //               Add Travellers
        //             </Typography>
        //           </Grid>
        //           {this.props.history?.location?.state.data?.role === 'Admin' || this.props.history?.location?.state?.data?.role === 'Agency' ? (
        //             <Grid item xs={12}>
        //             <Typography className={classes.progressText} align="center">
        //               {this.props.history?.location?.state.data?.searchData.adults > 0 ? this.props.history?.location?.state.data?.searchData.adults == 1 ? `${this.props.history?.location?.state.data?.searchData.adults} Adult` : `${this.props.history?.location?.state.data?.searchData.adults} Adults` : null}
        //               {' '}
        //               {this.props.history?.location?.state.data?.searchData.children > 0 ? this.props.history?.location?.state.data?.searchData.children == 1 ? `${this.props.history?.location?.state.data?.searchData.children} Child` : `${this.props.history?.location?.state.data?.searchData.children} Children` : null}
        //               {' '}
        //               {this.props.history?.location?.state.data?.searchData.infants > 0 ? this.props.history?.location?.state.data?.searchData.infants == 1 ? `${this.props.history?.location?.state.data?.searchData.infants} Infant` : `${this.props.history?.location?.state.data?.searchData.infants} Infants` : null}
        //             </Typography>
        //           </Grid>
        //           ) : null}
        //           <Grid item md={8} xs={12}>
        //             <LinearProgress
        //               classes={{ root: classes.linearProgressBar }}
        //               variant="determinate"
        //               value={75}
        //             />
        //           </Grid>
        //           <Grid item md={8} xs={12}>
        //             <Grid container justify="space-between" alignItems="center">
        //               <Grid item xs={2}>
        //                 <Typography
        //                   align="justify"
        //                   className={classes.progressText}

        //                 >
        //                   Flight Selection
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={2}>
        //                 <Typography
        //                   align="justify"
        //                   className={classes.progressText}
        //                 >
        //                   Review
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={2}>
        //                 <Typography
        //                   align="justify"
        //                   className={classes.progressText}
        //                 >
        //                   Traveller Details
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={2}>
        //                 <Typography
        //                   align="justify"
        //                   className={classes.progressText}
        //                 >
        //                   Make Payment
        //                 </Typography>
        //               </Grid>
        //             </Grid>
        //           </Grid>

        //           <Grid item xs={11}>
        //             <Grid container spacing={2}>
        //               <Grid item xs={12} md={5}>
        //                 <Typography
        //                   align="center"
        //                   className={classes.sourceName}
        //                 >
        //                   {this.state?.fromCity?.city}
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={12} md={2}>
        //                 <Typography
        //                   align="center"
        //                   className={classes.sourceName}
        //                 >
        //                   to
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={12} md={5}>
        //                 <Typography
        //                   align="center"
        //                   className={classes.sourceName}
        //                 >
        //                   {this.state?.toCity?.city}
        //                 </Typography>
        //               </Grid>
        //             </Grid>
        //           </Grid>
        //           <Grid item xs={12}>
        //             <Grid container>
        //               <Grid item xs={12} md={5}>
        //                 <Typography
        //                   align="center"
        //                   className={classes.airportName}
        //                 >
        //                   {this.state?.fromCity?.name}
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={12} md={2}>
        //                 <Typography
        //                   align="center"
        //                   className={classes.airportName}
        //                 >
        //                   � �
        //                 </Typography>
        //               </Grid>
        //               <Grid item xs={12} md={5}>
        //                 <Typography
        //                   align="center"
        //                   className={classes.airportName}
        //                 >
        //                   {this.state?.toCity?.name}
        //                 </Typography>
        //               </Grid>
        //             </Grid>
        //           </Grid>
        //         </Grid>
        //       </Grid>
        //     </Grid>
        //   </Paper>
        //   {/* End hero unit */}
        //   <Formik
        //     initialValues={{
        //       travellers: trvallerList.map((trvaller)=>{
        //         return {
        //         traveling: false,
        //         first_name: "",
        //         last_name: "",
        //         sex: "M",
        //         pasport_number: "",
        //         passport_expiry_date: "",
        //         frequentl_flayer_no: "",
        //         nationality: "",
        //         type : trvaller.type,
        //         key :  uuidv4()

        //       }}),
        //       contactNo: "",
        //       email: "",
        //       clientsFullName: "",
        //       businessContactNo: "",
        //     }}
        //     enableReinitialize
        //     validationSchema={TravvellSchema}
        //     onSubmit={(values, actions) => {
        //       const data = this.toAPiConsumableData(values.travellers);
        //       {this.props.history?.location?.state.data?.role === 'Customer' ? (
        //         this.handleTravellsInfoSave(data, values.contactNo, values.email, null, null)
        //       ) : (
        //         this.handleTravellsInfoSave(data, values.contactNo, values.email, values.clientsFullName, values.businessContactNo)
        //       )}
        //       // this.setState({
        //       //   travellers: values.travellers
        //       // })
        //     }}
        //   >
        //     {(formikProps) => {
        //       const {
        //         values,
        //         handleSubmit,
        //         handleChange,
        //         setFieldValue,
        //         errors,
        //         touched,
        //       } = formikProps;
        //       return (
        //         <Form noValidate autocomplete="off">
        //           <FormikErrorFocus
        //     // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
        //     offset={0}
        //     align={'top'}
        //     focusDelay={200}
        //     ease={'linear'}
        //     duration={1000}
        //   />
        //           <Grid container justify="center" spacing={6}>
        //             <FieldArray
        //               name="travellers"
        //               render={(arrayHelpers) => {
        //                 return (
        //                   <>
        //                     <Grid item md={7} xs={11}>
        //                       <Grid container spacing={4}>
        //                            <Grid item xs={12}>
        //                              <Grid container spacing={2} className={classes.flightDetailBox}>
        //                 <Grid item xs={12}>
        //                   <Typography
        //                     color="textPrimary"
        //                     className={classes.groteskBold32}
        //                   >
        //                     {this.state.selectedTravellingType === "twoway" ? "Depature Flight Information" : "Flight Information"}
        //                   </Typography>
        //                 </Grid>
        //                 <Grid item md={12} xs={12}>
        //                   <Divider variant="inset" />
        //                 </Grid>
        //                 {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight,index)=>{
        //               return  <>
        //               <Grid key={flight?.FlightNumber} item xs={12}>
        //               <Grid container spacing={6}>
        //                 <Grid md={4} xs={12} item>
        //                   <ListItem style={{ padding: 0 }}>
        //                     <ListItemAvatar>
        //                       <img
        //                         className={classes.flightImage}
        //                         src={
        //                           flight?.Flight_Image 
        //                         }
        //                         style={{ marginRight: "10px" }}
        //                       />
        //                     </ListItemAvatar>
        //                     <ListItemText
        //                       classes={{
        //                         primary: classes.groteskBold24,
        //                         secondary: classes.openSans18,
        //                       }}
        //                       primary={
        //                         flight?.Flight_Name || "NA"
        //                       }
        //                       secondary={`${
        //                         flight?.Flight_carrier || "-"
        //                       } | ${flight?.Flight_Id || "-"}`}
        //                     />
        //                   </ListItem>
        //                 </Grid>
        //                 <Grid xs={12} md={8} item>
        //                   <Grid
        //                     container
        //                     justify="space-between"
        //                     alignItems="center"
        //                   >
        //                     <Grid item xs={4}>
        //                       <Typography
        //                         className={[
        //                           classes.openSans20,
        //                           classes.gutterBottom2,
        //                         ]}
        //                         color="textPrimary"
        //                         align="left"
        //                       >
        //                         {(flight?.Depature_DateTime &&
        //                           moment(
        //                             flight?.Depature_DateTime
        //                           ).format("ddd, DD MMM")) ||
        //                           "NA"}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.groteskBold18}
        //                         color="textPrimary"
        //                         align="left"
        //                       >
        //                         {" "}
        //                         Departure{" "}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.openSans16}
        //                         color="textSecondary"
        //                         align="left"
        //                       >
        //                         {(flight?.Depature_DateTime &&
        //                           moment(
        //                             (
        //                               flight?.Depature_DateTime
        //                             )
        //                           ).format("hh:mm A")) ||
        //                           "NA"}
        //                       </Typography>
        //                     </Grid>
        //                     <Grid item xs={3}>
        //                       <div className={classes.linewraper}>
        //                         <Typography
        //                           color="primary"
        //                           className={classes.groteskBold18}
        //                           style={{
        //                             lineHeight: 0,
        //                           }}
        //                         >
        //                           {this.timeDiff(flight?.Depature_DateTime,flight?.Arrival_DateTime) || "NA"}
        //                         </Typography>
        //                         <ul className={classes.container}>
        //                           <li className={classes.linkconnector} />
        //                           <li className={classes.linkconnector} />
        //                         </ul>

        //                       </div>
        //                     </Grid>
        //                     <Grid item xs={4}>
        //                       <Typography
        //                         className={[
        //                           classes.openSans20,
        //                           classes.gutterBottom2,
        //                         ]}
        //                         color="textPrimary"
        //                         align="right"
        //                       >
        //                         {" "}
        //                         {(flight?.Arrival_DateTime &&
        //                           moment(
        //                             flight?.Arrival_DateTime
        //                           ).format("ddd, DD MMM")) ||
        //                           "NA"}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.groteskBold18}
        //                         color="textPrimary"
        //                         align="right"
        //                       >
        //                         {" "}
        //                         Arrival{" "}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.openSans16}
        //                         color="textSecondary"
        //                         align="right"
        //                       >
        //                         {(flight?.Arrival_DateTime &&
        //                           moment(
        //                             (
        //                               flight?.Arrival_DateTime
        //                             )
        //                           ).format("hh:mm A")) ||
        //                           "NA"}
        //                       </Typography>
        //                     </Grid>
        //                     <Grid container justify="space-between">
        //                       <Grid item xs={6}>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterTop2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="left"
        //                         >
        //                           {" "}
        //                           {flight?.Boarding_Terminal ||
        //                             "NA"}
        //                         </Typography>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterBottom2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="left"
        //                         >
        //                           {" "}
        //                           {flight?.Depature_City ||
        //                             "NA"}
        //                         </Typography>
        //                       </Grid>
        //                       <Grid item xs={6}>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterTop2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="right"
        //                         >
        //                           {" "}
        //                           {flight?.Arriving_Terminal ||
        //                             "NA"}
        //                         </Typography>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterBottom2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="right"
        //                         >
        //                           {" "}
        //                           {flight?.Arrival_City ||
        //                             "NA"}
        //                         </Typography>
        //                       </Grid>
        //                     </Grid>
        //                     <Grid container justify="space-between">
        //                       <Grid xs={12} item>
        //                         <Grid
        //                           container
        //                           alignItems="center"
        //                           justify="space-between"
        //                         >
        //                           <Grid item xs={4}>
        //                             <ListItem
        //                               style={{ padding: 0 }}
        //                               alignItems="center"
        //                             >
        //                               <ListItemText
        //                                 primary="Baggage"
        //                                 secondary={
        //                                   this.state?.AirPriceInfo[0]?.type || "NA"

        //                                 }
        //                                 classes={{
        //                                   primary: classes.groteskBold18,
        //                                   secondary: classes.openSans16,
        //                                 }}
        //                               />
        //                             </ListItem>
        //                           </Grid>
        //                           <Grid item xs={4}>
        //                             <ListItem
        //                               style={{ padding: 0 }}
        //                               alignItems="center"
        //                             >
        //                               <ListItemText
        //                                 primary="Check-In"
        //                                 secondary={
        //                                   this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
        //                                 }
        //                                 classes={{
        //                                   primary: classes.groteskBold18,
        //                                   secondary: classes.openSans16,
        //                                 }}
        //                               />
        //                             </ListItem>
        //                           </Grid>
        //                           <Grid item xs={4}>
        //                             <ListItem
        //                               style={{ padding: 0 }}
        //                               alignItems="center"
        //                             >
        //                               <ListItemText
        //                                 primary="Cabin"
        //                                 secondary={
        //                                   this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
        //                                 }
        //                                 classes={{
        //                                   primary: classes.groteskBold18,
        //                                   secondary: classes.openSans16,
        //                                 }}
        //                               />
        //                             </ListItem>
        //                           </Grid>
        //                         </Grid>
        //                       </Grid>
        //                     </Grid>
        //                   </Grid>
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //               {index < (this.state.oneWayFlightList.length -1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
        //               <Divider variant="fullWidth" />
        //             </Grid><Grid item md={6} xs={6}>
        //               <Paper style={{backgroundColor : '#1565d8',padding : '10px',width : '100%'}}>
        //                   <Typography align="center" style={{color : '#fff'}}>Change of plane {this.state.entityDetail?.stops[index].stopDuration} in  {this.state.entityDetail?.stops[index].stopName}</Typography>           
        //               </Paper>
        //             </Grid>
        //             <Grid item md={3} xs={3}>
        //               <Divider variant="fullWidth" />
        //             </Grid></Grid>} 
        //          </> 
        //             })}
        //                </Grid>
        //                           </Grid>
        //             {this.state.selectedTravellingType === "twoway" ? (
        //           <Grid item xs={12}>
        //             <Grid container spacing={2} className={classes.flightBox}>
        //               <Grid item xs={12}>
        //                 <Typography
        //                   color="textPrimary"
        //                   className={classes.groteskBold32}
        //                 >
        //                   Return Flight Information
        //                 </Typography>
        //               </Grid>
        //               <Grid item md={12} xs={12}>
        //                 <Divider variant="inset" />
        //               </Grid>
        //               {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight,index)=>{
        //               return  <>
        //               <Grid key={flight?.FlightNumber} item xs={12}>
        //               <Grid container spacing={6}>
        //                 <Grid md={4} xs={12} item>
        //                   <ListItem style={{ padding: 0 }}>
        //                     <ListItemAvatar>
        //                       <img
        //                         className={classes.flightImage}
        //                         src={
        //                           flight?.Flight_Image 
        //                         }
        //                         style={{ marginRight: "10px" }}
        //                       />
        //                     </ListItemAvatar>
        //                     <ListItemText
        //                       classes={{
        //                         primary: classes.groteskBold24,
        //                         secondary: classes.openSans18,
        //                       }}
        //                       primary={
        //                         flight?.Flight_Name || "NA"
        //                       }
        //                       secondary={`${
        //                         flight?.Flight_carrier || "-"
        //                       } | ${flight?.Flight_Id || "-"}`}
        //                     />
        //                   </ListItem>
        //                 </Grid>
        //                 <Grid xs={12} md={8} item>
        //                   <Grid
        //                     container
        //                     justify="space-between"
        //                     alignItems="center"
        //                   >
        //                     <Grid item xs={4}>
        //                       <Typography
        //                         className={[
        //                           classes.openSans20,
        //                           classes.gutterBottom2,
        //                         ]}
        //                         color="textPrimary"
        //                         align="left"
        //                       >
        //                         {(flight?.Depature_DateTime &&
        //                           moment(
        //                             flight?.Depature_DateTime
        //                           ).format("ddd, DD MMM")) ||
        //                           "NA"}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.groteskBold18}
        //                         color="textPrimary"
        //                         align="left"
        //                       >
        //                         {" "}
        //                         Departure{" "}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.openSans16}
        //                         color="textSecondary"
        //                         align="left"
        //                       >
        //                         {(flight?.Depature_DateTime &&
        //                           moment(
        //                             (
        //                               flight?.Depature_DateTime
        //                             )
        //                           ).format("hh:mm A")) ||
        //                           "NA"}
        //                       </Typography>
        //                     </Grid>
        //                     <Grid item xs={3}>
        //                       <div className={classes.linewraper}>
        //                         <Typography
        //                           color="primary"
        //                           className={classes.groteskBold18}
        //                           style={{
        //                             lineHeight: 0,
        //                           }}
        //                         >
        //                           {this.timeDiff(flight?.Depature_DateTime,flight?.Arrival_DateTime) || "NA"}
        //                         </Typography>
        //                         <ul className={classes.container}>
        //                           <li className={classes.linkconnector} />
        //                           <li className={classes.linkconnector} />
        //                         </ul>

        //                       </div>
        //                     </Grid>
        //                     <Grid item xs={4}>
        //                       <Typography
        //                         className={[
        //                           classes.openSans20,
        //                           classes.gutterBottom2,
        //                         ]}
        //                         color="textPrimary"
        //                         align="right"
        //                       >
        //                         {" "}
        //                         {(flight?.Arrival_DateTime &&
        //                           moment(
        //                             flight?.Arrival_DateTime
        //                           ).format("ddd, DD MMM")) ||
        //                           "NA"}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.groteskBold18}
        //                         color="textPrimary"
        //                         align="right"
        //                       >
        //                         {" "}
        //                         Arrival{" "}
        //                       </Typography>
        //                       <Typography
        //                         className={classes.openSans16}
        //                         color="textSecondary"
        //                         align="right"
        //                       >
        //                         {(flight?.Arrival_DateTime &&
        //                           moment(
        //                             (
        //                               flight?.Arrival_DateTime
        //                             )
        //                           ).format("hh:mm A")) ||
        //                           "NA"}
        //                       </Typography>
        //                     </Grid>
        //                     <Grid xs={12} container justify="space-between">
        //                       <Grid item xs={6}>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterTop2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="left"
        //                         >
        //                           {" "}
        //                           {flight?.Boarding_Terminal ||
        //                             "NA"}
        //                         </Typography>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterBottom2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="left"
        //                         >
        //                           {" "}
        //                           {flight?.Depature_City ||
        //                             "NA"}
        //                         </Typography>
        //                       </Grid>
        //                       <Grid item xs={6}>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterTop2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="right"
        //                         >
        //                           {" "}
        //                           {flight?.Arriving_Terminal ||
        //                             "NA"}
        //                         </Typography>
        //                         <Typography
        //                           className={[
        //                             classes.openSans16,
        //                             classes.gutterBottom2,
        //                           ]}
        //                           color="textPrimary"
        //                           align="right"
        //                         >
        //                           {" "}
        //                           {flight?.Arrival_City ||
        //                             "NA"}
        //                         </Typography>
        //                       </Grid>
        //                     </Grid>
        //                     <Grid xs={12} container justify="space-between">
        //                       <Grid xs={12} item>
        //                         <Grid
        //                           container
        //                           alignItems="center"
        //                           justify="space-between"
        //                         >
        //                           <Grid item xs={4}>
        //                             <ListItem
        //                               style={{ padding: 0 }}
        //                               alignItems="center"
        //                             >
        //                               <ListItemText
        //                                 primary="Baggage"
        //                                 secondary={
        //                                   this.state?.AirPriceInfo[0]?.type || "NA"

        //                                 }
        //                                 classes={{
        //                                   primary: classes.groteskBold18,
        //                                   secondary: classes.openSans16,
        //                                 }}
        //                               />
        //                             </ListItem>
        //                           </Grid>
        //                           <Grid item xs={4}>
        //                             <ListItem
        //                               style={{ padding: 0 }}
        //                               alignItems="center"
        //                             >
        //                               <ListItemText
        //                                 primary="Check-In"
        //                                 secondary={
        //                                   this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
        //                                 }
        //                                 classes={{
        //                                   primary: classes.groteskBold18,
        //                                   secondary: classes.openSans16,
        //                                 }}
        //                               />
        //                             </ListItem>
        //                           </Grid>
        //                           <Grid item xs={4}>
        //                             <ListItem
        //                               style={{ padding: 0 }}
        //                               alignItems="center"
        //                             >
        //                               <ListItemText
        //                                 primary="Cabin"
        //                                 secondary={
        //                                   this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
        //                                 }
        //                                 classes={{
        //                                   primary: classes.groteskBold18,
        //                                   secondary: classes.openSans16,
        //                                 }}
        //                               />
        //                             </ListItem>
        //                           </Grid>
        //                         </Grid>
        //                       </Grid>
        //                     </Grid>

        //                   </Grid>
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //               {index < (this.state.twoWayFlightList.length -1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
        //               <Divider variant="fullWidth" />
        //             </Grid><Grid item md={6} xs={6}>
        //               <Paper style={{backgroundColor : '#1565d8',padding : '10px',width : '100%'}}>
        //                   <Typography align="center" style={{color : '#fff'}}>Change of plane {this.state.selectedFlight2?.stops[index].stopDuration} in  {this.state.selectedFlight2?.stops[index].stopName}</Typography>           
        //               </Paper>
        //             </Grid>
        //             <Grid item md={3} xs={3}>
        //               <Divider variant="fullWidth" />
        //             </Grid></Grid>} 
        //          </> 
        //             })}
        //              </Grid>
        //           </Grid>
        //         ) : null}
        //              <Grid
        //                               item
        //                               md={12}
        //                               xs={10}
        //                             >
        //                               <Grid container spacing={1}>
        //                               {values.travellers.map((traveller, index) => (
        //                           <>
        //                             <Grid
        //                               item
        //                               md={12}
        //                               xs={10}
        //                               key={index}
        //                             >
        //                               <Accordion className={classes.accordion}>
        //                                 <AccordionSummary
        //                                   expandIcon={<ExpandMoreIcon />}
        //                                 >
        //                                   <div
        //                                     style={{
        //                                       display: "flex",
        //                                       alignItems: "center",
        //                                     }}
        //                                   >
        //                                     <div style={{ flexGrow: 1 }}>
        //                                       <Typography
        //                                         className={classes.flightInfo}
        //                                       >
        //                                         Traveller {index + 1} Details({(traveller.type)})
        //                                       </Typography>
        //                                     </div>
        //                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //                                     <Typography color="error">
        //                                       {touched?.travellers &&
        //                                         touched?.travellers[index] &&
        //                                         errors?.travellers &&
        //                                         errors?.travellers[index] &&
        //                                         "Please fill all the required fields"}
        //                                     </Typography>
        //                                   </div>

        //                                   <Divider variant="middle" light />
        //                                 </AccordionSummary>
        //                                 <AccordionDetails>
        //                                   <Grid container spacing={1}>
        //                                     {this.state.role === "Customer"  && (
        //                                         <Grid item md={8} xs={10}>
        //                                           <FormControlLabel
        //                                             control={<Checkbox />}
        //                                             name={`travellers[${index}].traveling`}
        //                                             checked={
        //                                               traveller.traveling
        //                                             }
        //                                             onChange={(event) => {
        //                                               if (
        //                                                 event.target.checked
        //                                               ) {
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].first_name`,
        //                                                   this.state.profileData
        //                                                     .first_name || ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].last_name`,
        //                                                   this.state.profileData
        //                                                     .last_name || ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].sex`,
        //                                                   this.state.profileData
        //                                                     .gender ? this.state.profileData
        //                                                     .gender === 'Male' ? "M" : "F"   : ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].pasport_number`,
        //                                                   this.state.profileData
        //                                                     .passport_number ||
        //                                                     ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].passport_expiry_date`,
        //                                                   this.state.profileData
        //                                                     .passport_expiry ||
        //                                                     ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].nationality`,
        //                                                   this.state.profileData
        //                                                     .nationality || ""
        //                                                 );
        //                                               } else {
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].first_name`,
        //                                                   ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].last_name`,
        //                                                   ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].sex`,
        //                                                   "M"
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].pasport_number`,
        //                                                   ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].passport_expiry_date`,
        //                                                   ""
        //                                                 );
        //                                                 setFieldValue(
        //                                                   `travellers[${index}].nationality`,
        //                                                   ""
        //                                                 );
        //                                               }
        //                                               handleChange(event);
        //                                             }}
        //                                             label="I am travelling"
        //                                             labelPlacement="end"
        //                                             classes={{
        //                                               label: classes.labelText,
        //                                             }}
        //                                           />
        //                                         </Grid>
        //                                       )}
        //                                     <Grid item md={8} xs={10}>
        //                                       <Field
        //                                         component={InputField}
        //                                         required
        //                                         fullWidth
        //                                         label="First Name & Middle Name"
        //                                         name={`travellers[${index}].first_name`}
        //                                         placeholder="As Per The Passport"
        //                                       />
        //                                     </Grid>
        //                                     <Grid item md={8} xs={10}>
        //                                       <Field
        //                                         component={InputField}
        //                                         required
        //                                         fullWidth
        //                                         label="Last Name"
        //                                         name={`travellers[${index}].last_name`}
        //                                         placeholder="As Per The Passport"
        //                                       />
        //                                     </Grid>
        //                                     <Grid item md={8} xs={10}>
        //                                       <RadioGroup
        //                                         aria-label="sex"
        //                                         name={`travellers[${index}].sex`}
        //                                         value={traveller.sex}
        //                                         onChange={handleChange}
        //                                       >
        //                                         <FormControlLabel
        //                                           value="M"
        //                                           control={<Radio />}
        //                                           label="Male"
        //                                         />
        //                                         <FormControlLabel
        //                                           value="F"
        //                                           control={<Radio />}
        //                                           label="Female"
        //                                         />
        //                                       </RadioGroup>
        //                                     </Grid>
        //                                     <Grid item md={8} xs={10}>
        //                                       <Field
        //                                         component={InputField}
        //                                         required
        //                                         fullWidth
        //                                         label="Passport Number"
        //                                         name={`travellers[${index}].pasport_number`}
        //                                         placeholder="Enter Passport Number"
        //                                       />
        //                                     </Grid>
        //                                     <Grid item md={8} xs={10}>
        //                                       <Field
        //                                         component={InputField}
        //                                         required
        //                                         InputProps={{
        //                                           inputProps: {
        //                                             min: curDate,
        //                                           },
        //                                         }}
        //                                         fullWidth
        //                                         type="date"
        //                                         label="Passport Expiry Date"
        //                                         name={`travellers[${index}].passport_expiry_date`}
        //                                       />
        //                                     </Grid>
        //                                     <Grid item md={8} xs={10}>
        //                                       <Field
        //                                         component={InputField}
        //                                         fullWidth
        //                                         label="Frequent Flyer Number"
        //                                         name={`travellers[${index}].frequentl_flayer_no`}
        //                                         placeholder="Enter Airlines Frequent Flyer Number (If Any)"
        //                                       />
        //                                     </Grid>
        //                                     <Grid item md={6} xs={10}>
        //                                       <Field
        //                                         select
        //                                         required
        //                                         label="Nationality"
        //                                         name={`travellers[${index}].nationality`}
        //                                         fullWidth
        //                                         component={InputField}
        //                                       >
        //                                         {this.state.nationalityList.map(
        //                                           (country) => (
        //                                             <MenuItem
        //                                               key={country}
        //                                               value={country}
        //                                             >
        //                                               {country}
        //                                             </MenuItem>
        //                                           )
        //                                         )}
        //                                       </Field>
        //                                     </Grid>
        //                                   </Grid>
        //                                 </AccordionDetails>
        //                               </Accordion>
        //                             </Grid>
        //                             <Hidden xsDown>
        //                               <Grid item md={4} />
        //                             </Hidden>
        //                           </>
        //                         ))}       
        //                               </Grid>
        //                             </Grid>

        //                         <Grid
        //                           item
        //                           md={12}
        //                           xs={10}
        //                           className={classes.flightDetailBox}
        //                         >
        //                           <Grid container>
        //                             <Grid item xs={12}>
        //                               <Typography
        //                                 gutterBottom
        //                                 className={classes.flightInfo}
        //                               >
        //                                 {this.props.history?.location?.state.data?.role === 'Customer' ? 'Contact Details' : 'Client Contact Details'}
        //                               </Typography>
        //                               <Typography>
        //                                 Your ticket and flights information will
        //                                 be sent here.
        //                               </Typography>
        //                             </Grid>
        //                             <Grid item xs={12}>
        //                               <Divider variant="middle" />
        //                             </Grid>
        //                             <Grid item xs={8}>
        //                               <Grid container>
        //                                 {this.props.history?.location?.state.data?.role !== 'Customer' ? (
        //                                   <Grid item xs={12}>
        //                                   <Field
        //                                       component={InputField}
        //                                       fullWidth
        //                                       label="Full Name"
        //                                       name={`clientsFullName`}
        //                                       placeholder="Enter Client's Full Name"
        //                                     />
        //                                   </Grid>
        //                                 ) : null}
        //                                 <Grid item xs={12}>
        //                                   <Field
        //                                     component={InputField}
        //                                     fullWidth
        //                                     label="Phone Number"
        //                                     autocomplete="off"
        //                                     name={`contactNo`}
        //                                     placeholder="Enter With Country Code"
        //                                   />
        //                                 </Grid>
        //                                 <Grid item xs={12}>
        //                                   <Field
        //                                     component={InputField}
        //                                     fullWidth
        //                                     autocomplete="off"
        //                                     label="Email"
        //                                     name={`email`}
        //                                     placeholder="Enter Email ID"
        //                                   />
        //                                 </Grid>
        //                                 {this.props.history?.location?.state.data?.role !== 'Customer' ? (
        //                                   <Grid item xs={12}>
        //                                     <Field
        //                                       component={InputField}
        //                                       fullWidth
        //                                       label="Business Phone Number"
        //                                       name={`businessContactNo`}
        //                                       placeholder="Enter With Country Code"
        //                                     />
        //                                   </Grid>
        //                                 ) : null}
        //                               </Grid>
        //                             </Grid>
        //                           </Grid>
        //                         </Grid>
        //                         <Hidden xsDown>
        //                           <Grid item md={4} />
        //                         </Hidden>
        //                       </Grid>
        //                     </Grid>
        //                     <Grid item md={4} xs={11}>
        //                       <Grid container spacing={3}>
        //                       <Grid item xs={12}>
        //               <Grid
        //                 container
        //                 spacing={2}
        //                 spacing={1}
        //                 className={classes.flightDetailBox}
        //               >
        //                 <Grid item xs={12}>
        //                   <Box px={2}>
        //                     <Typography
        //                       color="textPrimary"
        //                       className={classes.groteskBold32}
        //                     >
        //                       Fare Summary
        //                     </Typography>
        //                   </Box>
        //                 </Grid>
        //                 <Grid md={12} xs={12} item>
        //                   <Divider variant="fullWidth" />
        //                 </Grid>
        //                 <Grid item xs={12}>
        //                   <Grid container justify={"space-between"} spacing={2}>
        //                     <Grid item xs={12}>
        //                       <List>
        //                         {this.state.fareSummaryLst.length > 0 && this.state.fareSummaryLst.map((fare,index)=>{
        //                           return <ListItem dense={true} key={index}>
        //                           <ListItemText
        //                             primary={`${fare.name} (${fare.count} X ${this.state.currency} ${fare.basePrice})`}
        //                             secondary="Base Fare"
        //                             classes={{
        //                               primary: classes.flightCharge,
        //                               secondary: classes.flightCharge,
        //                             }}
        //                           />
        //                           <ListItemSecondaryAction>
        //                             <Typography className={classes.flightValue}>
        //                               {fare &&
        //                                 `${this.state.currency} ${fare.toalPrice}`}
        //                             </Typography>
        //                           </ListItemSecondaryAction>
        //                         </ListItem>
        //                         })}
        //                         {/* <ListItem dense={true}>
        //                           <ListItemText
        //                             primary={`Travellers (${noOfTravellers} X ${this.state.entityDetail.ApproximateBasePrice})`}
        //                             secondary="Base Fare"
        //                             classes={{
        //                               primary: classes.flightCharge,
        //                               secondary: classes.flightCharge,
        //                             }}
        //                           />
        //                           <ListItemSecondaryAction>
        //                             <Typography className={classes.flightValue}>
        //                               {this.state.fareSummaryDetail &&
        //                                 this.state.fareSummaryDetail.toal_base_price}
        //                             </Typography>
        //                           </ListItemSecondaryAction>
        //                         </ListItem> */}
        //                         <ListItem dense={true}>
        //                           <ListItemText
        //                             primary="Fee & Surcharges"
        //                             secondary="Total fee & surcharges"
        //                             classes={{
        //                               primary: classes.flightCharge,
        //                               secondary: classes.flightCharge,
        //                             }}
        //                           />
        //                           <ListItemSecondaryAction>
        //                             <Typography className={classes.flightValue}>
        //                               {this.state.fareSummaryDetail &&
        //                                 `${this.state.currency} ${this.state.fareSummaryDetail.total_tax}`}
        //                             </Typography>
        //                           </ListItemSecondaryAction>
        //                         </ListItem>
        //                         {this.state.couponDiscount && (
        //                       <ListItem dense={true}>
        //                         <ListItemText
        //                           primary="Coupon Discount"
        //                           classes={{
        //                             primary: classes.flightCharge,
        //                             secondary: classes.flightCharge,
        //                           }}
        //                         />
        //                         <ListItemSecondaryAction>
        //                           <Typography className={classes.flightValue}>
        //                             {`${this.state.currency} ${this.state.couponDiscount?.discount || "Not Available"}`}
        //                           </Typography>
        //                         </ListItemSecondaryAction>
        //                       </ListItem>
        //                       )}
        //                         <Divider variant="middle" />
        //                         <ListItem dense={true}>
        //                           <ListItemText
        //                             classes={{
        //                               primary: classes.flightValue,
        //                             }}
        //                             primary={this.props.history?.location?.state.data?.role === 'Customer' ? 'Total Fare' : 'Total Amount Without Markup'}
        //                             />
        //                           <ListItemSecondaryAction>
        //                               {this.props.history?.location?.state.data?.role === 'Customer' ? (
        //                               <Typography className={classes.flightValue}>
        //                               {this.state.fareSummaryDetail &&
        //                                 `${this.state.currency} ${this.state.fareSummaryDetail.total_price}`}
        //                               </Typography>
        //                             ) : (
        //                               <Typography className={classes.flightValue}>
        //                               {this.state.fareSummaryDetail &&
        //                                 `${this.state.currency} ${this.state.fareSummaryDetail.total_price}`}
        //                               </Typography>
        //                             )}
        //                           </ListItemSecondaryAction>
        //                         </ListItem>
        //                       </List>
        //                     </Grid>
        //                   </Grid>
        //                 </Grid>
        //               </Grid>
        //             </Grid>

        //                         <Hidden xsDown>
        //                           <Grid item xs={12}>
        //                             <Button
        //                               fullWidth
        //                               onClick={handleSubmit}
        //                               variant="contained"
        //                               color="primary"
        //                             >
        //                               Continue
        //                             </Button>
        //                           </Grid>
        //                         </Hidden>
        //                       </Grid>
        //                     </Grid>
        //                   </>
        //                 );
        //               }}
        //             />
        //             <Hidden smUp>
        //               <Grid item xs={12} md={12}>
        //                 <Grid container justify="center">
        //                   <Grid xs={8} item>
        //                     <Button
        //                       fullWidth
        //                       type="submit"
        //                       variant={"contained"}
        //                       color="primary"
        //                     >
        //                       Continue
        //                     </Button>
        //                   </Grid>
        //                 </Grid>
        //               </Grid>
        //             </Hidden>
        //           </Grid>
        //         </Form>
        //       );
        //     }}
        //   </Formik>
        //   <Footer />
        // </div>
      )
    ) : null;
  }
}

const AddTravellersWithRouter = withRouter(AddTravellers);
const AddTravellersAlertBox = withAlertBox(AddTravellersWithRouter);
const AddTravellersLoader = withLoader(AddTravellersAlertBox);
const AddTravellersToast = withToast(AddTravellersLoader);
const AddTravellersWithDialog = withDialogBox(AddTravellersToast);
const AddTravellersWithFontStyles = withFontStyles(AddTravellersWithDialog);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      // marginBottom: theme.spacing(4),
      backgroundImage: `url(${flightCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // height: theme.spacing(55),
      [theme.breakpoints.down("xs")]: {
        // height: theme.spacing(85),
      },
    },
    // flightImage: {
    //   width: theme.spacing(7),
    //   height: theme.spacing(6.5),
    // },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    // flightDetailBox: {
    //   padding: theme.spacing(2),
    //   borderRadius: "8px",
    //   border: "solid 1px #e5eaf4",
    //   backgroundColor: "#ffffff",
    //   boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    // },
    notchedOutline: {
      borderRadius: "9px",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    lineHeadingwraper: {
      textAlign: "center",
      width: "6%",
      display: "inline-block",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    flightName: {
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#1E394E",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
        fontWeight: "600",
      },
    },
    flightCode: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAalign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightInfo: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCharge: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightAdditionChange: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightValue: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    travellNumber: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    layOver: {
      padding: '2rem',
      color: '#1565D8'
    },
    HereLink: {
      fontFamily: "Public Sans",
      fontSize: "22px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: '#1565D8',
      paddingLeft: '0.5rem'
    },
    adultsCount: {
      fontFamily: "Public Sans",
      fontSize: "22px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      padding: '1rem'
    },
    addTravellersBackground: {
      paddingTop: "60px",
      paddingBottom: "100px",
      backgroundColor: '#F6F9FF',
    },
    flightTotalAmountText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "600",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightPriceValue: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    flightBox: {
      borderRadius: "5px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      backgroundColor: "rgba(255,255,255, 1)",
    },
    reviewFlightHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    flightBoxDetails: {
      padding: "20px",
    },
    flightImage: {
      width: "75px",
      height: "75px",
      marginRight: "30px",
      [theme.breakpoints.only("xs")]: {
        width: "60px",
        height: "60px",
        marginRight: "15px",
      },
    },
    flightTotalPrice: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
      },
    },
    departureSection: {
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    arrivalSection: {
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "20px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "24px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px 0px"
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "20px",
      },
    },
    phoneNumberLabel: {
      color: "#5a7184",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      fontStretch: "normal",
      letterSpacing: "normal",
      margin: "5px 0px 4px 0px"
    },
    phoneNumberInput: {
      "& input": {
        width: "100% !important",
        height: "47px",
        borderRadius: "8px !important",
      },
      "& .special-label": {
        visibility: "hidden",
      }
    },
    accordionfrequent: {
      boxShadow: "none !important"
    },
    accordionsummaryfrequent: {
      display: "inline-flex !important",
      padding: "0px",
      "& p":{
        fontSize:"18px",
      },
    },
    frequentField:{
      padding: "0px",
    },
  })
)(AddTravellersWithFontStyles);
