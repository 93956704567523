import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";
export const configJSON = require("./config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: String;
  password: String;
  unique_auth_id? : String;
};
export default class AgencySignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  agencySignUpApiCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.agencySignUpApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson?.account?.meta?.token) {
              console.log('agency sign up', responseJson)
            this.props.onSubmit('signUpSuccessFull')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
      } 
  } else if (getName(MessageEnum.AlertMessage) === message.id) {
    const title = message.getData(getName(MessageEnum.AlertTitleMessage));
    var AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );
    this.props.showAlert({
      title: "Alert",
      message: AlertBodyMessage,
    });
  }
}
  
  handleAgencySignUp = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.agencySignUpApiCallId = requestMessage.messageId;
    const httpBody = {
        account_type:"agency",
        data: {
          full_name:values.fullName,
          email: values.email,
          password: values.password,
          confirm_password: values.password,
          activated:true,
          full_phone_number:Number(values.phoneNumber),
          business_registration_number:Number(values.registrationNumber),
          permission_ntb_number:Number(values.ntbNumber),
          agency_name:values.agencyName,
          agency_address:values.agencyAddress,
          agency_phone_numbeer:Number(values.agencyPhoneNumber),
          pan_number:values.panNumber                
      },
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `${configJSON.agencySignUp}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
