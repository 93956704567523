//@ts-nocheck
import React from "react";
import AllCouponsListController, {
  Props,
} from "./AllCouponsListController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withFontStyles from "./withFontStyles.web";
import moment from "moment";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";

class AllCouponsList extends AllCouponsListController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getCoupons();
          if (this.props.apiType === "accountId") {
            // this.getSubAgancyListbyAccountId(this.props.history.location.state.id);
          } else {
            // this.getSubAgancyList();
          }
        }
      );
    }
  }
  goToSelectedRoute = async (routeName, data) => {
    const { history } = this.props;
    await StorageProvider.set("agancy", JSON.stringify(data));
    history.push({ pathname: routeName });
  };
  
  addNewCoupon = () => {
    this.props.history.push("/AdminDashboard/addNewCoupon");
  };

  editCoupon = (routeName, data) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data } });
  };

  deleteCouponUI = (memberId) => {
    const onClose = (result) => {
      if(result === 'Yes'){
        this.deleteCoupon(memberId)
      }
    }
    this.props.openConfirmDialog(
      'Delete','Are you sure you want to delete this coupon',onClose
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="space-around" spacing={4}>
        <Grid item xs={11} >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pendIngRequestBox}
          >
            <Grid item md={9}>
              <Typography className={classes.pendIngTite}>
                All Coupons
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.addNewCoupon();
                }}
              >
                {" "}
                Add New{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {this.state.allCouponsList.length > 0 ? (
          this.state.allCouponsList.map((coupon) => {
            return (
              <Grid item key={coupon.id} xs={11}>
                <Grid
                  container
                  justify="space-around"
                  className={classes.subagencyContainer}
                >
                  <Grid xs={11}>
                    <Typography className={classes.agencyName}>
                      {coupon.attributes.title}
                    </Typography>
                    <Typography className={classes.anancyInfo}>
                      {coupon.attributes.description}
                    </Typography>
                  </Grid>
                  <Grid xs={11}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Coupon Code"
                            secondary={coupon.attributes.code}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Discount"
                            secondary={`${coupon.attributes.discount} ${coupon.attributes.discount_type === 'percentage' ? "%" : "flat"}` }
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Valid From"
                            secondary={
                              (coupon.attributes.valid_from &&
                                moment(
                                  new Date(coupon.attributes.valid_from)
                                ).format("DD/MM/yyyy")) ||
                              "NA"
                            }
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Valid Till"
                            secondary={
                              (coupon.attributes.valid_to &&
                                moment(
                                  new Date(coupon.attributes.valid_to)
                                ).format("DD/MM/yyyy")) ||
                              "NA"
                            }
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Min Cart Value"
                            secondary={coupon.attributes.min_cart_value}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemText
                            primary="Max Cart Value"
                            secondary={coupon.attributes.max_cart_value}
                            classes={{
                              primary: classes.primartText,
                              secondary: classes.secondaryText,
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={3}>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              this.editCoupon("editCoupon", {
                                couponData: { ...coupon.attributes },
                              });
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              console.log(coupon.id);
                              this.deleteCouponUI(coupon.id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={11}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.subagencyContainer}
              style={{ height: "70px" }}
            >
              <Grid item md={11}>
                <Typography className={classes.pendIngTite}>
                  No Coupons Available
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const AllCouponsListWithRouter = withRouter(AllCouponsList);
const AllCouponsListAlertBox = withAlertBox(AllCouponsListWithRouter);
const AllCouponsListLoader = withLoader(AllCouponsListAlertBox);
const AllCouponsListToast = withToast(AllCouponsListLoader);
const AllCouponsListWithDialog = withDialog(AllCouponsListToast);
const AllCouponsListWithFontStyles = withFontStyles(AllCouponsListWithDialog);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: theme.spacing(11)
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
  })
)(withConfirmBox(AllCouponsListWithFontStyles));
