import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import ContactDetailsWeb from "./ContactDetails.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken: any,
  nationalityList: any
  profileData : any;
  fromCity : any;
  toCity : any;
  entityDetail : any;
  searchData : {},
  entityType : 'Hotels' | 'Flights',
  loading : boolean,
  role : string
  selectedTravellingType : string
  selectedFlight2 : any,
  fareSummaryDetail : any,
  fareSummaryLst : Array<any>,
  currency : string,
  oneWayFlightList : Array<any>,
  AirPriceInfo : any,
  couponDiscount : any,
  twoWayFlightList : Array<any>,
  NewTravellersList:Array<any>,
  isAlreadyExists:boolean,
  travellerCount:number
  contactNumber:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddTravellersontroller extends BlockComponent<
  Props,
  S,
  SS
> {
  getCountriesListApiCallID: String = "";
  getProfileDetailApiCallID : String = ""; 
  updateTravellApiCallId : String = "";
  getflightDetailApiCallId : String = "";
  getreturnflightDetailApiCallId : String = "";
  faresummaryApiCallId : string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
        authToken: '',
        nationalityList: [],
        profileData : {},
        fromCity : {},
        toCity : {},
        entityDetail : {},
        searchData : {},
        entityType : 'Flights',
        loading : true,
        role : '',
        selectedTravellingType : "",
        selectedFlight2 : {},
        fareSummaryDetail : {},
        fareSummaryLst : [],
        currency : 'USD',
        oneWayFlightList : [],
        AirPriceInfo : {},
        couponDiscount : null,
        twoWayFlightList : [],
        NewTravellersList:[],
        isAlreadyExists:true,
        travellerCount:1,
        contactNumber:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getreturnflightDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.length > 0) {
          this.setState({
            selectedFlight2: {
              ...this.state.selectedFlight2,
              ...responseJson?.data?.attributes[0],
            },
          });
        }
      } else if (apiRequestCallId === this.faresummaryApiCallId) {
        this.props.hideLoader();
        if (responseJson?.AirPriceInfo) {
        
          const dataAirPrice = responseJson;
          const fareSummaryLst = [];
          let isAdult = false;
          for(let key in dataAirPrice?.AirPriceInfo){
            const AirPriceInfo = dataAirPrice?.AirPriceInfo[key]
            const temp = {
              type : '',
              count : 0,
              toalPrice : 0,
              basePrice : 0,
              name : '',
            };
            
            if(AirPriceInfo.type === 'ADT' && dataAirPrice.adult){
              temp.name = "Adults"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.adult;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.adult;
              temp.basePrice = AirPriceInfo.base_rate;
              isAdult = true
            } else if(AirPriceInfo.type === 'INF' && dataAirPrice.infents){
              temp.name = "Infants"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.infents;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.infents;
              temp.basePrice = AirPriceInfo.base_rate;
            } else if(AirPriceInfo.type === 'CNN' && dataAirPrice.child){
              temp.name = "Children"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.child;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.child;
              temp.basePrice = AirPriceInfo.base_rate;
            }
            fareSummaryLst.push(temp)
          }
          const returnFlights : any = [];
          const departureFlights : any = []
          if(this.state.selectedTravellingType === 'twoway'){
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight : any)=>{
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any)=>{ return oldFlight.flight_Id ===  flight.Flight_Id})
              if(df){
                departureFlights.push({...df,...flight})
              }
              const rf = this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any)=>{ return oldFlight.flight_Id ===  flight.Flight_Id}) 
              if(rf){
                returnFlights.push({...rf,...flight});
              }
            })
          } else if(this.state.selectedTravellingType === 'oneway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight : any)=>{
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any)=>{ return oldFlight.flight_Id ===  flight.Flight_Id})
              if(df){
                departureFlights.push({...df,...flight})
              }
            })
          }
          this.setState({
            fareSummaryDetail : dataAirPrice,
            AirPriceInfo : dataAirPrice?.AirPriceInfo,
            fareSummaryLst : fareSummaryLst,
          },()=>{
            if(this.state.selectedTravellingType === 'twoway'){
                this.setState({
                  oneWayFlightList : departureFlights,
                  twoWayFlightList : returnFlights,
                },()=>{
                  if(this.state?.couponDiscount){
                    this.setState({
                      fareSummaryDetail : {
                        ...this.state.fareSummaryDetail,total_price : (Number(this.state?.couponDiscount?.after_discount_price)) 
                      }
                    })
                  }
                })
            } else if(this.state.selectedTravellingType === 'oneway'){
              this.setState({
                oneWayFlightList : departureFlights
              },()=>{
                if(this.state?.couponDiscount){
                  this.setState({
                    fareSummaryDetail : {
                      ...this.state.fareSummaryDetail,total_price : (Number(this.state?.couponDiscount?.after_discount_price)) 
                    }
                  })
                }
              })
            }
          })
          
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else  if (apiRequestCallId === this.updateTravellApiCallId) {
        this.props.hideLoader();
        const path = this?.state.role !== 'Agency' ? './addOns' : './addOnsAgency';
        if (responseJson && responseJson?.booking_id && responseJson?.travel_detail?.data?.id) {
          this.props.showToast({type : 'success',message : 'Travellers detail updated succssfully'})
          this.props.history.replace({pathname : path,state : {searchData : {...this.state.searchData},
          entityDetail : {...this.state.entityDetail},
          fromCity : this.state.fromCity,
          toCity : this.state.toCity,
          couponDiscount : this.state.couponDiscount,
          entityType : this.state.entityType,booking_id : responseJson?.booking_id,travaller_id : responseJson?.travel_detail?.data?.id,
          role : this.state.role,selectedTravellingType: this.state?.selectedTravellingType,
          selectedFlight2: this.state?.selectedFlight2,
          travellerInfo : responseJson?.travel_detail?.data?.attributes?.data}})
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getCountriesListApiCallID) {
        // this.props.hideLoader();
        if (responseJson?.countries) {
          this.setState({
            nationalityList: responseJson.countries
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.props.hideLoader();
        if (responseJson?.data) {
            this.setState({...this.state,
                profileData : responseJson?.data?.attributes
            },()=>{
              this.setState({
                profileData : {...this.state.profileData,...{first_name:this.state?.profileData?.full_name?.split(" ")[0] ||  this.state?.profileData?.first_name,last_name:this.state?.profileData?.full_name?.split(" ")[1] ||  this.state?.profileData?.last_name}}
              })
            })
        } else {
            this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getflightDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.length > 0) {
          this.setState({
            entityDetail : {...this.state.entityDetail,...responseJson?.data?.attributes[0]}
          })
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }

  
  getProfileDetail = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken 

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDetailApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userEditProfile}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getCountriesList = () => {
    // this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken 
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountriesListApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountriesList}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleTravellsInfoSave = (values: any,contactNo : any,email : any) => {
    const travellerDetails : Array<any> = Object.values(values);
    const uniqTraveller = []
    for (var i = 0; i < travellerDetails.length; i++) {
      for (var j = 0; j < travellerDetails.length; j++) {
        if (travellerDetails[i]?.first_name + "" +  travellerDetails[i]?.last_name == travellerDetails[j]?.first_name + "" +  travellerDetails[j]?.last_name && i != j) break;
        else if (j == travellerDetails.length - 1) uniqTraveller.push(travellerDetails[i])
      }
    }
    if(uniqTraveller.length === travellerDetails.length){
      this.props.showLoader();
      const headers = {
        "Content-Type": configJSON.ApiContentType,
        "token" : this.state.authToken 
      };
          const self = this;
          let twoWayFlightList : any = [];
          let oneflightDetail  : any = []
          if(this.state.oneWayFlightList && this.state.oneWayFlightList.length > 0){
            oneflightDetail = this.state.oneWayFlightList.map((flight) => {
              return {
                'fare_info_key' : self.state.entityDetail?.Fare_info_key,
                'fare_rule_key' : self.state.entityDetail?.Fare_rule_Key,
                'flight_name' : flight?.Flight_Name,
                'flight_id' : flight?.Flight_Id,
                'flight_carrier' :flight?.Flight_carrier,
                'flight_type' : 'departure'
              }
          });
          }
          
        if(this.state.twoWayFlightList && this.state.twoWayFlightList.length > 0){
          twoWayFlightList = this.state.twoWayFlightList.map((flight) => {
            return {
              'fare_info_key' : self.state.selectedFlight2?.Fare_info_key,
              'fare_rule_key' : self.state.selectedFlight2?.Fare_rule_Key,
              'flight_name' : flight?.Flight_Name,
              'flight_id' : flight?.Flight_Id,
              'flight_carrier' :flight?.Flight_carrier,
              'flight_type' : 'return'
            }
        });
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
          
          this.updateTravellApiCallId = requestMessage.messageId;
          const httpBody = {
            details: {
              data: {
                ...values,
              },
              // flight_id: this.state.entityDetail?.Flight_Id,
              contact_number: this.state.contactNumber,
              email: email,
              flight_details_hash : [...oneflightDetail,...twoWayFlightList],
              coupon_code_id : this.state.couponDiscount?.coupon_code_id || null,
              // "fare_rule_key": this.state.entityDetail?.Fare_Rule_Key,
              // "fare_info_key": this.state.entityDetail?.Fare_Info_Key,
              // "currancy" : this.state.currency
            },
            token: this.state.authToken,
          };
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_shopping_cart/travelar_details`
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodPOST
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
     } else {
       this.props.showToast({type:'error',message : 'Please Enter Unique Travaller Details'})
     }
  }
  getFlightDetail = () => {
    this.props.showLoader();
    this.setState({
      entityDetail : {...this.state.entityDetail,Depature_DateTime : this.state.entityDetail?.Depature_Time,Arrival_DateTime : this.state.entityDetail?.Arrival_Time}
    },()=>{
    let url = `${configJSON.get_flight_details}?carrier=${this.state.entityDetail?.flight_Carrier}&flight_number=${this.state.entityDetail?.flight_Id}&origin=${this.state.entityDetail?.Depature_Iata}&destination=${this.state.entityDetail?.Arrival_Iata}&departure_datetime=${this.state.entityDetail?.Depature_DateTime}&arrival_datetime=${this.state.entityDetail?.Arrival_DateTime}&class_of_service=Y&baggage_type=${this.state.entityDetail?.Baggage_Type}&baggage_cabin=${this.state.entityDetail?.Baggage_Cabin}&baggage_check_in=${this.state.entityDetail?.Baggage_Check_In}&mode_of_flight=${this.state.entityDetail?.Mode_Of_Flight}&fare_basis_code=${this.state.entityDetail?.FareBasisCode}&booking_code=${this.state.entityDetail?.BookingCode}&fare_rule_key=${this.state.entityDetail?.Fare_rule_Key}&fare_info_key=${this.state.entityDetail?.fare_info_key}`;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getflightDetailApiCallId = requestMessage.messageId;

    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    })
    
  };
  getReturnFlightDetail = () => {
    this.props.showLoader();
    this.setState({
      selectedFlight2 : {...this.state.selectedFlight2,Depature_DateTime : this.state.selectedFlight2?.Depature_Time,Arrival_DateTime : this.state.selectedFlight2?.Arrival_Time}
    },()=>{
      let url = `${configJSON.get_flight_details}?carrier=${this.state.selectedFlight2?.flight_Carrier}&flight_number=${this.state.selectedFlight2?.flight_Id}&origin=${this.state.selectedFlight2?.Depature_Iata}&destination=${this.state.selectedFlight2?.Arrival_Iata}&departure_datetime=${this.state.selectedFlight2?.Depature_DateTime}&arrival_datetime=${this.state.selectedFlight2?.Arrival_DateTime}&class_of_service=Y&baggage_type=${this.state.selectedFlight2?.Baggage_Type}&baggage_cabin=${this.state.selectedFlight2?.Baggage_Cabin}&baggage_check_in=${this.state.selectedFlight2?.Baggage_Check_In}&mode_of_flight=${this.state.selectedFlight2?.Mode_Of_Flight}&fare_basis_code=${this.state.selectedFlight2?.FareBasisCode}&booking_code=${this.state.selectedFlight2?.BookingCode}&fare_rule_key=${this.state.selectedFlight2?.Fare_rule_Key}&fare_info_key=${this.state.selectedFlight2?.fare_info_key}`;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getreturnflightDetailApiCallId = requestMessage.messageId;

    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    })
    
  };

  getFareSummary = (searchData:any,flightData:any = [],FareBasisCode : any,FareBasisCode2 : any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.faresummaryApiCallId = requestMessage.messageId;
    const air_attributes = flightData.map((flight : any,index : number)=>{
      const temp = {
        "id": index,
        "air_segment_ref": flight.Key,
        "carrier": flight.flight_Carrier,
        "group": flight.Group || 0,
        "flight_number": flight.flight_Id,
        "origin": flight.Origin,
        "destination": flight.Destination,
        "departure_time": flight.DepartureTime,
        "arrival_time": flight.ArrivalTime,
        "class_of_service": flight.ClassOfService
      }  
      return temp;
    })
    const pricing_modifiers = flightData.map((flight : any,index : number)=>{
      const temp = {
        "id": index,
        "fare_basic_code": FareBasisCode,
        "air_segment_ref": flight.Key,
        "code": flight.BookingCode
      }  
      return temp;
    })
    const httpBody = {
      "air_price": {
        "type": this.state.selectedTravellingType,
        "adult": searchData.adults || 0,
        "child": searchData.children || 0,
        "infents": searchData.infants || 0,
        "air_attributes": air_attributes,
        "currency" : this.state.currency,
        "pricing_modifiers": pricing_modifiers
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/travel_search/air_price`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
