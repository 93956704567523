// Object.defineProperty(exports, "__esModule", {
//   value: true
// });

// // Customizable Area Start
// exports.dashboardGetUrl = "/dashboard/dashboards";
// exports.dashboarContentType = "application/json";
// exports.dashboarApiMethodType = "GET";
// exports.dashboardHost = "<calculated when request is sent>";
// exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
// exports.dashboarAccept = "*/*";
// exports.dashboarAcceptEncoding = "gzip, deflate, br";
// exports.dashboarConnection = "keep-alive";
// exports.dashboartoken = "";
// exports.labelTitleText = "dashboard";
// exports.labelBodyText = "dashboard Body";
// // Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.ApiContentType = "application/json";
exports.methodGET = "GET";
exports.loginAPiEndPoint = "login/login";
exports.methodPOST = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End

exports.adminSignupUrl = "account_block/sastos_accounts";
exports.loginUrl ='bx_block_login/sasto_login'
exports.verifyAccountEndPoint ='account_block/sastos_accounts/sasto_email_confirmation'

exports.allBookingEndPoint ='bx_block_dashboard/all_booking'
exports.hotelBookingEndPoint ='bx_block_shopping_cart/hotel_booking'
exports.flightBookingEndPoint ='bx_block_shopping_cart/flight_booking'



exports.hotelSearchEndPoint = "bx_block_advanced_search/hotel_search/search_hotel"
exports.methodGET = "GET"
exports.methodPOST = "POST"
exports.methodPUT = "PUT"
exports.ApiContentType = "application/json";
// Customizable Area End

//Auth Endpoints
exports.userSignUpEndpoint = "account_block/account";
exports.userSignInEndpoint = "bx_block_login/login";
exports.userForgotPasswordEndpoint = "bx_block_profile/reset_password";
exports.userChangePasswordEndpoint = "bx_block_profile/change_password";
exports.userVerifyOTPEndpoints = "bx_block_profile/otp_verify";

//Profile Endpoints
exports.userEditProfile = "bx_block_profile/profile";
exports.getCountriesList = "bx_block_profile/profile/countries_list";
exports.getNationalityList = "bx_block_profile/profile/nationality_list";


exports.oneWayFlightSearch = "bx_block_advanced_search/travel_search/one_way_flight_search";
exports.twoWayFlightSearch = "bx_block_advanced_search/travel_search/two_way_flight_search";
exports.multicityFlightSearch = "bx_block_advanced_search/travel_search/multicity_flight_search";
exports.get_flight_details = "bx_block_advanced_search/travel_search/view_flight"
exports.get_flight_booking_detail = "bx_block_shopping_cart/flight_booking/view_booking"
exports.getCustomerBookings = "bx_block_dashboard/customer_bookings"
exports.getAgancySubAgancyBooking = "bx_block_dashboard/get_agencies_sub_agencies_booking"

exports.allBookingEndPoint ='bx_block_dashboard/all_booking'
exports.hotelBookingEndPoint ='bx_block_shopping_cart/hotel_booking'
exports.flightBookingEndPoint ='bx_block_shopping_cart/flight_booking'


exports.adminSignupUrl = "account_block/sastos_accounts";
exports.adminloginUrl ='bx_block_login/sasto_login'
exports.adminverifyAccountEndPoint ='account_block/sastos_accounts/sasto_email_confirmation'
exports.getSubAgancyList = "bx_block_dashboard/get_agency_associates"
exports.flightSearch = "bx_block_advanced_search/flight_codes"

exports.agencySignUp = "account_block/agencies"
exports.agencyLogin = "bx_block_login/agency_login"
exports.agencyForgotPassword = "bx_block_profile/reset_password"
exports.agencyGetProfileData = "bx_block_dashboard/get_accounts"
exports.agencyEditProfile = "account_block/agencies/update"

//Agency account reports
exports.getTodayAndTotalBookingsEndPoint = "bx_block_statistics_reports/agency_reports/get_today_and_total_bookings"
exports.getBookedAirlinesEndPoint = "bx_block_statistics_reports/agency_reports/get_booked_airlines"
exports.getTotalMonthlyBookingsEndPoint = "bx_block_statistics_reports/agency_reports/get_total_monthly_bookings"
exports.getCancelledBookingsEndPoint = "bx_block_statistics_reports/agency_reports/get_cancelled_bookings"
exports.getAgencyReportsEndPoint = "bx_block_statistics_reports/agency_reports"

exports.getCustomerReportsEndPoint = "bx_block_statistics_reports/customer_booking_reports"

//terms and conditions
exports.createTermsAndConditionsEndPoint = "bx_block_content_management/terms_and_conditions"
exports.getTermsAndConditionsEndPoint = "bx_block_content_management/terms_and_conditions"
