//@ts-nocheck
import React from "react";
import Seat from "./Seat.web";

export default function SeatRow({
  rowNum,
  rowMap,
  setSelectedSeat,
  selectedSeat
}) {
  return (
    <ol className="row fuselage">
      {rowMap.map(seat => {
        const seatID = seat.SeatCode;
        return (
          <li key={seatID}>
            <Seat
              seatID={seatID}
              seatInfo={seat}
              setSelectedSeat={setSelectedSeat}
              isSelected={selectedSeat?.indexOf(seatID) > -1}
              occupied={seat.Availability !== 'Available'}
            />
          </li>
        );
      })}
    </ol>
  );
}
