//@ts-nocheck
import React from "react";
import AddNewSubAgancyController, {
  Props,
} from "./AddNewSubAgancyController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Hidden,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  adminName: Yup.string().required("This field is required."),
  adminEmail: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  adminPhoneNumber: Yup.string()
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
      "Phone number is not valid"
    )
    .required("This field is required"),
  subAgencyName: Yup.string().required("This field is required."),
  subAgencyAddress: Yup.string().required("This field is required"),
  subAgencyPhoneNumber: Yup.string()
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
      "Phone number is not valid"
    )
    .required("This field is required"),
  businessRegistrationNumber: Yup.number().required("This field is required."),
  panNumber: Yup.number().required("This field is required."),
  ntbNumber: Yup.number().required("This field is required."),
  credit: Yup.number().required("This field is required."),
  hotelMarkup: Yup.number().required("This field is required."),
  flightMarkup: Yup.number().required("This field is required."),
  hotelMarkupPercent: Yup.boolean().required("This field is required."),
  flightMarkupPercent: Yup.boolean().required("This field is required."),
});

class EditSubAgancy extends AddNewSubAgancyController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {

    const { history } = this.props
    const { location } = history
    const { state } = location
    const { agencyData } = state

    const authToken = await StorageProvider.get("agancyToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          agencyDataWithId : agencyData
        },
        () => {
          this.getSubAgancyDetial(agencyData.id);
          this.getProfileDetail();
        }
      );
    }
  }
  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          adminName: this.state.agencyData.full_name,
          adminEmail: this.state.agencyData.email,
          adminPhoneNumber: this.state.agencyData.full_phone_number,
          subAgencyName: this.state.agencyData.agency_name,
          subAgencyAddress: this.state.agencyData.agency_address,
          subAgencyPhoneNumber: this.state.agencyData.agency_phone_numbeer,
          businessRegistrationNumber: this.state.agencyData.business_registration_number,
          panNumber: this.state.agencyData.pan_number,
          ntbNumber: this.state.agencyData.permission_ntb_number,
          credit: this.state.agencyData.balance,
          hotelMarkupPercent: false,
          hotelMarkup: '',
          flightMarkupPercent: false,
          flightMarkup: '',
        }}
        validationSchema={Schema}
        enableReinitialize
        onSubmit={(values) => {
          this.handleSubAgencyEdit(values);
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
          } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2} >
                <Grid item xs={11} >
                  <Grid container justify='center' alignItems='center' className={classes.pendIngRequestBox}>
                    <Grid item md={11}>
                      <Typography className={classes.pendIngTite}>
                        Edit SubAgency Details
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11}>
                  <Grid container>
                    <Grid xs={11} item>
                      <Typography
                        gutterBottom
                        className={classes.formHeadingName}
                      >
                        Subagency Essential Details
                      </Typography>
                    </Grid>
                    <Grid xs={11} item>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="adminName"
                            placeholder="Enter Full Name"
                            // onChange={handleChange}
                            value={values.adminName}
                            fullWidth
                            label="Administrator Full Name"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="subAgencyName"
                            placeholder="Enter Name"
                            // onChange={handleChange}
                            value={values.subAgencyName}
                            fullWidth
                            label="Subagency Name"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Grid container spacing={1} justify="space-between">
                            <Grid item xs={12}>
                              <Field
                                component={InputFieled}
                                name="adminEmail"
                                placeholder="Enter Email ID"
                                // onChange={handleChange}
                                value={values.adminEmail}
                                fullWidth
                                label="Administrator Email"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                component={InputFieled}
                                name="adminPhoneNumber"
                                placeholder="Enter Phone Number"
                                // onChange={handleChange}
                                value={values.adminPhoneNumber}
                                fullWidth
                                label="Administrator Phone Number"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="subAgencyAddress"
                            placeholder="Enter Full Address"
                            multiline
                            rows={5}
                            // onChange={handleChange}
                            value={values.subAgencyAddress}
                            fullWidth
                            label="Subagency Address"
                          />
                        </Grid>
                        <Hidden xsDown>
                          <Grid item md={5} xs={12} />
                        </Hidden>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="subAgencyPhoneNumber"
                            placeholder="Enter Phone Number"
                            value={values.subAgencyPhoneNumber}
                            fullWidth
                            label="Subagency Phone Number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={11} item>
                  <Grid container>
                    <Grid xs={11} item>
                      <Typography
                        gutterBottom
                        className={classes.formHeadingName}
                      >
                        Subagency Business Details
                      </Typography>
                    </Grid>
                    <Grid xs={11} item>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="businessRegistrationNumber"
                            placeholder="Enter Number"
                            // onChange={handleChange}
                            value={values.businessRegistrationNumber}
                            fullWidth
                            label="Business Registration Number"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="credit"
                            placeholder="0"
                            // onChange={handleChange}
                            value={values.credit}
                            fullWidth
                            label="Assign Credits"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="panNumber"
                            placeholder="Enter Number"
                            // onChange={handleChange}
                            value={values.panNumber}
                            fullWidth
                            label="VAT/PAN Number"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <ListItem>
                            <ListItemText
                              primary="Available Balance"
                              secondary={`${this.state?.profileData?.balance || "Not Available"}` || "Not Available"}
                            />
                          </ListItem>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="ntbNumber"
                            placeholder="Enter Number"
                            // onChange={handleChange}
                            value={values.ntbNumber}
                            fullWidth
                            label="Permission (NTB) Number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={11} item>
                  <Grid container spacing={1} justify="space-between">
                    <Grid item md={5} xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.formHeadingName}
                      >
                        Define Flight Markup
                      </Typography>
                      <FormControlLabel
                        control={<Checkbox name="flightMarkupPercent" />}
                        classes={{
                          label: classes.sectionHeading,
                        }}
                        label="Use Percentage Instead Of Fixed Markup"
                      />
                      <Field
                        component={InputFieled}
                        name="flightMarkup"
                        placeholder="0.0"
                        // onChange={handleChange}
                        value={values.flightMarkup}
                        fullWidth
                        label="Fixed Markup Value Per Flight Booking"
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.formHeadingName}
                      >
                        Define Hotel Markup
                      </Typography>
                      <FormControlLabel
                        control={<Checkbox name="hotelMarkupPercent" />}
                        classes={{
                          label: classes.sectionHeading,
                        }}
                        label="Use Percentage Instead Of Fixed Markup"
                      />
                      <Field
                        component={InputFieled}
                        name="hotelMarkup"
                        placeholder="0.0"
                        // onChange={handleChange}
                        value={values.hotelMarkup}
                        fullWidth
                        label="Fixed Markup Value Per Hotel Booking"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={11} item>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item xs={2}>
                      <Button
                       variant="outlined"
                       color="primary"
                       fullWidth
                       onClick={()=>this.props.history.push("/AgancyDashborad/AllSubagencies")}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type='submit'
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const EditSubAgancyWithRouter = withRouter(EditSubAgancy);
const EditSubAgancyAlertBox = withAlertBox(EditSubAgancyWithRouter);
const EditSubAgancyLoader = withLoader(EditSubAgancyAlertBox);
const EditSubAgancyToast = withToast(EditSubAgancyLoader);
const EditSubAgancyWithDialog = withDialog(EditSubAgancyToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: '100px'
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    formHeadingName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
  })
)(EditSubAgancyWithDialog);
