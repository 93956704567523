//@ts-nocheck
import React from "react";
import AddNewTeamMemberController, { Props } from "./AddNewTeamMemberController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  DialogContent,
  Button,
  Box,
  FormControl,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import { signUPPoster } from "./assets";
// import { arrow, } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { TextInput } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "yup-phone";
const Schema = Yup.object().shape({
  fullName: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
    contanctNo: Yup.string()
    .phone()
    .required("This field is required"),
  
});

class AddNewTeamMember extends AddNewTeamMemberController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.dataToPass.tokenName || "agancyToken";
    const authToken = await StorageProvider.get(tokenName);
    if (authToken) {
      this.setState(
        {
          token: authToken,
        },
        () => {
           this.getListOfPermission();
        }
      );
    }
  }
  handleChangePermission = (event,value) => {
    if(event.target.checked){
      const selectedPermission = [...this.state.selectedPermission];
      this.setState({selectedPermission : [...selectedPermission,value]})
    } else {
      const selectedPermission = [...this.state.selectedPermission];
      this.setState({selectedPermission : selectedPermission.filter((per)=>{return per.name !== value.name}) })
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          email: this.props?.dataToPass?.teamMemberDetail?.email || "",
          fullName: this.props?.dataToPass?.teamMemberDetail?.full_name || "",
          contanctNo : this.props?.dataToPass?.teamMemberDetail?.full_phone_number || ""
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          this.props?.dataToPass?.teamMemberDetail ? this.handleUpdateLogin(values,this.props?.dataToPass?.teamMemberDetail.id) :  this.handleLogin(values);
        }}
      >
        {(formikProps) => {
          const { values, setFieldValue, isValid, dirty } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={2} justify="center">
                <Grid item xs={10}>
                  <Field
                    component={InputField}
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    placeholder="Enter Full Name"
                  />
                </Grid>

                <Grid item xs={10}>
                  <Field
                    component={InputField}
                    fullWidth
                    label="Email Address"
                    name="email"
                    placeholder="Enter Email Address"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Field
                    component={InputField}
                    fullWidth
                    label="Contact Number"
                    name="contanctNo"
                    placeholder="Enter Contact Number"
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormControl
                    component="fieldset"
                    className={classes.filterformControl}
                  >
                    <FormLabel component="legend">
                       Permissions
                    </FormLabel>
                    <FormGroup>
                      {this.state.permissionList.map((permission)=>{
                        return <FormControlLabel
                          key={permission.id} 
                          classes={{
                            label: classes.sectionHeading,
                          }}
                          control={<Checkbox checked={this.state?.selectedPermission?.findIndex((temp)=>{return permission.name === temp.name})>-1} onChange={(event)=>{this.handleChangePermission(event,permission)}} />}
                          label={permission.name}
                        />
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                   <Grid container justify="flex-end" spacing={1}>
                        <Grid item xs={3}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={()=>{this.props.onCancel()}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                // onClick={()=>{this.props.onCancel()}}
                            >
                                {this.props?.dataToPass?.teamMemberDetail ? "Update" : "Add"}
                            </Button>
                        </Grid>    
                   </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const AddNewTeamMemberWithRouter = withRouter(AddNewTeamMember);
const AddNewTeamMemberWithToast = withToast(AddNewTeamMemberWithRouter);
const AddNewTeamMemberWithLoader = withLoader(AddNewTeamMemberWithToast);
const AddNewTeamMemberWithAlertBox = withAlertBox(AddNewTeamMemberWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundImage: `url(${signUPPoster})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: "pointer",
    },
    filterformControl: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(4),
        textAlign: "center",
      },
  })
)(AddNewTeamMemberWithAlertBox);
