//@ts-nocheck
import React from "react";
import TeamMemberlistController, {
  Props,
} from "./TeamMemberlistController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  IconButton
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AddNewTeamMember from "./AddNewTeamMember.web";
// // import withBoatIdComponent from "./withBoatIdComponent.js";

class TeamMemberlist extends TeamMemberlistController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "authToken";
    const authToken = await StorageProvider.get(tokenName);
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {

          this.getTeamMemberList();
        }
      );
    }
  }
  goToSelectedRoute = (routeName,data,id) => {
    const { history } = this.props;
    history.push({pathname : routeName,state : {...data,id:id}});
  };
  AddNewTeam = () => {
    this.props
      .openDialogBox({
        dataToPass: {tokenName : this.props.tokenName,role : this.props.role},
        renderedComponent: AddNewTeamMember,
        title: "Add New Team Member",
        withCustomDialog: false,
        disableBackdropClick : true,
        disableEscapeKeyDown : false,
        width : '500px'
      })
      .then(async(type : any) => {
         if (type) {
          this.props.showToast({
            type: "success",
            message: "New team member added successfully.",
          });
          this.getTeamMemberList();
        }
      });
  };
  deleteTeamMember = (memberId) => {
    const onClose = (result) => {
      if(result === 'Yes'){
        this.deleteMember(memberId)
      }
    }
    this.props.openConfirmDialog(
      'Delete','Are you sure you want to delete this team memeber',onClose
    )
  }
  updateTeamMember = (teamMemberDetail) => {
    this.props
      .openDialogBox({
        dataToPass: {tokenName : this.props.tokenName,teamMemberDetail : teamMemberDetail,role : this.props.role},
        renderedComponent: AddNewTeamMember,
        title: "Update Team Member",
        withCustomDialog: false,
        disableBackdropClick : true,
        disableEscapeKeyDown : false,
        width : '500px'
      })
      .then(async(type : any) => {
         if (type) {
          this.props.showToast({
            type: "success",
            message: "New team member added successfully.",
          });
          this.getTeamMemberList();
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="space-around" spacing={4}>
          
        <Grid item xs={11} className={classes.pendIngRequestBox}>
            <div style={{display : 'flex'}}>
               <div style={{flexGrow : 1}}>
               <Typography gutterBottom className={classes.pendIngTite}>
                  Team Management
                </Typography>      
               </div>
               <Button variant="contained" color="primary" onClick={()=>{this.AddNewTeam()}}> Add New </Button>
             </div>  
          </Grid>
          {
          this.state.teamMemberList.length > 0 ? 
          this.state.teamMemberList.map((team) => {
            return (
              <Grid item key={team.id} xs={11}>
                <Grid
                  container
                  justify="space-around"
                  className={classes.agencyPendingRequestContainer}
                >
                  <Grid xs={3} item>
                    <Typography className={classes.agencyName} gutterBottom>
                    {this.props.role !== 'Admin' ? (team?.attributes?.full_name || "Not Available") : team[0]?.full_name}
                    </Typography>
                    <Typography className={classes.agencyemail}>
                    {this.props.role !== 'Admin' ? (team?.attributes?.email || "Not Available") : team[0]?.email}
                    </Typography>
                    <Typography
                            style={{ display: "block", marginTop: "8px" }}
                            className={classes.viewActivity}
                          >
                      View Activity
                    </Typography>
                  </Grid>
                  <Grid xs={4} item >
                    <Typography className={classes.agencyName} gutterBottom>
                    { "Permissions"}
                    </Typography>
                    <Typography
                            style={{ display: "block", marginTop: "8px" }}
                            className={classes.agencyemail}
                          >
                            {this.props.role !== 'Admin' ? (team?.attributes?.permissions?.join(",") || "Not Available") : team[0]?.permissions?.join(",")}
                    </Typography>
                  </Grid>
                  <Grid xs={4} item style={{alignSelf : 'center'}}> 
                  <div className={classes.iconroot}>
                      {/* <IconButton color="textPrimary"  aria-label="delete">
                        <CachedIcon />
                      </IconButton> */}
                      <IconButton onClick={()=>{this.props.role !== 'Admin' ? this.deleteTeamMember(team.id) : this.deleteTeamMember(team[0].id)}} aria-label="delete" color="default">
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      <IconButton color="default" aria-label="add an alarm">
                        <VpnKeyOutlinedIcon />
                      </IconButton>
                      <IconButton onClick={()=>{this.props.role !== 'Admin' ? this.updateTeamMember({id : team.id,...team.attributes}) : this.updateTeamMember(team[0])}} color="default" aria-label="add to shopping cart">
                        <CreateOutlinedIcon />
                      </IconButton>
                   </div>
                  </Grid>
                  
                </Grid>
              </Grid>
            );
          })
           : <Grid item  xs={11}>
          <Grid
            container
            justify="space-around"
            className={classes.agencyPendingRequestContainer}
          >
            <Grid item xs={12}>
               <Typography align="center">No team member found</Typography>
            </Grid>
          </Grid>
          </Grid>
        }
        </Grid>
      </div>
    );
  }
}

const TeamMemberlistWithRouter = withRouter(TeamMemberlist);
const TeamMemberlistAlertBox = withAlertBox(TeamMemberlistWithRouter);
const TeamMemberlistLoader = withLoader(TeamMemberlistAlertBox);
const TeamMemberlistToast = withToast(TeamMemberlistLoader);
const TeamMemberlistWithDialog = withDialog(TeamMemberlistToast);
const TeamMemberlistWithConfirmBox = withConfirmBox(TeamMemberlistWithDialog)
export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      padding : theme.spacing(0,2)
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #1565d8",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyemail: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#5a7184",
      wordBreak:'break-all'
    },
    primartText : {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText : {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184"
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewActivity : {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor : 'pointer'
    },
    iconroot: {
      '& > *': {
        margin: theme.spacing(0.2),
     },
    }
  })
)(TeamMemberlistWithConfirmBox);
