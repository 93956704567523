//@ts-nocheck
import React from "react";
import AgencySignUpController, { Props } from "./AgencySignUpController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  DialogContent,
  Dialog,
  IconButton,
  DialogTitle,
} from "@material-ui/core";
import { signUPPoster } from "./assets";
import { withRouter } from "react-router";
import CloseIcon from "@material-ui/icons/Close";

import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "yup-phone";
const Schema = Yup.object().shape({
  fullName: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("This field is required"),
  confirmPassword: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .phone()
    .required("This field is required."),
  agencyName: Yup.string().required("This field is required."),
  agencyAddress: Yup.string().required("This field is required."),
  agencyPhoneNumber: Yup.string()
    .phone()
    .required("This field is required."),
  registrationNumber: Yup.string().required("This field is required."),
  ntbNumber: Yup.string().required("This field is required."),
  panNumber: Yup.string().required("This field is required."),
  termsAndConditions: Yup.boolean()
    .oneOf([true], "Accept terms and condtions to continue")
    .required("Accept terms and condtions to continue"),
});

class AgencySignUp extends AgencySignUpController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
       
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              password: "",
              confirmPassword: "",
              phoneNumber: "",
              agencyName: "",
              agencyAddress: "",
              agencyPhoneNumber: "",
              registrationNumber: "",
              ntbNumber: "",
              panNumber: "",
              termsAndConditions: false,
            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              this.handleAgencySignUp(values);
            }}
          >
            {(formikProps) => {
              const { values, setFieldValue, isValid, dirty } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <Grid container justify="space-around">
                    <Grid item xs={11}>
                      <Grid container justify="space-around">
                        <Grid item xs={11}>
                          <Typography className={classes.subHeading}>
                            Essential Details
                          </Typography>
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Your Full Name"
                            name="fullName"
                            placeholder="Enter Full Name"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Your Email"
                            name="email"
                            placeholder="Enter Email ID"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            type="password"
                            fullWidth
                            label="Password"
                            name="password"
                            placeholder="Enter Password"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            type="password"
                            fullWidth
                            label="Confirm Password"
                            name="confirmPassword"
                            placeholder="Enter Password"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Your Phone Number"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Agency Name"
                            name="agencyName"
                            placeholder="Enter Name"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            multiline
                            rows={4}
                            label="Agency Address"
                            name="agencyAddress"
                            placeholder="Enter Address"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Agency Phone Number"
                            name="agencyPhoneNumber"
                            placeholder="Enter Phone Number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container justify="space-around">
                        <Grid item xs={11}>
                          <Typography className={classes.subHeading}>
                            Agency Business Details
                          </Typography>
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Business Registration Number"
                            name="registrationNumber"
                            placeholder="Enter Number"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Permission (NTB) Number"
                            name="ntbNumber"
                            placeholder="Enter Number"
                          />
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="VAT/PAN Number"
                            name="panNumber"
                            placeholder="Enter Number"
                          />
                        </Grid>
                        <Grid item md={5} xs={10} />
                      </Grid>
                      <Grid item xs={11}>
                        <Grid
                          container
                          style={{ marginBottom: "20px", marginTop: "20px" }}
                          justify="space-around"
                        >
                          <Grid item md={5} xs={10}>
                            <FormControlLabel
                              style={{ flexGrow: 1 }}
                              control={<Checkbox />}
                              name="termsAndConditions"
                              checked={values.termsAndConditions}
                              onChange={() =>
                                setFieldValue(
                                  "termsAndConditions",
                                  !values.termsAndConditions
                                )
                              }
                              label="Terms & Condition"
                              labelPlacement="right"
                              classes={{
                                label: classes.labelText,
                              }}
                            />
                          </Grid>
                          <Grid item md={5} xs={10}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              fullWidth
                            >
                              Apply
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
      </>
    );
  }
}
const AgencySignUpWithRouter = withRouter(AgencySignUp);
const AgencySignUpWithToast = withToast(AgencySignUpWithRouter);
const AgencySignUpWithLoader = withLoader(AgencySignUpWithToast);
const AgencySignUpWithAlertBox = withAlertBox(AgencySignUpWithLoader);

export default withStyles((theme) =>
  createStyles({
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
    },
    heading: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: "#183b56",
      marginTop: "70px",
    },
    subHeading: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.2px",
      color: "#183b56",
      marginBottom: "20px",
      marginTop: "20px",
    },
    root: {
      margin: 0,
      padding: "16px 0px 0px 24px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#110f57",
    },
    dialogTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(AgencySignUpWithAlertBox);
