//@ts-nocheck
import React from "react";
import myBookingsController, { Props } from "./myBookingsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
} from "@material-ui/core";
import InputField from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withDialogBox from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import NewBookingFilterDialog from "./NewBookingFilterDialog.web";
import PhoneIcon from "@material-ui/icons/Phone";
import Rating from "@material-ui/lab/Rating";
import { hotelIcon, allIcon, flightIcon, hotelImage, filterIcon } from "./assets";
import FlightInfo from "./FlightInfo.web";
import HotelInfo from "./HotelInfo.web";
const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
  root: {
    fontSize: '1em'
  }
})(Rating);


class myBookings extends myBookingsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "authToken";
    const authToken = await StorageProvider.get(tokenName);
    const accountId = this.props?.match?.params?.accountId || false;
    if (authToken) {
      this.setState(
        {
          token: authToken,
          accountId: accountId
        },
        () => {
          if (this.props.apiType) {
            this.getBookingsByApiType(this.props.apiType);
          } else if (accountId) {
            this.getBookingsByApiAccountId(accountId);
          } else {
            this.getBookings();
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiType !== prevProps.apiType) {
      this.getBookingsByApiType(this.props.apiType);
    }
  }
  openBookingFilter = () => {
    this.props
      .openDialogBox({
        title: "",
        dataToPass: {
          role: this.props.role,
          tokenName: this.props.tokenName,
          filterData: this.state.filterData
        },
        renderedComponent: NewBookingFilterDialog,
        withCustomDialog: true,
        catchOnCancel: true,
        disableBackdropClick: true,
        scroll: 'body',
        disableEscapeKeyDown: true
      })
      .then((data) => {
        this.getBookings(data);
      });
  };
  goToSelectedRoute = (routeName, flightInfo) => {
    const { history } = this.props;
    const data = {
      searchData: { ...this.state.searchData },
      entityDetail: { ...flightInfo },
      fromCity: {},
      toCity: {},
      entityType: 'Flight',
      booking_id: flightInfo?.id,
      role: this.props.role,
      travaller_id: flightInfo?.id
    }
    history.push({ pathname: routeName, state: data });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter' && this.state.search !== '') {
      if (this.props.apiType) {
        this.getBookingsByApiType(this.props.apiType);
      } else if (this.props?.match?.params?.accountId) {
        this.getBookingsByApiAccountId(accountId);
      } else {
        this.getBookings();
      }
    }
  }

  render() {
    const { classes } = this.props;
    const hotelBooking = this.state.bookingList.filter((booking) => {
      return booking?.type
        !== 'flight_booking'
    })
    const flightBooking = this.state.bookingList.filter((booking) => {
      return booking?.type
        === 'flight_booking'
    })
    return (
      <Grid container justify="space-between" spacing={5}>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ borderRadius: "8px", backgroundColor: "#f8f8fa" }}
          >
            <Tabs
              value={this.state.selectedType}
              onChange={(evt, value) => {
                this.setState(
                  {
                    selectedType: value,
                    search: ''
                  },
                  () => {
                    if (this.props.apiType) {
                      this.getBookingsByApiType(this.props.apiType);
                    } else if (this.state.accountId) {
                      this.getBookingsByApiAccountId(this.state.accountId);
                    } else {
                      this.getBookings();
                    }
                  }
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="All" classes={{ root: classes.tabText }} value={"All"} icon={<img style={{ width: '20px', height: "22px" }} src={allIcon} />} />
              <Tab label="Hotels" classes={{ root: classes.tabText }} value={"Hotels"} icon={<img style={{ width: '20px', height: "22px" }} src={hotelIcon} />} />
              <Tab label="Flights" classes={{ root: classes.tabText }} value={"Flights"} icon={<img style={{ width: '20px', height: "22px" }} src={flightIcon} />} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify={"space-between"} spacing={2}>
            <Grid item xs={10}>
              <InputField
                name="search"
                placeholder="Try searching by PNR, booking ID, Passengers, hotel, airline or destination"
                variant='outlined'
                onChange={(evt) => {
                  this.setState({
                    search: evt.target.value,
                  });
                }}
                onKeyDown={(evt) => {
                  this.handleKeyDown(evt)
                }}
                value={this.state.search}
                fullWidth
              />
            </Grid>
            <Grid xs={2} item>
              <img onClick={() => {
                this.openBookingFilter();
              }}
                src={filterIcon}
                style={{ height: '44px', width: '44px' }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Tabs
              value={this.state.selectedTime}
              onChange={(evt, value) => {
                this.setState(
                  {
                    selectedTime: value,
                    search: ''
                  },
                  () => {
                    if (this.props.apiType) {
                      this.getBookingsByApiType(this.props.apiType);
                    } else if (this.state.accountId) {
                      this.getBookingsByApiAccountId(this.state.accountId);
                    } else {
                      this.getBookings();
                    }
                  }
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab value={"upcoming"} classes={{ root: classes.tabText }} label="Upcoming" />
              <Tab value={"completed"} classes={{ root: classes.tabText }} label="Completed" />
              <Tab value={"cancelled"} classes={{ root: classes.tabText }} label="Cancelled" />
            </Tabs>
          </Paper>
        </Grid>

        <>
          {this.state.selectedType === "All" ? (
            <>
              {
                this.state.bookingList.length > 0 ? <>
                  {hotelBooking.length > 0 && hotelBooking.map((hotel, index) => {
                    return <Grid xs={12} item key={index}>
                      {/* <HotelInfo goToSelectedRoute={this.goToSelectedRoute}></HotelInfo> */}
                    </Grid>
                  })
                  }
                  {flightBooking.length > 0 && flightBooking.map((flight, index) => {
                    return <Grid xs={12} item key={index}>
                      <FlightInfo openPopover={this.props.openPopover} flightDetail={flight} goToSelectedRoute={() => { this.goToSelectedRoute("/myBookings/Flight", flight) }}></FlightInfo>
                    </Grid>
                  })
                  }
                </> : <Grid xs={12} item className={classes.abc}>
                  <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
                </Grid>
              }
            </>

          ) : this.state.selectedType === "Hotels" ? (
            <>
              {
                this.state.bookingList.length > 0 ? this.state.bookingList.map(hotel => (
                  <Grid xs={12} item key={hotel}>
                    {/* <HotelInfo goToSelectedRoute={this.goToSelectedRoute}></HotelInfo> */}
                  </Grid>
                )) : <Grid xs={12} item className={classes.abc}>
                  <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
                </Grid>
              }
            </>
          ) :

            this.state.selectedType === "Flights" ? (
              <>
                {
                  this.state.bookingList.length > 0 ? this.state.bookingList.map(flight => {
                    return <Grid xs={12} item key={flight?.id}>
                      <FlightInfo openPopover={this.props.openPopover} flightDetail={flight} goToSelectedRoute={() => { this.goToSelectedRoute("/myBookings/Flight", flight) }}></FlightInfo>
                    </Grid>
                  }) : <Grid xs={12} item className={classes.abc}>
                    <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
                  </Grid>
                }
              </>
            ) : <></>}
        </>

      </Grid>
    );
  }
}
const myBookingsWithRouter = withRouter(myBookings);
const myBookingsAlertBox = withAlertBox(myBookingsWithRouter);
const myBookingsLoader = withLoader(myBookingsAlertBox);
const myBookingsToast = withToast(myBookingsLoader);
const myBookingswithDialogBox = withDialogBox(myBookingsToast);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: "url(https://source.unsplash.com/random)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "300px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "250px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    appBar: {
      background: "transparent",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 128,
      [theme.breakpoints.down("xs")]: {
        height: 128,
      },
    },
    hotelOptionalImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 72,
    },
    hotelNameHeadingContainer: {
      flexWrap: "wrap",
    },
    hotelNameTitle: {
      flexGrow: 1,
    },
    footerLinkHeading: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    hotelName: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    hotelAddress: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: " ",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: " ",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    logo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
    tabText: {
      textTransform: 'none',
      fontFamily: 'Public Sans',
      fontSize: '20px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: '#183b56',
      textAlign: 'center'
    }
  })
)(myBookingswithDialogBox);
