//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import _ from 'underscore';
export const configJSON = require("./config");
import PolicyDialog from "./PolicyDialog.web";

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken: string;
  fromCity: any;
  toCity: any;
  entityDetail: any;
  searchData: any;
  entityType: "Hotels" | "Flights";
  loading: boolean;
  flightPolicies: any;
  allUserCoupons: any;
  selectedCouponId: any;
  selectedAppliedCouponId: any;
  isCouponApplied: boolean,
  selectedCouponCode: string,
  couponDiscount: string,
  selectedFlight2: any
  fareSummaryDetail: any
  selectedTravellingType: string,
  fareSummaryLst: Array<any>,
  currency: string,
  oneWayFlightList: Array<any>,
  twoWayFlightList: Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewFlightController extends BlockComponent<
  Props,
  S,
  SS
> {
  getflightDetailApiCallId: String = "";
  getPoliciesAndInformationApiCallId: String = "";
  getUserCouponsApiCallId: String = "";
  ApplyCouponApiCallId: String = "";
  getreturnflightDetailApiCallId: String = "";
  faresummaryApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken: "",
      fromCity: {},
      toCity: {},
      entityDetail: {},
      searchData: {},
      entityType: "Flights",
      loading: true,
      flightPolicies: [],
      allUserCoupons: [],
      selectedCouponId: null,
      selectedAppliedCouponId: null,
      isCouponApplied: false,
      selectedCouponCode: "",
      selectedFlight2: null,
      fareSummaryDetail: {},
      selectedTravellingType: 'oneway',
      fareSummaryLst: [],
      currency: 'USD',
      couponDiscount: null,
      oneWayFlightList: [],
      twoWayFlightList: [],
      totalPrice: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getreturnflightDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.length > 0) {
          this.setState({
            selectedFlight2: {
              ...this.state.selectedFlight2,
              ...responseJson?.data?.attributes[0],
            },
          });
        }
      } else if (apiRequestCallId === this.faresummaryApiCallId) {
        this.props.hideLoader();
        if (responseJson?.AirPriceInfo) {
          const dataAirPrice = responseJson;

          const fareSummaryLst = [];
          // const AirPriceInfo = _.groupBy(responseJson?.AirPriceInfo,"type");
          let isAdult = false;
          for (let key in dataAirPrice?.AirPriceInfo) {
            const AirPriceInfo = dataAirPrice?.AirPriceInfo[key]
            const temp = {
              type: '',
              count: 0,
              toalPrice: 0,
              basePrice: 0,
              name: '',
            };

            if (AirPriceInfo.type === 'ADT' && dataAirPrice.adult) {
              temp.name = "Adults"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.adult;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.adult;
              temp.basePrice = AirPriceInfo.base_rate;
              isAdult = true
            } else if (AirPriceInfo.type === 'INF' && dataAirPrice.infents) {
              temp.name = "Infants"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.infents;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.infents;
              temp.basePrice = AirPriceInfo.base_rate;
            } else if (AirPriceInfo.type === 'CNN' && dataAirPrice.child) {
              temp.name = "Children"
              temp["type"] = AirPriceInfo.type;
              temp.count = dataAirPrice.child;
              temp.toalPrice = AirPriceInfo.base_rate * dataAirPrice.child;
              temp.basePrice = AirPriceInfo.base_rate;
            }
            fareSummaryLst.push(temp)
          }
          const returnFlights = [];
          const departureFlights = []
          if (this.state.selectedTravellingType === 'twoway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
              const rf = this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo.find((oldFlight) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (rf) {
                returnFlights.push({ ...rf, ...flight });
              }
            })
          } else if (this.state.selectedTravellingType === 'oneway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
            })
          }
          this.setState({
            fareSummaryDetail: dataAirPrice,
            AirPriceInfo: dataAirPrice?.AirPriceInfo,
            fareSummaryLst: fareSummaryLst,
            totalPrice: responseJson?.total_price
          }, () => {
            this.getUserCoupons();
            if (this.state.selectedTravellingType === 'twoway') {
              this.setState({
                oneWayFlightList: departureFlights,
                twoWayFlightList: returnFlights,
              })
            } else if (this.state.selectedTravellingType === 'oneway') {
              this.setState({
                oneWayFlightList: departureFlights
              })
            }
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getflightDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.length > 0) {
          this.setState({
            entityDetail: {
              ...this.state.entityDetail,
              ...responseJson?.data?.attributes[0],
            },
          });
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getPoliciesAndInformationApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes && typeof responseJson?.data?.attributes !== 'string') {
          this.setState({
            flightPolicies: responseJson?.data?.attributes
          }, () => {
            this.openPolicyDialog(this.state.flightPolicies)
          });
        } else {
          this.props.showToast({ type: 'error', message: "No policy found" })
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getUserCouponsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson && responseJson?.data) {
          this.setState({
            allUserCoupons: responseJson?.data,
          });

        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.ApplyCouponApiCallId) {
        this.props.hideLoader();
        if (responseJson.discount) {
          this.props.showToast({ type: 'success', message: 'Coupon applied successfully' });
          this.setState({
            isCouponApplied: true,
            selectedAppliedCouponId: responseJson?.coupon_code_id,
            couponDiscount: responseJson,
            selectedCouponId: responseJson?.coupon_code_id,
            selectedCouponCode: "",
          })
        } else {
          this.props.showToast({ type: 'error', message: responseJson.error })
        }

        // if (responseJson && responseJson?.coupons && responseJson?.coupons?.data && responseJson?.coupons?.data.length > 0) {
        //   this.setState({
        //     allUserCoupons: responseJson?.coupons?.data
        //   }, () => console.log(this.state.allUserCoupons));
        // }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      });
      if (msg === "Token has Expired" || msg === "Invalid token") {
        await StorageProvider.clearStorage();
        this.props.history.replace("/");
      } else if (msg === "000292 NO PLATING CARRIER FOUND") {
        this.props.history.goBack()
      }
    }
  }

  getFlightDetail = () => {

    this.props.showLoader();
    this.setState({
      entityDetail: { ...this.state.entityDetail, Depature_DateTime: this.state.entityDetail?.Depature_Time, Arrival_DateTime: this.state.entityDetail?.Arrival_Time }
    }, () => {
      let url = `${configJSON.get_flight_details}?carrier=${this.state.entityDetail?.flight_Carrier}&flight_number=${this.state.entityDetail?.flight_Id}&origin=${this.state.entityDetail?.Depature_Iata}&destination=${this.state.entityDetail?.Arrival_Iata}&departure_datetime=${this.state.entityDetail?.Depature_Time}&arrival_datetime=${this.state.entityDetail?.Arrival_Time}&class_of_service=Y&baggage_type=${this.state.entityDetail?.Baggage_Type}&baggage_cabin=${this.state.entityDetail?.Baggage_Cabin}&baggage_check_in=${this.state.entityDetail?.Baggage_Check_In}&mode_of_flight=${this.state.entityDetail?.Mode_Of_Flight}&fare_basis_code=${this.state.entityDetail?.FareBasisCode}&booking_code=${this.state.entityDetail?.BookingCode}&fare_rule_key=${this.state.entityDetail?.Fare_rule_Key}&fare_info_key=${this.state.entityDetail?.fare_info_key}`;
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getflightDetailApiCallId = requestMessage.messageId;

      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })

  };

  getReturnFlightDetail = () => {

    this.props.showLoader();
    this.setState({
      selectedFlight2: { ...this.state.selectedFlight2, Depature_DateTime: this.state.selectedFlight2?.Depature_Time, Arrival_DateTime: this.state.selectedFlight2?.Arrival_Time }
    }, () => {
      let url = `${configJSON.get_flight_details}?carrier=${this.state.selectedFlight2?.flight_Carrier}&flight_number=${this.state.selectedFlight2?.flight_Id}&origin=${this.state.selectedFlight2?.Depature_Iata}&destination=${this.state.selectedFlight2?.Arrival_Iata}&departure_datetime=${this.state.selectedFlight2?.Depature_Time}&arrival_datetime=${this.state.selectedFlight2?.Arrival_Time}&class_of_service=Y&baggage_type=${this.state.selectedFlight2?.Baggage_Type}&baggage_cabin=${this.state.selectedFlight2?.Baggage_Cabin}&baggage_check_in=${this.state.selectedFlight2?.Baggage_Check_In}&mode_of_flight=${this.state.selectedFlight2?.Mode_Of_Flight}&fare_basis_code=${this.state.selectedFlight2?.FareBasisCode}&booking_code=${this.state.selectedFlight2?.BookingCode}&fare_rule_key=${this.state.selectedFlight2?.Fare_rule_Key}&fare_info_key=${this.state.selectedFlight2?.fare_info_key}`;
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getreturnflightDetailApiCallId = requestMessage.messageId;

      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })

  };

  getFareSummary = (searchData: any, flightData: any = []) => {

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.faresummaryApiCallId = requestMessage.messageId;
    const air_attributes = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "air_segment_ref": flight.Key,
        "carrier": flight.flight_Carrier,
        "group": flight.Group || 0,
        "flight_number": flight.flight_Id,
        "origin": flight.Origin,
        "destination": flight.Destination,
        "departure_time": flight.DepartureTime,
        "arrival_time": flight.ArrivalTime,
        "class_of_service": flight.ClassOfService
      }
      return temp;
    })
    const pricing_modifiers = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "fare_basic_code": flight.FareBasisCode,
        "air_segment_ref": flight.Key,
        "code": flight.BookingCode
      }
      return temp;
    })
    const httpBody = {
      "air_price": {
        "type": this.state.selectedTravellingType,
        "adult": searchData.adults || 0,
        "child": searchData.children || 0,
        "infents": searchData.infants || 0,
        "air_attributes": air_attributes,
        "currency": this.state.currency,
        "pricing_modifiers": pricing_modifiers
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/travel_search/air_price`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPoliciesAndInformation = (Fare_rule_Key: any, fare_info_key: any) => {

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPoliciesAndInformationApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        'fare_rule_key': Fare_rule_Key,
        'fare_info_key': fare_info_key
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/travel_search/flight_policy`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserCoupons = () => {

    this.props.showLoader();
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserCouponsApiCallId = requestMessage.messageId;

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account/get_user_coupons?cart_value=${this.state.totalPrice}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  applyCoupon = () => {

    this.setState({
      isCouponApplied: false,
    }, () => {
      this.props.showLoader();
      const headers = {
        "Content-Type": configJSON.ApiContentType,
        "token": this.state.authToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ApplyCouponApiCallId = requestMessage.messageId;
      // const httpBody = {

      //   }

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_advanced_search/travel_search/coupons_application_flight?coupon_code=${this.state?.selectedCouponCode}&cart_value=${this.state?.fareSummaryDetail?.total_price}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      // requestMessage.addData(
      //   getName(MessageEnum.RestAPIRequestBodyMessage),
      //   JSON.stringify(httpBody)
      // );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })

  }

  openPolicyDialog = (policyData: any) => {

    this.props
      .openDialogBox({
        title: "Flight Policy",
        dataToPass: { policyData: policyData },
        renderedComponent: PolicyDialog,
        withCustomDialog: true,
        catchOnCancel: true,
        scroll: "paper"
      })
  }
}



