// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import RouteConfig from "./routeConfig";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import ProjecttaskTracking from '../../blocks/ProjecttaskTracking/src/ProjecttaskTracking';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Gallery from '../../blocks/Gallery/src/Gallery';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import LogoDesign from '../../blocks/LogoDesign/src/LogoDesign';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import Crm3rdPartyIntegration from '../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration';
import MultipleCurrencySupport from '../../blocks/MultipleCurrencySupport/src/MultipleCurrencySupport';
import CvresumeCandidateManagement from '../../blocks/CvresumeCandidateManagement/src/CvresumeCandidateManagement';
import Settings from '../../blocks/Settings/src/Settings';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import ProductQuickview from '../../blocks/ProductQuickview/src/ProductQuickview';
import GdsAvinodeIntegration from '../../blocks/GdsAvinodeIntegration/src/GdsAvinodeIntegration';
import Analytics from '../../blocks/Analytics/src/Analytics';
import CentralisedBilling from '../../blocks/CentralisedBilling/src/CentralisedBilling';
import SalesReporting from '../../blocks/SalesReporting/src/SalesReporting';
import PosIntegration from '../../blocks/PosIntegration/src/PosIntegration';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import TaskViews from '../../blocks/TaskViews/src/TaskViews';
import Notifications from '../../blocks/Notifications/src/Notifications';
import TaskList from '../../blocks/TaskList/src/TaskList';
import PricingEngine from '../../blocks/PricingEngine/src/PricingEngine';
import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import VideoAutoplay from '../../blocks/VideoAutoplay/src/VideoAutoplay';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import InventoryTrendAnalysis from '../../blocks/InventoryTrendAnalysis/src/InventoryTrendAnalysis';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import Feedback from '../../blocks/Feedback/src/Feedback';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Maps from '../../blocks/Maps/src/Maps';
import StatisticsReports from '../../blocks/StatisticsReports/src/StatisticsReports';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import Payments from '../../blocks/Payments/src/Payments';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import Wishlist from '../../blocks/Wishlist/src/Wishlist';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import AuditTrail from '../../blocks/AuditTrail/src/AuditTrail';
import StoreCredits from '../../blocks/StoreCredits/src/StoreCredits';
import SearchHistory from '../../blocks/SearchHistory/src/SearchHistory';
import AffiliateUrl from '../../blocks/AffiliateUrl/src/AffiliateUrl';

// import SignUpBlock from '../../blocks/email-account-login/src/Agency-blocks/signUpBlock/signUpScreen.web';
// import LoginBlock from '../../blocks/email-account-login/src/Agency-blocks/loginBlock/Agency-loginScreen.web';
// import AgencyMarketing from '../../blocks/dashboard/src/Agency/Agency-marketing/AgencyMarketing.web';
// import AgencyMyBooking from '../../blocks/dashboard/src/Agency/Agency-dashboard/AgencyMyBooking2.web';
// import AgencyFilter from '../../blocks/dashboard/src/Agency/Agency-filter/AgencyFilter.web';
// import AgencyAddonPayments from '../../blocks/ShoppingCart/src/agency/AgencyAddonsPayment.web';
// import AgencyFlightResults from '../../blocks/AdvancedSearch/src/agency/AgencyFlightResults/AgencyFlightResults.web';
// import AgencyNewSearch from '../../blocks/AdvancedSearch/src/agency/AgencyNewSearch/AgencyNewSearch.web';
// import AgencyFlightResults2way from '../../blocks/AdvancedSearch/src/agency/AgencyFlightResultsReturn2way.web';
// import AgencyAddTravellers from '../../blocks/ShoppingCart/src/agency/AgencyAddTravellers/AgencyAddTravellers.web';
// import AgencyFlightReview from '../../blocks/ShoppingCart/src/agency/AgencyReviewFlights/AgencyReviewFlights.web';
// import AgencyMyProfile from "../../blocks/dashboard/src/Agency/AgencyMyProfile/AgencyMyProfile.web";
// import AgencyEditProfile from "../../blocks/dashboard/src/Agency/AgencyEditProfile/AgencyEditProfile.web";
// import agencyForgotPassword from "../../blocks/email-account-login/src/Agency-blocks/forgotPasswordBlock/agencyForgotPassword.web";
// import agencyResetPassword from "../../blocks/email-account-login/src/Agency-blocks/resetPassword/agencyResetPassword.web";

const routeMap = {
  // SignUpBlock: {
  //   component: SignUpBlock, //added responsive
  //   path: '/SignUpBlock'
  // },
  // LoginBlock: {
  //   component: LoginBlock, //added   responsive
  //   path: '/LoginBlock'
  // },
  // AgencyMarketing: {
  //   component: AgencyMarketing, //added responsive
  //   path: '/AgencyMarketing'
  // },
  // AgencyMyBooking: {
  //   component: AgencyMyBooking, //added   
  //   path: '/AgencyMyBooking'
  // },
  // AgencyFilter: {
  //   component: AgencyFilter, //added  responsive
  //   path: '/AgencyFilter'
  // },
  // AgencyAddonPayments: {
  //   component: AgencyAddonPayments, //added   responsive
  //   path: '/AgencyAddonPayments'
  // },
  // AgencyNewSearch: {
  //   component: AgencyNewSearch, //added
  //   path: '/AgencyNewSearch'
  // },
  // AgencyAddTravellers: {
  //   component: AgencyAddTravellers, //added
  //   path: '/AgencyAddTravellers'
  // },
  // AgencyFlightReview: {
  //   component: AgencyFlightReview, //added
  //   path: '/AgencyFlightReview'
  // },
  // AgencyFlightResults2way: {
  //   component: AgencyFlightResults2way, //added style error
  //   path: '/AgencyFlightResults2way'
  // },
  // AgencyFlightResults: {
  //   component: AgencyFlightResults, //added style error
  //   path: '/AgencyFlightResults'
  // },
  // AgencyMyProfile: {
  //   component: AgencyMyProfile, //added
  //   path: '/AgencyMyProfile'
  // },
  // AgencyEditProfile: {
  //   component: AgencyEditProfile, //added
  //   path: '/AgencyEditProfile'
  // },
  // agencyForgotPassword: {
  //   component: agencyForgotPassword, //added
  //   path: '/agencyForgotPassword'
  // },
  // agencyResetPassword: {
  //   component: agencyResetPassword, //added
  //   path: '/agencyResetPassword'
  // },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  ProjecttaskTracking: {
    component: ProjecttaskTracking,
    path: '/ProjecttaskTracking'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  LogoDesign: {
    component: LogoDesign,
    path: '/LogoDesign'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  Crm3rdPartyIntegration: {
    component: Crm3rdPartyIntegration,
    path: '/Crm3rdPartyIntegration'
  },
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: '/MultipleCurrencySupport'
  },
  CvresumeCandidateManagement: {
    component: CvresumeCandidateManagement,
    path: '/CvresumeCandidateManagement'
  },
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  ProductQuickview: {
    component: ProductQuickview,
    path: '/ProductQuickview'
  },
  GdsAvinodeIntegration: {
    component: GdsAvinodeIntegration,
    path: '/GdsAvinodeIntegration'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  CentralisedBilling: {
    component: CentralisedBilling,
    path: '/CentralisedBilling'
  },
  SalesReporting: {
    component: SalesReporting,
    path: '/SalesReporting'
  },
  PosIntegration: {
    component: PosIntegration,
    path: '/PosIntegration'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  TaskViews: {
    component: TaskViews,
    path: '/TaskViews'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  PricingEngine: {
    component: PricingEngine,
    path: '/PricingEngine'
  },
  UserGroups: {
    component: UserGroups,
    path: '/UserGroups'
  },
  VideoAutoplay: {
    component: VideoAutoplay,
    path: '/VideoAutoplay'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  InventoryTrendAnalysis: {
    component: InventoryTrendAnalysis,
    path: '/InventoryTrendAnalysis'
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  ProductRecommendationEngine: {
    component: ProductRecommendationEngine,
    path: '/ProductRecommendationEngine'
  },
  Feedback: {
    component: Feedback,
    path: '/Feedback'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Maps: {
    component: Maps,
    path: '/Maps'
  },
  StatisticsReports: {
    component: StatisticsReports,
    path: '/StatisticsReports'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Wishlist: {
    component: Wishlist,
    path: '/Wishlist'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  AuditTrail: {
    component: AuditTrail,
    path: '/AuditTrail'
  },
  StoreCredits: {
    component: StoreCredits,
    path: '/StoreCredits'
  },
  SearchHistory: {
    component: SearchHistory,
    path: '/SearchHistory'
  },
  AffiliateUrl: {
    component: AffiliateUrl,
    path: '/AffiliateUrl'
  },

  Home: {
    component: Ordermanagement,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      // <View style={{ height: '100vh', width: '100vw' }}>
      //   <TopNav />
      //   {WebRoutesGenerator({ routeMap })}
      //   <ModalContainer />
      // </View>
      <RouteConfig/>
    );
  }
}

export default App;
