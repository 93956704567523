//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
  IconButton,
  TablePagination
} from "@material-ui/core";
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import InputFiled from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withDialogBox from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import NewBookingFilterDialog from "./NewBookingFilterDialog.web";
import customerListController,
{Props,
} from "./CustomerListController";
import withFontStyles from "./withFontStyles.web";

class customerList extends customerListController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          token: authToken,
        },
        () => this.getCustomers()
      );
    }
  }
  
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        pageCount: value,
      },
      () => {
        this.getCustomers();
      }
    );
  };
  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPage: parseInt(event.target.value, 10),
        pageCount: 0,
      },
      () => {
        this.getCustomers();
      }
    );
  };

  handleSearch = (evt) => {
    this.setState({
      search: evt.target.value,
    });
  }

  render() {
    const { classes } = this.props;
    return (
        <Grid container justify="space-around" spacing={4}>
          <Grid item xs={12} md={12}>
            <Grid container justify='center' alignItems='center' className={classes.customerBox}>
              <Grid item md={11} xs={11}>
                  <Typography color='textPrimary' className={classes.groteskBold24}>
                    Customers ({this.state.totalCustomer})
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
              <InputFiled
                name="search"
                placeholder="Try searching by name or email ID"
                variant='outlined'
                onChange={(evt) => {
                  this.handleSearch(evt)
                }}
                value={this.state.search}
                fullWidth
                label="Search"
              />
            </Grid>
           
          {this.state.customerList.map((customer) => {
            return (
              <Grid item key={customer.id} xs={12} md={12}>
                <Grid
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={1}
                  className={classes.customerContainer}
                >
                  <Grid item xs={12} md={2}>
                      <Typography align='center' color='primary' className={classes.groteskBold24}>
                        {customer?.full_name}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <Typography align='center' color='textSecondary' className={classes.openSans16}>
                       {customer?.email}
                      </Typography>
                  </Grid>  
                   <Grid xs={6} item md={3}>
                        <Typography align='center' color='primary' className={classes.groteskBold18} onClick={()=>{this.props.history.push("/AdminDashboard/customerBookings/"+customer?.id)}} className={classes.booking}>
                          View Booking
                        </Typography>
                   </Grid>
                   <Grid item xs={6} md={3} container justify='center'>
                      <IconButton color="textPrimary" aria-label="add an alarm">
                        <VpnKeyOutlinedIcon />
                      </IconButton>
                      <IconButton color="textPrimary" aria-label="add to shopping cart">
                        <CreateOutlinedIcon />
                      </IconButton>
                      <IconButton aria-label="delete" color="textPrimary">
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                   </Grid>  
                </Grid>
              </Grid>
            );
          })}
          {this.state.customerList.length >0 && <Grid  item xs={12}>
                    <TablePagination
                            component="div"
                            
                            count={this.state.totalCustomer}
                            page={this.state.pageCount}
                            onChangePage={this.handlePageChange}
                            rowsPerPage={this.state.recordsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                      </Grid>
                   }
        </Grid>
    );
  }
}
const customerListWithRouter = withRouter(customerList);
const customerListAlertBox = withAlertBox(customerListWithRouter);
const customerListLoader = withLoader(customerListAlertBox);
const customerListToast = withToast(customerListLoader);
const customerListwithDialogBox = withDialogBox(customerListToast);
const customerListWithFontStyles = withFontStyles(customerListwithDialogBox)

export default withStyles((theme) =>
  createStyles({
    customerBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: theme.spacing(12)
    },
    customerTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    customerContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("md")]: {
        padding: '30px 54px 33px 35px',
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
      },
    },
    customerName: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor:'poiner'
    },
    booking: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    iconButton: {
      cursor: "pointer",
    },
  })
)(customerListWithFontStyles);