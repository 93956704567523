//@ts-nocheck
import React from "react";
import ReviewFlightController, { Props } from "./ReviewFlightController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  List,
  Button,
  Paper,
  Divider,
  MenuItem,
  Hidden,
  Container,
  Link,
  LinearProgress,
  TextField,
  IconButton,
} from "@material-ui/core";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Redirect, RouterProps, Route } from "react-router-dom";
import MainFlightPostWeb from "./MainFlightPost.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import withFontStyles from "./withFontStyles.web";
import Header from "./Header.web";
import Footer from "./Footer.web";
import {
  flighPlaceHolder,
  flightCover,
  alertIcon,
  covidIcon,
  suitcaseIcon,
} from "./assets";
import moment from "moment";
import _ from "underscore";

const Schema = Yup.object().shape({
  // couponcode: Yup.string().required("This field is required."),
});

class ReviewFlight extends ReviewFlightController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.data?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.data?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    let profileData = await StorageProvider.get("profileData");
    profileData = (profileData && JSON.parse(profileData)) || {};
    const authToken = await StorageProvider.get(tokenKeyName);
    if (
      authToken &&
      state?.data?.entityType &&
      state?.data?.fromCity &&
      state?.data?.toCity &&
      state?.data?.searchData
    ) {
      this.setState(
        {
          authToken: authToken,
          entityType: state?.data?.entityType,
          fromCity: state?.data?.fromCity,
          toCity: state?.data?.toCity,
          searchData: state?.data?.searchData,
          entityDetail: state?.data?.entityDetail,
          loading: false,
          role: state?.data?.role || "Customer",
          selectedTravellingType: state?.data?.selectedTravellingType,
          selectedFlight2: state?.data?.selectedFlight2,
          noOfTravellers: state?.noOfTravellers,
          currency: profileData.currency || "USD",
          selectedCouponCode: "",
          isCouponApplied: false,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getFareSummary(this.state.searchData,
              this.state.entityDetail?.mergeAirSagmentBookingFlightInfo
            );
            // this.getFlightDetail();
          } else if (this.state.selectedTravellingType === "twoway") {
            const departuremergeAirSagmentBookingFlightInfo = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo;
            const returnmergeAirSagmentBookingFlightInfo = this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo;
            this.getFareSummary(this.state.searchData, [
              ...departuremergeAirSagmentBookingFlightInfo,
              ...returnmergeAirSagmentBookingFlightInfo,
            ]);
          }
        }
      );
    } else {
      this.setState({
        loading: false,
        authToken: null,
      });
    }
  }

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.replace({
      state: { data },
      pathname: routeName,
    });
  };
  timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " hr" + " " + extraMin + " " + "min"
    } else {
      return Math.floor(diff.hours) + " hr"
    }
  }

  render() {
    const { classes } = this.props;
    let redirectRouteName = "/";
    if (this.state.role === "Admin") {
      redirectRouteName = "/AdminLogin";
    } else if (this.state.role === "Agency") {
      redirectRouteName = "/AgencyMarketing";
    }
    const { searchData } = this.state;

    const noOfTravellers =
      (+searchData.adults || 0) +
      (+searchData.children || 0) +
      (+searchData.infants || 0);
    return !this.state.loading ? (
      this.state.authToken === null ? (
        <Redirect to={redirectRouteName} />
      ) : (
        <div className={classes.root}>
          <Paper
            className={classes.mainPost}
            style={{
              backgroundImage: `url(${flightCover})`,
              backgroundSize: "cover",
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={flightCover}
                alt={"aaaaaaaaaaaaaaaa"}
              />
            }
            <div className={classes.overlay} />
            {this.state.role && <Header role={this.state.role} />}
            <MainFlightPostWeb fromCity={this.state?.fromCity} toCity={this.state?.toCity} selectedTravellingType={this.state?.selectedTravellingType} searchData={this.state?.searchData} />
            {/* <Grid container justify="center">
              <Grid item md={8} xs={10}>
                <Grid
                  container
                  spacing={4}
                  justify="center"
                  className={classes.mainPostContent}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.helloAdmin} align="center">
                      Review Flight
                    </Typography>
                  </Grid>
                  {this.props.history?.location?.state.data?.role === "Admin" ||
                    this.props.history?.location?.state?.data?.role ===
                    "Agency" ? (
                    <Grid item xs={12}>
                      <Typography
                        className={classes.progressText}
                        align="center"
                      >
                        {this.props.history?.location?.state.data?.searchData
                          .adults > 0
                          ? this.props.history?.location?.state.data?.searchData
                            .adults == 1
                            ? `${this.props.history?.location?.state.data?.searchData.adults} Adult`
                            : `${this.props.history?.location?.state.data?.searchData.adults} Adults`
                          : null}{" "}
                        {this.props.history?.location?.state.data?.searchData
                          .children > 0
                          ? this.props.history?.location?.state.data?.searchData
                            .children == 1
                            ? `${this.props.history?.location?.state.data?.searchData.children} Child`
                            : `${this.props.history?.location?.state.data?.searchData.children} Children`
                          : null}{" "}
                        {this.props.history?.location?.state.data?.searchData
                          .infants > 0
                          ? this.props.history?.location?.state.data?.searchData
                            .infants == 1
                            ? `${this.props.history?.location?.state.data?.searchData.infants} Infant`
                            : `${this.props.history?.location?.state.data?.searchData.infants} Infants`
                          : null}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item md={8} xs={12}>
                    <LinearProgress
                      classes={{ root: classes.linearProgressBar }}
                      variant="determinate"
                      value={50}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Flight Selection
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Review
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Traveller Details
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Make Payment
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.fromCity?.city}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          to
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.toCity?.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.fromCity?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          ○─○
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.toCity?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Paper>
          <Grid container justify="center" spacing={6} className={classes.reviewFlightPage}>
            {this.state.selectedTravellingType === "twoway" && (
              <>
                <Grid item md={7}>
                  <Typography
                    color="textPrimary"
                    className={classes.flightRoutes}
                  >
                    {this.state?.fromCity?.city} to {this.state?.toCity?.city}
                  </Typography>
                </Grid>
                <Grid item md={4}></Grid>
              </>)}
            <Grid item md={7} xs={10}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Flight Information
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight, index) => {
                        return <>
                          <Grid key={flight?.FlightNumber} item xs={12}>
                            <Grid xs={12} item style={{ marginBottom: "30px" }}>
                              <ListItem style={{ padding: 0 }}>
                                <ListItemAvatar>
                                  <img
                                    className={classes.flightImage}
                                    src={
                                      flight?.Flight_Image
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  classes={{
                                    primary: classes.flightName,
                                    secondary: classes.flightCode,
                                  }}
                                  primary={
                                    flight?.Flight_Name || "NA"
                                  }
                                  secondary={`${flight?.Flight_carrier || "-"
                                    }${flight?.Flight_Id || "-"}`}
                                />
                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state?.totalPrice}` || 0} />
                              </ListItem>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                            <Grid xs={12} item style={{ marginBottom: "30px", marginTop: "20px" }}>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {(flight?.Depature_DateTime &&
                                      moment(
                                        flight?.Depature_DateTime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Departure{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.Depature_DateTime &&
                                      moment(
                                        (
                                          flight?.Depature_DateTime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                      "NA"}{", "} {flight?.Depature_City ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                  <div className={classes.linewraper}>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffTime}
                                    >
                                      {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                    </Typography>
                                    <ul className={classes.container}>
                                      <li className={classes.linkconnector} />
                                      <li className={classes.linkconnector} />
                                    </ul>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffStop}
                                    >
                                      {/* {flight?.Mode_Of_Flight} */}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {" "}
                                    {(flight?.Arrival_DateTime &&
                                      moment(
                                        flight?.Arrival_DateTime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Arrival{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.Arrival_DateTime &&
                                      moment(
                                        (
                                          flight?.Arrival_DateTime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                      "NA"}{", "} {flight?.Arrival_City ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={12} item>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={8} md={8} container justify="space-between">
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                  {this.state.AirPriceInfo.length > 0 && this.state.AirPriceInfo.map((airPriceInfo, index) => {
                                    return <>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                    </>
                                  })
                                  }
                                </Grid>
                                <Grid item className={classes.flightInfoDivider}>
                                  <Divider orientation="vertical" variant="middle" style={{ border: "0px solid rgba(195, 202, 217, 1)", color: "#C3CAD9" }} />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} alignItems="center">
                                  <ListItem
                                    style={{ padding: 0 }}
                                    alignItems="center"
                                  >
                                    <ListItemText
                                      primary={`Class : ${flight?.CabinClass}`}
                                      secondary={
                                        "Full Refund Available"
                                      }
                                      classes={{
                                        primary: classes.flightClassText,
                                        secondary: classes.flightClassRefund,
                                      }}
                                    />
                                  </ListItem>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid key={flight?.FlightNumber} item xs={12}>
                            <Grid container spacing={6}>
                              <Grid md={4} xs={12} item>
                                <ListItem style={{ padding: 0 }}>
                                  <ListItemAvatar>
                                    <img
                                      className={classes.flightImage}
                                      src={
                                        flight?.Flight_Image
                                      }
                                      style={{ marginRight: "10px" }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    classes={{
                                      primary: classes.groteskBold24,
                                      secondary: classes.openSans18,
                                    }}
                                    primary={
                                      flight?.Flight_Name || "NA"
                                    }
                                    secondary={`${flight?.Flight_carrier || "-"
                                      } | ${flight?.Flight_Id || "-"}`}
                                  />
                                </ListItem>
                              </Grid>
                              <Grid xs={12} md={8} item>
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                >
                                  <Grid item xs={4}>
                                    <Typography
                                      className={[
                                        classes.openSans20,
                                        classes.gutterBottom2,
                                      ]}
                                      color="textPrimary"
                                      align="left"
                                    >
                                      {(flight?.Depature_DateTime &&
                                        moment(
                                          flight?.Depature_DateTime
                                        ).format("ddd, DD MMM")) ||
                                        "NA"}
                                    </Typography>
                                    <Typography
                                      className={classes.groteskBold18}
                                      color="textPrimary"
                                      align="left"
                                    >
                                      {" "}
                                      Departure{" "}
                                    </Typography>
                                    <Typography
                                      className={classes.openSans16}
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {(flight?.Depature_DateTime &&
                                        moment(
                                          (
                                            flight?.Depature_DateTime
                                          )
                                        ).format("hh:mm A")) ||
                                        "NA"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className={classes.linewraper}>
                                      <Typography
                                        color="primary"
                                        className={classes.groteskBold18}
                                        style={{
                                          lineHeight: 0,
                                        }}
                                      >
                                        {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                      </Typography>
                                      <ul className={classes.container}>
                                        <li className={classes.linkconnector} />
                                        <li className={classes.linkconnector} />
                                      </ul>

                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography
                                      className={[
                                        classes.openSans20,
                                        classes.gutterBottom2,
                                      ]}
                                      color="textPrimary"
                                      align="right"
                                    >
                                      {" "}
                                      {(flight?.Arrival_DateTime &&
                                        moment(
                                          flight?.Arrival_DateTime
                                        ).format("ddd, DD MMM")) ||
                                        "NA"}
                                    </Typography>
                                    <Typography
                                      className={classes.groteskBold18}
                                      color="textPrimary"
                                      align="right"
                                    >
                                      {" "}
                                      Arrival{" "}
                                    </Typography>
                                    <Typography
                                      className={classes.openSans16}
                                      color="textSecondary"
                                      align="right"
                                    >
                                      {(flight?.Arrival_DateTime &&
                                        moment(
                                          (
                                            flight?.Arrival_DateTime
                                          )
                                        ).format("hh:mm A")) ||
                                        "NA"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} container justify="space-between">
                                    <Grid item xs={6}>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterTop2,
                                        ]}
                                        color="textPrimary"
                                        align="left"
                                      >
                                        {" "}
                                        {flight?.Boarding_Terminal ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterBottom2,
                                        ]}
                                        color="textPrimary"
                                        align="left"
                                      >
                                        {" "}
                                        {flight?.Depature_City ||
                                          "NA"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterTop2,
                                        ]}
                                        color="textPrimary"
                                        align="right"
                                      >
                                        {" "}
                                        {flight?.Arriving_Terminal ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterBottom2,
                                        ]}
                                        color="textPrimary"
                                        align="right"
                                      >
                                        {" "}
                                        {flight?.Arrival_City ||
                                          "NA"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} container justify="space-between">
                                    <Grid xs={12} item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        justify="space-between"
                                      >
                                        <Grid item xs={4}>
                                          <ListItem
                                            style={{ padding: 0 }}
                                            alignItems="center"
                                          >
                                            <ListItemText
                                              primary="Baggage"
                                              secondary={
                                                this.state?.AirPriceInfo[0]?.type || "NA"

                                              }
                                              classes={{
                                                primary: classes.groteskBold18,
                                                secondary: classes.openSans16,
                                              }}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <ListItem
                                            style={{ padding: 0 }}
                                            alignItems="center"
                                          >
                                            <ListItemText
                                              primary="Check-In"
                                              secondary={
                                                this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                              }
                                              classes={{
                                                primary: classes.groteskBold18,
                                                secondary: classes.openSans16,
                                              }}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <ListItem
                                            style={{ padding: 0 }}
                                            alignItems="center"
                                          >
                                            <ListItemText
                                              primary="Cabin"
                                              secondary={
                                                this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                              }
                                              classes={{
                                                primary: classes.groteskBold18,
                                                secondary: classes.openSans16,
                                              }}
                                            />
                                          </ListItem>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography display="inline">
                                      {`To read ${this.state.entityDetail?.flight_Name} Terms and Conditions policy,`}
                                    </Typography>
                                    <Link
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.getPoliciesAndInformation(
                                          this.state.entityDetail?.Fare_rule_Key,
                                          flight?.FareInfoRef
                                        );
                                      }}
                                    >
                                      {`click here`}
                                    </Link>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          {index < (this.state.oneWayFlightList.length - 1) &&
                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "30px" }}>
                              <Divider className={classes.dividerStyle} />
                              <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[index].stopName})</Typography>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                          }
                        </>
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.selectedTravellingType === "twoway" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.flightRoutes}
                      >
                        {this.state?.toCity?.city} to {this.state?.fromCity?.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container className={classes.flightBox}>
                        <Grid item xs={12}>
                          <Typography
                            color="textPrimary"
                            className={classes.reviewFlightHeading}
                          >
                            Flight Information
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Divider className={classes.dividerStyle} />
                        </Grid>
                        <Grid item xs={12} className={classes.flightBoxDetails}>
                          {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight, index) => {
                            return <>
                              <Grid key={flight?.FlightNumber} item xs={12}>
                                <Grid xs={12} item style={{ marginBottom: "30px" }}>
                                  <ListItem style={{ padding: 0 }}>
                                    <ListItemAvatar>
                                      <img
                                        className={classes.flightImage}
                                        src={
                                          flight?.Flight_Image
                                        }
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      classes={{
                                        primary: classes.flightName,
                                        secondary: classes.flightCode,
                                      }}
                                      primary={
                                        flight?.Flight_Name || "NA"
                                      }
                                      secondary={`${flight?.Flight_carrier || "-"
                                        }${flight?.Flight_Id || "-"}`}
                                    />
                                    <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state?.totalPrice}` || 0} />
                                  </ListItem>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <Divider className={classes.dividerStyle} />
                                </Grid>
                                <Grid xs={12} item style={{ marginBottom: "30px", marginTop: "20px" }}>
                                  <Grid
                                    container
                                    justify="space-between"
                                  >
                                    <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                      <Typography
                                        className={[
                                          classes.flightDate,
                                        ]}
                                      >
                                        {(flight?.Depature_DateTime &&
                                          moment(
                                            flight?.Depature_DateTime
                                          ).format("ddd, DD MMM")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={classes.flightPlaceMode}
                                      >
                                        {" "}
                                        Departure{" "}
                                      </Typography>
                                      <Typography
                                        className={classes.flightTime}
                                        color="textSecondary"
                                      >
                                        {(flight?.Depature_DateTime &&
                                          moment(
                                            (
                                              flight?.Depature_DateTime
                                            )
                                          ).format("hh:mm A")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.flightAddress,
                                        ]}
                                        color="textPrimary"
                                      >
                                        {" "}
                                        {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                          "NA"}{", "} {flight?.Depature_City ||
                                            "NA"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <div className={classes.linewraper}>
                                        <Typography
                                          color="primary"
                                          className={classes.flightDiffTime}
                                        >
                                          {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                        </Typography>
                                        <ul className={classes.container}>
                                          <li className={classes.linkconnector} />
                                          <li className={classes.linkconnector} />
                                        </ul>
                                        <Typography
                                          color="primary"
                                          className={classes.flightDiffStop}
                                        >
                                          {/* {flight?.Mode_Of_Flight} */}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                      <Typography
                                        className={[
                                          classes.flightDate,
                                        ]}
                                      >
                                        {" "}
                                        {(flight?.Arrival_DateTime &&
                                          moment(
                                            flight?.Arrival_DateTime
                                          ).format("ddd, DD MMM")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={classes.flightPlaceMode}
                                      >
                                        {" "}
                                        Arrival{" "}
                                      </Typography>
                                      <Typography
                                        className={classes.flightTime}
                                        color="textSecondary"
                                      >
                                        {(flight?.Arrival_DateTime &&
                                          moment(
                                            (
                                              flight?.Arrival_DateTime
                                            )
                                          ).format("hh:mm A")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.flightAddress,
                                        ]}
                                        color="textPrimary"
                                      >
                                        {" "}
                                        {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                          "NA"}{", "} {flight?.Arrival_City ||
                                            "NA"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                  <Grid
                                    container
                                    justify="space-between"
                                  >
                                    <Grid item xs={12} sm={8} md={8} container justify="space-between">
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                      {this.state.AirPriceInfo.length > 0 && this.state.AirPriceInfo.map((airPriceInfo, index) => {
                                        return <>
                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                        </>
                                      })
                                      }
                                    </Grid>
                                    <Grid item className={classes.flightInfoDivider}>
                                      <Divider orientation="vertical" variant="middle" style={{ border: "0px solid rgba(195, 202, 217, 1)", color: "#C3CAD9" }} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} alignItems="center">
                                      <ListItem
                                        style={{ padding: 0 }}
                                        alignItems="center"
                                      >
                                        <ListItemText
                                          primary={`Class : ${flight?.CabinClass}`}
                                          secondary={
                                            "Full Refund Available"
                                          }
                                          classes={{
                                            primary: classes.flightClassText,
                                            secondary: classes.flightClassRefund,
                                          }}
                                        />
                                      </ListItem>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {index < (this.state.twoWayFlightList.length - 1) &&
                                <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "30px" }}>
                                  <Divider className={classes.dividerStyle} />
                                  <Typography align="center" className={classes.layoverText}> Layover {this.state.selectedFlight2?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.selectedFlight2?.stops[index].stopName})</Typography>
                                  <Divider className={classes.dividerStyle} />
                                </Grid>
                              }

                              {/* {index < (this.state.twoWayFlightList.length - 1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                            <Divider variant="fullWidth" />
                          </Grid><Grid item md={6} xs={6}>
                              <Paper style={{ backgroundColor: '#1565d8', padding: '10px', width: '100%' }}>
                                <Typography align="center" style={{ color: '#fff' }}>Change of plane {this.state.selectedFlight2?.stops[index].stopDuration} in  {this.state.selectedFlight2?.stops[index].stopName}</Typography>
                              </Paper>
                            </Grid>
                            <Grid item md={3} xs={3}>
                              <Divider variant="fullWidth" />
                            </Grid></Grid>} */}
                            </>
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Refund Policy
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid item xs={12}>
                        <ListItem style={{ padding: 0, marginBottom: "15px" }}>
                          <ListItemAvatar>
                            <img src={alertIcon} className={classes.paraImage} />
                          </ListItemAvatar>
                          <ListItemText classes={{ primary: classes.paraHeading }} primary="Non Refundable Policy" />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.paraText}>
                          Sunt autem quidam e nostris, qui studiose antiqua
                          persequeris, claris et fortibus viris. Quae fuerit causa,
                          mox videro; interea hoc tenebo, si ob rem voluptas sit.
                          Sed ut de homine sensibus reliqui nihil ut alterum
                          aspernandum sentiamus alii autem.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Important Information
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid item xs={12}>
                        <ListItem style={{ padding: 0, marginBottom: "15px" }}>
                          <ListItemAvatar>
                            <img src={covidIcon} className={classes.paraImage} />
                          </ListItemAvatar>
                          <ListItemText classes={{ primary: classes.paraHeading }} primary="COVID- 19 Related" />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.paraText}>
                          Sunt autem quidam e nostris, qui studiose antiqua
                          persequeris, claris et fortibus viris. Quae fuerit causa,
                          mox videro; interea hoc tenebo, si ob rem voluptas sit.
                          Sed ut de homine sensibus reliqui nihil ut alterum
                          aspernandum sentiamus alii autem.
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <ListItem style={{ padding: 0, marginBottom: "15px", marginTop: "30px" }}>
                          <ListItemAvatar>
                            <img src={suitcaseIcon} className={classes.paraImage} />
                          </ListItemAvatar>
                          <ListItemText classes={{ primary: classes.paraHeading }} primary="Baggage Policy" />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.paraText}>
                          Sunt autem quidam e nostris, qui studiose antiqua
                          persequeris, claris et fortibus viris. Quae fuerit causa,
                          mox videro; interea hoc tenebo, si ob rem voluptas sit.
                          Sed ut de homine sensibus reliqui nihil ut alterum
                          aspernandum sentiamus alii autem.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
                      <Grid container spacing={2} justify="space-around" spacing={2}>
                        <Grid item container alignItems='center' xs={10} style={{ marginTop: '20px' }}>
                          <img src={covidIcon} style={{ width: '35px', height: '32px', display: 'inline-block', marginRight: '10px' }} />
                          <Typography display='inline' color='textPrimary' className={classes.groteskBold18}>COVID-19 Related</Typography>
                        </Grid>
                        <Grid item xs={10} >
                          <Typography color='textSecondary' className={classes.openSans16}>
                            Sunt autem quidam e nostris, qui studiose antiqua
                            persequeris, claris et fortibus viris. Quae fuerit causa,
                            mox videro; interea hoc tenebo, si ob rem voluptas sit.
                            Sed ut de homine sensibus reliqui nihil ut alterum
                            aspernandum sentiamus alii autem.
                          </Typography>
                        </Grid>
                        <Grid item container alignItems='center' xs={10} style={{ marginTop: '30px' }}>
                          <img src={suitcaseIcon} style={{ width: '35px', height: '32px', display: 'inline-block', marginRight: '10px' }} />
                          <Typography display='inline' color='textPrimary' className={classes.groteskBold18}>Baggage Policy</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography color='textSecondary' className={classes.openSans16} style={{ marginBottom: '50px' }}>
                            Sunt autem quidam e nostris, qui studiose antiqua
                            persequeris, claris et fortibus viris. Quae fuerit causa,
                            mox videro; interea hoc tenebo, si ob rem voluptas sit. Sed
                            ut de homine sensibus reliqui nihil ut alterum aspernandum
                            sentiamus alii autem.
                          </Typography>
                        </Grid>
                      </Grid>

                    </Grid>
                    {this.state.flightPolicies.length > 0 ? (
                      this.state.flightPolicies.map((policy) => (
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            justify="space-around"
                          >
                            <Grid
                              item
                              alignItems="center"
                              xs={12}
                              style={{ marginTop: "20px" }}
                            >
                              <img src={covidIcon} style={{ width: '35px', height: '32px', display: 'inline-block', marginRight: '10px' }} />
                              <Typography
                                display="inline"
                                color="textPrimary"
                                className={classes.groteskBold18}
                              >
                                {policy.heading}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                color="textSecondary"
                                align="justify"
                                className={classes.openSans16}
                              >
                                {policy.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={2}
                          justify="space-around"
                          spacing={2}
                        >
                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={10}
                            style={{ marginTop: "20px" }}
                          >
                            <img src={covidIcon} style={{ width: '35px', height: '32px', display: 'inline-block', marginRight: '10px' }} />
                            <Typography
                              display="inline"
                              color="textPrimary"
                              className={classes.groteskBold18}
                            >
                              No policies
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )} */}

            <Grid item md={4} xs={10}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Deal Codes
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid xs={12} item className={classes.flightBoxDetails}>
                      <Grid xs={12}>
                        <Formik
                          initialValues={{
                            couponcode: this.state.selectedCouponCode,
                          }}
                          validationSchema={Schema}
                        // onSubmit={(values, actions) => {
                        //   this.applyCoupon();
                        // }}
                        >
                          {(formikProps) => {
                            const { values, errors, setFieldValue, handleChange } = formikProps;
                            return (
                              <Form noValidate autoComplete="off">
                                <Grid item xs={12} className={classes.enterCouponCode}>
                                  <Field
                                    component={InputField}
                                    fullWidth
                                    name="selectedCouponCode"
                                    placeholder="Enter Coupon Code"
                                    value={this.state?.selectedCouponCode}
                                    onChange={(e) => {
                                      this.setState({
                                        selectedCouponCode: e.target.value
                                      })
                                    }}
                                    className={classes.couponcodeInput}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    // type="submit"
                                    className={classes.couponcodeButton}
                                    disabled={this.state?.selectedCouponCode?.length <= 0}
                                    onClick={() => { this.applyCoupon() }}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      </Grid>
                      <Grid xs={12}
                      >
                        {this.state.allUserCoupons.length > 0 ? (
                          this.state.allUserCoupons.map((coupon) => (
                            <Grid key={coupon.id} item xs={12}>
                              <Box
                                onClick={() => {
                                  this.setState({ selectedCouponCode: coupon?.code, selectedCouponId: coupon?.id });
                                }}
                              >
                                <Grid
                                  container
                                  justify="space-between"
                                  className={
                                    this.state?.selectedCouponId &&
                                      this.state?.selectedCouponId === coupon.id ? classes.selectedCouponCode : classes.couponCode
                                  }
                                >
                                  <Grid item xs={12}>
                                    <List>
                                      <ListItem dense={true}>
                                        <ListItemText
                                          classes={{
                                            primary: classes.couponText,
                                            secondary: classes.couponValueText,
                                          }}
                                          primary={coupon.code}
                                          secondary={this.state?.isCouponApplied && this.state?.selectedCouponId === coupon.id && this.state?.selectedAppliedCouponId === coupon?.id ? `Discount Applied : USD ${coupon.discount} ${coupon?.discount_type === 'percentage' ? '%' : '' }` : coupon.description}
                                        />
                                        <ListItemSecondaryAction>
                                          {this.state?.isCouponApplied && this.state?.selectedAppliedCouponId === coupon?.id ? <IconButton edge="end" aria-label="delete">
                                            <CancelOutlinedIcon style={{ height: "36px", width: "36px" }} onClick={() => {
                                              this.setState({ isCouponApplied: false , couponDiscount : null});
                                            }} />
                                          </IconButton> :
                                            <Typography
                                              className={classes.flightValue}
                                            >
                                              {coupon.discount} {coupon?.discount_type === 'percentage' ? '%' : '' }
                                            </Typography>}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      {this.state?.isCouponApplied && this.state?.selectedAppliedCouponId === coupon?.id && <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.couponMsgText,
                                          }}
                                          primary={"Congratulations! Coupon applied successfully."}
                                        />
                                      </ListItem>}
                                    </List>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Grid
                              container
                              justify="space-between"
                              className={classes.noCouponsAvailable}
                            >
                              <Grid item md={8}>
                                <Typography
                                  className={classes.openSans18}
                                  color="textPrimary"
                                >
                                  No Coupons Available
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      className={classes.flightBox}
                    >
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={classes.reviewFlightHeading}
                        >
                          Fare Summary
                        </Typography>
                      </Grid>
                      <Grid md={12} xs={12} item>
                        <Divider className={classes.dividerStyle} />
                      </Grid>
                      <Grid item xs={12} className={classes.flightBoxDetails} >
                        <Grid container justify={"space-between"}>
                          <Grid item xs={12}>
                            <List style={{ padding: "0px" }}>
                              {this.state.fareSummaryLst?.length > 0 &&
                                this.state.fareSummaryLst?.map((fare, index) => {
                                  return (
                                    <ListItem dense={true} key={index} style={{ padding: "0px", marginBottom: "20px" }}>
                                      <ListItemText style={{ margin: "0px" }}
                                        primary={`${fare.name} (${fare.count} X ${this.state.currency} ${fare.basePrice})`}
                                        secondary="Base Fare"
                                        classes={{
                                          primary: classes.flightCharge,
                                          secondary: classes.flightCharge,
                                        }}
                                      />
                                      <ListItemSecondaryAction style={{ right: "0px" }}>
                                        <Typography
                                          className={classes.flightValue}
                                        >
                                          {fare &&
                                            `${this.state.currency} ${fare.toalPrice || "Not Available"}`}
                                        </Typography>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  );
                                })}
                              {/* <ListItem dense={true}>
                                  <ListItemText
                                    primary={`Travellers (${noOfTravellers} X ${this.state.entityDetail.ApproximateBasePrice})`}
                                    secondary="Base Fare"
                                    classes={{
                                      primary: classes.flightCharge,
                                      secondary: classes.flightCharge,
                                    }}
                                  />
                                  <ListItemSecondaryAction>
                                    <Typography className={classes.flightValue}>
                                      {this.state.fareSummaryDetail &&
                                        this.state.fareSummaryDetail.toal_base_price}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                </ListItem> */}
                              <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                                <ListItemText style={{ margin: "0px" }}
                                  primary="Fee & Surcharges"
                                  secondary="Total fee & surcharges"
                                  classes={{
                                    primary: classes.flightCharge,
                                    secondary: classes.flightCharge,
                                  }}
                                />
                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                  <Typography className={classes.flightValue}>
                                    {this.state.fareSummaryDetail &&
                                      `${this.state.currency} ${this.state.fareSummaryDetail.total_tax || "Not Available"}`}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItem>
                              {this.state.isCouponApplied && (
                                <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                                  <ListItemText style={{ margin: "0px" }}
                                    primary="Coupon Discount"
                                    classes={{
                                      primary: classes.flightCharge,
                                      secondary: classes.flightCharge,
                                    }}
                                  />
                                  <ListItemSecondaryAction style={{ right: "0px" }}>
                                    <Typography className={classes.flightValue} style={{ color: "#FF0000" }}>
                                      {`${this.state.currency} ${this.state.couponDiscount?.discount_price || "Not Available"}`}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )}
                              <Divider className={classes.dividerStyle} />
                              <ListItem dense={true} style={{ marginTop: "20px" }}>
                                <ListItemText
                                  classes={{
                                    primary: classes.flightTotalAmountText,
                                  }}
                                  primary={
                                    this.props.history?.location?.state.data
                                      ?.role === "Customer"
                                      ? "Total Amount to be Paid"
                                      : "Total Amount Without Markup"
                                  }
                                />
                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                  {this.props.history?.location?.state.data
                                    ?.role === "Customer" ? (
                                    <Typography className={classes.flightValue}>
                                      {this.state.fareSummaryDetail?.total_price && (!this.state.isCouponApplied ?
                                        `${this.state.currency} ${this.state.fareSummaryDetail?.total_price || "Not Available"}` : this.state.couponDiscount?.after_discount_price)}
                                    </Typography>
                                  ) : (
                                    <Typography className={classes.flightValue}>
                                      {this.state.fareSummaryDetail &&
                                        `${this.state.currency} ${this.state.fareSummaryDetail?.total_price || "Not Available"}`}
                                    </Typography>
                                  )}
                                </ListItemSecondaryAction>
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    disabled={!this.state.fareSummaryDetail?.total_price}
                    onClick={() => {
                      this.goToSelectedRoute("/addTravellers", {
                        searchData: { ...this.state.searchData },
                        entityDetail: { ...this.state.entityDetail },
                        fromCity: this.state.fromCity,
                        toCity: this.state.toCity,
                        entityType: this.state.entityType,
                        role: this.state.role,
                        selectedTravellingType:
                          this.state?.selectedTravellingType,
                        selectedFlight2: this.state?.selectedFlight2,
                        couponDiscount: this.state.couponDiscount || null
                      });
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer marginTop={0}></Footer>
        </div >
      )
    ) : null;
  }
}

const ReviewFlightWithRouter = withRouter(ReviewFlight);
const ReviewFlightAlertBox = withAlertBox(ReviewFlightWithRouter);
const ReviewFlightLoader = withLoader(ReviewFlightAlertBox);
const ReviewFlightToast = withToast(ReviewFlightLoader);
const ReviewFlightWithDialog = withDialog(ReviewFlightToast);
const ReviewFlightWithFontStyles = withFontStyles(ReviewFlightWithDialog);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    gridItem1: {
      order: 1,
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },
    },
    gridItem2: {
      order: 2,
      [theme.breakpoints.down("xs")]: {
        order: 6,
      },
    },
    gridItem3: {
      order: 3,
      [theme.breakpoints.down("xs")]: {
        order: 2,
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(-40),
      },
    },
    gridItem4: {
      order: 4,
      [theme.breakpoints.down("xs")]: {
        order: 3,
      },
    },
    gridItem5: {
      order: 5,
      [theme.breakpoints.up("sm")]: {
        order: 5,
      },
    },
    gridItem6: {
      order: 5,
      [theme.breakpoints.up("sm")]: {
        order: 6,
      },
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      backgroundImage: "url${flightCover}",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    flightImage: {
      width: "75px",
      height: "75px",
      marginRight: "30px",
      [theme.breakpoints.only("xs")]: {
        width: "60px",
        height: "60px",
        marginRight: "15px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightDetailBox: {
      padding: theme.spacing(2),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    flightBox: {
      borderRadius: "5px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      backgroundColor: "rgba(255,255,255, 1)",
      // [theme.breakpoints.up('md')]: {
      //   height: '446px',
      // }
    },
    notchedOutline: {
      borderRadius: "9px",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    lineHeadingwraper: {
      textAlign: "center",
      width: "6%",
      display: "inline-block",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    flightName: {
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#1E394E",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
        fontWeight: "600",
      },
    },
    flightCode: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAalign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightInfo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCharge: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightAdditionChange: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightValue: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    couponCode: {
      backgroundColor: "#E1F7E6",
      borderRadius: "5px",
      // border: "1px dashed rgba(30,57,78, 1)",
      padding: "0px 15px",
      marginBottom: "10px",
      cursor: "pointer",
    },
    noCouponsAvailable: {
      backgroundColor: "#f8f8fa",
      borderRadius: "4px",
      padding: "16px",
    },
    selectedCouponCode: {
      backgroundColor: "#E1F7E6",
      borderRadius: "5px",
      border: "3px dashed rgba(145,163,182, 1)",
      padding: "0px 15px",
      marginBottom: "10px",
      "& button:hover": {
        backgroundColor: "none",
      },
    },
    boxHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    boxSubHeading: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    flightBoxDivider: {
      // width: "611px",
      // height: "1px",
      // opacity: 0.15,
      // backgroundColor: "#979797",

    },
    reviewFlightPage: {
      backgroundColor: "#F6F9FF",
      paddingTop: "60px",
      paddingBottom: "100px",
    },
    reviewFlightHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px",
    },
    flightBoxDetails: {
      padding: "20px",
    },
    flightTotalPrice: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "20px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightDetailText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "17px",
      fontWeight: "700",
      fontWtyle: "normal",
      letterSpacing: "0px",
      marginBottom: "10px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    flightDetailValue: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "15px",
      fontWeight: "500",
      fontStyle: "normal",
      marginBottom: "5px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    flightClassText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightClassRefund: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0.11px",
      lineHeight: "24px",
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "20px",
      },
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "24px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px 0px"
    },
    paraImage: {
      height: "27px",
      width: "27px",
      marginRight: "20px",
      display: "flex",
    },
    paraHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      marginLeft: "-10px",
      letterSpacing: "0px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    paraText: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightTotalAmountText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "600",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    couponMsgText: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
    },
    couponValueText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
    },
    couponText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "24px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "-1.33px",
      textAalign: "left",
    },
    enterCouponCode: {
      marginBottom: "20px",
      position: "relative",
    },
    couponcodeInput: {
      "& input": {
        padding: "16px 14px",
      }
    },
    couponcodeButton: {
      position: "absolute",
      top: "0",
      borderRadius: "8px",
      right: "5px",
      top: "5px",
      width: "40%",
      height: "40px",
      cursor: "pointer",
      boxShadow: "none",
      [theme.breakpoints.only("md")]: {
        width: "30%",
      },
    },
    departureSection: {
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    arrivalSection: {
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightInfoDivider: {
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },
    flightRoutes: {
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "-30px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
  })
)(ReviewFlightWithFontStyles);
