//@ts-nocheck
import React from "react";
import MarkupController, { Props } from "./MarkupController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Divider,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  Box,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import AddNewTeamMember from "./AddNewTeamMember.web";
// import deleteImage from "../assets/image-delete.png";
// // import withBoatIdComponent from "./withBoatIdComponent.js";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import moment from "moment";
const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();
const Schema = Yup.object().shape({
  defaultFixedMarkupValuePerFlightBooking: Yup.number().required(
    "This field is required."
  ),
  // email: Yup.string()
  //   .email("Email must be in valid format!")
  //   .required("This field is required."),
  // addressLineOne: Yup.string().required("This field is required."),
  // addressLineTwo: Yup.string().required("This field is required."),
  // city: Yup.string().required("This field is required."),
  // pincode: Yup.number().required("This field is required"),
  // phoneNumber: Yup.string()
  //   .matches(
  //     /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
  //     "Phone number is not valid"
  //   )
  //   .required("This field is required"),
  // passportNumber: Yup.string().required("This field is required."),
  // passportExpiryDate: Yup.date().required("This field is required."),
});

class Markup extends MarkupController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "adminToken";
    const authToken = await StorageProvider.get(tokenName);
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          // this.getCreditLimits();
        }
      );
    }
  }
  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };
  AddNewTeam = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: AddNewTeamMember,
        title: "Add New Team Member",
        withCustomDialog: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: false,
        width: "500px",
      })
      .then(async (type: any) => {
        if (type === "memberAddedSuccssfully") {
          this.props.showToast({
            type: "success",
            message: "successfully.",
          });
        }
      });
  };
  handleflightMarkupSave(values) {
    console.log(values);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="space-around" spacing={4}>
          <Grid item xs={11} className={classes.markupRequestBox}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Typography gutterBottom className={classes.markupTite}>
                  Markup
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={11}>
            <Grid container justify="space-around" alignItems="center">
              <Grid xs={12} justify="start" item>
                <Formik
                  enableReinitialize
                  initialValues={{
                    defaultFixedMarkupValuePerFlightBooking: "",
                  }}
                  // validationSchema={Schema}
                  onSubmit={(values, actions) => {
                    this.handleProfileSave(values);
                  }}
                >
                  {(formikProps) => {
                    const { values, setFieldValue, handleChange } = formikProps;
                    return (
                      <Form noValidate autoComplete="off">
                        <Grid
                          container
                          spacing={3}
                          className={classes.markupFormContainer}
                        >
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={10}>
                                <Typography
                                  gutterBottom
                                  className={classes.markupTite}
                                >
                                  Flight Markups
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider flexItem variant="middle" />
                              </Grid>
                              <Grid item xs={10} sm={7}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Default Fixed Markup Value Per Flight Booking"
                                  name="defaultFixedMarkupValuePerFlightBooking"
                                  placeholder="0.0"
                                />
                              </Grid>
                              <Grid item xs={10} sm={5}>
                                <Box mt={2}>
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    name="isWhatsApp"
                                    checked={values.isWhatsApp}
                                    onChange={handleChange}
                                    label="Use Percentage Instead Of Fixed Markup"
                                    labelPlacement="end"
                                    classes={{
                                      label: classes.labelText,
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <Typography
                                  className={classes.formSectionHeading}
                                  gutterBottom
                                  variant="h6"
                                >
                                  Based On Airlines
                                </Typography>
                              </Grid>
                              <Grid item xs={10} sm={7}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Select Airline"
                                  name="addressLineOne"
                                  placeholder="House/Flat Number, Street Name"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  spacing={2}
                                  justify="space-around"
                                  alignItems="center"
                                  className={classes.markupFormContainer}
                                >
                                  <Grid item xs={12} sm={3}>
                                    <Typography
                                      className={classes.formSectionHeading}
                                      gutterBottom
                                      variant="h6"
                                    >
                                      Air India
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Field
                                      component={InputField}
                                      required
                                      fullWidth
                                      label="Fixed Markup Value"
                                      name="addressLineTwo"
                                      placeholder="0.0"
                                    />
                                  </Grid>
                                  <Grid item xs={10} sm={4}>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      name="isWhatsApp"
                                      checked={values.isWhatsApp}
                                      onChange={handleChange}
                                      label="Use Percentage"
                                      labelPlacement="end"
                                      classes={{
                                        label: classes.labelText,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    {/* <img src={deleteImage} /> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="City"
                                  name="city"
                                  placeholder="City Name"
                                />
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="ZIP/PIN Code"
                                  name="pincode"
                                  placeholder="Zip or Pin code"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <Typography
                                  className={classes.formSectionHeading}
                                  gutterBottom
                                  variant="h6"
                                >
                                  Based On Airlines
                                </Typography>
                              </Grid>
                              <Grid item xs={10} sm={7}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Select Airline"
                                  name="addressLineOne"
                                  placeholder="House/Flat Number, Street Name"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  spacing={2}
                                  justify="space-around"
                                  alignItems="center"
                                  className={classes.markupFormContainer}
                                >
                                  <Grid item xs={12} sm={3}>
                                    <Typography
                                      className={classes.formSectionHeading}
                                      gutterBottom
                                      variant="h6"
                                    >
                                      Air India
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Field
                                      component={InputField}
                                      required
                                      fullWidth
                                      label="Fixed Markup Value"
                                      name="addressLineTwo"
                                      placeholder="0.0"
                                    />
                                  </Grid>
                                  <Grid item xs={10} sm={4}>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      name="isWhatsApp"
                                      checked={values.isWhatsApp}
                                      onChange={handleChange}
                                      label="Use Percentage"
                                      labelPlacement="end"
                                      classes={{
                                        label: classes.labelText,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    {/* <img src={deleteImage} /> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="City"
                                  name="city"
                                  placeholder="City Name"
                                />
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="ZIP/PIN Code"
                                  name="pincode"
                                  placeholder="Zip or Pin code"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <Typography
                                  className={classes.formSectionHeading}
                                  gutterBottom
                                  variant="h6"
                                >
                                  Based On Airlines
                                </Typography>
                              </Grid>
                              <Grid item xs={10} sm={7}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Select Airline"
                                  name="addressLineOne"
                                  placeholder="House/Flat Number, Street Name"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  spacing={2}
                                  justify="space-around"
                                  alignItems="center"
                                  className={classes.markupFormContainer}
                                >
                                  <Grid item xs={12} sm={3}>
                                    <Typography
                                      className={classes.formSectionHeading}
                                      gutterBottom
                                      variant="h6"
                                    >
                                      Air India
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Field
                                      component={InputField}
                                      required
                                      fullWidth
                                      label="Fixed Markup Value"
                                      name="addressLineTwo"
                                      placeholder="0.0"
                                    />
                                  </Grid>
                                  <Grid item xs={10} sm={4}>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      name="isWhatsApp"
                                      checked={values.isWhatsApp}
                                      onChange={handleChange}
                                      label="Use Percentage"
                                      labelPlacement="end"
                                      classes={{
                                        label: classes.labelText,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    {/* <img src={deleteImage} /> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="City"
                                  name="city"
                                  placeholder="City Name"
                                />
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="ZIP/PIN Code"
                                  name="pincode"
                                  placeholder="Zip or Pin code"
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.formSectionHeading}
                                  gutterBottom
                                >
                                  Contact Details
                                </Typography>
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Phone Number"
                                  name="phoneNumber"
                                  placeholder="Phone Number With Country Code"
                                />
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <FormControlLabel
                                  control={<Checkbox />}
                                  name="isWhatsApp"
                                  checked={values.isWhatsApp}
                                  onChange={handleChange}
                                  label="Yes, I am on WhatsApp with above number"
                                  labelPlacement="end"
                                  classes={{
                                    label: classes.labelText,
                                  }}
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  name="isViber"
                                  checked={values.isViber}
                                  onChange={handleChange}
                                  label="Yes, I am on Viber with above number"
                                  labelPlacement="end"
                                  classes={{
                                    label: classes.labelText,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.formSectionHeading}
                                  gutterBottom
                                >
                                  Passport Details / Citizenship Details
                                </Typography>
                              </Grid>

                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  label="Passport Number"
                                  name="passportNumber"
                                  placeholder="Enter Passport Number"
                                />
                              </Grid>
                              <Grid item md={5} xs={10}>
                                <Field
                                  component={InputField}
                                  required
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      min: curDate,
                                    },
                                  }}
                                  type="date"
                                  label="Passport Expiry Date"
                                  name="passportExpiryDate"
                                  placeholder="12/12/2020"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  color="primary"
                                  variant="contained"
                                >
                                  SAVE
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid
              container
              justify="space-around"
              alignItems="center"
              className={classes.markupPendingRequestContainer}
            >
              <Grid xs={10} justify="start" item>
                <Typography className={classes.markupName} gutterBottom>
                  Hotel Markups
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const MarkupWithRouter = withRouter(Markup);
const MarkupAlertBox = withAlertBox(MarkupWithRouter);
const MarkupLoader = withLoader(MarkupAlertBox);
const MarkupToast = withToast(MarkupLoader);
const MarkupWithDialog = withDialog(MarkupToast);

export default withStyles((theme) =>
  createStyles({
    markupRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      padding: theme.spacing(0, 2),
    },
    markupTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    markupPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    markupName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    labelText: {
      fontSize: "14px",
    },
    markupFormContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(2, 4),
    },
    formSectionHeading: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    iconroot: {
      "& > *": {
        margin: theme.spacing(0.2),
      },
    },
  })
)(MarkupWithDialog);
