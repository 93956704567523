import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  authToken: any,
  countriesList: Array<any>,
  profileData: any;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  saveProfileApiCallId: String = "";
  getCountriesListApiCallID: String = "";
  getProfileDetailApiCallID: String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken: '',
      countriesList: [],
      profileData: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.saveProfileApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id) {
          this.setState({
            ...this.state,
            profileData: responseJson?.data?.attributes
          }, async () => {
            await StorageProvider.set("profileData", JSON.stringify(this.state.profileData))
          })
          this.props.history.push("/myAccount/profile");
          this.props.showToast({ type: 'success', message: 'Profile updated succssfully' });
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getCountriesListApiCallID) {
        // this.props.hideLoader();
        if (responseJson?.countries) {
          this.setState({
            countriesList: responseJson.countries
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.props.hideLoader();
        if (responseJson?.data) {
          this.setState({
            ...this.state,
            profileData: responseJson?.data?.attributes
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      }
    }
  }
  getProfileDetail = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDetailApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userEditProfile}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getCountriesList = () => {
    // this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountriesListApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountriesList}?token=${this.state.authToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleProfileSave = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveProfileApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        "full_name": values.first_name + " " + values.last_name,
        "first_name": values.first_name,
        "last_name": values.last_name,
        "full_phone_number": values.phoneNumber,
        "gender": values.gender,
        "dob": values.dob,
        "currency": values.currency,
        "first_address": values.addressLineOne,
        "second_address": values.addressLineTwo,
        "city": values.city,
        "zip_pin": values.pinCode,
        "country": values.country,
        "whatsapp": values.isWhatsApp,
        "vibar": values.isViber,
        "passport_number": values.passportNumber,
        "passport_expiry": values.passportExpiryDate,
        "nationality": values.country,
        "passport_available": values.isPassport,
        "citizenship_number": values.citizenNumber,
        "citizenship_issue_date": values.citizenNumberExpiryDate,
      },
      "token": this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userEditProfile}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}
