//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const InputField = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin="none",
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
    // {...fieldToTextField(props)}
      {...props}
      variant="outlined"
      margin="none"
      InputLabelProps={{ ...InputLabelProps, shrink: true,classes : {
        asterisk : classes?.astriklabel,
        outlined : classes?.outlinedLabel,
        shrink : classes?.shrink,
        root : classes?.lableroot
      }}}
      
      InputProps={{
        ...InputProps,
        classes: {
          input: classes?.input,
          root : classes?.inputfieledroot,
        },
        notched : false
      }}
    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot : {
      'label + &': {
              marginTop: 30
            }
    },
    input: {
      borderRadius: '8px',
      position: 'relative',
      backgroundColor: '#FFF',
      // border: 'solid 1px #C3CAD9',
      fontSize: '16px',
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '14px 14px',
    },
    astriklabel : {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#ef3e22'
    },
    lableroot : {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#5a7184',
    },
    outlinedLabel : {
      transform : 'translate(3px, 9px) scale(1)!important'
    },
    shrink : {
      transform : 'translate(3px, 9px) scale(1)!important'
    }
  })
)(InputField);
