import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import moment from 'moment';
import _ from 'underscore';

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  selectedTravellingType: string;
  oneWayflights: Array<any>,
  twoWayFlights1: Array<any>,
  twoWayFlights2: Array<any>,
  authToken: string;
  searchData: any;
  fromCity: any;
  toCity: any;
  selectedFlight1: any;
  selectedFlight2: any;
  sourceSuggestionloading: boolean,
  destinationSuggestionloading: boolean,
  sourcesearchSuggestionList: Array<any>
  destinationsearchSuggestionList: Array<any>,
  role: string
  pageCount: number,
  loadingState: boolean,
  page: number;
  twoWayPage: number;
  twoWayReturnPage: number;
  recordsPerPage: number;
  recordsPerPageTwoWay: number;
  recordsPerPageTwoWayReturn: number;
  oneWayflightsTotalCount: number;
  twoWayFlights1TotalCount: number;
  twoWayFlights2TotalCount: number;
  searchBYFilghtCategory: Array<any>,
  searchBYFilghtCategoryReturn: Array<any>,
  searchBySortingFlight: Array<any>,
  searchBySortingFlightReturn: Array<any>,
  searchFlightByDepratureTime: string,
  searchFlightByDepratureTimeReturn: string,
  searchFlightByArrivalTime: string,
  searchFlightByArrivalTimeReturn: string,
  searchFlightByPriceRange: any,
  searchFlightByPriceRangeReturn: any,
  FilghtCategoryList: Array<any>,
  FilghtCategoryListReturn: Array<any>,
  stopCategoryList: Array<any>,
  stopCategoryListReturn: Array<any>,
  sliderMinValue: any,
  sliderMinValueReturn: any,
  sliderMaxValue: any,
  sliderMaxValueReturn: any,
  searchByStopType: Array<any>,
  searchByStopTypeReturn: Array<any>,
  currency: String,
  selectedSortingButton: any,
  selectedFlightViewDetails: any,
  entityDetail: any,
  oneWayFlightList: Array<any>,
  twoWayFlightList: Array<any>,
  AirPriceInfo: any,
  totalPrice: any,
  flightViewDetailsOpen: boolean,
  flight1ViewDetailsOpen: boolean,
  flight2ViewDetailsOpen: boolean,
  selectedFlight1Show: boolean,
  selectedFlight2Show: boolean,
  travellerCountStatus:boolean,
}


interface SS {
  id: any;
}

export default class FlightSearchResultController extends BlockComponent<
  Props,
  S,
  SS
> {
  getoneWaySearchResultsApiCallId: String = "";
  gettwoWaySearchResults1ApiCallId: String = "";
  gettwoWaySearchResults2ApiCallId: String = "";
  getLocationSearchApiCallId: String = "";
  getDestinationLocationSearchApiCallId: String = "";
  getoneWaySearchResultsFilterApiCallId: String = "";
  gettwoWaySearchResults1ApiCallIdForfilter: String = "";
  gettwoWaySearchResults2ApiCallIdFilter: String = "";
  faresummaryApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      selectedTravellingType: 'oneway',
      oneWayflights: [],
      twoWayFlights1: [],
      twoWayFlights2: [],
      authToken: '',
      searchData: {},
      fromCity: {},
      toCity: {},
      selectedFlight1: null,
      selectedFlight2: null,
      role: "",
      sourceSuggestionloading: false,
      travellerCountStatus:false,
      destinationSuggestionloading: false,
      destinationsearchSuggestionList: [],
      sourcesearchSuggestionList: [],
      pageCount: 0,
      loadingState: false,
      page: 0,
      twoWayPage: 0,
      twoWayReturnPage: 0,
      recordsPerPage: 10,
      recordsPerPageTwoWay: 10,
      recordsPerPageTwoWayReturn: 10,
      oneWayflightsTotalCount: 0,
      twoWayFlights1TotalCount: 0,
      twoWayFlights2TotalCount: 0,
      searchBYFilghtCategory: [],
      searchBYFilghtCategoryReturn: [],
      searchByStopType: [],
      searchByStopTypeReturn: [],
      searchBySortingFlight: [],
      searchBySortingFlightReturn: [],
      searchFlightByDepratureTime: "",
      searchFlightByDepratureTimeReturn: "",
      searchFlightByArrivalTime: "",
      searchFlightByArrivalTimeReturn: "",
      searchFlightByPriceRange: [],
      searchFlightByPriceRangeReturn: [],
      FilghtCategoryList: [],
      FilghtCategoryListReturn: [],
      stopCategoryList: [],
      stopCategoryListReturn: [],
      sliderMinValue: 0,
      sliderMinValueReturn: 0,
      sliderMaxValue: 0,
      sliderMaxValueReturn: 0,
      currency: 'USD',
      selectedSortingButton: null,
      selectedFlightViewDetails: "",
      entityDetail: {},
      oneWayFlightList: [],
      twoWayFlightList: [],
      AirPriceInfo: [],
      totalPrice: "",
      flightViewDetailsOpen: false,
      flight1ViewDetailsOpen: false,
      flight2ViewDetailsOpen: false,
      selectedFlight1Show: false,
      selectedFlight2Show: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getoneWaySearchResultsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.meta) {
          const flightSearchData = this.processData(responseJson?.data?.data);
          this.setState({
            oneWayflights: [...(flightSearchData || [])],
            oneWayflightsTotalCount: responseJson?.meta?.pagination?.total_count
          })
        } else {
          this.setState({
            oneWayflights: [],
            oneWayflightsTotalCount: 0,
          }, () => {
            this.parseApiErrorResponse(responseJson)
          })

        }
      } else if (apiRequestCallId === this.getoneWaySearchResultsFilterApiCallId) {
        if (responseJson && responseJson?.data && responseJson?.data.length > 0) {
          const flightSearchData = this.processData(responseJson?.data);
          const flight_Name_list = _.groupBy(flightSearchData, "flight_Name")
          const stopNameList = _.groupBy(flightSearchData, "Mode_Of_Flight");
          const minPriceFlight = _.min(flightSearchData, (flight) => {
            return this.retnum(flight.TotalPrice)
          });
          const maxPriceFlight = _.max(flightSearchData, (flight) => {
            return this.retnum(flight.TotalPrice)
          });
          let min = this.retnum((minPriceFlight?.TotalPrice)) + "";
          // min = min.charAt(0) + "0".repeat(min.length - 1)
          let max = this.retnum((maxPriceFlight?.TotalPrice)) + "";
          // max = (+max.charAt(0) + 1) + "0".repeat(max.length - 1)

          const stopCategoryList = []
          const FilghtCategoryList = [];
          for (let key in flight_Name_list) {
            const temp: any = {};
            temp.name = key;
            temp.count = flight_Name_list[key].length;
            FilghtCategoryList.push(temp);
          }
          for (let key in stopNameList) {
            const temp: any = {};
            temp.name = key;
            temp.count = stopNameList[key].length;
            stopCategoryList.push(temp);
          }
          this.setState({
            FilghtCategoryList: [...(FilghtCategoryList || [])],
            stopCategoryList: stopCategoryList,
            sliderMinValue: Math.floor(Number(min)),
            sliderMaxValue: Math.ceil(Number(max)),
            searchFlightByPriceRange: [Math.floor(Number(min)), Math.ceil(Number(max))]
          })
        } else {
          this.setState({
            FilghtCategoryList: [],
            stopCategoryList: [],
            sliderMinValue: 0,
            sliderMaxValue: 0,
            searchFlightByPriceRange: [0, 0]
          })
          this.parseApiErrorResponse(responseJson)
        }
      } else if (this.gettwoWaySearchResults1ApiCallIdForfilter === apiRequestCallId) {
        if (responseJson && responseJson?.data?.length > 0) {
          const flightSearchData = this.processData(responseJson?.data);
          const flight_Name_list = _.groupBy(flightSearchData, "flight_Name")
          const stopNameList = _.groupBy(flightSearchData, "Mode_Of_Flight");
          const minPriceFlight = _.min(flightSearchData, (flight) => {
            return this.retnum(flight.TotalPrice)
          });
          const maxPriceFlight = _.max(flightSearchData, (flight) => {
            return this.retnum(flight.TotalPrice)
          });
          let min = this.retnum(minPriceFlight?.TotalPrice) + "";
          // min = min.charAt(0) + "0".repeat(min.length - 1)
          let max = this.retnum(maxPriceFlight?.TotalPrice) + ""
          // max = (+max.charAt(0) + 1) + "0".repeat(max.length - 1)
          const stopCategoryList = []
          const FilghtCategoryList = [];
          for (let key in flight_Name_list) {
            const temp: any = {};
            temp.name = key;
            temp.count = flight_Name_list[key].length;
            FilghtCategoryList.push(temp);
          }
          for (let key in stopNameList) {
            const temp: any = {};
            temp.name = key;
            temp.count = stopNameList[key].length;
            stopCategoryList.push(temp);
          }
          this.setState({
            FilghtCategoryList: [...(FilghtCategoryList || [])],
            stopCategoryList: stopCategoryList,
            sliderMinValue: Math.floor(Number(min)),
            sliderMaxValue: Math.ceil(Number(max)),
            searchFlightByPriceRange: [Math.floor(Number(min)), Math.ceil(Number(max))]
          }
            // , () => {
            //   this.getTwoWayReturnSideFlightForFilter(this.state.searchData)
            // }
          )
        } else {
          this.setState({
            FilghtCategoryList: [],
            stopCategoryList: [],
            sliderMinValue: 0,
            sliderMaxValue: 0
          }
            // , () => {
            //   this.getTwoWayReturnSideFlightForFilter(this.state.searchData)
            // }
          )
          this.parseApiErrorResponse(responseJson)
        }
      } else if (this.gettwoWaySearchResults2ApiCallIdFilter === apiRequestCallId) {
        if (responseJson && responseJson?.data?.length > 0) {
          const flightSearchData = this.processData(responseJson?.data);
          const flight_Name_list = _.groupBy(flightSearchData, "flight_Name")
          const stopNameList = _.groupBy(flightSearchData, "Mode_Of_Flight");
          const minPriceFlight = _.min(flightSearchData, (flight) => {
            return this.retnum(flight.TotalPrice)
          });
          const maxPriceFlight = _.max(flightSearchData, (flight) => {
            return this.retnum(flight.TotalPrice)
          });
          let min = +this.retnum(minPriceFlight?.TotalPrice) + "";
          // min = min.charAt(0) + "0".repeat(min.length - 1)
          let max = +this.retnum(maxPriceFlight?.TotalPrice) + ""
          // max = (+max.charAt(0) + 1) + "0".repeat(max.length - 1)
          // max = max > this.state.sliderMaxValue ? max : this.state.sliderMaxValue;
          const stopCategoryList = []
          const FilghtCategoryList = [];
          for (let key in flight_Name_list) {
            const temp: any = {};
            temp.name = key;
            temp.count = flight_Name_list[key].length;
            FilghtCategoryList.push(temp);
          }
          // const mergedFlightCategory = this.mergeByProperty([...this.state.FilghtCategoryList], [...FilghtCategoryList], "name");
          for (let key in stopNameList) {
            const temp: any = {};
            temp.name = key;
            temp.count = stopNameList[key].length;
            stopCategoryList.push(temp);
          }
          // const mergedStopCategory = this.mergeByProperty([...this.state.stopCategoryList], [...stopCategoryList], "name");
          this.setState({
            searchFlightByPriceRangeReturn: [Math.floor(Number(min)), Math.ceil(Number(max))],
            FilghtCategoryListReturn: FilghtCategoryList,
            stopCategoryListReturn: stopCategoryList,
            sliderMinValueReturn: Math.floor(Number(min)),
            sliderMaxValueReturn: Math.ceil(Number(max)),
          })
        } else {
          this.setState({
            FilghtCategoryListReturn: [...this.state.FilghtCategoryListReturn],
            stopCategoryListReturn: [...this.state.stopCategoryListReturn],
            sliderMinValueReturn: 0,
            sliderMaxValueReturn: 0
          })
          this.parseApiErrorResponse(responseJson)
        }
      } else if (this.gettwoWaySearchResults1ApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.departure_array) {
          const flightSearchData = this.processData(responseJson?.data?.departure_array);
          this.setState({
            twoWayFlights1: flightSearchData || [],
            twoWayFlights1TotalCount: responseJson?.meta?.pagination?.total_count
          })
        } else {
          this.setState({
            twoWayFlights1: [],
            twoWayFlights1TotalCount: 0
          })
          this.parseApiErrorResponse(responseJson)
        }
      } else if (this.gettwoWaySearchResults2ApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.return_array) {
          const flightSearchData = this.processData(responseJson?.data?.return_array);
          this.setState({
            twoWayFlights2: flightSearchData || [],
            twoWayFlights2TotalCount: responseJson?.meta?.pagination?.total_count
          })
        } else {
          this.setState({
            twoWayFlights2: [],
            twoWayFlights2TotalCount: 0
          })
          this.parseApiErrorResponse(responseJson)
        }
      } else if (apiRequestCallId === this.getLocationSearchApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({
            sourcesearchSuggestionList: responseJson.data,
            sourceSuggestionloading: false
          })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.getDestinationLocationSearchApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({
            destinationsearchSuggestionList: responseJson.data,
            destinationSuggestionloading: false
          })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.faresummaryApiCallId) {
        this.props.hideLoader();
        if (responseJson?.AirPriceInfo) {
          const dataAirPrice = responseJson;
          const returnFlights: any = [];
          const departureFlights: any = []
          if (this.state.selectedTravellingType === 'twoway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight: any) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
              const rf = this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (rf) {
                returnFlights.push({ ...rf, ...flight });
              }
            })
          } else if (this.state.selectedTravellingType === 'oneway') {
            const Flight_Details = responseJson.Flight_Details || [];
            Flight_Details.forEach((flight: any) => {
              const df = this.state.entityDetail?.mergeAirSagmentBookingFlightInfo.find((oldFlight: any) => { return oldFlight.flight_Id === flight.Flight_Id })
              if (df) {
                departureFlights.push({ ...df, ...flight })
              }
            })
          }
          this.setState({
            AirPriceInfo: dataAirPrice?.AirPriceInfo,
            totalPrice: responseJson?.total_price,
          }, () => {
            if (this.state.selectedTravellingType === 'twoway') {
              this.setState({
                oneWayFlightList: departureFlights,
                twoWayFlightList: returnFlights,
              })
            } else if (this.state.selectedTravellingType === 'oneway') {
              this.setState({
                oneWayFlightList: departureFlights,
              })
            }
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      }
    }
  }
  retnum = (str: string) => {
    if (str) {
      var num = str.replace(/[^0-9\.]+/g, "");
      // return Math.round(parseFloat(num));
      return parseFloat(num);
    }
    return 0
  }

  mergeByProperty = (target: Array<any>, source: Array<any>, prop: string) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? targetElement.count = targetElement.count + sourceElement.count : target.push(sourceElement);
    })
    return [...target];
  }

  processData = (flightSearchData: any) => {
    const processFlightSearchData = [];
    for (let i = 0; i < flightSearchData?.length; i++) {
      const flight = { ...flightSearchData[i] };
      let flightIDs = flightSearchData[i].Flight_Info.map((flight: any) => { return flight.flight_Id });
      flightIDs = Array.from(new Set(flightIDs)).join(",")
      flight.flight_Id = flightIDs;
      flight.flight_Carrier = Array.from(new Set(flightSearchData[i].Flight_Info.map((flight: any) => { return flight.flight_Carrier }))).join(",");
      flight.flight_Image = Array.from(new Set(flightSearchData[i].Flight_Info.map((flight: any) => { return flight.flight_Image }))).join(",");
      flight.flight_Name = Array.from(new Set(flightSearchData[i].Flight_Info.map((flight: any) => { return flight.flight_Name }))).join(",");
      const mergedFlightInfo = [];
      for (let j = 0; j < flightSearchData[i].Flight_Info.length; j++) {
        const flightInfo = flightSearchData[i].Flight_Info[j];
        const airSagmentInfo = flightSearchData[i].Air_Segments.find((newflight: any) => { return flightInfo.flight_Id == newflight.FlightNumber })
        mergedFlightInfo.push({ ...airSagmentInfo, ...flightInfo, FareBasisCode: flight.FareBasisCode })
      }
      const mergeAirSagmentBookingFlightInfo = []
      for (let j = 0; j < mergedFlightInfo.length; j++) {
        const flightInfo = mergedFlightInfo[j];
        // const flightBookingInfo = Array.isArray(flightSearchData[i].BookingInfo) ? flightSearchData[i].BookingInfo.find((bookingflight: any) => { return flightInfo.Key == bookingflight.SegmentRef }) : [flightSearchData[i].BookingInfo].find((bookingflight) => { return flightInfo.Key == bookingflight.SegmentRef })
        const flightBookingInfo = Array.isArray(flightSearchData[i].BookingInfo) ? flightSearchData[i].BookingInfo[j] : [flightSearchData[i].BookingInfo[j]]
        mergeAirSagmentBookingFlightInfo.push({ ...flightBookingInfo, ...flightInfo })
      }
      flight.mergeAirSagmentBookingFlightInfo = mergeAirSagmentBookingFlightInfo;
      if (flight.Air_Segments.length > 1) {
        flight.stops = this.stopCalculation(flight.Air_Segments);
      } else {
        flight.stops = []
      }
      flight.stops = this.stopCalculation(flight.Air_Segments);
      processFlightSearchData.push(flight);
    }
    return processFlightSearchData;
  }
  stopCalculation = (sagmentInfo: any) => {
    const stops = [];
    for (let i = 0; i < sagmentInfo.length - 1; i++) {
      const a = sagmentInfo[i];
      const b = sagmentInfo[i + 1];
      const stop = {
        stopName: '',
        stopDuration: '',
        stopType: ''
      }
      stop.stopName = a.Destination;
      stop.stopDuration = this.timeDiff(b.DepartureTime, a.ArrivalTime)
      stop.stopType = "ChangeOfPlane"
      stops.push(stop);
    }
    return stops
  }
  timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60;
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m";
    } else {
      return Math.floor(diff.hours) + " h";
    }
  }

  getOneWayFlight = (values: any) => {
    this.setState({ searchData: values, flightViewDetailsOpen: false }, () => {
      this.props.showLoader();
      let url = ''
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );;
      if (this.state.selectedTravellingType === 'oneway') {
        const date = moment(values.depratureDate).format("YYYY-MM-DD");
        this.getoneWaySearchResultsApiCallId = requestMessage.messageId;
        const totalPassenger = parseInt(values.adults || 0) + parseInt(values.children || 0) + parseInt(values.infants || 0);
        url = `${configJSON.oneWayFlightSearch}?query[search_type]=${'flight'}&query[journey_type]=${'one_way'}&query[search][from]=${this.state?.fromCity?.iata}&query[search][to]=${this.state?.toCity?.iata}&query[search][departure]=${date}&query[search][class]=${values.class}&query[search][currency]=${this.state.currency}&query[search][number_of_passenger]=${totalPassenger}&query[search][types_of_passengers][adults]=${values.adults}&query[search][types_of_passengers][child]=${(values.children || 0)}&query[search][types_of_passengers][infents]=${values.infants || 0}&page=${this.state.pageCount + 1}&per=${this.state.recordsPerPage}&pagination=true&new_api=true`
      }

      if (this.state?.searchBYFilghtCategory?.length > 0) {
        url += "&query[filters][flight_name]=" + this.state?.searchBYFilghtCategory.join(",")
      }
      if (this.state?.searchByStopType?.length > 0) {
        url += "&query[filters][mode_of_flight]=" + this.state?.searchByStopType.join(",")
      }
      if (this.state.searchFlightByDepratureTime) {
        url += "&query[filters][departur_time_form]=" + this.state.searchFlightByDepratureTime.split("-")[0] + "&query[filters][departur_time_to]=" + this.state.searchFlightByDepratureTime.split("-")[1]
      }
      if (this.state.searchFlightByArrivalTime) {
        url += "&query[filters][arrival_time_from]=" + this.state.searchFlightByArrivalTime.split("-")[0] + "&query[filters][arrival_time_to]=" + this.state.searchFlightByArrivalTime.split("-")[1]
      }
      if (this.state?.searchFlightByPriceRange?.length > 0) {
        url += "&query[filters][min_price]=" + this.state.searchFlightByPriceRange[0] + "&query[filters][max_price]=" + this.state?.searchFlightByPriceRange[1]
      }
      if (this.state?.searchBySortingFlight?.length > 0) {
        url += "&query[filters][flight_sorting]=" + this.state?.searchBySortingFlight
      }
      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  getOneWayFlightForFilter = (values: any) => {
    let url = ''
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );;
    if (this.state.selectedTravellingType === 'oneway') {
      const date = moment(values?.depratureDate).format("YYYY-MM-DD");
      this.getoneWaySearchResultsFilterApiCallId = requestMessage.messageId;
      const totalPassenger = parseInt(values.adults || 0) + parseInt(values.children || 0) + parseInt(values.infants || 0);
      url = `${configJSON.oneWayFlightSearch}?query[search_type]=${'flight'}&query[journey_type]=${'one_way'}&query[search][from]=${this.state?.fromCity?.iata}&query[search][to]=${this.state?.toCity?.iata}&query[search][departure]=${date}&query[search][class]=${values.class}&query[search][number_of_passenger]=${totalPassenger}&query[search][currency]=${this.state.currency}&query[search][types_of_passengers][adults]=${values.adults}&query[search][types_of_passengers][child]=${(values.children || 0)}&query[search][types_of_passengers][infents]=${values.infants || 0}&pagination=false&new_api=true`
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFareSummary = (searchData: any, flightData: any = []) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.faresummaryApiCallId = requestMessage.messageId;
    const air_attributes = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "air_segment_ref": flight.Key,
        "carrier": flight.flight_Carrier,
        "group": flight.Group || 0,
        "flight_number": flight.flight_Id,
        "origin": flight.Origin,
        "destination": flight.Destination,
        "departure_time": flight.DepartureTime,
        "arrival_time": flight.ArrivalTime,
        "class_of_service": flight.ClassOfService
      }
      return temp;
    })
    const pricing_modifiers = flightData.map((flight: any, index: number) => {
      const temp = {
        "id": index,
        "fare_basic_code": flight.FareBasisCode,
        "air_segment_ref": flight.Key,
        "code": flight.BookingCode
      }
      return temp;
    })
    const httpBody = {
      "air_price": {
        "type": this.state.selectedTravellingType,
        "adult": searchData.adults || 0,
        "child": searchData.children || 0,
        "infents": searchData.infants || 0,
        "air_attributes": air_attributes,
        "currency": this.state.currency,
        "pricing_modifiers": pricing_modifiers
      }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/travel_search/air_price`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTwoWaydepratureSideFlight = (values: any) => {
    this.setState({ searchData: values, flight1ViewDetailsOpen: false, selectedFlight1Show: false }, () => {
      this.props.showLoader();
      let url = ''
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );;
      if (this.state.selectedTravellingType === 'twoway') {
        this.gettwoWaySearchResults1ApiCallId = requestMessage.messageId;
        const date = moment(values.depratureDate).format("YYYY-MM-DD");
        const totalPassenger = parseInt(values.adults || 0) + parseInt(values.children || 0) + parseInt(values.infants || 0);
        url = `${configJSON.twoWayFlightSearch}?query[search_type]=${'flight'}&query[journey_type]=${'two_way'}&query[search][from]=${this.state?.fromCity?.iata}&query[search][to]=${this.state?.toCity?.iata}&query[search][departure]=${date}&query[search][return]=${values.returnDate}&query[search][class]=${values.class}&query[search][number_of_passenger]=${totalPassenger}&query[search][currency]=${this.state.currency}&query[search][types_of_passengers][adults]=${values.adults}&query[search][types_of_passengers][child]=${(values.children || 0)}&query[search][types_of_passengers][infents]=${values.infants || 0}&page=${this.state.twoWayPage + 1}&per=${this.state.recordsPerPageTwoWay}&two_way_type=departure&pagination=true&new_api=true`
      }
      if (this.state.searchBYFilghtCategory.length > 0) {
        url += "&query[filters][flight_name]=" + this.state?.searchBYFilghtCategory.join(",")
      }
      if (this.state.searchByStopType.length > 0) {
        url += "&query[filters][mode_of_flight]=" + this.state?.searchByStopType.join(",")
      }
      if (this.state.searchFlightByDepratureTime) {
        url += "&query[filters][departur_time_form]=" + this.state?.searchFlightByDepratureTime.split("-")[0] + "&query[filters][departur_time_to]=" + this.state?.searchFlightByDepratureTime.split("-")[1]
      }
      if (this.state.searchFlightByArrivalTime) {
        url += "&query[filters][arrival_time_from]=" + this.state?.searchFlightByArrivalTime.split("-")[0] + "&query[filters][arrival_time_to]=" + this.state?.searchFlightByArrivalTime.split("-")[1]
      }
      if (this.state.searchFlightByPriceRange.length > 0) {
        url += "&query[filters][min_price]=" + this.state?.searchFlightByPriceRange[0] + "&query[filters][max_price]=" + this.state?.searchFlightByPriceRange[1]
      }
      if (this.state?.searchBySortingFlight?.length > 0) {
        url += "&query[filters][flight_sorting]=" + this.state?.searchBySortingFlight
      }

      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  getTwoWaydepratureSideFlightForFilter = (values: any) => {
    this.setState({ searchData: values, twoWayFlights1: [] }, () => {
      let url = ''
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );;
      if (this.state.selectedTravellingType === 'twoway') {
        this.gettwoWaySearchResults1ApiCallIdForfilter = requestMessage.messageId;
        const date = moment(values.depratureDate).format("YYYY-MM-DD");
        const totalPassenger = parseInt(values.adults || 0) + parseInt(values.children || 0) + parseInt(values.infants || 0);
        url = `${configJSON.twoWayFlightSearch}?query[search_type]=${'flight'}&query[journey_type]=${'two_way'}&query[search][from]=${this.state?.fromCity?.iata}&query[search][to]=${this.state?.toCity?.iata}&query[search][departure]=${date}&query[search][return]=${values.returnDate}&query[search][class]=${values.class}&query[search][number_of_passenger]=${totalPassenger}&query[search][currency]=${this.state.currency}&query[search][types_of_passengers][adults]=${values.adults}&query[search][types_of_passengers][child]=${(values.children || 0)}&query[search][types_of_passengers][infents]=${values.infants || 0}&two_way_type=departure&pagination=false&new_api=true`
      }

      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  getTwoWayReturnSideFlight = (values: any) => {
    this.setState({ searchData: values, flight2ViewDetailsOpen: false, selectedFlight2Show: false }, () => {
      this.props.showLoader();
      let url = ''
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );;

      if (this.state.selectedTravellingType === 'twoway') {
        const date = moment(values.depratureDate).format("YYYY-MM-DD");
        this.gettwoWaySearchResults2ApiCallId = requestMessage.messageId;
        const totalPassenger = parseInt(values.adults || 0) + parseInt(values.children || 0) + parseInt(values.infants || 0);
        url = `${configJSON.twoWayFlightSearch}?query[search_type]=${'flight'}&query[journey_type]=${'two_way'}&query[search][from]=${this.state?.fromCity?.iata}&query[search][to]=${this.state?.toCity?.iata}&query[search][departure]=${date}&query[search][return]=${values.returnDate}&query[search][currency]=${this.state.currency}&query[search][class]=${values.class}&query[search][number_of_passenger]=${totalPassenger}&query[search][types_of_passengers][adults]=${values.adults}&query[search][types_of_passengers][child]=${(values.children || 0)}&query[search][types_of_passengers][infents]=${values.infants || 0}&page=${this.state.twoWayReturnPage + 1}&per=${this.state.recordsPerPageTwoWayReturn}&two_way_type=return&pagination=true&new_api=true`

      }
      if (this.state.searchBYFilghtCategoryReturn.length > 0) {
        url += "&query[filters][flight_name]=" + this.state.searchBYFilghtCategoryReturn.join(",")
      }
      if (this.state.searchByStopTypeReturn.length > 0) {
        url += "&query[filters][mode_of_flight]=" + this.state.searchByStopTypeReturn.join(",")
      }
      if (this.state.searchFlightByDepratureTimeReturn) {
        url += "&query[filters][departur_time_form]=" + this.state.searchFlightByDepratureTimeReturn.split("-")[0] + "&query[filters][departur_time_to]=" + this.state.searchFlightByDepratureTimeReturn.split("-")[1]
      }
      if (this.state.searchFlightByArrivalTimeReturn) {
        url += "&query[filters][arrival_time_from]=" + this.state.searchFlightByArrivalTimeReturn.split("-")[0] + "&query[filters][arrival_time_to]=" + this.state.searchFlightByArrivalTimeReturn.split("-")[1]
      }
      if (this.state.searchFlightByPriceRangeReturn.length > 0) {
        url += "&query[filters][min_price]=" + this.state.searchFlightByPriceRangeReturn[0] + "&query[filters][max_price]=" + this.state.searchFlightByPriceRangeReturn[1]
      }
      if (this.state?.searchBySortingFlightReturn?.length > 0) {
        url += "&query[filters][flight_sorting]=" + this.state?.searchBySortingFlightReturn
      }
      const headers = {
        "Content-Type": configJSON.ApiContentType,
      };

      requestMessage?.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  getTwoWayReturnSideFlightForFilter = (values: any) => {
    let url = ''
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );;

    if (this.state.selectedTravellingType === 'twoway') {
      const date = moment(values.depratureDate).format("YYYY-MM-DD");
      this.gettwoWaySearchResults2ApiCallIdFilter = requestMessage.messageId;
      const totalPassenger = parseInt(values.adults || 0) + parseInt(values.children || 0) + parseInt(values.infants || 0);
      url = `${configJSON.twoWayFlightSearch}?query[search_type]=${'flight'}&query[journey_type]=${'two_way'}&query[search][from]=${this.state?.fromCity?.iata}&query[search][to]=${this.state?.toCity?.iata}&query[search][departure]=${date}&query[search][return]=${values.returnDate}&query[search][class]=${values.class}&query[search][number_of_passenger]=${totalPassenger}&query[search][currency]=${this.state.currency}&query[search][types_of_passengers][adults]=${values.adults}&query[search][types_of_passengers][child]=${(values.children || 0)}&query[search][types_of_passengers][infents]=${values.infants || 0}&two_way_type=return&pagination=false&new_api=true`
    }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFromLocationSuggestion = (location: any) => {
    this.setState({
      sourceSuggestionloading: true
    }, () => {
      if (location) {
        const headers = {
          // "content-type": configJSON.ApiContentType,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.flightSearch}?search=${location}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })
  }
  getDestinationLocationSuggestion = (location: any) => {
    this.setState({
      destinationSuggestionloading: true
    }, () => {
      if (location) {
        const headers = {
          // "content-type": configJSON.ApiContentType,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDestinationLocationSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.flightSearch}?search=${location}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })
  }
}
