//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  ListItemText,
  Button,
  Divider,
  ListItemAvatar,
  Hidden,
} from "@material-ui/core";
import moment from "moment"
import { flighPlaceHolder } from "./assets";
import withFontStyles from "./withFontStyles.web";

const FlightInfo = (props) => {
  const { classes } = props;
  const { flightDetail = {} } = props;
  let travel_detail = flightDetail?.travel_detail?.data?.attributes?.data
    &&
    Object.values(
      flightDetail?.travel_detail?.data?.attributes?.data
    );
  travel_detail = _.groupBy(travel_detail, 'type');
  let trvallName = "";
  if (Object.keys(travel_detail).length > 0) {
    for (let type in travel_detail) {
      trvallName += `${type || ""} ${travel_detail[type].length}`
    }
  }
  const timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60;
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " hr" + " " + extraMin + " " + " min";
    } else {
      return Math.floor(diff.hours) + " hr";
    }
  };
  return (
    <Grid item xs={12} className={classes.detailContainer}>
      <Grid
        container
        justify="space-between"
        // alignContent="center"
        // alignItems="center"
        style={{ margin: "0px" }}
      >
        <Grid item container xs={12} md={3}>
          <Grid item md={11} xs={12} className={classes.pnrBox} >
            <Grid align="start">
              <Typography
                className={classes.pnr}
                gutterBottom
              >
                PNR : {flightDetail?.pnr_number || "NA"}
              </Typography>
              <Typography
                className={classes.bookingDetail}
                gutterBottom
              >
                Booking ID : {flightDetail?.locator_code || "NA"}
              </Typography>
              <Typography
                className={classes.bookingDetail}
                gutterBottom
              >
                Booked On : {flightDetail?.booked_on || "NA"}
              </Typography>
              <Typography
                className={classes.bookingTravellerDetail}
                gutterBottom
              >
                {Object.values(flightDetail?.travel_detail?.data?.attributes?.data)[0]?.sex === 'M' ? 'Mr' : 'Miss'} : {Object.values(flightDetail?.travel_detail?.data?.attributes?.data)[0]?.first_name} {Object.keys(flightDetail?.travel_detail?.data?.attributes?.data)?.length > 1 && '+'} {Object.keys(flightDetail?.travel_detail?.data?.attributes?.data)?.length > 1 && (Object.keys(flightDetail?.travel_detail?.data?.attributes?.data)?.length) - 1} {Object.keys(flightDetail?.travel_detail?.data?.attributes?.data)?.length > 1 && 'Travellers'}
              </Typography>
              {/* <Box mt={2}>

                <Typography
                  align="center"
                  color="textSecondary"
                  className={classes.pnr}
                  gutterBottom
                >
                  Price: {flightDetail?.payment?.data?.attributes?.note?.currency} {flightDetail?.total_amount || "NA"}
                </Typography>

              </Box> */}
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item md={1} justify="center" style={{ display: "flex" }}>
              <Divider
                orientation="vertical"
                className={classes.dividerStyle}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} style={{ margin: "10px 0px" }}>
            <Divider className={classes.dividerStyle} />
          </Grid>
        </Hidden>
        <Grid xs={12} md={9}>
          <Grid>
            {flightDetail?.flights?.oneWayFlightList?.length > 0 &&
              flightDetail?.flights?.oneWayFlightList?.map((flight: any, index) => {
                return (
                  <>
                    <Grid
                      container
                      key={flight?.flight_number}
                    >
                      <Grid xs={2} md={2} item>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemAvatar>
                            <img
                              className={classes.flightImage}
                              src={
                                flight?.flight_logo
                              }
                            />
                          </ListItemAvatar>
                        </ListItem>
                        <ListItemText
                          classes={{
                            primary: classes.flightName,
                            secondary: classes.flightCode,
                          }}
                          primary={
                            flight?.flight_name || "NA"
                          }
                          secondary={`${flight?.flight_carrier || "-"
                            }${flight?.flight_number || "-"}`}
                        />
                      </Grid>
                      <Grid xs={10} md={10} item>
                        <Grid
                          container
                          justify="space-between"
                        >
                          <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                            <Typography
                              className={[
                                classes.flightDate,
                              ]}
                            >
                              {(flight?.depature_datetime &&
                                moment(
                                  flight?.depature_datetime
                                ).format("ddd, DD MMM")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.flightPlaceMode}
                            >
                              {" "}
                              Departure{" "}
                            </Typography>
                            <Typography
                              className={classes.flightTime}
                              color="textSecondary"
                            >
                              {(flight?.depature_datetime &&
                                moment(
                                  flight?.depature_datetime
                                ).format("hh:mm A")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={[
                                classes.flightAddress,
                              ]}
                              color="textPrimary"
                            >
                              {" "}
                              {flight?.boarding_terminal ||
                                "NA"}{", "} {flight?.depature_city ||
                                  "NA"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3} md={3}>
                            <div className={classes.linewraper}>
                              <Typography
                                color="primary"
                                className={classes.flightDiffTime}
                              >
                                {timeDiff(flight?.depature_datetime, flight?.arrival_datetime) || "NA"}
                              </Typography>
                              <ul className={classes.container}>
                                <li className={classes.linkconnector} />
                                <li className={classes.linkconnector} />
                              </ul>
                              <Typography
                                color="primary"
                                className={classes.flightDiffStop}
                              >
                                {/* {flight?.Mode_Of_Flight} */}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                            <Typography
                              className={[
                                classes.flightDate,
                              ]}
                            >
                              {" "}
                              {(flight?.arrival_datetime &&
                                moment(
                                  flight?.arrival_datetime
                                ).format("ddd, DD MMM")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.flightPlaceMode}
                            >
                              {" "}
                              Arrival{" "}
                            </Typography>
                            <Typography
                              className={classes.flightTime}
                              color="textSecondary"
                            >
                              {(flight?.arrival_datetime &&
                                moment(
                                  (
                                    flight?.arrival_datetime
                                  )
                                ).format("hh:mm A")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={[
                                classes.flightAddress,
                              ]}
                              color="textPrimary"
                            >
                              {" "}
                              {flight?.arriving_terminal ||
                                "NA"}{", "} {flight?.arrival_city ||
                                  "NA"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {index < (flightDetail?.flights?.oneWayFlightList?.length - 1) &&
                      <Grid item xs={12} style={{ margin: "10px 0px" }}>
                        <Divider className={classes.dividerStyle} />
                        <Typography align="center" className={classes.layoverText}> Layover {flightDetail?.flights?.oneWayStops[index]?.stopDuration} | {flightDetail?.flights?.oneWayStops[index]?.stopName} ({flight?.arrival_iata})</Typography>
                        <Divider className={classes.dividerStyle} />
                      </Grid>
                    }
                  </>);
              })}
            {(flightDetail?.flights?.twoWayFlightList?.length > 0) && <Grid item xs={12}><Divider style={{ margin: "10px 0px", border: "1px dashed #1565d8" }} /></Grid>}
            {flightDetail?.flights?.twoWayFlightList?.length > 0 &&
              flightDetail?.flights?.twoWayFlightList?.map((flight: any, index) => {
                return (
                  <>
                    <Grid
                      container
                      key={flight?.flight_number}
                    >
                      <Grid xs={2} md={2} item>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemAvatar>
                            <img
                              className={classes.flightImage}
                              src={
                                flight?.flight_logo
                              }
                            />
                          </ListItemAvatar>
                        </ListItem>
                        <ListItemText
                          classes={{
                            primary: classes.flightName,
                            secondary: classes.flightCode,
                          }}
                          primary={
                            flight?.flight_name || "NA"
                          }
                          secondary={`${flight?.flight_carrier || "-"
                            }${flight?.flight_number || "-"}`}
                        />
                      </Grid>
                      <Grid xs={10} md={10} item>
                        <Grid
                          container
                          justify="space-between"
                        >
                          <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                            <Typography
                              className={[
                                classes.flightDate,
                              ]}
                            >
                              {(flight?.depature_datetime &&
                                moment(
                                  flight?.depature_datetime
                                ).format("ddd, DD MMM")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.flightPlaceMode}
                            >
                              {" "}
                              Departure{" "}
                            </Typography>
                            <Typography
                              className={classes.flightTime}
                              color="textSecondary"
                            >
                              {(flight?.depature_datetime &&
                                moment(
                                  flight?.depature_datetime
                                ).format("hh:mm A")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={[
                                classes.flightAddress,
                              ]}
                              color="textPrimary"
                            >
                              {" "}
                              {flight?.boarding_terminal ||
                                "NA"}{", "} {flight?.depature_city ||
                                  "NA"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3} md={3}>
                            <div className={classes.linewraper}>
                              <Typography
                                color="primary"
                                className={classes.flightDiffTime}
                              >
                                {timeDiff(flight?.depature_datetime, flight?.arrival_datetime) || "NA"}
                              </Typography>
                              <ul className={classes.container}>
                                <li className={classes.linkconnector} />
                                <li className={classes.linkconnector} />
                              </ul>
                              <Typography
                                color="primary"
                                className={classes.flightDiffStop}
                              >
                                {/* {flight?.Mode_Of_Flight} */}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                            <Typography
                              className={[
                                classes.flightDate,
                              ]}
                            >
                              {" "}
                              {(flight?.arrival_datetime &&
                                moment(
                                  flight?.arrival_datetime
                                ).format("ddd, DD MMM")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.flightPlaceMode}
                            >
                              {" "}
                              Arrival{" "}
                            </Typography>
                            <Typography
                              className={classes.flightTime}
                              color="textSecondary"
                            >
                              {(flight?.arrival_datetime &&
                                moment(
                                  (
                                    flight?.arrival_datetime
                                  )
                                ).format("hh:mm A")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={[
                                classes.flightAddress,
                              ]}
                              color="textPrimary"
                            >
                              {" "}
                              {flight?.arriving_terminal ||
                                "NA"}{", "} {flight?.arrival_city ||
                                  "NA"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {index < (flightDetail?.flights?.twoWayFlightList?.length - 1) &&
                      <Grid item xs={12} style={{ margin: "10px 0px" }}>
                        <Divider className={classes.dividerStyle} />
                        <Typography align="center" className={classes.layoverText}> Layover {flightDetail?.flights?.twoWayStops[index].stopDuration} | {flightDetail?.flights?.twoWayStops[index].stopName} ({flight?.arrival_iata})</Typography>
                        <Divider className={classes.dividerStyle} />
                      </Grid>
                    }
                  </>);
              })}
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.pnr}
              gutterBottom
            >
              {flightDetail?.payment?.data?.attributes?.note?.currency} : {flightDetail?.total_amount || "NA"}
            </Typography>
            <Typography className={classes.bookingDetail}>Including Taxes</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Divider className={classes.dividerStyle} style={{ margin: "20px 0px" }} />
      </Grid>
      <Grid item xs={12} container>
        <Hidden smDown>
          <Grid item xs={3}></Grid>
        </Hidden>
        <Grid item xs={4} md={3} className={classes.bookingButton}>
          <Button
            color="primary"
            variant="outlined"
          >
            Download
          </Button>
        </Grid>
        <Grid item xs={4} md={3} className={classes.bookingButton}>
          <Button
            color="primary"
            variant="outlined"
          >
            Share
          </Button>
        </Grid>
        <Grid item xs={4} md={3} className={classes.bookingButton}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              props.goToSelectedRoute(
                "/myBookings/Flight",
                flightDetail
              );
            }}
          >
            View
          </Button>
        </Grid>
      </Grid>
    </Grid >
  );
};
const FlightInfoWithFontStyles = withFontStyles(FlightInfo);
export default withStyles((theme) =>
  createStyles({
    detailContainer: {
      padding: "20px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0,
      letterSpacing: "normal",
      textAlign: "center",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "700",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1E394E",
      marginTop: "10px",
    },
    bookingDetail: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
      color: "#91A3B6",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    flightImage: {
      width: "60px",
      height: "60px",
      marginRight: "15px",
      [theme.breakpoints.only("xs")]: {
        width: "50px",
        height: "50px",
      },
    },
    tabText: {
      textTransform: "none",
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      textAlign: "center",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    bookingButton: {
      display: "flex",
      justifyContent: "end",
      "& button": {
        width: "90%",
      }
    },
    bookingTravellerDetail: {
      fontSize: "18px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1E394E",
    },
    departureSection: {
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    arrivalSection: {
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "10px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "5px 0px"
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "20px",
      },
    },
    flightName: {
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#1E394E",
      wordBreak: "break-all",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
        fontWeight: "600",
      },
    },
    flightCode: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "15px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAalign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    pnrBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    }
  })
)(FlightInfoWithFontStyles);
