//@ts-nocheck
import React from "react";
import AddAddonsController, { Props } from "./AddAddonsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  Button,
  Paper,
  Divider,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  List,
  ListItemText,
  Checkbox,
  MenuItem,
  Avatar,
  FormGroup,
  FormControl,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import moment from "moment";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withFontStyles from "./withFontStyles.web";

import {
  flighPlaceHolder,
  flightCover,
  addInsuranceIcon,
  addSeatIcon,
  addFlightIcon,
} from "./assets";
import { Redirect, RouterProps, Route } from "react-router-dom";
import Header from "./Header.web";
import Footer from "./Footer.web";
import InputFiledWeb from "../../../components/src/InputFiled.web";

const curDate = moment(new Date()).format("YYYY-MM-DD").toString();

class AddAddonsAgency extends AddAddonsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    let profileData = await StorageProvider.get("profileData");
    profileData =  profileData && JSON.parse(profileData) || {};
    if (
      authToken &&
      state?.role &&
      state?.entityType &&
      state?.fromCity &&
      state?.toCity &&
      state?.searchData &&
      state?.booking_id &&
      state?.travaller_id,
      state?.travellerInfo
    ) {
      this.setState(
        {
          authToken: authToken,
          entityType: state?.entityType,
          fromCity: state?.fromCity,
          toCity: state?.toCity,
          searchData: state?.searchData,
          entityDetail: state?.entityDetail,
          loading: false,
          booking_id: state?.booking_id,
          travaller_id: state?.travaller_id,
          role: state?.role,
          noOfTravellers: state?.noOfTravellers,
          selectedTravellingType: state?.selectedTravellingType,
          selectedFlight2: state?.selectedFlight2,
          currency : profileData.currency || "USD",
          travellerInfo : state?.travellerInfo,
          couponDiscount : state?.couponDiscount,
          profileData : profileData
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getFareSummary(this.state.searchData, 
              this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              this.state.entityDetail?.FareBasisCode
            );
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getFareSummary(this.state.searchData, [
              ...this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              ...this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo,
            ],this.state.entityDetail?.FareBasisCode);
          }
          // this.getFlightDetail()
          // if(this.state.selectedTravellingType === 'twoway'){
          //   this.getReturnFlightDetail();
          // }
        }
      );
    } else {
      this.setState({
        loading: false,
        authToken: null,
      });
    }
  }

  handleInsurancce = (name) => {
    if (this.state.selectedInsurance.indexOf(name) > -1) {
      const selectedInsurance = this.state.selectedInsurance.filter((ins) => {
        return ins !== name;
      });
      this.setState({
        selectedInsurance: selectedInsurance,
      });
    } else {
      this.setState({
        selectedInsurance: [...this.state.selectedInsurance, name],
      });
    }
  };

  handleUpgrades = (name) => {
    if (this.state.selectedUpgrades.indexOf(name) > -1) {
      const selectedUpgrades = this.state.selectedUpgrades.filter((ins) => {
        return ins !== name;
      });
      this.setState({
        selectedUpgrades: selectedUpgrades,
      });
    } else {
      this.setState({
        selectedUpgrades: [...this.state.selectedUpgrades, name],
      });
    }
  };

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  handlePaymentModeChange = (event) => {
    this.setState({
      paymentModeValue: event.target.value
    })
  }
  timeDiff = function (date1 : any, date2 : any) {
    var a = new Date(date1).getTime(),
        b = new Date(date2).getTime(),
        diff = {
          milliseconds : 0,
          seconds : 0,
          minutes : 0,
          hours : 0
        };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if(extraMin > 0){
      return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m"
    } else {
      return Math.floor(diff.hours) + " h"
    }
  }
   
  render() {
    const { classes } = this.props;
    const { searchData } = this.state;
    const noOfTravellers =
      (+searchData.adults || 0) +
      (+searchData.children || 0) +
      (+searchData.infants || 0);
    let redirectRouteName = "/";
    if (this.state.role === "Admin") {
      redirectRouteName = "/AdminLogin";
    } else if (this.state.role === "Agency") {
      redirectRouteName = "/AgencyMarketing";
    }
    return !this.state.loading ? (
      this.state.authToken === null ? (
        <Redirect to={redirectRouteName} />
      ) : (
        <div className={classes.root}>
          <Paper
            className={classes.mainPost}
            style={{
              backgroundImage: `url(${flightCover})`,
              backgroundSize: "cover",
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={flightCover}
                alt={"aaaaaaaaaaaaaaaa"}
              />
            }
            <div className={classes.overlay} />
            {this.state.role && <Header role={this.state.role} />}
            <Grid container justify="center">
              <Grid item md={8} xs={10}>
                <Grid
                  container
                  spacing={4}
                  justify="center"
                  className={classes.mainPostContent}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.helloAdmin} align="center">
                      Addons & Payment
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.progressText} align="center">
                      {this.props.history?.location?.state?.searchData.adults >
                      0
                        ? this.props.history?.location?.state?.searchData
                            .adults == 1
                          ? `${this.props.history?.location?.state?.searchData.adults} Adult`
                          : `${this.props.history?.location?.state?.searchData.adults} Adults`
                        : null}{" "}
                      {this.props.history?.location?.state?.searchData
                        .children > 0
                        ? this.props.history?.location?.state?.searchData
                            .children == 1
                          ? `${this.props.history?.location?.state?.searchData.children} Child`
                          : `${this.props.history?.location?.state?.searchData.children} Children`
                        : null}{" "}
                      {this.props.history?.location?.state?.searchData.infants >
                      0
                        ? this.props.history?.location?.state?.searchData
                            .infants == 1
                          ? `${this.props.history?.location?.state?.searchData.infants} Infant`
                          : `${this.props.history?.location?.state?.searchData.infants} Infants`
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <LinearProgress
                      classes={{ root: classes.linearProgressBar }}
                      variant="determinate"
                      value={75}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Flight Selection
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Review
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Traveller Details
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Make Payment
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.fromCity?.city}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          to
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.toCity?.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.fromCity?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          ○─○
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.toCity?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justify="center" spacing={6}>
            <Grid item md={7} xs={10}>
              <Grid container spacing={3}>
              <Grid item xs={12}>
                      <Grid container spacing={2} className={classes.flightDetailBox}>
                        <Grid item xs={12}>
                          <Typography
                            color="textPrimary"
                            className={classes.groteskBold32}
                          >
                            {this.state.selectedTravellingType === "twoway" ? "Depature Flight Information" : "Flight Information"}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Divider variant="inset" />
                        </Grid>
                        {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight,index)=>{
                      return  <>
                      <Grid key={flight?.FlightNumber} item xs={12}>
                      <Grid container spacing={6}>
                        <Grid md={4} xs={12} item>
                          <ListItem style={{ padding: 0 }}>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                src={
                                  flight?.Flight_Image 
                                }
                                style={{ marginRight: "10px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              classes={{
                                primary: classes.groteskBold24,
                                secondary: classes.openSans18,
                              }}
                              primary={
                                flight?.Flight_Name || "NA"
                              }
                              secondary={`${
                                flight?.Flight_carrier || "-"
                              } | ${flight?.Flight_Id || "-"}`}
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={12} md={8} item>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="left"
                              >
                                {(flight?.Depature_DateTime &&
                                  moment(
                                    flight?.Depature_DateTime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="left"
                              >
                                {" "}
                                Departure{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="left"
                              >
                                {(flight?.Depature_DateTime &&
                                  moment(
                                    (
                                      flight?.Depature_DateTime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.linewraper}>
                                <Typography
                                  color="primary"
                                  className={classes.groteskBold18}
                                  style={{
                                    lineHeight: 0,
                                  }}
                                >
                                  {this.timeDiff(flight?.Depature_DateTime,flight?.Arrival_DateTime) || "NA"}
                                </Typography>
                                <ul className={classes.container}>
                                  <li className={classes.linkconnector} />
                                  <li className={classes.linkconnector} />
                                </ul>
                                
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                {(flight?.Arrival_DateTime &&
                                  moment(
                                    flight?.Arrival_DateTime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                Arrival{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="right"
                              >
                                {(flight?.Arrival_DateTime &&
                                  moment(
                                    (
                                      flight?.Arrival_DateTime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Grid container justify="space-between">
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.Boarding_Terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.Depature_City ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.Arriving_Terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.Arrival_City ||
                                    "NA"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid> 
                            <Grid item xs={12}>
                          <Grid container justify="space-between">
                              <Grid xs={12} item>
                                <Grid
                                  container
                                  alignItems="center"
                                  justify="space-between"
                                >
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Baggage"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.type || "NA"
                                            
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Check-In"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Cabin"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={()=>{this.getSeatData(flight)}}>Seat Selection</Button>
                            </Grid>
                        </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                      {index < (this.state.oneWayFlightList.length -1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid><Grid item md={6} xs={6}>
                      <Paper style={{backgroundColor : '#1565d8',padding : '10px',width : '100%'}}>
                          <Typography align="center" style={{color : '#fff'}}>Change of plane {this.state.entityDetail?.stops[index].stopDuration} in  {this.state.entityDetail?.stops[index].stopName}</Typography>           
                      </Paper>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid></Grid>} 
                 </> 
                    })}
                       </Grid>
                    </Grid>
                    {this.state.selectedTravellingType === "twoway" ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.flightBox}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={classes.groteskBold32}
                        >
                          Return Flight Information
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Divider variant="inset" />
                      </Grid>
                      {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight,index)=>{
                      return  <>
                      <Grid key={flight?.FlightNumber} item xs={12}>
                      <Grid container spacing={6}>
                        <Grid md={4} xs={12} item>
                          <ListItem style={{ padding: 0 }}>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                src={
                                  flight?.Flight_Image 
                                }
                                style={{ marginRight: "10px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              classes={{
                                primary: classes.groteskBold24,
                                secondary: classes.openSans18,
                              }}
                              primary={
                                flight?.Flight_Name || "NA"
                              }
                              secondary={`${
                                flight?.Flight_carrier || "-"
                              } | ${flight?.Flight_Id || "-"}`}
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={12} md={8} item>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="left"
                              >
                                {(flight?.Depature_DateTime &&
                                  moment(
                                    flight?.Depature_DateTime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="left"
                              >
                                {" "}
                                Departure{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="left"
                              >
                                {(flight?.Depature_DateTime &&
                                  moment(
                                    (
                                      flight?.Depature_DateTime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.linewraper}>
                                <Typography
                                  color="primary"
                                  className={classes.groteskBold18}
                                  style={{
                                    lineHeight: 0,
                                  }}
                                >
                                  {this.timeDiff(flight?.Depature_DateTime,flight?.Arrival_DateTime) || "NA"}
                                </Typography>
                                <ul className={classes.container}>
                                  <li className={classes.linkconnector} />
                                  <li className={classes.linkconnector} />
                                </ul>
                                
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                {(flight?.Arrival_DateTime &&
                                  moment(
                                    flight?.Arrival_DateTime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                Arrival{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="right"
                              >
                                {(flight?.Arrival_DateTime &&
                                  moment(
                                    (
                                      flight?.Arrival_DateTime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid xs={12} container justify="space-between">
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.Boarding_Terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.Depature_City ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.Arriving_Terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.Arrival_City ||
                                    "NA"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid xs={12} container justify="space-between">
                              <Grid xs={12} item>
                                <Grid
                                  container
                                  alignItems="center"
                                  justify="space-between"
                                >
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Baggage"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.type || "NA"
                                            
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Check-In"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Cabin"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                </Grid>
                              </Grid>
                               
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={()=>{this.getSeatData(flight)}}>Seat Selection</Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                      {index < (this.state.twoWayFlightList.length -1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid><Grid item md={6} xs={6}>
                      <Paper style={{backgroundColor : '#1565d8',padding : '10px',width : '100%'}}>
                          <Typography align="center" style={{color : '#fff'}}>Change of plane {this.state.selectedFlight2?.stops[index].stopDuration} in  {this.state.selectedFlight2?.stops[index].stopName}</Typography>           
                      </Paper>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid></Grid>} 
                 </> 
                    })}
                     </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.flightDetailBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.groteskBold32}
                      >
                        Markup
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider variant="inset" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={6}>
                        <Grid md={6} xs={12} item>
                          <Typography color='textPrimary' className={classes.groteskBold24}>Total Amount Without Markup</Typography>
                          <Typography color='textPrimary' gutterBottom className={classes.groteskBold24}>(Charged from you)</Typography>
                          <Typography color='textPrimary' gutterBottom className={classes.openSans18}>INR 500</Typography>
                          <Typography color='textSecondary' className={classes.openSans16}>These amounts will not be reflected in invoice to client</Typography>
                        </Grid>
                        <Grid xs={12} md={5} item>
                          <InputFiledWeb
                            label='Your Markup Amount'
                            placeholder='Your Markup For Client'
                            value={this.state.yourMarkupAmountInput}
                            onChange={(e) => {
                              this.setState({
                                yourMarkupAmountInput: e.target.value
                              })
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Divider variant="inset" />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Typography color='textPrimary' className={classes.groteskBold24}>Final Amount Payable</Typography>
                          <Typography color='textPrimary' gutterBottom className={classes.groteskBold24}>By Client</Typography>
                          <Typography color='textSecondary' className={classes.openSans16}>Will be shown in invoice to client</Typography>
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <Typography color='textPrimary' gutterBottom className={classes.groteskBold24}>INR 600</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.flightDetailBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.groteskBold32}
                      >
                        Remarks
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider variant="inset" />
                    </Grid>
                    <Grid item xs={12}>
                      <InputFiledWeb
                        multiline
                        rows={4}
                        label='Remarks To Airline'
                        placeholder='Any Remarks To Airline'
                        value={this.state.remarkToAirlineInput}
                        onChange={(e) => {
                          this.setState({
                            remarkToAirlineInput: e.target.value
                          })
                        }}
                        fullWidth
                      />
                      <InputFiledWeb
                        multiline
                        rows={4}
                        label='Remarks To Itinerary'
                        placeholder='Any Remarks To Itinerary'
                        value={this.state.remarkToItineraryInput}
                        onChange={(e) => {
                          this.setState({
                            remarkToItineraryInput: e.target.value
                          })
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.flightDetailBox}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        className={classes.groteskBold32}
                      >
                        Flight Cancellation & Insurance
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            gutterBottom
                            className={classes.sectionHeading}
                          >
                            Get all the benefits for just ₹ 199 per traveller
                            (18% GST included)
                          </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                          <Grid container spacing={3}>
                            <Grid item md={5} xs={12}>
                              <div
                                className={
                                  this.state.selectedInsurance.indexOf(
                                    "AddFlightCancellation"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleInsurancce(
                                    "AddFlightCancellation"
                                  );
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{
                                      display: "inline-block",
                                      height: "24px",
                                      width: "28px",
                                    }}
                                    src={addFlightIcon}
                                  />
                                </div>
                                <Typography
                                  className={classes.boxHeading}
                                  align="center"
                                >
                                  Add Flight Cancellation
                                </Typography>
                                <Typography
                                  align="center"
                                  className={classes.boxSubHeading}
                                >
                                  Get Upto ₹ 3000
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item md={5} xs={12}>
                              <div
                                className={
                                  this.state.selectedInsurance.indexOf(
                                    "AddInsurance"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleInsurancce("AddInsurance");
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{
                                      display: "inline-block",
                                      height: "24px",
                                      width: "25px",
                                    }}
                                    src={addInsuranceIcon}
                                  />
                                </div>
                                <Typography
                                  align="center"
                                  className={classes.boxHeading}
                                >
                                  Add Insurance
                                </Typography>
                                <Typography
                                  align="center"
                                  className={classes.boxSubHeading}
                                >
                                  Get Upto ₹ 3000
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={10} xs={12}>
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginRight: 0 }}
                                control={<Checkbox name="gilad" />}
                                classes={{
                                  label: classes.sectionHeading,
                                }}
                                label="Some custom checkbox"
                              />
                              <FormControlLabel
                                style={{ marginBottom: 0, marginRight: 0 }}
                                control={<Checkbox name="gilad" />}
                                classes={{
                                  label: classes.sectionHeading,
                                }}
                                label="Some custom checkbox"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.flightDetailBox}>
                    <Grid item xs={12}>
                      <Box px={2}>
                        <Typography gutterBottom className={classes.flightInfo}>
                          Add Upgrades
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item md={10} xs={12}>
                          <Typography
                            gutterBottom
                            className={classes.sectionHeading}
                          >
                            <Box px={2}>
                              Add upgrades to your flight to get the best out of
                              it
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                          <Grid container spacing={3}>
                            <Grid item md={4} xs={12}>
                              <div
                                className={
                                  this.state.selectedUpgrades.indexOf(
                                    "AddSeatSelection"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleUpgrades("AddSeatSelection");
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{
                                      display: "inline-block",
                                      height: "29px",
                                      width: "31px",
                                    }}
                                    src={addSeatIcon}
                                  />
                                </div>
                                <Typography
                                  className={classes.boxHeading}
                                  align="center"
                                >
                                  Add Seat Selection
                                </Typography>
                                <Typography
                                  align="center"
                                  className={classes.boxSubHeading}
                                >
                                  ₹ 3000
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <div
                                className={
                                  this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage15"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleUpgrades("ExtraBaggage15");
                                }}
                              >
                                <Typography
                                  align="center"
                                  className={classes.boxHeading}
                                >
                                  Extra Baggage 15 Kgs
                                </Typography>
                                <Typography
                                  align="center"
                                  className={classes.boxSubHeading}
                                >
                                  ₹ 200
                                </Typography>
                              </div>
                            </Grid>

                            <Grid item md={4} xs={12}>
                              <div
                                className={
                                  this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage25"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleUpgrades("ExtraBaggage25");
                                }}
                              >
                                <Typography
                                  align="center"
                                  className={classes.boxHeading}
                                >
                                  Extra Baggage 25 Kgs
                                </Typography>
                                <Typography
                                  align="center"
                                  className={classes.boxSubHeading}
                                >
                                  ₹ 340
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Box px={2} py={1}>
                            <TextField
                              label="Add meal"
                              select
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value={10}>Hindu Veg</MenuItem>
                              <MenuItem value={20}>Jain Food</MenuItem>
                              <MenuItem value={30}>Non-Veg Food</MenuItem>
                              <MenuItem value={40}>Vegan</MenuItem>
                            </TextField>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={11}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={10}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.flightDetailBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.groteskBold32}
                      >
                        Fare Summary
                      </Typography>
                    </Grid>
                    <Grid md={12} xs={12} item>
                      <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify={"space-between"} spacing={2}>
                        <Grid item xs={12}>
                          <List>
                            {this.state.fareSummaryLst.length > 0 &&
                              this.state.fareSummaryLst.map((fare, index) => {
                                return (
                                  <ListItem dense={true} key={index}>
                                    <ListItemText
                                      primary={`${fare.name}(static) (${fare.count} X ${this.state.currency} ${fare.basePrice})`}
                                      secondary="Base Fare"
                                      classes={{
                                        primary: classes.flightCharge,
                                        secondary: classes.flightCharge,
                                      }}
                                    />
                                    <ListItemSecondaryAction>
                                      <Typography
                                        className={classes.flightValue}
                                      >
                                        {fare &&
                                          `${this.state.currency} ${fare.toalPrice}`}
                                      </Typography>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                            {/* <ListItem dense={true}>
                                  <ListItemText
                                    primary={`Travellers (${noOfTravellers} X ${this.state.entityDetail.ApproximateBasePrice})`}
                                    secondary="Base Fare"
                                    classes={{
                                      primary: classes.flightCharge,
                                      secondary: classes.flightCharge,
                                    }}
                                  />
                                  <ListItemSecondaryAction>
                                    <Typography className={classes.flightValue}>
                                      {this.state.fareSummaryDetail &&
                                        this.state.fareSummaryDetail.toal_base_price}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                </ListItem> */}
                            <ListItem dense={true}>
                              <ListItemText
                                primary="Fee & Surcharges"
                                secondary="Total fee & surcharges"
                                classes={{
                                  primary: classes.flightCharge,
                                  secondary: classes.flightCharge,
                                }}
                              />
                              <ListItemSecondaryAction>
                                <Typography className={classes.flightValue}>
                                  {this.state.fareSummaryDetail &&
                                    `${this.state.currency} ${this.state.fareSummaryDetail.total_tax}`}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                            {this.state.couponDiscount && (
                              <ListItem dense={true}>
                                <ListItemText
                                  primary="Coupon Discount"
                                  classes={{
                                    primary: classes.flightCharge,
                                    secondary: classes.flightCharge,
                                  }}
                                />
                                <ListItemSecondaryAction>
                                  <Typography className={classes.flightValue}>
                                    {`${this.state.currency} ${this.state.couponDiscount?.discount || "Not Available"}`}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItem>
                              )}
                            <Divider variant="middle" />
                            <ListItem dense={true}>
                              <ListItemText
                                classes={{
                                  primary: classes.flightValue,
                                }}
                                primary="Total Amount Without Markup"
                              />
                              <ListItemSecondaryAction>
                                <Typography className={classes.flightValue}>
                                {this.state.fareSummaryDetail &&
                                    `${this.state.currency} ${this.state.fareSummaryDetail.total_price}`}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={10}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.flightDetailBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.groteskBold32}
                      >
                        Payment Mode
                      </Typography>
                    </Grid>
                    <Grid md={12} xs={12} item>
                      <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify={"space-between"} spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                                  className={classes.groteskBold18}
                                  color="textPrimary"
                                  align="left"
                                >
                                  Credits Available
                                </Typography>
                                <Typography
                                  className={classes.openSans16}
                                  color="textSecondary"
                                  align="left"
                                >
                                  {this.state.currency} {this.state.profileData?.balance || 0}
                              </Typography>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="paymentMode"
                                  defaultValue={this.state.paymentModeValue}
                                  name="radio-buttons-group"
                                  // value={paymentMode}
                                  onChange={this.handlePaymentModeChange}
                                >
                                  <FormControlLabel value='cash_credit' name='paymentMode' control={<Radio />} label="Pay By Credits" />
                                  <FormControlLabel value='book_now_pay_later' name='paymentMode' control={<Radio />} label="Book Now, Pay Later" />
                                </RadioGroup>
                              </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      this.state.selectedFlight2
                        ? (this.getSeatData(), this.getSeatDataReturnFlight())
                        : this.getSeatData();
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Seat Map
                  </Button>
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      if (this.state.selectedTravellingType === "oneway")
                        // this.bookFlight(this.state.searchData, [
                        //   this.state.entityDetail,
                        // ]);
                        this.makePaymentbyCreadit();
                      else if (this.state.selectedTravellingType === "twoway")
                        // this.bookFlight(this.state.searchData, [
                        //   this.state.entityDetail,
                        //   this.state.selectedFlight2,
                        // ]);
                        this.makePaymentbyCreadit();
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End hero unit */}
          <Footer></Footer>
        </div>
      )
    ) : null;
  }
}

const AddAddonsAgencyWithRouter = withRouter(AddAddonsAgency);
const AddAddonsAgencyAlertBox = withAlertBox(AddAddonsAgencyWithRouter);
const AddAddonsAgencyLoader = withLoader(AddAddonsAgencyAlertBox);
const AddAddonsAgencyToast = withToast(AddAddonsAgencyLoader);
const AddAddonsAgencyWithDialog = withDialog(AddAddonsAgencyToast);
const AddAddonsAgencyWithFontStyles = withFontStyles(AddAddonsAgencyWithDialog);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    flightImage: {
      width: theme.spacing(7),
      height: theme.spacing(6.5),
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${flightCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: theme.spacing(55),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(85),
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightDetailBox: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    notchedOutline: {
      borderRadius: "9px",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    lineHeadingwraper: {
      textAlign: "center",
      width: "6%",
      display: "inline-block",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    flightName: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCode: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    flightInfo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCharge: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightAdditionChange: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightValue: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    addonBox: {
      borderRadius: "6px",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "142px",
    },
    addonBoxSelected: {
      borderRadius: "6px",
      boxShadow: "0 20px 24px 0 rgba(0, 0, 0, 0.08)",
      border: "solid 1px #1565d8",
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "142px",
    },
    boxHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    boxSubHeading: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    sectionHeading: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
  })
)(AddAddonsAgencyWithFontStyles);
