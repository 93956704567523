//@ts-check
import React, { PureComponent } from 'react';
import { Redirect, RouterProps, Route } from 'react-router-dom';
import StorageProvider from '../../framework/src/StorageProvider.web';

export type Props = RouterProps & {
    role: string;
};

export interface S {
    token: any;
    loading: boolean;
}

export default class AgencyAdminPrivateDashborad extends PureComponent<Props, S> {
    state = {
        token: '',
        loading: true

    };

    async componentDidMount() {
        let token = await StorageProvider.get('agancyToken');
        if(!token){
            token = await StorageProvider.get('adminToken');
        }
        if (token) {
            this.setState({
                token: token,
                loading: false
            });
        } else {
            this.setState({
                token: null,
                loading: false
            });
        }
    }
    render() {
        return !this.state.loading ? (
            this.state.token === null ? (<Redirect to="/AgencyMarketing" />) : (<Route {...this.props} />)
        ) : null
    }
};
