//@ts-nocheck
import React from "react";
import ResetPasswordController, {
  Props,
} from "./ResetPasswordController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  DialogContent,
} from "@material-ui/core";
import { forgotBackground, forgotLogo, signUPPoster } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { TextInput } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';

const Schema = Yup.object().shape({
  password: Yup.string()
    // .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("New Password is required"),
    // .required("This field is required"),
  confirmPassword: Yup.string()
    // .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm New Password is required"),
    // .required("This field is required"),
});

class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  visibilityPassword = ()=>{
    this.setState({visibilityPassword: !this.state.visibilityPassword})
  };
  visibilityPassword1 = ()=>{
    this.setState({visibilityPassword1: !this.state.visibilityPassword1})
  };

  render() {
    const { classes } = this.props;
    return (
      <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        <Formik
          initialValues={{
            password: "",
            confirmPassword : ""
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            this.handlePasswordChange(values);
          }}
        >
          {(formikProps) => {
            const { isValid, dirty } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <Grid container  justify="space-between" style={{ padding: "0px", overflow: "hidden" }}>
                  <Grid
                    item
                    md={6}
                    xs={false}
                    className={classes.dailogBackground}
                    // className={classes.dialogImage}
                  >
                    <Grid container justify="center" alignItems="center">
                        <Grid xs={10} className={classes.loginImageGrid}>
                          <img src={forgotLogo} className={classes.loginImage} />
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box py={8}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={10}>
                          <Typography
                            variant="h2"
                            className={classes.welcomeBack}
                            gutterBottom
                          >
                            Reset Your Password
                          </Typography>
                        </Grid>

                        <Grid item xs={10}>
                          {/* <Field
                            type='password'
                            component={InputField}
                            fullWidth
                            label="New Password"
                            name="password"
                            placeholder="Enter your new password"
                          /> */}
                          <Field
                             type={this.state.visibilityPassword?'text':'password'}
                              component={InputField}                                                                                  
                              fullWidth
                              label="New Password"
                              name="password"
                              placeholder="Enter your new password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=> this.visibilityPassword()}
                                    edge="end"
                                  >
                                   {this.state.visibilityPassword?<Visibility /> : <VisibilityOff />}
                                    
                                  </IconButton>
                                </InputAdornment>
                                )

                              }}
                           />
                        </Grid>
                        <Grid item xs={10}>
                          {/* <Field
                            type='password'
                            component={InputField}
                            fullWidth
                            label="Confirm New Password"
                            name="confirmPassword"
                            placeholder="Confirm your new password"
                          /> */}
                          <Field
                             type={this.state.visibilityPassword1?'text':'password'}
                              component={InputField}                                                                                  
                              fullWidth
                              label="Confirm New Password"
                              name="confirmPassword"
                              placeholder="Confirm your new password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=> this.visibilityPassword1()}
                                    edge="end"
                                  >
                                   {this.state.visibilityPassword1?<Visibility /> : <VisibilityOff />}
                                    
                                  </IconButton>
                                </InputAdornment>
                                )

                              }}
                           />
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={!(isValid && dirty)}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item xs={10}>
                          <div style={{ textAlign: "center" }}>
                            <Typography
                              align="center"
                              onClick={() => {
                                this.props.onCancel();
                              }}
                              className={classes.buttonText}
                              display="inline"
                            >
                              Cancel
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }
}

const ResetPasswordWithRouter = withRouter(ResetPassword);
const ResetPasswordWithToast = withToast(ResetPasswordWithRouter);
const ResetPasswordWithLoader = withLoader(ResetPasswordWithToast);
const ResetPasswordWithAlertBox = withAlertBox(ResetPasswordWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundImage: `url(${signUPPoster})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor:'pointer'
    },
    loginImage: {
      width: '15rem',
      height: '100%',
    },
    loginImageGrid: {
      margin: 'auto',
      marginTop:'35%'
    },
    dailogBackground:{
      backgroundImage: `url(${forgotBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width:'100%',
      padding:'44px',
      overflow:'hidden'
    },
  })
)(ResetPasswordWithAlertBox);
