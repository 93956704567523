//@ts-nocheck
import React from "react";
import EditAgancyProfileController, {
  Props,
} from "./EditAgancyProfileController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Hidden,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";


const Schema = Yup.object().shape({
  fullName: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  phoneNumber: Yup.string().required("This field is required."),
  agencyName: Yup.string().required("This field is required."),
  agencyAddress: Yup.string().required("This field is required."),
  agencyPhoneNumber: Yup.string().required("This field is required."),
  registrationNumber: Yup.string().required("This field is required."),
  ntbNumber: Yup.string().required("This field is required."),
  panNumber: Yup.string().required("This field is required."),
});


class EditAgancyProfile extends EditAgancyProfileController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("agancyToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getProfileDetail();
        }
      );
    }
  }
  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          fullName: this.state.profileData?.full_name || "",
          email: this.state.profileData?.email  || "",
          phoneNumber: this.state.profileData?.full_phone_number  || "",
          agencyName: this.state.profileData?.agency_name  || "",
          agencyAddress: this.state.profileData?.agency_address  || "",
          agencyPhoneNumber: this.state.profileData?.agency_phone_numbeer  || "",
          registrationNumber: this.state.profileData?.business_registration_number  || "",
          ntbNumber: this.state.profileData?.permission_ntb_number  || "",
          panNumber: this.state.profileData?.pan_number  || "",
        }}

        enableReinitialize
        validationSchema={Schema}
        onSubmit={(values) => {
          this.handleProfileSave(values)
        }}
      >
        {(formikProps) => {
          const { values, handleBlur, handleChange, setFieldValue } =
            formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={11} className={classes.pendIngRequestBox}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1 }}>
                      <Typography gutterBottom className={classes.pendIngTite}>
                            Edit Profile
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={11}>
                  <Grid container>
                    <Grid xs={11} item>
                      <Typography
                        gutterBottom
                        className={classes.formHeadingName}
                      >
                        Essential Details
                      </Typography>
                    </Grid>
                    <Grid xs={11} item>
                      <Grid container spacing={1} justify="space-between">
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="fullName"
                            placeholder="Enter Full Name"
                            // onChange={handleChange}
                            value={values.fullName}
                            fullWidth
                            label="Your Full Name"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="agencyName"
                            placeholder="Enter Name"
                            // onChange={handleChange}
                            value={values.agencyName}
                            fullWidth
                            label="Agency Name"
                          />
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Grid container spacing={1} justify="space-between">
                            <Grid item xs={12}>
                              <Field
                                component={InputFieled}
                                name="email"
                                placeholder="Enter Email ID"
                                // onChange={handleChange}
                                value={values.email}
                                fullWidth
                                label="Your Email"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                component={InputFieled}
                                name="phoneNumber"
                                placeholder="Enter Phone Number"
                                // onChange={handleChange}
                                value={values.phoneNumber}
                                fullWidth
                                label="Your Phone Number"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="agencyAddress"
                            placeholder="Enter Full Address"
                            multiline
                            rows={5}
                            // onChange={handleChange}
                            value={values.agencyAddress}
                            fullWidth
                            label="Agency Address"
                          />
                        </Grid>
                        <Hidden xsDown>
                          <Grid item md={5} xs={12}></Grid>
                        </Hidden>
                        <Grid item md={5} xs={12}>
                          <Field
                            component={InputFieled}
                            name="agencyPhoneNumber"
                            placeholder="Enter Phone Number"
                            value={values.agencyPhoneNumber}
                            fullWidth
                            label="Agency Phone Number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={11} item>
                <Grid container>
                  <Grid xs={11} item>
                    <Typography gutterBottom className={classes.formHeadingName}>
                        Business Details
                      </Typography>
                  </Grid>
                  <Grid xs={11} item>
                  <Grid container spacing={1} justify="space-between">
                    <Grid item md={5} xs={12}>
                      <Field
                        component={InputFieled}
                        name="registrationNumber"
                        placeholder="Enter Number"
                        // onChange={handleChange}
                        value={values.registrationNumber}
                        fullWidth
                        label="Business Registration Number"
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Field
                        component={InputFieled}
                        name="ntbNumber"
                        placeholder="0"
                        // onChange={handleChange}
                        value={values.ntbNumber}
                        fullWidth
                        label="Permission (NTB) Number"
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Field
                        component={InputFieled}
                        name="panNumber"
                        placeholder="Enter Number"
                        // onChange={handleChange}
                        value={values.panNumber}
                        fullWidth
                        label="VAT/PAN Number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
                <Grid xs={11} item>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item xs={3}>
                      <Button
                       variant="outlined"
                       color="primary"
                       fullWidth
                       onClick={()=>this.props.history.push("/AgancyDashborad/profileDetails")}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type='submit'
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const EditAgancyProfileWithRouter = withRouter(EditAgancyProfile);
const EditAgancyProfileAlertBox = withAlertBox(EditAgancyProfileWithRouter);
const EditAgancyProfileLoader = withLoader(EditAgancyProfileAlertBox);
const EditAgancyProfileToast = withToast(EditAgancyProfileLoader);
const EditAgancyProfileWithDialog = withDialog(EditAgancyProfileToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    formHeadingName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
  })
)(EditAgancyProfileWithDialog);