import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import moment from "moment";
export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : string;
  agencyDetail : any
  profiieData : any
  agencyData : object
  allAcceptedInvites : Array<any>,
  couponDataToEdit: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddNewCouponController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAndUpdateCouponApiCallId: String = "";
  saveProfileApiCallId : String = "";
  allAcceptedRequestCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      agencyDetail : {},
      authToken : '',
      profiieData : {},
      agencyData: {},
      allAcceptedInvites: [],
      couponDataToEdit: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ); 
      if (apiRequestCallId === this.createAndUpdateCouponApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data.id) {
            this.props.showToast({type : 'success',message : 'Coupon created successfully'})
            this.props.history.push("/AdminDashboard/coupons");
        }
      } else if (apiRequestCallId === this.allAcceptedRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data.length > 0) {
          this.setState({
            allAcceptedInvites : responseJson?.data,
          })
        } else {
          this.setState({
            allAcceptedInvites : [],
          })
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }

  getAllAcceptedRequest() {
    this.props.showLoader();
    let url = "";
      url = `account_block/get_agencies_list`
      // per=${this.state.activerecordsPerPage}&page=${this.state.activeCount+1}
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allAcceptedRequestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createAndUpdateCoupon = (values: any, type: string) => {
    let method = ''
    let url = ''
    type === 'PUT' ? method = configJSON.methodPUT : method = configJSON.methodPOST
    type === 'PUT' ? url = `bx_block_coupon_cg/coupon_code_generators/${this.state.couponDataToEdit?.id}` : url = `bx_block_coupon_cg/coupon_code_generators`

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      'token': this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const startDate = moment(new Date(values.validFrom).setHours(0,0,0)).format("YYYY-MM-DD HH:mm:ss")
    const endDate = moment(new Date(values.validTo).setHours(23,59,59)).format("YYYY-MM-DD HH:mm:ss")
    this.createAndUpdateCouponApiCallId = requestMessage.messageId;
    const httpBody = {
        "data":{
            "title":values.title,
            "description":values.description,
            "code":values.code,
            "discount_type":values.discountType,
            "discount":values.discount,
            "valid_from":startDate,
            "valid_to":endDate,
            "min_cart_value":values.minCartValue,
            "max_cart_value":values.maxCartValue,
            "applicability_agency_account" : [values.applicabilityAgencyAccount],
            "applicability_end_user" : ["null"]
        }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  // hello = (values: any) => {
  //   this.props.showLoader();
  //   const headers = {
  //     "Content-Type": configJSON.ApiContentType,
  //     'token':this.state.authToken,
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.saveProfileApiCallId = requestMessage.messageId;
  //   const httpBody ={
  //       "data":{
  //           "title":"title",
  //           "description":"for test purposes",
  //           "code":"12erddf8s",
  //           "discount_type":"flat",
  //           "discount":"100",
  //           "valid_from":"", 
  //           "valid_to":"", 
  //           "min_cart_value":"1000",
  //           "max_cart_value":"1200",
  //           "applicability_agency_account": ["1","2","3","4"], //pass the agency id or the user in the web
  //           "applicability_end_user": ["null"] //if coupons is for all users then pass a ["null"] insted of ids 
  //       }
  //   }

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     `${configJSON.agencyEditProfile}`
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(headers)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.methodPOST
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // }
}
