//@ts-nocheck
import React from "react";
import AgencyDetailController, {
  Props,
} from "./AgencyDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Divider,
  Tabs,
  Tab,
  TextField,
  Hidden,
} from "@material-ui/core";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import InputFiled from "../../../components/src/InputFiled.web";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import Rating from "@material-ui/lab/Rating";
import {
  hotelIcon,
  allIcon,
  flightIcon,
  hotelImage,
  agancyCover,
  filterIcon
} from "./assets";
import FlightInfo from "./FlightInfo.web";
import HotelInfo from "./HotelInfo.web";
import { withRouter, Route, Switch, Redirect } from "react-router";
import Footer from "./Footer.web";
import Header from "./Header.web";
import { NavLink } from "react-router-dom";
import withFontStyles from "./withFontStyles.web";

const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
  root: {
    fontSize: "1em",
  },
})(Rating);

class AgencyDetail extends AgencyDetailController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    let agencyDetail = await StorageProvider.get("agencyDetail");
    agencyDetail = agencyDetail && JSON.parse(agencyDetail);
    if (authToken) {
      this.setState(
        {
          token: authToken,
          agencyDetail: (agencyDetail),
          accountId: agencyDetail?.account_id,
        },
        () => {
          this.getAgancy(agencyDetail.id);
          if(this.state?.agencyDetail?.status !== "pending"){
            this.getBookingsByApiAccountId(agencyDetail?.account_id); 
          }
          
        }
      );
    }
  }
 
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  render() {
    const { classes } = this.props;
    let hotelBooking = [];
    let flightBooking = [];
    if (Array.isArray(this.state.bookingList)) {
        hotelBooking = this.state.bookingList.filter((booking) => {
        return booking?.data?.type !== "flight_booking";
      });
      flightBooking = this.state.bookingList.filter((booking) => {
        return booking?.data?.type === "flight_booking";
      });
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid
                  container
                  className={classes.card}
                  spacing={1}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item md={8} xs={12}>
                    <Grid container alignItem="center" >
                       <Grid item xs={1}>
                       <ArrowBackOutlinedIcon style={{cursor : 'pointer'}} onClick={()=>{this.goToSelectedRoute("/AdminDashboard/Allagenciesandsubagencies")}}></ArrowBackOutlinedIcon>
                       </Grid>
                       <Grid item xs={7}>
                       <Typography
                      color="textPrimary"
                      className={classes.groteskBold32}
                    >
                      {this.state?.agencyDetail?.agency_name || "Not Available"}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      className={classes.openSans16}
                    >
                      {this.state?.agencyDetail?.email || "Not Available"}
                    </Typography>
                 
                       </Grid>
                    </Grid>
                     </Grid>
                  {this.state?.agencyDetail?.status === "pending" && (
                    <>
                      <Grid item md={2} xs={12}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => {
                            this.updateAgancyStatus(
                              "rejected",
                              this.state?.agencyDetail?.id
                            );
                          }}
                        >
                          Reject
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.updateAgancyStatus(
                              "accepted",
                              this.state?.agencyDetail?.id
                            );
                          }}
                          color="primary"
                        >
                          Approve
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Hidden xsDown>
                    <Grid
                      item
                      md={11}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Divider variant="middle" />
                    </Grid>
                  </Hidden>
                  <Grid item md={12} xs={6}>
                    <ListItem>
                      <ListItemText
                        primary="Status"
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        secondary={
                          this.state?.agencyDetail?.status || "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <ListItem>
                      <ListItemText
                        primary="Contact Details"
                        secondary={
                          this.state?.agencyDetail?.full_phone_number ||
                          "Not Available"
                        }
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <ListItem>
                      <ListItemText
                        primary="Address"
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        secondary={
                          this.state?.agencyDetail?.agency_address ||
                          "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <ListItem>
                      <ListItemText
                        primary="Company Registration"
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        secondary={
                          this.state?.agencyDetail
                            ?.business_registration_number || "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <ListItem>
                      <ListItemText
                        primary="VAT/PAN"
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        secondary={
                          this.state?.agencyDetail?.pan_number ||
                          "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <ListItem>
                      <ListItemText
                        primary="Permission (NTB) License No."
                        secondary={
                          this.state?.agencyDetail?.permission_ntb_number ||
                          "Not Available"
                        }
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                      />
                    </ListItem>
                  </Grid>
                 {this.state?.agencyDetail?.status !== "pending" && <Grid item md={4} xs={12}>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        primary="Assign Credits"
                        secondary={
                          this.state?.agencyDetail?.balance ||
                          "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                 } 
                  <Hidden xsDown>
                    <Grid
                      item
                      md={11}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Divider variant="middle" />
                    </Grid>
                  </Hidden>
                  <Grid item md={4} xs={6}>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        primary="Admin Name"
                        secondary={
                          this.state?.agencyDetail?.full_name ||
                          "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.listItemPrimary,
                          secondary: classes.listItemSecondary,
                        }}
                        primary="Admin Email"
                        secondary={
                          this.state?.agencyDetail?.email ||
                          "Not Available"
                        }
                      />
                    </ListItem>
                  </Grid>
                  
                
                </Grid>
              </Grid>
              {this.state?.agencyDetail?.status !== "pending" && ( <Grid item md={12} xs={12}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#f8f8fa",
                  }}
                >
                  <Grid item xs={11}>
                    <Typography className={classes.agencyName}>
                      Assign Credits
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.state?.agencyDetail?.status !== "pending" && (<>
              <Grid item md={9} xs={9}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter credits to be assigned"
                  type="number"
                  value={this.state.creditTextField}
                  onChange={(evt) => {
                    this.setState({
                      creditTextField: evt.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item md={3} xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    this.assignCredits(this.state.accountId);
                  }}
                >
                  Assign
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#f8f8fa",
                  }}
                >
                  <Grid item md={12}>
                    <Tabs
                      value={this.state.selectedType}
                      onChange={(evt, value) => {
                        this.setState(
                          {
                            selectedType: value,
                          },
                          () => {
                            this.getBookingsByApiAccountId(
                              this.state.accountId
                            );
                          }
                        );
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab
                        label="All"
                        classes={{ root: classes.tabLabel }}
                        value={"All"}
                        icon={
                          <img
                            style={{ width: "23px", height: "32px" }}
                            src={allIcon}
                          />
                        }
                      />
                      <Tab
                        label="Hotels"
                        classes={{ root: classes.tabLabel }}
                        value={"Hotels"}
                        icon={
                          <img
                            style={{ width: "34px", height: "23px" }}
                            src={hotelIcon}
                          />
                        }
                      />
                      <Tab
                        label="Flights"
                        classes={{ root: classes.tabLabel }}
                        value={"Flights"}
                        icon={
                          <img
                            style={{ width: "30px", height: "34px" }}
                            src={flightIcon}
                          />
                        }
                      />
                    </Tabs>
                  </Grid>
                </Grid>
              </Grid>
              
                  <Grid item xs={7} md={10}>
                    <InputFiled
                      name="search"
                      placeholder="Try searching by PNR, booking ID, Passengers, hotel, airline or destination"
                      variant="outlined"
                      onChange={(evt) => {
                        this.setState({
                          search: evt.target.value,
                        });
                      }}
                      value={this.state.search}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={3} md={2} item>
                  <img onClick={() => {
                      // this.openBookingFilter();
                    }}
                    src={filterIcon}
                    style={{height: '44px', width: '44px'}}/>
                  </Grid>
                

              <Grid item xs={12}>
                <Paper elevation={0}>
                  <Tabs
                    value={this.state.selectedTime}
                    onChange={(evt, value) => {
                      this.setState(
                        {
                          selectedTime: value,
                        },
                        () => {
                          this.getBookingsByApiAccountId(this.state.accountId);
                        }
                      );
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab
                      value={"upcoming"}
                      classes={{ root: classes.tabLabel }}
                      label="Upcoming"
                    />
                    <Tab
                      value={"completed"}
                      classes={{ root: classes.tabLabel }}
                      label="Completed"
                    />
                    <Tab
                      value={"cancelled"}
                      classes={{ root: classes.tabLabel }}
                      label="Cancelled"
                    />
                  </Tabs>
                </Paper>
              </Grid>
              <>
                {this.state.selectedType === "All" ? (
                  <>
                    {this.state.bookingList.length > 0 ? (
                      <>
                        {hotelBooking.length > 0 &&
                          hotelBooking.map((hotel, index) => {
                            return (
                              <Grid xs={10} item key={index}>
                                <HotelInfo
                                  goToSelectedRoute={this.goToSelectedRoute}
                                ></HotelInfo>
                              </Grid>
                            );
                          })}
                        {flightBooking.length > 0 &&
                          flightBooking.map((flight, index) => {
                            return (
                              <Grid xs={10} item key={index}>
                                <FlightInfo
                                  flightDetail={flight?.data?.attributes}
                                  goToSelectedRoute={() => {
                                    this.goToSelectedRoute(
                                      "/myBookings/Flight",
                                      flight?.data?.attributes
                                    );
                                  }}
                                ></FlightInfo>
                              </Grid>
                            );
                          })}
                      </>
                    ) : (
                      <Grid xs={10} item className={classes.abc}>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          align="center"
                        >
                          {"No Booking found"}
                        </Typography>
                      </Grid>
                    )}
                  </>
                ) : this.state.selectedType === "Hotels" ? (
                  <>
                    {this.state.bookingList.length > 0 ? (
                      this.state.bookingList.map((hotel) => (
                        <Grid xs={10} item key={hotel}>
                          <HotelInfo
                            goToSelectedRoute={this.goToSelectedRoute}
                          ></HotelInfo>
                        </Grid>
                      ))
                    ) : (
                      <Grid xs={10} item className={classes.abc}>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          align="center"
                        >
                          {"No Booking found"}
                        </Typography>
                      </Grid>
                    )}
                  </>
                ) : this.state.selectedType === "Flights" ? (
                  <>
                    {this.state.bookingList.length > 0 ? (
                      this.state.bookingList.map((flight) => (
                        <Grid xs={10} item key={flight?.data.id}>
                          <FlightInfo
                            flightDetail={flight?.data?.attributes}
                            goToSelectedRoute={() => {
                              this.goToSelectedRoute(
                                "/myBookings/Flight",
                                flight?.data?.attributes
                              );
                            }}
                          ></FlightInfo>
                        </Grid>
                      ))
                    ) : (
                      <Grid xs={10} item className={classes.abc}>
                        <Typography
                          color="textPrimary"
                          variant="h5"
                          align="center"
                        >
                          {"No Booking found"}
                        </Typography>
                      </Grid>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
           </>)}
        </Grid>
               
              
    );
  }
}
const AgencyDetailControllerWithRouter = withRouter(AgencyDetail);
const AgencyDetailControllerAlertBox = withAlertBox(
  AgencyDetailControllerWithRouter
);
const AgencyDetailControllerLoader = withLoader(
  AgencyDetailControllerAlertBox
);
const AgencyDetailControllerToast = withToast(
  AgencyDetailControllerLoader
);

const AgencyDetailWithFontStyles = withFontStyles(
  AgencyDetailControllerToast
);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [theme.breakpoints.up("md")]: {
        height: "348px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    sideLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      padding: theme.spacing(1, 1.5),
    },
    sideLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: "left",
      display: "block",
      textDecoration: "none",
      padding: theme.spacing(1.5),
    },
    activeSideLink: {
      backgroundColor: "#fff",
      color: "#1565d8",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "40px",
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    tabLabel: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: "#183b56",
      textTransform: "capitalize",
    },
    card: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#fff",
      padding: "38px 48px 66px 45px",
    },
    listItemPrimary: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemSecondary: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    rightSideBox: {
      [theme.breakpoints.up("md")]: {
        position: "relative",
        bottom: "120px",
      },
    },
  })
)(AgencyDetailWithFontStyles);
