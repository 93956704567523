export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const hotelImage = require("../assets/hotelImage.jpg")
export const success = require("../assets/success.png")
export const signUPPoster = require("../assets/signUpPoster.png")
export const CustomerlandingPage = require("../assets/CustomerlandingPage.png")
export const hotelIcon = require("../assets/hotelIcon.png")
export const hotelIcon1 = require("../assets/hotelWT.png")
export const flightIcon = require("../assets/flightIcon.png")
export const flightIcon1 = require("../assets/flightWT.png")
export const companylogo1 = require("../assets/complogo1.png")
export const companylogo = require("../assets/complogo1.png")
export const allIcon = require("../assets/allSearch.png")
export const flighPlaceHolder = require("../assets/flighPlaceHolder.png")
export const flightCover = require("../assets/flightCover.png")
export const imageCheckBox = require("../assets/image-checkbox.png")
export const hotelCover = require("../assets/hotelCover.png")
export const paymentFailedCross = require("../assets/paymentFailedCross.png")
export const wheelChairIcon = require("../assets/wheelChairIcon.png")
export const wifiIcon = require("../assets/wifiIcon.png")
export const pIcon = require("../assets/49692c6f60a3345768b49d6144f84909d97e598e.png")
export const AgencyMarketingBackground = require("../assets/hero-banner.png")
export const AgentBanner = require("../assets/side-img.png")
export const bellIcon = require("../assets/bell.png")
export const heroBanner = require("../assets/hero-banner.png")
export const sideImage = require("../assets/side-img.png")
export const likeIcon = require("../assets/likeIcon.png")
export const heartIcon = require("../assets/heart-icon.png")
export const agancyCover = require("../assets/agancyCover.png")
export const cheapThanOtherIcon = require("../assets/icon-1.png")
export const securePaymentIcon = require("../assets/icon-2.png")
export const bestOfferIcon = require("../assets/icon-3.png")
export const easyBookIcon = require("../assets/icon-4.png")
export const greatAmenitiesIcon = require("../assets/c89030e52a01af767899459384c85d5061867a21.png")
export const topRatedIcon = require("../assets/08b70c7ccc01ca3a83f31c5d2b5ff1267b96292b.png")
export const fastRefundIcon = require("../assets/32a485381dca594bd67d79f074d085825a09785d.png")
export const supportIcon = require("../assets/0e81fe96f7974fb78e2c9d5c5f86e3be44a48853.png")
export const alertIcon = require("../assets/image-alert@3x.png")
export const covidIcon = require("../assets/image-covid@3x.png")
export const suitcaseIcon = require("../assets/image-suitcase@3x.png")
export const filterIcon = require("../assets/filterIcon.png")
export const addFlightIcon = require("../assets/addFlightIcon.png")
export const addSeatIcon = require("../assets/addSeatIcon.png")
export const addInsuranceIcon = require("../assets/addInsuranceIcon.png")
export const upload = require("../assets/upload-3.png")
export const Group = require("../assets/Group4.png")
export const forgotLogo = require("../assets/ForgotLogo.png")
export const forgotBackground = require("../assets/ForgotBackground.png")
export const landingPage = require("../assets/Landingpage.png")
export const successVerify = require("../assets/successVerify.png")
export const landingcover = require("../assets/landingCover.png")
export const loading = require("../assets/loading.png")
export const notification = require("../assets/notification.png")
export const userSuccess = require("../assets/check.png")