import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

export const configJSON = require("./config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  token: any,
  permissionList : Array<any>,
  selectedPermission : Array<any>
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  permissionsCallId : String = "";
  loginApiUpdateEmailCallId : string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      token : '',
      permissionList : [],
      selectedPermission : []
    }
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiEmailCallId) {
        this.props.hideLoader();
        if(responseJson?.status === 'created' && responseJson?.account?.data?.id){
           this.props.onSubmit(responseJson?.account?.data?.id)
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if(this.permissionsCallId === apiRequestCallId){
        this.props.hideLoader();
        if(responseJson?.permissions){
          this.setState({
            permissionList : responseJson?.permissions
          },()=>{
            this.setState({
              selectedPermission : this.props.dataToPass?.teamMemberDetail?.permissions.map((a : string)=>{ const aaa = this.state.permissionList.find((b : any)=>{return a === b.name});  if(aaa){return aaa} }) || []
            })
          })
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } else if(apiRequestCallId === this.loginApiUpdateEmailCallId){
        this.props.hideLoader();
        if(responseJson?.data?.data?.id){
           this.props.onSubmit(responseJson?.data?.data?.id)
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      } 
    } 
  }
  getListOfPermission = () => {
    const url = "bx_block_roles_permissions/permissions"
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.permissionsCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),  `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleUpdateLogin = (values : any,id : any) => {
    let httpBody = {}
    if(this.props.dataToPass.role === 'Admin'){
      httpBody = {
        "permisstions_ids": this.state.selectedPermission.map((a)=>{return a.id}),
        "data": {
          full_name:values.fullName,
          email: values.email,
          password: "values.password",
          confirm_password: "values.password",
          full_phone_number:values.contanctNo,
        }
      }
    } else if(this.props.dataToPass.role === 'Agency'){
      httpBody = {
        account_type:"team_member",
        data: {
          full_name:values.fullName,
          "member_id": id,
          email: values.email,
          password: "values.password",
          confirm_password: "values.password",
          full_phone_number:values.contanctNo,
          permissions : this.state.selectedPermission.map((a)=>{return a.id}),
                            
        },
        
        token : this.state.token
      }
    }
    
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiUpdateEmailCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),  
      
     this.props.dataToPass.role === 'Admin' ? `account_block/admin_teams?id=${id}` :  "bx_block_dashboard/update_team_member"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.props.dataToPass.role === 'Admin' ? configJSON.methodPUT : configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleLogin = (values: any) => {
    let httpBody = {}
    if(this.props.dataToPass.role === 'Admin'){
      httpBody = {
        "permisstions_ids": this.state.selectedPermission.map((a)=>{return a.id}),
        "data": {
          full_name:values.fullName,
          email: values.email,
          password: "values.password",
          confirm_password: "values.password",
          full_phone_number:values.contanctNo,
        }
      }
    } else if(this.props.dataToPass.role === 'Agency'){
      httpBody = {
        account_type:"team_member",
        data: {
          full_name:values.fullName,
          email: values.email,
          password: "values.password",
          confirm_password: "values.password",
            full_phone_number:values.contanctNo,
                            
        },
        permissions : this.state.selectedPermission.map((a)=>{return a.id}),
        token : this.state.token
      }
    }
    
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiEmailCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),  
      
     this.props.dataToPass.role === 'Admin' ? "account_block/admin_teams" :  `${configJSON.agencySignUp}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
