//@ts-nocheck
import React from "react";
import CancelledBookingsController, {
  Props,
} from "./CancelledBookingsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Divider,
  Tabs,
  Tab,
  Paper,
  Link,
  Hidden,
  ListItemAvatar,
  Box,
  TablePagination,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import Rating from "@material-ui/lab/Rating";
import { hotelIcon, allIcon, flightIcon, hotelImage } from "./assets";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import FlightInfo from "./FlightInfo.web";
import HotelInfo from "./HotelInfo.web";
const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
})(Rating);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

class CancelledBookings extends CancelledBookingsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          token: authToken,
        },
        () => {
          // this.getBoatListing();
          this.getBookings();
        }
      );
    }
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        pageCount: value,
      },
      () => {
        this.getBookings();
      }
    );
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPage: parseInt(event.target.value, 10),
        pageCount: 0,
      },
      () => {
        this.getBookings();
      }
    );
  };

  goToSelectedRoute = (routeName, flightInfo) => {
    const { history } = this.props;
    const data = {
      searchData: {},
      entityDetail: { ...flightInfo },
      fromCity: {},
      toCity: {},
      entityType: 'Flight',
      booking_id: flightInfo?.id,
      role: "Customer",
      travaller_id: flightInfo?.id
    }
    history.push({ pathname: routeName, state: data });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ borderRadius: "5px", backgroundColor: "#EBEBEB", marginBottom: "15px" }}
          >
            <Tabs
              value={this.state.selectedType}
              onChange={(evt, value) => {
                this.setState(
                  {
                    selectedType: value,
                  },
                  () => {
                    this.getBookings();
                  }
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Hotels" classes={{ root: classes.tabText }} value={"Hotels"} icon={<img style={{ width: '20px', height: "22px" }} src={hotelIcon} />} />
              <Tab label="Flights" classes={{ root: classes.tabText }} value={"Flights"} icon={<img style={{ width: '20px', height: "22px" }} src={flightIcon} />} />
            </Tabs>
          </Paper>
        </Grid>
        <>
          {

            this.state.selectedType === "Flights" ? (
              <>
                {
                  this.state.bookingList.length > 0 ? this.state.bookingList.map((flight, index) => (
                    <Grid xs={12} item key={index}>
                      <FlightInfo openPopover={this.props.openPopover} flightDetail={flight} goToSelectedRoute={() => { this.goToSelectedRoute("/myBookings/Flight", flight) }}></FlightInfo>
                    </Grid>
                  )) : <Grid xs={12} item className={classes.abc}>
                    <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
                  </Grid>
                }
                {this.state?.bookingList?.length > 0 && (
                  <Grid item xs={12}>
                    <TablePagination
                      component="div"
                      count={this.state?.bookingFlightsTotalCount}
                      page={this.state?.pageCount}
                      onChangePage={this.handlePageChange}
                      rowsPerPage={this.state?.recordsPerPage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </Grid>
                )}
              </>
            ) : this.state.selectedType === "Hotels" ? <>
              {
                this.state.bookingList.length > 0 ? this.state.bookingList.map(flight => (
                  <Grid xs={12} item key={flight}>
                    <HotelInfo goToSelectedRoute={this.goToSelectedRoute}></HotelInfo>
                  </Grid>
                )) : <Grid xs={12} item className={classes.abc}>
                  <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
                </Grid>
              }
            </> : <></>}
        </>

      </Grid>

    );
  }
}

const CancelledBookingsWithRouter = withRouter(CancelledBookings);
const CancelledBookingsAlertBox = withAlertBox(CancelledBookingsWithRouter);
const CancelledBookingsLoader = withLoader(CancelledBookingsAlertBox);
const CancelledBookingsToast = withToast(CancelledBookingsLoader);
const CancelledBookingsWithDialog = withDialog(CancelledBookingsToast);

export default withStyles((theme) =>
  createStyles({
    card: {
      padding: "30px 50px 33px 46px",
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    headingCard: {
      backgroundColor: "#f8f8fa",
      borderRadius: "8px",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      maxWidth: "256px",
      maxHeight: "149px",
      [theme.breakpoints.down("xs")]: {
        height: 128,
      },
    },
    hotelNameHeadingContainer: {
      flexWrap: "wrap",
    },
    hotelNameTitle: {
      flexGrow: 1,
    },
    footerLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    hotelName: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    hotelAddress: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "HKGrotes",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    logo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
  })
)(CancelledBookingsWithDialog);
