import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  token : string;
  agencyDetail : any
  profiieData : any
  selectedType: String;
  selectedTime: string;
  bookingList: Array<any>;
  creditTextField: string;
  accountId: any;
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AcceptAgencyRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateAgencyStatusApiCallId: String = "";
  getAgencyStatusApiCallId : String = "";
  allBookingEndPointCallId : String = "";
  assignCreditsApiCallId: String = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      agencyDetail : {},
      token : '',
      profiieData : {},
      selectedTime: "upcoming",
      selectedType: "All",
      bookingList: [],
      creditTextField: '',
      accountId: ''
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ); 
      if (apiRequestCallId === this.allBookingEndPointCallId) {
        this.props.hideLoader();
          if (
            responseJson &&
            responseJson?.data 
          ) {
            this.setState({ bookingList: responseJson?.data || [] }, () => {console.log('booking list ===========', this.state.bookingList, this.state.selectedType, this.state.selectedTime)});
          } else {
            this.setState({
              bookingList : []
            })
            this.parseApiErrorResponse(responseJson);
          }
      } else if (apiRequestCallId === this.updateAgencyStatusApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.account && responseJson?.account?.data) {
            this.props.showToast({type : 'success',message : 'Agency Status updated successfully'})
            this.props.history.push("/AdminDashboard/Allagenciesandsubagencies");
        }
      } else if (apiRequestCallId === this.getAgencyStatusApiCallId) {
        this.props.hideLoader();
        // if (responseJson && responseJson?.account && responseJson?.account?.data) {
        //     this.props.showToast({type : 'success',message : 'Agency Status updated successfully'})
        //     this.props.history.push("/AdminDashboard/Allagenciesandsubagencies");
        // }
      } else if (apiRequestCallId === this.assignCreditsApiCallId) {
        this.props.hideLoader();
        if(responseJson && responseJson?.data && responseJson?.data?.data && responseJson?.data?.data?.attributes) {
          this.setState({
            creditTextField: ''
          })
          this.props.showToast({type: 'success', message: 'Credits assined successfully'})
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }
  
  updateAgancyStatus = (status:any,id:any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAgencyStatusApiCallId = requestMessage.messageId;
    const httpBody = {
      "status":status,//options (accepted, rejected , pending )
      "token":this.state.token,
      "agency_id":id
  }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/agencies/status_updates`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAgancy = (id:any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token : this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAgencyStatusApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/get_agency_credit_details?agency_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBookingsByApiAccountId = (accountId : number) => {
    this.props.showLoader();
    let url = "bx_block_dashboard/fight_lists_by_account";
    
    if (this.state.selectedType === "All") {
        url = `${url}?search=${this.state.selectedTime}&type=${'all'}&account_id=${accountId}`;
    } else if (this.state.selectedType === "Hotels") {
      url = `${url}?search=${this.state.selectedTime}&type=${'hotel'}&account_id=${accountId}`;
    } else if (this.state.selectedType === "Flights") {
      url = `${url}?search=${this.state.selectedTime}&type=${'flight'}&account_id=${accountId}`;
    }
    url = `${url}&per=${10}&page=${1}`;

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allBookingEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  assignCredits = (accountId: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      'token': this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignCreditsApiCallId = requestMessage.messageId;
    const httpBody = {
        "credit_limit": {
          "credit_limit": this.state.creditTextField,
          "account_id": this.state.agencyDetail.id
       }
  }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_store_credits/credit_limits`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
