//@ts-nocheck
import React from "react";
import SubAgencyDashboardController, {
  Props,
} from "./SubAgencyDashboardController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Paper,
  Divider,
  Hidden,
  IconButton,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { hotelImage,agancyCover } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import SubAgencyDetails from "./SubAgencyDetails.web";
import Report from "./Report.web";
import EditSubAgency from "./EditSubAgency.web";
import AgencyAdminPrivateDashborad from "../../../components/src/AgencyAdminPrivateDashborad.web";
import { NavLink } from "react-router-dom";
import Header from "./Header.web";
import Footer from "./Footer.web";
import AgancyPrivateRoute from "../../../components/src/AgancyPrivateRoute.web";

const drawerWidth = 240;

class SubAgencyDashboard extends SubAgencyDashboardController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    let agancy = await StorageProvider.get("agancy")
    if (agancy) {
      agancy = agancy && JSON.parse(agancy);
      const authToken = await StorageProvider.get(agancy.tokenName || "agancyToken");
      this.setState(
        {
          token: authToken,
          id : (agancy)?.id || null,
          accountId : (agancy)?.attributes?.account_id || null,
          loading : false,
          tokenName : agancy.tokenName || "agancyToken"
        }
      );
    } else {
      this.setState({
        loading : false,
        token : null,
        id : null,
        accountId : null,
        tokenName : null
      })
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  render() {
    const { classes } = this.props;
    return !this.state.loading ? (
      this.state.token === null && this.state.id === null ? (<Typography>Loading.....</Typography>) : ((
        <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${agancyCover})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={agancyCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* <Header role="Agency"/> */}
          <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              SlideProps={{ unmountOnExit: true }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              <Grid container>
                <Grid item xs={10}>
                  <Paper elevation={0} className={classes.sidebarAboutBox}>
                    {[
                      {
                        title: "Subagency Details",
                        url: `/subAgencyDashboard/subAgencyDetails`,
                      },
                      { title: "Reports", url: `/subAgencyDashboard/reports` },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
   
          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  Sub-Agency
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="center"
          style={{ marginTop: "60px" }}
        >
          <Hidden xsDown>
            <Grid xs={3} item>
              <Grid container>
                <Grid item xs={10}>
                  <Paper elevation={0} className={classes.sidebarAboutBox}>
                    {[
                      {
                        title: "Subagency Details",
                        url: `/subAgencyDashboard/subAgencyDetails`,
                      },
                      { title: "Reports", url: `/subAgencyDashboard/reports` },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid xs={10} md={7} item>
            <Switch>
              <AgencyAdminPrivateDashborad path="/subAgencyDashboard/subAgencyDetails" exact>
                <SubAgencyDetails />
              </AgencyAdminPrivateDashborad>
              <AgencyAdminPrivateDashborad path="/subAgencyDashboard/reports" exact>
                <Report 
                  tokenName={this.state?.tokenName} role="SubAgency" accountId={this.state.accountId} apiType="subAgencyReport"
                />
              </AgencyAdminPrivateDashborad>
              <AgencyAdminPrivateDashborad path="/subAgencyDashboard/editAgencyDetails" exact>
                <EditSubAgency />
              </AgencyAdminPrivateDashborad>
              <Redirect
                exact
                from="/subAgencyDashboard"
                to={`/subAgencyDashboard/subAgencyDetails`}
              />
            </Switch>
          </Grid>
        </Grid>
        <Footer></Footer>
      </div>
        ))
  ) : null
  }
    
}
const SubAgencyDashboardControllerWithRouter = withRouter(SubAgencyDashboard);
const SubAgencyDashboardControllerAlertBox = withAlertBox(
  SubAgencyDashboardControllerWithRouter
);
const SubAgencyDashboardControllerLoader = withLoader(
  SubAgencyDashboardControllerAlertBox
);
const SubAgencyDashboardControllerToast = withToast(
  SubAgencyDashboardControllerLoader
);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [theme.breakpoints.down("xs")]: {
        height: "440px",
      },
      [theme.breakpoints.up("md")]: {
        height: "345px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(2),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "220px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      margin: theme.spacing(0, 1),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },

    sideLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      padding: theme.spacing(1, 1.5),
    },
    sideLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: "left",
      display: "block",
      textDecoration: "none",
      padding: theme.spacing(1.5),
    },
    activeSideLink: {
      backgroundColor: "#fff",
      color: "#1565d8",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    listItemPrimaryText : {
      fontFamily: "Public Sans",
      fontSize: "19px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemPrimaryText1 : {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemSecondaryText : {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    listItemSecondaryText1 : {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#5a7184",
    }
  })
)(SubAgencyDashboardControllerToast);
