//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
  TextField,
  Box,
  DialogActions,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import { success, arrow } from "./assets";
import PolicyDialogController, { Props } from "./PolicyDialogController.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { DialogTitle } from "../../../components/src/DialogContext";
// import DialogTitle from '@material-ui/core/DialogTitle';

import InputFiled from "../../../components/src/InputFiled.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";

class PolicyDialog extends PolicyDialogController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    this.props.onCancel();
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle id="simple-dialog-title" onCancel={this.props.onCancel}>
          Flight Policy
        </DialogTitle>

        <DialogContent className={classes.dialogStyles}>
          <Grid container spacing={2} justify="space-between">
            {this.props.dataToPass.policyData.length > 0 ? (
              this.props.dataToPass.policyData.map((policy) => (
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="space-around">
                    <Grid
                      item
                      alignItems="center"
                      xs={12}
                      style={{ marginTop: "20px" }}
                    >
                      {/* <img src={covidIcon} style={{width: '35px', height: '32px', display:'inline-block', marginRight: '10px'}}/> */}
                      <Typography
                        display="inline"
                        color="textPrimary"
                        className={classes.groteskBold18}
                      >
                        {policy.heading}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        color="textSecondary"
                        align="justify"
                        className={classes.openSans16}
                      >
                        {policy.text}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={2} justify="space-around" spacing={2}>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={10}
                    style={{ marginTop: "20px" }}
                  >
                    {/* <img src={covidIcon} style={{width: '35px', height: '32px', display:'inline-block', marginRight: '10px'}}/> */}
                    <Typography
                      display="inline"
                      color="textPrimary"
                      className={classes.groteskBold18}
                    >
                      No policies
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end">
            <Grid item xs={2}>
              <Button
                onClick={this.props.onCancel}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  }
}
const PolicyDialogAlertBox = withAlertBox(PolicyDialog);
const PolicyDialogLoader = withLoader(PolicyDialogAlertBox);
const PolicyDialogToast = withToast(PolicyDialogLoader);
const PolicyDialogWithRouter = withRouter(PolicyDialogToast);

export default withStyles((theme) =>
  createStyles({
    confirmationText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.15,
      color: "#1b4050",
    },
    confirmationSubTitle: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: 1.19,
      color: "#1b4050",
    },
    boxLarge: {
      borderRadius: "6px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: "#ffffff",
      border: "solid 1px #e5eaf4",
    },
    checkBoxLabel: {
      fontSize: "12px",
      fontFamily: "Open Sans",
    },
    dialogStyles: {
      padding: "32px",
    },
  })
)(PolicyDialogWithRouter);
