//@ts-nocheck
import React from "react";
import EditProfileController, { Props } from "./EditProfileController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Divider,
  Switch,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import moment from "moment";

const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();
const Schema = Yup.object().shape({
  // name: Yup.string().required("This field is required."),
  first_name: Yup.string().required("This field is required."),
  last_name: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  gender: Yup.string().required("This field is required."),
  dob: Yup.string().required("This field is required."),
  addressLineOne: Yup.string().required("This field is required."),
  addressLineTwo: Yup.string().required("This field is required."),
  country: Yup.string().required("This field is required."),
  city: Yup.string().required("This field is required."),
  pinCode: Yup.number().required("This field is required"),
  phoneNumber: Yup.string()
    .phone()
    .required("This field is required"),
  passportNumber: Yup.string().required("This field is required."),
  passportExpiryDate: Yup.date().required("This field is required."),
  currency: Yup.string().required("This field is required."),
  isPassport: Yup.boolean(),
  passportNumber: Yup
    .string()
    .when("isPassport", {
      is: true,
      then: Yup.string().required("This field is required"),
    }),
  passportExpiryDate: Yup
    .date()
    .when("isPassport", {
      is: true,
      then: Yup.date().required("This field is required")
    }),
  citizenNumber: Yup
    .string()
    .when("isPassport", {
      is: false,
      then: Yup.string().required("This field is required"),
    }),
  citizenNumberExpiryDate: Yup
    .date()
    .when("isPassport", {
      is: false,
      then: Yup.date().required("This field is required")
    })
});
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
class EditProfile extends EditProfileController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getProfileDetail();
          this.getCountriesList()
        }
      );
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          // name: this.state?.profileData?.full_name || "",
          first_name: this.state?.profileData?.first_name || "",
          last_name: this.state?.profileData?.last_name || "",
          email: this.state?.profileData?.email || "",
          gender: this.state?.profileData?.gender || "Male",
          dob: this.state?.profileData?.dob || "",
          addressLineOne: this.state?.profileData?.first_address || "",
          addressLineTwo: this.state?.profileData?.second_address || "",
          city: this.state?.profileData?.city || "",
          pinCode: this.state?.profileData?.zip_pin || "",
          country: this.state?.profileData?.country || "",
          phoneNumber: this.state?.profileData?.full_phone_number || "",
          passportNumber: this.state?.profileData?.passport_number || "",
          passportExpiryDate: this.state?.profileData?.passport_expiry || "",
          citizenNumber: this.state?.profileData?.citizenship_number || "",
          isPassport: this.state?.profileData?.passport_available || true,
          citizenNumberExpiryDate: this.state?.profileData?.citizenship_issue_date || "",
          isViber: this.state?.profileData?.vibar || "",
          isWhatsApp: this.state?.profileData?.whatsapp || "",
          currency: this.state?.profileData?.currency || "USD",
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          const onClose = (result) => {
            if (result === 'Yes') {
              this.handleProfileSave(values);
            }
          }
          this.props.openConfirmDialog(
            'Save', 'Are you sure you want to save this info', onClose
          )
          // this.handleProfileSave(values);
        }}
      >
        {(formikProps) => {
          const { values, errors, setFieldValue, handleChange } = formikProps;
          return (
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12}>
                <Grid container justify='center' alignItems='center' className={classes.userProfileBox}>
                  <Grid item md={11}>
                    <Typography className={classes.userProfileTitle}>
                      Edit Profile
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Form noValidate autoComplete="off">
                  <Grid container className={classes.EditProfileContainer}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.formSectionHeading}
                          >
                            Personal Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.dividerStyle}></Divider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            label="First Name"
                            name="first_name"
                            fullWidth
                            placeholder="Enter First Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            label="Last Name"
                            name="last_name"
                            fullWidth
                            placeholder="Enter Last Name"
                          />
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            label="Your Name"
                            name="name"
                            fullWidth
                            placeholder="Enter Full Name"
                          />
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            label="Email Address"
                            name="email"
                            fullWidth
                            placeholder="Enter Email Address"
                          />
                        </Grid>
                        <Grid container item xs={12} md={6}>
                          <RadioGroup
                            row
                            aria-label="gender"
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="Male"
                              control={<Radio />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio />}
                              label="Female"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            type="date"
                            InputProps={{
                              inputProps: {
                                max: curDate,
                              },
                            }}
                            fullWidth
                            label="Date of Birth"
                            name="dob"
                            placeholder="dd/mm/yyyy"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGridItem}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.formSectionHeading}

                          >
                            Address Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.dividerStyle}></Divider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Address Line 1"
                            name="addressLineOne"
                            placeholder="House/Flat Number, Street Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Address Line 2"
                            name="addressLineTwo"
                            placeholder="Landmark Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="City"
                            name="city"
                            placeholder="City Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="ZIP/PIN Code"
                            name="pinCode"
                            placeholder="Zip or Pin code"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            select
                            required
                            fullWidth
                            label="Country"
                            name="country"
                            component={InputField}
                          >
                            <MenuItem value="">Select Country</MenuItem>
                            {this.state.countriesList.map((country) => {
                              return <MenuItem key={country} value={country}>{country}</MenuItem>
                            })}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGridItem}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.formSectionHeading}

                          >
                            Currency Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.dividerStyle}></Divider>
                        </Grid>

                        <Grid container item xs={12} md={6}>
                          <FormControl component="fieldset">
                            <FormLabel
                              className={classes.radioGroupTitle}
                              component="legend"
                            >
                              Currency
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label="position"
                              name="currency"
                              onChange={handleChange}
                              defaultValue={values.currency}
                            >
                              <FormControlLabel
                                classes={{
                                  label: classes.radioButtonLabel,
                                }}
                                value="USD"
                                control={
                                  <Radio
                                    color="primary"
                                  />
                                }
                                label="USD"
                              />
                              <FormControlLabel
                                classes={{
                                  label: classes.radioButtonLabel,
                                }}
                                value="NPR"
                                control={
                                  <Radio
                                    color="primary"
                                  />
                                }
                                label="NPR"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGridItem}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.formSectionHeading}

                          >
                            Contact Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.dividerStyle}></Divider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Phone Number"
                            name="phoneNumber"
                            placeholder="Phone Number With Country Code"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Checkbox />}
                            name="isWhatsApp"
                            checked={values.isWhatsApp}
                            onChange={handleChange}
                            label="Yes, I am on WhatsApp with above number"
                            labelPlacement="end"
                            classes={{
                              label: classes.labelText,
                            }}
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            name="isViber"
                            checked={values.isViber}
                            onChange={handleChange}
                            label="Yes, I am on Viber with above number"
                            labelPlacement="end"
                            classes={{
                              label: classes.labelText,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGridItem}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.formSectionHeading} >
                            Passport Details / Citizenship Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.dividerStyle}></Divider>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Switch />}
                            name="isPassport"
                            checked={values.isPassport}
                            onChange={handleChange}
                            label="Use Passport instead of citizenship"
                            labelPlacement="start"
                            classes={{
                              label: classes.labelText,
                            }}
                          />
                        </Grid>
                        {values.isPassport && <>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="Passport Number"
                              name="passportNumber"
                              placeholder="Enter Passport Number"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              InputProps={{
                                inputProps: {
                                  min: curDate,
                                },
                              }}
                              type="date"
                              label="Passport Expiry Date"
                              name="passportExpiryDate"
                              placeholder="dd/mm/yyyy"
                            />
                          </Grid></>}
                        {!values.isPassport && <>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              label="Citizen Number"
                              name="citizenNumber"
                              placeholder="Enter Citizen Number"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Field
                              component={InputField}
                              required
                              fullWidth
                              InputProps={{
                                inputProps: {
                                  min: curDate,
                                },
                              }}
                              type="date"
                              label="Citizen Expiry Date"
                              name="citizenNumberExpiryDate"
                              placeholder="12/12/2020"
                            />
                          </Grid></>}

                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2} className={classes.buttonGridItem} justify="flex-end" alignItems="center">
                      <Grid item md={3} xs={5}>
                        <Button fullWidth color="primary" variant="outlined" onClick={() => this.goToSelectedRoute("/myAccount/profile")}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item md={3} xs={5}>
                        <Button type="submit" fullWidth color="primary" variant="contained">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    );
  }
}

const EditProfileWithRouter = withRouter(EditProfile);
const EditProfileAlertBox = withAlertBox(EditProfileWithRouter);
const EditProfileLoader = withLoader(EditProfileAlertBox);
const EditProfileToast = withToast(EditProfileLoader);

export default withStyles((theme) =>
  createStyles({
    userProfileBox: {
      padding: "20px 0px",
      marginBottom: "20px",
      borderRadius: "5px",
      backgroundColor: "#EBEBEB",
    },
    userProfileTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    EditProfileContainer: {
      borderRadius: "5px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: "#ffffff",
      padding: "30px",
      marginBottom: "50px",
    },
    formSectionHeading: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    userEmail: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    userName: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9",
    },
    buttonGridItem: {
      marginTop: "20px",
    }
  })
)(withConfirmBox(EditProfileToast));
