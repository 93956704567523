import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken: string;
  agencyDetail: any;
  profiieData: any;
  agencyData: object;
  allAcceptedInvites: Array<any>;
  policyTitle: string;
  policyDescription: string;
  policyType: string;
  policyId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddNewPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAndUpdateTermsAndConditionsApiCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      agencyDetail: {},
      authToken: "",
      profiieData: {},
      agencyData: {},
      allAcceptedInvites: [],
      policyTitle: "",
      policyDescription: "",
      policyType: "",
      policyId: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (
        apiRequestCallId === this.createAndUpdateTermsAndConditionsApiCallId
      ) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
          this.props.history.location.pathname === "/AdminDashboard/editPolicy"
            ? this.props.showToast({
                type: "success",
                message: "Updated Terms and Condition Successfully",
              })
            : this.props.showToast({
                type: "success",
                message: "Added Terms and Condition Successfully",
              });
          this.props.history.push("policies");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      });
      if (msg === "Token has Expired" || msg === "Invalid token") {
        await StorageProvider.clearStorage();
        this.props.history.replace("/");
      }
    }
  }

  createAndUpdateTermsAndConditions = (type: string) => {
    let method = "";
    let url = "";
    type === "PUT"
      ? (method = configJSON.methodPUT)
      : (method = configJSON.methodPOST);
    type === "PUT"
      ? (url = `${configJSON.createTermsAndConditionsEndPoint}/${
          this.state.policyId
        }`)
      : (url = `${configJSON.createTermsAndConditionsEndPoint}`);

    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAndUpdateTermsAndConditionsApiCallId = requestMessage.messageId;

    const httpBody = {
      data: {
        description: this.state.policyDescription,
        t_and_c_type: this.state.policyType,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
