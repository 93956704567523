import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";
export const configJSON = require("./config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  authToken: string;
  offerImage: any;
  offerImageServer : any
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: String;
  password: String;
  unique_auth_id? : String;
};
export default class AddOfferDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  createOfferApiCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken: '',
      offerImage: null,
      offerImageServer : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.createOfferApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson?.data?.id) {
            this.props.onSubmit('SuccessFull')
          } else {
            this.parseApiErrorResponse(responseJson);
          }
      } 
  } else if (getName(MessageEnum.AlertMessage) === message.id) {
    const title = message.getData(getName(MessageEnum.AlertTitleMessage));
    var AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );
    this.props.showAlert({
      title: "Alert",
      message: AlertBodyMessage,
    });
  }
}
updateOfferHandler = (values: any) => {
   this.props.showLoader();
   const headers = {
     // "Content-Type": configJSON.ApiContentType,
     "token": this.state.authToken,
   };

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.createOfferApiCallId = requestMessage.messageId;
   
   
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage), 
     `${"bx_block_content_management/advertisements/"}${this.props.dataToPass.offerDetail.id}?data[title]=${values.offerTitle}&data[description]=${values.offerDescription}&data[advertisement_type]=${values.offerType}&data[start_date]=${values.fromDate}&data[end_date]=${values.toDate}&data[url]=${values.url}`
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headers)
   );
   if(this.state.offerImage){
    const formdata = new FormData();
    formdata.append("images",this.state.offerImage.file)    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
   }
  

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.methodPUT
   );
   runEngine.sendMessage(requestMessage.id, requestMessage);
  
 }
  createOfferHandler = (values: any) => {
   if(this.state.offerImage){
    this.props.showLoader();
    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOfferApiCallId = requestMessage.messageId;
    

    const formdata = new FormData();
    formdata.append("images",this.state.offerImage.file)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `${"bx_block_content_management/advertisements"}?data[title]=${values.offerTitle}&data[description]=${values.offerDescription}&data[advertisement_type]=${values.offerType}&data[start_date]=${values.fromDate}&data[end_date]=${values.toDate}&data[url]=${values.url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
   
  }
}
