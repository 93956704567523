//@ts-nocheck
import React from "react";
import NewSearchController, { Props } from "./NewSearchController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  Container,
  Avatar,
  InputAdornment,
  IconButton,
  TextField,
  MenuItem
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import Header from "./Header.web";
import Footer from "./Footer.web";
import Close from "@material-ui/icons/Close";
import {
  hotelImage,
  CustomerlandingPage,
  hotelIcon,
  flightIcon,
} from "./assets";
import { withRouter } from "react-router";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const hotelSchema = Yup.object().shape({
  hotelName: Yup.string().required("This field is required."),
  noOfRooms: Yup.number().required("This field is required"),
  noOfGuests: Yup.number().required("This field is required"),
  checkIn: Yup.date()
    .max(Yup.ref("checkOut"), "Checkin date can't be after checkout date")
    .required("This field is required."),
  checkOut: Yup.date()
    .min(Yup.ref("checkIn"), "Checkout date can't be before checkin date")
    .required("This field is required."),
});

const flightOneWaySchema = Yup.object().shape({
  from: Yup.string().required("This field is required."),
  to: Yup.string().required("This field is required"),
  depratureDate: Yup.date().required("This field is required."),
  adults: Yup.number().required("This field is required."),
  infants : Yup.number(),
  children: Yup.number(),
  class: Yup.string().required("This field is required."),
});

const flightTwoWaySchema = Yup.object().shape({
  from: Yup.string().required("This field is required."),
  to: Yup.string().required("This field is required"),
  depratureDate: Yup.date().required("This field is required."),
  returnDate: Yup.date()
    .min(Yup.ref("depratureDate"), "End date can't be before Start date")
    .required("This field is required."),
    adults: Yup.number().required("This field is required."),
    infants : Yup.number(),
    children: Yup.number(),
  class: Yup.string().required("This field is required."),
});

const flightMultiCitySchema = Yup.object().shape({
  // from: Yup.string().required("This field is required."),
  // to: Yup.string().required("This field is required"),
  // depratureDate: Yup.date().required("This field is required."),
  newCity: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required("This field is required."),
      to: Yup.string().required("This field is required"),
      depratureDate: Yup.date().required("This field is required."),
    })
  ),
  adults: Yup.number().required("This field is required."),
  infants : Yup.number(),
  children: Yup.number(),
  class: Yup.string().required("This field is required."),
});
class NewSearch extends NewSearchController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "authToken";
    const authToken = await StorageProvider.get(tokenName);
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          // this.getBoatListing();
          // this.getBookings();
        }
      );
    }
  }

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.push({
      state: {
        data: { ...data },
        selectedTravellingType: this.state.selectedTravellingType,
        fromCity: this.state.fromCity,
        toCity: this.state.toCity,
        role : this.props.role || "Customer"
      },
      pathname: routeName,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container justify="space-around" spacing={2}>
          <Grid item md={10} xs={12} className={classes.pendIngRequestBox}>
            <Tabs
              value={this.state.selectedSearchType}
              onChange={(evt, value) => {
                this.setState(
                  {
                    selectedSearchType: value,
                  },
                  () => {
                    //   this.getBookings();
                  }
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab
                label="Hotels"
                value={"Hotels"}
                icon={
                  <img
                    src={hotelIcon}
                    style={{ widht: "20px", height: "20px" }}
                  />
                }
              />
              <Tab
                label="Flights"
                value={"Flights"}
                icon={
                  <img
                    style={{ widht: "20px", height: "20px" }}
                    src={flightIcon}
                  />
                }
              />
            </Tabs>
          </Grid>
          <Grid item md={10} xs={12}>
            <Grid container justify="center" className={classes.agencyPendingRequestContainer}>
              <Grid
                item
                md={10} 
                xs={10}
              >
                <TabPanel
                        value={this.state.selectedSearchType}
                        index={"Hotels"}
                      >
                        {" "}
                        <Formik
                          initialValues={{
                            hotelName: "",
                            checkIn: "",
                            checkOut: "",
                            noOfRooms: "",
                            noOfGuests: "",
                          }}
                          validationSchema={hotelSchema}
                          onSubmit={(values) => {
                            this.goToSelectedRoute(
                              "/hotelSearchResult",
                              values
                            );
                          }}
                        >
                          {(formikProps) => {
                            const {
                              values,
                              handleBlur,
                              handleChange,
                              setFieldValue,
                            } = formikProps;
                            return (
                              <Form autoComplete="off" noValidate>
                                <Grid container justify="center" spacing={2}>
                                  <Grid xs={10} item>
                                    <Field
                                      component={InputFieled}
                                      name="hotelName"
                                      placeholder="Enter City / Hotel / Area / Building"
                                      // onChange={handleChange}
                                      value={values.hotelName}
                                      fullWidth
                                      label="City / Hotel / Area / Building "
                                    />
                                  </Grid>
                                  <Grid xs={10} item>
                                    <Field
                                      component={InputFieled}
                                      name="checkIn"
                                      type="date"
                                      placeholder="Enter Check-In Date"
                                      // onChange={handleChange}
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      value={values.checkIn}
                                      fullWidth
                                      label="Check In"
                                    />
                                  </Grid>
                                  <Grid xs={10} item>
                                    <Field
                                      component={InputFieled}
                                      name="checkOut"
                                      type="date"
                                      placeholder="Enter Check In Date"
                                      // onChange={handleChange}
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      value={values.checkOut}
                                      fullWidth
                                      label="Check Out"
                                    />
                                  </Grid>
                                  <Grid xs={10} item>
                                    <Field
                                      component={InputFieled}
                                      name="noOfGuests"
                                      type="number"
                                      placeholder="Enter No. of Guest"
                                      // onChange={handleChange}
                                      value={values.noOfGuests}
                                      fullWidth
                                      label="Guests"
                                    />
                                  </Grid>
                                  <Grid xs={10} item>
                                    <Field
                                      component={InputFieled}
                                      name="noOfRooms"
                                      type="number"
                                      placeholder="Enter No. of Rooms"
                                      // onChange={handleChange}
                                      value={values.noOfRooms}
                                      fullWidth
                                      label="Rooms"
                                    />
                                  </Grid>
                                  <Grid xs={10} item>
                                    <Box py={3}>
                                      <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                      >
                                        Search
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Form>
                            );
                          }}
                        </Formik>
                      </TabPanel>

                      <TabPanel
                        value={this.state.selectedSearchType}
                        index={"Flights"}
                      >
                        <Tabs
                          value={this.state.selectedTravellingType}
                          onChange={(evt, value) => {
                            this.setState({
                              selectedTravellingType: value,
                              fromCity: null,
                              toCity: null,
                              destinationSuggestionloading: false,
                              destinationsearchSuggestionList: [],
                              sourceSuggestionloading: false,
                              sourcesearchSuggestionList: [],
                            });
                          }}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                        >
                          <Tab value={"oneway"} label="One Way" classes={{root: classes.tabLabel}} />
                          <Tab value={"twoway"} label="Two Way" classes={{root: classes.tabLabel}} />
                          <Tab value={"multicity"} label="Multicity" classes={{root: classes.tabLabel}} />
                        </Tabs>
                        <TabPanel
                          value={this.state.selectedTravellingType}
                          index={"oneway"}
                        >
                          <Formik
                            initialValues={{
                              from: "",
                              to: "",
                              depratureDate: "",
                              adults: "",
                              children: "0",
                              infants: "0",
                              class: "Economy",
                            }}
                            enableReinitialize
                            validationSchema={flightOneWaySchema}
                            onSubmit={(values) => {
                              this.goToSelectedRoute(
                                "/flightSearchResult",
                                values
                              );
                            }}
                          >
                            {(formikProps) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                              } = formikProps;
                              return (
                                <Form autoComplete="off">
                                  <Grid container justify="center" spacing={2}>
                                    <Grid xs={10} item>
                                      <Autocomplete
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        freeSolo
                                        style={{ width: "100%" }}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) =>
                                          `${value.city},${value.country}(${value.iata})`
                                        }
                                        options={
                                          this.state.sourcesearchSuggestionList
                                        }
                                        loading={false}
                                        onInputChange={(evt) => {
                                          setFieldTouched("from", true);
                                          setFieldValue("from", "");
                                          this.getFromLocationSuggestion(
                                            evt.target.value
                                          );
                                        }}
                                        onChange={(evt, value) => {
                                          setFieldValue(
                                            "from",
                                            `${value.city},(${value.name})`
                                          );
                                          this.setState({
                                            sourceSuggestionloading: false,
                                            fromCity: value,
                                          });
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="From"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .sourceSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  }
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.from && touched.from && (
                                        <Typography color="error">
                                          {errors.from}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        style={{ width: "100%" }}
                                        options={
                                          this.state
                                            .destinationsearchSuggestionList
                                        }
                                        loading={false}
                                        onInputChange={(evt) => {
                                          this.getDestinationLocationSuggestion(
                                            evt.target.value
                                          );
                                        }}
                                        onInputChange={(evt) => {
                                          setFieldTouched("to", true);
                                          setFieldValue("to", "");
                                          this.getDestinationLocationSuggestion(
                                            evt.target.value
                                          );
                                        }}
                                        onChange={(evt, value) => {
                                          setFieldValue(
                                            "to",
                                            `${value.city},(${value.name})`
                                          );
                                          this.setState({
                                            destinationSuggestionloading: false,
                                            toCity: value,
                                          });
                                        }}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) =>
                                          `${value.city},${value.country}(${value.iata})`
                                        }
                                        onBlur={() => {
                                          setFieldTouched("to", true);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="To"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .destinationSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  }
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.to && touched.to && (
                                        <Typography color="error">
                                          {errors.to}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Field
                                        component={InputFieled}
                                        name="depratureDate"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: curDate,
                                          },
                                        }}
                                        placeholder="Enter Departure Date"
                                        onChange={handleChange}
                                        value={values.depratureDate}
                                        fullWidth
                                        label="Departure Date"
                                      />
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Grid container spacing={1}>
                                      <Grid xs={4} item>
                                      <Field
                                        component={InputFieled}
                                        select
                                        name="adults"
                                        onChange={handleChange}
                                        value={values.adults}
                                        fullWidth
                                        label="Adults(> 12yr)"
                                      >
                                        {[
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                          {
                                            value: "7",
                                          },
                                          {
                                            value: "8",
                                          },
                                          {
                                            value: "9",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={5} item>
                                      <Field
                                        select
                                        component={InputFieled}
                                        name="children"
                                        onChange={handleChange}
                                        value={values.children}
                                        fullWidth
                                        label="Children(2yr - 12yr)"
                                      >
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <Field
                                        component={InputFieled}
                                        name="infants"
                                        placeholder="No. of Children"
                                        onChange={handleChange}
                                        select
                                        value={values.infants}
                                        fullWidth
                                        label="Infant(< 2yr)"
                                      >
                                        {" "}
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                                      </Grid>
                                    </Grid>
                                    
                                    <Grid xs={10} item>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Class"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                                    <Grid xs={10} item>
                                      <Box py={3}>
                                        <Button
                                          type="submit"
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                        >
                                          Search
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </TabPanel>
                        <TabPanel
                          value={this.state.selectedTravellingType}
                          index={"twoway"}
                        >
                          <Formik
                            initialValues={{
                              from: "",
                              to: "",
                              depratureDate: "",
                              returnDate: "",
                              adults: "",
                              children: "0",
                              infants: "0",
                              class: "Economy",
                            }}
                            validationSchema={flightTwoWaySchema}
                            onSubmit={(values) => {
                              this.goToSelectedRoute(
                                "/flightSearchResult",
                                values
                              );
                            }}
                          >
                            {(formikProps) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldError,
                                setFieldTouched,
                              } = formikProps;
                              return (
                                <Form autoComplete="off">
                                  <Grid container justify="center" spacing={2}>
                                    <Grid xs={10} item>
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        style={{ width: "100%" }}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) =>
                                          `${value.city},${value.country}(${value.iata})`
                                        }
                                        options={
                                          this.state.sourcesearchSuggestionList
                                        }
                                        loading={false}
                                        onInputChange={(evt) => {
                                          setFieldTouched("from", true);
                                          setFieldValue("from", "");
                                          this.getFromLocationSuggestion(
                                            evt.target.value
                                          );
                                        }}
                                        onChange={(evt, value) => {
                                          setFieldValue(
                                            "from",
                                            `${value.city},(${value.name})`
                                          );
                                          this.setState({
                                            sourceSuggestionloading: false,
                                            fromCity: value,
                                          });
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="From"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .sourceSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  }
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.from && touched.from && (
                                        <Typography color="error">
                                          {errors.from}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options, state) =>
                                          options
                                        }
                                        style={{ width: "100%" }}
                                        options={
                                          this.state
                                            .destinationsearchSuggestionList
                                        }
                                        loading={false}
                                        onInputChange={(evt) => {
                                          this.getDestinationLocationSuggestion(
                                            evt.target.value
                                          );
                                        }}
                                        onInputChange={(evt) => {
                                          setFieldTouched("to", true);
                                          setFieldValue("to", "");
                                          this.getDestinationLocationSuggestion(
                                            evt.target.value
                                          );
                                        }}
                                        onChange={(evt, value) => {
                                          setFieldValue(
                                            "to",
                                            `${value.city},(${value.name})`
                                          );
                                          this.setState({
                                            destinationSuggestionloading: false,
                                            toCity: value,
                                          });
                                        }}
                                        renderOption={(value) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                              }}
                                            >
                                              <div style={{ flexGrow: 1 }}>
                                                <Typography
                                                  gutterBottom
                                                  color="textPrimary"
                                                >
                                                  {value.city}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                  {value.name}
                                                </Typography>
                                              </div>
                                              <Typography>
                                                {value.iata}
                                              </Typography>
                                            </div>
                                          );
                                        }}
                                        getOptionLabel={(value) =>
                                          `${value.city},${value.country}(${value.iata})`
                                        }
                                        onBlur={() => {
                                          setFieldTouched("to", true);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Enter Airport or City Name"
                                            variant="outlined"
                                            label="To"
                                            InputLabelProps={{
                                              ...params.InputLabelProps,
                                              shrink: true,
                                              classes: {
                                                asterisk: classes?.astriklabel,
                                                outlined:
                                                  classes?.outlinedLabel,
                                                shrink: classes?.shrink,
                                                root: classes?.lableroot,
                                              },
                                            }}
                                            required
                                            InputProps={{
                                              ...params.InputProps,
                                              classes: {
                                                input: classes?.input,
                                                root: classes?.inputfieledroot,
                                              },
                                              notched: false,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {this.state
                                                    .destinationSuggestionloading ? (
                                                    <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                    />
                                                  ) : null}
                                                  {
                                                    <InputAdornment position="end">
                                                      <IconButton aria-label="search">
                                                        <SearchIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  }
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      {errors.to && touched.to && (
                                        <Typography color="error">
                                          {errors.to}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Field
                                        component={InputFieled}
                                        name="depratureDate"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: curDate,
                                          },
                                        }}
                                        placeholder="Enter Departure Date"
                                        onChange={handleChange}
                                        value={values.depratureDate}
                                        fullWidth
                                        label="Departure Date"
                                      />
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Field
                                        component={InputFieled}
                                        name="returnDate"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: curDate,
                                          },
                                        }}
                                        placeholder="Enter Return Date"
                                        onChange={handleChange}
                                        value={values.returnDate}
                                        fullWidth
                                        label="Return"
                                      />
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Grid container spacing={1}>
                                      <Grid xs={4} item>
                                      <Field
                                        component={InputFieled}
                                        select
                                        name="adults"
                                        onChange={handleChange}
                                        value={values.adults}
                                        fullWidth
                                        label="Adults(> 12yr)"
                                      >
                                        {[
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                          {
                                            value: "7",
                                          },
                                          {
                                            value: "8",
                                          },
                                          {
                                            value: "9",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={5} item>
                                      <Field
                                        select
                                        component={InputFieled}
                                        name="children"
                                        onChange={handleChange}
                                        value={values.children}
                                        fullWidth
                                        label="Children(2yr - 12yr)"
                                      >
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <Field
                                        component={InputFieled}
                                        name="infants"
                                        placeholder="No. of Children"
                                        onChange={handleChange}
                                        select
                                        value={values.infants}
                                        fullWidth
                                        label="Infant(< 2yr)"
                                      >
                                        {" "}
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                                      </Grid>
                                    </Grid>
                                    <Grid xs={10} item>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Class"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                   
                                    <Grid xs={10} item>
                                      <Box py={3}>
                                        <Button
                                          type="submit"
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                        >
                                          Search
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </TabPanel>
                        <TabPanel
                          value={this.state.selectedTravellingType}
                          index={"multicity"}
                        >
                          <Formik
                            initialValues={{
                              newCity: [
                                { from: "", to: "", depratureDate: "" },
                              ],
                              adults: "",
                              children: "0",
                              infants: "0",
                              class: "Economy",
                            }}
                            enableReinitialize
                            validationSchema={flightMultiCitySchema}
                            onSubmit={(values) => {
                              // this.goToSelectedRoute(
                              //   "/flightSearchResult",
                              //   values
                              // );
                            }}
                          >
                            {(formikProps) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError,
                              } = formikProps;
                              return (
                                <Form autoComplete="off">
                                  <Grid container justify="center" spacing={2}>
                                  <Grid xs={10} item>
                                      <Grid container spacing={1}>
                                      <Grid xs={4} item>
                                      <Field
                                        component={InputFieled}
                                        select
                                        name="adults"
                                        onChange={handleChange}
                                        value={values.adults}
                                        fullWidth
                                        label="Adults(> 12yr)"
                                      >
                                        {[
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                          {
                                            value: "7",
                                          },
                                          {
                                            value: "8",
                                          },
                                          {
                                            value: "9",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={5} item>
                                      <Field
                                        select
                                        component={InputFieled}
                                        name="children"
                                        onChange={handleChange}
                                        value={values.children}
                                        fullWidth
                                        label="Children(2yr - 12yr)"
                                      >
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <Field
                                        component={InputFieled}
                                        name="infants"
                                        placeholder="No. of Children"
                                        onChange={handleChange}
                                        select
                                        value={values.infants}
                                        fullWidth
                                        label="Infant(< 2yr)"
                                      >
                                        {" "}
                                        {[
                                          {
                                            value: "0",
                                          },
                                          {
                                            value: "1",
                                          },
                                          {
                                            value: "2",
                                          },
                                          {
                                            value: "3",
                                          },
                                          {
                                            value: "4",
                                          },
                                          {
                                            value: "5",
                                          },
                                          {
                                            value: "6",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                                      </Grid>
                                    </Grid>
                                    
                                   <Grid xs={10} item>
                                      <Field
                                        component={InputFieled}
                                        name="class"
                                        select
                                        placeholder="Class"
                                        value={values.class}
                                        fullWidth
                                        label="Class"
                                      >
                                        {[
                                          {
                                            value: "Economy",
                                          },
                                          {
                                            value: "Premimum Economy",
                                          },
                                          {
                                            value: "Business",
                                          },
                                          {
                                            value: "First Class",
                                          },
                                        ].map((value) => {
                                          return (
                                            <MenuItem
                                              key={value.value}
                                              value={value.value}
                                            >
                                              {value.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>
                                    </Grid>
                                    
                              
                                    <FieldArray
                                      name="newCity"
                                      render={(arrayHelpers) => (
                                        <>
                                          {values.newCity &&
                                          values.newCity.length > 0 ? (
                                            values.newCity.map(
                                              (newcity, index) => (
                                                <React.Fragment key={index}>
                                                  <Grid xs={10} item>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignSelf : "center",
                                                        alignItems : "center"

                                                      }}
                                                    >
                                                      <div
                                                        style={{ flexGrow: 1,
                                                          alignSelf : "center",
                                                          alignItems : "center" }}
                                                      ><Divider></Divider></div>
                                                      <IconButton
                                                        onClick={() => {
                                                          if (
                                                            values?.newCity
                                                              .length > 1
                                                          ) {
                                                            arrayHelpers.remove(
                                                              index
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <HighlightOffIcon></HighlightOffIcon>
                                                      </IconButton>
                                                    </div>
                                                  </Grid>
                                                  <Grid xs={10} item>
                                                    <Autocomplete
                                                      freeSolo
                                                      filterOptions={(
                                                        options,
                                                        state
                                                      ) => options}
                                                      style={{ width: "100%" }}
                                                      renderOption={(value) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                flexGrow: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                gutterBottom
                                                                color="textPrimary"
                                                              >
                                                                {value.city}
                                                              </Typography>
                                                              <Typography color="textSecondary">
                                                                {value.name}
                                                              </Typography>
                                                            </div>
                                                            <Typography>
                                                              {value.iata}
                                                            </Typography>
                                                          </div>
                                                        );
                                                      }}
                                                      getOptionLabel={(value) =>
                                                        `${value.city},${value.country}(${value.iata})`
                                                      }
                                                      options={
                                                        this.state
                                                          .sourcesearchSuggestionList
                                                      }
                                                      loading={false}
                                                      onInputChange={(evt) => {
                                                        setFieldTouched(
                                                          `newCity[${index}].from`,
                                                          true
                                                        );
                                                        setFieldValue(
                                                          `newCity[${index}].from`,
                                                          ""
                                                        );
                                                        this.getFromLocationSuggestion(
                                                          evt.target.value
                                                        );
                                                      }}
                                                      onChange={(
                                                        evt,
                                                        value
                                                      ) => {
                                                        setFieldValue(
                                                          `newCity[${index}].from`,
                                                          JSON.stringify(value)
                                                        );
                                                        this.setState({
                                                          sourceSuggestionloading: false,
                                                        });
                                                      }}
                                                      onBlur={() => {
                                                        setFieldTouched(
                                                          `newCity[${index}].from`,
                                                          true
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          fullWidth
                                                          placeholder="Enter Airport or City Name"
                                                          variant="outlined"
                                                          label="From"
                                                          InputLabelProps={{
                                                            ...params.InputLabelProps,
                                                            shrink: true,
                                                            classes: {
                                                              asterisk:
                                                                classes?.astriklabel,
                                                              outlined:
                                                                classes?.outlinedLabel,
                                                              shrink:
                                                                classes?.shrink,
                                                              root: classes?.lableroot,
                                                            },
                                                          }}
                                                          required
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            classes: {
                                                              input:
                                                                classes?.input,
                                                              root: classes?.inputfieledroot,
                                                            },
                                                            notched: false,
                                                            endAdornment: (
                                                              <React.Fragment>
                                                                {this.state
                                                                  .sourceSuggestionloading ? (
                                                                  <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                  />
                                                                ) : null}
                                                                {
                                                                  <InputAdornment position="end">
                                                                    <IconButton aria-label="search">
                                                                      <SearchIcon />
                                                                    </IconButton>
                                                                  </InputAdornment>
                                                                }
                                                              </React.Fragment>
                                                            ),
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                    {errors?.newCity &&
                                                      touched?.newCity &&
                                                      errors?.newCity[index]
                                                        ?.from &&
                                                      touched?.newCity[index]
                                                        ?.from && (
                                                        <Typography color="error">
                                                          {
                                                            errors?.newCity[
                                                              index
                                                            ]?.from
                                                          }
                                                        </Typography>
                                                      )}
                                                  </Grid>
                                                  <Grid xs={10} item>
                                                    <Autocomplete
                                                      freeSolo
                                                      filterOptions={(
                                                        options,
                                                        state
                                                      ) => options}
                                                      style={{ width: "100%" }}
                                                      options={
                                                        this.state
                                                          .destinationsearchSuggestionList
                                                      }
                                                      loading={false}
                                                      onInputChange={(evt) => {
                                                        this.getDestinationLocationSuggestion(
                                                          evt.target.value
                                                        );
                                                      }}
                                                      onInputChange={(evt) => {
                                                        setFieldTouched(
                                                          `newCity[${index}].to`,
                                                          true
                                                        );
                                                        setFieldValue(
                                                          `newCity[${index}].to`,
                                                          ""
                                                        );
                                                        this.getDestinationLocationSuggestion(
                                                          evt.target.value
                                                        );
                                                      }}
                                                      onChange={(
                                                        evt,
                                                        value
                                                      ) => {
                                                        setFieldValue(
                                                          `newCity[${index}].to`,
                                                          JSON.stringify(value)
                                                        );
                                                        this.setState({
                                                          destinationSuggestionloading: false,
                                                        });
                                                      }}
                                                      renderOption={(value) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                flexGrow: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                gutterBottom
                                                                color="textPrimary"
                                                              >
                                                                {value.city}
                                                              </Typography>
                                                              <Typography color="textSecondary">
                                                                {value.name}
                                                              </Typography>
                                                            </div>
                                                            <Typography>
                                                              {value.iata}
                                                            </Typography>
                                                          </div>
                                                        );
                                                      }}
                                                      getOptionLabel={(value) =>
                                                        `${value.city},${value.country}(${value.iata})`
                                                      }
                                                      onBlur={() => {
                                                        setFieldTouched(
                                                          `newCity[${index}].to`,
                                                          true
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          fullWidth
                                                          placeholder="Enter Airport or City Name"
                                                          variant="outlined"
                                                          label="To"
                                                          InputLabelProps={{
                                                            ...params.InputLabelProps,
                                                            shrink: true,
                                                            classes: {
                                                              asterisk:
                                                                classes?.astriklabel,
                                                              outlined:
                                                                classes?.outlinedLabel,
                                                              shrink:
                                                                classes?.shrink,
                                                              root: classes?.lableroot,
                                                            },
                                                          }}
                                                          required
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            classes: {
                                                              input:
                                                                classes?.input,
                                                              root: classes?.inputfieledroot,
                                                            },
                                                            notched: false,
                                                            endAdornment: (
                                                              <React.Fragment>
                                                                {this.state
                                                                  .destinationSuggestionloading ? (
                                                                  <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                  />
                                                                ) : null}
                                                                {
                                                                  <InputAdornment position="end">
                                                                    <IconButton aria-label="search">
                                                                      <SearchIcon />
                                                                    </IconButton>
                                                                  </InputAdornment>
                                                                }
                                                              </React.Fragment>
                                                            ),
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                    {errors?.newCity &&
                                                      touched?.newCity &&
                                                      errors?.newCity[index]
                                                        ?.to &&
                                                      touched?.newCity[index]
                                                        ?.to && (
                                                        <Typography color="error">
                                                          {
                                                            errors?.newCity[
                                                              index
                                                            ]?.to
                                                          }
                                                        </Typography>
                                                      )}
                                                  </Grid>
                                                  <Grid xs={10} item>
                                                    <Field
                                                      component={InputFieled}
                                                      name={`newCity[${index}].depratureDate`}
                                                      type="date"
                                                      InputProps={{
                                                        inputProps: {
                                                          min: curDate,
                                                        },
                                                      }}
                                                      placeholder="Enter Departure Date"
                                                      fullWidth
                                                      label="Departure Date"
                                                    />
                                                  </Grid>
                                                  {index ===
                                                    values?.newCity.length -
                                                      1 && (
                                                    <Grid xs={10} item>
                                                      <Typography
                                                        className={
                                                          classes.addNewCity
                                                        }
                                                        gutterBottom
                                                        onClick={() => {
                                                          if (
                                                            values?.newCity
                                                              .length < 3
                                                          ) {
                                                            arrayHelpers.push({
                                                              from: "",
                                                              to: "",
                                                              depratureDate: "",
                                                            });
                                                          } else {
                                                            this.props.showAlert(
                                                              {
                                                                title: "Error",
                                                                message:
                                                                  "Maximum 3 city can be added",
                                                              }
                                                            );
                                                          }
                                                        }}
                                                        align="center"
                                                      >
                                                        Add Another City
                                                      </Typography>
                                                    </Grid>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )
                                          ) : (
                                            <Typography
                                              className={classes.addNewCity}
                                              gutterBottom
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  from: "",
                                                  to: "",
                                                  depratureDate: "",
                                                })
                                              }
                                              align="center"
                                            >
                                              Add Another City
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    />

                                    <Grid xs={10} item>
                                      <Box py={3}>
                                        <Button
                                          type="submit"
                                          fullWidth
                                          variant="contained"
                                          color="primary"
                                        >
                                          Search
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Form>
                              );
                            }}
                          </Formik>
                        </TabPanel>
                      </TabPanel>
                   
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
      </>
    );
  }
}

const NewSearchWithRouter = withRouter(NewSearch);
const NewSearchAlertBox = withAlertBox(NewSearchWithRouter);
const NewSearchLoader = withLoader(NewSearchAlertBox);
const NewSearchToast = withToast(NewSearchLoader);
export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
        borderRadius: "8px",
        backgroundColor: "#f8f8fa",
    },
    addNewCity: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    inputfieledroot: {
      "label + &": {
        marginTop: 30,
      },
    },
    input: {
      position: "relative",
      backgroundColor: "#FFF",
      border: "none!important",
      height: "35px!important",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#959ead",
      padding: "2.5px 4px!important",
    },
    astriklabel: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#959ead",
    },
    lableroot: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    outlinedLabel: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    shrink: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    agencyPendingRequestContainer: {
        borderRadius: "8px",
        boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
        border: "solid 1px #e5eaf4",
        backgroundColor: "#ffffff",
        padding: theme.spacing(1, 0),
      }
  })
)(withDialog(NewSearchToast));
