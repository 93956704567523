import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : string;
  profileData : any;
  role : any;
  permissions : Array<any>
  mobileOpen : boolean,
  profileDataWithId : any,
  loading : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AgancyDashboradController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProfileDetailApiCallID: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : "",
      profileData : {},
      role : "",
      permissions : [],
      mobileOpen : false,
      profileDataWithId : {},
      loading : true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getProfileDetailApiCallID) {
        this.props.hideLoader();
        if (responseJson?.agencies) {
            console.log('profile data of agency', responseJson)
            this.setState({...this.state,
                profileData : responseJson?.agencies?.data?.attributes,
                profileDataWithId : responseJson?.agencies?.data,
                loading : false
            })
        } else {
            this.parseApiErrorResponse(responseJson)
        }
    }
  } else if (getName(MessageEnum.AlertMessage) === message.id) {
    const title = message.getData(getName(MessageEnum.AlertTitleMessage));
    var AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );
    const msg  = await  this.props.showAlert({
      title: "Alert",
      message: AlertBodyMessage,
    })
    if(msg === 'Token has Expired' || msg === 'Invalid token' ){
      await StorageProvider.clearStorage();
      this.props.history.replace("/")
    }
  }
}

getProfileDetail = () => {
  this.props.showLoader();
  const headers = {
    "Content-Type": configJSON.ApiContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getProfileDetailApiCallID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.agencyGetProfileData}?token=${this.state.authToken}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }
}
