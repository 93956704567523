//@ts-nocheck
import React from "react";
import SubAgencyDetailsController, {
  Props,
} from "./SubAgencyDetailsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
} from "@material-ui/core";
import InputFiled from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withDialogBox from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import NewBookingFilterDialog from "./NewBookingFilterDialog.web";
import PhoneIcon from "@material-ui/icons/Phone";
import Rating from "@material-ui/lab/Rating";
import { hotelIcon, allIcon, flightIcon, hotelImage,agancyCover } from "./assets";
import FlightInfo from "./FlightInfo.web";
import HotelInfo from "./HotelInfo.web";

const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
  root: {
    fontSize: "1em",
  },
})(Rating);

class SubAgencyDetails extends SubAgencyDetailsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    let agancy = await StorageProvider.get("agancy")
    if (agancy) {
      agancy = agancy && JSON.parse(agancy);
      let authToken = await StorageProvider.get(agancy.tokenName || "agancyToken");
      this.setState(
        {
          token: authToken,
          id : (agancy)?.id || null,
          accountId : (agancy)?.attributes?.account_id || null,
          loading : false,
          subAgency : (agancy)
        },
        ()=>{
          this.getAgancy(this.state.id)
          if(agancy.tokenName === "agancyToken"){
            this.getProfileDetail()
          }
          this.getBookingsByApiAccountId(this.state.accountId);
        }
      );
    }
    
  }
  openBookingFilter = () => {
    this.props
      .openDialogBox({
        title: "",
        dataToPass: {},
        renderedComponent: NewBookingFilterDialog,
        withCustomDialog: true,
        catchOnCancel: true,
      })
      .then((data) => {
        console.log(data);
        // const { history } = this.props;
        //     history.push("/basicAuth/login");
      });
  };

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props
    history.push({
      state: { agencyData : {...data}},
      pathname: routeName,
    })
  }
  viewFlightDetail = (routeName,flightInfo) => {
    const { history } = this.props;
    const data = {searchData : {},
    entityDetail : {...flightInfo},
    fromCity : {},
    toCity : {},
    entityType : 'Flight',
    booking_id : flightInfo?.id,
    role : "Agency",
    travaller_id : flightInfo?.id
   }
    history.push({pathname : routeName,state: data});
  };

  render() {
    const { classes } = this.props;
    const hotelBooking = this.state.bookingList.filter((booking)=>{return booking?.data?.type 
      !== 'flight_booking'})
      const flightBooking = this.state.bookingList.filter((booking)=>{return booking?.data?.type 
        === 'flight_booking'})
    return (
      <Grid
        container
        justify="space-between"
        spacing={5}
        style={{ position: "relative", bottom: "120px" }}
      >
        <Grid item md={12} className={classes.card}>
          <Grid container spacing={2}>
            <Grid item md={9} style={{ marginTop: "15px" }}>
              <Typography
                style={{
                  fontFamily: "Public Sans",
                  fontSize: "32px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.25,
                  letterSpacing: "0.27px",
                  color: "#183b56",
                }}
              >
                {this.state?.agancyDetail?.agency_name  || "Not Available"}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.75,
                  letterSpacing: "0.27px",
                  color: "#5a7184",
                }}
              >
                {this.state?.agancyDetail?.email  || "Not Available"}
              </Typography>
            </Grid>
            <Grid item md={2} style={{ marginTop: "15px" }}>
              {this.state?.subAgency?.tokenName === 'agancyToken' && (<Button
                variant="contained"
                color="primary"
                style={{ width: "142px", height: "48px" }}
                onClick={() => this.goToSelectedRoute('/subAgencyDashboard/editAgencyDetails', this.state.subAgency)}
              >
                <Typography
                style={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    color: "#fff",
                  }}>EDIT</Typography>
              </Button>)}
              
            </Grid>
            <Grid
              item
              md={11}
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <Divider variant="middle" />
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Bookings Today"
                  secondary={`${this.state?.agancyDetail?.flight_booking_today || 0} flights`}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Status"
                  secondary= {this.state?.agancyDetail?.status  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Contact Details"
                  secondary= {this.state?.agancyDetail?.agency_phone_numbeer  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Address"
                  secondary= {this.state?.agancyDetail?.agency_address  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Company Registration"
                  secondary= {this.state?.agancyDetail?.business_registration_number  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="VAT/PAN"
                  secondary= {this.state?.agancyDetail?.pan_number  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Permission (NTB) License No."
                  secondary= {this.state?.agancyDetail?.permission_ntb_number  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Credits"
                  secondary= {this.state?.agancyDetail?.balance  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={11}>
              <Divider />
            </Grid>
            <Grid item md={12}>
              <Typography
                style={{
                  fontFamily: "Public Sans",
                  fontSize: "24px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.33,
                  letterSpacing: "0.2px",
                  color: "#183b56",
                }}
              >
                Markups
              </Typography>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Flights"
                  secondary= {this.state?.agancyDetail?.flightMarkup  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Hotels"
                  secondary= {this.state?.agancyDetail?.hotelMarkup  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item md={4}>
              <ListItem dense={true}>
                <ListItemText
                  primary="Total Earnings From This Subagency"
                  secondary= {this.state?.agancyDetail?.totalEarings  || "Not Available"}
                  classes={{
                    primary: classes.listItemPrimary,
                    secondary: classes.listItemSecondary,
                  }}
                />
              </ListItem>
            </Grid>
          </Grid>
        </Grid>
        {this.state?.subAgency?.tokenName === 'agancyToken' && (<Grid item md={12}>
                <Grid container spacing={2} justify="space-around">
                <Grid item xs={12}>
                  <Grid container justify='center' alignItems='center' style={{
                    height: '100px',
                    borderRadius: '8px',
                    backgroundColor: '#f8f8fa',
                  }}>
                    <Grid item xs={11}>
                         <div style={{display : 'flex'}}>
                            <div style={{flexGrow : 1}}>
                            <Typography className={classes.agencyName}>
                                Assign Credits
                             </Typography>      
                            </div>
                            <Typography className={classes.agencyName}>
                                Your Balance : {`${this.state?.profileData?.credt_limit?.data?.attributes?.available_limit || "Not Available"}`}
                             </Typography>      
                         </div>
                          

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                    <TextField
                      variant='outlined'
                      fullWidth
                      type='number'
                      value={this.state.creditTextField}
                      placeholder='Enter credits to be assigned'
                      onChange={(evt) => {
                        this.setState({
                          creditTextField: evt.target.value
                        })
                      }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                    <Button
                      variant='contained'
                      fullWidth
                      color='primary'
                      disabled={!this.state.creditTextField}
                      onClick={() => {
                        this.assignCredits(this.state.id)
                      }}
                        >
                          Assign
                        </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                </Grid>
        )}
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{ borderRadius: "8px", backgroundColor: "#f8f8fa" }}
          >
            <Tabs
              value={this.state.selectedType}
              onChange={(evt, value) => {
                this.setState(
                  {
                    selectedType: value,
                  },
                  () => {
                    this.getBookingsByApiAccountId(this.state.accountId);
                  }
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab
                label="All"
                classes={{ root: classes.tabLabel }}
                value={"All"}
                icon={
                  <img
                    style={{ width: "23px", height: "32px" }}
                    src={allIcon}
                  />
                }
              />
              <Tab
                label="Hotels"
                classes={{ root: classes.tabLabel }}
                value={"Hotels"}
                icon={
                  <img
                    style={{ width: "34px", height: "23px" }}
                    src={hotelIcon}
                  />
                }
              />
              <Tab
                label="Flights"
                classes={{ root: classes.tabLabel }}
                value={"Flights"}
                icon={
                  <img
                    style={{ width: "30px", height: "34px" }}
                    src={flightIcon}
                  />
                }
              />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify={"space-between"} spacing={2}>
            <Grid item xs={10}>
              <TextField
                name="search"
                placeholder="Try searching by PNR, booking ID, Passengers, hotel, airline or destination"
                variant="outlined"
                onChange={(evt) => {
                  this.setState({
                    search: evt.target.value,
                  });
                }}
                value={this.state.search}
                fullWidth
                label="Search"
              />
            </Grid>
            <Grid xs={2} item>
              <Button
                onClick={() => {
                  this.openBookingFilter();
                }}
                variant="contained"
                color="primary"
              >
                <PhoneIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Tabs
              value={this.state.selectedTime}
              onChange={(evt, value) => {
                this.setState(
                  {
                    selectedTime: value,
                  },
                  () => {
                    this.getBookingsByApiAccountId(this.state.accountId);
                  }
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab
                value={"upcoming"}
                classes={{ root: classes.tabLabel }}
                label="Upcoming"
              />
              <Tab
                value={"completed"}
                classes={{ root: classes.tabLabel }}
                label="Completed"
              />
              <Tab
                value={"cancelled"}
                classes={{ root: classes.tabLabel }}
                label="Cancelled"
              />
            </Tabs>
          </Paper>
        </Grid>
        <>
            {this.state.selectedType === "All" ? (
              <>
              {
               this.state.bookingList.length > 0 ? <>
               {hotelBooking.length > 0 && hotelBooking.map((hotel,index)=>{
                 return <Grid xs={12} item key={index}>
                  <HotelInfo goToSelectedRoute={this.goToSelectedRoute}></HotelInfo>
                    </Grid>
                })
               }
               {flightBooking.length > 0 && flightBooking.map((flight,index)=>{
                 return <Grid xs={12} item key={index}>
                        <FlightInfo flightDetail={flight?.data?.attributes} goToSelectedRoute={()=>{this.viewFlightDetail("/myBookings/Flight",flight?.data?.attributes)}}></FlightInfo>
                    </Grid>
                })
               }
             </> : <Grid xs={12} item className={classes.abc}>
                <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
              </Grid>
              }
              </>
              
            ) : this.state.selectedType === "Hotels" ? (
              <>
              {
               this.state.bookingList.length > 0 ? this.state.bookingList.map(hotel => (
                <Grid xs={12} item key={hotel}>
                    <HotelInfo goToSelectedRoute={this.goToSelectedRoute}></HotelInfo>
                </Grid>
              )) : <Grid xs={12} item className={classes.abc}>
                <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
              </Grid>
              }
              </>
            ) : 
            
            this.state.selectedType === "Flights" ? (
              <>
              {
               this.state.bookingList.length > 0 ? this.state.bookingList.map(flight => (
                <Grid xs={12} item key={flight?.data.id}>
                    <FlightInfo flightDetail={flight?.data?.attributes} goToSelectedRoute={()=>{this.viewFlightDetail("/myBookings/Flight",flight?.data?.attributes)}}></FlightInfo>
                </Grid>
              )) : <Grid xs={12} item className={classes.abc}>
                <Typography color="textPrimary" variant="h5" align="center">{"No Booking found"}</Typography>
              </Grid>
              }
              </>
            ) : <></>}
          </>      
        </Grid>
    );
  }
}
const SubAgencyDetailsWithRouter = withRouter(SubAgencyDetails);
const SubAgencyDetailsAlertBox = withAlertBox(SubAgencyDetailsWithRouter);
const SubAgencyDetailsLoader = withLoader(SubAgencyDetailsAlertBox);
const SubAgencyDetailsToast = withToast(SubAgencyDetailsLoader);
const SubAgencyDetailswithDialogBox = withDialogBox(SubAgencyDetailsToast);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "300px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "250px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    appBar: {
      background: "transparent",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 128,
      [theme.breakpoints.down("xs")]: {
        height: 128,
      },
    },
    hotelOptionalImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 72,
    },
    hotelNameHeadingContainer: {
      flexWrap: "wrap",
    },
    hotelNameTitle: {
      flexGrow: 1,
    },
    footerLinkHeading: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    hotelName: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    hotelAddress: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: " ",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: " ",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    logo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
    tabLabel: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: 'bold',
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: "#183b56",
      textTransform: 'capitalize'
    },
    card: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#fff",
      marginBottom: "30px",
    },
    listItemPrimary: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemSecondary: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
  })
)(SubAgencyDetailswithDialogBox);
