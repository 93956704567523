//@ts-nocheck

//muspamirdo@yevme.com

import React from "react";
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  DialogContent,
} from "@material-ui/core";
import { forgotBackground, forgotLogo, signUPPoster } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required."),
  // .required("This field is required."),
});

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              this.handlePasswordReset(values);
            }}
          >
            {(formikProps) => {
              const { values, isValid, dirty } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <Grid
                    container
                    justify="space-between"
                    style={{ padding: "0px", overflow: "hidden" }}
                  >
                    <Grid
                      item
                      md={6}
                      xs={false}
                      className={classes.dailogBackground}
                    >
                      {/* <Box py={8}> */}
                      <Grid container justify="center" alignItems="center">
                        <Grid xs={10} className={classes.loginImageGrid}>
                          <img
                            src={forgotLogo}
                            className={classes.loginImage}
                          />
                        </Grid>
                      </Grid>
                      {/* </Box> */}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box py={8}>
                        <Grid container justify="center" spacing={4}>
                          <Grid item xs={10}>
                            <Typography
                              className={classes.welcomeBack}
                              gutterBottom
                            >
                              Forgot Password?
                            </Typography>
                            <Typography
                              className={classes.labelText}
                              gutterBottom
                            >
                              Enter your email to reset your password
                            </Typography>
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              component={InputField}
                              fullWidth
                              label="Email Address"
                              name="email"
                              placeholder="Enter Email Address"
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              fullWidth
                              disabled={!(isValid && dirty)}
                              // onClick={() => {
                              //   this.closeDialog("enterOtp");
                              // }}
                            >
                              SEND OTP
                            </Button>
                          </Grid>
                          <Grid item xs={10}>
                            <div style={{ textAlign: "center" }}>
                              <Typography
                                align="center"
                                onClick={() => {
                                  this.closeDialog({ type: "signIN" });
                                }}
                                className={classes.buttonText}
                                display="inline"
                              >
                                Back to Login
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>

        {/* <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            this.handlePasswordReset(values);
          }}
        >
          {(formikProps) => {
            const { values, isValid, dirty } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2} justify="space-between">
                  <Grid
                    item
                    md={6}
                    xs={false}
                    className={classes.dialogImage}
                  />
                  <Grid item md={6} xs={12}>
                    <Box py={8}>
                      <Grid container justify="center" spacing={4}>
                        <Grid item xs={12}>
                          <Typography
                            align="center"
                            className={classes.welcomeBack}
                            gutterBottom
                          >
                            Forgot Password
                          </Typography>
                          <Typography
                            align="center"
                            className={classes.labelText}
                            gutterBottom
                          >
                            Enter your email to reset your password
                          </Typography>
                        </Grid>

                        <Grid item xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Email Address"
                            name="email"
                            placeholder="Enter Email Address"
                          />
                        </Grid>

                        <Grid item xs={10} >
                          <Typography>{" "}</Typography>
                        </Grid>
                        <Grid item xs={10} >
                          <Typography>{" "}</Typography>
                        </Grid>
                        <Grid item xs={10} >
                          <Typography>{" "}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={!(isValid && dirty)}
                          >
                            Send Reset Link
                          </Button>
                        </Grid>
                        <Grid item xs={10}>
                          <div style={{ textAlign: "center" }}>
                            <Typography
                              align="center"
                              onClick={() => {
                                this.closeDialog("signIN");
                              }}
                              className={classes.buttonText}
                              display="inline"
                            >
                              Back to Login
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent> */}
      </>
    );
  }
}

const ForgotPasswordWithRouter = withRouter(ForgotPassword);
const ForgotPasswordWithToast = withToast(ForgotPasswordWithRouter);
const ForgotPasswordWithLoader = withLoader(ForgotPasswordWithToast);
const ForgotPasswordWithAlertBox = withAlertBox(ForgotPasswordWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundImage: `url(${signUPPoster})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: "pointer",
    },
    loginImage: {
      width: "15rem",
      height: "100%",
    },
    loginImageGrid: {
      margin: "auto",
      marginTop: "35%",
    },
    dailogBackground: {
      backgroundImage: `url(${forgotBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100%",
      padding: "44px",
      overflow: "hidden",
    },
  })
)(ForgotPasswordWithAlertBox);
