// @ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";

const withFontStyles = (wrappedComponent) => {
  return withStyles((theme) =>
    createStyles({
      //Public Sans styles
      groteskBold18: {
        fontFamily: "Public Sans",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.22,
        letterSpacing: "0.2px",
      },
      groteskBold20: {
        fontFamily: "Public Sans",
        fontSize: "18px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.2,
        letterSpacing: "0.2px",
      },
      groteskBold24: {
        fontFamily: "Public Sans",
        fontSize: "22px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "0.2px",
      },
      groteskBold32: {
        fontFamily: "Public Sans",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "0.27px",
      },
      groteskBold36: {
        fontFamily: "Public Sans",
        fontSize: "34px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "0.2px",
      },
      groteskBold54: {
        fontFamily: "Public Sans",
        fontSize: "34px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.21",
        letterSpacing: "0.2px",
        color: "#ffffff",
      },

      //Open Sans styles
      // openSans14: {
      //   fontFamily: "Open Sans",
      //   fontSize: "12px",
      //   fontWeight: "normal",
      //   fontStretch: "normal",
      //   fontStyle: "normal",
      //   lineHeight: "normal",
      //   letterSpacing: "normal",
      // },
      openSans16: {
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.75,
        letterSpacing: "normal",
      },
      openSans18: {
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.78,
        letterSpacing: "normal",
      },
      openSans20: {
        fontFamily: "Open Sans",
        fontSize: "18px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.8,
        letterSpacing: "normal",
      },
      openSans20_SemiBold: {
        fontFamily: "Open Sans",
        fontSize: "18px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.1,
        letterSpacing: "0.22px",
      },
      openSansBold24: {
        fontFamily: "Open Sans",
        fontSize: "22px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
      },
      //Button Text styles
      btnGrotesk20: {
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.2",
        letterSpacing: "0.2px",
        textAlign: "center",
        textTransform: "capitalize",
      },
      containedButton: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25",
        letterSpacing: "normal",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#ffffff",
      },
      gutterVerticle: {
        marginTop: "0.35em",
        marginBottom: "0.35em",
      },
      gutterVerticle2: {
        marginTop: "0.75em",
        marginBottom: "0.75em",
      },
      gutterVerticle3: {
        marginTop: "1.5em",
        marginBottom: "1.5em",
      },
      gutterBottom: {
        marginBottom: "0.35em",
      },
      gutterBottom2: {
        marginBottom: "0.75em",
      },
      gutterBottom3: {
        marginBottom: "1.5em",
      },
      gutterTop: {
        marginTop: "0.35em",
      },
      gutterTop2: {
        marginTop: "0.75em",
      },
      gutterTop3: {
        marginTop: "1.5em",
      },
      whiteText: {
        color: "#fff",
      },
      xsHidden: {
        [theme.breakpoints.down("xs")]: {
          display: 'none',
        },
      },
      mdHidden: {
        [theme.breakpoints.up("md")]: {
          display: 'none',
        },
      }
    })
  )(wrappedComponent);
};

export default withFontStyles;
