//@ts-nocheck
import React from "react";
import AdminDashboardController, {
  Props,
} from "./AdminDashboardController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
  Avatar,
  IconButton,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import InputFiled from "../../../components/src/InputFiled.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import PhoneIcon from "@material-ui/icons/Phone";
import { withRouter, Route, Switch, Redirect } from "react-router";
import MyBookings from "./myBookings.web";
import AllSubAgencies from "./AllSubAgencies.web";
import NewSearch from "./NewSearch.web";
import { NavLink } from "react-router-dom";
import Header from "./Header.web";
import Footer from "./Footer.web";
import PrivateRoute from "../../../components/src/PrivateRoute.web";
import AdminPrivateRoute from "../../../components/src/AdminPrivateRoute.web";
import { hotelImage, agancyCover } from "./assets";
import AllSubagancyList from "./AllSubagancyList.web";
import TeamMemberlist from "./TeamMemberlist.web";
import CreadiTransaction from "./CreadiTransaction.web";
import Invoices from "./Invoices.web";
import Policies from "./Policies.web";
import AllCouponsList from './AllCouponsList.web.tsx'
import CustomerList from "./CustomerList.web";
import Report from "./Report.web";
import AddNewCoupon from "./AddNewCoupon.web";
import Markup from "./Markup.web.tsx"
import withFontStyles from "./withFontStyles.web";
import AllOffersList from "./AllOffersList.web";
import AddNewPolicy from "./AddNewPolicy.web";
const drawerWidth = 240;
class AdminDashboard extends AdminDashboardController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const adminToken = await StorageProvider.get("adminToken");
    if (adminToken) {
      this.setState(
        {
          token: adminToken,
        },
        () => {}
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${agancyCover})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={agancyCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header role="Admin" />
          <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              SlideProps={{ unmountOnExit: true }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              <Grid container>
                <Grid item xs={10}>
                  <Paper elevation={0} className={classes.sidebarAboutBox}>
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/AdminDashboard/NewSearch");
                      }}
                    >
                      New Search
                    </Typography>
                    <Divider />
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                    >
                      Global
                    </Typography>
                    {[
                      {
                        title: "My Bookings",
                        url: "/AdminDashboard/myBooking",
                      },
                      {
                        title: "Customer Bookings",
                        url: "/AdminDashboard/customerBookings",
                      },
                      {
                        title: "Customer Booking Reports",
                        url: "/AdminDashboard/customerBookingReports",
                      },
                      { title: "Customers", url: "/AdminDashboard/Customers" },
                      { title: "Invoices", url: "/AdminDashboard/invoices" },
                      { title: "Team", url: "/AdminDashboard/team" },
                      { title: "Credits", url: "/AdminDashboard/credits" },
                      { title: "Markups", url: "/AdminDashboard/markups" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                    <Divider />
                    <Typography
                      gutterBottom
                      className={classes.sideLinkHeading}
                    >
                      Agencies/Subagencies
                    </Typography>
                    {[
                      {
                        title: "All Agencies & Subagencies",
                        url: "/AdminDashboard/Allagenciesandsubagencies",
                      },
                      {
                        title: "Agencies & Subagencies Bookings",
                        url: "/AdminDashboard/agenciesSubagenciesBookings",
                      },
                      {
                        title: "Agencies & Subagencies Reports",
                        url: "/AdminDashboard/agenciesSubagenciesReports",
                      },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                    <Divider />
                    <Typography
                      gutterBottom
                      className={classes.sideLinkHeading}
                    >
                      Content
                    </Typography>
                    {[
                      { title: "Content", url: "/AdminDashboard/content" },
                      { title: "Marketing ", url: "/AdminDashboard/marketing" },
                      {
                        title: "Notifications",
                        url: "/AdminDashboard/notifications",
                      },
                      { title: "Policies", url: "/AdminDashboard/policies" },
                      { title: "Coupons", url: "/AdminDashboard/coupons" },
                      { title: "Offers", url: "/AdminDashboard/offers" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>

          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  Hello Admin
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={10}>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                className={classes.mainPostSecondaryContent}
              >
                <Grid item xs={6} md={2}>
                  <ListItem>
                    <ListItemText
                      primary="Sasto Travels"
                      secondary="Not Avaialble"
                      classes={{
                        primary: classes.primartText,
                        secondary: classes.secondaryText,
                      }}
                    />
                  </ListItem>
                </Grid>
                <Hidden xsDown>
                  <Grid item md="auto">
                    <div
                      style={{
                        width: "1px",
                        height: "50px",
                        opacity: "0.62",
                        backgroundColor: "#979797", 
                      }}
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={6} md={7}>
                  {/* <ListItem>
                    <ListItemText
                      primary="Account Balance In Credits"
                      secondary="Not Avaialble"
                      classes={{
                        primary: classes.primartText,
                        secondary: classes.secondaryText,
                      }}
                    />
                  </ListItem> */}
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button color="primary" variant="contained">
                    Edit Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          justify="center"
          style={{ marginTop: "120px" }}
        >
          <Hidden xsDown>
            <Grid xs={3} item>
              <Grid container>
                <Grid item xs={10}>
                  <Paper elevation={0} className={classes.sidebarAboutBox}>
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push("/AdminDashboard/NewSearch");
                      }}
                    >
                      New Search
                    </Typography>
                    <Divider />
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.sideLinkHeading}
                    >
                      Global
                    </Typography>
                    {[
                      {
                        title: "My Bookings",
                        url: "/AdminDashboard/myBooking",
                      },
                      {
                        title: "Customer Bookings",
                        url: "/AdminDashboard/customerBookings",
                      },
                      {
                        title: "Customer Booking Reports",
                        url: "/AdminDashboard/customerBookingReports",
                      },
                      { title: "Customers", url: "/AdminDashboard/Customers" },
                      { title: "Invoices", url: "/AdminDashboard/invoices" },
                      { title: "Team", url: "/AdminDashboard/team" },
                      { title: "Credits", url: "/AdminDashboard/credits" },
                      { title: "Markups", url: "/AdminDashboard/markups" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                    <Divider />
                    <Typography
                      gutterBottom
                      className={classes.sideLinkHeading}
                    >
                      Agencies/Subagencies
                    </Typography>
                    {[
                      {
                        title: "All Agencies & Subagencies",
                        url: "/AdminDashboard/Allagenciesandsubagencies",
                      },
                      {
                        title: "Agencies & Subagencies Bookings",
                        url: "/AdminDashboard/agenciesSubagenciesBookings",
                      },
                      {
                        title: "Agencies & Subagencies Reports",
                        url: "/AdminDashboard/agenciesSubagenciesReports",
                      },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                    <Divider />
                    <Typography
                      gutterBottom
                      className={classes.sideLinkHeading}
                    >
                      Content
                    </Typography>
                    {[
                      { title: "Content", url: "/AdminDashboard/content" },
                      { title: "Marketing ", url: "/AdminDashboard/marketing" },
                      {
                        title: "Notifications",
                        url: "/AdminDashboard/notifications",
                      },
                      { title: "Policies", url: "/AdminDashboard/policies" },
                      { title: "Coupons", url: "/AdminDashboard/coupons" },
                      { title: "Offers", url: "/AdminDashboard/offers" },
                    ].map((archive) => (
                      <NavLink
                        to={archive.url}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={archive.title}
                      >
                        {archive.title}
                      </NavLink>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid xs={10} md={7} item>
            <Switch>
              <AdminPrivateRoute path="/AdminDashboard/NewSearch">
                <NewSearch tokenName="adminToken" role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/myBooking" exact>
                <MyBookings tokenName="adminToken" role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/Customers" exact>
                <CustomerList />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/customerBookings" exact>
                <MyBookings
                  tokenName="adminToken"
                  role="Admin"
                  apiType="customerBookings"
                />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/customerBookings/:accountId"
                exact
              >
                <MyBookings tokenName="adminToken" role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/customerBookingReports"
                exact
              >
                <Report
                  tokenName="adminToken"
                  role="Customer"
                  // reportType = 'customer'
                  apiType="agenciesSubagenciesReport"
                />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/invoices" exact>
                <Invoices tokenName="adminToken" role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/team" exact>
                <TeamMemberlist tokenName="adminToken" role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/credits" exact>
                <CreadiTransaction tokenName="adminToken" role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/markups" exact>
                <Markup />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/Allagenciesandsubagencies"
                exact
              >
                <AllSubAgencies />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/SubagancyList" exact>
                <AllSubagancyList
                  tokenName="adminToken"
                  role="Admin"
                  apiType="accountId"
                />
              </AdminPrivateRoute>

              <AdminPrivateRoute
                path="/AdminDashboard/agenciesSubagenciesBookings"
                exact
              >
                <MyBookings
                  tokenName="adminToken"
                  role="Admin"
                  apiType="agenciesSubagenciesBookings"
                />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/agenciesSubagenciesReports"
                exact
              >
                <Report
                  tokenName="adminToken"
                  role="Admin"
                  apiType="agenciesSubagenciesReport"
                />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/content" exact>
                <MyBookings />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/marketing" exact>
                <MyBookings />
              </AdminPrivateRoute>
              <AdminPrivateRoute path="/AdminDashboard/notifications" exact>
                <MyBookings />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/policies"
                exact
              >
                <Policies tokenName="adminToken"
                role="Admin"
                 />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/addNewPolicy"
                exact
              >
                <AddNewPolicy tokenName="adminToken"
                role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                
                path="/AdminDashboard/editPolicy"
                exact
              >
                <AddNewPolicy tokenName="adminToken"
                role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                
                path="/AdminDashboard/coupons"
                exact
              >
                <AllCouponsList tokenName="adminToken"
                role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                path="/AdminDashboard/addNewCoupon"
                exact
              >
                <AddNewCoupon tokenName="adminToken"
                role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                
                path="/AdminDashboard/editCoupon"
                exact
              >
                <AddNewCoupon tokenName="adminToken"
                role="Admin" />
              </AdminPrivateRoute>
              <AdminPrivateRoute
                tokenName="adminToken"
                role="Admin"
                path="/AdminDashboard/offers"
                exact
              >
                <AllOffersList />
              </AdminPrivateRoute>              
              <Redirect
                exact
                from="/AdminDashboard"
                to="/AdminDashboard/myBooking"
              />
            </Switch>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}
const AdminDashboardControllerWithRouter = withRouter(AdminDashboard);
const AdminDashboardControllerAlertBox = withAlertBox(
  AdminDashboardControllerWithRouter
);
const AdminDashboardControllerLoader = withLoader(
  AdminDashboardControllerAlertBox
);
const AdminDashboardControllerToast = withToast(AdminDashboardControllerLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [theme.breakpoints.down("xs")]: {
        height: "440px",
      },
      [theme.breakpoints.up("md")]: {
        height: "345px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(2),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "220px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    appBar: {
      background: "transparent",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      margin: theme.spacing(0, 1),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },

    sideLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      padding: theme.spacing(1, 1.5),
    },
    sideLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: "left",
      display: "block",
      textDecoration: "none",
      padding: theme.spacing(1.5),
    },
    activeSideLink: {
      backgroundColor: "#fff",
      color: "#1565d8",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.down("xs")]: {
        height: 128,
      },
    },
    hotelNameHeadingContainer: {
      flexWrap: "wrap",
    },
    hotelNameTitle: {
      flexGrow: 1,
    },
    footerLinkHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: " #0d2436",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    hotelName: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    hotelAddress: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    date: {
      fontFamily: "OpenSans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "HKGrotes",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "OpenSans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: "OpenSans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: "OpenSans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    logo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
  })
)(AdminDashboardControllerToast);
