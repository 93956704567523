//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
  MenuItem,
} from "@material-ui/core";
// import { Chart, registerables } from "chart.js";
// Chart.register(...registerables);

import InputFiled from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withDialogBox from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import NewBookingFilterDialog from "./NewBookingFilterDialog.web";
import agancyReportController from "./ReportController";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();

class agancyReport extends agancyReportController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get(this.props.tokenName);
    let agencyDetail = await StorageProvider.get("agencyDetail");
        agencyDetail = agencyDetail && JSON.parse(agencyDetail);
    if (authToken) {
      this.setState({
        token: authToken,
        agencyDetail: (agencyDetail),
        accountId: agencyDetail?.account_id,
      },()=>{
        if(this.props.role === 'Customer'){
          this.getCustomerReports();
        } else {
          this.getTodayAndTotalBookings();
          this.getBookedAirlines();
          this.getTotalMonthlyBookings();
          this.getCancelledBookings();
        }
      });
    }
    
  }
  componentDidUpdate(prevProps, prevState){
    if(this.props.role !== prevProps.role){
      if(this.props.role === 'Customer'){
        this.getCustomerReports();
      } else {
        this.getTodayAndTotalBookings();
        this.getBookedAirlines();
        this.getTotalMonthlyBookings();
        this.getCancelledBookings();
      }
    }
    
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;
    return (
        <Grid container spacing={4}>
          <Grid item xs={10} className={classes.agancyReportBox}>
            <Typography gutterBottom className={classes.agancyReportTitle}>
              Reports
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {/* <Box mt={2}> */}
              <Grid container justify="space-between" spacing={4}>
                {this.props.role === 'Customer' ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyReportContainer}>
                    <Typography
                      gutterBottom
                      className={classes.agancyReportTitle}
                    >
                      Customers
                    </Typography>
                    <Grid container justify="space-around" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Listed
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.customerReports?.customers_stats?.total_count || 0}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        {this.state.customerReports?.customers_stats?.total_bookings_count  || 0}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Today Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        {this.state.customerReports?.customers_stats?.today_bookings_count  || 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                ) : <></>}
                {this.props.role === 'Admin' ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyReportContainer}>
                    <Typography
                      gutterBottom
                      className={classes.agancyReportTitle}
                    >
                      Agencies     
                    </Typography>
                    <Grid container justify="space-around" spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Listed
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.agencies_and_sub_agencies_stats?.agencies_total_count || 0}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Bookings Done
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agencies_and_sub_agencies_stats?.total_bookings_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                ) : null}
                {this.props.role === 'Agency'  ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyReportContainer}>
                    <Typography
                      gutterBottom
                      className={classes.agancyReportTitle}
                    >
                      Agency
                    </Typography>
                    <Grid container justify="space-around" >
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Subagencies Listed
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.agency_stats?.sub_agencies_stats?.total_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agency_stats?.total_bookings_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          By Subagencies
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agency_stats?.sub_agencies_stats?.total_bookings_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                ) : null}
                {this.props.role === 'SubAgencies'  ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyReportContainer}>
                    <Typography
                      gutterBottom
                      className={classes.agancyReportTitle}
                    >
                      Subagencies
                    </Typography>
                    <Grid container justify="space-around" spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Listed
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.agency_stats?.sub_agencies_stats?.total_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.agency_stats?.sub_agencies_stats?.total_bookings_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Today Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.agency_stats?.sub_agencies_stats?.today_bookings_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                ) : null}
                {this.props.role === 'SubAgency'  ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyReportContainer}>
                    <Typography
                      gutterBottom
                      className={classes.agancyReportTitle}
                    >
                      Subagencies
                    </Typography>
                    <Grid container justify="space-around" spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.sub_agency_stats?.total_bookings_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom>
                          Today Bookings
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.todayAndTotalBookingsData?.sub_agency_stats?.today_bookings_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                ) : null}
                {this.props.role === 'Admin' ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyAllReportContainer}>
                    <Grid container justify="space-around" spacing={0}>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Total Bookings
                        </Typography>
                        <Typography variant="h2" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agencies_and_sub_agencies_stats?.total_bookings_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          Today
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agencies_and_sub_agencies_stats?.today_bookings_count}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={5}>
                        <Typography variant="subtitle2" gutterBottom>
                          By Subagencies
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                          1000
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </Paper>
                </Grid>
                ): null}
                {this.props.role === 'Agency' ? (
                  <Grid md={6} xs={12} item>
                  <Paper className={classes.agancyAllReportContainer}>
                    <Grid container justify="space-around" spacing={1}>
                      
                      <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          Today by agency
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agency_stats?.today_bookings_count}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          Today by subagencies
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                        {this.state.todayAndTotalBookingsData?.agency_stats?.sub_agencies_stats?.today_bookings_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                ): null}
              </Grid>
            {/* </Box> */}
          </Grid>
          <Grid item xs={10}>
            <Box mt={2} p={4} className={classes.agancyReportContainer}>
              <Typography gutterBottom className={classes.agancyReportTitle}>
                Bookings
              </Typography>
              <Divider />
              <Grid container>
                        <Grid container spacing={2}>
                          <Grid xs={4} item>
                            <InputFiled
                              type="date"
                              InputProps={{
                                inputProps: {
                                  max: curDate,
                                },
                              }}
                              placeholder="Enter Start Date"
                              onChange={(e) => {
                                this.setState({bookingStartDate: e.target.value})
                              }}
                              value={this.state.bookingStartDate}
                              fullWidth
                              label="Start Date"
                            />
                          </Grid>
                          <Grid xs={4} item>
                            <InputFiled
                              type="date"
                              placeholder="Enter End Date"
                              InputProps={{
                                inputProps: {
                                  max: curDate,
                                },
                              }}
                              onChange={(e) => {
                                this.setState({bookingEndDate: e.target.value})
                              }}
                              value={this.state.bookingEndDate}
                              fullWidth
                              label="End Date"
                            />
                          </Grid>
                          
                          <Grid xs={4} item>
                            <InputFiled
                              select
                              fullWidth
                              onChange={(e) => {
                                this.setState({bookingAirline: e.target.value},()=>{
                                      // if(this.props.role === 'Customer'){
                                      //   this.getCustomerReports(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                      // } else {
                                      //   this.getTodayAndTotalBookings();
                                      //   this.getBookedAirlines();
                                      //   this.getTotalMonthlyBookings(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                      //   this.getCancelledBookings(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                      // } 
                                })
                              }}
                              value={this.state.bookingAirline}
                              label="Airline"
                            >
                              {
                                this.props.role === 'Agency' ? (
                                  this.state.bookedAirlines?.agency_stats?.booked_airlines.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                ) :
                                this.props.role === 'Admin' ? (
                                  this.state.bookedAirlines?.agencies_and_sub_agencies_stats?.booked_airlines.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                ) : this.props.role === 'Customer' ? (
                                  this.state.customerReports?.customers_stats?.booked_airlines?.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  this.state.bookedAirlines?.agency_stats?.sub_agencies_stats?.booked_airlines.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                )
                              }
                              
                            </InputFiled>
                          </Grid>
                          <Grid item xs={8}>
                          
                              <Button variant="contained" color="primary" onClick={()=>{
                                    if(new Date(this.state.bookingStartDate).getMonth() + "" + new Date(this.state.bookingStartDate).getFullYear() !== new Date(this.state.bookingEndDate).getMonth() + "" + new Date(this.state.bookingEndDate).getFullYear()){
                                      if(this.props.role === 'Customer'){
                                        this.getCustomerReports(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                      } else {
                                        this.getTodayAndTotalBookings();
                                        // this.getBookedAirlines();
                                        this.getTotalMonthlyBookings(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                        this.getCancelledBookings(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                      } 
                                    } else {
                                      this.props.showToast({type : 'error',message : "start date month and end date month can't be same"})
                                    }
                              }}>
                                  Apply
                              </Button>
                              <Button style={{marginLeft : '16px'}} onClick={()=>{
                                this.setState({
                                  bookingStartDate : "",
                                  bookingEndDate : "",
                                  bookingAirline : null
                                },()=>{
                                  // if(new Date(this.state.bookingStartDate).getMonth() + "" + new Date(this.state.bookingStartDate).getFullYear() !== new Date(this.state.bookingEndDate).getMonth() + "" + new Date(this.state.bookingEndDate).getFullYear()){
                                    if(this.props.role === 'Customer'){
                                      this.getCustomerReports(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                    } else {
                                      this.getTodayAndTotalBookings();
                                      // this.getBookedAirlines();
                                      this.getTotalMonthlyBookings(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                      this.getCancelledBookings(this.state.bookingStartDate,this.state.bookingEndDate,this.state.bookingAirline);
                                    } 
                                  // } else {
                                  //   this.props.showToast({type : 'error',message : "start date month and end date month can't be same"})
                                  // }
                                })
                              }} variant="outlined" color="primary">
                                  Reset
                              </Button>
                       
                          </Grid>
                          </Grid>
                <Grid item xs={12}>
                  <canvas id="barChart" style={this.state.showBarChart ? {display : 'block'} : {display : 'none'}}/>
                </Grid>
                <Grid item xs={12}>
                    <div style={this.state.showBarChart ? {display : 'none',textAlign:'center'} : {display : 'block',textAlign:'center'}}>
                       <Typography align="center" style={{lineHeight : '200px'}} >No Booking Available</Typography>
                    </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box mt={2} p={4} className={classes.agancyReportContainer}>
              <Typography gutterBottom className={classes.agancyReportTitle}>
                Cancellation
              </Typography>
              <Divider />
              <Grid container>
                
                        <Grid container spacing={2}>
                        <Grid xs={3} item>
                            <InputFiled
                              type="date"
                              InputProps={{
                                inputProps: {
                                  max: curDate,
                                },
                              }}
                              placeholder="Enter Start Date"
                              onChange={(e) => {
                                this.setState({cancellationStartDate: e.target.value},()=>{
                                  // if(this.state.cancellationEndDate !== null){
                                  //   if(new Date(this.state.cancellationStartDate).getMonth() + "" + new Date(this.state.cancellationStartDate).getFullYear() !== new Date(this.state.cancellationEndDate).getMonth() + "" + new Date(this.state.cancellationEndDate).getFullYear()){
                                  //     if(this.props.role === 'Customer'){
                                  //       this.getCustomerReports(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //     } else {
                                  //       this.getTodayAndTotalBookings();
                                  //       this.getBookedAirlines();
                                  //       this.getTotalMonthlyBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //       this.getCancelledBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //     } 
                                  //   } else {
                                  //     this.props.showToast({type : 'error',message : "start date month and end date month can't be same"})
                                  //   }
                                  // } else {
                                  //   this.props.showToast({type : 'error',message : "Please select end date too"})
                                  // }
                               })
                              }}
                              value={this.state.cancellationStartDate}
                              fullWidth
                              label="Start Date"
                            />
                          </Grid>
                          <Grid xs={3} item>
                            <InputFiled
                              type="date"
                              InputProps={{
                                inputProps: {
                                  max: curDate,
                                },
                              }}
                              placeholder="Enter End Date"
                              onChange={(e) => {
                                this.setState({cancellationEndDate: e.target.value},()=>{
                                  // if(this.state.cancellationStartDate !== null){
                                  //   if(new Date(this.state.cancellationStartDate).getMonth() + "" + new Date(this.state.cancellationStartDate).getFullYear() !== new Date(this.state.cancellationEndDate).getMonth() + "" + new Date(this.state.cancellationEndDate).getFullYear()){
                                  //     if(this.props.role === 'Customer'){
                                  //       this.getCustomerReports(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //     } else {
                                  //       this.getTodayAndTotalBookings();
                                  //       this.getBookedAirlines();
                                  //       this.getTotalMonthlyBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //       this.getCancelledBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //     } 
                                  //   } else {
                                  //     this.props.showToast({type : 'error',message : "start date month and end date month can't be same"})
                                  //   }
                                  // } else {
                                  //   this.props.showToast({type : 'error',message : "Please select end date too"})
                                  // }
                                   
                              })
                              }}
                              value={this.state.cancellationEndDate}
                              fullWidth
                              label="End Date"
                            />
                          </Grid>
                          <Grid xs={3} item>
                            <InputFiled
                              select
                              fullWidth
                              onChange={(e) => {
                                this.setState({cancelledAirlineInput: e.target.value},()=>{
                                  // if(this.props.role === 'Customer'){
                                  //   this.getCustomerReports(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  // } else {
                                  //   this.getTodayAndTotalBookings();
                                  //   this.getBookedAirlines();
                                  //   this.getTotalMonthlyBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  //   this.getCancelledBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  // } 
                            })
                              }}
                              value={this.state.cancelledAirlineInput}
                              label="Airline"
                            >
                              {
                                this.props.role === 'Agency' ? (
                                  this.state.bookedAirlines?.agency_stats?.booked_airlines.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                ) :
                                this.props.role === 'Admin' ? (
                                  this.state.bookedAirlines?.agencies_and_sub_agencies_stats?.booked_airlines.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                ) : this.props.role === 'Customer' ? (
                                  this.state.customerReports?.customers_stats?.booked_airlines?.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  this.state.bookedAirlines?.agency_stats?.sub_agencies_stats?.booked_airlines.map((value) => {
                                    return (
                                      <MenuItem
                                        key={value}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    );
                                  })
                                )
                              }
                              
                            </InputFiled>
                          </Grid>
                          <Grid item xs={8}>
                          
                          <Button variant="contained" color="primary" onClick={()=>{
                               if(new Date(this.state.cancellationStartDate).getMonth() + "" + new Date(this.state.cancellationStartDate).getFullYear() !== new Date(this.state.cancellationEndDate).getMonth() + "" + new Date(this.state.cancellationEndDate).getFullYear()){
                                if(this.props.role === 'Customer'){
                                  this.getCustomerReports(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                } else {
                                  this.getTodayAndTotalBookings();
                                  this.getBookedAirlines();
                                  this.getTotalMonthlyBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  this.getCancelledBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                } 
                              } else {
                                this.props.showToast({type : 'error',message : "start date month and end date month can't be same"})
                              } 
                          }}>
                              Apply
                          </Button>
                          <Button style={{marginLeft : '16px'}} onClick={()=>{
                            this.setState({
                              cancellationStartDate : "",
                              cancellationEndDate : "",
                              cancelledAirlineInput : null
                            },()=>{
                              // if(new Date(this.state.cancellationStartDate).getMonth() + "" + new Date(this.state.cancellationStartDate).getFullYear() !== new Date(this.state.cancellationEndDate).getMonth() + "" + new Date(this.state.cancellationEndDate).getFullYear()){
                                if(this.props.role === 'Customer'){
                                  this.getCustomerReports(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                } else {
                                  this.getTodayAndTotalBookings();
                                  // this.getBookedAirlines();
                                  this.getTotalMonthlyBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                  this.getCancelledBookings(this.state.cancellationStartDate,this.state.cancellationEndDate,this.state.cancelledAirlineInput);
                                } 
                              // } else {
                              //   this.props.showToast({type : 'error',message : "start date month and end date month can't be same"})
                              // }
                            })
                          }} variant="outlined" color="primary">
                              Reset
                          </Button>
                   
                      </Grid>
                     
                        </Grid>
                <Grid item xs={12}>
                  <canvas id="lineChart" style={this.state.showLineChart ? {display : 'block'} : {display : 'none'}}/>
                </Grid>
                <Grid item xs={12}>
                    <div style={this.state.showLineChart ? {display : 'none',textAlign:'center'} : {display : 'block',textAlign:'center'}}>
                       <Typography align="center" style={{lineHeight : '200px'}} >No Booking Available</Typography>
                    </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
    );
  }
}
const agancyReportListWithRouter = withRouter(agancyReport);
const agancyReportListAlertBox = withAlertBox(agancyReportListWithRouter);
const agancyReportListLoader = withLoader(agancyReportListAlertBox);
const agancyReportListToast = withToast(agancyReportListLoader);
const agancyReportListwithDialogBox = withDialogBox(agancyReportListToast);
export default withStyles((theme) =>
  createStyles({
    agancyReportBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
    },
    agancyReportTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agancyReportContainer: {
      borderRadius: "8px",
      padding: "2rem",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
    },
    agancyAllReportContainer: {
      padding: "2rem",
      paddingBottom: "3.1rem",
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#1565d8",
      color: "white",
    },
    agancyReportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
    },
  })
)(agancyReportListwithDialogBox);
