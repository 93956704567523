import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import AgencySignUp from "./AgencySignUp.web";
import CustomerLogin from "./Login.web";
import CustomerSignup from "./SignUp.web";
import ForgotPassword from "./ForgotPassword.web";
import ResetPassword from "./ResetPassword.web";
import SignUpVerifyLinkDialog from "./SignUpVerifyLinkDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  selectedSearchType : 'Hotels' | 'Flights',
  selectedTravellingType : string;
  authToken : any;
  sourceSuggestionloading : boolean,
  destinationSuggestionloading : boolean,
  sourcesearchSuggestionList : Array<any>
  destinationsearchSuggestionList : Array<any>,
  fromCity : any;
  toCity : any;
//   selectedType : String;
//   token : string;
//   selectedTime : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AgencyMarketingController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  verifyAccountApiCallId : String = "";
  getLocationSearchApiCallId : String = ""
  getDestinationLocationSearchApiCallId : String = ""
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
        selectedSearchType : 'Hotels',
        selectedTravellingType : 'oneway',
        authToken : '',
        sourceSuggestionloading : false,
        destinationSuggestionloading : false,
        destinationsearchSuggestionList : [],
        sourcesearchSuggestionList : [],
        fromCity : null,
        toCity : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiEmailCallId) {
        // this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
        }
      } else if (apiRequestCallId === this.getLocationSearchApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              sourcesearchSuggestionList : responseJson.data,
              sourceSuggestionloading : false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      } else if (apiRequestCallId === this.getDestinationLocationSearchApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              destinationsearchSuggestionList : responseJson.data,
              destinationSuggestionloading : false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }

  openApplyForAgency = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: AgencySignUp,
        title: "Apply as agency",
        withCustomDialog: false,
        disableBackdropClick : true,
        disableEscapeKeyDown : true,
        scroll : "body"
      })
      .then((type : any) => {
         if (type === "signUpSuccessFull") {
          this.props.showToast({
              type: "success",
              message: "Your request sent to admin successfully",
            });
        }
      });
  };

  openAgencyLogin = () => {
    this.props
      .openDialogBox({
        dataToPass: {role : 'Agency'},
        renderedComponent: CustomerLogin,
        title: "Login",
        withCustomDialog: true,
      })
      .then(async(type : any) => {
        if (type === "signUp") {
          this.openSignUp();
        } else if (type === "forgotPassword") {
          this.openForgotPassword();
        } else if (type === "loginSuccessFull") {
          this.props.showToast({
            type: "success",
            message: "Logged in successfully.",
          });
          const authToken = await StorageProvider.get("agancyToken");
          this.setState({
            authToken : authToken
          },()=>{
            this.props.history.push("/AgancyDashborad")
          })
        }
      });
  };

  openSignUp = () => {
    this.props
      .openDialogBox({
        dataToPass: {role : 'Agency'},
        renderedComponent: CustomerSignup,
        title: "Sign Up",
        withCustomDialog: true,
      })
      .then((type : any) => {
        if (type === "signIN") {
          this.openAgencyLogin();
        } else if (type === "signUpSuccessFull") {
          this.props.showToast({
              type: "success",
              message: "Verification link sent successfully.",
            });
        }
      });
  };

  openForgotPassword = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: ForgotPassword,
        title: "Forgot Password",
        withCustomDialog: true,
      })
      .then((type : any) => {
       if (type === "forgotSuccessfull") {
          this.props.showToast({
              type: "success",
              message: "Password reset link sent successfully.",
            });
        } else if (type === "signIN") {
          this.openAgencyLogin();
        }
      });
  };

  openResetPassword = (email : any) => {
    this.props
      .openDialogBox({
        dataToPass: {email : email},
        renderedComponent: ResetPassword,
        title: "Reset Password",
        withCustomDialog: true,
      })
      .then((type : any) => {
        if (type === "signUp") {
          this.openSignUp();
        } else if (type === "forgotPassword") {
          this.openForgotPassword();
        } else if (type === "changedPasswordSuccessfully") {
          this.props.showToast({
            type: "success",
            message: "Password changed successfully.",
          });
          this.props.history.push("/")
        }
      });
  };

  openVerifyAccount = () => {
    this.props
      .openDialogBox({
        dataToPass: {role : 'Agency'},
        renderedComponent: SignUpVerifyLinkDialog,
        title: "Verify Accout",
        withCustomDialog: true,
      })
      .then((data : any) => {
        if (data?.type === "verified") {
          this.props.history.push('/completeProfile?token='+this.state.authToken);
        } else if(data?.type === 'signIN'){
           this.openAgencyLogin(); 
        }
      });
  };

  // verifyAccount = (token : any) => {
  //   this.props.showLoader();
  //   const headers = {
  //     "Content-Type": configJSON.ApiContentType,
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.verifyAccountApiCallId = requestMessage.messageId;
    
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //    this.props.role === 'Admin' ? `${configJSON.adminverifyAccountEndPoint}?token=${token}` : `account_block/accounts/email_confirmations?token=${token}`
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(headers)
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.methodGET
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // }
}
