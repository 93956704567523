import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  token: any,
  
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AgancyResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  changePasswordApiCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      token : ''
    }
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.changePasswordApiCallId) {
        this.props.hideLoader();
        if (responseJson?.status === 'success') {
          await StorageProvider.clearStorage();
          this.props.showToast({type:'success',message:'Password reset succesfully'})
          this.props.history.push("/AgencyMarketing");
        } else {
            this.props.showAlert({
                title: "Alert",
                message: responseJson?.message,
              });  
          this.parseApiErrorResponse(responseJson);
        }
      } 
      
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      });
    }
  }
  
  handlePasswordChange = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token":this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        "confirm_password":values.password,
        "new_password":values.confirmPassword
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userChangePasswordEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
