import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import PolicyDialog from "./PolicyDialog.web";
import { string } from "yup";

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  bookingId : String;
  token : string;
  bookingDetail : any
  flightDetail : any,
  flightPolicies: any,
  fareSummaryDetail : any,
  fareSummaryLst : Array<any>,
  oneWayFlightList : Array<any>
  twoWayFlightList : Array<any>
  selectedTravellingType : string,
  oneWayStops : Array<any>,
  twoWayStops : Array<any>,
  couponDisCount : any

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyBookingFlightDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  get_flight_booking_detailApiCallId: String = "";
  getTicketApiCallId : string = "";
  getPoliciesAndInformationApiCallId : string = ""
  donwloadInvoiceAPiCallId : String = ""
  cancelBookingApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      bookingId : '',
      flightDetail : {},
      bookingDetail : {},
      token : '',
      flightPolicies : {},
      fareSummaryDetail : {},
      fareSummaryLst : [],
      oneWayFlightList : [],
      twoWayFlightList : [],
      selectedTravellingType : 'oneway',
      oneWayStops : [],
      twoWayStops : [],
      couponDisCount : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.get_flight_booking_detailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data?.id) {
          const flightList = [...responseJson?.data?.attributes?.air_segment];
          const flightPolicyDetails = [...responseJson?.data?.attributes?.flight_details_hash];
          const flights = this.processData(flightList,flightPolicyDetails);
          const aggregateFlight = this.processDataForAggregateflight(flightList,{});
          this.setState({
            flightDetail : aggregateFlight,
            selectedTravellingType : responseJson?.data?.attributes?.type_of_ticket,
            bookingDetail : responseJson.data?.attributes,
            oneWayFlightList : flights?.onewayflights.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight}),
            oneWayStops : flights?.onewayflights.length > 0 ? this.stopCalculation(flights?.onewayflights) : [],
            twoWayFlightList : flights?.twoWayFlights.filter((flight:any)=>{return flight?.flight_type === 'return' && flight}),
            twoWayStops : flights?.twoWayFlights.length > 0 ? this.stopCalculation(flights?.twoWayFlights) : [],
          },()=>{
            if (responseJson && responseJson?.data?.attributes?.payment?.data?.attributes?.note?.AirPriceInfo) {
              const fareSummaryLst = [];
              const newFareSummaryJson = responseJson?.data?.attributes?.payment?.data?.attributes?.note || {};
              const couponDisCount = responseJson?.data.attributes.payment.data.attributes.note.couponDisCount || null;
              // const AirPriceInfo = _.groupBy(responseJson?.AirPriceInfo,"type");
              let isAdult = false;
              for(let key in newFareSummaryJson?.AirPriceInfo){
                const AirPriceInfo = newFareSummaryJson?.AirPriceInfo[key]
                const temp = {
                  type : '',
                  count : 0,
                  toalPrice : 0,
                  basePrice : 0,
                  name : '',
                };
                
                if(AirPriceInfo.type === 'ADT' && newFareSummaryJson.adult){
                  temp.name = "Adults"
                  temp["type"] = AirPriceInfo.type;
                  temp.count = newFareSummaryJson.adult;
                  temp.toalPrice = AirPriceInfo.base_rate * newFareSummaryJson.adult;
                  temp.basePrice = AirPriceInfo.base_rate;
                  isAdult = true
                } else if(AirPriceInfo.type === 'INF' && newFareSummaryJson.infents){
                  temp.name = "Infants"
                  temp["type"] = AirPriceInfo.type;
                  temp.count = newFareSummaryJson.infents;
                  temp.toalPrice = AirPriceInfo.base_rate * newFareSummaryJson.infents;
                  temp.basePrice = AirPriceInfo.base_rate;
                } else if(AirPriceInfo.type === 'CNN' && newFareSummaryJson.child){
                  temp.name = "Children"
                  temp["type"] = AirPriceInfo.type;
                  temp.count = newFareSummaryJson.child;
                  temp.toalPrice = AirPriceInfo.base_rate * newFareSummaryJson.child;
                  temp.basePrice = AirPriceInfo.base_rate;
                }
                fareSummaryLst.push(temp)
              }
              this.setState({
                fareSummaryDetail : newFareSummaryJson,
                fareSummaryLst : fareSummaryLst,
                couponDisCount : couponDisCount
              },()=>{
                
              })
            }
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(this.getTicketApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (!responseJson?.tickets?.Envelope?.Body?.Fault?.faultstring) {
           
        } else {
          this.props.showToast({type : 'error',message :responseJson?.tickets?.Envelope?.Body?.Fault?.faultstring })
          //this.parseApiErrorResponse(responseJson);
        }
      } else if(this.donwloadInvoiceAPiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson?.status && responseJson?.invoice_url) {
          //  const blob = await responseJson.blob();
          //  var url = window.URL.createObjectURL(responseJson);
              window.open(responseJson?.invoice_url)
            // var a = document.createElement('a');
            // a.href = responseJson?.invoice_url;
            // a.download = "ticket.pdf";
            // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            // a.click();    
            // a.remove()
        } else {
          this.props.showToast({type : 'error',message :'Unable to download' })
          //this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getPoliciesAndInformationApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes && typeof responseJson?.data?.attributes !== 'string') {
          this.setState({
            flightPolicies: responseJson?.data?.attributes
          },()=>{
            this.openPolicyDialog(this.state.flightPolicies)
          });
        } else {
          this.props.showToast({type:'error',message : "No policy found"})
          this.parseApiErrorResponse(responseJson)
        }
      } else if(this.cancelBookingApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes?.status === 'true') {
           this.props.showToast({type:'success',message : responseJson?.data?.attributes?.message})
        } else {
          this.props.showToast({type:'error',message : responseJson?.data?.attributes?.message})
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }

  processData = (flights : any,flightPolicy : any) => {
    const processFlightSearchData = [];
    for(let i=0;i<flights.length;i++){
        const flight = {...flights[i]};
        let airSagmentInfo = {};
        airSagmentInfo = flightPolicy.find((newflight : any) =>{return flight.flight_number == newflight.flight_id})
        processFlightSearchData.push({...flight,...airSagmentInfo});
    }
    return {
            onewayflights : processFlightSearchData.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight}),
            twoWayFlights : processFlightSearchData.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
          };
  }
  processDataForAggregateflight = (flights : any,a :any = {}) => {
        
    const flightnew = {...a};
    let flightIDs = flights.map((flight : any)=>{return flight.flight_number});
    flightIDs = Array.from(new Set(flightIDs)).join(",")
    flightnew.flight_number = flightIDs;
    flightnew.flight_carrier = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_carrier}))).join(",");
    flightnew.flight_logo = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_logo}))).join(",");
    flightnew.flight_name = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_name}))).join(",");
    const departueFlight = this.getFlightDepartureInfo(flights);
    flightnew.boarding_terminal = departueFlight?.boarding_terminal
    flightnew.depature_city = departueFlight?.depature_city
    flightnew.depature_date = departueFlight?.depature_date
    flightnew.depature_datetime = departueFlight?.depature_datetime
    flightnew.depature_iata = departueFlight?.depature_iata
    flightnew.depature_time = departueFlight?.depature_time;

    const returnFlight = this.getFlightRetrunInfo(flights);
    flightnew.arriving_terminal = returnFlight?.arriving_terminal
    flightnew.arrival_city = returnFlight?.arrival_city
    flightnew.arrival_date = returnFlight?.arrival_date
    flightnew.arrival_datetime = returnFlight?.arrival_datetime
    flightnew.arrival_iata = returnFlight?.arrival_iata
    flightnew.arrival_iata = returnFlight?.arrival_iata;
    
    if(flights.length > 1){
      flightnew.stops = this.stopCalculation(flights);
    } else {
      flightnew.stops = []
    }
    return (flightnew);
}
getFlightDepartureInfo = (airsagment : any) => {
  const flight = {...airsagment[0]};
  return flight; 
}
getFlightRetrunInfo = (airsagment : any) => {
  const flight = {...airsagment[airsagment.length - 1]};
  return flight;
}
  stopCalculation = (sagmentInfo : any) => {

    const stops = [];
    for(let i=0;i<sagmentInfo.length - 1;i++){
      const a = sagmentInfo[i];
      const b = sagmentInfo[i+1];
      const stop = {
        stopName : '',
        stopDuration : '',
        stopType : '',
        flight_type : a.flight_type
      }
      stop.stopName = a.arrival_city;
      stop.stopDuration = this.timeDiff(b.depature_datetime,a.arrival_datetime)
      stop.stopType = "Change Of Plane"
      stops.push(stop);
    }
    return stops
  }
  timeDiff = function (date1 : any, date2 : any) {
    var a = new Date(date1).getTime(),
        b = new Date(date2).getTime(),
        diff = {
          milliseconds : 0,
          seconds : 0,
          minutes : 0,
          hours : 0
        };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if(extraMin > 0){
      return Math.floor(diff.hours) + " hr" + " " + extraMin + " " + "min"
    } else {
      return Math.floor(diff.hours) + " hr"
    }
  }
  getBookingDetail = () => {
    this.props.showLoader();
     let url = `${configJSON.get_flight_booking_detail}?booking_id=${this.state.bookingId}`;
     let requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.get_flight_booking_detailApiCallId = requestMessage.messageId;
     
     const headers = {
       "Content-Type": configJSON.ApiContentType,
       "token": this.state.token
     };
 
     requestMessage?.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage), url
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(headers)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.methodGET
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  downloadInvoice = () => {
    this.props.showLoader();
     let url = `bx_block_invoice_billing/flight_invoice?booking_id=${this.state.bookingDetail.id}`;
     let requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.donwloadInvoiceAPiCallId = requestMessage.messageId;
     
     const headers = {
       "token": this.state.token,
       "Content-Type": "application/json"
     };
 
     requestMessage?.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage), url
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(headers)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.methodGET
     );
     
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTikcet = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTicketApiCallId = requestMessage.messageId;
    const httpBody = { 
      "locator_code": this.state.bookingDetail?.air_locator_code
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/flight_booking/air_ticketing`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPoliciesAndInformation = (fare_rule_key:any,fare_info_key:any) => { 
      this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.token
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPoliciesAndInformationApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        'fare_info_key': fare_info_key,
        'fare_rule_key': fare_rule_key
      }
      }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/travel_search/flight_policy`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);  
    
  }
  openPolicyDialog = (policyData : any) => {
    this.props
      .openDialogBox({
        title: "Flight Policy",
        dataToPass: { policyData : policyData},
        renderedComponent: PolicyDialog,
        withCustomDialog: true,
        catchOnCancel: true,
      })
  }
  cancelBooking = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelBookingApiCallId = requestMessage.messageId;
    // const httpBody = { 
    //   "locator_code": this.state.bookingDetail?.locator_code
    // }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/flight_booking/cancel_flight_universal_booking?booking_id=${this.state.bookingId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

