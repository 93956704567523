//@ts-nocheck
import React from "react";
import AddNewCouponController, { Props } from "./AddNewCouponController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Hidden,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  InputAdornment
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import InputField from "../../../components/src/InputFiled.web";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";

const curDate = moment(new Date()).format("YYYY-MM-DD").toString();

const Schema = Yup.object().shape({
  title: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
  code: Yup.string().required("This field is required"),
  discountType: Yup.string().required("This field is required."),
  discount: Yup.number().required("This field is required."),
  validFrom: Yup.date()
  .typeError('Valid from date is required')
  .required('Valid from date is required')
    ,
  validTo: Yup.date()
  .typeError('End Date is required')
  .required('End Date is required')
  .when('validFrom', (validFrom) => {
      if (validFrom) {
          return Yup.date()
              .min(validFrom, 'Valid to date must be after valid from date')
              .typeError('Valid to date is required')
      }
  }),
  minCartValue: Yup.number().required("This field is required.").positive().moreThan(0,"more then 0"),
  maxCartValue: Yup.number().required("This field is required.").when('minCartValue', (minCartValue) => {
    if (minCartValue) {
        return Yup.number()
            .min(minCartValue, 'Max cart value must be greater then min cart value')
            .typeError('This field is required')
    }
}),
  // applicabilityAgencyAccount: Yup.string(),
  // applicabilityEndUser: Yup.string(),
});

class AddNewCoupon extends AddNewCouponController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    console.log(this.props.history);
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          couponDataToEdit: state?.couponData || {},
        },
        () => {
          this.getAllAcceptedRequest();
        }
      );
    }
  }

  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          title: this.state?.couponDataToEdit?.title || "",
          description: this.state?.couponDataToEdit?.description || "",
          code: this.state?.couponDataToEdit?.code || "",
          discountType: this.state?.couponDataToEdit?.discount_type || "",
          discount: this.state?.couponDataToEdit?.discount || "",
          validFrom: (this.state?.couponDataToEdit?.valid_from && moment(this.state?.couponDataToEdit?.valid_from).format("YYYY-MM-DD")) || "",
          validTo: (this.state?.couponDataToEdit?.valid_to && moment(this.state?.couponDataToEdit?.valid_to).format("YYYY-MM-DD")) || "",
          minCartValue: this.state?.couponDataToEdit?.min_cart_value || "",
          maxCartValue: this.state?.couponDataToEdit?.max_cart_value || "",
          currency : this.state?.couponDataToEdit?.currency || "USD",
          // applicabilityAgencyAccount:
          //   this.state?.couponDataToEdit?.applicabilityAgencyAccount || "",
          // applicabilityEndUser:
          //   this.state?.couponDataToEdit?.applicabilityEndUser || "",
        }}
        // moment(new Date(coupon.attributes.valid_from )).format("DD/MM/yyyy")) || "",
        // {(coupon.attributes.valid_from &&  || "NA"}
        enableReinitialize
        validationSchema={Schema}
        onSubmit={(values) => {
          {
            this.props.history.location.pathname ===
            "/AdminDashboard/editCoupon"
              ? this.createAndUpdateCoupon(values, "PUT")
              : this.createAndUpdateCoupon(values, "POST");
          }
        }}
      >
        {(formikProps) => {
          const { values, handleBlur, handleChange, setFieldValue } =
            formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2}>
                <Grid item xs={11} className={classes.pendIngRequestBox}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ height: "70px" }}
                  >
                    <Grid item md={11}>
                      <Typography className={classes.pendIngTite}>
                      {Object.keys(this.state.couponDataToEdit).length > 0 ? 'Edit Coupon' : 'Add New Coupon'}  
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid md={5} xs={10} item>
                      <Field
                        component={InputFieled}
                        name="title"
                        placeholder="Enter title"
                        value={values.title}
                        fullWidth
                        label="Title"
                      />
                    </Grid>
                    <Grid md={5} xs={10} item>
                      <Field
                        component={InputFieled}
                        name="code"
                        placeholder="Enter coupon code"
                        value={values.code}
                        fullWidth
                        label="Code"
                      />
                    </Grid>
                    <Grid md={5} xs={10} item>
                      <Field
                        component={InputFieled}
                        name="description"
                        placeholder="Enter description"
                        multiline
                        rows={5}
                        // onChange={handleChange}
                        value={values.description}
                        fullWidth
                        label="Description"
                      />
                    </Grid>
                    <Grid md={5} xs={10} item>
                    <FormControl component="fieldset">
                                    <FormLabel
                                      className={classes.radioGroupTitle}
                                      component="legend"
                                    >
                                      Currency
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="currency"
                                      onChange={handleChange}
                                      defaultValue={values.currency}
                                    >
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="USD"
                                        control={
                                          <Radio
                                            color="primary"
                                          />
                                        }
                                        label="USD"
                                      />
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="NPR"
                                        control={
                                          <Radio
                                            color="primary"
                                          />
                                        }
                                        label="NPR"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                   
                    </Grid>
                    <Grid md={5} xs={12} item>
                      <Field
                        component={InputFieled}
                        name="minCartValue"
                        placeholder="Enter minimum cart value"
                        // onChange={handleChange}
                        value={values.minCartValue}
                        fullWidth
                        label="Minumum Cart Value"
                      />
                    </Grid>
                    <Grid md={5} xs={12} item>
                      <Field
                        component={InputFieled}
                        name="maxCartValue"
                        placeholder="Enter maximum cart value"
                        // onChange={handleChange}
                        value={values.maxCartValue}
                        fullWidth
                        label="Maximum Cart Value"
                      />
                    </Grid>
                    <Grid md={5} xs={10} item>
                      <Field
                        component={InputFieled}
                        name="discountType"
                        select
                        placeholder="Enter discount type"
                        // onChange={handleChange}
                        value={values.discountType}
                        fullWidth
                        label="Discount Type"
                      >
                        {["flat", "percentage"].map((type) => {
                          return (
                            <MenuItem key={type} value={type}>
                              {type || "NA"}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                    <Grid md={5} xs={10} item>
                      <Field
                        component={InputFieled}
                        name="discount"
                        placeholder={values.discountType === 'flat' ? "Enter discount amount" : "Enter discount percenage"}
                        // onChange={handleChange}
                        value={values.discount}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              {values.discountType === 'percentage' && ("%") }
                            </InputAdornment>
                          ),
                        }}
                        label="Discount"
                      />
                    </Grid>
                    
                    <Grid md={5} xs={12} item>
                      <Field
                        component={InputFieled}
                        name="validFrom"
                        type="date"
                        InputProps={{
                          inputProps: {
                            min: curDate,
                          },
                        }}
                        placeholder="Enter coupon start date"
                        // onChange={handleChange}
                        value={values.validFrom}
                        fullWidth
                        label="Coupon Start Date"
                      />
                    </Grid>
                    <Grid md={5} xs={12} item>
                      <Field
                        component={InputFieled}
                        name="validTo"
                        type="date"
                        InputProps={{
                          inputProps: {
                            min: curDate,
                          },
                        }}
                        placeholder="Enter coupon expiry date"
                        onChange={handleChange}
                        value={values.validTo}
                        fullWidth
                        label="Coupon Expiry Date"
                      />
                    </Grid>
                    <Grid md={5} xs={12} item>
                      <Field
                          component={InputFieled}
                          select
                          name="applicabilityAgencyAccount"
                          placeholder="Enter applicabilityAgencyAccount value"
                          // onChange={handleChange}
                          value={values.applicabilityAgencyAccount}
                          fullWidth
                          multiple={true}
                          label="Applicability for Agency Account"
                        >
                          {this.state.allAcceptedInvites.map((agency) => {
                                        return (
                                          <MenuItem
                                            key={agency.id}
                                            value={agency.id}
                                          >
                                            {agency.full_name || 'NA'}
                                          </MenuItem>
                                        );
                             })}
                        </Field>
                    </Grid>
                    {/* <Grid md={5} xs={12} item>
                      <Field
                        component={InputFieled}
                        select
                        name="applicabilityAgencyAccount"
                        placeholder="Enter applicabilityAgencyAccount value"
                        // onChange={handleChange}
                        value={values.applicabilityAgencyAccount}
                        fullWidth
                        label="Applicability for Agency Account"
                      >
                        {this.state.allAcceptedInvites.map((agency) => {
                          return (
                            <MenuItem key={agency.id} value={agency.id}>
                              {agency.attributes.agency_name || "NA"}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                    <Grid md={5} xs={12} item>
                      <Field
                        component={InputFieled}
                        name="applicabilityEndUser"
                        placeholder="Enter applicabilityEndUser value"
                        onChange={handleChange}
                        value={values.applicabilityEndUser}
                        fullWidth
                        label="Applicability for User"
                      />
                    </Grid>
                   */}
                  </Grid>
                </Grid>
                <Grid xs={11} item>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item xs={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() =>
                          this.props.history.push("/AdminDashboard/coupons")
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        {this.props.history.location.pathname ===
                        "/AdminDashboard/editCoupon"
                          ? "Save"
                          : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const AddNewCouponWithRouter = withRouter(AddNewCoupon);
const AddNewCouponAlertBox = withAlertBox(AddNewCouponWithRouter);
const AddNewCouponLoader = withLoader(AddNewCouponAlertBox);
const AddNewCouponToast = withToast(AddNewCouponLoader);
const AddNewCouponWithDialog = withDialog(AddNewCouponToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    formHeadingName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
  })
)(AddNewCouponWithDialog);
