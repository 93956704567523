//@ts-nocheck
import React from "react";
import CreadiTransactionController, {
  Props,
} from "./CreadiTransactionController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  IconButton,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import AddNewTeamMember from "./AddNewTeamMember.web";
import withFontStyles from "./withFontStyles.web";
import moment from "moment";

class CreadiTransaction extends CreadiTransactionController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "authToken";
    const authToken = await StorageProvider.get(tokenName);
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          if(this.props.role === 'Admin')
          this.getAllCredits();
          else{
            this.getAgancyCredits(this.props.profileData);
          }
          
        }
      );
    }
  }
  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };
  AddNewTeam = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: AddNewTeamMember,
        title: "Add New Team Member",
        withCustomDialog: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: false,
        width: "500px",
      })
      .then(async (type: any) => {
        if (type === "memberAddedSuccssfully") {
          this.props.showToast({
            type: "success",
            message: "successfully.",
          });
        }
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="space-around" spacing={4}>
          <Grid item xs={11}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.pendIngRequestBox}
            >
              <Grid item md={11}>
                <Typography className={classes.pendIngTite}>Credits</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Grid item md={6}>
                <Typography display="inline" color='textPrimary' className={classes.groteskBold20}>USD.</Typography>
                <Typography display="inline" color='textPrimary' className={classes.groteskBold36}>
                  {this.props.role === 'Admin' ? 0 : (this.props.profileData?.attributes?.balance || "NA")}
                </Typography>

                <Typography color='textSecondary' className={classes.groteskBold18}>Credits Available</Typography>
              </Grid>
              <Grid item md={5} style={{alignSelf:'flex-end'}}>
                <Typography align='right' color='textSecondary' className={classes.openSans16}>
                  To assign credits to an sub agency, please <br/> visit its profile
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {this.state.allCreditsList.map((agencyCredit) => {
            return (
              <Grid item key={agencyCredit.id} xs={11}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={classes.agencyPendingRequestContainer}
                >
                  <Grid xs={2} item>
                    <Typography className={classes.agencyName}>
                      {this.props.role !== 'Admin' ? `- ${agencyCredit.attributes.amount}` : `- ${agencyCredit.attributes.credit_limit}`}
                    </Typography>
                  </Grid>
                  <Grid xs={5} item>
                    <Typography className={classes.agencyemail}>
                      {/* {"Given to reciever_full_name(${}) - Kathmandu on 12/12/2020"} */}
                      {this.props.role !== 'Admin' ? `Given to  ${agencyCredit.attributes.reciver_name || "not available"}(${agencyCredit.attributes.receiver_email}) - ${moment(agencyCredit.attributes.created_at).format("DD/MM/YYYY")}` : `Given to  ${agencyCredit.attributes.reciever_full_name || "not available"}(${agencyCredit.attributes.reciever_email}) - ${moment((agencyCredit.attributes.created_at || new Date())).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Grid>
                  {this.props.role === 'Admin' && <Grid xs={4} item style={{ alignSelf: "center" }}>
                    <Typography className={classes.agencyemail}>
                      {`Closing balance is ${agencyCredit.attributes.available_limit}`}
                    </Typography>
                  </Grid>} 
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const CreadiTransactionWithRouter = withRouter(CreadiTransaction);
const CreadiTransactionAlertBox = withAlertBox(CreadiTransactionWithRouter);
const CreadiTransactionLoader = withLoader(CreadiTransactionAlertBox);
const CreadiTransactionToast = withToast(CreadiTransactionLoader);
const CreadiTransactionWithDialog = withDialog(CreadiTransactionToast);
const CreadiTransactionWithFontStyles = withFontStyles(CreadiTransactionWithDialog)

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      padding: theme.spacing(0, 2),
      height: theme.spacing(10),
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
      height: theme.spacing(10),
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyemail: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewActivity: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    iconroot: {
      "& > *": {
        margin: theme.spacing(0.2),
      },
    },
  })
)(CreadiTransactionWithFontStyles);
