//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
  TextField,
  Box,
  DialogActions
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import { success, arrow } from "./assets";
import NewBookingFilterDialogController, {
  Props,
} from "./NewBookingFilterDialogController.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import InputFiled from "../../../components/src/InputFiled.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";

class FilterDialog extends NewBookingFilterDialogController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get(this.props.dataToPass.tokenName);
    let agencyDetail = await StorageProvider.get("agencyDetail");
        agencyDetail = agencyDetail && JSON.parse(agencyDetail);
        const filterData = this.props.dataToPass.filterData;
    if (authToken) {
      this.setState({
        token: authToken,
        agencyDetail: (agencyDetail),
        accountId: agencyDetail?.account_id,
        searchBYFilghtType : filterData?.flightType || [],
        searchBYFilghtCategory : filterData?.flightName || [],
        bookingEndDate : filterData?.bookingDate?.endDate || null,
        bookingStartDate : filterData?.bookingDate?.startDate || null,
        travellStartDate : filterData?.travellerDate?.startDate || null,
        travellEndDate : filterData?.travellerDate?.endDate || null,
      },()=>{
          this.getBookedAirlines()
      });
    }
  }
  goToSelectedRoute = (routeName) => {
    this.props.onCancel();
  };
  handleFlightCategoryChange = (evt, value) => {
    const searchBYFilghtCategory = [...this.state.searchBYFilghtCategory];
    if (evt.target.checked) {
      this.setState(
        {
          searchBYFilghtCategory: [...searchBYFilghtCategory, value]
        },
      );
    } else {
      this.setState(
        {
          searchBYFilghtCategory: searchBYFilghtCategory.filter((category) => {
            return category !== value;
          })
        },
        () => {
        }
      );
    }
  };
  handleFlightTypeChange = (evt, value) => {
    const searchBYFilghtType = [...this.state.searchBYFilghtType];
    if (evt.target.checked) {
      this.setState(
        {
          searchBYFilghtType: [...searchBYFilghtType, value]
        },
      );
    } else {
      this.setState(
        {
          searchBYFilghtType: searchBYFilghtType.filter((category) => {
            return category !== value;
          })
        },
        () => {
        }
      );
    }
  };
  filterBooking = () => {
    this.props.onSubmit({
      flightName : this.state.searchBYFilghtCategory,
      flightType : this.state.searchBYFilghtType,
      bookingDate : {
        startDate : this.state.bookingStartDate,
        endDate : this.state.bookingEndDate
      },
      travellerDate : {
        startDate : this.state.travellStartDate,
        endDate : this.state.travellEndDate
      }
    })
  }
  render() {
    const { classes } = this.props;
    return (
      <>
      <DialogContent className={classes.dialogStyles}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={6}>
            <Box p={1} className={classes.boxLarge}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    Filter By Booking Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputFiled
                    fullWidth
                    value={this.state.bookingStartDate}
                    onChange={(evt)=>{this.setState({
                      bookingStartDate : evt.target.value
                    })}}
                    label="From Date"
                    type="date"
                    variant="outlined"
                    placeholder="12/12/2020"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFiled
                    fullWidth
                    value={this.state.bookingEndDate}
                    onChange={(evt)=>{
                      this.setState({
                      bookingEndDate : evt.target.value
                    })}}
                    type="date"
                    label="To Date"
                    variant="outlined"
                    placeholder="12/12/2020"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box p={1} className={classes.boxLarge}>
              <Grid container spacing={1} justify="space-between">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    Filter By Travel Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputFiled
                    fullWidth
                    value={this.state.travellStartDate}
                    onChange={(evt)=>{
                      this.setState({
                        travellStartDate : evt.target.value
                    })}}
                    label="From Date"
                    type="date"
                    variant="outlined"
                    placeholder="12/12/2020"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFiled
                    fullWidth
                    value={this.state.travellEndDate}
                    onChange={(evt)=>{
                      this.setState({
                        travellEndDate : evt.target.value
                    })}}
                    type="date"
                    label="To Date"
                    variant="outlined"
                    placeholder="12/12/2020"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* <Grid item xs={6}>
            <Box p={1} className={classes.boxLarge}>
              <Grid container spacing={1} justify="space-between">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    {"Travel Source & Destination"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputFiled
                    fullWidth
                    label="Source"
                    variant="outlined"
                    placeholder="Type Source"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFiled
                    fullWidth
                    label="Destination"
                    variant="outlined"
                    placeholder="Type Destination"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
           */}
          <Grid item xs={6} alignItems={"stretch"}>
            <Box p={1} className={classes.boxLarge}>
              <Grid container >
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    Airline
                  </Typography>
                </Grid>
                {this.state.bookedAirlines.map((airlineName)=>{
                  return <Grid item xs={4} key={airlineName}>
                      <FormControlLabel
                        control={<Checkbox 
                          checked={
                            this.state.searchBYFilghtCategory.indexOf(
                              airlineName
                            ) > -1
                          }
                          onChange={(evt) => {
                            this.handleFlightCategoryChange(
                              evt,
                              airlineName
                            );
                          }}
                        />}
                        
                        label={airlineName}
                        classes={{ label: classes.checkBoxLabel }}
                      />
                    </Grid>
                })}
               </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box p={1} className={classes.boxLarge}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    Type Of Ticket
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value={"oneway"}
                    checked={
                      this.state.searchBYFilghtType.indexOf(
                        "oneway"
                      ) > -1
                    }
                    onChange={(evt) => {
                      this.handleFlightTypeChange(
                        evt,
                        "oneway"
                      );
                    }}/>}
                    label="one way"
                    classes={{ label: classes.checkBoxLabel }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value={"twoway"}
                    checked={
                      this.state.searchBYFilghtType.indexOf(
                        "twoway"
                      ) > -1
                    }
                    onChange={(evt) => {
                      this.handleFlightTypeChange(
                        evt,
                        "twoway"
                      );
                    }} />}
                    label="two way"
                    classes={{ label: classes.checkBoxLabel }}
                  />
                </Grid>
               </Grid>
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
       <Grid container justify="flex-end">
          <Grid item xs={6}>
            <Button onClick={()=>{this.props.onCancel()}}>Reset Filters</Button>
            <Button onClick={()=>{this.filterBooking()}} style={{marginLeft : '8px'}} variant="contained" color="primary">
              Apply Filters
            </Button>
          </Grid>
          
        </Grid>
      </DialogActions>
      </>
    );
  }
}
const FilterDialogAlertBox = withAlertBox(FilterDialog);
const FilterDialogLoader = withLoader(FilterDialogAlertBox);
const FilterDialogToast = withToast(FilterDialogLoader);
const FilterDialogWithRouter = withRouter(FilterDialogToast);

export default withStyles((theme) =>
  createStyles({
    confirmationText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.15,
      color: "#1b4050",
    },
    confirmationSubTitle: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: 1.19,
      color: "#1b4050",
    },
    boxLarge: {
      borderRadius: "6px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: "#ffffff",
      border: "solid 1px #e5eaf4",
    },
    checkBoxLabel: {
      fontSize: "12px",
      fontFamily: "Open Sans",
    },
    dialogStyles: {
      padding: "32px",
    },
  })
)(FilterDialogWithRouter);
