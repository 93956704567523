//@ts-nocheck
import React from "react";
import PaymentController, { Props } from "./PaymentController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  Button,
  Paper,
  Divider,
  LinearProgress,
  ListItem,
  Hidden,
  ListItemAvatar,
  ListItemSecondaryAction,
  List,
  ListItemText,
  Popper,
  Fade
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withFontStyles from "./withFontStyles.web";
import {
  flighPlaceHolder,
  flightCover,
  hotelCover,
  hotelImage,
  hotelIcon,
  wifiIcon,
  wheelChairIcon,
  pIcon,
  paymentFailedCross,
  success,
} from "./assets";
import Header from "./Header.web";
import Footer from "./Footer.web";
import FlightInfo from "./FlightInfo.web";
import HotelInfo from "./HotelInfo.web";
import { Redirect, RouterProps, Route } from "react-router-dom";
import moment from "moment";
import _ from "underscore";

const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
  root: {
    fontSize: "0.8rem",
  },
})(Rating);

class Payment extends PaymentController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    let profileData = await StorageProvider.get("profileData");
    profileData = profileData && JSON.parse(profileData) || {};
    if (
      authToken &&
      state?.entityType &&
      state?.fromCity &&
      state?.toCity &&
      state?.searchData &&
      state?.booking_id
    ) {
      this.setState(
        {
          authToken: authToken,
          entityType: state?.entityType,
          fromCity: state?.fromCity,
          toCity: state?.toCity,
          searchData: state?.searchData,
          loading: false,
          booking_id: state?.booking_id,
          travaller_id: state?.travaller_id,
          role: state?.role,
          locator_code: state?.locator_code,
          entityDetail: state?.entityDetail,
          paymentStatus: state?.paymentStatus,
          oneWayFlightList: state?.oneWayFlightList,
          twoWayFlightList: state?.twoWayFlightList,
          selectedFlight2: state?.selectedFlight2,
          currency: profileData.currency || "USD",
          selectedTravellingType: state?.selectedTravellingType,
        },
        () => {
          // this.getFlightDetail();
          this.getBookingDetail();
        }
      );
    } else {
      this.setState({
        loading: false,
        authToken: null,
      });
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    const data = {
      booking_id: this.state?.booking_id,
      role: this.state.role,
    };
    history.push({ pathname: routeName, state: data });
  };

  timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60;
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m";
    } else {
      return Math.floor(diff.hours) + " h";
    }
  };

  openPopover = (event, stops) => {
    this.setState({
      openpoper: !this.state.openpoper,
      poperAchor: event.currentTarget,
      stops: stops
    })
  }



  render() {
    const { classes } = this.props;
    const flightDetail = this.state.entityDetail;
    let redirectRouteName = "/";
    if (this.state.role === "Admin") {
      redirectRouteName = "/AdminLogin";
    } else if (this.state.role === "Agency") {
      redirectRouteName = "/AgencyMarketing";
    }
    let travel_detail =
      flightDetail?.travel_detail?.data?.attributes?.data &&
      Object.values(flightDetail?.travel_detail?.data?.attributes?.data);
    travel_detail = _.groupBy(travel_detail, "type");
    let trvallName = "";
    if (Object.keys(travel_detail).length > 0) {
      for (let type in travel_detail) {
        trvallName += `${type || ""} ${travel_detail[type].length}`;
      }
    }

    return !this.state.loading ? (
      this.state.authToken === null ? (
        <Redirect to={redirectRouteName} />
      ) : (
        <>
          <div className={classes.root} onClick={() => {
            this.setState({
              openpoper: false
            })
          }}>
            <Popper open={this.state.openpoper} anchorEl={this.state.poperAchor} placement={'top-start'} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{ backgroundColor: '#1565d8', padding: '16px' }}>
                    {this.state.stops.map((stop) => {
                      return <>
                        <Typography style={{ color: '#fff' }} sx={{ p: 2 }}>{stop.stopType}</Typography>
                        <Typography style={{ color: '#fff' }} sx={{ p: 2 }} align="center">{stop.stopName} | {stop.stopDuration}</Typography>
                      </>
                    })}

                  </Paper>
                </Fade>
              )}
            </Popper>
            <Paper
              className={classes.mainPost}
              style={{
                backgroundImage:
                  this.state.entityType === "Hotels"
                    ? `url(${hotelCover})`
                    : `url(${flightCover})`,
                backgroundSize: "cover",
              }}
            >
              {
                <img
                  style={{ display: "none" }}
                  src={flightCover}
                  alt={"aaaaaaaaaaaaaaaa"}
                />
              }
              <div className={classes.overlay} />
              {this.state.role && <Header role={this.state.role} />}
              <Grid container justify="center">
                <Grid item md={8} xs={10}>
                  <Grid
                    container
                    spacing={4}
                    justify="center"
                    className={classes.mainPostContent}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.helloAdmin} align="center">
                        Happy Travel !
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.groteskBold24} align="center">
                        Payment Successfull
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.bookingText} align="center">
                        You are all set for your next booking and can manage it and download invoice from the booking details.
                      </Typography>
                    </Grid>
                    {this.state.entityType === "Hotels" ? (
                      <Grid item xs={11}>
                        <Typography align="center" className={classes.sourceName}>
                          15 Jan - 16 Jan | 1 Room | 2 Adults
                        </Typography>
                        <Typography align="center" className={classes.sourceName}>
                          Sandard Room Twin Beds
                        </Typography>
                      </Grid>
                    ) : (null)}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              spacing={1}
            >
              {this.state.entityType === "Flights" ? (
                <Grid item md={10} xs={11}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Grid container className={classes.flightBox}>
                        <Grid item xs={12}>
                          <Typography
                            color="textPrimary"
                            className={classes.reviewFlightHeading}
                          >
                            Flight Information
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Divider className={classes.dividerStyle} />
                        </Grid>
                        <Grid item xs={12} className={classes.flightBoxDetails}>
                          {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight, index) => {
                            // const selectedSeat = self.state.seletedSeats.filter((seat) => { return seat.SegmentRef === flight.Key })
                            return <>
                              <Grid key={flight?.FlightNumber} item xs={12}>
                                <Grid xs={12} item style={{ marginBottom: "20px" }}>
                                  <ListItem style={{ padding: 0 }}>
                                    <ListItemAvatar>
                                      <img
                                        className={classes.flightImage}
                                        src={
                                          flight?.Flight_Image
                                        }
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      classes={{
                                        primary: classes.flightName,
                                        secondary: classes.flightCode,
                                      }}
                                      primary={
                                        flight?.Flight_Name || "NA"
                                      }
                                      secondary={`${flight?.Flight_carrier || "-"
                                        }${flight?.Flight_Id || "-"}`}
                                    />
                                    <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state?.paymentStatus}` || 0} />
                                  </ListItem>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <Divider className={classes.dividerStyle} />
                                </Grid>
                                <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                                  <Grid
                                    container
                                    justify="space-between"
                                  >
                                    <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                      <Typography
                                        className={[
                                          classes.flightDate,
                                        ]}
                                      >
                                        {(flight?.Depature_DateTime &&
                                          moment(
                                            flight?.Depature_DateTime
                                          ).format("ddd, DD MMM")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={classes.flightPlaceMode}
                                      >
                                        {" "}
                                        Departure{" "}
                                      </Typography>
                                      <Typography
                                        className={classes.flightTime}
                                        color="textSecondary"
                                      >
                                        {(flight?.Depature_DateTime &&
                                          moment(
                                            (
                                              flight?.Depature_DateTime
                                            )
                                          ).format("hh:mm A")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.flightAddress,
                                        ]}
                                        color="textPrimary"
                                      >
                                        {" "}
                                        {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                          "NA"}{", "} {flight?.Depature_City ||
                                            "NA"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <div className={classes.linewraper}>
                                        <Typography
                                          color="primary"
                                          className={classes.flightDiffTime}
                                        >
                                          {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                        </Typography>
                                        <ul className={classes.container}>
                                          <li className={classes.linkconnector} />
                                          <li className={classes.linkconnector} />
                                        </ul>
                                        <Typography
                                          color="primary"
                                          className={classes.flightDiffStop}
                                        >
                                          {/* {flight?.Mode_Of_Flight} */}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                      <Typography
                                        className={[
                                          classes.flightDate,
                                        ]}
                                      >
                                        {" "}
                                        {(flight?.Arrival_DateTime &&
                                          moment(
                                            flight?.Arrival_DateTime
                                          ).format("ddd, DD MMM")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={classes.flightPlaceMode}
                                      >
                                        {" "}
                                        Arrival{" "}
                                      </Typography>
                                      <Typography
                                        className={classes.flightTime}
                                        color="textSecondary"
                                      >
                                        {(flight?.Arrival_DateTime &&
                                          moment(
                                            (
                                              flight?.Arrival_DateTime
                                            )
                                          ).format("hh:mm A")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.flightAddress,
                                        ]}
                                        color="textPrimary"
                                      >
                                        {" "}
                                        {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                          "NA"}{", "} {flight?.Arrival_City ||
                                            "NA"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {index < (this.state.oneWayFlightList.length - 1) &&
                                <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                                  <Divider className={classes.dividerStyle} />
                                  <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[index].stopName})</Typography>
                                  <Divider className={classes.dividerStyle} />
                                </Grid>
                              }
                            </>
                          })}
                        </Grid>
                        {this.state?.selectedTravellingType === "twoway" && <Grid item md={12} xs={12}>
                          <Divider style={{ margin: "0px 20px", border: "1px dashed #1565d8" }} />
                        </Grid>}
                        {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight, index) => {
                          return <>
                            <Grid item xs={12} className={classes.flightBoxDetails}>
                              <Grid key={flight?.FlightNumber} item xs={12}>
                                <Grid xs={12} item style={{ marginBottom: "20px" }}>
                                  <ListItem style={{ padding: 0 }}>
                                    <ListItemAvatar>
                                      <img
                                        className={classes.flightImage}
                                        src={
                                          flight?.Flight_Image
                                        }
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      classes={{
                                        primary: classes.flightName,
                                        secondary: classes.flightCode,
                                      }}
                                      primary={
                                        flight?.Flight_Name || "NA"
                                      }
                                      secondary={`${flight?.Flight_carrier || "-"
                                        }${flight?.Flight_Id || "-"}`}
                                    />
                                    <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state?.paymentStatus}` || 0} />
                                  </ListItem>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <Divider className={classes.dividerStyle} />
                                </Grid>
                                <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                                  <Grid
                                    container
                                    justify="space-between"
                                  >
                                    <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                      <Typography
                                        className={[
                                          classes.flightDate,
                                        ]}
                                      >
                                        {(flight?.Depature_DateTime &&
                                          moment(
                                            flight?.Depature_DateTime
                                          ).format("ddd, DD MMM")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={classes.flightPlaceMode}
                                      >
                                        {" "}
                                        Departure{" "}
                                      </Typography>
                                      <Typography
                                        className={classes.flightTime}
                                        color="textSecondary"
                                      >
                                        {(flight?.Depature_DateTime &&
                                          moment(
                                            (
                                              flight?.Depature_DateTime
                                            )
                                          ).format("hh:mm A")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.flightAddress,
                                        ]}
                                        color="textPrimary"
                                      >
                                        {" "}
                                        {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                          "NA"}{", "} {flight?.Depature_City ||
                                            "NA"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <div className={classes.linewraper}>
                                        <Typography
                                          color="primary"
                                          className={classes.flightDiffTime}
                                        >
                                          {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                        </Typography>
                                        <ul className={classes.container}>
                                          <li className={classes.linkconnector} />
                                          <li className={classes.linkconnector} />
                                        </ul>
                                        <Typography
                                          color="primary"
                                          className={classes.flightDiffStop}
                                        >
                                          {/* {flight?.Mode_Of_Flight} */}
                                        </Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                      <Typography
                                        className={[
                                          classes.flightDate,
                                        ]}
                                      >
                                        {" "}
                                        {(flight?.Arrival_DateTime &&
                                          moment(
                                            flight?.Arrival_DateTime
                                          ).format("ddd, DD MMM")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={classes.flightPlaceMode}
                                      >
                                        {" "}
                                        Arrival{" "}
                                      </Typography>
                                      <Typography
                                        className={classes.flightTime}
                                        color="textSecondary"
                                      >
                                        {(flight?.Arrival_DateTime &&
                                          moment(
                                            (
                                              flight?.Arrival_DateTime
                                            )
                                          ).format("hh:mm A")) ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.flightAddress,
                                        ]}
                                        color="textPrimary"
                                      >
                                        {" "}
                                        {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                          "NA"}{", "} {flight?.Arrival_City ||
                                            "NA"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {index < (this.state.twoWayFlightList.length - 1) &&
                                <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                                  <Divider className={classes.dividerStyle} />
                                  <Typography align="center" className={classes.layoverText}> Layover {this.state.selectedFlight2?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.selectedFlight2?.stops[index].stopName})</Typography>
                                  <Divider className={classes.dividerStyle} />
                                </Grid>
                              }
                            </Grid>
                          </>
                        })}
                        <Grid item xs={12} className={classes.flightBoxDetails}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <Typography
                                className={classes.backToHome}
                                onClick={() => {
                                  this.props.history.push("/")
                                }}>BACK TO HOME</Typography>
                            </Grid>
                            <Grid xs={6} container style={{ justifyContent: "end" }}>
                              <Grid item xs={12} sm={8} md={8}>
                                <Button className={classes.bookingDetails} fullWidth
                                  onClick={() => {
                                    this.goToSelectedRoute("/myBookings/Flight");
                                  }}>
                                  View Booking Details
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                // <Grid item md={10} xs={11}>
                //   <Grid
                //     container
                //     spacing={2}
                //     alignItems="center"
                //     className={classes.hotelDetailContainer}
                //   >
                //     <Grid item xs={12}>
                //       <Typography
                //         color="textPrimary"
                //         className={classes.groteskBold20}
                //       >
                //         {this.state.selectedTravellingType === "twoway" ? "Depature Flight Information" : "Flight Information"}
                //       </Typography>
                //     </Grid>
                //     <Grid item xs={12}>
                //       <Divider variant="fullWidth" />
                //     </Grid>
                //     <Grid xs={12}>
                //       <Grid container spacing={3}>
                //         {this.state?.paymentStatus?.payment?.note.Flight_Details.length > 0 &&
                //           this.state?.paymentStatus?.payment?.note.Flight_Details.map((flight: any, index) => {
                //             {/* {this.state.flightList.length > 0 &&
                //           this.state.flightList.map((flight: any, index) => { */}
                //             return (
                //               <>
                //                 <Grid
                //                   key={flight?.FlightNumber}
                //                   item xs={12}>
                //                   <Grid container direction="row" justify="space-between" alignItems="center">
                //                     <Grid md={6} xs={12} item>
                //                       <ListItem style={{ padding: 0 }}>
                //                         <ListItemAvatar>
                //                           <img
                //                             className={classes.flightImage}
                //                             src={
                //                               flight?.Flight_Image
                //                             }
                //                             style={{ marginRight: "10px" }}
                //                           />
                //                         </ListItemAvatar>
                //                         <ListItemText
                //                           classes={{
                //                             primary: classes.groteskBold24,
                //                             secondary: classes.openSans18,
                //                           }}

                //                           primary={
                //                             flight?.Flight_Name || "NA"
                //                           }
                //                           secondary={`${flight?.Flight_carrier || "-"
                //                             } | ${flight?.Flight_Id || "-"}`}
                //                         />
                //                       </ListItem>
                //                     </Grid>
                //                     <Grid md={6} xs={12} item>
                //                       <Typography align="right" variant="h1">
                //                         {this.state.fareSummaryDetail && `${this.state.currency}`}
                //                         <Typography display="inline" variant="h1"
                //                         >
                //                           {this.state?.paymentStatus?.payment?.amount}
                //                           {/* {this.state.fareSummaryLst[0].basePrice} */}
                //                         </Typography>
                //                       </Typography>
                //                     </Grid>
                //                     <Grid item xs={12}>
                //                       <Divider variant="fullWidth" />
                //                     </Grid>
                //                     <Grid xs={12} item>
                //                       <Grid
                //                         container
                //                         justify="space-between"
                //                         alignItems="center"
                //                       >
                //                         <Grid item xs={4}>
                //                           <Typography
                //                             className={[
                //                               classes.openSans20,
                //                               classes.gutterBottom2,
                //                             ]}
                //                             color="textPrimary"
                //                             align="left"
                //                           >
                //                             {(flight?.Depature_DateTime &&
                //                               moment(
                //                                 flight?.Depature_DateTime
                //                               ).format("ddd, DD MMM")) ||
                //                               "NA"}
                //                           </Typography>
                //                           <Typography
                //                             className={classes.groteskBold24}
                //                             color="textPrimary"
                //                             align="left"
                //                           >
                //                             {" "}
                //                             Departure{" "}
                //                           </Typography>
                //                           <Typography
                //                             className={classes.openSans16}
                //                             color="textSecondary"
                //                             align="left"
                //                           >
                //                             {(flight?.Depature_DateTime &&
                //                               moment(
                //                                 (
                //                                   flight?.Depature_DateTime
                //                                 )
                //                               ).format("hh:mm A")) ||
                //                               "NA"}
                //                           </Typography>
                //                         </Grid>
                //                         <Grid item xs={3}>
                //                           <div className={classes.linewraper}>
                //                             <Typography
                //                               color="primary"
                //                               className={classes.groteskBold18}
                //                               style={{
                //                                 lineHeight: 0,
                //                               }}
                //                             >
                //                               {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                //                             </Typography>
                //                             <ul className={classes.container}>
                //                               <li className={classes.linkconnector} />
                //                               <li className={classes.linkconnector} />
                //                             </ul>

                //                           </div>
                //                         </Grid>
                //                         <Grid item xs={4}>
                //                           <Typography
                //                             className={[
                //                               classes.openSans20,
                //                               classes.gutterBottom2,
                //                             ]}
                //                             color="textPrimary"
                //                             align="right"
                //                           >
                //                             {" "}
                //                             {(flight?.Arrival_DateTime &&
                //                               moment(
                //                                 flight?.Arrival_DateTime
                //                               ).format("ddd, DD MMM")) ||
                //                               "NA"}
                //                           </Typography>
                //                           <Typography
                //                             className={classes.groteskBold24}
                //                             color="textPrimary"
                //                             align="right"
                //                           >
                //                             {" "}
                //                             Arrival{" "}
                //                           </Typography>
                //                           <Typography
                //                             className={classes.openSans16}
                //                             color="textSecondary"
                //                             align="right"
                //                           >
                //                             {(flight?.Arrival_DateTime &&
                //                               moment(
                //                                 (
                //                                   flight?.Arrival_DateTime
                //                                 )
                //                               ).format("hh:mm A")) ||
                //                               "NA"}
                //                           </Typography>
                //                         </Grid>
                //                         <Grid container justify="space-between">
                //                           <Grid item xs={6}>
                //                             <Typography
                //                               className={[
                //                                 classes.openSans16,
                //                                 classes.gutterTop2,
                //                               ]}
                //                               color="textPrimary"
                //                               align="left"
                //                             >
                //                               {" "}
                //                               {flight?.Boarding_Terminal ||
                //                                 "NA"}
                //                             </Typography>
                //                             <Typography
                //                               className={[
                //                                 classes.openSans16,
                //                                 classes.gutterBottom2,
                //                               ]}
                //                               color="textPrimary"
                //                               align="left"
                //                             >
                //                               {" "}
                //                               {flight?.Depature_City ||
                //                                 "NA"}
                //                             </Typography>
                //                           </Grid>
                //                           <Grid item xs={6}>
                //                             <Typography
                //                               className={[
                //                                 classes.openSans16,
                //                                 classes.gutterTop2,
                //                               ]}
                //                               color="textPrimary"
                //                               align="right"
                //                             >
                //                               {" "}
                //                               {flight?.Arriving_Terminal ||
                //                                 "NA"}
                //                             </Typography>
                //                             <Typography
                //                               className={[
                //                                 classes.openSans16,
                //                                 classes.gutterBottom2,
                //                               ]}
                //                               color="textPrimary"
                //                               align="right"
                //                             >
                //                               {" "}
                //                               {flight?.Arrival_City ||
                //                                 "NA"}
                //                             </Typography>
                //                           </Grid>
                //                         </Grid>
                //                       </Grid>
                //                     </Grid>

                //                   </Grid>
                //                 </Grid>
                //                 <Grid container key={flight?.flight_number} spacing={6}>
                //                   {index < (this.state?.paymentStatus?.payment?.note?.Flight_Details.length - 1) && 
                //                   <Grid item xs={12}>
                //                     <Grid item xs={12}>
                //                       <Divider variant="fullWidth" />
                //                     </Grid>
                //                     <Grid item xs={12}>
                //                       <Typography align="center" variant="h3" className={classes.layOver}>Layover {this.state?.entityDetail?.stops[index]?.stopDuration} |  {this.state?.entityDetail?.stops[index]?.stopName}</Typography>
                //                     </Grid>
                //                     <Grid item xs={12}>
                //                       <Divider variant="fullWidth" />
                //                     </Grid>
                //                   </Grid>}
                //                 </Grid>

                //                 {/* <Grid
                //                   container
                //                   key={flight?.flight_number}
                //                   spacing={6}
                //                 >
                //                   <Grid md={4} xs={12} item>
                //                     <ListItem style={{ padding: 0 }}>
                //                       <ListItemAvatar>
                //                         <img
                //                           className={classes.flightImage}
                //                           src={flight?.flight_logo}
                //                           style={{ marginRight: "10px" }}
                //                         />
                //                       </ListItemAvatar>
                //                       <ListItemText
                //                         classes={{
                //                           primary: classes.groteskBold24,
                //                           secondary: classes.openSans18,
                //                         }}
                //                         primary={flight?.flight_name || "NA"}
                //                         secondary={`${flight?.flight_carrier || "-"
                //                           } | ${flight?.flight_number || "-"}`}
                //                       />
                //                     </ListItem>
                //                   </Grid>
                //                   <Grid xs={12} md={8} item>
                //                     <Grid
                //                       container
                //                       justify="space-between"
                //                       alignItems="center"
                //                     >
                //                       <Grid item xs={4}>
                //                         <Typography
                //                           className={[
                //                             classes.openSans20,
                //                             classes.gutterBottom2,
                //                           ]}
                //                           color="textPrimary"
                //                           align="left"
                //                         >
                //                           {(flight?.depature_datetime &&
                //                             moment(
                //                               flight?.depature_datetime
                //                             ).format("ddd, DD MMM")) ||
                //                             "NA"}
                //                         </Typography>
                //                         <Typography
                //                           className={classes.groteskBold18}
                //                           color="textPrimary"
                //                           align="left"
                //                         >
                //                           {" "}
                //                           Departure{" "}
                //                         </Typography>
                //                         <Typography
                //                           className={classes.openSans16}
                //                           color="textSecondary"
                //                           align="left"
                //                         >
                //                           {(flight?.depature_datetime &&
                //                             moment(
                //                               flight?.depature_datetime
                //                             ).format("hh:mm A")) ||
                //                             "NA"}
                //                         </Typography>
                //                       </Grid>
                //                       <Grid item xs={3}>
                //                         <div className={classes.linewraper} onClick={(e) => { e.stopPropagation(); flight?.stops.length > 0 && this.openPopover(e, flight?.stops) }}>
                //                           <Typography
                //                             color="primary"
                //                             className={classes.groteskBold18}
                //                             style={{
                //                               lineHeight: 0,
                //                             }}
                //                           >
                //                             {this.timeDiff(
                //                               flight?.depature_datetime,
                //                               flight?.arrival_datetime
                //                             ) || "NA"}
                //                           </Typography>
                //                           <ul className={classes.container}>
                //                             <li className={classes.linkconnector} />
                //                             <li className={classes.linkconnector} />
                //                           </ul>
                //                         </div>
                //                       </Grid>
                //                       <Grid item xs={4}>
                //                         <Typography
                //                           className={[
                //                             classes.openSans20,
                //                             classes.gutterBottom2,
                //                           ]}
                //                           color="textPrimary"
                //                           align="right"
                //                         >
                //                           {" "}
                //                           {(flight?.arrival_datetime &&
                //                             moment(flight?.arrival_datetime).format(
                //                               "ddd, DD MMM"
                //                             )) ||
                //                             "NA"}
                //                         </Typography>
                //                         <Typography
                //                           className={classes.groteskBold18}
                //                           color="textPrimary"
                //                           align="right"
                //                         >
                //                           {" "}
                //                           Arrival{" "}
                //                         </Typography>
                //                         <Typography
                //                           className={classes.openSans16}
                //                           color="textSecondary"
                //                           align="right"
                //                         >
                //                           {(flight?.arrival_datetime &&
                //                             moment(flight?.arrival_datetime).format(
                //                               "hh:mm A"
                //                             )) ||
                //                             "NA"}
                //                         </Typography>
                //                       </Grid>
                //                       <Grid
                //                         item
                //                         xs={12}
                //                         container
                //                         justify="space-between"
                //                       >
                //                         <Grid item xs={6}>
                //                           <Typography
                //                             className={[
                //                               classes.openSans16,
                //                               classes.gutterTop2,
                //                             ]}
                //                             color="textPrimary"
                //                             align="left"
                //                           >
                //                             {" "}
                //                             {flight?.boarding_terminal || "NA"}
                //                           </Typography>
                //                           <Typography
                //                             className={[
                //                               classes.openSans16,
                //                               classes.gutterBottom2,
                //                             ]}
                //                             color="textPrimary"
                //                             align="left"
                //                           >
                //                             {" "}
                //                             {flight?.depature_city || "NA"}
                //                           </Typography>
                //                         </Grid>
                //                         <Grid item xs={6}>
                //                           <Typography
                //                             className={[
                //                               classes.openSans16,
                //                               classes.gutterTop2,
                //                             ]}
                //                             color="textPrimary"
                //                             align="right"
                //                           >
                //                             {" "}
                //                             {flight?.arriving_terminal || "NA"}
                //                           </Typography>
                //                           <Typography
                //                             className={[
                //                               classes.openSans16,
                //                               classes.gutterBottom2,
                //                             ]}
                //                             color="textPrimary"
                //                             align="right"
                //                           >
                //                             {" "}
                //                             {flight?.arrival_city || "NA"}
                //                           </Typography>
                //                         </Grid>
                //                       </Grid>
                //                     </Grid>
                //                   </Grid>
                //                   {index < (this.state.flightList.length - 1) && <Grid item xs={12}><Divider variant="inset" /></Grid>}
                //                 </Grid> */}

                //               </>);
                //           })}
                //       </Grid>

                //     </Grid>
                //     <Grid item xs={12}>
                //       <Grid container direction="row" alignItems="center">
                //         <Grid item xs={6}>
                //           <Typography
                //             className={classes.backToHome}
                //             onClick={() => {
                //               this.props.history.push("/")
                //             }}>BACK TO HOME</Typography>
                //         </Grid>
                //         <Grid item xs={6}>
                //           <Button className={classes.bookingDetails} fullWidth>
                //             view booking details
                //           </Button>

                //         </Grid>
                //       </Grid>
                //     </Grid>
                //   </Grid>
                // </Grid>
              ) : (
                <Grid key={"abcd"} item md={10} xs={11}>
                  <Grid container spacing={2} className={classes.flightDetailBox}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid md={3} xs={12} item>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <img
                                className={classes.hotelPrimaryImage}
                                src={hotelImage}
                              />
                            </Grid>
                            <Hidden xsDown>
                              <Grid item md="auto">
                                <img
                                  className={classes.hotelSecondaryImage}
                                  src={hotelImage}
                                />
                              </Grid>
                              <Grid item md="auto">
                                <img
                                  className={classes.hotelSecondaryImage}
                                  src={hotelImage}
                                />
                              </Grid>
                              <Grid item md="auto">
                                <img
                                  className={classes.hotelSecondaryImage}
                                  src={hotelImage}
                                />
                              </Grid>
                              <Grid item md={12} style={{ textAlign: "center" }}>
                                <img
                                  src={pIcon}
                                  style={{ height: "24px", width: "21px" }}
                                />
                                <img
                                  src={wifiIcon}
                                  style={{ height: "18px", width: "25px" }}
                                />
                                <img
                                  src={wheelChairIcon}
                                  style={{ height: "24px", width: "19px" }}
                                />
                                <img
                                  src={hotelIcon}
                                  style={{ height: "20px", width: "29px" }}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <Typography align="center" color="textPrimary">
                                  PNR: SDSDSS
                                </Typography>
                                <Typography
                                  gutterBottom
                                  align="center"
                                  color="textSecondary"
                                >
                                  Booking ID: 2112312SDWD
                                </Typography>
                                <Typography
                                  gutterBottom
                                  align="center"
                                  color="textSecondary"
                                >
                                  2 Adults
                                </Typography>
                                <Typography align="center" color="textPrimary">
                                  Mr. John + 9 Travellers
                                </Typography>
                              </Grid>
                            </Hidden>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={9} item>
                          <Box py={3}>
                            <Grid container justify="space-around" spacing={2}>
                              <Grid item md={8}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="left"
                                >
                                  Marriott Hotel
                                </Typography>
                                <StyledRating readOnly value={4} />
                              </Grid>
                              <Grid item md={4}>
                                <Typography color="textSecondary" align="right">
                                  1 Room | 2 Adults
                                </Typography>
                              </Grid>
                              <Grid item md={12}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="left"
                                >
                                  15 Al Habtoor City, Palm Jumeriah, Dubai
                                </Typography>
                              </Grid>
                              <Grid item md={12}>
                                <Divider variant="fullWidth" />
                              </Grid>

                              <Grid item xs={5}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  Fri, 12 Feb{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  Fri, 12 Feb{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Grid container justify="center">
                                  <Grid item xs={5}>
                                    <div className={classes.linewraper}>
                                      <Typography className={classes.lineHeading}>
                                        2 Nights
                                      </Typography>
                                      <ul className={classes.container}>
                                        <li className={classes.linkconnector} />
                                        <li className={classes.linkconnector} />
                                      </ul>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container justify="space-around">
                                  <Grid item xs={5}>
                                    <Typography
                                      className={classes.checkIn}
                                      color="textPrimary"
                                      align="left"
                                    >
                                      Check-In
                                    </Typography>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {" "}
                                      09:00 AM{" "}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography
                                      className={classes.checkIn}
                                      color="textPrimary"
                                      align="right"
                                    >
                                      Check-Out
                                    </Typography>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                      align="right"
                                    >
                                      {" "}
                                      09:00 AM{" "}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container justify="space-around">
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                    >
                                      Sandard Room Only For 2 Adults
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                    >
                                      — Breakfast Available, Pay At Property
                                    </Typography>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                    >
                                      — This booking is non refundable
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={12}>
                                <Grid container justify="space-between">
                                  <Grid item md={9}>
                                    <Typography>Rs. 4000</Typography>
                                    <Typography>Including Taxes</Typography>
                                  </Grid>
                                  <Grid item md={1}>
                                    <Button variant="contained" color="primary">
                                      Share
                                    </Button>
                                  </Grid>
                                  <Grid item md={1}>
                                    <Button variant="contained" color="primary">
                                      View
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* End hero unit */}
            <Footer></Footer>
          </div>
          {/* <div className={classes.root} onClick={() => {
            this.setState({
              openpoper: false
            })
          }}>
            <Popper open={this.state.openpoper} anchorEl={this.state.poperAchor} placement={'top-start'} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{ backgroundColor: '#1565d8', padding: '16px' }}>
                    {this.state.stops.map((stop) => {
                      return <>
                        <Typography style={{ color: '#fff' }} sx={{ p: 2 }}>{stop.stopType}</Typography>
                        <Typography style={{ color: '#fff' }} sx={{ p: 2 }} align="center">{stop.stopName} | {stop.stopDuration}</Typography>
                      </>
                    })}

                  </Paper>
                </Fade>
              )}
            </Popper>
            <Paper
              className={classes.mainPost}
              style={{
                backgroundImage:
                  this.state.entityType === "Hotels"
                    ? `url(${hotelCover})`
                    : `url(${flightCover})`,
                backgroundSize: "cover",
              }}
            >
              {
                <img
                  style={{ display: "none" }}
                  src={flightCover}
                  alt={"aaaaaaaaaaaaaaaa"}
                />
              }
              <div className={classes.overlay} />
              {this.state.role && <Header role={this.state.role} />}
              <Grid container justify="center">
                <Grid item md={8} xs={10}>
                  <Grid
                    container
                    spacing={4}
                    justify="center"
                    className={classes.mainPostContent}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.helloAdmin} align="center">
                        Payment Successful
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        spacing={1}
                      >
                        <Grid item md={11} xs={12}>
                          <LinearProgress
                            classes={{ root: classes.linearProgressBar }}
                            variant="determinate"
                            value={100}
                          />
                        </Grid>
                        <Grid item md={1} xs={6} style={{ textAlign: "center" }}>
                          <img
                            src={success}
                            style={{ height: "52px", width: "52px" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={2}>
                          <Typography
                            align="justify"
                            className={classes.progressText}
                          >
                            Flight Selection
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            align="justify"
                            className={classes.progressText}
                          >
                            Review
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            align="justify"
                            className={classes.progressText}
                          >
                            Traveller Details
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            align="justify"
                            className={classes.progressText}
                          >
                            Make Payment
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {this.state.entityType === "Hotels" ? (
                      <Grid item xs={11}>
                        <Typography align="center" className={classes.sourceName}>
                          15 Jan - 16 Jan | 1 Room | 2 Adults
                        </Typography>
                        <Typography align="center" className={classes.sourceName}>
                          Sandard Room Twin Beds
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={11}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={5}>
                              <Typography
                                align="center"
                                className={classes.sourceName}
                              >
                                {this.state?.fromCity?.city}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography
                                align="center"
                                className={classes.sourceName}
                              >
                                to
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                              <Typography
                                align="center"
                                className={classes.sourceName}
                              >
                                {this.state?.toCity?.city}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12} md={5}>
                              <Typography
                                align="center"
                                className={classes.airportName}
                              >
                                {this.state?.fromCity?.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography
                                align="center"
                                className={classes.airportName}
                              >
                                ○─○
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                              <Typography
                                align="center"
                                className={classes.airportName}
                              >
                                {this.state?.toCity?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              spacing={1}
            >
              {this.state.entityType === "Flights" ? (
                <Grid item md={10} xs={11}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.hotelDetailContainer}
                  >
                    <Grid item xs={10} md={3}>
                      <Grid container justify="space-between" spacing={2}>
                        <Grid md={12} xs={12} item>
                          <Box mt={2}>
                            <Typography
                              align="center"
                              color="textPrimary"
                              className={classes.pnr}
                              gutterBottom
                            >
                              PNR : {flightDetail?.pnr_number || "NA"}
                            </Typography>

                            <Typography
                              align="center"
                              color="textSecondary"
                              className={classes.bookingDetail}
                              gutterBottom
                            >
                              Booking ID : {flightDetail?.locator_code || "NA"}
                            </Typography>

                            <Typography
                              align="center"
                              color="textSecondary"
                              className={classes.bookingDetail}
                              gutterBottom
                            >
                              Booked On: {flightDetail?.booked_on || "NA"}
                            </Typography>
                          </Box>
                          <Box mt={2}>

                            <Typography
                              align="center"
                              color="textSecondary"
                              className={classes.pnr}
                              gutterBottom
                            >
                              Price: {flightDetail?.payment?.data?.attributes?.note?.currency} {flightDetail?.total_amount || "NA"}
                            </Typography>

                          </Box>
                          <Box style={{ textAlign: 'center' }} mt={2}>
                            <Button

                              color="primary"
                              variant="contained"
                              onClick={() => {
                                this.goToSelectedRoute(
                                  "/myBookings/Flight",
                                  this.state.entityDetail
                                );
                              }}
                            >
                              View
                            </Button>
                          </Box>


                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" />
                    <Grid xs={10} md={8}>
                      <Grid container spacing={3}>
                        {this.state.flightList.length > 0 &&
                          this.state.flightList.map((flight: any, index) => {
                            return (
                              <>
                                <Grid
                                  container
                                  key={flight?.flight_number}
                                  spacing={6}
                                >
                                  <Grid md={4} xs={12} item>
                                    <ListItem style={{ padding: 0 }}>
                                      <ListItemAvatar>
                                        <img
                                          className={classes.flightImage}
                                          src={flight?.flight_logo}
                                          style={{ marginRight: "10px" }}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        classes={{
                                          primary: classes.groteskBold24,
                                          secondary: classes.openSans18,
                                        }}
                                        primary={flight?.flight_name || "NA"}
                                        secondary={`${flight?.flight_carrier || "-"
                                          } | ${flight?.flight_number || "-"}`}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid xs={12} md={8} item>
                                    <Grid
                                      container
                                      justify="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item xs={4}>
                                        <Typography
                                          className={[
                                            classes.openSans20,
                                            classes.gutterBottom2,
                                          ]}
                                          color="textPrimary"
                                          align="left"
                                        >
                                          {(flight?.depature_datetime &&
                                            moment(
                                              flight?.depature_datetime
                                            ).format("ddd, DD MMM")) ||
                                            "NA"}
                                        </Typography>
                                        <Typography
                                          className={classes.groteskBold18}
                                          color="textPrimary"
                                          align="left"
                                        >
                                          {" "}
                                          Departure{" "}
                                        </Typography>
                                        <Typography
                                          className={classes.openSans16}
                                          color="textSecondary"
                                          align="left"
                                        >
                                          {(flight?.depature_datetime &&
                                            moment(
                                              flight?.depature_datetime
                                            ).format("hh:mm A")) ||
                                            "NA"}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className={classes.linewraper} onClick={(e) => { e.stopPropagation(); flight?.stops.length > 0 && this.openPopover(e, flight?.stops) }}>
                                          <Typography
                                            color="primary"
                                            className={classes.groteskBold18}
                                            style={{
                                              lineHeight: 0,
                                            }}
                                          >
                                            {this.timeDiff(
                                              flight?.depature_datetime,
                                              flight?.arrival_datetime
                                            ) || "NA"}
                                          </Typography>
                                          <ul className={classes.container}>
                                            <li className={classes.linkconnector} />
                                            <li className={classes.linkconnector} />
                                          </ul>
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography
                                          className={[
                                            classes.openSans20,
                                            classes.gutterBottom2,
                                          ]}
                                          color="textPrimary"
                                          align="right"
                                        >
                                          {" "}
                                          {(flight?.arrival_datetime &&
                                            moment(flight?.arrival_datetime).format(
                                              "ddd, DD MMM"
                                            )) ||
                                            "NA"}
                                        </Typography>
                                        <Typography
                                          className={classes.groteskBold18}
                                          color="textPrimary"
                                          align="right"
                                        >
                                          {" "}
                                          Arrival{" "}
                                        </Typography>
                                        <Typography
                                          className={classes.openSans16}
                                          color="textSecondary"
                                          align="right"
                                        >
                                          {(flight?.arrival_datetime &&
                                            moment(flight?.arrival_datetime).format(
                                              "hh:mm A"
                                            )) ||
                                            "NA"}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        container
                                        justify="space-between"
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            className={[
                                              classes.openSans16,
                                              classes.gutterTop2,
                                            ]}
                                            color="textPrimary"
                                            align="left"
                                          >
                                            {" "}
                                            {flight?.boarding_terminal || "NA"}
                                          </Typography>
                                          <Typography
                                            className={[
                                              classes.openSans16,
                                              classes.gutterBottom2,
                                            ]}
                                            color="textPrimary"
                                            align="left"
                                          >
                                            {" "}
                                            {flight?.depature_city || "NA"}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            className={[
                                              classes.openSans16,
                                              classes.gutterTop2,
                                            ]}
                                            color="textPrimary"
                                            align="right"
                                          >
                                            {" "}
                                            {flight?.arriving_terminal || "NA"}
                                          </Typography>
                                          <Typography
                                            className={[
                                              classes.openSans16,
                                              classes.gutterBottom2,
                                            ]}
                                            color="textPrimary"
                                            align="right"
                                          >
                                            {" "}
                                            {flight?.arrival_city || "NA"}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {index < (this.state.flightList.length - 1) && <Grid item xs={12}><Divider variant="inset" /></Grid>}
                                </Grid>

                              </>);
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid key={"abcd"} item md={10} xs={11}>
                  <Grid container spacing={2} className={classes.flightDetailBox}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid md={3} xs={12} item>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <img
                                className={classes.hotelPrimaryImage}
                                src={hotelImage}
                              />
                            </Grid>
                            <Hidden xsDown>
                              <Grid item md="auto">
                                <img
                                  className={classes.hotelSecondaryImage}
                                  src={hotelImage}
                                />
                              </Grid>
                              <Grid item md="auto">
                                <img
                                  className={classes.hotelSecondaryImage}
                                  src={hotelImage}
                                />
                              </Grid>
                              <Grid item md="auto">
                                <img
                                  className={classes.hotelSecondaryImage}
                                  src={hotelImage}
                                />
                              </Grid>
                              <Grid item md={12} style={{ textAlign: "center" }}>
                                <img
                                  src={pIcon}
                                  style={{ height: "24px", width: "21px" }}
                                />
                                <img
                                  src={wifiIcon}
                                  style={{ height: "18px", width: "25px" }}
                                />
                                <img
                                  src={wheelChairIcon}
                                  style={{ height: "24px", width: "19px" }}
                                />
                                <img
                                  src={hotelIcon}
                                  style={{ height: "20px", width: "29px" }}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <Typography align="center" color="textPrimary">
                                  PNR: SDSDSS
                                </Typography>
                                <Typography
                                  gutterBottom
                                  align="center"
                                  color="textSecondary"
                                >
                                  Booking ID: 2112312SDWD
                                </Typography>
                                <Typography
                                  gutterBottom
                                  align="center"
                                  color="textSecondary"
                                >
                                  2 Adults
                                </Typography>
                                <Typography align="center" color="textPrimary">
                                  Mr. John + 9 Travellers
                                </Typography>
                              </Grid>
                            </Hidden>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={9} item>
                          <Box py={3}>
                            <Grid container justify="space-around" spacing={2}>
                              <Grid item md={8}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="left"
                                >
                                  Marriott Hotel
                                </Typography>
                                <StyledRating readOnly value={4} />
                              </Grid>
                              <Grid item md={4}>
                                <Typography color="textSecondary" align="right">
                                  1 Room | 2 Adults
                                </Typography>
                              </Grid>
                              <Grid item md={12}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="left"
                                >
                                  15 Al Habtoor City, Palm Jumeriah, Dubai
                                </Typography>
                              </Grid>
                              <Grid item md={12}>
                                <Divider variant="fullWidth" />
                              </Grid>

                              <Grid item xs={5}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  Fri, 12 Feb{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  className={classes.date}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  Fri, 12 Feb{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Grid container justify="center">
                                  <Grid item xs={5}>
                                    <div className={classes.linewraper}>
                                      <Typography className={classes.lineHeading}>
                                        2 Nights
                                      </Typography>
                                      <ul className={classes.container}>
                                        <li className={classes.linkconnector} />
                                        <li className={classes.linkconnector} />
                                      </ul>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container justify="space-around">
                                  <Grid item xs={5}>
                                    <Typography
                                      className={classes.checkIn}
                                      color="textPrimary"
                                      align="left"
                                    >
                                      Check-In
                                    </Typography>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {" "}
                                      09:00 AM{" "}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography
                                      className={classes.checkIn}
                                      color="textPrimary"
                                      align="right"
                                    >
                                      Check-Out
                                    </Typography>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                      align="right"
                                    >
                                      {" "}
                                      09:00 AM{" "}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container justify="space-around">
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                    >
                                      Sandard Room Only For 2 Adults
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                    >
                                      — Breakfast Available, Pay At Property
                                    </Typography>
                                    <Typography
                                      className={classes.time}
                                      color="textSecondary"
                                    >
                                      — This booking is non refundable
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={12}>
                                <Grid container justify="space-between">
                                  <Grid item md={9}>
                                    <Typography>Rs. 4000</Typography>
                                    <Typography>Including Taxes</Typography>
                                  </Grid>
                                  <Grid item md={1}>
                                    <Button variant="contained" color="primary">
                                      Share
                                    </Button>
                                  </Grid>
                                  <Grid item md={1}>
                                    <Button variant="contained" color="primary">
                                      View
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* End hero unit */}
          <Footer></Footer>
          {/* </div> */}
        </>
      )
    ) : null;
  }
}

const PaymentWithRouter = withRouter(Payment);
const PaymentAlertBox = withAlertBox(PaymentWithRouter);
const PaymentLoader = withLoader(PaymentAlertBox);
const PaymentToast = withToast(PaymentLoader);
const PaymentWithDialog = withDialog(PaymentToast);
const PaymentWithFontStyles = withFontStyles(PaymentWithDialog);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    flightImage: {
      width: theme.spacing(7),
      height: theme.spacing(6.5),
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },

    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0,
      letterSpacing: "normal",
      textAlign: "center",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      textTransform: "capitalize",
      letterSpacing: "normal",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: " ",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: " ",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    tabText: {
      textTransform: "none",
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      textAlign: "center",
    },
    hotelPrimaryImage: {
      width: theme.spacing(28),
      height: theme.spacing(16.3),
    },
    hotelSecondaryImage: {
      width: theme.spacing(8.5),
      height: theme.spacing(5),
    },
    layOver: {
      padding: '2rem',
      color: '#1565D8'
    },
    backToHome: {
      color: '#1565D8',
      cursor: 'pointer',
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    bookingDetails: {
      color: "#FFFFFF",
      backgroundColor: '#1565D8',
      textTransform: 'capitalize',
      fontWeight: "500",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: '#1565D8',
      }
    },
    flightBox: {
      borderRadius: "5px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      backgroundColor: "rgba(255,255,255, 1)",
    },
    reviewFlightHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    flightBoxDetails: {
      padding: "20px",
    },
    flightImage: {
      width: "75px",
      height: "75px",
      marginRight: "30px",
      [theme.breakpoints.only("xs")]: {
        width: "60px",
        height: "60px",
        marginRight: "15px",
      },
    },
    flightTotalPrice: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
      },
    },
    departureSection: {
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    arrivalSection: {
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "20px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "24px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px 0px"
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "20px",
      },
    },
    bookingText: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1.2",
      fontStretch: "normal",
      letterSpacing: "0.2px",
    },
  })
)(withFontStyles(PaymentWithFontStyles));
