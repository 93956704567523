"use strict";


const baseURL = "https://sastotickets-33331-ruby.b33331.dev.eastus.az.svc.builder.ai";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
