//@ts-nocheck
import React from "react";
import InvoicesController, { Props } from "./InvoicesController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  IconButton,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import CachedIcon from "@material-ui/icons/Cached";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import AddNewTeamMember from "./AddNewTeamMember.web";
import withFontStyles from "./withFontStyles.web";

class Invoices extends InvoicesController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const tokenName = this.props.tokenName || "adminToken";
    const adminToken = await StorageProvider.get(tokenName);
    console.log(this.props)
    if (adminToken) {
      this.setState(
        {
            adminToken: adminToken,
        },
        () => {
            this.getInvoices();
        }
      );
    }
  }

  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };
  AddNewTeam = () => {
    this.props
      .openDialogBox({
        dataToPass: {},
        renderedComponent: AddNewTeamMember,
        title: "Add New Team Member",
        withCustomDialog: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: false,
        width: "500px",
      })
      .then(async (type: any) => {
        if (type === "memberAddedSuccssfully") {
          this.props.showToast({
            type: "success",
            message: "successfully.",
          });
        }
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="space-around" spacing={4}>
          <Grid item xs={11}>
            <Grid container className={classes.pendIngRequestBox} justify="center" alignItems="center">
              <Grid item xs={11}>
                <Typography className={classes.pendIngTite}>
                  Invoices
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={11}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <div>
                  <Typography display="inline">Rs.</Typography>
                  <Typography display="inline" className={classes.pendIngTite}>
                    1212
                  </Typography>
                </div>
                <Typography>Credit Avaialble</Typography>
              </div>
              <Typography gutterBottom className={classes.agencyemail}>
                To assign credits to an agency, please visit its profile
              </Typography>
            </div>
          </Grid> */}
          {this.state.invoiceList.map((invoice) => {
            return (
              <Grid item key={invoice.id} xs={11}>
                <Grid
                  container
                  justify="space-around"
                  alignItems="center"
                  className={classes.agencyPendingRequestContainer}
                >
                  <Grid xs={3} item>
                    <Typography align='center' className={classes.agencyName}>
                      Booked by
                    </Typography>
                    <Typography align='center' className={classes.agencyemail}>
                      {invoice.booked_by || 'NA'}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Typography align='center' className={classes.agencyName}>
                      PNR number
                    </Typography>
                    <Typography align='center' className={classes.agencyemail}>
                    {invoice.pnr_number || 'NA'}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Typography align='center' className={classes.agencyName}>
                      Travel date
                    </Typography>
                    <Typography align='center' className={classes.agencyemail}>
                    {invoice.travel_date || 'NA'}
                    </Typography>
                  </Grid>
                  <Grid xs={3} item>
                    <Typography align='center' className={classes.agencyName}>
                      Flight name
                    </Typography>
                    <Typography align='center' className={classes.agencyemail}>
                    {invoice.flight_name || 'NA'}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Button variant="contained" onClick={()=>{this.downloadInvoice(invoice.id)}}>
                      Download
                    </Button>
                    
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const InvoicesWithRouter = withRouter(Invoices);
const InvoicesAlertBox = withAlertBox(InvoicesWithRouter);
const InvoicesLoader = withLoader(InvoicesAlertBox);
const InvoicesToast = withToast(InvoicesLoader);
const InvoicesWithDialog = withDialog(InvoicesToast);
const InvoicesWithFontStyles = withFontStyles(InvoicesWithDialog);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: '100px'
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
      height: '91px'
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyemail: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewActivity: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    iconroot: {
      "& > *": {
        margin: theme.spacing(0.2),
      },
    },
  })
)(InvoicesWithFontStyles);
