//@ts-nocheck

//muspamirdo@yevme.com

// Test@1234
// yowinow136@64ge.com
import React from "react";
import LoginController, { Props } from "./LoginController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  DialogContent,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import { signUPPoster, Group } from "./assets";
// import { arrow, } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { TextInput } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withDialogBox from "../../../components/src/withDialog.web";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FilledInput from "@material-ui/core/FilledInput";

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required."),
  // .required("This field is required."),
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("Password is required"),
  // .required("This field is required"),
});

class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  visibilityPassword = () => {
    this.setState({ visibilityPassword: !this.state.visibilityPassword })
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <Formik
            initialValues={{
              email: "",
              password: "",
              keepMeSignedIn: false,
              showPassword: false,
            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              this.handleLogin(values);
            }}
          >
            {(formikProps) => {
              const { values, setFieldValue, isValid, dirty } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <Grid
                    container
                    justify="space-around"
                    alignItems="center"
                    style={{ padding: "0px", overflow: "hidden" }}
                  >
                    <Grid
                      item
                      md={6}
                      xs={false}
                      className={classes.dailogBackground}
                    // className={classes.dialogImage}
                    >
                      <Box py={8}>
                        <Grid container justify="center" alignItems="center">
                          <Grid xs={10} className={classes.loginImageGrid}>
                            <img src={Group} className={classes.loginImage} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              align="center"
                              className={classes.loginText}
                            >
                              Ut placet, inquam tum dicere exorsus est laborum
                              et molestiae consequatur, velillum.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box py={8}>
                        <Grid container justify="center" spacing={2}>
                          <Grid item xs={10}>
                            <Typography
                              variant="h3"
                              className={classes.textColor}
                            >
                              Welcome to Doolwa.Com
                            </Typography>
                          </Grid>

                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>
                              Email Address
                              <span className={classes.AsentrixSignColor}>
                                *
                              </span>
                            </Typography>
                            <Field
                              component={InputField}
                              fullWidth
                              // label="Email Address"
                              name="email"
                              placeholder="Enter Email Address"
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>
                              Password
                              <span className={classes.AsentrixSignColor}>
                                *
                              </span>
                            </Typography>
                            <Field
                              type={this.state.visibilityPassword ? 'text' : 'password'}
                              component={InputField}
                              fullWidth
                              // label="Password"
                              name="password"
                              placeholder="Enter Password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this.visibilityPassword()}
                                      edge="end"
                                    >
                                      {this.state.visibilityPassword ? <Visibility /> : <VisibilityOff />}

                                    </IconButton>
                                  </InputAdornment>
                                )

                              }}
                            />
                            {/* <Field
            component={TextField}
            variant="outlined"
            required
            fullWidth
            type={values.showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Enter Password"
            // onChange={handleChange}
            // onBlur={handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents="true">
                 {values.showPassword ? <Visibility onClick={(e, value) => {      
     formik.setFieldValue(
     'showPassword',true
     );
   }} /> : <VisibilityOff onClick={(e, value) => {
    formik.setFieldValue(
    'showPassword',false
    );
  }}/>}            
                </InputAdornment>
              ),
            }}
          /> */}
                          </Grid>
                          <Grid item xs={10}>
                            <div style={{ display: "flex", justifyContent:"end" }}>
                              {/* <FormControlLabel
                                style={{ flexGrow: 1 }}
                                control={<Checkbox />}
                                name="termsAndConditions"
                                checked={values.keepMeSignedIn}
                                onChange={() =>
                                  setFieldValue(
                                    "keepMeSignedIn",
                                    !values.keepMeSignedIn
                                  )
                                }
                                label="Keep me sign in"
                                labelPlacement="right"
                                classes={{
                                  label: classes.labelText,
                                }}
                              /> */}
                              <Typography
                                align="center"
                                display="inline"
                                className={classes.buttonText}
                                onClick={() => {
                                  this.closeDialog("forgotPassword");
                                }}
                              >
                                Forgot Password?
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={10}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              fullWidth
                              disabled={!(isValid && dirty)}
                            >
                              Sign In
                            </Button>
                          </Grid>
                          {this.props.dataToPass.role !== "Agency" ? (
                            <Grid item xs={10}>
                              <Typography
                                className={classes.labelText}
                                align="center"
                              // display="inline"
                              >
                                Don’t have an account?
                                <Typography
                                  style={{ paddingLeft: "8px" }}
                                  onClick={() => {
                                    this.closeDialog("signUp");
                                  }}
                                  className={classes.buttonText}
                                  display="inline"
                                >
                                  Sign Up
                                </Typography>
                              </Typography>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        {/* <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        <Formik
          initialValues={{
            email: "",
            password: "",
            keepMeSignedIn: false,
          }}
          // validationSchema={Schema}
          onSubmit={(values, actions) => {
            this.handleLogin(values);
          }}
        >
          {(formikProps) => {
            const { values, setFieldValue, isValid, dirty } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2} justify="space-between">
                  <Grid
                    item
                    md={6}
                    xs={false}
                    className={classes.dialogImage}
                  />
                  <Grid item md={6} xs={12}>
                    <Box py={8}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h1"
                            align="center"
                            className={classes.welcomeBack}
                          >
                            Welcome Back
                          </Typography>
                        </Grid>

                        <Grid item xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Email Address"
                            name="email"
                            placeholder="Enter Email Address"
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <Field
                            type="password"
                            component={InputField}
                            fullWidth
                            label="Password"
                            name="password"
                            placeholder="Enter Password"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              style={{ flexGrow: 1 }}
                              control={<Checkbox />}
                              name="termsAndConditions"
                              checked={values.keepMeSignedIn}
                              onChange={() =>
                                setFieldValue(
                                  "keepMeSignedIn",
                                  !values.keepMeSignedIn
                                )
                              }
                              label="Keep me sign in"
                              labelPlacement="right"
                              classes={{
                                label: classes.labelText,
                              }}
                            />
                            <Typography
                              align="center"
                              display="inline"
                              className={classes.buttonText}
                              onClick={() => {
                                this.closeDialog("forgotPassword");
                              }}
                            >
                              Forgot Password?
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={!(isValid && dirty)}
                          >
                            Sign In
                          </Button>
                        </Grid>
                        {this.props.dataToPass.role !== "Agency" ? (
                          <Grid item xs={10}>
                            <Typography
                              className={classes.labelText}
                              align="left"
                              display="inline"
                            >
                              Don’t have an account?
                            </Typography>
                            <Typography
                              style={{ paddingLeft: "8px" }}
                              align="center"
                              onClick={() => {
                                this.closeDialog("signUp");
                              }}
                              className={classes.buttonText}
                              display="inline"
                            >
                              Sign Up Now
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent> */}
      </>
    );
  }
}

const LoginWithRouter = withRouter(Login);
const LoginWithToast = withToast(LoginWithRouter);
const LoginWithLoader = withLoader(LoginWithToast);
const LoginWithAlertBox = withAlertBox(LoginWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      // fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      // backgroundImage: `url(${signUPPoster})`,
      background: "#F2F2F2",
      backgroundRepeat: "no-repeat",
      // backgroundColor:
      //   theme.palette.type === "light"
      //     ? theme.palette.grey[50]
      //     : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: "pointer",
    },
    textColor: {
      color: "#1E394E",
    },
    loginImage: {
      width: "15rem",
      height: "100%",
    },
    loginImageGrid: {
      marginLeft: "auto",
    },
    loginText: {
      margin: "auto",
      width: "15rem",
    },
    dailogBackground: {
      backgroundColor: "#F2F2F2",
      padding: "44px",
      overflow: "hidden",
    },
    labelAsentric: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#959ead",
      padding: "0.3rem",
    },
    AsentrixSignColor: {
      color: "#c20808",
    },
  })
)(withDialogBox(LoginWithAlertBox));
