import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  selectedType: String;
  selectedTime: string;
  bookingList: Array<any>;
  agancyDetail : any;
  creditTextField: string;
  subAgency : any,
  id : any,
  profileData : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubAgencyDetailsController extends BlockComponent<Props, S, SS> {
  loginApiEmailCallId: String = "";
  allBookingEndPointCallId: String = "";
  getAgencyStatusApiCallId:string = ""
  assignCreditsApiCallId: String = "";
  getProfileDetailApiCallID : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      selectedTime: "upcoming",
      selectedType: "All",
      bookingList: [],
      token: "",
      agancyDetail : null,
      creditTextField: '',
      subAgency : {},
      id : null,
      profileData : {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.allBookingEndPointCallId) {
        this.props.hideLoader();
          if (
            responseJson &&
            responseJson?.data 
          ) {
            this.setState({ bookingList: responseJson?.data || [] }, () => {console.log('booking list ===========', this.state.bookingList, this.state.selectedType, this.state.selectedTime)});
          } else {
            this.setState({
              bookingList : []
            })
            this.parseApiErrorResponse(responseJson);
          }
      } else if (apiRequestCallId === this.getAgencyStatusApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.agencies && responseJson?.agencies?.data) {
            this.setState({
              agancyDetail : responseJson?.agencies?.data?.attributes
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.assignCreditsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.data?.id) {
          this.props.showToast({
            type : 'success',
            message : 'Creadit assigned successfully'
          })
          this.setState({
            creditTextField : ''
          },()=>{
            this.getProfileDetail();
              this.getAgancy(this.state.id)
          })
      } else {
        this.parseApiErrorResponse(responseJson);
        }
      } else if(this.getProfileDetailApiCallID === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson?.agencies) {
          this.setState({
              profileData : responseJson?.agencies?.data?.attributes
          })
      } else {
          this.parseApiErrorResponse(responseJson)
      }
      }
    } 
  }

   getBookingsByApiAccountId = (accountId : number) => {
    this.props.showLoader();
    let url = "bx_block_dashboard/fight_lists_by_account";
    
    if (this.state.selectedType === "All") {
        url = `${url}?search=${this.state.selectedTime}&type=${'all'}&account_id=${accountId}`;
    } else if (this.state.selectedType === "Hotels") {
      url = `${url}?search=${this.state.selectedTime}&type=${'hotel'}&account_id=${accountId}`;
    } else if (this.state.selectedType === "Flights") {
      url = `${url}?search=${this.state.selectedTime}&type=${'flight'}&account_id=${accountId}`;
    }
    url = `${url}&per=${10}&page=${1}`;

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allBookingEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAgancy = (id:any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token : this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAgencyStatusApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/get_agency_credit_details?agency_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  assignCredits = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignCreditsApiCallId = requestMessage.messageId;
    const httpBody = {
        account_type:"sub_agency",
        data: {
          full_name:this.state.agancyDetail?.full_name,
          email: this.state.agancyDetail?.email,
          member_id : this.state.id,
          password: "this.state.agancyDetail?.password",
          confirm_password: "this.state.agancyDetail?.password",
          balance : this.state.creditTextField,
          full_phone_number:(this.state.agancyDetail?.full_phone_number),
          business_registration_number:(this.state.agancyDetail?.business_registration_number),
          permission_ntb_number:(this.state.agancyDetail?.permission_ntb_number),
          agency_name:this.state.agancyDetail?.agency_name,
          agency_address:this.state.agancyDetail?.agency_address,
          agency_phone_numbeer:(this.state.agancyDetail?.agency_phone_numbeer),
          pan_number:this.state.agancyDetail?.pan_number,
      },
      token : this.state.token
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `${"bx_block_dashboard/update_team_member"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProfileDetail = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getProfileDetailApiCallID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.agencyGetProfileData}?token=${this.state.token}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }

}
