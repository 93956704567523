//@ts-nocheck
import React from "react";
import ProfileChangePasswordController, {
  Props,
} from "./ProfileChangePasswordController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Divider,
  Switch,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
} from "@material-ui/core";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import { CheckBox } from "react-native";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';

const Schema = Yup.object().shape({
  password: Yup.string()
    // .min(8, "Enter atleast 8 characters Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("This field is required"),
  confirmPassword: Yup.string()
    // .min(8, "Enter atleast 8 characters Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

class ProfileChangePassword extends ProfileChangePasswordController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          // this.getBoatListing();
          // this.getBookings();
        }
      );
    }
    // this.getCountriesList();
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  visibilityPassword = ()=>{
    this.setState({visibilityPassword: !this.state.visibilityPassword})
  };
  visibilityPassword1 = ()=>{
    this.setState({visibilityPassword1: !this.state.visibilityPassword1})
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          this.handlePasswordChange(values);
        }}
      >
        {(formikProps) => {
          const { isValid, dirty } = formikProps;
          return (
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12}>
                <Grid container justify='center' alignItems='center' className={classes.userProfileBox}>
                  <Grid item md={11}>
                    <Typography className={classes.userProfileTitle}>
                      Change Password
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Form noValidate autoComplete="off">
                  <Grid container className={classes.EditProfileContainer}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.formSectionHeading}
                          >
                            Change Your Password
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.dividerStyle}></Divider>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item md={6} xs={12}>
                            {/* <Field
                              type="password"
                              component={InputField}
                              fullWidth
                              label="New Password"
                              name="password"
                              placeholder="Enter your new password"
                            /> */}
                          <Field
                             type={this.state.visibilityPassword?'text':'password'}
                              component={InputField}                                                                                  
                              fullWidth
                               label="New Password"
                              name="password"
                              placeholder="Enter your new password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=> this.visibilityPassword()}
                                    edge="end"
                                  >
                                   {this.state.visibilityPassword?<Visibility /> : <VisibilityOff />}
                                    
                                  </IconButton>
                                </InputAdornment>
                                )

                              }}
                           />
                            {/* <Field
                              type="password"
                              component={InputField}
                              fullWidth
                              label="Confirm New Password"
                              name="confirmPassword"
                              placeholder="Enter your new password"
                            /> */}
                            <Field
                             type={this.state.visibilityPassword1?'text':'password'}
                              component={InputField}                                                                                  
                              fullWidth
                              label="Confirm New Password"
                              name="confirmPassword"
                              placeholder="Enter your new password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=> this.visibilityPassword1()}
                                    edge="end"
                                  >
                                   {this.state.visibilityPassword1?<Visibility /> : <VisibilityOff />}
                                    
                                  </IconButton>
                                </InputAdornment>
                                )

                              }}
                           />

                          </Grid>
                        </Grid>
                        <Grid item md={4} xs={6}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={!(isValid && dirty)}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    );
  }
}

const ProfileChangePasswordWithRouter = withRouter(ProfileChangePassword);
const ProfileChangePasswordAlertBox = withAlertBox(
  ProfileChangePasswordWithRouter
);
const ProfileChangePasswordLoader = withLoader(ProfileChangePasswordAlertBox);
const ProfileChangePasswordToast = withToast(ProfileChangePasswordLoader);
const ProfileChangePasswordWithDialog = withDialog(ProfileChangePasswordToast);

export default withStyles((theme) =>
  createStyles({
    userProfileBox: {
      padding: "20px 0px",
      marginBottom: "20px",
      borderRadius: "5px",
      backgroundColor: "#EBEBEB",
    },
    userProfileTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    EditProfileContainer: {
      borderRadius: "5px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      backgroundColor: "#ffffff",
      padding: "30px",
      marginBottom: "50px",
    },
    formSectionHeading: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
  })
)(ProfileChangePasswordWithDialog);
