import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    tokenName: String;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  selectedType: String;
  token: string;
  selectedTime: string;
  bookingList: Array<any>;
  search: string;
  filterData : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class myBookingsController extends BlockComponent<Props, S, SS> {
  bookingEndPointCallId: String = "";
  allBookingEndPointCallId: String = "";
  flightBookingEndPointCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      selectedTime: "upcoming",
      selectedType: "Flights",
      token: "",
      bookingList: [],
      search: "",
      filterData : {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.bookingEndPointCallId) {
        this.props.hideLoader();
        var bookings = [];
        if (responseJson && responseJson?.data && responseJson?.data.length > 0) {
          for(var i = 0;i<responseJson?.data.length;i++){
            var booking = responseJson?.data[i];
            if(booking?.data?.attributes?.air_segment){
              
              const flightList = [...booking?.data?.attributes?.air_segment];
              const flightPolicyDetails = booking?.data?.attributes?.flight_details_hash ? [...booking?.data?.attributes?.flight_details_hash] : [];
              const flights = this.processData1(flightList,flightPolicyDetails);
              const oneWayFlightList = flights?.onewayflights.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight})
              const twoWayFlightList = flights?.twoWayFlights.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
              let onewayflightDetail = {}
              let twowayflightDetail = {}
              const flightListnew : any = []
              if(oneWayFlightList.length > 0){
                onewayflightDetail = this.processData(oneWayFlightList); 
                flightListnew.push(onewayflightDetail);
              } 
              if(twoWayFlightList.length > 0){
                twowayflightDetail = this.processData(twoWayFlightList); 
                flightListnew.push(twowayflightDetail);
              }
              bookings.push({...booking?.data?.attributes,flights : flightListnew,type : booking?.data.type})
            }
          }
          this.setState({ bookingList: bookings || [] })
        } else {
          await this.setState({
            bookingList: [],
          });
          this.parseApiErrorResponse(responseJson);
        }
      } else if (this.allBookingEndPointCallId === apiRequestCallId) {
        this.props.hideLoader();
        var bookings = [];
        if (responseJson && responseJson?.bookings && responseJson?.bookings.length > 0) {
          for(var i = 0;i<responseJson?.bookings.length;i++){
            var booking = responseJson?.bookings[i];
            var flightList = [...booking?.data?.attributes?.air_segment];
             var flightPolicyDetails = [...booking?.data?.attributes?.flight_details_hash];
             var flights = this.processData1(flightList,flightPolicyDetails);
             var oneWayFlightList = flights?.onewayflights.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight})
             var twoWayFlightList = flights?.twoWayFlights.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
             let onewayflightDetail = {}
             let twowayflightDetail = {}
             var flightListnew : any = []
             if(oneWayFlightList.length > 0){
               onewayflightDetail = this.processData(oneWayFlightList); 
               flightListnew.push(onewayflightDetail);
             } 
             if(twoWayFlightList.length > 0){
               twowayflightDetail = this.processData(twoWayFlightList); 
               flightListnew.push(twowayflightDetail);
             }
             bookings.push({...booking?.data?.attributes,flights : flightListnew,type : booking?.data.type})
           }
          this.setState({ bookingList: bookings  })
        } else {
          await this.setState({
            bookingList: [],
          });
          this.parseApiErrorResponse(responseJson);
        }
      } else if(this.flightBookingEndPointCallId === apiRequestCallId){
        this.props.hideLoader();
        var bookings = [];
        if (responseJson && responseJson?.bookings && responseJson?.bookings.length > 0) {
          for(var i = 0;i<responseJson?.bookings.length;i++){
            var booking = responseJson?.bookings[i];
            var flightList = [...booking?.data?.attributes?.air_segment];
             var flightPolicyDetails = [...booking?.data?.attributes?.flight_details_hash];
             var flights = this.processData1(flightList,flightPolicyDetails);
             var oneWayFlightList = flights?.onewayflights.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight})
             var twoWayFlightList = flights?.twoWayFlights.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
             let onewayflightDetail = {}
             let twowayflightDetail = {}
             var flightListnew : any = []
             if(oneWayFlightList.length > 0){
               onewayflightDetail = this.processData(oneWayFlightList); 
               flightListnew.push(onewayflightDetail);
             } 
             if(twoWayFlightList.length > 0){
               twowayflightDetail = this.processData(twoWayFlightList); 
               flightListnew.push(twowayflightDetail);
             }
             bookings.push({...booking?.data?.attributes,flights : flightListnew,type : booking?.data.type})
           }
          this.setState({ bookingList: bookings })
        } else {
          await this.setState({
            bookingList: [],
          });
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
    }
  }

  processData = (flights : any,a :any = {}) => {
        
      const flightnew = {...a};
      let flightIDs = flights.map((flight : any)=>{return flight.flight_number});
      flightIDs = Array.from(new Set(flightIDs)).join(",")
      flightnew.flight_number = flightIDs;
      flightnew.flight_carrier = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_carrier}))).join(",");
      flightnew.flight_logo = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_logo}))).join(",");
      flightnew.flight_name = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_name}))).join(",");
      const departueFlight = this.getFlightDepartureInfo(flights);
      flightnew.boarding_terminal = departueFlight?.boarding_terminal
      flightnew.depature_city = departueFlight?.depature_city
      flightnew.depature_date = departueFlight?.depature_date
      flightnew.depature_datetime = departueFlight?.depature_datetime
      flightnew.depature_iata = departueFlight?.depature_iata
      flightnew.depature_time = departueFlight?.depature_time;

      const returnFlight = this.getFlightRetrunInfo(flights);
      flightnew.arriving_terminal = returnFlight?.arriving_terminal
      flightnew.arrival_city = returnFlight?.arrival_city
      flightnew.arrival_date = returnFlight?.arrival_date
      flightnew.arrival_datetime = returnFlight?.arrival_datetime
      flightnew.arrival_iata = returnFlight?.arrival_iata
      flightnew.arrival_iata = returnFlight?.arrival_iata;
      
      if(flights.length > 1){
        flightnew.stops = this.stopCalculation(flights);
      } else {
        flightnew.stops = []
      }
      return (flightnew);
     }
      getFlightDepartureInfo = (airsagment : any) => {
      const flight = {...airsagment[0]};
      return flight; 
      }
      getFlightRetrunInfo = (airsagment : any) => {
      const flight = {...airsagment[airsagment.length - 1]};
      return flight;
      }
      processData1 = (flights : any,flightPolicy : any) => {
      const processFlightSearchData = [];
      for(let i=0;i<flights.length;i++){
          const flight = {...flights[i]};
          let airSagmentInfo = {};
          airSagmentInfo = flightPolicy.find((newflight : any) =>{return flight.flight_number == newflight.flight_id})
          processFlightSearchData.push({...flight,...airSagmentInfo});
      }
      return {
              onewayflights : processFlightSearchData.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight}),
              twoWayFlights : processFlightSearchData.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
            };
      }
  stopCalculation = (sagmentInfo : any) => {
  const stops = [];
  for(let i=0;i<sagmentInfo.length - 1;i++){
    const a = sagmentInfo[i];
    const b = sagmentInfo[i+1];
    const stop = {
      stopName : '',
      stopDuration : '',
      stopType : '',
      flight_type : a.flight_type
    }
    stop.stopName = a.arrival_city;
    stop.stopDuration = this.timeDiff(b.depature_datetime,a.arrival_datetime)
    stop.stopType = "Change Of Plane"
    stops.push(stop);
  }
  return stops
  }
  timeDiff = function (date1 : any, date2 : any) {
  var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds : 0,
        seconds : 0,
        minutes : 0,
        hours : 0
      };

  diff.milliseconds = a > b ? a % b : b % a;
  diff.seconds = diff.milliseconds / 1000;
  diff.minutes = diff.seconds / 60;
  diff.hours = diff.minutes / 60;
  const extraMin = diff.minutes % 60
  if(extraMin > 0){
    return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m"
  } else {
    return Math.floor(diff.hours) + " h"
  }
  }

  getBookingsByApiType = (apiType: string) => {
    this.props.showLoader();
    let url = "";
    if (apiType === "customerBookings") {
      this.state.search === ""
        ? (url = `${configJSON.getCustomerBookings}`)
        : (url = `${configJSON.getCustomerBookings}?search_filter=${this.state.search}`);
    } else if (apiType === "agenciesSubagenciesBookings") {
      this.state.search === ""
        ? (url = `${configJSON.getAgancySubAgancyBooking}`)
        : (url = `${configJSON.getAgancySubAgancyBooking}?search_filter=${this.state.search}`);
    } else if (apiType === "subagancymyBooking") {
      this.state.search === ""
        ? (url = "bx_block_dashboard/get_sub_agencies_booking")
        : (url = `bx_block_dashboard/get_sub_agencies_booking?search_filter=${this.state.search}`);
    }
    if (this.state.selectedType === "All") {
      this.state.search === ""
        ? (url = `${url}?search=${this.state.selectedTime}&type=${"all"}`)
        : (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"all"}&search_filter=${this.state.search}`);
    } else if (this.state.selectedType === "Hotels") {
      this.state.search === ""
        ? (url = `${url}?search=${this.state.selectedTime}&type=${"hotel"}`)
        : (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"hotel"}&search_filter=${this.state.search}`);
    } else if (this.state.selectedType === "Flights") {
      this.state.search === ""
        ? (url = `${url}?search=${this.state.selectedTime}&type=${"flight"}`)
        : (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"flight"}&search_filter=${this.state.search}`);
    }
    url = `${url}&per=${10}&page=${1}`;
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bookingEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getBookingsByApiAccountId = (accountId: number) => {
    this.props.showLoader();
    let url = "bx_block_dashboard/fight_lists_by_account";

    if (this.state.selectedType === "All") {
      this.state.search === ""
        ? (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"all"}&account_id=${accountId}`)
        : (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"all"}&account_id=${accountId}&search_filter=${
            this.state.search
          }`);
    } else if (this.state.selectedType === "Hotels") {
      this.state.search === ""
        ? (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"hotel"}&account_id=${accountId}`)
        : (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"hotel"}&account_id=${accountId}&search_filter=${
            this.state.search
          }`);
    } else if (this.state.selectedType === "Flights") {
      this.state.search === ""
        ? (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"flight"}&account_id=${accountId}`)
        : (url = `${url}?search=${
            this.state.selectedTime
          }&type=${"flight"}&account_id=${accountId}&search_filter=${
            this.state.search
          }`);
    }
    url = `${url}&per=${10}&page=${1}`;

    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bookingEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBookings(filterData : any) {
    this.setState({
      filterData : filterData
    },()=>{
      this.props.showLoader();
      let url = "";
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );;
      requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.bookingEndPointCallId = requestMessage.messageId;
      let type = 'all';
      if(this.state.selectedType === 'Flights'){
        type = 'flight'
      } else if(this.state.selectedType === 'Hotels'){
        type = 'hotel'
      }
      this.state.search === ""
        ? (url = `${configJSON.allBookingEndPoint}?search=${this.state.selectedTime}&type=${type}`)
        : (url = `${configJSON.allBookingEndPoint}?search=${this.state.selectedTime}&type=${type}&search_filter=${this.state.search}`);
        if(this.state.filterData){
        if(this.state.filterData.flightName && this.state.filterData.flightName.length > 0){
            url += `&search_filter[flight_name]=${this.state.filterData.flightName.join(",")}`
        } 
         if(this.state.filterData.flightType && this.state.filterData.flightType.length > 0){
          url += `&search_filter[type_of_ticket]=${this.state.filterData.flightType.join(",")}`
        } 
         if(this.state.filterData.bookingDate && this.state.filterData.bookingDate?.startDate && this.state.filterData.bookingDate?.endDate){
          url += `&search_filter[booking_date][from]=${this.state.filterData.bookingDate.startDate}&search_filter[booking_date][to]=${this.state.filterData.bookingDate.endDate}`
        } 
         if(this.state.filterData.travellerDate){

        }
      }
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    requestMessage?.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage?.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage?.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage?.id || "1", requestMessage);
    }) 
    
  }
}
