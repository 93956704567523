import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  adminToken : string;
  invoiceList : Array<any>,
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InvoicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getInvoicesApiCallId: String = "";
  donwloadInvoiceAPiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      adminToken : '',
      invoiceList : [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getInvoicesApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
            invoiceList : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(this.donwloadInvoiceAPiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson?.status && responseJson?.invoice_url) {
          //  const blob = await responseJson.blob();
          //  var url = window.URL.createObjectURL(responseJson);
              window.open(responseJson?.invoice_url)
            // var a = document.createElement('a');
            // a.href = responseJson?.invoice_url;
            // a.download = "ticket.pdf";
            // document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            // a.click();    
            // a.remove()
        } else {
          this.props.showToast({type : 'error',message :'Unable to download' })
          //this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }

  getInvoices() {
    this.props.showLoader();
    let url = "";
      url = `bx_block_invoice_billing/invoice_billing`
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.adminToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoicesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
   downloadInvoice = (bookingId : any) => {
      this.props.showLoader();
       let url = `bx_block_invoice_billing/flight_invoice?booking_id=${bookingId}`;
       let requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       this.donwloadInvoiceAPiCallId = requestMessage.messageId;
       
       const headers = {
         "token": this.state.adminToken,
         "Content-Type": "application/json"
       };
   
       requestMessage?.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage), url
       );
   
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
       );
   
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodGET
       );
       
       runEngine.sendMessage(requestMessage.id, requestMessage);
   }
}