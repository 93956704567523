import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken: any,
  searchData : any,
  entityDetail : any,
  fromCity : any,
  toCity : any,
  entityType : any,
  booking_id : string,
  travaller_id : string,
  loading : boolean,
  role : string,
  locator_code : string,
  openpoper : boolean,
  poperAchor : any,
  stops : [],
  flightList : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTicketApiCallId: String = "";
  getflightDetailApiCallId : String = "";
  get_flight_booking_detailApiCallId: String = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
        authToken: '',
        searchData : {},
        entityDetail : {},
        fromCity : {},
        toCity : {},
        entityType : {},
        booking_id : "",
        travaller_id : "",
        loading : true,
        role : '',
        locator_code : "",
        openpoper : false,
        poperAchor : null,
        stops : [],
        flightList : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getTicketApiCallId) {
        this.props.hideLoader();
        // if (responseJson?.nationalities) {
         
        // }
      } else if (apiRequestCallId === this.get_flight_booking_detailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.attributes) {
          const flightList = [...responseJson?.data?.attributes?.air_segment];
          const flightPolicyDetails = [...responseJson?.data?.attributes?.flight_details_hash];
          const flights = this.processData1(flightList,flightPolicyDetails);
          const selectedTravellingType = responseJson?.data?.attributes?.type_of_ticket
          const oneWayFlightList = flights?.onewayflights.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight})
          const oneWayStops = flights?.onewayflights.length > 0 ? this.stopCalculation(flights?.onewayflights) : []
          const twoWayFlightList = flights?.twoWayFlights.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
          const twoWayStops = flights?.twoWayFlights.length > 0 ? this.stopCalculation(flights?.twoWayFlights) : []
          let onewayflightDetail = {}
          let twowayflightDetail = {}
          const flightListnew : any = []
          if(oneWayFlightList.length > 0){
            onewayflightDetail = this.processData(oneWayFlightList); 
            flightListnew.push(onewayflightDetail);
          } 
          if(twoWayFlightList.length > 0){
            twowayflightDetail = this.processData(twoWayFlightList); 
            flightListnew.push(twowayflightDetail);
          }
          this.setState({
            entityDetail : {...responseJson?.data?.attributes},
            flightList : flightListnew,
          },()=>{
            this.setState({
              // flightStops : []
            })
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg  = await  this.props.showAlert({
        title: "Alert",
        message: AlertBodyMessage,
      })
      if(msg === 'Token has Expired' || msg === 'Invalid token'  ){
        await StorageProvider.clearStorage();
        this.props.history.replace("/")
      }
    }
  }
  
  getFlightDetail = () => {
    console.log(this.state.entityDetail);
    this.props.showLoader();
     let url = `${configJSON.get_flight_details}?carrier=${this.state.entityDetail?.flight_Carrier}&flight_number=${this.state.entityDetail?.flight_Id}&origin=${this.state.entityDetail?.Depature_Iata}&destination=${this.state.entityDetail?.Arrival_Iata}&departure_datetime=${this.state.entityDetail?.Depature_Time}&arrival_datetime=${this.state.entityDetail?.Arrival_Time}&class_of_service=Y`;
     let requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.getflightDetailApiCallId = requestMessage.messageId;
     
     const headers = {
       "Content-Type": configJSON.ApiContentType,
       "token": this.state.authToken
     };
 
     requestMessage?.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage), url
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(headers)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.methodGET
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
   
   }


   getBookingDetail = () => {
    this.props.showLoader();
     let url = `${configJSON.get_flight_booking_detail}?booking_id=${this.state.booking_id}`;
     let requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
     this.get_flight_booking_detailApiCallId = requestMessage.messageId;
     
     const headers = {
       "Content-Type": configJSON.ApiContentType,
       "token": this.state.authToken
     };
 
     requestMessage?.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage), url
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(headers)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.methodGET
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  processData = (flights : any,a :any = {}) => {
        
        const flightnew = {...a};
        let flightIDs = flights.map((flight : any)=>{return flight.flight_number});
        flightIDs = Array.from(new Set(flightIDs)).join(",")
        flightnew.flight_number = flightIDs;
        flightnew.flight_carrier = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_carrier}))).join(",");
        flightnew.flight_logo = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_logo}))).join(",");
        flightnew.flight_name = Array.from(new Set(flights.map((flight : any)=>{return flight.flight_name}))).join(",");
        const departueFlight = this.getFlightDepartureInfo(flights);
        flightnew.boarding_terminal = departueFlight?.boarding_terminal
        flightnew.depature_city = departueFlight?.depature_city
        flightnew.depature_date = departueFlight?.depature_date
        flightnew.depature_datetime = departueFlight?.depature_datetime
        flightnew.depature_iata = departueFlight?.depature_iata
        flightnew.depature_time = departueFlight?.depature_time;

        const returnFlight = this.getFlightRetrunInfo(flights);
        flightnew.arriving_terminal = returnFlight?.arriving_terminal
        flightnew.arrival_city = returnFlight?.arrival_city
        flightnew.arrival_date = returnFlight?.arrival_date
        flightnew.arrival_datetime = returnFlight?.arrival_datetime
        flightnew.arrival_iata = returnFlight?.arrival_iata
        flightnew.arrival_iata = returnFlight?.arrival_iata;
        
        if(flights.length > 1){
          flightnew.stops = this.stopCalculation(flights);
        } else {
          flightnew.stops = []
        }
        return (flightnew);
  }
  getFlightDepartureInfo = (airsagment : any) => {
    const flight = {...airsagment[0]};
    return flight; 
  }
  getFlightRetrunInfo = (airsagment : any) => {
    const flight = {...airsagment[airsagment.length - 1]};
    return flight;
  }
  processData1 = (flights : any,flightPolicy : any) => {
    const processFlightSearchData = [];
    for(let i=0;i<flights.length;i++){
        const flight = {...flights[i]};
        let airSagmentInfo = {};
        airSagmentInfo = flightPolicy.find((newflight : any) =>{return flight.flight_number == newflight.flight_id})
        processFlightSearchData.push({...flight,...airSagmentInfo});
    }
    return {
            onewayflights : processFlightSearchData.filter((flight:any)=>{return flight?.flight_type === 'departure' && flight}),
            twoWayFlights : processFlightSearchData.filter((flight:any)=>{return flight?.flight_type === 'return' && flight})
          };
  }
  stopCalculation = (sagmentInfo : any) => {
    const stops = [];
    for(let i=0;i<sagmentInfo.length - 1;i++){
      const a = sagmentInfo[i];
      const b = sagmentInfo[i+1];
      const stop = {
        stopName : '',
        stopDuration : '',
        stopType : '',
        flight_type : a.flight_type
      }
      stop.stopName = a.arrival_city;
      stop.stopDuration = this.timeDiff(b.depature_datetime,a.arrival_datetime)
      stop.stopType = "Change Of Plane"
      stops.push(stop);
    }
    return stops
  }
  timeDiff = function (date1 : any, date2 : any) {
    var a = new Date(date1).getTime(),
        b = new Date(date2).getTime(),
        diff = {
          milliseconds : 0,
          seconds : 0,
          minutes : 0,
          hours : 0
        };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if(extraMin > 0){
      return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m"
    } else {
      return Math.floor(diff.hours) + " h"
    }
  }
  
  
}
