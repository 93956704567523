//@ts-nocheck
import React from "react";
import SignUpController, { Props } from "./SignUpController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  DialogContent,
} from "@material-ui/core";
import { signUPPoster, Group } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';

const Schema = Yup.object().shape({
  // fullName: Yup.string().required("This field is required.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  first_name: Yup.string().required("This field is required.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string().required("This field is required.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email is required."),
  // .required("This field is required."),
  password: Yup.string()
    // .min(8, "Enter atleast 8 characters Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required."),
  // .required("This field is required"),
  confirmPassword: Yup.string()
    // .min(8, "Enter atleast 8 characters Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(" Confirm password is required."),
  // .required("This field is required"),
  termsAndConditions: Yup.boolean().oneOf([true], 'Accept terms and condtions to continue').required('Accept terms and condtions to continue'),
});

class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  visibilityPassword = () => {
    this.setState({ visibilityPassword: !this.state.visibilityPassword })
  };
  visibilityPassword1 = () => {
    this.setState({ visibilityPassword1: !this.state.visibilityPassword1 })
  };



  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <Formik
            initialValues={{
              // fullName: this.props?.dataToPass?.values?.fullName || "",
              first_name: this.props?.dataToPass?.values?.first_name || "",
              last_ame: this.props?.dataToPass?.values?.last_name || "",
              email: this.props?.dataToPass?.values?.email || "",
              password: this.props?.dataToPass?.values?.password || "",
              confirmPassword: this.props?.dataToPass?.values?.confirmPassword || "",
              termsAndConditions: this.props?.dataToPass?.values?.termsAndConditions || false,
            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              this.handleSignUp(values);
            }}
          >
            {(formikProps) => {
              const { values, setFieldValue, isValid, dirty } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <Grid container justify="space-around" alignItems="center" style={{ padding: "0px", overflow: "hidden" }}>
                    <Grid
                      item
                      md={6}
                      xs={false}
                      className={classes.dailogBackground}
                    >
                      <Box py={8}>
                        <Grid container justify="center" alignItems="center">
                          <Grid xs={10} className={classes.loginImageGrid}>
                            <img src={Group} className={classes.loginImage} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h5" align="center" className={classes.loginText}>
                              Ut placet, inquam tum dicere exorsus est laborum et molestiae consequatur, velillum.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box py={2}>
                        <Grid container justify="center">
                          <Grid item xs={10}>
                            <Typography
                              variant="h1"
                              className={classes.welcomeBack}
                            >
                              Get Started!
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>Full Name
                              <span className={classes.AsentrixSignColor}>*</span>
                            </Typography>
                            <Field
                              component={InputField}
                              fullWidth
                              // label="Full Name"
                              name="fullName"
                              placeholder="Enter Full Name"
                            />
                          </Grid> */}
                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>First Name
                              <span className={classes.AsentrixSignColor}>*</span>
                            </Typography>
                            <Field
                              component={InputField}
                              fullWidth
                              // label="Full Name"
                              name="first_name"
                              placeholder="Enter First Name"
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>Last Name
                              <span className={classes.AsentrixSignColor}>*</span>
                            </Typography>
                            <Field
                              component={InputField}
                              fullWidth
                              // label="Full Name"
                              name="last_name"
                              placeholder="Enter Last Name"
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>Email Address
                              <span className={classes.AsentrixSignColor}>*</span>
                            </Typography>
                            <Field
                              component={InputField}
                              fullWidth
                              // label="Email Address"
                              name="email"
                              placeholder="Enter Email Address"
                            />
                          </Grid>

                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>Password
                              <span className={classes.AsentrixSignColor}>*</span>
                            </Typography>
                            {/* <Field
                              type="password"
                              component={InputField}
                              fullWidth
                              // label="Password"
                              name="password"
                              placeholder="Enter Password"
                            /> */}
                            <Field
                              type={this.state.visibilityPassword ? 'text' : 'password'}
                              component={InputField}
                              fullWidth
                              // label="Password"
                              name="password"
                              placeholder="Enter Password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this.visibilityPassword()}
                                      edge="end"
                                    >
                                      {this.state.visibilityPassword ? <Visibility /> : <VisibilityOff />}

                                    </IconButton>
                                  </InputAdornment>
                                )

                              }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography className={classes.labelAsentric}>Confirm Password
                              <span className={classes.AsentrixSignColor}>*</span>
                            </Typography>
                            {/* <Field
                              type="password"
                              component={InputField}
                              fullWidth
                              // label="Confirm Password"
                              name="confirmPassword"
                              placeholder="Re Enter Password"
                            /> */}
                            <Field
                              type={this.state.visibilityPassword1 ? 'text' : 'password'}
                              component={InputField}
                              fullWidth
                              // label="Password"
                              name="confirmPassword"
                              placeholder="Re Enter Password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => this.visibilityPassword1()}
                                      edge="end"
                                    >
                                      {this.state.visibilityPassword1 ? <Visibility /> : <VisibilityOff />}

                                    </IconButton>
                                  </InputAdornment>
                                )

                              }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <FormControlLabel
                              style={{ flexGrow: 1 }}
                              control={<Checkbox />}
                              name="termsAndConditions"
                              checked={values.termsAndConditions}
                              onChange={() =>
                                setFieldValue("termsAndConditions", !values.termsAndConditions)
                              }
                              label="I agree with terms & conditions"
                              labelPlacement="right"
                              classes={{
                                label: classes.labelText,
                              }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              fullWidth
                              disabled={!(isValid && dirty)}
                            >
                              Sign Up
                            </Button>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              className={classes.labelText}
                              align="center"
                            >
                              Already have an account?
                              <Typography
                                style={{ paddingLeft: "8px" }}
                                display='inline'
                                className={classes.buttonText}
                                onClick={() => {
                                  this.closeDialog("signIN");
                                }}
                              >
                                Sign In
                              </Typography>
                            </Typography>

                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        {/* <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        <Formik
          initialValues={{
            fullName: this.props?.dataToPass?.values?.fullName || "",
            email: this.props?.dataToPass?.values?.email || "",
            password: this.props?.dataToPass?.values?.password || "",
            confirmPassword: this.props?.dataToPass?.values?.confirmPassword || "",
            termsAndConditions: this.props?.dataToPass?.values?.termsAndConditions || false,
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            this.handleSignUp(values);
          }}
        >
          {(formikProps) => {
            const { values, setFieldValue, isValid, dirty } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2} justify="space-between">
                  <Grid
                    item
                    md={6}
                    xs={false}
                    className={classes.dialogImage}
                  />
                  <Grid item md={6} xs={12}>
                    <Box py={2}>
                      <Grid container justify="center">
                        <Grid item xs={12}>
                          <Typography
                            variant="h1"
                            align="center"
                            className={classes.welcomeBack}
                          >
                            Get Started
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Your Name"
                            name="fullName"
                            placeholder="Enter Full Name"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Email Address"
                            name="email"
                            placeholder="Enter Email Address"
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <Field
                            type="password"
                            component={InputField}
                            fullWidth
                            label="Password"
                            name="password"
                            placeholder="Enter Password"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Field
                            type="password"
                            component={InputField}
                            fullWidth
                            label="Confirm Password"
                            name="confirmPassword"
                            placeholder="Re Enter Password"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <FormControlLabel
                            style={{ flexGrow: 1 }}
                            control={<Checkbox />}
                            name="termsAndConditions"
                            checked={values.termsAndConditions}
                            onChange={() =>
                              setFieldValue("termsAndConditions", !values.termsAndConditions)
                            }
                            label="Agree to terms and conditions"
                            labelPlacement="right"
                            classes={{
                              label: classes.labelText,
                            }}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.termsText
                            }
                            onClick={() => {
                              this.openTermsAndConditions(values);
                            }}
                          >
                            Read Terms & Conditions
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={!(isValid && dirty)}
                            >
                            Sign Up
                          </Button>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            className={classes.labelText}
                            align="left"
                            display="inline"
                          >
                            Already have an account?
                          </Typography>
                          <Typography
                            style={{ paddingLeft: "8px" }}
                            align="center"
                            className={classes.buttonText}
                            display="inline"
                            onClick={() => {
                              this.closeDialog("signIN");
                            }}
                          >
                            Sign In Now
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent> */}
      </>
    );
  }
}
const SignUpWithRouter = withRouter(SignUp);
const SignUpWithToast = withToast(SignUpWithRouter);
const SignUpWithLoader = withLoader(SignUpWithToast);
const SignUpWithAlertBox = withAlertBox(SignUpWithLoader);
const SignUpWithDialogBox = withDialog(SignUpWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundImage: `url(${signUPPoster})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: 'pointer'
    },
    termsText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: 'pointer'
    },
    textColor: {
      color: '#1E394E',
    },
    loginImage: {
      width: '15rem',
      height: '100%'
    },
    loginImageGrid: {
      marginLeft: 'auto'
    },
    loginText: {
      margin: 'auto',
      width: '15rem',
    },
    dailogBackground: {
      backgroundColor: '#F2F2F2',
      padding: '44px',
      overflow: 'hidden'

    },
    labelAsentric: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#959ead',
      padding: '0.3rem'
    },
    AsentrixSignColor: {
      color: "#c20808",
      paddingLeft: "2px",
    }
  })
)(SignUpWithDialogBox);
