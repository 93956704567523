import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
    DialogProps &
    withAlertBoxProps &
    withToastProps &
    withLoaderProps & {
        id: string;
        // Customizable Area Start
        // Customizable Area End
    };

interface S {
    email: any,
    password: any,
    token: any,
    // Customizable Area Start
    otpCode: any,
    // Customizable Area End
}

interface SS {
    id: any;
}
export type loginTypeValue = {
    email: String;
    password: String;
    unique_auth_id?: String;
};
export default class EnterOTPController extends BlockComponent<
    Props,
    S,
    SS
> {
    enterOtpApiCallId: String = "";
    resendOTPApiCallId: String = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
            // Customizable Area End
        ];

        this.state = {
            email: "",
            password: "",
            token: "",
            otpCode: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId === this.enterOtpApiCallId) {
                this.props.hideLoader();
                if (responseJson?.status === true) {
                    this.props.onSubmit('enterOtpSuccessfull');
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
            } else if (apiRequestCallId === this.resendOTPApiCallId) {
                this.props.hideLoader();
                if (responseJson?.status === 'true') {
                    this.props.onSubmit("resendOtp");
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
            }
        } else if (getName(MessageEnum.AlertMessage) === message.id) {
            const title = message.getData(getName(MessageEnum.AlertTitleMessage));
            var AlertBodyMessage = message.getData(
                getName(MessageEnum.AlertBodyMessage)
            );
            this.props.showAlert({
                title: "Alert",
                message: AlertBodyMessage,
            });
        }
    }

    handleEnterOtp = (code: any) => {
        this.props.showLoader();
        const headers = {
            "Content-Type": configJSON.ApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.enterOtpApiCallId = requestMessage.messageId;
        const httpBody = {
            data: {
                "email": this.props.dataToPass.email,
                "otp": code,
            },
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userVerifyOTPEndpoints}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodPUT
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResendOTP = () => {
        this.props.showLoader();
        this.setState({ email: this.props.dataToPass.email });
        const headers = {
            "Content-Type": configJSON.ApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resendOTPApiCallId = requestMessage.messageId;
        const httpBody = {
            email: this.props.dataToPass.email,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userForgotPasswordEndpoint}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodPUT
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
}
