//@ts-nocheck
import React from "react";
import EnterOTPController, {
    Props,
} from "./EnterOTPController.web";
import {
    withStyles,
    Grid,
    Typography,
    createStyles,
    Button,
    Paper,
    Link,
    Box,
    Container,
    TextField,
    DialogContent,
} from "@material-ui/core";
import { forgotBackground, forgotLogo, signUPPoster } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { TextInput } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";

const Schema = Yup.object().shape({
    otpCode: Yup.string().required("This field is required."),
});

class EnterOTP extends EnterOTPController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({
            ...this.state,
            otpCode: "",
        })
    }

    goToSelectedRoute = (routeName) => {
        const { history } = this.props;
        history.push(routeName);
    };

    closeDialog = (data) => {
        const { onSubmit } = this.props;
        onSubmit(data);
    }

    handleChange = (code) => {
        this.setState({
            otpCode: code,
        })
    };

    render() {
        const { classes } = this.props;
        return (
            <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
                <Formik
                    initialValues={{
                        OTPCode: "",
                    }}
                    validationSchema={Schema}
                >
                    {(formikProps) => {
                        const { isValid, dirty } = formikProps;
                        return (
                            <Form noValidate autoComplete="off">
                                <Grid container justify="space-between" style={{ padding: "0px", overflow: "hidden" }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={false}
                                        className={classes.dailogBackground}
                                    // className={classes.dialogImage}
                                    >
                                        <Grid container justify="center" alignItems="center">
                                            <Grid xs={10} className={classes.loginImageGrid}>
                                                <img src={forgotLogo} className={classes.loginImage} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Box py={8}>
                                            <Grid container justify="center" spacing={2}>
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="h2"
                                                        className={classes.welcomeBack}
                                                        gutterBottom
                                                    >
                                                        Enter OTP
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="h5"
                                                    >
                                                        Enter OTP
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <OtpInput
                                                        value={this.state?.otpCode}
                                                        onChange={this.handleChange}
                                                        name="OTPCode"
                                                        numInputs={6}
                                                        separator={<span style={{ width: "8px" }}></span>}
                                                        isInputNum={true}
                                                        shouldAutoFocus={true}
                                                        inputStyle={{
                                                            borderRadius: "8px",
                                                            border: "1px solid rgba(195,202,217, 1)",
                                                            width: "50px",
                                                            height: "40px",
                                                            fontSize: "18px",
                                                            fontWeight: "400",
                                                            caretColor: "blue"
                                                        }}
                                                        focusStyle={{
                                                            border: "1px solid #CFD3DB",
                                                            outline: "none"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={10} style={{ textAlign: "end" }}>
                                                    <Typography
                                                        align="end"
                                                        display="inline"
                                                        className={classes.buttonText}
                                                        onClick={() => {this.handleResendOTP(this.props.dataToPass.email)}}
                                                    >
                                                        Resend OTP?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Button
                                                        color="primary"
                                                        // type="submit"
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={this.state?.otpCode.length != 6}
                                                        onClick={()=>{this.handleEnterOtp(this.state.otpCode)}}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        );
    }
}

const EnterOTPWithRouter = withRouter(EnterOTP);
const EnterOTPWithToast = withToast(EnterOTPWithRouter);
const EnterOTPWithLoader = withLoader(EnterOTPWithToast);
const EnterOTPWithAlertBox = withAlertBox(EnterOTPWithLoader);

export default withStyles((theme) =>
    createStyles({
        dialogStyle: {
            padding: theme.spacing(0),
            height: theme.spacing(60),
        },
        welcomeBack: {
            fontFamily: "Open Sans",
            fontSize: "32px",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.33,
            letterSpacing: "normal",
        },
        labelText: {
            fontFamily: "Open Sans",
            fontSize: "12px",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.43,
            letterSpacing: "normal",
            color: "rgba(0, 0, 0, 0.6)",
        },
        dialogImage: {
            backgroundImage: `url(${signUPPoster})`,
            backgroundRepeat: "no-repeat",
            backgroundColor:
                theme.palette.type === "light"
                    ? theme.palette.grey[50]
                    : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: theme.spacing(1),
        },
        buttonText: {
            fontFamily: "Open Sans",
            fontSize: "12px",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 3.38,
            letterSpacing: "normal",
            fontWeight: "bold",
            color: "#1570dd",
            cursor: 'pointer'
        },
        loginImage: {
            width: '15rem',
            height: '100%',
        },
        loginImageGrid: {
            margin: 'auto',
            marginTop: '35%'
        },
        dailogBackground: {
            backgroundImage: `url(${forgotBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: '100%',
            padding: '44px',
            overflow: 'hidden'
        },
    })
)(EnterOTPWithAlertBox);
