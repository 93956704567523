//@ts-nocheck
import React from "react";
import HotelSearchResultController, {
  Props,
} from "./HotelSearchResultController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Drawer,
} from "@material-ui/core";
import Header from "./Header.web";
import Footer from "./Footer.web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { hotelImage, flightImage,imageCheckBox } from "./assets";
import { withRouter } from "react-router";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";



const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();

const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
})(Rating);

const drawerWidth = 240;
const hotelSchema = Yup.object().shape({
  hotelName: Yup.string().required("This field is required."),
  noOfRooms: Yup.number().required("This field is required"),
  noOfGuests: Yup.number().required("This field is required"),
  checkIn: Yup.date()
    .max(Yup.ref("checkOut"), "Checkin date can't be after checkout date")
    .required("This field is required."),
  checkOut: Yup.date()
    .min(Yup.ref("checkIn"), "Checkout date can't be before checkin date")
    .required("This field is required."),
});
class HotelSearchResult extends HotelSearchResultController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    this.setState(
      {
        searchData: state,
      },
      () => {
        this.hotelSearch();
      }
    );
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url('https://source.unsplash.com/random')`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={"https://source.unsplash.com/random"}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header />
          <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              SlideProps={{ unmountOnExit: true }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              <div
                style={{
                  borderRadius: "6px",
                  border: "solid 1px #b3bac5",
                }}
              >
                {" "}
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Drawer>
          </Hidden>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.hotelName}
                  align="center"
                  gutterBottom
                >
                  Hotels, Villas, Apartments and more in Maldives
                </Typography>
                <div style={{ textAlign: "center", margin: "24px auto" }}>
                  <Typography align="center">
                    We request you to keep track of latest guideline and
                    restrictions.
                  </Typography>
                </div>
                <div style={{ textAlign: "center", margin: "24px auto" }}>
                  <Typography align="center">
                    We request you to keep track of latest guideline and
                    restrictions.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={10}>
            <Formik
                  enableReinitialize
                  initialValues={this.props?.history?.location?.state?.data ? this.props.history.location.state.data : {
                    hotelName: "",
                            checkIn: "",
                            checkOut: "",
                            noOfRooms: "",
                            noOfGuests: "",
                  }}
                  validationSchema={hotelSchema}
                  onSubmit={(values) => {
                    // this.goToSelectedRoute("/flightSearchResult", values);
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                    } = formikProps;
                    this.submitForm = handleSubmit;
                    return (
                      <Form autoComplete="off">
                        <Grid
                          container
                          spacing={2}
                          justify="space-evenly"
                          alignItems="center"
                          className={classes.mainPostSecondaryContent}
                        >
                          <Grid item xs={6} md={2}>
                          <Field
                                      component={InputFieled}
                                      name="hotelName"
                                      placeholder="Enter City / Hotel / Area / Building"
                                      // onChange={handleChange}
                                      value={values.hotelName}
                                      fullWidth
                                      label="City / Hotel / Area / Building "
                                    />
                          </Grid>
                          <Grid item xs={6} md={2}>
                          <Field
                                      component={InputFieled}
                                      name="checkIn"
                                      type="date"
                                      placeholder="Enter Check-In Date"
                                      // onChange={handleChange}
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      value={values.checkIn}
                                      fullWidth
                                      label="Check In"
                                    />
                          </Grid>
                          <Grid item xs={6} md={2}>
                          <Field
                                      component={InputFieled}
                                      name="checkOut"
                                      type="date"
                                      placeholder="Enter Check In Date"
                                      // onChange={handleChange}
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      value={values.checkOut}
                                      fullWidth
                                      label="Check Out"
                                    />
                          </Grid>
                          <Grid item xs={6} md={2}>
                          <Field
                                      component={InputFieled}
                                      name="noOfGuests"
                                      type="number"
                                      placeholder="Enter No. of Guest"
                                      // onChange={handleChange}
                                      value={values.noOfGuests}
                                      fullWidth
                                      label="Guests"
                                    />
                          </Grid>
                          <Grid md={2} item>
                                    <Field
                                      component={InputFieled}
                                      name="noOfRooms"
                                      type="number"
                                      placeholder="Enter No. of Rooms"
                                      // onChange={handleChange}
                                      value={values.noOfRooms}
                                      fullWidth
                                      label="Rooms"
                                    />
                                  </Grid>
                                  <Grid md={2} item>
                                    {/* <Box py={3}> */}
                                      <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{height: '56px'}}
                                      >
                                        MODIFY SEARCH
                                      </Button>
                                    {/* </Box> */}
                                  </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid
          container
          spacing={2}
          className={classes.containerPosition}
          justify="space-around"
        >
          <Grid item md={11} xs={11}>
            <Typography gutterBottom>
              Showing {this.state.hotelList.length} properties available
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <div className={classes.sortIngButton}>
                  <Typography className={classes.sortIconTitle}>
                    Lowest Price
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.sortIngButton}>
                  <Typography className={classes.sortIconTitle}>
                    Top Picks
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.sortIngButton}>
                  <Typography className={classes.sortIconTitle}>
                    Highest Rated
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.sortIngButton}>
                  <Typography className={classes.sortIconTitle}>
                    Highest Price
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item md={3} xs={false}>
              <div
                style={{
                  borderRadius: "6px",
                  border: "solid 1px #b3bac5",
                }}
              >
                {" "}
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.filterformControl}
                >
                  <FormLabel component="legend">
                    Assign responsibility
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="gilad" />}
                      label="Gilad Gray"
                    />
                    <FormControlLabel
                      control={<Checkbox name="jason" />}
                      label="Jason Killian"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                    <FormControlLabel
                      control={<Checkbox name="antoine" />}
                      label="Antoine Llorca"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>
          </Hidden>

          <Grid item md={8} xs={11}>
            <Grid container justify="center" spacing={2}>
              {this.state.hotelList.length > 0 ? (
                this.state.hotelList.map((hotel, index) => {
                  return (
                    <Grid item xs={12} key={hotel.id}>
                      <div className={classes.flightItemContainer}>
                        <Grid container spacing={2} justify="space-around">
                          <Grid item md={4} xs={12}>
                            <Grid container spacing={4} justify="space-evenly">
                              <Grid
                                item
                                xs={12}
                                className={classes.hotelMainImage}
                                style={{
                                  backgroundImage : `url(${hotel.Hotel_Image})`
                                }}
                              />
                              {hotel.images.length> 0 && hotel.images.map((image,indexImage)=>{
                                    return <Grid
                                    xs={3}
                                    key={indexImage}
                                    item
                                    className={classes.hoteloptionalImage}
                                    style={{
                                      backgroundImage : `url(${image})`
                                    }}
                                  />
                              })}
                            </Grid>
                          </Grid>
                          <Grid item md={3} xs={6}>
                            <Typography className={classes.hotelName}>
                              {hotel.Hotel_Name}
                            </Typography>
                            <StyledRating
                              readOnly
                              size="small"
                              value={
                                hotel.Hotel_Rating ? hotel.Hotel_Rating : 4
                              }
                            />
                            <Typography className={classes.hotelLocation}>
                              {hotel.Hotel_Location}
                            </Typography>
                            <Grid container justify="space-between" spacing={1}>
                              <Grid item xs={6}>
                                <div className={classes.sortIngButton}>
                                  <Typography className={classes.sortIconTitle}>
                                    Top Picks
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={classes.sortIngButton}>
                                  <Typography className={classes.sortIconTitle}>
                                    Highest Rated
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                            <Typography gutterBottom>Free WiFi</Typography>
                            <Typography>Kitchenette</Typography>
                          </Grid>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                          <Grid item md={4} xs={6}>
                            <Typography>{hotel.Extra}</Typography>
                            <Typography>{hotel.Hotel_Price}</Typography>
                            <Typography>{hotel.Hotel_Price_tax}</Typography>
                            <Button variant="contained" color="primary">
                              View
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <></>
              )}{" "}
            </Grid>
          </Grid>
        </Grid>
        <Footer></Footer>
        </div>
    );
  }
}
const HotelSearchResultWithRouter = withRouter(HotelSearchResult);
const HotelSearchResultAlertBox = withAlertBox(HotelSearchResultWithRouter);
const HotelSearchResultLoader = withLoader(HotelSearchResultAlertBox);
const HotelSearchResultToast = withToast(HotelSearchResultLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: "url(https://source.unsplash.com/random)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "420px",
      [theme.breakpoints.down("xs")]: {
        height: "600px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(1),
      top: "50%",
      height: "150px",
      [theme.breakpoints.down("xs")]: {
        height: "350px",
      },
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    flightItemContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelMainImage: {
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(20),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      margin: "16px",
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    hoteloptionalImage: {
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(8),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(5),
      },
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    filterformControl: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(4),
      textAlign: "center",
    },
    sortIngButton: {
      backgroundColor: "rgb(21,101,216,0.1)",
      padding: theme.spacing(0.75, 0.5),
    },
    sortIconTitle: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    hotelName : {
       fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    hotelLocation : {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    hotelFeatures : {
     
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    containerPosition: {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(44),
      },
    },
  })
)(HotelSearchResultToast);
