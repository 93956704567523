import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    accountId : string
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  token : string
  accountId : any,
  bookedAirlines : Array<any>,
  searchBYFilghtCategory : Array<any>
  searchBYFilghtType : Array<any>,
  bookingStartDate : any,
  bookingEndDate : any,
  travellStartDate : any,
  travellEndDate : any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilterDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  getBookedAirlinesApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token : '',
      accountId : '',
      bookedAirlines : [],
      searchBYFilghtCategory : [],
      searchBYFilghtType : [],
      bookingStartDate : null,
      bookingEndDate : null,
      travellStartDate : null,
      travellEndDate : null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBookedAirlinesApiCallId) {
        this.props.hideLoader();
        if (responseJson) {
          let bookedAirLineName = [];
          if(this.props?.dataToPass?.role === 'Agency'){
            bookedAirLineName = responseJson?.agency_stats?.booked_airlines;
          } else if(this.props?.dataToPass?.role === 'Admin'){
            bookedAirLineName = responseJson?.agencies_and_sub_agencies_stats?.booked_airlines;
          } else if(this.props?.dataToPass?.role === 'Customer'){
            bookedAirLineName = responseJson?.customers_stats?.booked_airlines;
          } else {
            bookedAirLineName = responseJson?.agency_stats?.sub_agencies_stats?.booked_airlines
          }
          this.setState({ bookedAirlines: bookedAirLineName });
        } else {
          this.parseApiErrorResponse(responseJson);
        }    
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
    }
  }
  
  getBookedAirlines = (values: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let url = configJSON.getBookedAirlinesEndPoint;
    this.getBookedAirlinesApiCallId = requestMessage.messageId;
    if(this.props.accountId || this.state.accountId){
      url += url.indexOf("?") > -1 ? `&account_id=${this.props.accountId || this.state.accountId}` : `?account_id=${this.props.accountId || this.state.accountId}`
    } 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
