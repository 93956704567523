//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  ListItem,
  ListItemText,
  Button,
  Divider,
  ListItemAvatar,
} from "@material-ui/core";
import { hotelImage } from "./assets";

import PhoneIcon from "@material-ui/icons/Phone";
import Rating from "@material-ui/lab/Rating";

const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
  root : {
    fontSize : '1em'
  }
})(Rating);

const HotelInfo = (props) => {
  const { classes } = props;
  return <Grid container spacing={2} className={classes.hotelDetailContainer}>
  <Grid item xs={10} md={4}>
    <Grid container justify="space-between" spacing={2}>
      <Grid md={12} xs={12} item>
        <div className={classes.hotelImage}></div>
      </Grid>
      <Grid md={12} xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            <div className={classes.hotelOptionalImage}></div>
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.hotelOptionalImage}></div>
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.hotelOptionalImage}></div>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={12} xs={12} item>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.bookedByAdmin}
          gutterBottom
        >
          Booked By Admin
        </Typography>
        <Box mt={2}>
          <Typography
            align="center"
            color="textPrimary"
            className={classes.pnr}
            gutterBottom
          >
            PNR : SDSDSS
          </Typography>
         
          <Typography
            align="center"
            color="textSecondary"
            className={classes.bookingDetail}
            gutterBottom
          >
            Booking ID : 2112312SDWDn
          </Typography>
          
          <Typography
            align="center"
            color="textSecondary"
            className={classes.bookingDetail}
            gutterBottom
          >
            Booked On: 12/12/2020
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography
            align="center"
            color="textSecondary"
            className={classes.bookingDetail}
            gutterBottom
          >
            1 Room | 2 Adults
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            className={classes.travellName}
            gutterBottom
          >
            Mr. John + 9 Travellers
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Grid>
  <Grid xs={10} md={8}>
    <Grid container spacing={3}>
      <Grid xs={12} item>
        <div style={{display : 'flex'}}>
            <div style={{flexGrow : 1}}>
            <Typography
              color="textPrimary"
              className={classes.hotelName}
            >
              Marriott Hotel
            </Typography>
            <StyledRating name="read-only" value={4} readOnly />
            <Typography
              gutterBottom
              color="textSecondary"
              className={classes.hotelAddress}
            >
              15 Al Habtoor City, Palm Jumeriah
            </Typography>
            </div>
            <PhoneIcon className={classes.marginRight} />
            <PhoneIcon className={classes.marginRight} />
            <PhoneIcon className={classes.marginRight} />
            <PhoneIcon className={classes.marginRight} />
         </div>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="middle"/>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
            <Grid item xs={12}>
              <Grid container justify="space-around">
                <Grid item xs={5}>
                  <Typography
                    className={classes.date}
                    color="textPrimary"
                    align="left"
                  >
                    {" "}
                    Fri, 12 Feb{" "}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    className={classes.date}
                    color="textPrimary"
                    align="right"
                  >
                    {" "}
                    Fri, 12 Feb{" "}
                  </Typography>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={5}>
                <div className={classes.linewraper}>
                  <Typography className={classes.lineHeading}>
                    2 Nights
                  </Typography>
                  <ul className={classes.container}>
                    <li className={classes.linkconnector} />
                    <li className={classes.linkconnector} />
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-around">
              <Grid item xs={5}>
                <Typography
                  className={classes.checkIn}
                  color="textPrimary"
                  align="left"
                >
                  {" "}
                  Check-In{" "}
                </Typography>
                <Typography
                  className={classes.time}
                  color="textSecondary"
                  align="left"
                >
                  {" "}
                  09:00 AM{" "}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  className={classes.checkIn}
                  color="textPrimary"
                  align="right"
                >
                  {" "}
                  Check-Out{" "}
                </Typography>
                <Typography
                  className={classes.time}
                  color="textSecondary"
                  align="right"
                >
                  {" "}
                  09:00 AM{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item>
          <Divider variant="middle" />
      </Grid>
      <Grid xs={12} item>
          <Box p={2}>
            <Typography
              align="left"
              color="textPrimary"
              className={classes.travellName}
              gutterBottom
            >
              {" "}
              Sandard Room Only For 2 Adults
            </Typography>
            <Typography
              color="textSecondary"
              className={classes.bookingDetail}
              align="left"
            >
              — Breakfast Available, Pay At Propert
            </Typography>
            <Typography
              color="textSecondary"
              className={classes.bookingDetail}
              align="left"
            >
              — This booking is non refundable
            </Typography>
          </Box>
          <Box m={1}>
            <Grid container spacing={1} justify="space-between">
              <Grid item md={6} xs={4}>
                <ListItem>
                  <ListItemText
                    primary="Rs. 4000"
                    secondary="Cost to you: Rs. 3500"
                  />
                </ListItem>
              </Grid>
              <Grid item md={3} xs={2} style={{alignSelf : 'center'}}>
                <Button color="primary" variant="contained">
                  Share
                </Button>
              </Grid>
              <Grid item md={3} xs={2} style={{alignSelf : 'center'}}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.goToSelectedRoute("/myBookings/Hotel");
                  }}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Box>
      </Grid>
    </Grid>
  </Grid>
</Grid>
}
export default withStyles((theme) =>
  createStyles({
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    hotelImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 128,
      [theme.breakpoints.down("xs")]: {
        height: 128,
      },
    },
    hotelOptionalImage: {
      position: "relative",
      backgroundImage: `url(${hotelImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: 72,
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    hotelName: {
      fontFamily: "Open Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    hotelAddress: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    bookedByAdmin: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    pnr: {
      fontFamily: " ",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    bookingDetail: {
      fontFamily: " ",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.78,
      letterSpacing: "normal",
    },
    travellName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
    }
  })
)(HotelInfo);
