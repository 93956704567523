//@ts-nocheck
import React from "react";
import AddOfferDialogController, {
  Props,
} from "./AddOfferDialogController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  DialogContent,
   FormControl,
   FormLabel,
   FormControlLabel,
   RadioGroup,
   Radio
} from "@material-ui/core";
import { withRouter } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";
import Checkbox from "@material-ui/core/Checkbox";
import withFontStyles from "./withFontStyles.web";
import UploadBox from "./UploadBox.web"
import { DialogTitle } from "../../../components/src/DialogContext";
const Schema = Yup.object().shape({
  offerTitle: Yup.string().required("This field is required."),
  offerDescription: Yup.string().required("This field is required."),
  offerType: Yup.string().required("This field is required."),
  fromDate: Yup.date()
  .typeError('From date is required')
  .required('From date is required')
    ,
    toDate: Yup.date()
  .typeError('To date is required')
  .required('To date is required')
  .when('fromDate', (fromDate) => {
      if (fromDate) {
          return Yup.date()
              .min(fromDate, "offer expiry date can't be before offer start date")
              .typeError('To date is required')
      }
  }),
  url : Yup.string() 
});
const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
class AddOfferDialog extends AddOfferDialogController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          offerImageServer : this.props.dataToPass?.offerDetail?.attributes.images ? this.props.dataToPass?.offerDetail?.attributes.images : null
        },
        () => {
          //call
        }
      );
    }
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({base64 : reader.result,file : file,id : new Date().getTime()});
      reader.onerror = error => reject(error);
    });
  };

  handleThumbnailImage = async (files : FileList) => {
    const filePathsPromises = [];
    [...files].forEach(file => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      offerImage : filePaths[0]
    })
  }
  removeOfferImage = () => {
    this.setState({
      offerImage : null
    })
  }
  removeOfferImageFromServer = () => {
    this.setState({
      offerImageServer : null
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle id="simple-dialog-title" onCancel={this.props.onCancel}>{this.props.dataToPass?.offerDetail ? "Update Offer" : "Add Offer"} </DialogTitle>
        <DialogContent style={{overflow : 'hidden'}}>
          <Formik
            initialValues={{
              offerTitle: this.props.dataToPass ? this.props.dataToPass?.offerDetail?.attributes?.title : "",
              offerDescription: this.props.dataToPass ? this.props.dataToPass?.offerDetail?.attributes?.description : "",
              offerType: this.props.dataToPass ? this.props.dataToPass?.offerDetail?.attributes?.advertisement_type : "Customers",
              fromDate: this.props.dataToPass?.offerDetail?.attributes?.start_date ? moment(this.props.dataToPass?.offerDetail?.attributes?.start_date).format("YYYY-MM-DD") : "",
              toDate: this.props.dataToPass?.offerDetail?.attributes?.end_date ? moment(this.props.dataToPass?.offerDetail?.attributes?.end_date).format("YYYY-MM-DD") : "",
              // url : this.props.dataToPass ? this.props.dataToPass?.offerDetail?.attributes?.url : "",
            }}
            enableReinitialize
            validationSchema={Schema}
            onSubmit={(values, actions) => {
                  
                this.props.dataToPass?.offerDetail ? this.updateOfferHandler(values) : this.createOfferHandler(values) 
            }}
          >
            {(formikProps) => {
              const { values,touched,errors, setFieldValue, isValid, dirty,handleChange } = formikProps;
              return (
                <Form noValidate autoComplete="off">
                  <Grid container  spacing={2}>
                    <Grid item xs={12} container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={8} container> 
                          <Grid item xs={12}>
                              <Field
                                component={InputField}
                                fullWidth
                                label="Offer Title"
                                name="offerTitle"
                                placeholder="Enter Offer Title"
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Field
                                component={InputField}
                                fullWidth
                                multiline
                                rows={6}
                                label="Offer Description"
                                name="offerDescription"
                                placeholder="Enter Offer Description"
                              />
                           </Grid>
                        </Grid>
                        <Grid item xs={4}>
                        {this.state.offerImageServer ? <div  style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeOfferImageFromServer();
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={this.state.offerImageServer}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "6px",
                                }}
                              />
                            </div> :  this.state.offerImage ? <div  style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeOfferImage();
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={this.state.offerImage?.base64}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "6px",
                                }}
                              />
                            </div> : <><UploadBox
                            multiple={false}
                            uploadMsg="Main Image"
                            acceptedFormat={"image/*"}
                            onTouched={() => {this.setState({
                              isTouched : true
                            })}}
                            onSelectFile={(files: any) => {
                            this.handleThumbnailImage(files);
                            }}
                         />{!this.state.offerImage && this.state.isTouched && (<div><Typography color="error">This image is required</Typography></div>)}</>}
                        
                         
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl component="fieldset">
                                    <FormLabel
                                      className={classes.groteskBold20}
                                      component="legend"
                                    >
                                      For
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="offerType"
                                      onChange={handleChange}
                                      defaultValue={values.offerType}
                                    >
                                      <FormControlLabel
                                        
                                        value="Customers"
                                        control={
                                          <Radio
                                            color="primary"
                                          />
                                        }
                                        label="Customers"
                                      />
                                      <FormControlLabel
                                        
                                        value="Agencies/Subagencies"
                                        control={
                                          <Radio
                                            color="primary"
                                          />
                                        }
                                        label="Agencies/Subagencies"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                    </Grid>
                  
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Date From"
                            InputProps={{
                              inputProps: {
                                min: curDate,
                              },
                            }}
                            placeholder="Enter Departure Date"
                            value={values.fromDate}
                            onChange={handleChange}
                            name="fromDate"
                            type="date"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            component={InputField}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                min: curDate,
                              },
                            }}
                            placeholder="Enter Departure Date"
                            onChange={handleChange}
                            value={values.toDate}
                            label="Date To"
                            name="toDate"
                            type="date"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    <Grid container justify="flex-end">
                    <Grid item xs={4} container spacing={1}>
                      <Grid item xs={6}>
                        <Button onClick={this.props.onCancel}  variant="outlined" color="primary">
                          Cancel
                        </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button type="submit" onClick={()=>{this.setState({
                            isTouched : true
                          })}} variant="contained" color="primary">
                            {this.props.dataToPass?.offerDetail ? "Update Offer" : "Add Offer"} 
                          </Button>
                        </Grid>
                      </Grid>
                     </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
       
      </>
    );
  }
}

const AddOfferDialogWithRouter = withRouter(AddOfferDialog);
const AddOfferDialogWithToast = withToast(AddOfferDialogWithRouter);
const AddOfferDialogWithLoader = withLoader(AddOfferDialogWithToast);
const AddOfferDialogWithAlertBox = withAlertBox(AddOfferDialogWithLoader);
const AddOfferDialogWithFontStyles = withFontStyles(AddOfferDialogWithAlertBox);

export default withStyles((theme) =>
  createStyles({
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
    },
    heading: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: "#183b56",
      marginTop: "70px",
    },
    subHeading: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.2px",
      color: "#183b56",
      marginBottom: "40px",
      marginTop: "40px",
    },
    root: {
      margin: 0,
      padding: "16px 0px 0px 24px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#110f57",
    },
    dialogTitle: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    crossOverImage: {
      right: "0px",
      top: "-2px",
      position: "absolute",
      backgroundColor: "#fff",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
    },
  })
)(AddOfferDialogWithFontStyles);
