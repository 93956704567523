//@ts-nocheck 
import React from "react";
import FlightSearchResultController, {
  Props,
} from "./FlightSearchResultController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Paper,
  Link,
  Tabs,
  Tab,
  TextField,
  Divider,
  Container,
  Hidden,
  ListItemAvatar,
  IconButton,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Drawer,
  InputAdornment,
  MenuItem,
  TablePagination,
  Box,
  Slider,
  RadioGroup,
  Radio,
  Popper,
  Fade,
  Collapse,
} from "@material-ui/core";
import Header from "./Header.web";
import Footer from "./Footer.web";
import '../../../web/src/style.css';
import MainFlightPost from "./MainFlightPost.web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { flighPlaceHolder, flightCover } from "./assets";
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Remove';
import { withRouter } from "react-router";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
// import SearchIcon from "@material-ui/icons/Search";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import debounce from "lodash.debounce";
// import { eventNames } from "cluster";

const drawerWidth = 240;

const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();

const flightOneWaySchema = Yup.object().shape({
  from: Yup.string().required("This field is required."),
  to: Yup.string().required("This field is required"),
  depratureDate: Yup.date().required("This field is required."),
  adults: Yup.string().required("This field is required."),
  children: Yup.string(),
  infants: Yup.string(),
  class: Yup.string().required("This field is required."),
});

const flightTwoWaySchema = Yup.object().shape({
  from: Yup.string().required("This field is required."),
  to: Yup.string().required("This field is required"),
  depratureDate: Yup.date().required("This field is required."),
  returnDate: Yup.date()
    .min(Yup.ref("depratureDate"), "End date can't be before Start date")
    .required("This field is required."),
  adults: Yup.number().required("This field is required."),
  children: Yup.number().required("This field is required."),
  class: Yup.string().required("This field is required."),
});

const flightMultiCitySchema = Yup.object().shape({
  from: Yup.string().required("This field is required."),
  to: Yup.string().required("This field is required"),
  noOfPassenger: Yup.string().required("This field is required"),
  depratureDate: Yup.date().required("This field is required."),
  newCity: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required("This field is required."),
      to: Yup.string().required("This field is required"),
      depratureDate: Yup.date().required("This field is required."),
    })
  ),
});
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const flightSortingButtons = [
  { key: "lowest_price", value: "Lowest Price" },
  { key: "top_picks", value: "Top Picks" },
  { key: "fastest", value: "Fastest" },
  { key: "least_stop", value: "Least Stops" },
]

class FlightSearchResult extends FlightSearchResultController {
  TOTAL_PASSENGERS_ALLOWED = 9;
  constructor(props: Props) {
    super(props);

    this.state.counter = 1
    this.state.counter1 = 0
    this.state.counter2 = 0
    this.state.twoWayCounterInfant = 0
    this.state.twoWayCounterAdult = 1
    this.state.twoWayCounterChild = 0

    this.state.Pasanger = 'Adult'
    this.state.PasangerValus = [
      { name: 'Adult' },
      { name: 'Child' },
      { name: 'Infant' },
    ]
    this.SearchFlightFromCity = debounce(this.SearchFlightFromCity.bind(this), 1000);
    this.SearchFlightToCity = debounce(this.SearchFlightToCity.bind(this), 1000);
  }

  dropDownSelect = () => {
    this.setState({
      SelectOption: !this.state.SelectOption
    })
  }


  headerRef = React.createRef();
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    let profileData = await StorageProvider.get("profileData");
    profileData = (profileData && JSON.parse(profileData)) || {};
    this.setState(
      {
        ...this.state,
        searchData: state?.data || {},
        selectedTravellingType: state?.selectedTravellingType || "oneway",
        fromCity: state?.fromCity || {},
        toCity: state?.toCity || {},
        departureDate: state?.departureDate || null,
        returnDate: state?.returnDate || null,
        adult: state?.adult || 0,
        childrens: state?.childrens || 0,
        infants: state?.infant || 0,
        authToken: authToken,
        role: state?.role || "Customer",
        currency: profileData.currency || "USD",
        flightViewDetailsOpen: false,
        flight1ViewDetailsOpen: false,
        flight2ViewDetailsOpen: false,
        selectedFlightViewDetails: "",
        selectedFlight1ViewDetails: "",
        selectedFlight2ViewDetails: "",
        selectedFlight1Show: false,
        selectedFlight2Show: false,
        selectedFlight1Index: 0,
        selectedFlight2Index: 0,
      },
      () => {
        if (Object.keys(this.state.searchData).length > 0) {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(state.data);
            this.getOneWayFlightForFilter(state.data);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlightForFilter(state.data);
            this.getTwoWayReturnSideFlightForFilter(state.data);
            this.getTwoWaydepratureSideFlight(state.data);
            this.getTwoWayReturnSideFlight(state.data);
          }
        }
      }
    );

    if (this.state.searchData.adults) {
      this.setState({
        counter: this.state.searchData.adults
      })
    }
    if (this.state.searchData.children) {
      this.setState({
        counter1: this.state.searchData.children
      })
    }
    if (this.state.searchData.infants) {
      this.setState({
        counter2: this.state.searchData.infants
      })
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.push({
      state: { data },
      pathname: routeName,
    });
  };
  refRoot = React.createRef();
  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        pageCount: value,
      },
      () => {
        this.getOneWayFlight(this.state.searchData);
      }
    );
  };

  handleDecrement = (value, flighWays) => {

    if (flighWays === 'oneWay') {
      if (value === 'Adult') {
        this.setState({
          counter: this.state.counter - 1
        })
      }
      else if (value === 'Child') {
        this.setState({
          counter1: this.state.counter1 - 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          counter2: this.state.counter2 - 1
        })
      }
    }

    else if (flighWays === 'twoWay') {
      if (value === 'Adult') {
        this.setState({
          twoWayCounterAdult: this.state.twoWayCounterAdult - 1
        })
      }
      if (value === 'Child') {
        this.setState({
          twoWayCounterChild: this.state.twoWayCounterChild - 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          twoWayCounterInfant: this.state.twoWayCounterInfant - 1
        })
      }

    }

    else if (flighWays === 'multiWay') {
      if (value === 'Adult') {
        this.setState({
          multiWayCounterAdult: this.state.multiWayCounterAdult - 1
        })
      }
      if (value === 'Child') {
        this.setState({
          multiWayCounterChild: this.state.multiWayCounterChild - 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          multiWayCounterInfant: this.state.multiWayCounterInfant - 1
        })
      }
    }

  };
  validateCount = (c1, c2, c3) => {
    const totalCount = c1 + c2 + c3;
    return totalCount < this.TOTAL_PASSENGERS_ALLOWED;
  };


  handleIncrement = (value, flighWays) => {
    if (flighWays === 'oneWay') {

      if (value === 'Adult') {
        this.setState({
          counter: this.state.counter + 1
        })
      }
      if (value === 'Child') {
        this.setState({
          counter1: this.state.counter1 + 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          counter2: this.state.counter2 + 1
        })
      }


    }



    else if (flighWays === 'twoWay') {
      if (value === 'Adult') {
        this.setState({
          twoWayCounterAdult: this.state.twoWayCounterAdult + 1
        })
      }
      if (value === 'Child') {
        this.setState({
          twoWayCounterChild: this.state.twoWayCounterChild + 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          twoWayCounterInfant: this.state.twoWayCounterInfant + 1
        })
      }

    }

    else if (flighWays === 'multiWay') {
      if (value === 'Adult') {
        this.setState({
          multiWayCounterAdult: this.state.multiWayCounterAdult + 1
        })
      }
      if (value === 'Child') {
        this.setState({
          multiWayCounterChild: this.state.multiWayCounterChild + 1
        })
      }
      else if (value === 'Infant') {
        this.setState({
          multiWayCounterInfant: this.state.multiWayCounterInfant + 1
        })
      }
    }

    // this.setState({ counter: this.state.counter + 1 });
  };
  dropDowmalues = (event, name) => {
    if (name === 'Adult') {
      this.setState({
        Pasanger: 'Adult',
      })
    }
    else if (name === 'Child') {
      this.setState({
        Pasanger: 'Child',
      })
    }
    else {
      this.setState({
        Pasanger: 'Infant',
      })
    }

  }

  // clear data 

  ClearDataForm = (flightType, setfieldVlaues) => {
    if (flightType === 'oneway') {
      this.setState({
        counter: 1,
        counter1: 0,
        counter2: 0,
        fromCity: {},
        sourcesearchSuggestionList: [],
        toCity: {},
        depratureDate: ''
      }, () => {
        setfieldVlaues('from', '', 'to', '', 'depratureDate', '')
      })
    }
    else if (flightType === 'twoway') {
      this.setState({
        twoWayCounterInfant: 0,
        twoWayCounterAdult: 1,
        twoWayCounterChild: 0,
        fromCity: {},
        sourcesearchSuggestionList: [],
        toCity: {},
        depratureDate: ''
      }, () => {
        setfieldVlaues('from', '', 'to', '', 'depratureDate', '', 'returnDate', "",)
      })
    }
    else if (flightType === 'multicity') {
      this.setState({
        counter: 1,
        counter1: 0,
        counter2: 0,
        fromCity: {},
        sourcesearchSuggestionList: [],
        toCity: {},
        depratureDate: ''
      }, () => {
        setfieldVlaues('from', '', 'to', '', 'depratureDate', '')
      })
    }
  }


  handleTwoWayPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    this.setState(
      {
        twoWayPage: value,
      },
      () => {
        this.getTwoWaydepratureSideFlight(this.state.searchData);
      }
    );
  };
  handleTwoWayReturnPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    this.setState(
      {
        twoWayReturnPage: value,
      },
      () => {
        this.getTwoWayReturnSideFlight(this.state.searchData);
      }
    );
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPage: parseInt(event.target.value, 10),
        pageCount: 0,
      },
      () => {
        this.getOneWayFlight(this.state.searchData);
      }
    );
  };
  handleChangeRowsPerPageTwoWay = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPageTwoWay: parseInt(event.target.value, 10),
        twoWayPage: 0,
        selectedFlight1: null,
        // selectedFlight2: null,
      },
      () => {
        this.getTwoWaydepratureSideFlight(this.state.searchData);
      }
    );
  };
  handleChangeRowsPerPageTwoWayReturn = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPageTwoWayReturn: parseInt(event.target.value, 10),
        twoWayReturnPage: 0,
        // selectedFlight1: null,
        selectedFlight2: null,
      },
      () => {
        this.getTwoWayReturnSideFlight(this.state.searchData);
      }
    );
  };
  handleFlightCategoryChange = (evt, value) => {
    const searchBYFilghtCategory = [...this.state.searchBYFilghtCategory];
    if (evt.target.checked) {
      this.setState(
        {
          searchBYFilghtCategory: [...searchBYFilghtCategory, value],
          selectedFlight1: null,
          // selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    } else {
      this.setState(
        {
          searchBYFilghtCategory: searchBYFilghtCategory.filter((category) => {
            return category !== value;
          }),
          selectedFlight1: null,
          // selectedFlight2: null,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    }
  };
  handleFlightCategoryChangeReturn = (evt, value) => {
    const searchBYFilghtCategoryReturn = [...this.state.searchBYFilghtCategoryReturn];
    if (evt.target.checked) {
      this.setState(
        {
          searchBYFilghtCategoryReturn: [...searchBYFilghtCategoryReturn, value],
          // selectedFlight1: null,
          selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    } else {
      this.setState(
        {
          searchBYFilghtCategoryReturn: searchBYFilghtCategoryReturn.filter((category) => {
            return category !== value;
          }),
          // selectedFlight1: null,
          selectedFlight2: null,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    }
  };
  handleStopCategoryChange = (evt, value) => {
    const searchByStopType = [...this.state.searchByStopType];
    if (evt.target.checked) {
      this.setState(
        {
          searchByStopType: [...searchByStopType, value],
          selectedFlight1: null,
          // selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    } else {
      this.setState(
        {
          searchByStopType: searchByStopType.filter((category) => {
            return category !== value;
          }),
          selectedFlight1: null,
          // selectedFlight2: null,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    }
  };
  handleStopCategoryChangeReturn = (evt, value) => {
    const searchByStopTypeReturn = [...this.state.searchByStopTypeReturn];
    if (evt.target.checked) {
      this.setState(
        {
          searchByStopTypeReturn: [...searchByStopTypeReturn, value],
          // selectedFlight1: null,
          selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    } else {
      this.setState(
        {
          searchByStopTypeReturn: searchByStopTypeReturn.filter((category) => {
            return category !== value;
          }),
          // selectedFlight1: null,
          selectedFlight2: null,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    }
  };
  handleSortingFlightChange = (value) => {
    const searchBySortingFlight = [...this.state?.searchBySortingFlight]
    if (!this.state?.searchBySortingFlight?.find((sortingFlight) => sortingFlight.includes(value))) {
      this.setState({
        searchBySortingFlight: [...searchBySortingFlight, value],
        selectedFlight1: null,
        // selectedFlight2: null,
        pageCount: 0,
        twoWayPage: 0,
        twoWayReturnPage: 0,
      }, () => {
        if (this.state.selectedTravellingType === "oneway") {
          this.getOneWayFlight(this.state.searchData);
        } else if (this.state.selectedTravellingType === "twoway") {
          this.getTwoWaydepratureSideFlight(this.state.searchData);
        }
      })
    } else {
      this.setState(
        {
          searchBySortingFlight: searchBySortingFlight.filter((category) => {
            return category !== value;
          }),
          selectedFlight1: null,
          selectedFlight2: null,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    }

  };
  handleSortingFlightChangeReturn = (value) => {
    const searchBySortingFlightReturn = [...this.state?.searchBySortingFlightReturn]
    if (!this.state?.searchBySortingFlightReturn?.find((sortingFlight) => sortingFlight.includes(value))) {
      this.setState({
        searchBySortingFlightReturn: [...searchBySortingFlightReturn, value],
        // selectedFlight1: null,
        selectedFlight2: null,
        pageCount: 0,
        twoWayPage: 0,
        twoWayReturnPage: 0,
      }, () => {
        this.getTwoWayReturnSideFlight(this.state.searchData);
      })
    } else {
      this.setState(
        {
          searchBySortingFlightReturn: searchBySortingFlightReturn.filter((category) => {
            return category !== value;
          }),
          // selectedFlight1: null,
          selectedFlight2: null,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    }

  };
  handleDepratureTimeChange = (value) => {
    if (value !== this.state.searchFlightByDepratureTime) {
      this.setState(
        {
          searchFlightByDepratureTime: value,
          selectedFlight1: null,
          // selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    } else {
      this.setState(
        {
          searchFlightByDepratureTime: "",
          selectedFlight1: null,
          // selectedFlight2: null,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    }
  };
  handleDepratureTimeChangeReturn = (value) => {
    if (value !== this.state.searchFlightByDepratureTimeReturn) {
      this.setState(
        {
          searchFlightByDepratureTimeReturn: value,
          // selectedFlight1: null,
          selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    } else {
      this.setState(
        {
          searchFlightByDepratureTimeReturn: "",
          // selectedFlight1: null,
          selectedFlight2: null,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    }
  };
  handleArrivalTimeChange = (value) => {
    if (value !== this.state.searchFlightByArrivalTime) {
      this.setState(
        {
          searchFlightByArrivalTime: value,
          selectedFlight1: null,
          // selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    } else {
      this.setState(
        {
          searchFlightByArrivalTime: "",
          selectedFlight1: null,
          // selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getOneWayFlight(this.state.searchData);
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getTwoWaydepratureSideFlight(this.state.searchData);
          }
        }
      );
    }
  };
  handleArrivalTimeChangeReturn = (value) => {
    if (value !== this.state.searchFlightByArrivalTimeReturn) {
      this.setState(
        {
          searchFlightByArrivalTimeReturn: value,
          // selectedFlight1: null,
          selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    } else {
      this.setState(
        {
          searchFlightByArrivalTimeReturn: "",
          // selectedFlight1: null,
          selectedFlight2: null,
          pageCount: 0,
          twoWayPage: 0,
          twoWayReturnPage: 0,
        },
        () => {
          this.getTwoWayReturnSideFlight(this.state.searchData);
        }
      );
    }
  };
  handlePriceChange = (evt, value) => {
    this.setState(
      {
        searchFlightByPriceRange: value,
        selectedFlight1: null,
        // selectedFlight2: null,
        pageCount: 0,
        twoWayPage: 0,
        twoWayReturnPage: 0,
      },
      () => {
        if (this.state.selectedTravellingType === "oneway") {
          this.getOneWayFlight(this.state.searchData);
        } else if (this.state.selectedTravellingType === "twoway") {
          this.getTwoWaydepratureSideFlight(this.state.searchData);
        }
      }
    );
  };
  handlePriceChangeReturn = (evt, value) => {
    this.setState(
      {
        searchFlightByPriceRangeReturn: value,
        // selectedFlight1: null,
        selectedFlight2: null,
        pageCount: 0,
        twoWayPage: 0,
        twoWayReturnPage: 0,
      },
      () => {
        this.getTwoWayReturnSideFlight(this.state.searchData);
      }
    );
  };
  filterUi(classes) {
    // const marks = [];
    // for (let i = 0; i < this.state.sliderMaxValue; i = i + 1000) {
    //   marks.push({
    //     value: i,
    //     label: `${i}`,
    //   });
    // }
    return <>
      <div className={classes.flightsFilterBox}>
        {this.state.selectedTravellingType === "twoway" &&
          (<>
            <Typography className={classes.journeyText}>Onward Journey</Typography>
            <Grid item md={12} xs={12}>
              <Divider style={{ marginBottom: "10px" }} />
            </Grid>
          </>)}
        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel className={classes.filterTitleHeading} component="legend">
            Popular Filters
          </FormLabel>
          <FormGroup>
            {this.state?.FilghtCategoryList?.length > 0 &&
              this.state.FilghtCategoryList.map((FilghtCategory) => {
                return (
                  <FormControlLabel
                    key={FilghtCategory.name}
                    classes={{
                      label: classes.filterCheckBoxName,
                    }}
                    control={
                      <Checkbox
                        name="flightCategory"
                        checked={
                          this.state.searchBYFilghtCategory.indexOf(
                            FilghtCategory.name
                          ) > -1
                        }
                        onChange={(evt) => {
                          this.handleFlightCategoryChange(
                            evt,
                            FilghtCategory.name
                          );
                        }}
                      />
                    }
                    label={`${FilghtCategory.name} (${FilghtCategory.count})`}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
        <br></br>
        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Stops
          </FormLabel>
          <FormGroup>
            {this.state?.stopCategoryList?.length > 0 &&
              this.state?.stopCategoryList?.map((stopType) => {
                return (
                  <FormControlLabel
                    classes={{
                      label: classes.filterCheckBoxName,
                    }}
                    control={
                      <Checkbox
                        name="nonStop"
                        checked={
                          this.state.searchByStopType.indexOf(stopType.name) >
                          -1
                        }
                        onChange={(evt) => {
                          this.handleStopCategoryChange(evt, stopType.name);
                        }}
                      />
                    }
                    label={`${stopType.name} (${stopType.count})`}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
        <FormControl
          component="fieldset"
          className={classes.filterformControl}
          fullWidth
        >
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Price Range
          </FormLabel>
          <FormGroup>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={10}>
                <div style={{ width: "100%", marginTop: "40px" }}>
                  <Slider
                    valueLabelDisplay="on"
                    fullWidth
                    getAriaValueText={(value) => {
                      return value;
                    }}
                    value={this.state.searchFlightByPriceRange}
                    // step={100}
                    onChange={this.handlePriceChange}
                    min={this.state.sliderMinValue}
                    max={this.state.sliderMaxValue}
                    // marks={marks}
                    aria-labelledby="price-slider"
                  />
                </div>
              </Grid>
              {/* <Grid item xs={11}>
                <Grid container justify="space-between">
                  <Grid item>{`${this.state.currency} ${0}`}</Grid>

                  <Grid
                    item
                  >{`${this.state.currency} ${this.state.sliderMaxValue}`}</Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Departure From {this.state.fromCity?.city}
          </FormLabel>
          <FormGroup>
            <Grid
              container
              spacing={1}
              style={{ marginTop: "16px" }}
              justify="space-around"
            >
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTime === "00:00:00-05:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChange("00:00:00-05:59:59");
                  }}
                  size="small"
                >
                  12 AM - 6 AM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTime === "06:00:00-11:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChange("06:00:00-11:59:59");
                  }}
                  size="small"
                >
                  6 AM - 12 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTime === "12:00:00-17:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChange("12:00:00-17:59:59");
                  }}
                  size="small"
                >
                  12 PM - 6 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTime === "18:00:00-23:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChange("18:00:00-23:59:59");
                  }}
                  size="small"
                >
                  6 PM - 12 AM{" "}
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Arrival At {this.state.toCity?.city}
          </FormLabel>
          <FormGroup>
            <Grid
              container
              style={{ marginTop: "16px" }}
              spacing={1}
              justify="space-around"
            >
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTime === "00:00:00-05:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChange("00:00:00-05:59:59");
                  }}
                  size="small"
                >
                  12 AM - 6 AM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTime === "06:00:00-11:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChange("06:00:00-11:59:59");
                  }}
                  size="small"
                >
                  6 AM - 12 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTime === "12:00:00-17:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChange("12:00:00-17:59:59");
                  }}
                  size="small"
                >
                  12 PM - 6 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTime === "18:00:00-23:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChange("18:00:00-23:59:59");
                  }}
                  size="small"
                >
                  6 PM - 12 AM{" "}
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </div>
    </>
  }
  filterUiReturn(classes) {
    return <>
      <div className={classes.flightsFilterBox}>
        <Typography className={classes.journeyText}>Return Journey</Typography>
        <Grid item md={12} xs={12}>
          <Divider style={{ marginBottom: "10px" }} />
        </Grid>
        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel className={classes.filterTitleHeading} component="legend">
            Popular Filters
          </FormLabel>
          <FormGroup>
            {this.state.FilghtCategoryListReturn.length > 0 &&
              this.state.FilghtCategoryListReturn.map((FilghtCategory) => {
                return (
                  <FormControlLabel
                    key={FilghtCategory.name}
                    classes={{
                      label: classes.filterCheckBoxName,
                    }}
                    control={
                      <Checkbox
                        name="flightCategory"
                        checked={
                          this.state.searchBYFilghtCategoryReturn.indexOf(
                            FilghtCategory.name
                          ) > -1
                        }
                        onChange={(evt) => {
                          this.handleFlightCategoryChangeReturn(
                            evt,
                            FilghtCategory.name
                          );
                        }}
                      />
                    }
                    label={`${FilghtCategory.name} (${FilghtCategory.count})`}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
        <br></br>
        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Stops
          </FormLabel>
          <FormGroup>
            {this.state.stopCategoryListReturn.length > 0 &&
              this.state.stopCategoryListReturn.map((stopType) => {
                return (
                  <FormControlLabel
                    classes={{
                      label: classes.filterCheckBoxName,
                    }}
                    control={
                      <Checkbox
                        name="nonStop"
                        checked={
                          this.state.searchByStopTypeReturn.indexOf(stopType.name) >
                          -1
                        }
                        onChange={(evt) => {
                          this.handleStopCategoryChangeReturn(evt, stopType.name);
                        }}
                      />
                    }
                    label={`${stopType.name} (${stopType.count})`}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
        <FormControl
          component="fieldset"
          className={classes.filterformControl}
          fullWidth
        >
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Price Range
          </FormLabel>
          <FormGroup>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={10}>
                <div style={{ width: "100%", marginTop: "40px" }}>
                  <Slider
                    valueLabelDisplay="on"
                    fullWidth
                    getAriaValueText={(value) => {
                      return value;
                    }}
                    value={this.state.searchFlightByPriceRangeReturn}
                    // step={100}
                    onChange={this.handlePriceChangeReturn}
                    min={this.state.sliderMinValueReturn}
                    max={this.state.sliderMaxValueReturn}
                    // marks={marks}
                    aria-labelledby="price-slider"
                  />
                </div>
              </Grid>
              {/* <Grid item xs={11}>
                <Grid container justify="space-between">
                  <Grid item>{`${this.state.currency} ${0}`}</Grid>

                  <Grid
                    item
                  >{`${this.state.currency} ${this.state.sliderMaxValue}`}</Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Departure From {this.state.toCity?.city}
          </FormLabel>
          <FormGroup>
            <Grid
              container
              spacing={1}
              style={{ marginTop: "16px" }}
              justify="space-around"
            >
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTimeReturn === "00:00:00-05:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChangeReturn("00:00:00-05:59:59");
                  }}
                  size="small"
                >
                  12 AM - 6 AM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTimeReturn === "06:00:00-11:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChangeReturn("06:00:00-11:59:59");
                  }}
                  size="small"
                >
                  6 AM - 12 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTimeReturn === "12:00:00-17:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChangeReturn("12:00:00-17:59:59");
                  }}
                  size="small"
                >
                  12 PM - 6 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByDepratureTimeReturn === "18:00:00-23:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleDepratureTimeChangeReturn("18:00:00-23:59:59");
                  }}
                  size="small"
                >
                  6 PM - 12 AM{" "}
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.filterformControl}>
          <FormLabel component="legend" className={classes.filterTitleHeading}>
            Arrival At {this.state.fromCity?.city}
          </FormLabel>
          <FormGroup>
            <Grid
              container
              style={{ marginTop: "16px" }}
              spacing={1}
              justify="space-around"
            >
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTimeReturn === "00:00:00-05:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChangeReturn("00:00:00-05:59:59");
                  }}
                  size="small"
                >
                  12 AM - 6 AM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTimeReturn === "06:00:00-11:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChangeReturn("06:00:00-11:59:59");
                  }}
                  size="small"
                >
                  6 AM - 12 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTimeReturn === "12:00:00-17:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChangeReturn("12:00:00-17:59:59");
                  }}
                  size="small"
                >
                  12 PM - 6 PM{" "}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  fullWidth
                  className={this.state?.searchFlightByArrivalTimeReturn === "18:00:00-23:59:59" ? classes.selectedFilterTimingButton : classes.filterTimingButton}
                  variant="outlined"
                  onClick={() => {
                    this.handleArrivalTimeChangeReturn("18:00:00-23:59:59");
                  }}
                  size="small"
                >
                  6 PM - 12 AM{" "}
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </div>
    </>
  }
  openPopover = (event, stops) => {
    this.setState({
      openpoper: !this.state.openpoper,
      poperAchor: event.currentTarget,
      stops: stops,
    });
  };

  SearchFlightFromCity = (value) => {
    this.getFromLocationSuggestion(
      value
    );
  }
  SearchFlightToCity = (value) => {
    this.getDestinationLocationSuggestion(
      value
    );
  }

  render() {
    const { classes, history } = this.props;
    const { location } = history;
    const { state } = location;
    // const marks = [];

    return (
      <div
        onClick={() => {
          this.setState({
            openpoper: false,
          });
        }}
        className={classes.root}
        ref={(ref) => (this.myRef = ref)}
      >
        <Popper
          open={this.state.openpoper}
          anchorEl={this.state.poperAchor}
          placement={"top-start"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ backgroundColor: "#1565d8", padding: "16px" }}>
                {this.state.stops.map((stop, i) => {
                  return (
                    <React.Fragment key={'stop_' + i}>
                      <Typography style={{ color: "#fff" }} sx={{ p: 2 }}>
                        {stop.stopType}
                      </Typography>
                      <Typography
                        style={{ color: "#fff" }}
                        sx={{ p: 2 }}
                        align="center"
                      >
                        {stop.stopName} | {stop.stopDuration}
                      </Typography>
                    </React.Fragment>
                  );
                })}
              </Paper>
            </Fade>
          )}
        </Popper>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${flightCover})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={flightCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />

          <Header
            childRef={(ref) => (this.headerRef = ref)}
            role={state?.role}
            setToken={(token) => {
              this.setState({
                authToken: token,
              });
            }}
          />

          <Hidden smUp implementation="css">
            <Drawer
              variant="persistent"
              anchor={"left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              SlideProps={{ unmountOnExit: true }}
            >
              <IconButton
                onClick={this.handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
              {this.filterUi(classes)}
              {this.state?.selectedTravellingType === "twoway" &&
                <Grid style={{ marginTop: "20px" }}>
                  {this.filterUiReturn(classes)}
                </Grid>
              }
            </Drawer>
          </Hidden>

          <MainFlightPost fromCity={this.state?.fromCity} toCity={this.state?.toCity} selectedTravellingType={this.state?.selectedTravellingType} searchData={{ ...this.state?.searchData, children: this.state?.childrens, infants: this.state?.infant, adults: this.state?.adult, depratureDate: this.state?.departureDate, returnDate: this.state?.returnDate }} />

          {/* <Grid container justify="center">
            <Grid item md={8} xs={10}>
              <Grid
                container
                spacing={4}
                justify="center"
                className={classes.mainPostContent}
              >
                <Grid item xs={12}>
                  <Typography className={classes.flightText} align="center">
                    Flights From
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={5} md={5}>
                      <Typography align="center" className={classes.sourceName}>
                        {this.state?.fromCity?.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Typography align="center" className={classes.sourceName}>
                        to
                      </Typography>
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <Typography align="center" className={classes.sourceName}>
                        {this.state?.toCity?.city}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item xs={5} md={5}>
                      <Typography align="center" className={classes.airportName}>
                        {this.state?.fromCity?.iata && `(${this.state?.fromCity?.iata})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <Typography align="center" className={classes.airportName}>
                        {this.state?.toCity?.iata && `(${this.state?.toCity?.iata})`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.selectedTravellingType === "twoway" && (
                  <Grid item xs={12}>
                    <Typography className={classes.ReturnText} align="center">
                      & Return
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography className={classes.welcomeText} style={{ margin: "40px 0px", fontWeight: "700" }} align="center">
                    {this.state?.departureDate && moment(`${this.state?.departureDate}`).format("DD/MM/YYYY")} {(this.state?.adult > 0 || this.state?.childrens > 0) && `|`} {this.state?.adult > 0 && `${this.state?.adult} Adults`} {(this.state?.adult > 0 && this.state?.childrens > 0) && `,`} {this.state?.childrens > 0 && `${this.state?.childrens} Children`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.welcomeText} style={{ marginBottom: "60px" }} align="center">
                    <span style={{ borderBottom: "1px solid rgba(255,255,255, 1)" }}>Change your search from <Link to="#">here</Link></span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={10}>

              {/* One Way Start */}

              {this.state.selectedTravellingType === "oneway" && (
                <Formik
                  enableReinitialize
                  initialValues={
                    this.props?.history?.location?.state?.data
                      ? this.props.history.location.state.data
                      : {
                        from: this.state.searchData.from || "",
                        to: this.state.searchData.to || "",
                        depratureDate:
                          this.state.searchData.depratureDate || "",
                        adults: this.state.searchData.adults || "1",
                        children: this.state.searchData.children || "0",
                        class: this.state.searchData.class || "Economy",
                        infants: this.state.searchData.infants || "0",
                      }
                  }
                  validationSchema={flightOneWaySchema}
                  onSubmit={(values) => {
                    this.setState(
                      {
                        pageCount: 0,
                        selectedFlight1: null,
                        selectedFlight2: null,
                        searchBYFilghtCategory: [],
                        searchByStopType: [],
                        searchFlightByDepratureTime: "",
                        searchFlightByArrivalTime: "",
                        searchFlightByPriceRange: [],
                        searchBySortingFlight: [],
                      },
                      () => {
                        this.getOneWayFlight(values);
                        this.getOneWayFlightForFilter(values);
                      }
                    );
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                    } = formikProps;
                    this.submitForm = handleSubmit;
                    return (
                      <Form autoComplete="off">
                        {/* {JSON.stringify(errors)} */}
                        <Grid
                          container
                          spacing={2}
                          justify="space-evenly"
                          // alignItems="center"
                          className={classes.mainPostSecondaryContent + " " + classes.mainPostSecondaryContentbgcolor}
                        >
                          {this.state.selectedTravellingType && (
                            <Grid item xs={12}>
                              <FormControl component="fieldset" className={classes.borderedBottommain}>
                                <Box className={classes.borderedBottom}>
                                  <Tabs
                                    value={this.state.selectedTravellingType}
                                    onChange={(evt, value) => {
                                      this.setState({
                                        selectedTravellingType: value,
                                        searchData: values,
                                        oneWayflights: [],
                                        twoWayFlights1: [],
                                        twoWayFlights2: [],
                                        selectedFlight1: null,
                                        selectedFlight2: null,
                                        searchBYFilghtCategory: [],
                                        searchBYFilghtCategoryReturn: [],
                                        searchByStopType: [],
                                        searchByStopTypeReturn: [],
                                        searchFlightByDepratureTime: "",
                                        searchFlightByDepratureTimeReturn: "",
                                        searchFlightByArrivalTime: "",
                                        searchFlightByArrivalTimeReturn: "",
                                        searchFlightByPriceRange: [],
                                        searchFlightByPriceRangeReturn: [],
                                        FilghtCategoryList: [],
                                        FilghtCategoryListReturn: [],
                                        stopCategoryList: [],
                                        stopCategoryListReturn: [],
                                        sliderMinValue: 0,
                                        sliderMinValueReturn: 0,
                                        sliderMaxValue: 0,
                                        sliderMaxValueReturn: 0,
                                      });
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"

                                  >
                                    <Tab value={"oneway"} label="One Way" classes={{ root: classes.tabLabel }} />
                                    <Tab value={"twoway"} label="Two Way" classes={{ root: classes.tabLabel }} />
                                    <Tab value={"multicity"} label="Multicity" classes={{ root: classes.tabLabel }} />

                                  </Tabs>
                                </Box>

                                {/* <RadioGroup
                                  row
                                  aria-label="position"
                                  name="selectedTravellingType"
                                  onChange={(event) => {
                                    this.setState({
                                      selectedTravellingType:
                                        event.target.value,
                                      searchData: values,
                                      oneWayflights: [],
                                      twoWayFlights1: [],
                                      twoWayFlights2: [],
                                      selectedFlight1: null,
                                      selectedFlight2: null,
                                      searchBYFilghtCategory: [],
                                      searchByStopType: [],
                                      searchFlightByDepratureTime: "",
                                      searchFlightByArrivalTime: "",
                                      searchFlightByPriceRange: [],
                                      FilghtCategoryList: [],
                                      stopCategoryList: [],
                                      sliderMinValue: 0,
                                      sliderMaxValue: 0,
                                    });
                                  }}
                                  defaultValue={
                                    this.state.selectedTravellingType
                                  }
                                  value={this.state.selectedTravellingType}
                                >
                                  <FormControlLabel
                                    classes={{
                                      label: classes.radioButtonLabel,
                                    }}
                                    value="oneway"
                                    control={
                                      <Radio
                                        color="primary"
                                        defaultChecked={
                                          this.state.selectedTravellingType ===
                                          "oneway"
                                        }
                                      />
                                    }
                                    label="One Way"
                                  />
                                  <FormControlLabel
                                    classes={{
                                      label: classes.radioButtonLabel,
                                    }}
                                    value="twoway"
                                    control={
                                      <Radio
                                        defaultChecked={
                                          this.state.selectedTravellingType ===
                                          "twoway"
                                        }
                                        color="primary"
                                      />
                                    }
                                    label="Two way"
                                  />
                                </RadioGroup> */}
                              </FormControl>
                            </Grid>
                          )}
                          <Grid container justify="center" alignItems="center">
                            <TabPanel
                              value={this.state.selectedSearchType}
                              index={this.state.selectedSearchType}
                              style={{ width: '100%' }}
                            >

                              <Grid item xs={12} md={12}>
                                <Grid container spacing={2} alignItems="center" style={{ marginBottom: '18px' }}>
                                  <Grid item xs={12} md={4}>
                                    <Autocomplete
                                      freeSolo
                                      value={this.state?.fromCity || {}}
                                      filterOptions={(options, state) => options}
                                      style={{ width: "100%" }}
                                      renderOption={(value) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                gutterBottom
                                                color="textPrimary"
                                              >
                                                {value?.city || ""}
                                              </Typography>
                                              <Typography color="textSecondary">
                                                {value?.name || ""}
                                              </Typography>
                                            </div>
                                            <Typography>{value.iata}</Typography>
                                          </div>
                                        );
                                      }}
                                      getOptionLabel={(value) =>
                                        `${value.city || ""}${value.city ? "," : ""
                                        }${value.country || ""}${value.iata ? "(" : ""
                                        }${value.iata || ""}${value.iata ? ")" : ""
                                        }`
                                      }
                                      options={
                                        this.state.sourcesearchSuggestionList
                                      }
                                      loading={false}
                                      onInputChange={(evt) => {
                                        if (evt) {
                                          setFieldTouched("from", true);
                                          setFieldValue("from", "");
                                          this.getFromLocationSuggestion(
                                            evt?.target?.value
                                          );
                                          // this.SearchFlightFromCity(evt?.target?.value)
                                        }
                                        else {
                                          setFieldTouched("from", false);
                                        }
                                      }}
                                      onChange={(evt, value) => {
                                        if (value) {
                                          setFieldValue(
                                            "from",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            sourceSuggestionloading: false,
                                            fromCity: value,
                                          });
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder="Enter Airport or City Name"
                                          variant="outlined"
                                          label="From"
                                          InputLabelProps={{
                                            ...params.InputLabelProps,
                                            shrink: true,
                                            classes: {
                                              asterisk: classes?.astriklabel,
                                              outlined: classes?.outlinedLabel,
                                              shrink: classes?.shrink,
                                              root: classes?.lableroot,
                                            },
                                          }}
                                          // required
                                          InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                              input: classes?.input,
                                              root: classes?.inputfieledroot,
                                            },
                                            notched: false,
                                            endAdornment: (
                                              <React.Fragment>
                                                {this.state
                                                  .sourceSuggestionloading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {/* {
                                                  <InputAdornment position="end">
                                                    <IconButton aria-label="search">
                                                      <SearchIcon />
                                                    </IconButton>
                                                  </InputAdornment>
                                                } */}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.from && touched.from && (
                                      <Typography color="error" style={{ position: 'absolute' }}>
                                        {errors.from}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} md={4} onClick={() => this.setState({ SelectOption: false })}>
                                    <Autocomplete
                                      value={this.state?.toCity || {}}
                                      filterOptions={(options, state) => options}
                                      freeSolo
                                      style={{ width: "100%" }}
                                      options={
                                        this.state.destinationsearchSuggestionList
                                      }
                                      loading={false}
                                      onInputChange={(evt) => {
                                        this.getDestinationLocationSuggestion(
                                          evt?.target?.value
                                        );
                                      }}
                                      onInputChange={(evt) => {
                                        if (evt) {
                                          setFieldTouched("to", true);
                                          setFieldValue("to", "");
                                          this.getDestinationLocationSuggestion(
                                            evt?.target?.value
                                          );
                                          // this.SearchFlightToCity(evt?.target?.value)
                                        }
                                      }}
                                      onChange={(evt, value) => {
                                        if (value) {
                                          setFieldValue(
                                            "to",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            destinationSuggestionloading: false,
                                            toCity: value,
                                          });
                                        }
                                      }}
                                      renderOption={(value) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                gutterBottom
                                                color="textPrimary"
                                              >
                                                {value.city}
                                              </Typography>
                                              <Typography color="textSecondary">
                                                {value.name}
                                              </Typography>
                                            </div>
                                            <Typography>{value.iata}</Typography>
                                          </div>
                                        );
                                      }}
                                      getOptionLabel={(value) =>
                                        `${value.city || ""}${value.city ? "," : ""
                                        }${value.country || ""}${value.iata ? "(" : ""
                                        }${value.iata || ""}${value.iata ? ")" : ""
                                        }`
                                      }
                                      onBlur={() => {
                                        setFieldTouched("to", true);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder="Enter Airport or City Name"
                                          variant="outlined"
                                          label="To"
                                          InputLabelProps={{
                                            ...params.InputLabelProps,
                                            shrink: true,
                                            classes: {
                                              asterisk: classes?.astriklabel,
                                              outlined: classes?.outlinedLabel,
                                              shrink: classes?.shrink,
                                              root: classes?.lableroot,
                                            },
                                          }}
                                          // required
                                          InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                              input: classes?.input,
                                              root: classes?.inputfieledroot,
                                            },
                                            notched: false,
                                            endAdornment: (
                                              <React.Fragment>
                                                {this.state
                                                  .destinationSuggestionloading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {/* {
                                                  <InputAdornment position="end">
                                                    <IconButton aria-label="search">
                                                      <SearchIcon />
                                                    </IconButton>
                                                  </InputAdornment>
                                                } */}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.to && touched.to && (
                                      <Typography color="error" style={{ position: 'absolute' }}>
                                        {errors.to}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} md={4} onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="depratureDate"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      placeholder="Enter Departure Date"
                                      onChange={(val) => {
                                        setFieldValue("depratureDate", val.target.value); this.setState({
                                          departureDate: val.target.value
                                        })
                                      }}
                                      value={values.depratureDate}
                                      fullWidth
                                      label="Departure"
                                      className={classes.errorhandle}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid xs={12} md={12} item >
                                <Grid container spacing={2} alignItems="center">
                                  {/* <Grid xs={12} md={3} item>
                                    <Field
                                      component={InputFieled}
                                      select
                                      name="adults"
                                      onChange={(val) => {
                                        setFieldValue("adults", val.target.value); this.setState({
                                          adult: val.target.value
                                        })
                                      }}
                                      value={values.adults}
                                      fullWidth
                                      label="Adults(12 yrs.+)"
                                    >
                                      {[
                                        {
                                          value: "1",
                                        },
                                        {
                                          value: "2",
                                        },
                                        {
                                          value: "3",
                                        },
                                        {
                                          value: "4",
                                        },
                                        {
                                          value: "5",
                                        },
                                        {
                                          value: "6",
                                        },
                                        {
                                          value: "7",
                                        },
                                        {
                                          value: "8",
                                        },
                                        {
                                          value: "9",
                                        },
                                      ].map((value) => {
                                        return (
                                          <MenuItem
                                            key={value.value}
                                            value={value.value}
                                          >
                                            {value.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                  <Grid xs={12} md={3} item>
                                    <Field
                                      select
                                      component={InputFieled}
                                      name="children"
                                      onChange={(val) => {
                                        setFieldValue("children", val.target.value); this.setState({
                                          childrens: val.target.value
                                        })
                                      }}
                                      value={values.children}
                                      fullWidth
                                      label="Children(2yr - 12 yr)"
                                    >
                                      {[
                                        {
                                          value: "0",
                                        },
                                        {
                                          value: "1",
                                        },
                                        {
                                          value: "2",
                                        },
                                        {
                                          value: "3",
                                        },
                                        {
                                          value: "4",
                                        },
                                        {
                                          value: "5",
                                        },
                                        {
                                          value: "6",
                                        },
                                      ].map((value) => {
                                        return (
                                          <MenuItem
                                            key={value.value}
                                            value={value.value}
                                          >
                                            {value.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                  <Grid xs={12} md={3} item>
                                    <Field
                                      component={InputFieled}
                                      name="infants"
                                      placeholder="No. of Children"
                                      onChange={handleChange}
                                      select
                                      value={values.infants}
                                      fullWidth
                                      label="Infant(Below 2yr)"
                                    >
                                      {" "}
                                      {[
                                        {
                                          value: "0",
                                        },
                                        {
                                          value: "1",
                                        },
                                        {
                                          value: "2",
                                        },
                                        {
                                          value: "3",
                                        },
                                        {
                                          value: "4",
                                        },
                                        {
                                          value: "5",
                                        },
                                        {
                                          value: "6",
                                        },
                                      ].map((value) => {
                                        return (
                                          <MenuItem
                                            key={value.value}
                                            value={value.value}
                                          >
                                            {value.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid> */}
                                  <Grid xs={12} md={4} item>
                                    <div className={classes.customSelect}>
                                      <Typography className={classes.travellerheading}>Travellers</Typography>
                                      <Button onClick={this.dropDownSelect} className={classes.InputFieldSelect}>Adult {this.state.counter} | Child {this.state.counter1} | Infant {this.state.counter2}</Button>
                                      <Box className={classes.dropdownIcon}><ArrowDropDownIcon /></Box>
                                      {this.state.SelectOption &&
                                        <ul className={classes.DropdownMenu}>
                                          {this.state.PasangerValus.map((data, index) => {
                                            return (

                                              <li className={classes.Dropdown}>
                                                <div className={classes.DropDownList} onClick={(event) => this.dropDowmalues(event, data.name)}>
                                                  <p className={classes.DropDownMenu1}>{data.name}</p>
                                                  <ButtonGroup size="small" aria-label="small outlined button group" className={classes.DropDownMenu2}>
                                                    <Button
                                                      onClick={() => {

                                                        if (data.name === 'Adult') {
                                                          setFieldValue("adults", this.state.counter - 1),
                                                            this.setState({
                                                              adult: this.state.counter - 1,
                                                              counter: this.state.counter - 1
                                                            })
                                                        }
                                                        else if (data.name === 'Child') {
                                                          setFieldValue("children", this.state.counter1 - 1),
                                                            this.setState({
                                                              childrens: this.state.counter1 - 1,
                                                              counter1: this.state.counter1 - 1
                                                            })
                                                        }
                                                        else if (data.name === 'Infant') {
                                                          setFieldValue("infants", this.state.counter2 - 1),
                                                            this.setState({
                                                              infants: this.state.counter2 - 1,
                                                              counter2: this.state.counter2 - 1
                                                            })
                                                        }

                                                      }}
                                                      disabled={data.name === 'Adult' && this.state.counter <= 1 ? true :
                                                        data.name === 'Child' && this.state.counter1 <= 0 ? true :
                                                          data.name === 'Infant' && this.state.counter2 <= 0 ? true : false} className={classes.plusbutton}><RemoveIcon style={{ fontSize: "1rem" }} /></Button>
                                                    <Button disabled>
                                                      {data.name === 'Adult' ? this.state.counter : data.name === 'Child' ?
                                                        this.state.counter1 : this.state.counter2}
                                                    </Button>

                                                    <Button onClick={(val) => {
                                                      const { counter, counter1, counter2 } = this.state;
                                                      let status = this.validateCount(counter, counter1, counter2);
                                                      if (status) {
                                                        if (data.name === 'Adult') {
                                                          setFieldValue("adults", this.state.counter + 1),
                                                            this.setState({
                                                              adult: this.state.counter + 1,
                                                              counter: this.state.counter + 1
                                                            })
                                                        }
                                                        else if (data.name === 'Child') {
                                                          setFieldValue("children", this.state.counter1 + 1),
                                                            this.setState({
                                                              childrens: this.state.counter1 + 1,
                                                              counter1: this.state.counter1 + 1
                                                            })
                                                        }
                                                        else if (data.name === 'Infant') {
                                                          setFieldValue("infants", this.state.counter2 + 1),
                                                            this.setState({
                                                              infants: this.state.counter2 + 1,
                                                              counter2: this.state.counter2 + 1
                                                            })
                                                        } this.setState({ travellerCountStatus: false })

                                                      } else {
                                                        this.setState({ travellerCountStatus: true })
                                                      }
                                                    }}
                                                      disabled={data.name === 'Infant' && this.state.counter2 >= this.state.counter ? true : false} className={classes.plusbutton}><AddIcon style={{ fontSize: "1rem" }} /></Button>
                                                  </ButtonGroup>
                                                </div>
                                              </li>
                                            )
                                          })}
                                          {
                                            this.state.travellerCountStatus ?
                                              <li className={classes.Dropdown}>
                                                <Typography className={classes.countText}>you can book for a maximum of 9 passangers</Typography >
                                              </li>
                                              :
                                              null
                                          }

                                        </ul>
                                      }
                                    </div>
                                  </Grid>

                                  <Grid item xs={12} md={4} onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="class"
                                      select
                                      placeholder="Class"
                                      value={values.class}
                                      fullWidth
                                      label="Category"
                                    >
                                      {[
                                        {
                                          value: "Economy",
                                        },
                                        {
                                          value: "Premimum Economy",
                                        },
                                        {
                                          value: "Business",
                                        },
                                        {
                                          value: "First Class",
                                        },
                                      ].map((value) => {
                                        return (
                                          <MenuItem
                                            key={value.value}
                                            value={value.value}
                                          >
                                            {value.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} onClick={() => this.setState({ SelectOption: false })}>
                                <Grid container className={classes.boederedTop} spacing={2}>
                                  <Grid xs={12} md={6} item >
                                  </Grid>
                                  <Grid xs={12} md={3} item>
                                    {/* <Box py={3}> */}
                                    <Button
                                      fullWidth
                                      type="reset"
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => this.ClearDataForm('oneway', setFieldValue)}
                                    >
                                      Clear
                                    </Button>
                                    {/* </Box> */}
                                  </Grid>
                                  <Grid xs={12} md={3} item>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      type="submit"
                                      fullWidth
                                      onClick={() => this.setState({ SelectOption: false })}
                                    >
                                      {this.state?.oneWayflights?.length > 0
                                        ? "Modify Search"
                                        : "Search"}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TabPanel>
                          </Grid>
                        </Grid>

                      </Form>
                    );
                  }}
                </Formik>
              )};

              {/* One Way End */}

              {/* Two Way Start */}

              {this.state.selectedTravellingType === "twoway" && (

                <Formik
                  initialValues={
                    this.props?.history?.location?.state?.data
                      ? this.props.history.location.state.data
                      : {
                        from: this.state.searchData.from || "",
                        to: this.state.searchData.to || "",
                        depratureDate:
                          this.state.searchData.depratureDate || "",
                        returnDate: this.state.searchData.returnDate || "",
                        adults: this.state.searchData.adults || "",
                        children: this.state.searchData.children || "0",
                        class: this.state.searchData.class || "Economy",
                        infants: this.state.searchData.infants || "0",
                      }
                  }
                  enableReinitialize
                  validationSchema={flightTwoWaySchema}
                  onSubmit={(values) => {
                    this.setState(
                      {
                        twoWayPage: 0,
                        twoWayReturnPage: 0,
                        selectedFlight2: null,
                        selectedFlight1: null,
                        searchBYFilghtCategory: [],
                        searchBYFilghtCategoryReturn: [],
                        searchByStopType: [],
                        searchByStopTypeReturn: [],
                        searchFlightByDepratureTime: "",
                        searchFlightByDepratureTimeReturn: "",
                        searchFlightByArrivalTime: "",
                        searchFlightByArrivalTimeReturn: "",
                        searchFlightByPriceRange: [],
                        searchFlightByPriceRangeReturn: [],
                        searchBySortingFlight: [],
                        searchBySortingFlightReturn: [],
                      },
                      () => {
                        this.getTwoWaydepratureSideFlightForFilter(values);
                        this.getTwoWayReturnSideFlightForFilter(values);
                        this.getTwoWayReturnSideFlight(values);
                        this.getTwoWaydepratureSideFlight(values);
                      }
                    );
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                    } = formikProps;
                    return (
                      <Form autoComplete="off">
                        <Grid
                          container
                          spacing={2}
                          justify="space-evenly"
                          className={classes.mainPostSecondaryContent + " " + classes.mainPostSecondaryContentbgcolor}
                        >
                          {this.state.selectedTravellingType && (
                            <Grid item xs={12}>
                              <FormControl component="fieldset" className={classes.borderedBottommain}>
                                {/* <FormLabel
                  className={classes.radioGroupTitle}
                  component="legend"
                >
                  Travelling Type
                </FormLabel> */}
                                <Box className={classes.borderedBottom}>
                                  <Tabs
                                    value={this.state.selectedTravellingType}
                                    onChange={(evt, value) => {
                                      this.setState({
                                        selectedTravellingType: value,
                                        searchData: values,
                                        oneWayflights: [],
                                        twoWayFlights1: [],
                                        twoWayFlights2: [],
                                        selectedFlight1: null,
                                        selectedFlight2: null,
                                        searchBYFilghtCategory: [],
                                        searchBYFilghtCategoryReturn: [],
                                        searchByStopType: [],
                                        searchByStopTypeReturn: [],
                                        searchFlightByDepratureTime: "",
                                        searchFlightByDepratureTimeReturn: "",
                                        searchFlightByArrivalTime: "",
                                        searchFlightByArrivalTimeReturn: "",
                                        searchFlightByPriceRange: [],
                                        searchFlightByPriceRangeReturn: [],
                                        searchBySortingFlight: [],
                                        searchBySortingFlightReturn: [],
                                        FilghtCategoryList: [],
                                        FilghtCategoryListReturn: [],
                                        stopCategoryList: [],
                                        stopCategoryListReturn: [],
                                        sliderMinValue: 0,
                                        sliderMinValueReturn: 0,
                                        sliderMaxValue: 0,
                                        sliderMaxValueReturn: 0,
                                      });
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"

                                  >
                                    <Tab value={"oneway"} label="One Way" classes={{ root: classes.tabLabel }} />
                                    <Tab value={"twoway"} label="Two Way" classes={{ root: classes.tabLabel }} />
                                    <Tab value={"multicity"} label="Multicity" classes={{ root: classes.tabLabel }} />

                                  </Tabs>
                                </Box>
                                {/* <RadioGroup
                  row
                  aria-label="position"
                  name="selectedTravellingType"
                  onChange={(event) => {
                    this.setState({
                      selectedTravellingType:
                        event.target.value,
                      searchData: values,
                      oneWayflights: [],
                      twoWayFlights1: [],
                      twoWayFlights2: [],
                      selectedFlight1: null,
                      selectedFlight2: null,
                      searchBYFilghtCategory: [],
                      searchByStopType: [],
                      searchFlightByDepratureTime: "",
                      searchFlightByArrivalTime: "",
                      searchFlightByPriceRange: [],
                      FilghtCategoryList: [],
                      stopCategoryList: [],
                      sliderMinValue: 0,
                      sliderMaxValue: 0,
                    });
                  }}
                  defaultValue={
                    this.state.selectedTravellingType
                  }
                  value={this.state.selectedTravellingType}
                >
                  <FormControlLabel
                    classes={{
                      label: classes.radioButtonLabel,
                    }}
                    value="oneway"
                    control={
                      <Radio
                        color="primary"
                        defaultChecked={
                          this.state.selectedTravellingType ===
                          "oneway"
                        }
                      />
                    }
                    label="One Way"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.radioButtonLabel,
                    }}
                    value="twoway"
                    control={
                      <Radio
                        defaultChecked={
                          this.state.selectedTravellingType ===
                          "twoway"
                        }
                        color="primary"
                      />
                    }
                    label="Two way"
                  />
                </RadioGroup> */}
                              </FormControl>
                            </Grid>
                          )}
                          <TabPanel
                            value={this.state.selectedSearchType}
                            index={this.state.selectedSearchType}
                            style={{ width: '100%' }}
                          >
                            <Grid container justify="center" >
                              <Grid item xs={12} md={12}>
                                <Grid container spacing={2} alignItems="center" style={{ marginBottom: '18px' }} >
                                  <Grid xs={12} md={4} item>
                                    <Autocomplete
                                      freeSolo
                                      filterOptions={(options, state) => options}
                                      style={{ width: "100%" }}
                                      renderOption={(value) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                gutterBottom
                                                color="textPrimary"
                                              >
                                                {value.city}
                                              </Typography>
                                              <Typography color="textSecondary">
                                                {value.name}
                                              </Typography>
                                            </div>
                                            <Typography>{value.iata}</Typography>
                                          </div>
                                        );
                                      }}
                                      getOptionLabel={(value) =>
                                        `${value.city || ""}${value.city ? "," : ""
                                        }${value.country || ""}${value.iata ? "(" : ""
                                        }${value.iata || ""}${value.iata ? ")" : ""
                                        }`
                                      }
                                      options={
                                        this.state.sourcesearchSuggestionList
                                      }
                                      loading={false}
                                      value={this.state?.fromCity || {}}
                                      onInputChange={(evt) => {
                                        if (evt) {
                                          setFieldTouched("from", true);
                                          setFieldValue("from", "");
                                          this.getFromLocationSuggestion(
                                            evt?.target?.value
                                          );
                                          // this.SearchFlightFromCity(evt?.target?.value)
                                        }
                                        else {
                                          setFieldTouched("from", false);
                                        }
                                      }}
                                      onChange={(evt, value) => {
                                        if (value) {
                                          setFieldValue(
                                            "from",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            sourceSuggestionloading: false,
                                            fromCity: value,
                                          });
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder="Enter Airport or City Name"
                                          variant="outlined"
                                          label="From"
                                          InputLabelProps={{
                                            ...params.InputLabelProps,
                                            shrink: true,
                                            classes: {
                                              asterisk: classes?.astriklabel,
                                              outlined: classes?.outlinedLabel,
                                              shrink: classes?.shrink,
                                              root: classes?.lableroot,
                                            },
                                          }}
                                          // required
                                          InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                              input: classes?.input,
                                              root: classes?.inputfieledroot,
                                            },
                                            notched: false,
                                            endAdornment: (
                                              <React.Fragment>
                                                {this.state
                                                  .sourceSuggestionloading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {/* {
                                  <InputAdornment position="end">
                                    <IconButton aria-label="search">
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                } */}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.from && touched.from && (
                                      <Typography color="error" style={{ position: 'absolute' }}>
                                        {errors.from}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                    <Autocomplete
                                      value={this.state?.toCity || {}}
                                      filterOptions={(options, state) => options}
                                      freeSolo
                                      style={{ width: "100%" }}
                                      options={
                                        this.state.destinationsearchSuggestionList
                                      }
                                      loading={false}
                                      onInputChange={(evt) => {
                                        if (evt) {
                                          setFieldTouched("to", true);
                                          setFieldValue("to", "");
                                          this.getDestinationLocationSuggestion(
                                            evt?.target?.value
                                          );
                                          // this.SearchFlightToCity(evt?.target?.value)
                                        }
                                      }}
                                      onChange={(evt, value) => {
                                        if (value) {
                                          setFieldValue(
                                            "to",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            destinationSuggestionloading: false,
                                            toCity: value,
                                          });
                                        }
                                      }}
                                      renderOption={(value) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                gutterBottom
                                                color="textPrimary"
                                              >
                                                {value.city}
                                              </Typography>
                                              <Typography color="textSecondary">
                                                {value.name}
                                              </Typography>
                                            </div>
                                            <Typography>{value.iata}</Typography>
                                          </div>
                                        );
                                      }}
                                      getOptionLabel={(value) =>
                                        `${value.city || ""}${value.city ? "," : ""
                                        }${value.country || ""}${value.iata ? "(" : ""
                                        }${value.iata || ""}${value.iata ? ")" : ""
                                        }`
                                      }
                                      onBlur={() => {
                                        setFieldTouched("to", true);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder="Enter Airport or City Name"
                                          variant="outlined"
                                          label="To"
                                          InputLabelProps={{
                                            ...params.InputLabelProps,
                                            shrink: true,
                                            classes: {
                                              asterisk: classes?.astriklabel,
                                              outlined: classes?.outlinedLabel,
                                              shrink: classes?.shrink,
                                              root: classes?.lableroot,
                                            },
                                          }}
                                          // required
                                          InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                              input: classes?.input,
                                              root: classes?.inputfieledroot,
                                            },
                                            notched: false,
                                            endAdornment: (
                                              <React.Fragment>
                                                {this.state
                                                  .destinationSuggestionloading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {/* {
                                  <InputAdornment position="end">
                                    <IconButton aria-label="search">
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                } */}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.to && touched.to && (
                                      <Typography color="error" style={{ position: 'absolute' }}>
                                        {errors.to}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} md={4} onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="depratureDate"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      placeholder="Enter Departure Date"
                                      onChange={handleChange}
                                      value={values.depratureDate}
                                      fullWidth
                                      label="Departure"
                                      className={classes.errorhandle}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="returnDate"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      placeholder="Enter Departure Date"
                                      onChange={handleChange}
                                      value={values.returnDate}
                                      fullWidth
                                      label="Return Date"
                                      className={classes.errorhandle}
                                    />
                                  </Grid>

                                  <Grid xs={10} md={4} item>
                                    <div className={classes.customSelect}>
                                      <Typography className={classes.travellerheading}>Travellers</Typography>
                                      <Button onClick={this.dropDownSelect} className={classes.InputFieldSelect}>Adult {this.state.counter} | Child {this.state.counter1} | Infant {this.state.counter2}</Button>
                                      <Box className={classes.dropdownIcon}><ArrowDropDownIcon /></Box>
                                      {this.state.SelectOption &&
                                        <ul className={classes.DropdownMenu}>
                                          {this.state.PasangerValus.map((data, index) => {
                                            return (
                                              <li className={classes.Dropdown}>
                                                <div className={classes.DropDownList} onClick={(event) => this.dropDowmalues(event, data.name)}>
                                                  <p className={classes.DropDownMenu1}>{data.name}</p>
                                                  <ButtonGroup size="small" aria-label="small outlined button group" className={classes.DropDownMenu2}>
                                                    <Button
                                                      onClick={() => {

                                                        if (data.name === 'Adult') {
                                                          setFieldValue("adults", this.state.counter - 1),
                                                            this.setState({
                                                              adult: this.state.counter - 1,
                                                              counter: this.state.counter - 1
                                                            })
                                                        }
                                                        else if (data.name === 'Child') {
                                                          setFieldValue("children", this.state.counter1 - 1),
                                                            this.setState({
                                                              childrens: this.state.counter1 - 1,
                                                              counter1: this.state.counter1 - 1
                                                            })
                                                        }
                                                        else if (data.name === 'Infant') {
                                                          setFieldValue("infants", this.state.counter2 - 1),
                                                            this.setState({
                                                              infants: this.state.counter2 - 1,
                                                              counter2: this.state.counter2 - 1
                                                            })
                                                        }

                                                      }}
                                                      disabled={data.name === 'Adult' && this.state.counter <= 1 ? true :
                                                        data.name === 'Child' && this.state.counter1 <= 0 ? true :
                                                          data.name === 'Infant' && this.state.counter2 <= 0 ? true : false} className={classes.plusbutton}><RemoveIcon style={{ fontSize: "1rem" }} /></Button>
                                                    <Button disabled>
                                                      {data.name === 'Adult' ? this.state.counter : data.name === 'Child' ?
                                                        this.state.counter1 : this.state.counter2}
                                                    </Button>

                                                    <Button onClick={(val) => {
                                                      const { counter, counter1, counter2 } = this.state;
                                                      let status = this.validateCount(counter, counter1, counter2);
                                                      if (status) {
                                                        if (data.name === 'Adult') {
                                                          setFieldValue("adults", this.state.counter + 1),
                                                            this.setState({
                                                              adult: this.state.counter + 1,
                                                              counter: this.state.counter + 1
                                                            })
                                                        }
                                                        else if (data.name === 'Child') {
                                                          setFieldValue("children", this.state.counter1 + 1),
                                                            this.setState({
                                                              childrens: this.state.counter1 + 1,
                                                              counter1: this.state.counter1 + 1
                                                            })
                                                        }
                                                        else if (data.name === 'Infant') {
                                                          setFieldValue("infants", this.state.counter2 + 1),
                                                            this.setState({
                                                              infants: this.state.counter2 + 1,
                                                              counter2: this.state.counter2 + 1
                                                            })
                                                        }
                                                        this.setState({ travellerCountStatus: false })
                                                      }
                                                      else {
                                                        this.setState({ travellerCountStatus: true })
                                                      }
                                                    }}
                                                      disabled={data.name === 'Infant' && this.state.counter2 >= this.state.counter ? true : false} className={classes.plusbutton}><AddIcon style={{ fontSize: "1rem" }} /></Button>
                                                  </ButtonGroup>
                                                </div>
                                              </li>
                                            )
                                          })}
                                          {
                                            this.state.travellerCountStatus ?
                                              <li className={classes.Dropdown}>
                                                <Typography className={classes.countText}>you can book for a maximum of 9 passangers</Typography >
                                              </li>
                                              :
                                              null
                                          }
                                        </ul>}
                                    </div>
                                  </Grid>

                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="class"
                                      select
                                      placeholder="Class"
                                      value={values.class}
                                      fullWidth
                                      label="Category"
                                    >
                                      {[
                                        {
                                          value: "Economy",
                                        },
                                        {
                                          value: "Premimum Economy",
                                        },
                                        {
                                          value: "Business",
                                        },
                                        {
                                          value: "First Class",
                                        },
                                      ].map((value) => {
                                        return (
                                          <MenuItem
                                            key={value.value}
                                            value={value.value}
                                          >
                                            {value.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* <Grid container spacing={2} alignItems="center"> */}
                              {/* <Grid xs={6} md={3} item>
                  <Field
                    component={InputFieled}
                    select
                    name="adults"
                    onChange={handleChange}
                    value={values.adults}
                    fullWidth
                    label="Adults(12 yrs.+)"
                  >
                    {[
                      {
                        value: "1",
                      },
                      {
                        value: "2",
                      },
                      {
                        value: "3",
                      },
                      {
                        value: "4",
                      },
                      {
                        value: "5",
                      },
                      {
                        value: "6",
                      },
                      {
                        value: "7",
                      },
                      {
                        value: "8",
                      },
                      {
                        value: "9",
                      },
                    ].map((value) => {
                      return (
                        <MenuItem
                          key={value.value}
                          value={value.value}
                        >
                          {value.value}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Grid> */}
                              {/* <Grid xs={6} md={3} item>
                  <Field
                    select
                    component={InputFieled}
                    name="children"
                    onChange={handleChange}
                    value={values.children}
                    fullWidth
                    label="Children(2yr - 12 yr)"
                  >
                    {[
                      {
                        value: "0",
                      },
                      {
                        value: "1",
                      },
                      {
                        value: "2",
                      },
                      {
                        value: "3",
                      },
                      {
                        value: "4",
                      },
                      {
                        value: "5",
                      },
                      {
                        value: "6",
                      },
                    ].map((value) => {
                      return (
                        <MenuItem
                          key={value.value}
                          value={value.value}
                        >
                          {value.value}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Grid> */}

                              {/* <Grid xs={6} md={3} item>
                  <Field
                    component={InputFieled}
                    name="infants"
                    placeholder="No. of Children"
                    onChange={handleChange}
                    select
                    value={values.infants}
                    fullWidth
                    label="Infant(Below 2yr)"
                  >
                    {" "}
                    {[
                      {
                        value: "0",
                      },
                      {
                        value: "1",
                      },
                      {
                        value: "2",
                      },
                      {
                        value: "3",
                      },
                      {
                        value: "4",
                      },
                      {
                        value: "5",
                      },
                      {
                        value: "6",
                      },
                    ].map((value) => {
                      return (
                        <MenuItem
                          key={value.value}
                          value={value.value}
                        >
                          {value.value}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Grid> */}

                              {/* </Grid> */}
                            </Grid>
                            <Grid container spacing={2} onClick={() => this.setState({ SelectOption: false })} onClick={() => this.setState({ SelectOption: false })}>
                              <Grid container className={classes.boederedTop} spacing={2}>
                                <Grid xs={12} md={6} item>
                                </Grid>
                                <Grid xs={12} md={3} item>
                                  {/* <Box py={3}> */}
                                  <Button
                                    fullWidth
                                    type="reset"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => this.ClearDataForm('oneway', setFieldValue)}
                                  >
                                    Clear
                                  </Button>
                                  {/* </Box> */}
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  style={{
                                    alignSelf: "center",
                                    justifySelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                  >
                                    {(this.state?.twoWayFlights1?.length > 0 || this.state?.twoWayFlights2?.length > 0)
                                      ? "Modify Search"
                                      : "Search"}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              )};
              {/* Two Way End */}


              {/* multicity strted  */}

              {this.state.selectedTravellingType === "multicity" && (
                <Formik
                  initialValues={

                    this.props?.history?.location?.state?.data
                      ? this.props.history.location.state.data
                      : {
                        from: this.state.searchData.from || "",
                        to: this.state.searchData.to || "",
                        depratureDate:
                          this.state.searchData.depratureDate || "",
                        returnDate: this.state.searchData.returnDate || "",
                        adults: this.state.searchData.adults || "",
                        children: this.state.searchData.children || "0",
                        class: this.state.searchData.class || "Economy",
                        infants: this.state.searchData.infants || "0",
                        // newCity: [
                        //   { from: "", to: "", depratureDate: "" },
                        // ],
                      }

                  }
                  enableReinitialize
                  validationSchema={flightTwoWaySchema}
                  onSubmit={(values) => {
                    this.setState(
                      {
                        twoWayPage: 0,
                        twoWayReturnPage: 0,
                        selectedFlight2: null,
                        selectedFlight1: null,
                        searchBYFilghtCategory: [],
                        searchBYFilghtCategoryReturn: [],
                        searchByStopType: [],
                        searchByStopTypeReturn: [],
                        searchFlightByDepratureTime: "",
                        searchFlightByDepratureTimeReturn: "",
                        searchFlightByArrivalTime: "",
                        searchFlightByArrivalTimeReturn: "",
                        searchFlightByPriceRange: [],
                        searchFlightByPriceRangeReturn: [],
                        searchBySortingFlight: [],
                        searchBySortingFlightReturn: [],
                      },
                      () => {
                        // this.getMultiCitydepratureSideFlightForFilter(values);
                        // this.getMultiCityReturnSideFlightForFilter(values);
                        // this.getMultiCityReturnSideFlight(values);
                        // this.getMultiCitydepratureSideFlight(values);
                      }
                    );
                  }}
                >
                  {(formikProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                    } = formikProps;
                    return (
                      <Form autoComplete="off">
                        <Grid
                          container
                          spacing={2}
                          justify="space-evenly"
                          className={classes.mainPostSecondaryContent + " " + classes.mainPostSecondaryContentbgcolor}
                        >
                          {this.state.selectedTravellingType && (
                            <Grid item xs={12}>
                              <FormControl component="fieldset" className={classes.borderedBottommain}>
                                {/* <FormLabel
                                className={classes.radioGroupTitle}
                                component="legend"
                              >
                                Travelling Type
                              </FormLabel> */}
                                <Box className={classes.borderedBottom}>
                                  <Tabs
                                    value={this.state.selectedTravellingType}
                                    onChange={(evt, value) => {
                                      this.setState({
                                        selectedTravellingType: value,
                                        searchData: values,
                                        oneWayflights: [],
                                        twoWayFlights1: [],
                                        twoWayFlights2: [],
                                        selectedFlight1: null,
                                        selectedFlight2: null,
                                        searchBYFilghtCategory: [],
                                        searchBYFilghtCategoryReturn: [],
                                        searchByStopType: [],
                                        searchByStopTypeReturn: [],
                                        searchFlightByDepratureTime: "",
                                        searchFlightByDepratureTimeReturn: "",
                                        searchFlightByArrivalTime: "",
                                        searchFlightByArrivalTimeReturn: "",
                                        searchFlightByPriceRange: [],
                                        searchFlightByPriceRangeReturn: [],
                                        searchBySortingFlight: [],
                                        searchBySortingFlightReturn: [],
                                        FilghtCategoryList: [],
                                        FilghtCategoryListReturn: [],
                                        stopCategoryList: [],
                                        stopCategoryListReturn: [],
                                        sliderMinValue: 0,
                                        sliderMinValueReturn: 0,
                                        sliderMaxValue: 0,
                                        sliderMaxValueReturn: 0,
                                      });
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"

                                  >
                                    <Tab value={"oneway"} label="One Way" classes={{ root: classes.tabLabel }} />
                                    <Tab value={"twoway"} label="Two Way" classes={{ root: classes.tabLabel }} />
                                    <Tab value={"multicity"} label="Multicity" classes={{ root: classes.tabLabel }} />

                                  </Tabs>
                                </Box>
                                {/* <RadioGroup
                                row
                                aria-label="position"
                                name="selectedTravellingType"
                                onChange={(event) => {
                                  this.setState({
                                    selectedTravellingType:
                                      event.target.value,
                                    searchData: values,
                                    oneWayflights: [],
                                    twoWayFlights1: [],
                                    twoWayFlights2: [],
                                    selectedFlight1: null,
                                    selectedFlight2: null,
                                    searchBYFilghtCategory: [],
                                    searchByStopType: [],
                                    searchFlightByDepratureTime: "",
                                    searchFlightByArrivalTime: "",
                                    searchFlightByPriceRange: [],
                                    FilghtCategoryList: [],
                                    stopCategoryList: [],
                                    sliderMinValue: 0,
                                    sliderMaxValue: 0,
                                  });
                                }}
                                defaultValue={
                                  this.state.selectedTravellingType
                                }
                                value={this.state.selectedTravellingType}
                              >
                                <FormControlLabel
                                  classes={{
                                    label: classes.radioButtonLabel,
                                  }}
                                  value="oneway"
                                  control={
                                    <Radio
                                      color="primary"
                                      defaultChecked={
                                        this.state.selectedTravellingType ===
                                        "oneway"
                                      }
                                    />
                                  }
                                  label="One Way"
                                />
                                <FormControlLabel
                                  classes={{
                                    label: classes.radioButtonLabel,
                                  }}
                                  value="twoway"
                                  control={
                                    <Radio
                                      defaultChecked={
                                        this.state.selectedTravellingType ===
                                        "twoway"
                                      }
                                      color="primary"
                                    />
                                  }
                                  label="Two way"
                                />
                              </RadioGroup> */}
                              </FormControl>
                            </Grid>
                          )}
                          <TabPanel
                            value={this.state.selectedSearchType}
                            index={this.state.selectedSearchType}
                            style={{ width: '100%' }}
                          >
                            <Grid container justify="center" >
                              <Grid item xs={12} md={12}>
                                <Grid container spacing={2} alignItems="center" style={{ marginBottom: '18px' }} >
                                  <Grid xs={12} md={4} item>
                                    <Autocomplete
                                      freeSolo
                                      filterOptions={(options, state) => options}
                                      style={{ width: "100%" }}
                                      renderOption={(value) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                gutterBottom
                                                color="textPrimary"
                                              >
                                                {value.city}
                                              </Typography>
                                              <Typography color="textSecondary">
                                                {value.name}
                                              </Typography>
                                            </div>
                                            <Typography>{value.iata}</Typography>
                                          </div>
                                        );
                                      }}
                                      getOptionLabel={(value) =>
                                        `${value.city || ""}${value.city ? "," : ""
                                        }${value.country || ""}${value.iata ? "(" : ""
                                        }${value.iata || ""}${value.iata ? ")" : ""
                                        }`
                                      }
                                      options={
                                        this.state.sourcesearchSuggestionList
                                      }
                                      loading={false}
                                      value={this.state?.fromCity || {}}
                                      onInputChange={(evt) => {
                                        if (evt) {
                                          setFieldTouched("from", true);
                                          setFieldValue("from", "");
                                          this.getFromLocationSuggestion(
                                            evt?.target?.value
                                          );
                                          // this.SearchFlightFromCity(evt?.target?.value)
                                        }
                                        else {
                                          setFieldTouched("from", false);
                                        }
                                      }}
                                      onChange={(evt, value) => {
                                        if (value) {
                                          setFieldValue(
                                            "from",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            sourceSuggestionloading: false,
                                            fromCity: value,
                                          });
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder="Enter Airport or City Name"
                                          variant="outlined"
                                          label="From"
                                          InputLabelProps={{
                                            ...params.InputLabelProps,
                                            shrink: true,
                                            classes: {
                                              asterisk: classes?.astriklabel,
                                              outlined: classes?.outlinedLabel,
                                              shrink: classes?.shrink,
                                              root: classes?.lableroot,
                                            },
                                          }}
                                          // required
                                          InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                              input: classes?.input,
                                              root: classes?.inputfieledroot,
                                            },
                                            notched: false,
                                            endAdornment: (
                                              <React.Fragment>
                                                {this.state
                                                  .sourceSuggestionloading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {/* {
                                                <InputAdornment position="end">
                                                  <IconButton aria-label="search">
                                                    <SearchIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              } */}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.from && touched.from && (
                                      <Typography color="error" style={{ position: 'absolute' }}>
                                        {errors.from}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                    <Autocomplete
                                      value={this.state?.toCity || {}}
                                      filterOptions={(options, state) => options}
                                      freeSolo
                                      style={{ width: "100%" }}
                                      options={
                                        this.state.destinationsearchSuggestionList
                                      }
                                      loading={false}
                                      onInputChange={(evt) => {
                                        if (evt) {
                                          setFieldTouched("to", true);
                                          setFieldValue("to", "");
                                          this.getDestinationLocationSuggestion(
                                            evt?.target?.value
                                          );
                                          // this.SearchFlightToCity(evt?.target?.value)
                                        }
                                      }}
                                      onChange={(evt, value) => {
                                        if (value) {
                                          setFieldValue(
                                            "to",
                                            `${value?.city},(${value?.name})`
                                          );
                                          this.setState({
                                            destinationSuggestionloading: false,
                                            toCity: value,
                                          });
                                        }
                                      }}
                                      renderOption={(value) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <div style={{ flexGrow: 1 }}>
                                              <Typography
                                                gutterBottom
                                                color="textPrimary"
                                              >
                                                {value.city}
                                              </Typography>
                                              <Typography color="textSecondary">
                                                {value.name}
                                              </Typography>
                                            </div>
                                            <Typography>{value.iata}</Typography>
                                          </div>
                                        );
                                      }}
                                      getOptionLabel={(value) =>
                                        `${value.city || ""}${value.city ? "," : ""
                                        }${value.country || ""}${value.iata ? "(" : ""
                                        }${value.iata || ""}${value.iata ? ")" : ""
                                        }`
                                      }
                                      onBlur={() => {
                                        setFieldTouched("to", true);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder="Enter Airport or City Name"
                                          variant="outlined"
                                          label="To"
                                          InputLabelProps={{
                                            ...params.InputLabelProps,
                                            shrink: true,
                                            classes: {
                                              asterisk: classes?.astriklabel,
                                              outlined: classes?.outlinedLabel,
                                              shrink: classes?.shrink,
                                              root: classes?.lableroot,
                                            },
                                          }}
                                          // required
                                          InputProps={{
                                            ...params.InputProps,
                                            classes: {
                                              input: classes?.input,
                                              root: classes?.inputfieledroot,
                                            },
                                            notched: false,
                                            endAdornment: (
                                              <React.Fragment>
                                                {this.state
                                                  .destinationSuggestionloading ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {/* {
                                                <InputAdornment position="end">
                                                  <IconButton aria-label="search">
                                                    <SearchIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              } */}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.to && touched.to && (
                                      <Typography color="error" style={{ position: 'absolute' }}>
                                        {errors.to}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} md={4} onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="depratureDate"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: curDate,
                                        },
                                      }}
                                      placeholder="Enter Departure Date"
                                      onChange={handleChange}
                                      value={values.depratureDate}
                                      fullWidth
                                      label="Departure"
                                      className={classes.errorhandle}
                                    />
                                  </Grid>

                                  {/* add anotere city */}

                                  {/* {index === values?.newCity.length - 1 &&
                                     <Grid xs={10} item>
                                      <Typography className={classes.addNewcity} 
                                      gutterBottom 
                                      onClick={() => {
                                        if (
                                          values?.newCity
                                            .length < 3
                                        ) {
                                          params.push({
                                            from: "",
                                            to: "",
                                            depratureDate: "",
                                          });
                                        } else {
                                          this.props.showAlert(
                                            {
                                              title: "Error",
                                              message:
                                                "Maximum 3 city can be added",
                                            }
                                          );
                                        }
                                      }}
                                     >
                                        Add Another City
                                      </Typography>
                                     </Grid>
                                   } */}
                                  <Grid xs={10} item>
                                    <Typography className={classes.addNewCity}
                                      gutterBottom
                                      onClick={() => {
                                        arrayHelpers.newcity.push({
                                          from: "",
                                          to: "",
                                          depratureDate: "",
                                        })
                                      }}
                                      align="center"
                                      style={{ color: "blue" }}
                                    >Add Another City
                                    </Typography>
                                  </Grid>

                                  {/* add anotere city */}

                                  <Grid xs={10} md={4} item>
                                    <div className={classes.customSelect}>
                                      <Typography className={classes.travellerheading}>Travellers</Typography>
                                      <Button onClick={this.dropDownSelect} className={classes.InputFieldSelect}>Adult {this.state.counter} | Child {this.state.counter1} | Infant {this.state.counter2}</Button>
                                      <Box className={classes.dropdownIcon}><ArrowDropDownIcon /></Box>
                                      {this.state.SelectOption &&
                                        <ul className={classes.DropdownMenu}>
                                          {this.state.PasangerValus.map((data, index) => {
                                            return (
                                              <li className={classes.Dropdown}>
                                                <div className={classes.DropDownList} onClick={(event) => this.dropDowmalues(event, data.name)}>
                                                  <p className={classes.DropDownMenu1}>{data.name}</p>
                                                  <ButtonGroup size="small" aria-label="small outlined button group" className={classes.DropDownMenu2}>
                                                    <Button
                                                      onClick={() => {

                                                        if (data.name === 'Adult') {
                                                          setFieldValue("adults", this.state.counter - 1),
                                                            this.setState({
                                                              adult: this.state.counter - 1,
                                                              counter: this.state.counter - 1
                                                            })
                                                        }
                                                        else if (data.name === 'Child') {
                                                          setFieldValue("children", this.state.counter1 - 1),
                                                            this.setState({
                                                              childrens: this.state.counter1 - 1,
                                                              counter1: this.state.counter1 - 1
                                                            })
                                                        }
                                                        else if (data.name === 'Infant') {
                                                          setFieldValue("infants", this.state.counter2 - 1),
                                                            this.setState({
                                                              infants: this.state.counter2 - 1,
                                                              counter2: this.state.counter2 - 1
                                                            })
                                                        }

                                                      }}
                                                      disabled={data.name === 'Adult' && this.state.counter <= 1 ? true :
                                                        data.name === 'Child' && this.state.counter1 <= 0 ? true :
                                                          data.name === 'Infant' && this.state.counter2 <= 0 ? true : false} className={classes.plusbutton}><RemoveIcon style={{ fontSize: "1rem" }} /></Button>
                                                    <Button disabled>
                                                      {data.name === 'Adult' ? this.state.counter : data.name === 'Child' ?
                                                        this.state.counter1 : this.state.counter2}
                                                    </Button>

                                                    <Button onClick={(val) => {
                                                      const { counter, counter1, counter2 } = this.state;
                                                      let status = this.validateCount(counter, counter1, counter2);
                                                      if (status) {
                                                        if (data.name === 'Adult') {
                                                          setFieldValue("adults", this.state.counter + 1),
                                                            this.setState({
                                                              adult: this.state.counter + 1,
                                                              counter: this.state.counter + 1
                                                            })
                                                        }
                                                        else if (data.name === 'Child') {
                                                          setFieldValue("children", this.state.counter1 + 1),
                                                            this.setState({
                                                              childrens: this.state.counter1 + 1,
                                                              counter1: this.state.counter1 + 1
                                                            })
                                                        }
                                                        else if (data.name === 'Infant') {
                                                          setFieldValue("infants", this.state.counter2 + 1),
                                                            this.setState({
                                                              infants: this.state.counter2 + 1,
                                                              counter2: this.state.counter2 + 1
                                                            })
                                                        }
                                                        this.setState({ travellerCountStatus: false })
                                                      }
                                                      else {
                                                        this.setState({ travellerCountStatus: true })

                                                      }
                                                    }
                                                    }
                                                      disabled={data.name === 'Infant' && this.state.counter2 >= this.state.counter ? true : false} className={classes.plusbutton}><AddIcon style={{ fontSize: "1rem" }} /></Button>
                                                  </ButtonGroup>
                                                </div>
                                              </li>
                                            )
                                          })}
                                          {
                                            this.state.travellerCountStatus ?
                                              <li className={classes.Dropdown}>
                                                <Typography className={classes.countText}>you can book for a maximum of 9 passangers</Typography >
                                              </li>
                                              :
                                              null
                                          }
                                        </ul>}
                                    </div>
                                  </Grid>

                                  <Grid xs={12} md={4} item onClick={() => this.setState({ SelectOption: false })}>
                                    <Field
                                      component={InputFieled}
                                      name="class"
                                      select
                                      placeholder="Class"
                                      value={values.class}
                                      fullWidth
                                      label="Category"
                                    >
                                      {[
                                        {
                                          value: "Economy",
                                        },
                                        {
                                          value: "Premimum Economy",
                                        },
                                        {
                                          value: "Business",
                                        },
                                        {
                                          value: "First Class",
                                        },
                                      ].map((value) => {
                                        return (
                                          <MenuItem
                                            key={value.value}
                                            value={value.value}
                                          >
                                            {value.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* <Grid container spacing={2} alignItems="center"> */}
                              {/* <Grid xs={6} md={3} item>
                                <Field
                                  component={InputFieled}
                                  select
                                  name="adults"
                                  onChange={handleChange}
                                  value={values.adults}
                                  fullWidth
                                  label="Adults(12 yrs.+)"
                                >
                                  {[
                                    {
                                      value: "1",
                                    },
                                    {
                                      value: "2",
                                    },
                                    {
                                      value: "3",
                                    },
                                    {
                                      value: "4",
                                    },
                                    {
                                      value: "5",
                                    },
                                    {
                                      value: "6",
                                    },
                                    {
                                      value: "7",
                                    },
                                    {
                                      value: "8",
                                    },
                                    {
                                      value: "9",
                                    },
                                  ].map((value) => {
                                    return (
                                      <MenuItem
                                        key={value.value}
                                        value={value.value}
                                      >
                                        {value.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid> */}
                              {/* <Grid xs={6} md={3} item>
                                <Field
                                  select
                                  component={InputFieled}
                                  name="children"
                                  onChange={handleChange}
                                  value={values.children}
                                  fullWidth
                                  label="Children(2yr - 12 yr)"
                                >
                                  {[
                                    {
                                      value: "0",
                                    },
                                    {
                                      value: "1",
                                    },
                                    {
                                      value: "2",
                                    },
                                    {
                                      value: "3",
                                    },
                                    {
                                      value: "4",
                                    },
                                    {
                                      value: "5",
                                    },
                                    {
                                      value: "6",
                                    },
                                  ].map((value) => {
                                    return (
                                      <MenuItem
                                        key={value.value}
                                        value={value.value}
                                      >
                                        {value.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid> */}

                              {/* <Grid xs={6} md={3} item>
                                <Field
                                  component={InputFieled}
                                  name="infants"
                                  placeholder="No. of Children"
                                  onChange={handleChange}
                                  select
                                  value={values.infants}
                                  fullWidth
                                  label="Infant(Below 2yr)"
                                >
                                  {" "}
                                  {[
                                    {
                                      value: "0",
                                    },
                                    {
                                      value: "1",
                                    },
                                    {
                                      value: "2",
                                    },
                                    {
                                      value: "3",
                                    },
                                    {
                                      value: "4",
                                    },
                                    {
                                      value: "5",
                                    },
                                    {
                                      value: "6",
                                    },
                                  ].map((value) => {
                                    return (
                                      <MenuItem
                                        key={value.value}
                                        value={value.value}
                                      >
                                        {value.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid> */}

                              {/* </Grid> */}
                            </Grid>
                            <Grid container spacing={2} onClick={() => this.setState({ SelectOption: false })} onClick={() => this.setState({ SelectOption: false })}>
                              <Grid container className={classes.boederedTop} spacing={2}>
                                <Grid xs={12} md={6} item>
                                </Grid>
                                <Grid xs={12} md={3} item>
                                  {/* <Box py={3}> */}
                                  <Button
                                    fullWidth
                                    type="reset"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => this.ClearDataForm('multicity', setFieldValue)}
                                  >
                                    Clear
                                  </Button>
                                  {/* </Box> */}
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  xs={12}
                                  style={{
                                    alignSelf: "center",
                                    justifySelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                  >
                                    {(this.state?.twoWayFlights1?.length > 0 || this.state?.twoWayFlights2?.length > 0)
                                      ? "Modify Search"
                                      : "Search"}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>





              )};

              {/* multicity ended */}

            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid container justify="center" alignItems="center" style={{ backgroundColor: "#F6F9FF", minHeight: "300px", paddingTop: "60px" }}>
          {!!(Object.keys(this.state.searchData).length > 0) && (

            <Grid item md={10} xs={11}>
              <Grid container spacing={2} justify="space-around">
                <Hidden smUp>
                  <Grid item md={11} xs={11}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleDrawerToggle}
                      className={classes.filterResult}
                    >
                      Filter Result
                    </Button>
                  </Grid>
                </Hidden>

                {this.state.selectedTravellingType === "oneway" && (<Grid item xs={11} md={12} style={{ padding: "10px" }}>
                  <Typography className={classes.totalFlightsText}>
                    Showing {this.state?.oneWayflightsTotalCount || 0} flights
                    available
                  </Typography>
                  {(this.state?.FilghtCategoryList?.length > 0 || this.state?.stopCategoryList?.length > 0) && <Grid container className={classes.sortingbtn}>
                    {flightSortingButtons.length > 0 &&
                      flightSortingButtons.map((sortingButton) => (
                        <div key={sortingButton.key} className={this.state?.searchBySortingFlight?.find((value) => value.includes(sortingButton?.key)) ? classes.selectedSortingBtn : classes.sortIngButton}
                          onClick={() => { this.handleSortingFlightChange(sortingButton?.key) }}>
                          <Typography className={classes.sortIconTitle}>
                            {sortingButton.value}
                          </Typography>
                        </div>
                      ))}
                  </Grid>}
                </Grid>)}
                <Hidden xsDown>
                  {!!(Object.keys(this.state.searchData).length > 0) && (this.state?.FilghtCategoryList?.length > 0 || this.state?.stopCategoryList?.length > 0) && (
                    <Grid item md={3} xs={false} style={{ padding: "10px" }}>
                      {this.filterUi(classes)}
                      {this.state.selectedTravellingType === "twoway" && (this.state.FilghtCategoryListReturn.length > 0 || this.state.stopCategoryListReturn.length > 0) && (
                        <Grid style={{ marginTop: "20px" }}>
                          {this.filterUiReturn(classes)}
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Hidden>
                <Grid item md={9} xs={11} style={{ padding: "10px" }}>
                  <Grid container justify="space-between" spacing={1}>
                    {this.state.selectedTravellingType === "twoway" ? (
                      <>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                              {(this.state?.fromCity?.city && this.state?.toCity?.city) && (<Typography
                                className={classes.twowayHeading}
                                gutterBottom
                              >
                                {this.state?.fromCity?.city} to {this.state?.toCity?.city}
                              </Typography>)}
                              {/* {this.state?.searchData?.depratureDate && (<Typography className={classes.twowaySubHeading}>
                                {moment(
                                  this.state?.searchData?.depratureDate
                                ).format("dddd , DD MMM YYYY")}
                              </Typography>)} */}
                              <Typography className={classes.twowaySubHeading}>
                                Showing{" "}
                                {this.state.twoWayFlights1TotalCount || 0}{" "}
                                flights available
                              </Typography>
                              {(this.state?.FilghtCategoryList?.length > 0 || this.state?.stopCategoryList?.length > 0) && <Grid container className={classes.sortingbtn}>
                                {flightSortingButtons.length > 0 &&
                                  flightSortingButtons.map((sortingButton) => (
                                    <div key={sortingButton.key} className={this.state?.searchBySortingFlight?.find((value) => value.includes(sortingButton?.key)) ? classes.selectedSortingBtn : classes.sortIngButton}
                                      onClick={() => { this.handleSortingFlightChange(sortingButton?.key) }}>
                                      <Typography className={classes.sortIconTitle}>
                                        {sortingButton.value}
                                      </Typography>
                                    </div>
                                  ))}
                              </Grid>}
                            </Grid>
                            {this.state?.twoWayFlights1?.length > 0 ? (
                              this.state.twoWayFlights1.map((flight, index) => {
                                const selectedFlight1 = this.state.selectedFlight1;
                                return <>
                                  {(this.state?.selectedFlight1Show ? this.state?.selectedFlight1Index === index : true) && (<Grid key={index} item xs={12}>
                                    <div
                                      className={
                                        selectedFlight1 &&
                                          selectedFlight1?.flight_Name +
                                          selectedFlight1?.flight_Id +
                                          selectedFlight1.FareBasisCode ===
                                          flight?.flight_Name +
                                          flight?.flight_Id +
                                          flight.FareBasisCode
                                          ? classes.flightItemContainerSelected
                                          : classes.flightItemContainer
                                      }
                                    >
                                      {/* <Grid
                                        container
                                        justify="space-between"
                                        spacing={2}
                                        alignContent="center"
                                        alignItems="center"
                                      >
                                        <Grid item md={9} xs={12}>
                                          <ListItem>
                                            <ListItemAvatar>
                                              <img
                                                className={classes.flightImage}
                                                alt="flight logo"
                                                src={flight?.flight_Image}
                                              />
                                            </ListItemAvatar>
                                            <ListItemText
                                              classes={{
                                                primary:
                                                  classes.flightNameinList,
                                                secondary:
                                                  classes.flightIdInList,
                                              }}
                                              primary={
                                                flight?.flight_Name || "NA"
                                              }
                                              secondary={`${flight?.flight_Carrier ||
                                                "-"} | ${flight?.flight_Id ||
                                                "-"}`}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <Typography
                                            align="center"
                                            clsssName={classes.twoWayPrice}
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {flight?.TotalPrice || "NA"}
                                          </Typography>
                                          <div style={{ textAlign: "center" }}>
                                            <Button
                                              color="primary"
                                              fullWidth
                                              style={{ height: "24px" }}
                                              variant="contained"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({
                                                  selectedFlight1: flight,
                                                });
                                              }}
                                            >
                                              {selectedFlight1 &&
                                                selectedFlight1?.flight_Name +
                                                selectedFlight1?.flight_Id +
                                                selectedFlight1?.FareBasisCode ===
                                                flight?.flight_Name +
                                                flight?.flight_Id +
                                                flight?.FareBasisCode
                                                ? "Selected"
                                                : "Select"}
                                            </Button>
                                          </div>
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          item
                                          container
                                          alignContent="center"
                                          alignItems="center"
                                        >
                                          <Grid item xs={3}>
                                            <Typography
                                              className={classes.time}
                                              color="textSecondary"
                                              align="left"
                                            >
                                              {" "}
                                              {moment(
                                                new Date(flight?.Depature_Time)
                                              ).format("HH:mm") || "NA"}
                                            </Typography>
                                            <Typography
                                              className={classes.cityName}
                                              color="textSecondary"
                                              align="left"
                                            >
                                              {" "}
                                              {flight?.Depature_Iata || "NA"}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6} item>
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                flight?.stops.length > 0 &&
                                                  this.openPopover(
                                                    e,
                                                    flight?.stops
                                                  );
                                              }}
                                              className={classes.linewraper}
                                            >
                                              <Typography
                                                className={classes.lineHeading}
                                              >
                                                {flight?.Total_Time || "NA"}
                                              </Typography>
                                              <ul className={classes.connector}>
                                                <li
                                                  className={
                                                    classes.linkconnector
                                                  }
                                                />
                                                <li
                                                  className={
                                                    classes.linkconnector
                                                  }
                                                />
                                              </ul>
                                              <Typography
                                                color="textSecondary"
                                                className={
                                                  classes.lineSubHeading
                                                }
                                              >
                                                {flight?.Mode_Of_Flight || "NA"}
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Typography
                                              className={classes.time}
                                              color="textSecondary"
                                              align="right"
                                            >
                                              {" "}
                                              {moment(
                                                new Date(flight?.Arrival_Time)
                                              ).format("HH:mm") || "NA"}
                                            </Typography>
                                            <Typography
                                              className={classes.cityName}
                                              color="textSecondary"
                                              align="right"
                                            >
                                              {" "}
                                              {flight?.Arrival_Iata || "NA"}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid> */}

                                      <Grid
                                        container
                                        justify="space-between"
                                        alignContent="center"
                                        alignItems="center"
                                        style={{ margin: "0px" }}
                                      >
                                        <Grid item md={3} xs={12}>
                                          <ListItem>
                                            <ListItemAvatar>
                                              <img
                                                className={classes.flightImage}
                                                alt="flight logo"
                                                src={flight?.flight_Image}
                                              />
                                            </ListItemAvatar>

                                            <ListItemText
                                              classes={{
                                                primary: classes.flightNameinList,
                                                secondary: classes.flightIdInList,
                                              }}
                                              primary={flight?.flight_Name || "NA"}
                                              secondary={`${flight?.flight_Carrier ||
                                                "-"}  ${flight?.flight_Id || "-"}`}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item md={0.5}>
                                          <Divider
                                            variant="middle"
                                            flexItem
                                            orientation="vertical"
                                            className={classes.firstDivider}
                                            style={{ height: "60px", alignSelf: "center" }}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={5} item className={classes.flightTimeDetails}>
                                          <Grid
                                            container
                                            justify="space-between"
                                            alignContent="center"
                                            alignItems="center"
                                            spacing={3}
                                          >
                                            <Grid item xs={4}>
                                              <Typography
                                                className={classes.time}
                                                color="textSecondary"
                                                align="center"
                                              >
                                                {(flight?.Depature_Time &&
                                                  moment(
                                                    new Date(flight?.Depature_Time)
                                                  ).format("HH:mm")) ||
                                                  "NA"}
                                              </Typography>
                                              <Typography
                                                className={classes.cityName}
                                                align="center"
                                              >
                                                {flight?.Depature_City || "NA"}
                                              </Typography>
                                            </Grid>
                                            <Grid xs={4} item>
                                              <div
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  flight?.stops.length > 0 &&
                                                    this.openPopover(
                                                      e,
                                                      flight?.stops
                                                    );
                                                }}
                                                className={classes.linewraper}
                                              >
                                                <Typography
                                                  className={classes.lineHeading}
                                                >
                                                  {flight?.Total_Time || "NA"}
                                                </Typography>
                                                <ul className={classes.connector}>
                                                  <li
                                                    className={classes.linkconnector}
                                                  />
                                                  <li
                                                    className={classes.linkconnector}
                                                  />
                                                </ul>
                                                <Typography
                                                  className={classes.lineSubHeading}
                                                >
                                                  {flight?.Mode_Of_Flight || "NA"}{" "}
                                                  {flight?.stops.length > 0 && "Via"}{" "}
                                                  {flight?.stops
                                                    .map((stop) => {
                                                      return stop.stopName;
                                                    })
                                                    .join(",")}
                                                </Typography>
                                              </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                              <Typography
                                                className={classes.time}
                                                align="center"
                                              >
                                                {(flight?.Arrival_Time &&
                                                  moment(
                                                    new Date(flight?.Arrival_Time)
                                                  ).format("HH:mm")) ||
                                                  "NA"}
                                              </Typography>
                                              <Typography
                                                className={classes.cityName}
                                                align="center"
                                              >
                                                {flight?.Arrival_City}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item md={0.5}>
                                          <Divider
                                            variant="middle"
                                            flexItem
                                            orientation="vertical"
                                            className={classes.secondDivider}
                                            style={{ height: "120px", alignSelf: "center" }}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={2} item style={{ padding: "0px" }}>
                                          <Typography align="center" className={classes.flightTotalPrice} >
                                            {flight?.TotalPrice || "NA"}
                                          </Typography>
                                          <div style={{ textAlign: "center", paddingBottom: "5px" }}>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className={classes.flightbookbtn}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({
                                                  selectedFlight1: this.state?.selectedFlight1Show ? null : flight,
                                                  selectedFlight1Show: !(this.state?.selectedFlight1Show),
                                                  selectedFlight1Index: index,
                                                });
                                              }}
                                            >
                                              {selectedFlight1 &&
                                                selectedFlight1?.flight_Name +
                                                selectedFlight1?.flight_Id +
                                                selectedFlight1?.FareBasisCode ===
                                                flight?.flight_Name +
                                                flight?.flight_Id +
                                                flight?.FareBasisCode
                                                ? "Remove"
                                                : "Book"}
                                            </Button>
                                          </div>
                                          <Typography
                                            onClick={() => {
                                              this.setState({
                                                selectedFlight1ViewDetails: index,
                                                searchData: { ...this.state?.searchData, },
                                                entityDetail: { ...flight },
                                                flight1ViewDetailsOpen: this.state?.selectedFlight1ViewDetails === index ? !this.state?.flight1ViewDetailsOpen : true,
                                                flight2ViewDetailsOpen: false,
                                                oneWayFlightList: [],
                                              }, () => { !this.state?.flight1ViewDetailsOpen ? "" : this.getFareSummary(this.state.searchData, this.state.entityDetail?.mergeAirSagmentBookingFlightInfo); })
                                            }}
                                            color="primary"
                                            style={{ cursor: "pointer", fontSize: "15px", fontWeight: "600", color: "#3385D7" }}
                                            gutterBottom
                                            align="center"
                                          >
                                            View Details
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Collapse in={this.state?.flight1ViewDetailsOpen}>
                                        {this.state?.selectedFlight1ViewDetails === index &&
                                          <Grid>
                                            <Divider variant="fullWidth" className={classes.viewFlightDivider} />
                                            <Grid item xs={12} className={classes.flightBoxDetails}>
                                              {this.state.oneWayFlightList?.length > 0 && this.state.oneWayFlightList?.map((flight, flightIndex) => {
                                                return <>
                                                  <Grid key={flight?.FlightNumber} container>
                                                    <Grid item md={5} sm={6} xs={12} className={classes.viewFlightDetail1}>
                                                      <Grid item md={12} xs={12} className={classes.viewFlightDetail}>
                                                        <ListItem style={{ padding: 0 }}>
                                                          <ListItemAvatar>
                                                            <img
                                                              className={classes.viewFlightImage}
                                                              src={
                                                                flight?.Flight_Image
                                                              }
                                                            />
                                                          </ListItemAvatar>
                                                          <ListItemText
                                                            classes={{
                                                              primary: classes.flightName,
                                                              secondary: classes.flightCode,
                                                            }}
                                                            primary={
                                                              flight?.Flight_Name || "NA"
                                                            }
                                                            secondary={`${flight?.Flight_carrier || "-"
                                                              }${flight?.Flight_Id || "-"}`}
                                                          />
                                                          {/* <ListItemText classes={{ primary: classes.ViewFlightTotalPrice }} primary={`${this.state?.currency} ${this.state?.totalPrice}` || 0} /> */}
                                                        </ListItem>
                                                      </Grid>
                                                      <Grid item md={12} xs={12}>
                                                        <Grid
                                                          container
                                                          justify="space-between"
                                                        >
                                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                                          {this.state.AirPriceInfo.length > 0 && this.state.AirPriceInfo.map((airPriceInfo, index) => {
                                                            return <>
                                                              <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                                              <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                                              <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                                            </>
                                                          })
                                                          }
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid item md={7} sm={6} xs={12} className={classes.viewFlightDetail2}>
                                                      <Grid
                                                        container
                                                        justify="space-between"
                                                      >
                                                        <Grid item xs={4} sm={4} md={4} className={classes.departureSection}>
                                                          <Typography
                                                            className={[
                                                              classes.flightDate,
                                                            ]}
                                                          >
                                                            {(flight?.Depature_DateTime &&
                                                              moment(
                                                                flight?.Depature_DateTime
                                                              ).format("ddd, DD MMM")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightPlaceMode}
                                                          >
                                                            {" "}
                                                            Departure{" "}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightTime}
                                                            color="textSecondary"
                                                          >
                                                            {(flight?.Depature_DateTime &&
                                                              moment(
                                                                (
                                                                  flight?.Depature_DateTime
                                                                )
                                                              ).format("hh:mm A")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={[
                                                              classes.flightAddress,
                                                            ]}
                                                            color="textPrimary"
                                                          >
                                                            {" "}
                                                            {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                                              "NA"}{", "} {flight?.Depature_City ||
                                                                "NA"}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} md={3}>
                                                          <div className={classes.linewraper}>
                                                            <Typography
                                                              color="primary"
                                                              className={classes.flightDiffTime}
                                                            >
                                                              {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                                            </Typography>
                                                            <ul className={classes.container}>
                                                              <li className={classes.linkconnector} />
                                                              <li className={classes.linkconnector} />
                                                            </ul>
                                                            <Typography
                                                              color="primary"
                                                              className={classes.flightDiffStop}
                                                            >
                                                              {flight?.Mode_Of_Flight}
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} className={classes.arrivalSection}>
                                                          <Typography
                                                            className={[
                                                              classes.flightDate,
                                                            ]}
                                                          >
                                                            {" "}
                                                            {(flight?.Arrival_DateTime &&
                                                              moment(
                                                                flight?.Arrival_DateTime
                                                              ).format("ddd, DD MMM")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightPlaceMode}
                                                          >
                                                            {" "}
                                                            Arrival{" "}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightTime}
                                                            color="textSecondary"
                                                          >
                                                            {(flight?.Arrival_DateTime &&
                                                              moment(
                                                                (
                                                                  flight?.Arrival_DateTime
                                                                )
                                                              ).format("hh:mm A")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={[
                                                              classes.flightAddress,
                                                            ]}
                                                            color="textPrimary"
                                                          >
                                                            {" "}
                                                            {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                                              "NA"}{", "} {flight?.Arrival_City ||
                                                                "NA"}
                                                          </Typography>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  {flightIndex < (this.state.oneWayFlightList?.length - 1) &&
                                                    <Grid item xs={12} className={classes.layoverDivider}>
                                                      <Divider className={classes.dividerStyle} />
                                                      <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[flightIndex].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[flightIndex].stopName})</Typography>
                                                      <Divider className={classes.dividerStyle} />
                                                    </Grid>
                                                  }
                                                </>
                                              })}
                                            </Grid>
                                          </Grid>
                                        }
                                      </Collapse>
                                    </div>
                                  </Grid>)}
                                </>
                              })
                            ) : (
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  className={classes.noFlightFound}
                                >
                                  <Grid item xs={11}>
                                    <Typography color="textPrimary" variant="h1">
                                      No Flights Found
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {this.state?.twoWayFlights1?.length > 0 && (
                              <Grid item xs={12}>
                                <TablePagination
                                  component="div"
                                  count={this.state.twoWayFlights1TotalCount}
                                  page={this.state.twoWayPage}
                                  onChangePage={this.handleTwoWayPageChange}
                                  rowsPerPage={this.state.recordsPerPageTwoWay}
                                  onChangeRowsPerPage={
                                    this.handleChangeRowsPerPageTwoWay
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        {/* <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        /> */}
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                              {(this.state?.toCity?.city && this.state?.fromCity?.city) && (<Typography
                                className={classes.twowayHeading}
                                gutterBottom
                              >
                                {this.state?.toCity?.city} to {this.state?.fromCity?.city}
                              </Typography>)}
                              {/* {this.state?.searchData?.returnDate && (<Typography className={classes.twowaySubHeading}>
                                {moment(
                                  this.state?.searchData?.returnDate
                                ).format("dddd , DD MMM YYYY")}
                              </Typography>)} */}
                              <Typography className={classes.twowaySubHeading}>
                                Showing{" "}
                                {this.state.twoWayFlights2TotalCount || 0}{" "}
                                flights available
                              </Typography>
                              {(this.state?.FilghtCategoryListReturn?.length > 0 || this.state?.stopCategoryListReturn?.length > 0) && <Grid container className={classes.sortingbtn}>
                                {flightSortingButtons.length > 0 &&
                                  flightSortingButtons.map((sortingButton) => (
                                    <div key={sortingButton.key} className={this.state?.searchBySortingFlightReturn?.find((value) => value.includes(sortingButton?.key)) ? classes.selectedSortingBtn : classes.sortIngButton}
                                      onClick={() => { this.handleSortingFlightChangeReturn(sortingButton?.key) }}>
                                      <Typography className={classes.sortIconTitle}>
                                        {sortingButton.value}
                                      </Typography>
                                    </div>
                                  ))}
                              </Grid>}
                            </Grid>
                            {this.state?.twoWayFlights2?.length > 0 ? (
                              this.state.twoWayFlights2.map((flight, index) => {
                                const selectedFlight2 = this.state.selectedFlight2;
                                return <>
                                  {(this.state?.selectedFlight2Show ? this.state?.selectedFlight2Index === index : true) && (<Grid key={index} item xs={12}>
                                    <div
                                      className={
                                        selectedFlight2 &&
                                          selectedFlight2?.flight_Name +
                                          selectedFlight2?.flight_Id +
                                          selectedFlight2?.FareBasisCode ===
                                          flight?.flight_Name +
                                          flight?.flight_Id +
                                          flight?.FareBasisCode
                                          ? classes.flightItemContainerSelected
                                          : classes.flightItemContainer
                                      }
                                    >
                                      {/* <Grid
                                        container
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Grid item md={9} xs={12}>
                                          <ListItem>
                                            <ListItemAvatar>
                                              <img
                                                className={classes.flightImage}
                                                alt="flight logo"
                                                src={flight?.flight_Image}
                                              />
                                            </ListItemAvatar>
                                            <ListItemText
                                              classes={{
                                                primary:
                                                  classes.flightNameinList,
                                                secondary:
                                                  classes.flightIdInList,
                                              }}
                                              primary={
                                                flight?.flight_Name || "NA"
                                              }
                                              secondary={`${flight?.flight_Carrier ||
                                                "-"} | ${flight?.flight_Id ||
                                                "-"}`}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <Typography
                                            align="center"
                                            clsssName={classes.twoWayPrice}
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {flight?.TotalPrice || "NA"}
                                          </Typography>
                                          <div style={{ textAlign: "center" }}>
                                            <Button
                                              color="primary"
                                              fullWidth
                                              style={{ height: "24px" }}
                                              variant="contained"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({
                                                  selectedFlight2: flight,
                                                });
                                              }}
                                            >
                                              {selectedFlight2 &&
                                                selectedFlight2?.flight_Name +
                                                selectedFlight2?.flight_Id +
                                                selectedFlight2?.FareBasisCode ===
                                                flight?.flight_Name +
                                                flight?.flight_Id +
                                                flight?.FareBasisCode
                                                ? "Selected"
                                                : "Select"}
                                            </Button>
                                          </div>
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          item
                                          container
                                          alignContent="center"
                                          alignItems="center"
                                        >
                                          <Grid item xs={3}>
                                            <Typography
                                              className={classes.time}
                                              color="textSecondary"
                                              align="left"
                                            >
                                              {" "}
                                              {moment(
                                                new Date(flight?.Depature_Time)
                                              ).format("HH:mm") || "NA"}
                                            </Typography>
                                            <Typography
                                              className={classes.cityName}
                                              color="textSecondary"
                                              align="left"
                                            >
                                              {" "}
                                              {flight?.Depature_Iata || "NA"}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6} item>
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                flight?.stops.length > 0 &&
                                                  this.openPopover(
                                                    e,
                                                    flight?.stops
                                                  );
                                              }}
                                              className={classes.linewraper}
                                            >
                                              <Typography
                                                className={classes.lineHeading}
                                              >
                                                {flight?.Total_Time || "NA"}
                                              </Typography>
                                              <ul className={classes.connector}>
                                                <li
                                                  className={
                                                    classes.linkconnector
                                                  }
                                                />
                                                <li
                                                  className={
                                                    classes.linkconnector
                                                  }
                                                />
                                              </ul>
                                              <Typography
                                                color="textSecondary"
                                                className={
                                                  classes.lineSubHeading
                                                }
                                              >
                                                {flight?.Mode_Of_Flight || "NA"}
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Typography
                                              className={classes.time}
                                              color="textSecondary"
                                              align="right"
                                            >
                                              {" "}
                                              {moment(
                                                new Date(flight?.Arrival_Time)
                                              ).format("HH:mm") || "NA"}
                                            </Typography>
                                            <Typography
                                              className={classes.cityName}
                                              color="textSecondary"
                                              align="right"
                                            >
                                              {" "}
                                              {flight?.Arrival_Iata || "NA"}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid> */}

                                      <Grid
                                        container
                                        justify="space-between"
                                        alignContent="center"
                                        alignItems="center"
                                        style={{ margin: "0px" }}
                                      >
                                        <Grid item md={3} xs={12}>
                                          <ListItem>
                                            <ListItemAvatar>
                                              <img
                                                className={classes.flightImage}
                                                alt="flight logo"
                                                src={flight?.flight_Image}
                                              />
                                            </ListItemAvatar>

                                            <ListItemText
                                              classes={{
                                                primary: classes.flightNameinList,
                                                secondary: classes.flightIdInList,
                                              }}
                                              primary={flight?.flight_Name || "NA"}
                                              secondary={`${flight?.flight_Carrier ||
                                                "-"}  ${flight?.flight_Id || "-"}`}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item md={0.5}>
                                          <Divider
                                            variant="middle"
                                            flexItem
                                            orientation="vertical"
                                            className={classes.firstDivider}
                                            style={{ height: "60px", alignSelf: "center" }}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={5} item className={classes.flightTimeDetails}>
                                          <Grid
                                            container
                                            justify="space-between"
                                            alignContent="center"
                                            alignItems="center"
                                            spacing={3}
                                          >
                                            <Grid item xs={4}>
                                              <Typography
                                                className={classes.time}
                                                color="textSecondary"
                                                align="center"
                                              >
                                                {(flight?.Depature_Time &&
                                                  moment(
                                                    new Date(flight?.Depature_Time)
                                                  ).format("HH:mm")) ||
                                                  "NA"}
                                              </Typography>
                                              <Typography
                                                className={classes.cityName}
                                                align="center"
                                              >
                                                {flight?.Depature_City || "NA"}
                                              </Typography>
                                            </Grid>
                                            <Grid xs={4} item>
                                              <div
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  flight?.stops.length > 0 &&
                                                    this.openPopover(
                                                      e,
                                                      flight?.stops
                                                    );
                                                }}
                                                className={classes.linewraper}
                                              >
                                                <Typography
                                                  className={classes.lineHeading}
                                                >
                                                  {flight?.Total_Time || "NA"}
                                                </Typography>
                                                <ul className={classes.connector}>
                                                  <li
                                                    className={classes.linkconnector}
                                                  />
                                                  <li
                                                    className={classes.linkconnector}
                                                  />
                                                </ul>
                                                <Typography
                                                  className={classes.lineSubHeading}
                                                >
                                                  {flight?.Mode_Of_Flight || "NA"}{" "}
                                                  {flight?.stops.length > 0 && "Via"}{" "}
                                                  {flight?.stops
                                                    .map((stop) => {
                                                      return stop.stopName;
                                                    })
                                                    .join(",")}
                                                </Typography>
                                              </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                              <Typography
                                                className={classes.time}
                                                align="center"
                                              >
                                                {(flight?.Arrival_Time &&
                                                  moment(
                                                    new Date(flight?.Arrival_Time)
                                                  ).format("HH:mm")) ||
                                                  "NA"}
                                              </Typography>
                                              <Typography
                                                className={classes.cityName}
                                                align="center"
                                              >
                                                {flight?.Arrival_City}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item md={0.5}>
                                          <Divider
                                            variant="middle"
                                            flexItem
                                            orientation="vertical"
                                            className={classes.secondDivider}
                                            style={{ height: "120px", alignSelf: "center" }}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={2} item style={{ padding: "0px" }}>
                                          <Typography align="center" className={classes.flightTotalPrice} >
                                            {flight?.TotalPrice || "NA"}
                                          </Typography>
                                          <div style={{ textAlign: "center", paddingBottom: "5px" }}>
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              className={classes.flightbookbtn}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({
                                                  selectedFlight2: this.state?.selectedFlight2Show ? null : flight,
                                                  selectedFlight2Show: !(this.state?.selectedFlight2Show),
                                                  selectedFlight2Index: index,
                                                });
                                              }}
                                            >
                                              {selectedFlight2 &&
                                                selectedFlight2?.flight_Name +
                                                selectedFlight2?.flight_Id +
                                                selectedFlight2?.FareBasisCode ===
                                                flight?.flight_Name +
                                                flight?.flight_Id +
                                                flight?.FareBasisCode
                                                ? "Remove"
                                                : "Book"}
                                            </Button>
                                          </div>
                                          <Typography
                                            onClick={() => {
                                              this.setState({
                                                selectedFlight2ViewDetails: index,
                                                searchData: { ...this.state?.searchData, },
                                                entityDetail: { ...flight },
                                                flight2ViewDetailsOpen: this.state?.selectedFlight2ViewDetails === index ? !this.state?.flight2ViewDetailsOpen : true,
                                                flight1ViewDetailsOpen: false,
                                                oneWayFlightList: [],
                                              }, () => { !this.state?.flight2ViewDetailsOpen ? "" : this.getFareSummary(this.state.searchData, this.state.entityDetail?.mergeAirSagmentBookingFlightInfo); })
                                            }}
                                            color="primary"
                                            style={{ cursor: "pointer", fontSize: "15px", fontWeight: "600", color: "#3385D7" }}
                                            gutterBottom
                                            align="center"
                                          >
                                            View Details
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Collapse in={this.state?.flight2ViewDetailsOpen}>
                                        {this.state?.selectedFlight2ViewDetails === index &&
                                          <Grid>
                                            <Divider variant="fullWidth" className={classes.viewFlightDivider} />
                                            <Grid item xs={12} className={classes.flightBoxDetails}>
                                              {this.state.oneWayFlightList?.length > 0 && this.state.oneWayFlightList?.map((flight, flightIndex) => {
                                                return <>
                                                  <Grid key={flight?.FlightNumber} container>
                                                    <Grid item md={5} sm={6} xs={12} className={classes.viewFlightDetail1}>
                                                      <Grid item md={12} xs={12} className={classes.viewFlightDetail}>
                                                        <ListItem style={{ padding: 0 }}>
                                                          <ListItemAvatar>
                                                            <img
                                                              className={classes.viewFlightImage}
                                                              src={
                                                                flight?.Flight_Image
                                                              }
                                                            />
                                                          </ListItemAvatar>
                                                          <ListItemText
                                                            classes={{
                                                              primary: classes.flightName,
                                                              secondary: classes.flightCode,
                                                            }}
                                                            primary={
                                                              flight?.Flight_Name || "NA"
                                                            }
                                                            secondary={`${flight?.Flight_carrier || "-"
                                                              }${flight?.Flight_Id || "-"}`}
                                                          />
                                                          {/* <ListItemText classes={{ primary: classes.ViewFlightTotalPrice }} primary={`${this.state?.currency} ${this.state?.totalPrice}` || 0} /> */}
                                                        </ListItem>
                                                      </Grid>
                                                      <Grid item md={12} xs={12}>
                                                        <Grid
                                                          container
                                                          justify="space-between"
                                                        >
                                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                                          <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                                          {this.state.AirPriceInfo.length > 0 && this.state.AirPriceInfo.map((airPriceInfo, index) => {
                                                            return <>
                                                              <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                                              <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                                              <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                                            </>
                                                          })
                                                          }
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid item md={7} sm={6} xs={12} className={classes.viewFlightDetail2}>
                                                      <Grid
                                                        container
                                                        justify="space-between"
                                                      >
                                                        <Grid item xs={4} sm={4} md={4} className={classes.departureSection}>
                                                          <Typography
                                                            className={[
                                                              classes.flightDate,
                                                            ]}
                                                          >
                                                            {(flight?.Depature_DateTime &&
                                                              moment(
                                                                flight?.Depature_DateTime
                                                              ).format("ddd, DD MMM")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightPlaceMode}
                                                          >
                                                            {" "}
                                                            Departure{" "}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightTime}
                                                            color="textSecondary"
                                                          >
                                                            {(flight?.Depature_DateTime &&
                                                              moment(
                                                                (
                                                                  flight?.Depature_DateTime
                                                                )
                                                              ).format("hh:mm A")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={[
                                                              classes.flightAddress,
                                                            ]}
                                                            color="textPrimary"
                                                          >
                                                            {" "}
                                                            {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                                              "NA"}{", "} {flight?.Depature_City ||
                                                                "NA"}
                                                          </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} md={3}>
                                                          <div className={classes.linewraper}>
                                                            <Typography
                                                              color="primary"
                                                              className={classes.flightDiffTime}
                                                            >
                                                              {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                                            </Typography>
                                                            <ul className={classes.container}>
                                                              <li className={classes.linkconnector} />
                                                              <li className={classes.linkconnector} />
                                                            </ul>
                                                            <Typography
                                                              color="primary"
                                                              className={classes.flightDiffStop}
                                                            >
                                                              {flight?.Mode_Of_Flight}
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} className={classes.arrivalSection}>
                                                          <Typography
                                                            className={[
                                                              classes.flightDate,
                                                            ]}
                                                          >
                                                            {" "}
                                                            {(flight?.Arrival_DateTime &&
                                                              moment(
                                                                flight?.Arrival_DateTime
                                                              ).format("ddd, DD MMM")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightPlaceMode}
                                                          >
                                                            {" "}
                                                            Arrival{" "}
                                                          </Typography>
                                                          <Typography
                                                            className={classes.flightTime}
                                                            color="textSecondary"
                                                          >
                                                            {(flight?.Arrival_DateTime &&
                                                              moment(
                                                                (
                                                                  flight?.Arrival_DateTime
                                                                )
                                                              ).format("hh:mm A")) ||
                                                              "NA"}
                                                          </Typography>
                                                          <Typography
                                                            className={[
                                                              classes.flightAddress,
                                                            ]}
                                                            color="textPrimary"
                                                          >
                                                            {" "}
                                                            {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                                              "NA"}{", "} {flight?.Arrival_City ||
                                                                "NA"}
                                                          </Typography>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  {flightIndex < (this.state.oneWayFlightList?.length - 1) &&
                                                    <Grid item xs={12} className={classes.layoverDivider}>
                                                      <Divider className={classes.dividerStyle} />
                                                      <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[flightIndex].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[flightIndex].stopName})</Typography>
                                                      <Divider className={classes.dividerStyle} />
                                                    </Grid>
                                                  }
                                                </>
                                              })}
                                            </Grid>
                                          </Grid>
                                        }
                                      </Collapse>

                                    </div>
                                  </Grid>)}
                                </>
                              })
                            ) : (
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  className={classes.noFlightFound}
                                >
                                  <Grid item xs={11}>
                                    <Typography color="textPrimary" variant="h1">
                                      No Flights Found
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {this.state?.twoWayFlights2?.length > 0 && (
                              <Grid item xs={12}>
                                <TablePagination
                                  component="div"
                                  count={this.state.twoWayFlights2TotalCount}
                                  page={this.state.twoWayReturnPage}
                                  onChangePage={
                                    this.handleTwoWayReturnPageChange
                                  }
                                  rowsPerPage={
                                    this.state.recordsPerPageTwoWayReturn
                                  }
                                  onChangeRowsPerPage={
                                    this.handleChangeRowsPerPageTwoWayReturn
                                  }
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        {this.state?.oneWayflights?.length > 0 ? (
                          this.state.oneWayflights.map((flight, index) => (
                            <Grid item key={index} xs={12}>
                              <div className={classes.flightItemContainer}>
                                <Grid
                                  container
                                  justify="space-between"
                                  alignContent="center"
                                  alignItems="center"
                                  style={{ margin: "0px" }}
                                >
                                  <Grid item md={3} xs={12}>
                                    <ListItem>
                                      <ListItemAvatar>
                                        <img
                                          className={classes.flightImage}
                                          alt="flight logo"
                                          src={flight?.flight_Image}
                                        />
                                      </ListItemAvatar>

                                      <ListItemText
                                        classes={{
                                          primary: classes.flightNameinList,
                                          secondary: classes.flightIdInList,
                                        }}
                                        primary={flight?.flight_Name || "NA"}
                                        secondary={`${flight?.flight_Carrier ||
                                          "-"}  ${flight?.flight_Id || "-"}`}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item md={0.5}>
                                    <Divider
                                      variant="middle"
                                      flexItem
                                      orientation="vertical"
                                      className={classes.firstDivider}
                                      style={{ height: "60px", alignSelf: "center" }}
                                    />
                                  </Grid>
                                  <Grid xs={12} md={5} item className={classes.flightTimeDetails}>
                                    <Grid
                                      container
                                      justify="space-between"
                                      alignContent="center"
                                      alignItems="center"
                                      spacing={3}
                                    >
                                      <Grid item xs={4}>
                                        <Typography
                                          className={classes.time}
                                          color="textSecondary"
                                          align="center"
                                        >
                                          {(flight?.Depature_Time &&
                                            moment(
                                              new Date(flight?.Depature_Time)
                                            ).format("HH:mm")) ||
                                            "NA"}
                                        </Typography>
                                        <Typography
                                          className={classes.cityName}
                                          align="center"
                                        >
                                          {flight?.Depature_City || "NA"}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={4} item>
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            flight?.stops.length > 0 &&
                                              this.openPopover(
                                                e,
                                                flight?.stops
                                              );
                                          }}
                                          className={classes.linewraper}
                                        >
                                          <Typography
                                            className={classes.lineHeading}
                                          >
                                            {flight?.Total_Time || "NA"}
                                          </Typography>
                                          <ul className={classes.connector}>
                                            <li
                                              className={classes.linkconnector}
                                            />
                                            <li
                                              className={classes.linkconnector}
                                            />
                                          </ul>
                                          <Typography
                                            className={classes.lineSubHeading}
                                          >
                                            {flight?.Mode_Of_Flight || "NA"}{" "}
                                            {flight?.stops.length > 0 && "Via"}{" "}
                                            {flight?.stops
                                              .map((stop) => {
                                                return stop.stopName;
                                              })
                                              .join(",")}
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography
                                          className={classes.time}
                                          align="center"
                                        >
                                          {(flight?.Arrival_Time &&
                                            moment(
                                              new Date(flight?.Arrival_Time)
                                            ).format("HH:mm")) ||
                                            "NA"}
                                        </Typography>
                                        <Typography
                                          className={classes.cityName}
                                          align="center"
                                        >
                                          {flight?.Arrival_City}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item md={0.5}>
                                    <Divider
                                      variant="middle"
                                      flexItem
                                      orientation="vertical"
                                      className={classes.secondDivider}
                                      style={{ height: "120px", alignSelf: "center" }}
                                    />
                                  </Grid>
                                  <Grid xs={12} md={2} item style={{ padding: "0px" }}>
                                    <Typography align="center" className={classes.flightTotalPrice} >
                                      {flight?.TotalPrice || "NA"}
                                    </Typography>
                                    <div style={{ textAlign: "center", paddingBottom: "5px" }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.flightbookbtn}
                                        onClick={() => {
                                          if (this.state.authToken) {
                                            this.goToSelectedRoute(
                                              "/reviewBooking",
                                              {
                                                searchData: {
                                                  ...this.state.searchData,
                                                },
                                                entityDetail: { ...flight },
                                                fromCity: this.state.fromCity,
                                                toCity: this.state.toCity,
                                                entityType: "Flights",
                                                departureDate: this.state?.departureDate,
                                                adult: this.state?.adult,
                                                childrens: this.state?.childrens,
                                                infant: this.state?.infant,
                                                role: this.state.role,
                                                selectedTravellingType: this.state.selectedTravellingType,
                                              }
                                            );
                                          } else {
                                            this.headerRef?.openLogin();
                                          }
                                        }}
                                      >
                                        Book
                                      </Button>
                                    </div>
                                    <Typography
                                      // onClick={() => {
                                      //   this.goToSelectedRoute(
                                      //     "/flightDetail",
                                      //     {
                                      //       searchData: {
                                      //         ...this.state.searchData,
                                      //       },
                                      //       entityDetail: { ...flight },
                                      //       fromCity: this.state.fromCity,
                                      //       toCity: this.state.toCity,
                                      //       entityType: "Flights",
                                      //       role: this.state.role,
                                      //       selectedTravellingType: this.state
                                      //         .selectedTravellingType,
                                      //     }
                                      //   );
                                      // }}
                                      onClick={() => {
                                        this.setState({
                                          selectedFlightViewDetails: index,
                                          searchData: { ...this.state?.searchData, },
                                          entityDetail: { ...flight },
                                          flightViewDetailsOpen: this.state?.selectedFlightViewDetails === index ? !this.state?.flightViewDetailsOpen : true,
                                          oneWayFlightList: [],
                                        }, () => { !this.state?.flightViewDetailsOpen ? "" : this.getFareSummary(this.state.searchData, this.state.entityDetail?.mergeAirSagmentBookingFlightInfo); })
                                      }}
                                      color="primary"
                                      style={{ cursor: "pointer", fontSize: "15px", fontWeight: "600", color: "#3385D7" }}
                                      gutterBottom
                                      align="center"
                                    >
                                      View Details
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Collapse in={this.state?.flightViewDetailsOpen}>
                                  {this.state?.selectedFlightViewDetails === index &&
                                    <Grid>
                                      <Divider variant="fullWidth" className={classes.viewFlightDivider} />
                                      <Grid item xs={12} className={classes.flightBoxDetails}>
                                        {this.state.oneWayFlightList?.length > 0 && this.state.oneWayFlightList?.map((flight, flightIndex) => {
                                          return <>
                                            <Grid key={flight?.FlightNumber} container>
                                              <Grid item md={5} sm={6} xs={12} className={classes.viewFlightDetail1}>
                                                <Grid item md={12} xs={12} className={classes.viewFlightDetail}>
                                                  <ListItem style={{ padding: 0 }}>
                                                    <ListItemAvatar>
                                                      <img
                                                        className={classes.viewFlightImage}
                                                        src={
                                                          flight?.Flight_Image
                                                        }
                                                      />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                      classes={{
                                                        primary: classes.flightName,
                                                        secondary: classes.flightCode,
                                                      }}
                                                      primary={
                                                        flight?.Flight_Name || "NA"
                                                      }
                                                      secondary={`${flight?.Flight_carrier || "-"
                                                        }${flight?.Flight_Id || "-"}`}
                                                    />
                                                    {/* <ListItemText classes={{ primary: classes.ViewFlightTotalPrice }} primary={`${this.state?.currency} ${this.state?.totalPrice}` || 0} /> */}
                                                  </ListItem>
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                  <Grid
                                                    container
                                                    justify="space-between"
                                                  >
                                                    <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                                    <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                                    <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                                    {this.state.AirPriceInfo.length > 0 && this.state.AirPriceInfo.map((airPriceInfo, index) => {
                                                      return <>
                                                        <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                                        <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                                        <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                                      </>
                                                    })
                                                    }
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid item md={7} sm={6} xs={12} className={classes.viewFlightDetail2}>
                                                <Grid
                                                  container
                                                  justify="space-between"
                                                >
                                                  <Grid item xs={4} sm={4} md={4} className={classes.departureSection}>
                                                    <Typography
                                                      className={[
                                                        classes.flightDate,
                                                      ]}
                                                    >
                                                      {(flight?.Depature_DateTime &&
                                                        moment(
                                                          flight?.Depature_DateTime
                                                        ).format("ddd, DD MMM")) ||
                                                        "NA"}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.flightPlaceMode}
                                                    >
                                                      {" "}
                                                      Departure{" "}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.flightTime}
                                                      color="textSecondary"
                                                    >
                                                      {(flight?.Depature_DateTime &&
                                                        moment(
                                                          (
                                                            flight?.Depature_DateTime
                                                          )
                                                        ).format("hh:mm A")) ||
                                                        "NA"}
                                                    </Typography>
                                                    <Typography
                                                      className={[
                                                        classes.flightAddress,
                                                      ]}
                                                      color="textPrimary"
                                                    >
                                                      {" "}
                                                      {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                                        "NA"}{", "} {flight?.Depature_City ||
                                                          "NA"}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={3} sm={3} md={3}>
                                                    <div className={classes.linewraper}>
                                                      <Typography
                                                        color="primary"
                                                        className={classes.flightDiffTime}
                                                      >
                                                        {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                                      </Typography>
                                                      <ul className={classes.container}>
                                                        <li className={classes.linkconnector} />
                                                        <li className={classes.linkconnector} />
                                                      </ul>
                                                      <Typography
                                                        color="primary"
                                                        className={classes.flightDiffStop}
                                                      >
                                                        {flight?.Mode_Of_Flight}
                                                      </Typography>
                                                    </div>
                                                  </Grid>
                                                  <Grid item xs={4} sm={4} md={4} className={classes.arrivalSection}>
                                                    <Typography
                                                      className={[
                                                        classes.flightDate,
                                                      ]}
                                                    >
                                                      {" "}
                                                      {(flight?.Arrival_DateTime &&
                                                        moment(
                                                          flight?.Arrival_DateTime
                                                        ).format("ddd, DD MMM")) ||
                                                        "NA"}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.flightPlaceMode}
                                                    >
                                                      {" "}
                                                      Arrival{" "}
                                                    </Typography>
                                                    <Typography
                                                      className={classes.flightTime}
                                                      color="textSecondary"
                                                    >
                                                      {(flight?.Arrival_DateTime &&
                                                        moment(
                                                          (
                                                            flight?.Arrival_DateTime
                                                          )
                                                        ).format("hh:mm A")) ||
                                                        "NA"}
                                                    </Typography>
                                                    <Typography
                                                      className={[
                                                        classes.flightAddress,
                                                      ]}
                                                      color="textPrimary"
                                                    >
                                                      {" "}
                                                      {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                                        "NA"}{", "} {flight?.Arrival_City ||
                                                          "NA"}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            {flightIndex < (this.state.oneWayFlightList?.length - 1) &&
                                              <Grid item xs={12} className={classes.layoverDivider}>
                                                <Divider className={classes.dividerStyle} />
                                                <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[flightIndex].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[flightIndex].stopName})</Typography>
                                                <Divider className={classes.dividerStyle} />
                                              </Grid>
                                            }
                                          </>
                                        })}
                                      </Grid>
                                    </Grid>
                                  }
                                </Collapse>

                              </div>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <Grid
                              container
                              className={classes.noFlightFound}
                            >
                              <Grid item xs={11}>
                                <Typography color="textPrimary" variant="h1">
                                  No Flights Found
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {this.state?.oneWayflights?.length > 0 && (
                          <Grid item xs={12}>
                            <TablePagination
                              component="div"
                              count={this.state.oneWayflightsTotalCount}
                              page={this.state.pageCount}
                              onChangePage={this.handlePageChange}
                              rowsPerPage={this.state.recordsPerPage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!!(Object.keys(this.state.searchData).length > 0) &&
          this.state.selectedTravellingType === "twoway" &&
          this.state?.selectedFlight1 &&
          this.state?.selectedFlight2 && (
            <Grid item md={12} xs={12} className={classes.twoWaySelectedFlight}>
              <Grid
                container
                justify="space-around"
                alignItems="center"
                style={{
                  backgroundColor: "#1565d8",
                  color: "#fff",
                  padding: "15px",
                  // marginTop: "16px",
                }}
              >
                <Grid item xs={5} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justify="space-between">
                        <Grid item md={4} xs={12}>
                          <ListItem>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                alt="Remy Sharp"
                                src={this.state?.selectedFlight1?.flight_Image}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={this.state?.selectedFlight1?.flight_Name}
                              secondary={`${this.state?.selectedFlight1
                                ?.flight_Carrier || "-"} ${this.state
                                  ?.selectedFlight1?.flight_Id || "-"}`}
                              classes={{
                                primary: classes.selectedFilghtcolor,
                                secondary: classes.selectedFilghtcolor,
                              }}
                            />
                          </ListItem>
                        </Grid>

                        <Grid
                          md={7}
                          xs={12}
                          item
                          container
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <Typography
                              className={classes.selectedFilghttime}
                              align="left"
                            >
                              {" "}
                              {(this.state?.selectedFlight1?.Depature_Time &&
                                moment(
                                  new Date(
                                    this.state?.selectedFlight1?.Depature_Time
                                  )
                                ).format("HH:mm")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.selectedFilghttime}
                              align="left"
                            >
                              {" "}
                              {this.state?.selectedFlight1?.Depature_City}{" "}
                            </Typography>
                          </Grid>
                          <Grid xs={4} item>
                            <div className={classes.linewraper}>
                              <Typography
                                className={classes.selectedFilghtlineHeading}
                              >
                                {this.state?.selectedFlight1?.Total_Time}
                              </Typography>
                              <ul className={classes.selectedFilghtcontainer}>
                                <li
                                  className={
                                    classes.selectedFilghtlinkconnector
                                  }
                                />
                                <li
                                  className={
                                    classes.selectedFilghtlinkconnector
                                  }
                                />
                              </ul>
                              <Typography
                                color="textSecondary"
                                className={classes.selectedFilghtlineSubHeading}
                              >
                                {this.state?.selectedFlight1?.Mode_Of_Flight}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              className={classes.selectedFilghttime}
                              color="textSecondary"
                              align="right"
                            >
                              {" "}
                              {(this.state?.selectedFlight1?.Arrival_Time &&
                                moment(
                                  new Date(
                                    this.state?.selectedFlight1?.Arrival_Time
                                  )
                                ).format("HH:mm")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.selectedFilghttime}
                              color="textSecondary"
                              align="right"
                            >
                              {" "}
                              {this.state?.selectedFlight1?.Arrival_City}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Grid
                            container
                            justify="flex-end"
                            alignContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Typography
                                className={classes.selectedFilghPrice}
                              >
                                {this.state?.selectedFlight1?.TotalPrice}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  style={{
                    backgroundColor: "white",
                  }}
                />
                <Grid item xs={5} md={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justify="space-between">
                        <Grid item md={4} xs={12}>
                          <ListItem>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                alt="Remy Sharp"
                                src={this.state?.selectedFlight2?.flight_Image}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={this.state?.selectedFlight2?.flight_Name}
                              secondary={`${this.state?.selectedFlight2
                                ?.flight_Carrier || "-"} ${this.state
                                  ?.selectedFlight2?.flight_Id || "-"}`}
                              classes={{
                                primary: classes.selectedFilghtcolor,
                                secondary: classes.selectedFilghtcolor,
                              }}
                            />
                          </ListItem>
                        </Grid>

                        <Grid
                          md={7}
                          xs={12}
                          item
                          container
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <Typography
                              className={classes.selectedFilghttime}
                              align="left"
                            >
                              {" "}
                              {(this.state?.selectedFlight2?.Depature_Time &&
                                moment(
                                  new Date(
                                    this.state?.selectedFlight2?.Depature_Time
                                  )
                                ).format("HH:mm")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.selectedFilghttime}
                              align="left"
                            >
                              {" "}
                              {this.state?.selectedFlight2?.Depature_City}{" "}
                            </Typography>
                          </Grid>
                          <Grid xs={4} item>
                            <div className={classes.linewraper}>
                              <Typography
                                className={classes.selectedFilghtlineHeading}
                              >
                                {this.state?.selectedFlight2?.Total_Time}
                              </Typography>
                              <ul className={classes.selectedFilghtcontainer}>
                                <li
                                  className={
                                    classes.selectedFilghtlinkconnector
                                  }
                                />
                                <li
                                  className={
                                    classes.selectedFilghtlinkconnector
                                  }
                                />
                              </ul>
                              <Typography
                                color="textSecondary"
                                className={classes.selectedFilghtlineSubHeading}
                              >
                                {this.state?.selectedFlight2?.Mode_Of_Flight}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              className={classes.selectedFilghttime}
                              color="textSecondary"
                              align="right"
                            >
                              {" "}
                              {(this.state?.selectedFlight2?.Arrival_Time &&
                                moment(
                                  new Date(
                                    this.state?.selectedFlight2?.Arrival_Time
                                  )
                                ).format("HH:mm")) ||
                                "NA"}
                            </Typography>
                            <Typography
                              className={classes.selectedFilghttime}
                              color="textSecondary"
                              align="right"
                            >
                              {" "}
                              {this.state?.selectedFlight2?.Arrival_City}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Grid
                            container
                            justify="flex-end"
                            alignContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Typography
                                className={classes.selectedFilghPrice}
                              >
                                {this.state?.selectedFlight2?.TotalPrice}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden smDown>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{
                      backgroundColor: "white",
                    }}
                  />
                </Hidden>
                <Grid item xs={12} md={2}>
                  <Grid container justify="center">
                    <Grid item xs={12} md={10}>
                      <Typography gutterBottom align="center" className={classes.bookNowPriceText}>
                        {this.state.currency} {" "}
                        {(this.state?.selectedFlight1?.TotalPrice && this.state?.selectedFlight2?.TotalPrice &&
                          this.retnum(this.state?.selectedFlight1?.TotalPrice) + this.retnum(this.state?.selectedFlight2?.TotalPrice)
                        )}
                      </Typography>
                      <Button
                        variant="contained"
                        fullWidth
                        color="default"
                        className={classes.bookNowButton}
                        onClick={() => {
                          if (this.state.authToken) {
                            this.goToSelectedRoute("/reviewBooking", {
                              searchData: { ...this.state.searchData },
                              entityDetail: {
                                ...this.state.selectedFlight1,
                              },
                              selectedFlight2: this.state.selectedFlight2,
                              fromCity: this.state.fromCity,
                              toCity: this.state.toCity,
                              entityType: "Flights",
                              role: this.state.role,
                              selectedTravellingType: this.state
                                .selectedTravellingType,
                            });
                          } else {
                            this.headerRef?.openLogin();
                          }
                        }}
                      >
                        Book Now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        <Footer marginTop={0}></Footer>
      </div>
    );
  }
}
const FlightSearchResultWithRouter = withRouter(FlightSearchResult);
const FlightSearchResultAlertBox = withAlertBox(FlightSearchResultWithRouter);
const FlightSearchResultLoader = withLoader(FlightSearchResultAlertBox);
const FlightSearchResultToast = withToast(FlightSearchResultLoader);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      width: "100%",
      overflowY: "auto",
      height: "100vh",
      backgroundColor: "#fff",
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      backgroundImage: "url${flightCover}",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    countText: {
      color: "red"
    },

    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(1),
      top: "50%",
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    mainPostSecondaryContentbgcolor: {
      background: "linear-gradient(to bottom, #dbeae7 0%, #ffffff 100%)",

    },
    appBar: {
      background: "transparent",
    },
    flightItemContainer: {
      padding: "25px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
      '&:hover': {
        border: "2px solid rgba(21,101,216, 1)",
      },
    },
    flightItemContainerSelected: {
      borderRadius: "5px",
      marginBottom: "20px",
      backgroundColor: "rgba(234,237,244, 1)",
      padding: "25px",
    },



    marginRight: {
      marginRight: theme.spacing(2),
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "22px",
      fontWeight: 700,
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1E394E",
    },
    cityName: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#91A3B6",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
      },
    },
    flightImage: {
      width: "70px",
      height: "70px",
      display: "flex",
      overflow: "hidden",
      position: "relative",
      fontSize: "1.25rem",
      alignItems: "center",
      flexShrink: "0",
      lineHeight: "1",
      userSelect: "none",
      borderRadius: "20px",
      justifyContent: "center",
      [theme.breakpoints.only("md")]: {
        width: "60px",
        height: "60px",
      },
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
      cursor: "pointer",
    },
    lineHeadingwraper: {
      textAlign: "center",
      width: "6%",
      display: "inline-block",
    },
    connector: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565D8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#606C78"
    },
    filterformControl: {
      marginBottom: "30px",
    },
    totalFlightsText: {
      color: "rgba(30,57,78,1)",
      fontSize: "24px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "-0.8px",
      [theme.breakpoints.only("xs")]: {
        marginTop: "20px",
      },
    },
    sortIngButton: {
      borderRadius: "10px",
      border: "1px solid rgba(21,101,216, 1)",
      backgroundColor: "rgba(225,238,255, 1)",
      marginRight: "20px",
      maxHeight: "40px",
      cursor: "pointer",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "10px",
      },
      "& p": {
        color: "#1565D8 !important",
      },
    },
    selectedSortingBtn: {
      borderRadius: "10px",
      border: "1px solid rgba(21,101,216, 1)",
      backgroundColor: "#1565D8",
      marginRight: "20px",
      maxHeight: "40px",
      color: "#FFF !important",
      cursor: "pointer",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "10px",
      },
      "& p": {
        color: "#FFF !important",
      }
    },
    sortIconTitle: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      // color: "#1565D8",
      padding: "6px 20px",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      margin: theme.spacing(0, 1),
      color: "#fff",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    containerPosition: {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(36),
      },
    },
    twoWayPrice: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    twowayHeading: {
      fontSize: "22px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    twowaySubHeading: {
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },


    inputfieledroot: {
      "label + &": {
        marginTop: 30,
      },
    },
    input: {
      position: "relative",
      backgroundColor: "#FFF",
      border: "none!important",
      height: "35px!important",
      fontSize: "16px",
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#959ead",
      padding: "1.5px 14px!important",
    },
    astriklabel: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#959ead",
    },
    lableroot: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    outlinedLabel: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    shrink: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    sectionHeading: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    selectedFilghtcolor: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      marginLeft: theme.spacing(2),
      letterSpacing: "normal",
      color: "#fff",
    },
    selectedFilghttime: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#fff",
    },
    selectedFilghtlineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#fff",
    },
    selectedFilghtlinkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    selectedFilghtcontainer: {
      borderTop: "1px solid #fff",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    selectedFilghtlineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#fff",
    },
    addNewcity: {
      textAlign: "center",
      color: "#1565d8",
      padding: "8px",

    },
    selectedFilghPrice: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "700",
      fontStretch: "normal",
      fontStyle: "normal",
      // lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "end",
      color: "#fff",
    },
    flightNameinList: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1E394E",
      paddingLeft: "20px",
      [theme.breakpoints.only("md")]: {
        fontSize: "18px",
        paddingLeft: "10px",
      },
    },
    flightIdInList: {
      fontFamily: "Open Sans",
      fontSize: "15px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#91A3B6",
      paddingLeft: "20px",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        paddingLeft: "10px",
      },
    },
    filterTitleHeading: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "rgba(30,57,78,1);",
      // marginBottom: "20px",
    },
    filterCheckBoxName: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1E394E",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
      },
    },
    filterButtonText: {
      fontFamily: "Open Sans",
      fontSize: "10px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1E394E",
    },
    filterTimingButton: {
      border: "1px solid rgba(21,101,216, 1)",
      backgroundColor: "rgba(225,238,255, 1)",
      color: "#1565D8",
      padding: "6px 9px",
      fontSize: "12px",
      fontWeight: "700",
    },
    selectedFilterTimingButton: {
      border: "1px solid rgba(21,101,216, 1)",
      backgroundColor: "#1565D8",
      color: "#FFF",
      padding: "6px 9px",
      fontSize: "12px",
      fontWeight: "700",
    },
    flightsFilterBox: {
      border: "5px",
      backgroundColor: "#ffffff",
      padding: "20px",
    },
    flightbookbtn: {
      padding: "12px 40px",
      fontSize: "16px", fontWeight: "700", backgroungColor: "#1564D8",
      [theme.breakpoints.only("md")]: {
        padding: "0px",
        minWidth: "80px",
        maxHeight: "40px",
      },
    },
    flightTotalPrice: {
      fontSize: "20px", fontWeight: "700",
      [theme.breakpoints.only("md")]: {
        fontSize: "18px",
      },
    },
    firstDivider: {
      [theme.breakpoints.only("md")]: {
        marginLeft: "-20px",
      },
      [theme.breakpoints.down("md")]: {
        height: "0px !important",
        margin: "10px 0px",
      },
    },
    secondDivider: {
      [theme.breakpoints.down("md")]: {
        height: "0px !important",
        margin: "10px 0px",
      },
    },
    flightTimeDetails: {
      [theme.breakpoints.only("md")]: {
        marginLeft: "-12px",
      },
    },
    filterResult: {
      [theme.breakpoints.only("xs")]: {
        marginTop: "50px",
      },
    },
    customSelect: {
      position: 'relative',
    },
    InputFieldSelect: {
      textTransform: 'none',
      width: '100%',
      margin: '6px 0px 0px 0px',
      padding: '.6rem 7.5rem .6rem 0rem',
      fontSize: '15px',
      fontWeight: '600',
      border: '1px solid #C3CAD9',
      borderRadius: '8px',
      backgroundColor: '#ffffff',
      paddingLeft: '10px',
      '&:hover': {
        border: "1px solid black",
        backgroundColor: '#ffffff',
      },
      '&:focus': {
        border: "2px solid #1565d8",
      },
      '& .MuiButton-label': {
        justifyContent: 'start'
      }
    },
    DropdownMenu: {
      padding: '0px',
      position: 'absolute',
      width: '100%',
      zIndex: '11',
      background: '#fff',
      boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      border: '1px solid #C3CAD9',
      padding: '10px',
      top: '62px',
    },
    Dropdown: {
      listStyle: 'none',
      marginBottom: '10px',
    },
    DropDownList: {
      display: 'flex'
    },
    DropDownMenu1: {
      flexBasis: '30%',
      display: "flex",
      alignItems: "center",
      margin: "0px",
    },
    DropDownMenu2: {
      flexBasis: '70%',
      justifyContent: 'flex-end',
    },

    boederedTop: {
      borderTop: '1px solid #cdcdcd',
      width: '100%',
      marginTop: '35px'
    },
    borderedBottom: {
      borderBottom: '1px solid #cdcdcd'
    },
    borderedBottommain: {
      width: "100%",
    },
    sortingbtn: {
      paddingTop: "30px",
      paddingBottom: "40px",
      [theme.breakpoints.only("xs")]: {
        paddingTop: "30px",
        paddingBottom: "10px",
      },
    },
    noFlightFound: {
      height: "100px",
      borderRadius: "8px",
      backgroundColor: "#FFF",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      marginBottom: "100px",
    },
    viewFlightImage: {
      width: "40px",
      height: "40px",
      marginRight: "15px",
    },
    flightName: {
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#1E394E",
    },
    flightCode: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAalign: "left",
    },
    ViewFlightTotalPrice: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "center",
    },
    flightDetailText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "16px",
      fontWeight: "700",
      fontWtyle: "normal",
      letterSpacing: "0px",
      marginBottom: "10px",
    },
    flightDetailValue: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",
    },
    departureSection: {
      textAlign: "left",
    },
    arrivalSection: {
      textAlign: "right",
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      marginTop: "5px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "5px",
      marginTop: "5px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "14px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "10px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    layoverDivider: {
      marginTop: "10px",
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "10px 0px"
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "14px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    viewFlightDivider: {
      marginTop: "10px",
    },
    viewFlightDetail: {
      marginBottom: "10px",
    },
    viewFlightDetail1: {
      padding: "10px 10px 0px 0px",
      [theme.breakpoints.only("xs")]: {
        padding: "10px 0px 0px 0px",
      },
    },
    viewFlightDetail2: {
      padding: "10px 0px 0px 10px",
      [theme.breakpoints.only("xs")]: {
        padding: "10px 0px 0px 0px",
      },
      bottommar: {
        marginBottom: '18px'
      },
    },
    plusbutton: {
      backgroundColor: '#edf6f3'
    },
    errorhandle: {
      position: 'relative',
      '& .MuiFormHelperText-root': {
        fontSize: '1rem',
        position: 'absolute',
        bottom: '-24px'
      },
      '& .MuiFormHelperText-contained': {
        margin: '0'
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: '#5a7184'
      }
    },
    dropdownIcon: {
      position: 'absolute',
      bottom: '6px',
      right: '8px',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    travellerheading: {
      color: '#5a7184',
      fontSize: '14px',
      fontWeight: '600',
      '&:focus': {
        // backgroundColor: '#131415',
        color: "1px solid blue !important",
        // color: '#FFFFFF',
      },
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    twoWaySelectedFlight: {
      position: "fixed",
      bottom: "0",
      width: "100%",
      zIndex: "9999",
    },
    bookNowPriceText: {
      fontSize: "16px",
      fontWeight: "600",
    },
    bookNowButton: {
      fontSize: "14px",
      fontWeight: "700",
      color: "rgb(21, 101, 216)",
      background: "#fff",
    },
    journeyText: {
      padding: "10px",
      color: "rgba(30, 57, 78, 1)",
      fontSize: "20px",
      fontWeight: "700",
    },
  })
)(FlightSearchResultToast);
