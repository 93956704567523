import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";

export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps &
  {
    id: string;
    role : string
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : string;
  teamMemberList : Array<any>,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TeamMemberlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  teamMemberRequestCallId: String = "";
  deleteMemberRequestCallId : String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      teamMemberList : [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.teamMemberRequestCallId) {
        this.props.hideLoader();
        if(this.props.role === 'Admin'){
          if (responseJson && responseJson && responseJson?.data) {
            this.setState({
              teamMemberList : responseJson?.data
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          } 
        } else if(this.props.role === 'Agency') {
          if (responseJson && responseJson && responseJson?.agencies?.data) {
            this.setState({
              teamMemberList : responseJson?.agencies?.data
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        
      } else if (apiRequestCallId === this.deleteMemberRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.status === true) {
          this.props.showToast({type:'success',message:'Member Deleted succssfully'})
          this.getTeamMemberList();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } 
    }
  }
 
  getTeamMemberList = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.teamMemberRequestCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props.role === 'Admin' ? "account_block/get_admin_teams" : `${configJSON.getSubAgancyList}?associate_type=team_member`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteMember = (id : any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteMemberRequestCallId = requestMessage.messageId;
    const httpBody = {
      member_id : id
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props.role === 'Admin' ? `account_block/admin_teams?id=${id}` : `bx_block_dashboard/delete_member`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    if(this.props.role !== 'Admin'){
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      )
    }
    ;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
}
