//@ts-nocheck
import React from "react";
import {
    withStyles,
    Grid,
    Typography,
    createStyles,
    Link,
    Box,
    ListItem,
    ListItemText,
    Button,
    Divider,
    ListItemAvatar,
} from "@material-ui/core";
import withFontStyles from "./withFontStyles.web";
import moment from "moment";
import { Redirect } from "react-router-dom";

const MainFlightPost = (props) => {
    const { classes } = props;
    const { fromCity, toCity, selectedTravellingType, searchData } = props;
    return (
        <>
            <Grid container justify="center">
                <Grid item md={8} xs={10}>
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        className={classes.mainPostContent}
                    >
                        <Grid item xs={12}>
                            <Typography className={classes.flightText} align="center">
                                Flights From
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={5} md={5}>
                                    <Typography align="center" className={classes.sourceName}>
                                        {fromCity?.city}
                                    </Typography>
                                    <Typography align="center" className={classes.airportName}>
                                        {fromCity?.iata && `(${fromCity?.iata})`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography align="center" className={classes.sourceName}>
                                        to
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} md={5}>
                                    <Typography align="center" className={classes.sourceName}>
                                        {toCity?.city}
                                    </Typography>
                                    <Typography align="center" className={classes.airportName}>
                                        {toCity?.iata && `(${toCity?.iata})`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {selectedTravellingType === "twoway" && (
                        <>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography align="center" className={classes.sourceName}>
                                        & Return
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5} md={5}>

                                        <Typography align="center" className={classes.sourceName}>
                                            {toCity?.city}
                                        </Typography>
                                        <Typography align="center" className={classes.airportName}>
                                            {toCity?.iata && `(${toCity?.iata})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <Typography align="center" className={classes.sourceName}>
                                            to
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography align="center" className={classes.sourceName}>
                                            {fromCity?.city}
                                        </Typography>
                                        <Typography align="center" className={classes.airportName}>
                                            {fromCity?.iata && `(${fromCity?.iata})`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>)}
                        {/* <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                alignContent="center"
                            >
                                <Grid item xs={5} md={5}>
                                    
                                </Grid>
                                <Grid item xs={2} md={2}>
                                </Grid>
                                <Grid item xs={5} md={5}>
                                    
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* {selectedTravellingType === "twoway" && (
                            <Grid item xs={12}>
                                <Typography className={classes.ReturnText} align="center">
                                    & Return
                                </Typography>
                            </Grid>
                        )} */}
                        <Grid item xs={12}>
                            <Typography className={classes.welcomeText} style={{ margin: "30px 0px", fontWeight: "700" }} align="center">
                                {searchData?.depratureDate && moment(`${searchData?.depratureDate}`).format("DD/MM/YYYY")} {searchData?.returnDate && '&'} {searchData?.returnDate && moment(`${searchData?.returnDate}`).format("DD/MM/YYYY")} {(searchData?.adults > 0 || searchData?.children > 0 || searchData?.infants > 0) && `|`} {searchData?.adults > 0 && `${searchData?.adults} Adults`} {(searchData?.adults > 0 && searchData?.children > 0) && `,`} {searchData?.children > 0 && `${searchData?.children} Children`} {(searchData?.adults > 0 && searchData?.infants > 0) && `,`} {searchData?.infants > 0 && `${searchData?.infants} Infant`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.welcomeText} style={{ marginBottom: "40px" }} align="center">
                                <span style={{ borderBottom: "1px solid rgba(255,255,255, 1)" }}>Change your search from <Link href="/" style={{ fontWeight: '900' }}>here</Link></span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
const MainFlightPostWithFontStyles = withFontStyles(MainFlightPost);
export default withStyles((theme) =>
    createStyles({
        mainPostContent: {
            position: "relative",
            padding: "45px",
            color: "#FFF",
            maxHeight: "760px",
            [theme.breakpoints.up("md")]: {
                padding: "40px",
            },
            [theme.breakpoints.only("sm")]: {
                padding: "30px",
            },
            [theme.breakpoints.only("xs")]: {
                padding: "30px 0px 0px 0px",
            },
        },
        flightText: {
            fontFamily: "Public Sans",
            fontSize: "40px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.276,
            letterSpacing: "normal",
            color: "#ffffff",
            [theme.breakpoints.only("sm")]: {
                fontSize: "28px",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "22px",
            },
        },
        sourceName: {
            fontFamily: "Public Sans",
            fontSize: "40px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.276,
            letterSpacing: "normal",
            color: "#ffffff",
            [theme.breakpoints.only("sm")]: {
                fontSize: "32px",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "18px",
            },
        },
        airportName: {
            fontFamily: "Public Sans",
            fontSize: "18px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.33,
            letterSpacing: "normal",
            color: "#ffffff",
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "14px",
            },
        },
        ReturnText: {
            fontFamily: "Open Sans",
            fontSize: "32px",
            fontWeight: 600,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.75,
            letterSpacing: "normal",
            textAlign: "center",
            color: "#ffffff",
        },
        welcomeText: {
            fontFamily: "Open Sans",
            fontSize: "18px",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: "normal",
            // lineHeight: 0.17,
            letterSpacing: "normal",
            textAlign: "center",
            color: "#ffffff",
            "& a:hover": {
                textDecoration: "none",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
            },
        },
    })
)(MainFlightPostWithFontStyles);