//@ts-nocheck
import React from "react";
import AllSubAgenciesController, {
  Props,
} from "./AllSubAgenciesController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Tabs,
  Tab,
  Paper,
  TablePagination,
  InputAdornment,
  TextField,
  IconButton,
} from "@material-ui/core";
import InputField from "../../../components/src/InputFiled.web";
import { hotelIcon, allIcon, flightIcon, hotelImage } from "./assets";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import TimerIcon from '@material-ui/icons/Timer';

class AllSubAgencies extends AllSubAgenciesController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.geAllPendingRequest();
          this.geAllAcceptedRequest();
          this.geAllRejectedRequest();
        }
      );
    }
  }
  goToSelectedRoute = async (routeName, data, id) => {
    const { history } = this.props;
    await StorageProvider.set("agencyDetail",JSON.stringify({ ...data, id: id }))
    history.push(routeName);
  };
  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        pendingCount: value,
      },
      () => {
        this.geAllPendingRequest();
      }
    );
  };
  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        pendingrecordsPerPage: parseInt(event.target.value, 10),
        pendingCount: 0,
      },
      () => {
        this.geAllPendingRequest();
      }
    );
  };
  handleActivePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    this.setState(
      {
        activeCount: value,
      },
      () => {
        this.geAllAcceptedRequest();
      }
    );
  };
  handleActiveChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        activerecordsPerPage: parseInt(event.target.value, 10),
        activeCount: 0,
      },
      () => {
        this.geAllAcceptedRequest();
      }
    );
  };

  handleRejectedPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    this.setState(
      {
        rejectedCount: value,
      },
      () => {
        this.geAllRejectedRequest();
      }
    );
  };
  handleRejectedChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        rejectedrecordsPerPage: parseInt(event.target.value, 10),
        rejectedCount: 0,
      },
      () => {
        this.geAllRejectedRequest();
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
        <Grid container justify="space-around" spacing={4}>
          <Grid item xs={12}>
            <Grid
              container
              alignItems='center'
              justify='center'
              className={classes.pendIngRequestBox}
            >
              <Grid item md={12} xs={12}>
                <Tabs
                  value={this.state.selectedTab}
                  onChange={(evt, value) => {
                    this.setState(
                      {
                        selectedTab: value,
                      },
                      () => {
                        // this.getBookings();
                      }
                    );
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label="Pending"
                    classes={{ root: classes.tabLabel }}
                    value={"Pending"}
                    icon={
                      <TimerIcon/>
                    }
                  />
                  <Tab
                    label="Approved"
                    classes={{ root: classes.tabLabel }}
                    value={"Approved"}
                    icon={
                      <DoneIcon/>
                    }
                  />
                  <Tab
                    label="Rejected"
                    classes={{ root: classes.tabLabel }}
                    value={"Rejected"}
                    icon={
                      <CloseIcon/>
                    }
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
          {this.state.selectedTab === "Pending" ? (
            <>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  name="searchText"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      this.geAllPendingRequest();
                    }
                  }}
                  label="Search by email or name or phone number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {this.state.searchText ? (
                          <IconButton
                            onClick={() =>
                              this.setState({ searchText: "" }, () => {
                                this.geAllPendingRequest();
                              })
                            }
                          >
                            <ClearOutlinedIcon></ClearOutlinedIcon>
                          </IconButton>
                        ) : (
                          <IconButton>
                            <SearchOutlinedIcon></SearchOutlinedIcon>
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  type="search"
                  variant="outlined"
                />
              </Grid>
            </>
          ) : null}
          {this.state.selectedTab === "Pending"
            ? this.state.allPendIngInvites.map((agency) => {
                return (
                  <Grid item key={agency.id} xs={12}>
                    <Grid
                      container
                      justify="space-around"
                      className={classes.agencyPendingRequestContainer}
                    >
                      <Grid item xs={11}>
                        <div style={{ display: "flex" }}>
                          <div style={{ flexGrow: 1 }}>
                            <Typography
                              gutterBottom
                              className={classes.agencyName}
                            >
                              {agency?.attributes?.agency_name || "Not Available"}
                            </Typography>
                          </div>
                          <Typography
                            gutterBottom
                            className={classes.pendIngStatus}
                          >
                            Acceptance Pending
                          </Typography>
                        </div>
                      </Grid>
                      <Grid xs={11} item>
                        <div style={{ display: "flex" }}>
                          <div style={{ flexGrow: 1 }}>
                            <Typography
                              className={classes.anancyInfo}
                              gutterBottom
                            >
                              {agency?.attributes?.email || "Not Available"}
                            </Typography>
                            <Typography
                              className={classes.anancyInfo}
                              gutterBottom
                            >
                              {agency?.attributes?.full_phone_number || "Not Available"}
                            </Typography>
                            <Typography
                              className={classes.anancyInfo}
                              gutterBottom
                            >
                              {agency?.attributes?.full_name || "Not Available"}
                            </Typography>
                          </div>
                          <Button
                            style={{ alignSelf: "flex-end", width: '142px' }}
                            onClick={() => {
                              this.goToSelectedRoute(
                                "/acceptAgancyRequest",
                                agency?.attributes,
                                agency?.id
                              );
                            }}
                            variant="contained"
                            color="primary"
                          >
                            View
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {this.state.selectedTab === "Pending" ? (
            <>
              <Grid item xs={12}>
                <TablePagination
                  component="div"
                  count={this.state.totalPendingRequest}
                  page={this.state.pendingCount}
                  onChangePage={this.handlePageChange}
                  rowsPerPage={this.state.pendingrecordsPerPage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Grid>
            </>
          ) : null}
          {this.state.selectedTab === "Approved" ? (
            <>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  name="searchText"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      this.geAllAcceptedRequest();
                    }
                  }}
                  label="search by email or name or phone number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {this.state.searchText ? (
                          <IconButton
                            onClick={() =>
                              this.setState({ searchText: "" }, () => {
                                this.geAllAcceptedRequest();
                              })
                            }
                          >
                            <ClearOutlinedIcon></ClearOutlinedIcon>
                          </IconButton>
                        ) : (
                          <IconButton>
                            <SearchOutlinedIcon></SearchOutlinedIcon>
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  type="search"
                  variant="outlined"
                />
              </Grid>
            </>
          ) : null}
          {this.state.selectedTab === "Approved"
            ? this.state.allAcceptedInvites.map((agency) => {
                return (
                  <Grid item key={agency.id} xs={12}>
                    <Grid
                      container
                      justify="space-around"
                      className={classes.agencyRequestContainer}
                    >
                      <Grid item xs={11}>
                        <Typography className={classes.agencyName}>
                          {agency?.attributes?.agency_name || "Not Available"}
                        </Typography>
                        <Typography className={classes.anancyInfo}>
                          {agency?.attributes?.email || "Not Available"}
                        </Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container>
                          <Grid item xs={4}>
                            <ListItem>
                              <ListItemText
                                primary="Bookings Today"
                                secondary={`${agency?.attributes?.flight_booking_today || 0} flights`}
                                classes={{
                                  primary: classes.primartText,
                                  secondary: classes.secondaryText,
                                }}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={2}>
                            <ListItem>
                              <ListItemText
                                primary="Status"
                                secondary={
                                  agency?.attributes?.status || "Not Available"
                                }
                                classes={{
                                  primary: classes.primartText,
                                  secondary: classes.secondaryText,
                                }}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={3}>
                            <ListItem>
                              <ListItemText
                                primary="Credits"
                                secondary={
                                  agency?.attributes?.credt_limit?.data
                                    ?.attributes?.available_limit ||
                                  "Not Available"
                                }
                                classes={{
                                  primary: classes.primartText,
                                  secondary: classes.secondaryText,
                                }}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ textAlign: "center" }}>
                              <Button
                                style={{ display: "block", height: "40px" }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.goToSelectedRoute(
                                    "/acceptAgancyRequest",
                                    agency?.attributes,
                                    agency?.id
                                  );
                                }}
                              >
                                View
                              </Button>
                              {agency?.attributes?.role === "agency" && (
                                <Typography
                                  style={{ display: "block", marginTop: "8px" }}
                                  className={classes.viewSubAgancy}
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname: "/AdminDashboard/SubagancyList",
                                      state: { id: agency?.id },
                                    });
                                  }}
                                >
                                  View Subagencies
                                </Typography>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          {this.state.selectedTab === "Approved" ? (
            <>
              <Grid item xs={12}>
                <TablePagination
                  component="div"
                  count={this.state.totalActiveRequest}
                  page={this.state.activeCount}
                  onChangePage={this.handleActivePageChange}
                  rowsPerPage={this.state.activerecordsPerPage}
                  onChangeRowsPerPage={this.handleActiveChangeRowsPerPage}
                />
              </Grid>
            </>
          ) : null}
          {this.state.selectedTab === "Rejected" ? (
            <>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  name="searchText"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      this.geAllRejectedRequest();
                    }
                  }}
                  label="search by email or name or phone number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {this.state.searchText ? (
                          <IconButton
                            onClick={() =>
                              this.setState({ searchText: "" }, () => {
                                this.geAllRejectedRequest();
                              })
                            }
                          >
                            <ClearOutlinedIcon></ClearOutlinedIcon>
                          </IconButton>
                        ) : (
                          <IconButton>
                            <SearchOutlinedIcon></SearchOutlinedIcon>
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  type="search"
                  variant="outlined"
                />
              </Grid>
            </>
          ) : null}
          {this.state.selectedTab === "Rejected"
            ? this.state.allRejectedInvites.map((agency) => {
                return (
                  <Grid item key={agency.id} xs={12}>
                  <Grid
                    container
                    justify="space-around"
                    className={classes.agencyRequestContainer}
                  >
                    <Grid item xs={11}>
                      <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}>
                          <Typography
                            gutterBottom
                            className={classes.agencyName}
                          >
                            {agency?.attributes?.agency_name || "Not Available"}
                          </Typography>
                        </div>
                        <Typography
                          gutterBottom
                          className={classes.rejectedStatus}
                        >
                          Rejected
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={11} item>
                      <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}>
                          <Typography
                            className={classes.anancyInfo}
                            gutterBottom
                          >
                            {agency?.attributes?.email || "Not Available"}
                          </Typography>
                          <Typography
                            className={classes.anancyInfo}
                            gutterBottom
                          >
                            {agency?.attributes?.full_phone_number || "Not Available"}
                          </Typography>
                          <Typography
                            className={classes.anancyInfo}
                            gutterBottom
                          >
                            {agency?.attributes?.full_name || "Not Available"}
                          </Typography>
                        </div>
                        <Button
                          style={{ alignSelf: "flex-end", width: '100px' }}
                          onClick={() => {
                            this.goToSelectedRoute(
                              "/acceptAgancyRequest",
                              agency?.attributes,
                              agency?.id
                            );
                          }}
                          variant="contained"
                          color="primary"
                        >
                          View
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
             
                  );
              })
            : null}
          {this.state.selectedTab === "Rejected" ? (
            <>
              <Grid item xs={12}>
                <TablePagination
                  component="div"
                  count={this.state.totalRejectedRequest}
                  page={this.state.rejectedCount}
                  onChangePage={this.handleRejectedPageChange}
                  rowsPerPage={this.state.rejectedrecordsPerPage}
                  onChangeRowsPerPage={this.handleRejectedChangeRowsPerPage}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
    );
  }
}

const AllSubAgenciesWithRouter = withRouter(AllSubAgencies);
const AllSubAgenciesAlertBox = withAlertBox(AllSubAgenciesWithRouter);
const AllSubAgenciesLoader = withLoader(AllSubAgenciesAlertBox);
const AllSubAgenciesToast = withToast(AllSubAgenciesLoader);
const AllSubAgenciesWithDialog = withDialog(AllSubAgenciesToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
      height: theme.spacing(12.5)
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    agencyPendingRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #1565d8",
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
    },
    agencyRequestContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "32px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.2px",
      color: "#fb940e",
      alignSelf: "center",
    },
    rejectedStatus: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "red",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    tabLabel: {
      textTransform: "none",
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      textAlign: "center",
    },
  })
)(AllSubAgenciesWithDialog);
