//@ts-nocheck
import React from "react";
import AcceptAgencyRequestController, {
  Props,
} from "./AcceptAgencyRequestController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Divider,
  Tabs,
  Tab,
  TextField,
  Hidden,
} from "@material-ui/core";

import InputFiled from "../../../components/src/InputFiled.web";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import Rating from "@material-ui/lab/Rating";
import {
  hotelIcon,
  allIcon,
  flightIcon,
  hotelImage,
  agancyCover,
  filterIcon
} from "./assets";
import FlightInfo from "./FlightInfo.web";
import HotelInfo from "./HotelInfo.web";
import { withRouter, Route, Switch, Redirect } from "react-router";
import Footer from "./Footer.web";
import Header from "./Header.web";
import { NavLink } from "react-router-dom";
import withFontStyles from "./withFontStyles.web";
import AgencyDetail from "./AgencyDetail.web";
import Report from "./Report.web";
const StyledRating = withStyles({
  iconFilled: {
    color: "#1565d8",
  },
  iconHover: {
    color: "#1565d8",
  },
  root: {
    fontSize: "1em",
  },
})(Rating);

class AcceptAgencyRequest extends AcceptAgencyRequestController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("adminToken");
    let agencyDetail = await StorageProvider.get("agencyDetail");
    agencyDetail = agencyDetail && JSON.parse(agencyDetail);
    if (authToken && agencyDetail) {
      this.setState(
        {
          token: authToken,
          agencyDetail: JSON.parse(agencyDetail),
          accountId: agencyDetail?.account_id,
        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${agancyCover})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={agancyCover}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <Header role="Admin" />
          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  className={classes.helloAdmin}
                  align="center"
                  gutterBottom
                >
                  Agency
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
        {/* End hero unit */}
        <Grid container spacing={4} justify="center">
          <Grid md={3} xs={11} item>
            <Grid container>
              <Grid item xs={10}>
                <Paper elevation={0} className={classes.sidebarAboutBox}>
                  {[
                    {
                      title: "Subagency Details",
                      url: "/acceptAgancyRequest/subAgencyDetail",
                    },
                    { title: "Reports", url: "/acceptAgancyRequest/reports" },
                  ].map((archive) => (
                    <NavLink
                      to={archive.url}
                      className={classes.sideLink}
                      activeClassName={classes.activeSideLink}
                      key={archive.title}
                    >
                      {archive.title}
                    </NavLink>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={7} xs={11} item>

            <Switch>
               <Route path="/acceptAgancyRequest/reports">
                   <Report tokenName="adminToken" role="Agency" accountId={this.state.accountId} ></Report>
                   
               </Route>
               <Route path="/acceptAgancyRequest/subAgencyDetail">
                  <AgencyDetail></AgencyDetail>
               </Route>
               <Redirect from="/acceptAgancyRequest" to="/acceptAgancyRequest/subAgencyDetail"></Redirect>
            </Switch>
           </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}
const AcceptAgencyRequestControllerWithRouter = withRouter(AcceptAgencyRequest);
const AcceptAgencyRequestControllerAlertBox = withAlertBox(
  AcceptAgencyRequestControllerWithRouter
);
const AcceptAgencyRequestControllerLoader = withLoader(
  AcceptAgencyRequestControllerAlertBox
);
const AcceptAgencyRequestControllerToast = withToast(
  AcceptAgencyRequestControllerLoader
);

const AcceptAgencyRequestWithFontStyles = withFontStyles(
  AcceptAgencyRequestControllerToast
);
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    sidebarAboutBox: {
      padding: theme.spacing(1),
      borderRadius: "8px",
      border: "solid 1px #f8f8fa",
      backgroundColor: "#f8f8fa",
    },
    sidebarSection: {
      backgroundColor: theme.palette.grey[200],
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${agancyCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [theme.breakpoints.up("md")]: {
        height: "348px",
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    mainPostSecondaryContent: {
      position: "relative",
      padding: theme.spacing(2),
      top: "50%",
      borderRadius: "8px",
      boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
      backgroundColor: "#fff",
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "40px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    sideLinkHeading: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
      padding: theme.spacing(1, 1.5),
    },
    sideLink: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#183b56",
      cursor: "pointer",
      textAlign: "left",
      display: "block",
      textDecoration: "none",
      padding: theme.spacing(1.5),
    },
    activeSideLink: {
      backgroundColor: "#fff",
      color: "#1565d8",
      borderRadius: "8px",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    hotelDetailContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: "24px",
    },
    agencyName: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "40px",
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
    tabLabel: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: "#183b56",
      textTransform: "capitalize",
    },
    card: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#fff",
      padding: "38px 48px 66px 45px",
    },
    listItemPrimary: {
      fontFamily: "Public Sans",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    listItemSecondary: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    rightSideBox: {
      [theme.breakpoints.up("md")]: {
        position: "relative",
        bottom: "120px",
      },
    },
  })
)(AcceptAgencyRequestWithFontStyles);
