//@ts-nocheck
import React from "react";
import MyBookingFlightDetailsController, {
  Props,
} from "./MyBookingFlightDetailsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  List,
  ListItemText,
  Button,
  Paper,
  Divider,
  ListItemAvatar,
  ListItemSecondaryAction,
  Link
} from "@material-ui/core";
import moment from "moment";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { hotelImage, flightImage, flightCover, flighPlaceHolder } from "./assets";
import { withRouter } from "react-router";
import Header from "./Header.web";
import Footer from "./Footer.web";
import { alertIcon, covidIcon, suitcaseIcon } from "./assets";
import FlightInfo from "./FlightInfo.web";
import withFontStyles from "./withFontStyles.web";

class MyBookingFlightDetails extends MyBookingFlightDetailsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    if (authToken &&
      state?.booking_id) {
      this.setState(
        {
          token: authToken,
          bookingId: state?.booking_id,
          role: state?.role
        },
        () => {
          this.getBookingDetail();
        }
      );
    } else {
      this.setState({
        loading: false,
        token: null
      })
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  render() {
    const { classes } = this.props;
    const { searchData } = this.props;
    let redirectRouteName = "/"
    if (this.state.role === 'Admin') {
      redirectRouteName = "/AdminLogin"
    } else if (this.state.role === 'Agency') {
      redirectRouteName = "/AgencyMarketing"
    }

    const travellsdetail = (this.state.bookingDetail?.travel_detail?.data?.attributes?.data && Object.values(this.state.bookingDetail?.travel_detail?.data?.attributes?.data || {})) || []

    return !this.state.loading ? (
      this.state.authToken === null ? (<Redirect to={redirectRouteName} />) : ((
        <div className={classes.root}>
          <Paper
            className={classes.mainPost}
            style={{
              backgroundImage: `url(${flightCover})`,
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={flightCover}
                alt={"aaaaaaaaaaaaaaaa"}
              />
            }
            <div className={classes.overlay} />
            {this.state.role && <Header role={this.state.role} />}
            <Grid container justify="center">
              <Grid item md={8} xs={10}>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  className={classes.mainPostContent}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.helloAdmin} align="center">
                      Your Booking
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Typography className={classes.bookedBy} align="center">
                      Booking
                    </Typography> */}
                    <Typography className={classes.bookedBy} align="center">#
                      {this.state.bookingDetail?.air_locator_code || "Not Available"}
                    </Typography>
                    <Typography className={classes.pnrStatus} align="center">
                      PNR : {this.state.bookingDetail?.pnr_number || "Not Available"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={classes.welcomeText} style={{ margin: "30px 0px", fontWeight: "700" }} align="center">
                      {this.state?.oneWayFlightList[0]?.depature_datetime && moment(`${this.state?.oneWayFlightList[0]?.depature_datetime}`).format("DD/MM/YYYY")} {this.state?.twoWayFlightList[0]?.depature_datetime && '&'} {this.state?.twoWayFlightList[0]?.depature_datetime && moment(`${this.state?.twoWayFlightList[0]?.depature_datetime}`).format("DD/MM/YYYY")} {(this?.state?.fareSummaryDetail?.adult > 0 || this?.state?.fareSummaryDetail?.child > 0 || this?.state?.fareSummaryDetail?.infents > 0) && `|`} {this?.state?.fareSummaryDetail?.adult > 0 && `${this?.state?.fareSummaryDetail?.adult} Adults`} {(this?.state?.fareSummaryDetail?.adult > 0 && this?.state?.fareSummaryDetail?.child > 0) && `,`} {this?.state?.fareSummaryDetail?.child > 0 && `${this?.state?.fareSummaryDetail?.child} Children`} {(this?.state?.fareSummaryDetail?.adult > 0 && this?.state?.fareSummaryDetail?.infents > 0) && `,`} {this?.state?.fareSummaryDetail?.infents > 0 && `${this?.state?.fareSummaryDetail?.infents} Infant`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.welcomeText} style={{ marginBottom: "60px" }} align="center">
                      <span style={{ borderBottom: "1px solid rgba(255,255,255, 1)" }}>Need Help? Click <Link to="#">here</Link></span>
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography align="center" className={classes.sourceName}>
                          {this.state.flightDetail?.depature_city || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography align="center" className={classes.sourceName}>
                          to
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography align="center" className={classes.sourceName}>
                          {this.state.flightDetail?.arrival_city || "NA"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.fromCity?.name}
                          Indira Gandhi Intl Airport
                          {this.state.flightDetail?.boarding_terminal || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          ○─○
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          Chhatrapati Shivaji Intl Airport
                          {this.state.flightDetail?.arriving_terminal || "NA"}
                          {this.state?.toCity?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  {/* <Grid item xs={10}>
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={10} md={3} style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        onClick={()=>{this.getTikcet()}}
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          color: "blue",
                          borderRadius: "50px",
                          fontFamily: "Open Sans",
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          color: "#1565d8",
                        }}
                      >
                        Download Tickets
                      </Button>
                    </Grid>
                    <Grid item xs={10} md={2} style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#fff",
                          color: "blue",
                          textAlign: "center",
                          borderRadius: "50px",
                          fontFamily: "Open Sans",
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          color: "#1565d8",
                        }}
                      >
                        Get Help
                      </Button>
                    </Grid>
                  </Grid>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          {/* End hero unit */}
          <Grid container justify="center" spacing={6} className={classes.myBookingFlightBackground}>
            <Grid md={7} xs={11} item>
              <Grid container spacing={6}>
                {/* <Grid item xs={12}>
                  <Grid container spacing={2} className={classes.flightBox}>
                    <Grid item xs={12}>
                       <Typography
                        color="textPrimary"
                        className={classes.groteskBold32}
                      >
                        {this.state.selectedTravellingType === "twoway"
                          ? "Depature Flight Information"
                          : "Flight Information"}
                      </Typography> 
                    </Grid>
                    <Grid item md={12} xs={12}>
                    <Divider className={classes.dividerStyle} />
                    </Grid>
                    {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight,index)=>{
                      return  <>
                      <Grid key={flight?.flight_number} item xs={12}>
                      <Grid container spacing={6}>
                        <Grid md={4} xs={12} item>
                          <ListItem style={{ padding: 0 }}>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                src={
                                  flight?.flight_logo 
                                }
                                style={{ marginRight: "10px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              classes={{
                                primary: classes.groteskBold24,
                                secondary: classes.openSans18,
                              }}
                              primary={
                                flight?.flight_name || "NA"
                              }
                              secondary={`${
                                flight?.flight_carrier || "-"
                              } | ${flight?.flight_number || "-"}`}
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={12} md={8} item>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="left"
                              >
                                {(flight?.depature_datetime &&
                                  moment(
                                    flight?.depature_datetime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="left"
                              >
                                {" "}
                                Departure{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="left"
                              >
                                {(flight?.depature_datetime &&
                                  moment(
                                    (
                                      flight?.depature_datetime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.linewraper}>
                                <Typography
                                  color="primary"
                                  className={classes.groteskBold18}
                                  style={{
                                    lineHeight: 0,
                                  }}
                                >
                                  {this.timeDiff(flight?.depature_datetime,flight?.arrival_datetime) || "NA"}
                                </Typography>
                                <ul className={classes.container}>
                                  <li className={classes.linkconnector} />
                                  <li className={classes.linkconnector} />
                                </ul>
                                
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                {(flight?.arrival_datetime &&
                                  moment(
                                    flight?.arrival_datetime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                Arrival{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="right"
                              >
                                {(flight?.arrival_datetime &&
                                  moment(
                                    (
                                      flight?.arrival_datetime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="space-between">
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.boarding_terminal ||
                                    "NA"}
                                </Typography>
                                <Typographyvf
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.depature_city ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.arriving_terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.arrival_city ||
                                    "NA"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} container justify="space-between">                                                       
                            </Grid>
                            <Grid item xs={12}>
                                <Typography display="inline">
                              {`To read ${flight?.flight_name} Terms and Conditions policy,`} 
                                </Typography>
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.getPoliciesAndInformation(
                                      flight?.fare_rule_key,
                                      flight?.fare_info_key
                                    );
                                  }}
                                >
                                  { `click here`}
                                </Link>
                           </Grid> 
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                      {index < (this.state.oneWayFlightList.length -1) && 
                      <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}>
                        <Grid item xs={12} className={classes.Diveder}>
                      <Divider variant="fullWidth" />
                    </Grid>
                    
                    <Grid item  xs={12} className={classes.DivederStyle}>
                      <Divider variant="fullWidth" />
                    </Grid></Grid>} 
                 </> 
                    })}
                   </Grid>
                </Grid>  */}
                {/* {this.state.selectedTravellingType === "twoway" ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.flightBox}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={classes.groteskBold32}
                        >
                          Return Flight Information
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Divider variant="inset" />
                      </Grid>
                      {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight,index)=>{
                      return  <>
                      <Grid key={flight?.flight_number} item xs={12}>
                      <Grid container spacing={6}>
                        <Grid md={4} xs={12} item>
                          <ListItem style={{ padding: 0 }}>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                src={
                                  flight?.flight_logo 
                                }
                                style={{ marginRight: "10px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              classes={{
                                primary: classes.groteskBold24,
                                secondary: classes.openSans18,
                              }}
                              primary={
                                flight?.flight_name || "NA"
                              }
                              secondary={`${
                                flight?.flight_carrier || "-"
                              } | ${flight?.flight_number || "-"}`}
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={12} md={8} item>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="left"
                              >
                                {(flight?.depature_datetime &&
                                  moment(
                                    flight?.depature_datetime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="left"
                              >
                                {" "}
                                Departure{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="left"
                              >
                                {(flight?.depature_datetime &&
                                  moment(
                                    (
                                      flight?.depature_datetime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.linewraper}>
                                <Typography
                                  color="primary"
                                  className={classes.groteskBold18}
                                  style={{
                                    lineHeight: 0,
                                  }}
                                >
                                  {this.timeDiff(flight?.depature_datetime,flight?.arrival_datetime) || "NA"}
                                </Typography>
                                <ul className={classes.container}>
                                  <li className={classes.linkconnector} />
                                  <li className={classes.linkconnector} />
                                </ul>
                                
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                {(flight?.arrival_datetime &&
                                  moment(
                                    flight?.arrival_datetime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                Arrival{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="right"
                              >
                                {(flight?.arrival_datetime &&
                                  moment(
                                    (
                                      flight?.arrival_datetime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="space-between">
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.boarding_terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.depature_city ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.arriving_terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.arrival_city ||
                                    "NA"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} container justify="space-between">                           
                            </Grid>
                            <Grid item xs={12}>
                                <Typography display="inline">
                              {`To read ${flight?.flight_name} Terms and Conditions policy,`} 
                                </Typography>
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.getPoliciesAndInformation(
                                      flight?.fare_rule_key,
                                      flight?.fare_info_key
                                    );
                                  }}
                                >
                                  { `click here`}
                                </Link>
                           </Grid> 
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                      {index < (this.state.twoWayFlightList.length -1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid><Grid item md={6} xs={6}>
                      <Paper style={{backgroundColor : '#1565d8',padding : '10px',width : '100%'}}>
                          <Typography align="center" style={{color : '#fff'}}>Change of plane {this.state.twoWayStops[index].stopDuration} in  {this.state.twoWayStops[index].stopName}</Typography>           
                      </Paper>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid></Grid>} 
                 </>})}
                     </Grid>
                  </Grid>
                ) : null}  */}

                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Flight Information
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      {this.state?.oneWayFlightList?.length > 0 && this.state.oneWayFlightList.map((flight, index) => {
                        return <>
                          <Grid key={flight?.FlightNumber} item xs={12}>
                            <Grid xs={12} item style={{ marginBottom: "20px" }}>
                              <ListItem style={{ padding: 0 }}>
                                <ListItemAvatar>
                                  <img
                                    className={classes.flightImage}
                                    src={
                                      flight?.flight_logo
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  classes={{
                                    primary: classes.flightName,
                                    secondary: classes.flightCode,
                                  }}
                                  primary={
                                    flight?.flight_name || "NA"
                                  }
                                  secondary={`${flight?.flight_carrier || "-"
                                    }${flight?.flight_number || "-"}`}
                                />
                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.fareSummaryDetail?.currency} ${this.state.fareSummaryDetail?.total_price}` || 0} />
                              </ListItem>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                            <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {(flight?.depature_datetime &&
                                      moment(
                                        flight?.depature_datetime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Departure{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.depature_datetime &&
                                      moment(
                                        (
                                          flight?.depature_datetime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.boarding_terminal ||
                                      "NA"}{", "} {flight?.depature_city ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                  <div className={classes.linewraper}>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffTime}
                                    >
                                      {this.timeDiff(flight?.depature_datetime, flight?.arrival_datetime) || "NA"}
                                    </Typography>
                                    <ul className={classes.container}>
                                      <li className={classes.linkconnector} />
                                      <li className={classes.linkconnector} />
                                    </ul>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffStop}
                                    >
                                      {/* {flight?.Mode_Of_Flight} */}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {" "}
                                    {(flight?.arrival_datetime &&
                                      moment(
                                        flight?.arrival_datetime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Arrival{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.arrival_datetime &&
                                      moment(
                                        (
                                          flight?.arrival_datetime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.arriving_terminal ||
                                      "NA"}{", "} {flight?.arrival_city ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={12} item>
                              <Grid container justify="space-between" >
                                <Grid item xs={12} sm={8} md={8} container justify="space-between">
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                  {this.state?.fareSummaryDetail?.AirPriceInfo?.length > 0 && this.state?.fareSummaryDetail?.AirPriceInfo?.map((airPriceInfo, index) => {
                                    return <>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                    </>
                                  })
                                  }
                                </Grid>
                                <Grid item className={classes.flightInfoDivider}>
                                  <Divider orientation="vertical" variant="middle" style={{ border: "0px solid rgba(195, 202, 217, 1)", color: "#C3CAD9" }} />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} alignItems="center">
                                  <ListItem
                                    style={{ padding: 0 }}
                                    alignItems="center"
                                  >
                                    <ListItemText
                                      primary={`Class : ${flight?.baggage_cabin}`}
                                      secondary={
                                        "Full Refund Available"
                                      }
                                      classes={{
                                        primary: classes.flightClassText,
                                        secondary: classes.flightClassRefund,
                                      }}
                                    />
                                  </ListItem>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index < (this.state?.oneWayFlightList?.length - 1) &&
                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                              <Divider className={classes.dividerStyle} />
                              <Typography align="center" className={classes.layoverText}> Layover {this.state.oneWayStops[index].stopDuration} | {this.state.oneWayStops[index].stopName} ({flight?.arrival_iata})</Typography>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                          }
                        </>
                      })}
                    </Grid>
                    {this.state?.selectedTravellingType === "twoway" && <Grid item md={12} xs={12}>
                      <Divider style={{ margin: "0px 20px", border: "1px dashed #1565d8" }} />
                    </Grid>}
                    {this.state?.twoWayFlightList?.length > 0 && this.state?.twoWayFlightList?.map((flight, index) => {
                      return <>
                        <Grid item xs={12} className={classes.flightBoxDetails}>
                          <Grid key={flight?.flight_number} item xs={12}>
                            <Grid xs={12} item style={{ marginBottom: "20px" }}>
                              <ListItem style={{ padding: 0 }}>
                                <ListItemAvatar>
                                  <img
                                    className={classes.flightImage}
                                    src={
                                      flight?.flight_logo
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  classes={{
                                    primary: classes.flightName,
                                    secondary: classes.flightCode,
                                  }}
                                  primary={
                                    flight?.flight_name || "NA"
                                  }
                                  secondary={`${flight?.flight_carrier || "-"
                                    }${flight?.flight_number || "-"}`}
                                />
                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.fareSummaryDetail?.currency} ${this.state.fareSummaryDetail?.total_price}` || 0} />
                              </ListItem>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                            <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {(flight?.depature_datetime &&
                                      moment(
                                        flight?.depature_datetime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Departure{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.depature_datetime &&
                                      moment(
                                        (
                                          flight?.depature_datetime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.boarding_terminal ||
                                      "NA"}{", "} {flight?.depature_city ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                  <div className={classes.linewraper}>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffTime}
                                    >
                                      {this.timeDiff(flight?.depature_datetime, flight?.arrival_datetime) || "NA"}
                                    </Typography>
                                    <ul className={classes.container}>
                                      <li className={classes.linkconnector} />
                                      <li className={classes.linkconnector} />
                                    </ul>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffStop}
                                    >
                                      {/* {flight?.Mode_Of_Flight} */}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {" "}
                                    {(flight?.arrival_datetime &&
                                      moment(
                                        flight?.arrival_datetime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Arrival{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.arrival_datetime &&
                                      moment(
                                        (
                                          flight?.arrival_datetime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.arriving_terminal ||
                                      "NA"}{", "} {flight?.arrival_city ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={12} item>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={8} md={8} container justify="space-between">
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Baggage</Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Check-In</Grid>
                                  <Grid item xs={4} sm={4} md={4} className={classes.flightDetailText}>Cabin</Grid>

                                  {this.state?.fareSummaryDetail?.AirPriceInfo?.length > 0 && this.state?.fareSummaryDetail?.AirPriceInfo?.map((airPriceInfo, index) => {
                                    return <>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.type === "ADT" ? "Adult" : airPriceInfo?.type === "CNN" ? "Child" : airPriceInfo?.type === "INF" ? "Infant" : "NA"}</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                      <Grid item xs={4} sm={4} md={4} className={classes.flightDetailValue}>{airPriceInfo?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"}</Grid>
                                    </>
                                  })
                                  }
                                </Grid>
                                <Grid item className={classes.flightInfoDivider}>
                                  <Divider orientation="vertical" variant="middle" style={{ border: "0px solid rgba(195, 202, 217, 1)", color: "#C3CAD9" }} />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} alignItems="center">
                                  <ListItem
                                    style={{ padding: 0 }}
                                    alignItems="center"
                                  >
                                    <ListItemText
                                      primary={`Class : ${flight?.baggage_cabin}`}
                                      secondary={
                                        "Full Refund Available"
                                      }
                                      classes={{
                                        primary: classes.flightClassText,
                                        secondary: classes.flightClassRefund,
                                      }}
                                    />
                                  </ListItem>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index < (this.state?.twoWayFlightList?.length - 1) &&
                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                              <Divider className={classes.dividerStyle} />
                              <Typography align="center" className={classes.layoverText}> Layover {this.state.twoWayStops[index].stopDuration} | {flight?.arrival_city} ({this.state.twoWayStops[index].stopName})</Typography>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                          }
                        </Grid>
                      </>
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Refund Policy
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid item xs={12}>
                        <ListItem style={{ padding: 0, marginBottom: "15px" }}>
                          <ListItemAvatar>
                            <img src={alertIcon} className={classes.paraImage} />
                          </ListItemAvatar>
                          <ListItemText classes={{ primary: classes.paraHeading }} primary="Non Refundable Policy" />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.paraText}>
                          Sunt autem quidam e nostris, qui studiose antiqua
                          persequeris, claris et fortibus viris. Quae fuerit causa,
                          mox videro; interea hoc tenebo, si ob rem voluptas sit.
                          Sed ut de homine sensibus reliqui nihil ut alterum
                          aspernandum sentiamus alii autem.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Important Information
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid item xs={12}>
                        <ListItem style={{ padding: 0, marginBottom: "15px" }}>
                          <ListItemAvatar>
                            <img src={covidIcon} className={classes.paraImage} />
                          </ListItemAvatar>
                          <ListItemText classes={{ primary: classes.paraHeading }} primary="COVID- 19 Related" />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.paraText}>
                          Sunt autem quidam e nostris, qui studiose antiqua
                          persequeris, claris et fortibus viris. Quae fuerit causa,
                          mox videro; interea hoc tenebo, si ob rem voluptas sit.
                          Sed ut de homine sensibus reliqui nihil ut alterum
                          aspernandum sentiamus alii autem.
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <ListItem style={{ padding: 0, marginBottom: "15px", marginTop: "30px" }}>
                          <ListItemAvatar>
                            <img src={suitcaseIcon} className={classes.paraImage} />
                          </ListItemAvatar>
                          <ListItemText classes={{ primary: classes.paraHeading }} primary="Baggage Policy" />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.paraText}>
                          Sunt autem quidam e nostris, qui studiose antiqua
                          persequeris, claris et fortibus viris. Quae fuerit causa,
                          mox videro; interea hoc tenebo, si ob rem voluptas sit.
                          Sed ut de homine sensibus reliqui nihil ut alterum
                          aspernandum sentiamus alii autem.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid md={4} xs={11} item>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Fare Summary
                      </Typography>
                    </Grid>
                    <Grid md={12} xs={12} item>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid container justify={"space-between"}>
                        <Grid item xs={12}>
                          <List style={{ padding: "0px" }}>
                            {this.state?.fareSummaryLst?.length > 0 && this.state?.fareSummaryLst?.map((fare, index) => {
                              return <ListItem dense={true} key={index} style={{ padding: "0px", marginBottom: "20px" }}>
                                <ListItemText style={{ margin: "0px" }}
                                  primary={`${fare.name} (${fare.count} X ${this.state?.fareSummaryDetail?.currency} ${fare.basePrice})`}
                                  secondary="Base Fare"
                                  classes={{
                                    primary: classes.flightCharge,
                                    secondary: classes.flightCharge,
                                  }}
                                />
                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                  <Typography className={classes.flightValue}>
                                    {fare &&
                                      `${this.state?.fareSummaryDetail?.currency} ${fare.toalPrice}`}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItem>
                            })}
                            <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                              <ListItemText style={{ margin: "0px" }}
                                primary="Fee & Surcharges"
                                secondary="Total fee & surcharges"
                                classes={{
                                  primary: classes.flightCharge,
                                  secondary: classes.flightCharge,
                                }}
                              />
                              <ListItemSecondaryAction style={{ right: "0px" }}>
                                <Typography className={classes.flightValue}>
                                  {this.state.fareSummaryDetail &&
                                    `${this.state?.fareSummaryDetail?.currency} ${this.state.fareSummaryDetail.total_tax}`}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                            {this.state.couponDiscount && (
                              <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                                <ListItemText style={{ margin: "0px" }}
                                  //  primary="Coupon Discount"
                                  secondary="Discount"
                                  classes={{
                                    primary: classes.flightCharge,
                                    secondary: classes.flightCharge,
                                  }}
                                />
                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                  <Typography className={classes.flightValue} style={{ color: "#FF0000" }}>
                                    {`${this.state?.fareSummaryDetail?.currency} ${this.state.couponDiscount?.discount_price || "Not Available"}`}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}

                            <Divider className={classes.dividerStyle} />
                            <ListItem dense={true} style={{ marginTop: "20px" }}>
                              <ListItemText
                                classes={{
                                  primary: classes.flightTotalAmountText,
                                  // primary: classes.flightValue,
                                }}
                                primary={this.state?.role === 'Customer' ? 'Total Fare' : 'Total Amount Without Markup'}
                              />
                              <ListItemSecondaryAction style={{ right: "0px" }}>
                                {this.state?.role === 'Customer' ? (
                                  <Typography className={classes.flightValue}>
                                    {this.state.fareSummaryDetail &&
                                      `${this.state?.fareSummaryDetail?.currency} ${this.state.fareSummaryDetail.total_price}`}
                                  </Typography>
                                ) : (
                                  <Typography className={classes.flightValue}>
                                    {this.state.fareSummaryDetail &&
                                      `${this.state?.fareSummaryDetail?.currency} ${this.state.fareSummaryDetail.total_price}`}
                                  </Typography>
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justify="center"
                  >
                    {/* <Grid xs={6} item>
                <Button color="primary" variant="contained" onClick={()=>{this.downloadInvoice()}} fullWidth>
                  Get Invoice
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button color="primary" variant="contained" fullWidth>
                  Share
                </Button>
              </Grid> */}

                    <Grid item xs={12}>
                      <Button variant="outlined" className={classes.Modify} fullWidth onClick={() => { this.cancelBooking() }}>
                        Modify Or Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button color="primary" variant="contained" fullWidth onClick={() => { this.getTikcet() }}>
                        DOWNLOAD TICKET
                      </Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer marginTop={0}></Footer>
        </div>

      ))
    ) : null
  }
}
const MyBookingFlightDetailsWithRouter = withRouter(MyBookingFlightDetails);
const MyBookingFlightDetailsAlertBox = withAlertBox(
  MyBookingFlightDetailsWithRouter
);
const MyBookingFlightDetailsLoader = withLoader(MyBookingFlightDetailsAlertBox);
const MyBookingFlightDetailsToast = withToast(MyBookingFlightDetailsLoader);
const MyBookingFlightDetailsDialog = withDialog(MyBookingFlightDetailsToast)
export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    gridItem1: {
      order: 1,
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },
    },
    gridItem2: {
      order: 2,
      [theme.breakpoints.down("xs")]: {
        order: 6,
      },
    },
    gridItem3: {
      order: 3,
      [theme.breakpoints.down("xs")]: {
        order: 2,
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(-40),
      },
    },
    gridItem4: {
      order: 4,
      [theme.breakpoints.down("xs")]: {
        order: 3,
      },
    },
    gridItem5: {
      order: 5,
      [theme.breakpoints.up("sm")]: {
        order: 5,
      },
    },
    gridItem6: {
      order: 5,
      [theme.breakpoints.up("sm")]: {
        order: 6,
      },
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      backgroundImage: `url(${flightCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
    },
    bookedBy: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#ffffff",

    },
    welcomeText: {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      "& a:hover": {
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightDetailBox: {
      padding: theme.spacing(2),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    flightBox: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      // [theme.breakpoints.up('md')]: {
      //   height: '446px',
      // }
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    Modify: {
      color: 'Blue',
      // backgroundColor: "white",
      padding: '8px 20px;',
      borderRadius: '8px',
      border: '1px solid #1976d2',
      //  borderTop: 'aliceblue',
      fontSize: "14px",
      fontFamily: "Open Sans",
      fontWeight: "bold",
    },
    flightBoxDivider: {
      width: "611px",
      height: "1px",
      opacity: 0.15,
      backgroundColor: "#979797",
    },
    DivederStyle: {
      border: "none",
      borderTop: "1px dotted",
      height: "1px",
      width: "50%",
    },
    Diveder: {
      border: "none",
      borderTop: "1px normal",
      height: "1px",
      width: "50%",
      marginLeft: "none",

    },
    reviewFlightHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    // flight one 
    flightBox: {
      borderRadius: "5px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      backgroundColor: "rgba(255,255,255, 1)",
    },
    reviewFlightHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    flightBoxDetails: {
      padding: "20px",
    },
    flightImage: {
      width: "75px",
      height: "75px",
      marginRight: "30px",
      [theme.breakpoints.only("xs")]: {
        width: "60px",
        height: "60px",
        marginRight: "15px",
      },
    },
    flightName: {
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#1E394E",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
        fontWeight: "600",
      },
    },
    flightCode: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAalign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightTotalPrice: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
      },
    },
    departureSection: {
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "20px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "20px",
      },
    },
    arrivalSection: {
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "24px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px 0px"
    },
    // floight two
    myBookingFlightBackground: {
      paddingTop: "60px",
      paddingBottom: "100px",
      backgroundColor: '#F6F9FF',
    },
    flightCharge: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightValue: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    flightTotalAmountText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "600",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    paraImage: {
      height: "27px",
      width: "27px",
      marginRight: "20px",
      display: "flex",
    },
    paraHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      marginLeft: "-10px",
      letterSpacing: "0px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    paraText: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightDetailText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "17px",
      fontWeight: "700",
      fontWtyle: "normal",
      letterSpacing: "0px",
      marginBottom: "10px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    flightDetailValue: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "15px",
      fontWeight: "500",
      fontStyle: "normal",
      marginBottom: "5px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    flightClassText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightClassRefund: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0.11px",
      lineHeight: "24px",
    },
    flightInfoDivider: {
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },
  })
)(withFontStyles(MyBookingFlightDetailsDialog));
