//@ts-nocheck
import React from "react";
import AddAddonsController, { Props } from "./AddAddonsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  Button,
  Paper,
  Divider,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  List,
  ListItemText,
  Checkbox,
  MenuItem,
  Avatar,
  FormGroup,
  FormControl,
  FormControlLabel,
  TextField,

} from "@material-ui/core";

import moment from "moment";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import MainFlightPostWeb from "./MainFlightPost.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withFontStyles from "./withFontStyles.web";

import { flighPlaceHolder, flightCover, addInsuranceIcon, addSeatIcon, addFlightIcon } from "./assets";
import { Redirect, RouterProps, Route } from "react-router-dom";
import Header from "./Header.web";
import Footer from "./Footer.web";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { Formik, Form, Field } from "formik";
import InputFieldWithFormikWeb from "../../../components/src/InputFieldWithFormik.web";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const curDate = moment(new Date()).format("YYYY-MM-DD").toString();

class AddAddons extends AddAddonsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    let tokenKeyName = "";
    if (state?.role === "Admin") {
      tokenKeyName = "adminToken";
    } else if (state?.role === "Agency") {
      tokenKeyName = "agancyToken";
    } else {
      tokenKeyName = "authToken";
    }
    const authToken = await StorageProvider.get(tokenKeyName);
    let profileData = await StorageProvider.get("profileData");
    profileData = profileData && JSON.parse(profileData) || {};
    if (
      authToken &&
      state?.role &&
      state?.entityType &&
      state?.fromCity &&
      state?.toCity &&
      state?.searchData &&
      state?.booking_id &&
      state?.travaller_id,
      state?.travellerInfo
    ) {
      this.setState(
        {
          authToken: authToken,
          entityType: state?.entityType,
          fromCity: state?.fromCity,
          toCity: state?.toCity,
          searchData: state?.searchData,
          entityDetail: state?.entityDetail,
          loading: false,
          booking_id: state?.booking_id,
          travaller_id: state?.travaller_id,
          role: state?.role,
          noOfTravellers: state?.noOfTravellers,
          selectedTravellingType: state?.selectedTravellingType,
          selectedFlight2: state?.selectedFlight2,
          currency: profileData.currency || "USD",
          travellerInfo: state?.travellerInfo,
          couponDiscount: state?.couponDiscount
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getFareSummary(this.state.searchData,
              this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              this.state.entityDetail?.FareBasisCode
            );
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getFareSummary(this.state.searchData, [
              ...this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              ...this.state.selectedFlight2?.mergeAirSagmentBookingFlightInfo,
            ], this.state.entityDetail?.FareBasisCode);
          }
          // this.getFlightDetail()
          // if(this.state.selectedTravellingType === 'twoway'){
          //   this.getReturnFlightDetail();
          // }
        }
      );
    } else {
      this.setState({
        loading: false,
        authToken: null,
      });
    }
  }

  handleInsurancce = (name) => {
    if (this.state.selectedInsurance.indexOf(name) > -1) {
      const selectedInsurance = this.state.selectedInsurance.filter((ins) => {
        return ins !== name;
      });
      this.setState({
        selectedInsurance: selectedInsurance,
      });
    } else {
      this.setState({
        selectedInsurance: [...this.state.selectedInsurance, name],
      });
    }
  };

  handleUpgrades = (name) => {
    if (this.state.selectedUpgrades.indexOf(name) > -1) {
      const selectedUpgrades = this.state.selectedUpgrades.filter((ins) => {
        return ins !== name;
      });
      this.setState({
        selectedUpgrades: selectedUpgrades,
      });
    } else {
      this.setState({
        selectedUpgrades: [...this.state.selectedUpgrades, name],
      });
    }
  };

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  timeDiff = function (date1: any, date2: any) {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {
        milliseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0
      };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if (extraMin > 0) {
      return Math.floor(diff.hours) + " hr" + " " + extraMin + " " + " min"
    } else {
      return Math.floor(diff.hours) + " hr"
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };
  handleClose = () => {
    this.setState({ open: false })

  };

  render() {
    const { classes } = this.props;
    const { searchData } = this.state;

    const noOfTravellers =
      (+searchData.adults || 0) +
      (+searchData.children || 0) +
      (+searchData.infants || 0);
    let redirectRouteName = "/";
    if (this.state.role === "Admin") {
      redirectRouteName = "/AdminLogin";
    } else if (this.state.role === "Agency") {
      redirectRouteName = "/AgencyMarketing";
    }

    return !this.state.loading ? (
      this.state.authToken === null ? (
        <>
          <Redirect to={redirectRouteName} />
        </>

      ) : (
        <div className={classes.root}>
          <Paper
            className={classes.mainPost}
            style={{
              backgroundImage: `url(${flightCover})`,
              backgroundSize: "cover",
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={flightCover}
                alt={"aaaaaaaaaaaaaaaa"}
              />
            }
            <div className={classes.overlay} />
            {this.state.role && <Header role={this.state.role} />}
            <MainFlightPostWeb fromCity={this.state?.fromCity} toCity={this.state?.toCity} selectedTravellingType={this.state?.selectedTravellingType} searchData={this.state?.searchData} />

            {/* old code */}
            {/* <Grid container justify="center">
              <Grid item md={8} xs={10}>
                <Grid
                  container
                  spacing={4}
                  justify="center"
                  className={classes.mainPostContent}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.helloAdmin} align="center">
                      Addons
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <LinearProgress
                      classes={{ root: classes.linearProgressBar }}
                      variant="determinate"
                      value={75}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}

                        >
                          Flight Selection
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Review
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Traveller Details
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Make Payment
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.fromCity?.city}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          to
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.toCity?.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.fromCity?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          ○─○
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.toCity?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Paper>
          <Grid container justify="center" spacing={6} className={classes.addAddonsPage}>
            <Grid item md={7} xs={11}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Flight Information
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight, index) => {
                        const selectedSeat = this.state.seletedSeats.filter((seat) => { return seat.SegmentRef === flight.Key })
                        return <>
                          <Grid key={flight?.FlightNumber} item xs={12}>
                            <Grid xs={12} item style={{ marginBottom: "20px" }}>
                              <ListItem style={{ padding: 0 }}>
                                <ListItemAvatar>
                                  <img
                                    className={classes.flightImage}
                                    src={
                                      flight?.Flight_Image
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  classes={{
                                    primary: classes.flightName,
                                    secondary: classes.flightCode,
                                  }}
                                  primary={
                                    flight?.Flight_Name || "NA"
                                  }
                                  secondary={`${flight?.Flight_carrier || "-"
                                    }${flight?.Flight_Id || "-"}`}
                                />
                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state.fareSummaryDetail.total_price}` || 0} />
                              </ListItem>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                            {/* <Grid item xs={12} className={classes.seatMapButton}>
                              <Button variant="contained" color="primary" onClick={() => { this.getSeatData(flight, selectedSeat) }}>{selectedSeat.length > 0 ? 'Change Seat Selection ' : 'Seat Selection'} {selectedSeat.length > 0 && <Typography>({selectedSeat.map((seat)=>{return seat.SeatId}).join(",")})</Typography>}</Button>
                            </Grid> */}
                            <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {(flight?.Depature_DateTime &&
                                      moment(
                                        flight?.Depature_DateTime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Departure{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.Depature_DateTime &&
                                      moment(
                                        (
                                          flight?.Depature_DateTime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                      "NA"}{", "} {flight?.Depature_City ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                  <div className={classes.linewraper}>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffTime}
                                    >
                                      {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                    </Typography>
                                    <ul className={classes.container}>
                                      <li className={classes.linkconnector} />
                                      <li className={classes.linkconnector} />
                                    </ul>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffStop}
                                    >
                                      {/* {flight?.Mode_Of_Flight} */}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {" "}
                                    {(flight?.Arrival_DateTime &&
                                      moment(
                                        flight?.Arrival_DateTime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Arrival{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.Arrival_DateTime &&
                                      moment(
                                        (
                                          flight?.Arrival_DateTime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                      "NA"}{", "} {flight?.Arrival_City ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index < (this.state.oneWayFlightList.length - 1) &&
                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                              <Divider className={classes.dividerStyle} />
                              <Typography align="center" className={classes.layoverText}> Layover {this.state.entityDetail?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.entityDetail?.stops[index].stopName})</Typography>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                          }
                        </>
                      })}
                    </Grid>
                    {this.state?.selectedTravellingType === "twoway" && <Grid item md={12} xs={12}>
                      <Divider style={{ margin: "0px 20px", border: "1px dashed #1565d8" }} />
                    </Grid>}
                    {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight, index) => {
                      const selectedSeat = this.state.seletedSeats.filter((seat) => { return seat.SegmentRef === flight.Key })
                      return <>
                        <Grid item xs={12} className={classes.flightBoxDetails}>
                          <Grid key={flight?.FlightNumber} item xs={12}>
                            <Grid xs={12} item style={{ marginBottom: "20px" }}>
                              <ListItem style={{ padding: 0 }}>
                                <ListItemAvatar>
                                  <img
                                    className={classes.flightImage}
                                    src={
                                      flight?.Flight_Image
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  classes={{
                                    primary: classes.flightName,
                                    secondary: classes.flightCode,
                                  }}
                                  primary={
                                    flight?.Flight_Name || "NA"
                                  }
                                  secondary={`${flight?.Flight_carrier || "-"
                                    }${flight?.Flight_Id || "-"}`}
                                />
                                <ListItemText classes={{ primary: classes.flightTotalPrice }} primary={`${this.state?.currency} ${this.state.fareSummaryDetail.total_price}` || 0} />
                              </ListItem>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                            {/* <Grid item xs={12} className={classes.seatMapButton}>
                              <Button variant="contained" color="primary" onClick={() => { this.getSeatData(flight, selectedSeat) }}>{selectedSeat.length > 0 ? 'Change Seat Selection ' : 'Seat Selection'} {selectedSeat.length > 0 && <Typography>({selectedSeat.map((seat)=>{return seat.SeatId}).join(",")})</Typography>}</Button>
                            </Grid> */}
                            <Grid xs={12} item style={{ marginBottom: "10px", marginTop: "10px" }}>
                              <Grid
                                container
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={4} md={4} className={classes.departureSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {(flight?.Depature_DateTime &&
                                      moment(
                                        flight?.Depature_DateTime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Departure{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.Depature_DateTime &&
                                      moment(
                                        (
                                          flight?.Depature_DateTime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.Boarding_Terminal && `Terminal ${flight?.Boarding_Terminal}` ||
                                      "NA"}{", "} {flight?.Depature_City ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                  <div className={classes.linewraper}>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffTime}
                                    >
                                      {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                    </Typography>
                                    <ul className={classes.container}>
                                      <li className={classes.linkconnector} />
                                      <li className={classes.linkconnector} />
                                    </ul>
                                    <Typography
                                      color="primary"
                                      className={classes.flightDiffStop}
                                    >
                                      {/* {flight?.Mode_Of_Flight} */}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={classes.arrivalSection}>
                                  <Typography
                                    className={[
                                      classes.flightDate,
                                    ]}
                                  >
                                    {" "}
                                    {(flight?.Arrival_DateTime &&
                                      moment(
                                        flight?.Arrival_DateTime
                                      ).format("ddd, DD MMM")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={classes.flightPlaceMode}
                                  >
                                    {" "}
                                    Arrival{" "}
                                  </Typography>
                                  <Typography
                                    className={classes.flightTime}
                                    color="textSecondary"
                                  >
                                    {(flight?.Arrival_DateTime &&
                                      moment(
                                        (
                                          flight?.Arrival_DateTime
                                        )
                                      ).format("hh:mm A")) ||
                                      "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.flightAddress,
                                    ]}
                                    color="textPrimary"
                                  >
                                    {" "}
                                    {flight?.Arriving_Terminal && `Terminal ${flight?.Arriving_Terminal}` ||
                                      "NA"}{", "} {flight?.Arrival_City ||
                                        "NA"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index < (this.state.twoWayFlightList.length - 1) &&
                            <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
                              <Divider className={classes.dividerStyle} />
                              <Typography align="center" className={classes.layoverText}> Layover {this.state.selectedFlight2?.stops[index].stopDuration} | {flight?.Arrival_City} ({this.state.selectedFlight2?.stops[index].stopName})</Typography>
                              <Divider className={classes.dividerStyle} />
                            </Grid>
                          }
                        </Grid>
                      </>
                    })}
                  </Grid>
                </Grid>
                {/* {this.state.selectedTravellingType === "twoway" ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.flightBox}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={classes.groteskBold32}
                        >
                          Return Flight Information
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Divider variant="inset" />
                      </Grid>
                      {this.state.twoWayFlightList.length > 0 && this.state.twoWayFlightList.map((flight, index) => {
                        const selectedSeat = this.state.seletedSeats.find((seat) => { seat.SegmentRef === flight.Key })
                        return <>
                          <Grid key={flight?.FlightNumber} item xs={12}>
                            <Grid container spacing={6}>
                              <Grid md={4} xs={12} item>
                                <ListItem style={{ padding: 0 }}>
                                  <ListItemAvatar>
                                    <img
                                      className={classes.flightImage}
                                      src={
                                        flight?.Flight_Image
                                      }
                                      style={{ marginRight: "10px" }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    classes={{
                                      primary: classes.groteskBold24,
                                      secondary: classes.openSans18,
                                    }}
                                    primary={
                                      flight?.Flight_Name || "NA"
                                    }
                                    secondary={`${flight?.Flight_carrier || "-"
                                      } | ${flight?.Flight_Id || "-"}`}
                                  />
                                </ListItem>
                              </Grid>
                              <Grid xs={12} md={8} item>
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                >
                                  <Grid item xs={4}>
                                    <Typography
                                      className={[
                                        classes.openSans20,
                                        classes.gutterBottom2,
                                      ]}
                                      color="textPrimary"
                                      align="left"
                                    >
                                      {(flight?.Depature_DateTime &&
                                        moment(
                                          flight?.Depature_DateTime
                                        ).format("ddd, DD MMM")) ||
                                        "NA"}
                                    </Typography>
                                    <Typography
                                      className={classes.groteskBold18}
                                      color="textPrimary"
                                      align="left"
                                    >
                                      {" "}
                                      Departure{" "}
                                    </Typography>
                                    <Typography
                                      className={classes.openSans16}
                                      color="textSecondary"
                                      align="left"
                                    >
                                      {(flight?.Depature_DateTime &&
                                        moment(
                                          (
                                            flight?.Depature_DateTime
                                          )
                                        ).format("hh:mm A")) ||
                                        "NA"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <div className={classes.linewraper}>
                                      <Typography
                                        color="primary"
                                        className={classes.groteskBold18}
                                        style={{
                                          lineHeight: 0,
                                        }}
                                      >
                                        {this.timeDiff(flight?.Depature_DateTime, flight?.Arrival_DateTime) || "NA"}
                                      </Typography>
                                      <ul className={classes.container}>
                                        <li className={classes.linkconnector} />
                                        <li className={classes.linkconnector} />
                                      </ul>

                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography
                                      className={[
                                        classes.openSans20,
                                        classes.gutterBottom2,
                                      ]}
                                      color="textPrimary"
                                      align="right"
                                    >
                                      {" "}
                                      {(flight?.Arrival_DateTime &&
                                        moment(
                                          flight?.Arrival_DateTime
                                        ).format("ddd, DD MMM")) ||
                                        "NA"}
                                    </Typography>
                                    <Typography
                                      className={classes.groteskBold18}
                                      color="textPrimary"
                                      align="right"
                                    >
                                      {" "}
                                      Arrival{" "}
                                    </Typography>
                                    <Typography
                                      className={classes.openSans16}
                                      color="textSecondary"
                                      align="right"
                                    >
                                      {(flight?.Arrival_DateTime &&
                                        moment(
                                          (
                                            flight?.Arrival_DateTime
                                          )
                                        ).format("hh:mm A")) ||
                                        "NA"}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} container justify="space-between">
                                    <Grid item xs={6}>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterTop2,
                                        ]}
                                        color="textPrimary"
                                        align="left"
                                      >
                                        {" "}
                                        {flight?.Boarding_Terminal ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterBottom2,
                                        ]}
                                        color="textPrimary"
                                        align="left"
                                      >
                                        {" "}
                                        {flight?.Depature_City ||
                                          "NA"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterTop2,
                                        ]}
                                        color="textPrimary"
                                        align="right"
                                      >
                                        {" "}
                                        {flight?.Arriving_Terminal ||
                                          "NA"}
                                      </Typography>
                                      <Typography
                                        className={[
                                          classes.openSans16,
                                          classes.gutterBottom2,
                                        ]}
                                        color="textPrimary"
                                        align="right"
                                      >
                                        {" "}
                                        {flight?.Arrival_City ||
                                          "NA"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid xs={12} container justify="space-between">
                                    <Grid xs={12} item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        justify="space-between"
                                      >
                                        <Grid item xs={4}>
                                          <ListItem
                                            style={{ padding: 0 }}
                                            alignItems="center"
                                          >
                                            <ListItemText
                                              primary="Baggage"
                                              secondary={
                                                this.state?.AirPriceInfo[0]?.type || "NA"

                                              }
                                              classes={{
                                                primary: classes.groteskBold18,
                                                secondary: classes.openSans16,
                                              }}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <ListItem
                                            style={{ padding: 0 }}
                                            alignItems="center"
                                          >
                                            <ListItemText
                                              primary="Check-In"
                                              secondary={
                                                this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                              }
                                              classes={{
                                                primary: classes.groteskBold18,
                                                secondary: classes.openSans16,
                                              }}
                                            />
                                          </ListItem>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <ListItem
                                            style={{ padding: 0 }}
                                            alignItems="center"
                                          >
                                            <ListItemText
                                              primary="Cabin"
                                              secondary={
                                                this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                              }
                                              classes={{
                                                primary: classes.groteskBold18,
                                                secondary: classes.openSans16,
                                              }}
                                            />
                                          </ListItem>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={() => { this.getSeatData(flight, selectedSeat) }}>{selectedSeat ? 'Chnage Seat Selection' : 'Seat Selection'} {selectedSeat && <Typography>({selectedSeat.SeatId})</Typography>}</Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {index < (this.state.twoWayFlightList.length - 1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                            <Divider variant="fullWidth" />
                          </Grid><Grid item md={6} xs={6}>
                              <Paper style={{ backgroundColor: '#1565d8', padding: '10px', width: '100%' }}>
                                <Typography align="center" style={{ color: '#fff' }}>Change of plane {this.state.selectedFlight2?.stops[index].stopDuration} in  {this.state.selectedFlight2?.stops[index].stopName}</Typography>
                              </Paper>
                            </Grid>
                            <Grid item md={3} xs={3}>
                              <Divider variant="fullWidth" />
                            </Grid></Grid>}
                        </>
                      })}
                    </Grid>
                  </Grid>
                ) : null} */}
                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography className={classes.reviewFlightHeading}>
                        Flight Cancellation & Insurance
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.sectionHeading}
                          >
                            Get all the benefits for just ₹ 199 per traveller
                            (18% GST included)
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Grid container direction="row" justify="center" spacing={3}>
                            <Grid item md={6} sm={6} xs={12}>
                              <div
                                className={
                                  this.state.selectedInsurance.indexOf(
                                    "AddFlightCancellation"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleInsurancce(
                                    "AddFlightCancellation"
                                  );
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <FlightTakeoffIcon
                                    className={
                                      this.state.selectedInsurance.indexOf(
                                        "AddFlightCancellation"
                                      ) > -1
                                        ? classes.ImageSelected
                                        : classes.ImageBox
                                    }
                                  />
                                  {/* <img
                                   
                                    style={{
                                      display: "inline-block",
                                      height: "24px",
                                      width: "28px",
                                    }}
                                    src={addFlightIcon}
                                  /> */}
                                </div>
                                <Typography
                                  // className={classes.boxHeading}
                                  className={
                                    this.state.selectedInsurance.indexOf(
                                      "AddFlightCancellation"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxHeading
                                  }
                                  align="center"
                                >
                                  Add Flight Cancellation
                                </Typography>
                                <Typography
                                  align="center"
                                  // className={classes.boxSubHeading}
                                  className={
                                    this.state.selectedInsurance.indexOf(
                                      "AddFlightCancellation"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxSubHeading
                                  }
                                >
                                  Get Upto ₹ 3000
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                              <div
                                className={
                                  this.state.selectedInsurance.indexOf(
                                    "AddInsurance"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleInsurancce("AddInsurance");
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <Typography color='error'>
                                    <img
                                      style={{
                                        display: "inline-block",
                                        height: "24px",
                                        width: "25px",
                                      }}
                                      src={addInsuranceIcon}
                                    />
                                  </Typography>
                                </div>
                                <Typography
                                  align="center"
                                  // className={classes.boxHeading}
                                  className={
                                    this.state.selectedInsurance.indexOf(
                                      "AddInsurance"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxHeading
                                  }
                                >
                                  Add Insurance
                                </Typography>
                                <Typography
                                  align="center"
                                  // className={classes.boxSubHeading}
                                  className={
                                    this.state.selectedInsurance.indexOf(
                                      "AddInsurance"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxSubHeading
                                  }
                                >
                                  Get Upto ₹ 3000
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item md={10} xs={12}>
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginRight: 0 }}
                                control={<Checkbox name="gilad" />}
                                classes={{
                                  label: classes.sectionHeading,
                                }}
                                label="Some custom checkbox"
                              />
                              <FormControlLabel
                                style={{ marginBottom: 0, marginRight: 0 }}
                                control={<Checkbox name="gilad" />}
                                classes={{
                                  label: classes.sectionHeading,
                                }}
                                label="Some custom checkbox"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography className={classes.reviewFlightHeading}>
                        Add Upgrades
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid container >
                        <Grid item xs={12}>
                          <Typography
                            className={classes.sectionHeading}
                          >
                            Add upgrades to your flight to get the best out of
                            it
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item md={4} sm={4} xs={12}>
                              <div
                                // className={classes.addonBox}
                              className={
                                this.state.selectedUpgrades.indexOf(
                                  "AddSeatSelection"
                                ) > -1
                                  ? classes.addonBoxSelected
                                  : classes.addonBox
                              }
                              // onClick={() => {
                              //   this.handleUpgrades("AddSeatSelection");
                              //   this.getSeatData(this.state?.oneWayFlightList[0],this.state?.seletedSeats);
                              // }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{
                                      display: "inline-block",
                                      height: "29px",
                                      width: "31px",
                                    }}
                                    src={addSeatIcon}
                                  />
                                </div>
                                <Typography
                                  // className={classes.boxHeading}
                                  className={
                                    this.state.selectedUpgrades.indexOf(
                                      "AddSeatSelection"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxHeading
                                  }
                                  align="center"
                                  // onClick={() => {
                                  //   this.getSeatData()
                                  // }}
                                >
                                  {this.state?.seletedSeats?.length > 0 ? 'Change Seat Selection ' : 'Add Seat Selection'}
                                </Typography>
                                 {this.state?.seletedSeats?.length > 0 ? 
                                 <Typography className={
                                    this.state.selectedUpgrades.indexOf(
                                      "AddSeatSelection"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxSubHeading
                                  }>({this.state?.seletedSeats?.map((seat)=>{return seat.SeatId}).join(", ")})</Typography>
                               : <Typography
                                  align="center"
                                  // className={classes.boxSubHeading}
                                  className={
                                    this.state.selectedUpgrades.indexOf(
                                      "AddSeatSelection"
                                    ) > -1
                                      ? classes.BoxSelected
                                      : classes.boxSubHeading
                                  }
                                >
                                  Free
                                </Typography>}
                              </div>
                            </Grid>
                            <Grid item md={4} sm={4} xs={12}>
                              <div
                                className={
                                  this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage15"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleUpgrades("ExtraBaggage15");
                                }}
                              >
                                <Typography
                                  align="center"
                                  className={this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage15"
                                  ) > -1
                                    ? classes.BoxSelected
                                    : classes.boxHeading}
                                >
                                  Extra Baggage 15 Kgs
                                </Typography>
                                <Typography
                                  align="center"
                                  className={this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage15"
                                  ) > -1
                                    ? classes.BoxSelected
                                    : classes.boxSubHeading}
                                >
                                  ₹ 200
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item md={4} sm={4} xs={12}>
                              <div
                                className={
                                  this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage25"
                                  ) > -1
                                    ? classes.addonBoxSelected
                                    : classes.addonBox
                                }
                                onClick={() => {
                                  this.handleUpgrades("ExtraBaggage25");
                                }}
                              >
                                <Typography
                                  align="center"
                                  className={this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage25"
                                  ) > -1
                                    ? classes.BoxSelected
                                    : classes.boxHeading}
                                >
                                  Extra Baggage 25 Kgs
                                </Typography>
                                <Typography
                                  align="center"
                                  className={this.state.selectedUpgrades.indexOf(
                                    "ExtraBaggage25"
                                  ) > -1
                                    ? classes.BoxSelected
                                    : classes.boxSubHeading}
                                >
                                  ₹ 340
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* oldCode */}
                        {/* <Grid item md={5} xs={12}>
                          <Box px={2} py={1}>
                            <TextField
                              label="Add meal"
                              select
                              fullWidth
                              variant="outlined"
                            >
                              <MenuItem value={10}>Hindu Veg</MenuItem>
                              <MenuItem value={20}>Jain Food</MenuItem>
                              <MenuItem value={30}>Non-Veg Food</MenuItem>
                              <MenuItem value={40}>Vegan</MenuItem>
                            </TextField>
                          </Box>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* New Code Addeed For Meal and wheelchair */}
                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography className={classes.reviewFlightHeading}>
                        Meal
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Formik
                        initialValues={{

                        }}
                        // validationSchema={Schema}
                        onSubmit={(values, actions) => {
                          this.handleSignUp(values);
                        }}
                      >
                        {(formikProps) => {
                          const { values, setFieldValue, isValid, dirty } = formikProps;
                          return (
                            <Form noValidate autoComplete="off">
                              <Grid container spacing={1}>
                                {Object.values(this.state.travellerInfo).map((travellerInfo, index) => {
                                  return (
                                    <Grid item xs={10}>
                                      {travellerInfo?.type !== "INF" ?
                                        <Field
                                          select
                                          component={InputFieldWithFormikWeb}
                                          fullWidth
                                          label={`Meal for Traveller - ${index + 1} ${travellerInfo.first_name} ${travellerInfo.last_name}`}
                                          name="meal"
                                          placeholder="Select The Meal For Passenger"
                                        >
                                          <MenuItem value={10}>Hindu Veg</MenuItem>
                                          <MenuItem value={20}>Jain Food</MenuItem>
                                          <MenuItem value={30}>Non-Veg Food</MenuItem>
                                          <MenuItem value={40}>Vegan</MenuItem>
                                        </Field>
                                        : null}
                                    </Grid>
                                  )

                                })}

                                {/* <Typography>Add Travels Data</Typography> */}
                              </Grid>

                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography className={classes.reviewFlightHeading}>
                        Wheelchair
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.dividerStyle} />
                    </Grid>

                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      {/* <Form noValidate autoComplete="off"> */}
                      <Grid item xs={12} >
                        <Typography className={classes.sectionHeading}>
                          Wheelchair access is available for passengers at all the airports for Rs. 343 per person
                        </Typography>
                      </Grid>
                      <Grid container >
                        {Object.values(this.state.travellerInfo).map((travellerInfo, index) => {
                          return (
                            <Grid item xs={10}>
                              {travellerInfo?.type !== "INF" ?
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked="" name="" />}
                                    label={` Traveller - ${index + 1} ${travellerInfo.first_name} ${travellerInfo.last_name}`}
                                  />
                                </FormGroup>
                                : null}
                            </Grid>
                          )

                        })}

                        {/* <Typography>Add Travels Data</Typography> */}
                      </Grid>

                      {/* </Form> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              xs={11}
            >
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <Grid
                    container
                    className={classes.flightBox}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.reviewFlightHeading}
                      >
                        Fare Summary
                      </Typography>
                    </Grid>
                    <Grid md={12} xs={12} item>
                      <Divider className={classes.dividerStyle} />
                    </Grid>
                    <Grid item xs={12} className={classes.flightBoxDetails}>
                      <Grid container justify={"space-between"} >
                        <Grid item xs={12}>
                          <List style={{ padding: "0px" }}>
                            {this.state.fareSummaryLst.length > 0 && this.state.fareSummaryLst.map((fare, index) => {
                              return <ListItem dense={true} key={index} style={{ padding: "0px", marginBottom: "20px" }}>
                                <ListItemText style={{ margin: "0px" }}
                                  primary={`${fare.name}(static) (${fare.count} X ${this.state.currency} ${fare.basePrice})`}
                                  secondary="Base Fare"
                                  classes={{
                                    primary: classes.flightCharge,
                                    secondary: classes.flightCharge,
                                  }}
                                />
                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                  <Typography className={classes.flightValue}>
                                    {fare &&
                                      `${this.state.currency} ${fare.toalPrice}`}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItem>
                            })}
                            {/* <ListItem dense={true}>
                                  <ListItemText
                                    primary={`Travellers (${noOfTravellers} X ${this.state.entityDetail.ApproximateBasePrice})`}
                                    secondary="Base Fare"
                                    classes={{
                                      primary: classes.flightCharge,
                                      secondary: classes.flightCharge,
                                    }}
                                  />
                                  <ListItemSecondaryAction>
                                    <Typography className={classes.flightValue}>
                                      {this.state.fareSummaryDetail &&
                                        this.state.fareSummaryDetail.toal_base_price}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                </ListItem> */}
                            <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                              <ListItemText style={{ margin: "0px" }}
                                primary="Fee & Surcharges"
                                secondary="Total fee & surcharges"
                                classes={{
                                  primary: classes.flightCharge,
                                  secondary: classes.flightCharge,
                                }}
                              />
                              <ListItemSecondaryAction style={{ right: "0px" }}>
                                <Typography className={classes.flightValue}>
                                  {this.state.fareSummaryDetail &&
                                    `${this.state.currency} ${this.state.fareSummaryDetail.total_tax}`}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                            {this.state.couponDiscount && (
                              <ListItem dense={true} style={{ padding: "0px", marginBottom: "20px" }}>
                                <ListItemText style={{ margin: "0px" }}
                                  primary="Coupon Discount"
                                  classes={{
                                    primary: classes.flightCharge,
                                    secondary: classes.flightCharge,
                                  }}
                                />
                                <ListItemSecondaryAction style={{ right: "0px" }}>
                                  <Typography className={classes.flightValue} style={{ color: "#FF0000" }}>
                                    {`${this.state.currency} ${this.state.couponDiscount?.discount_price || "Not Available"}`}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                            <Divider variant="middle" />
                            <ListItem dense={true} style={{ marginTop: "20px" }}>
                              <ListItemText
                                classes={{
                                  primary: classes.flightTotalAmountText,
                                }}
                                // primary="Total Fare"
                                primary="Total Amount to be Paid"
                              />
                              <ListItemSecondaryAction style={{ right: "0px" }}>
                                <Typography className={classes.flightTotalAmountText}>
                                  {this.state.fareSummaryDetail &&
                                    `${this.state.currency} ${this.state.fareSummaryDetail.total_price}`}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      if (this.state.selectedTravellingType === 'oneway') {
                        this.completeBooking();

                      }
                      else if (this.state.selectedTravellingType === 'twoway') {
                        this.completeBooking();
                      }
                    }}
                    fullWidth
                    variant="contained"
                    color="primary">
                    Continue to Payment
                    {/* Continue */}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End hero unit */}
          <Footer marginTop={0}></Footer>
        </div >
      )
    ) : null;
  }
}

const AddAddonsWithRouter = withRouter(AddAddons);
const AddAddonsAlertBox = withAlertBox(AddAddonsWithRouter);
const AddAddonsLoader = withLoader(AddAddonsAlertBox);
const AddAddonsToast = withToast(AddAddonsLoader);
const AddAddonsWithDialog = withDialog(AddAddonsToast);
const AddAddonsWithFontStyles = withFontStyles(AddAddonsWithDialog);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    flightImage: {
      width: theme.spacing(7),
      height: theme.spacing(6.5),
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      // marginBottom: theme.spacing(4),
      backgroundImage: `url(${flightCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // height: theme.spacing(55),
      [theme.breakpoints.down("xs")]: {
        // height: theme.spacing(85),
      },
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightDetailBox: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    notchedOutline: {
      borderRadius: "9px",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    lineHeadingwraper: {
      textAlign: "center",
      width: "6%",
      display: "inline-block",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    flightName: {
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#1E394E",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
        fontWeight: "600",
      },
    },
    flightCode: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAalign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightInfo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCharge: {
      // fontFamily: "Public Sans",
      // fontSize: "12px",
      // fontWeight: "normal",
      // fontStretch: "normal",
      // fontStyle: "normal",
      // lineHeight: 1.75,
      // letterSpacing: "normal",
      // color: "#183b56",
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightAdditionChange: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightValue: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    addonBox: {
      borderRadius: "6px",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(3),
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "142px",
      color: "#183b56",

    },
    addonBoxSelected: {
      borderRadius: "6px",
      boxShadow: "0 20px 24px 0 rgba(0, 0, 0, 0.08)",
      border: "solid 1px #1565d8",
      backgroundColor: "#1565D8",
      padding: theme.spacing(3),
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "142px",

    },
    boxHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    boxSubHeading: {
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#606C78",
    },
    sectionHeading: {
      fontSize: "20px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      padding: '10px 0px',
      color: '#68819A'
    },
    BoxSelected: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    ImageBox: {
      display: 'inline-block',
      width: '49px',
      height: '36px'
    },
    ImageSelected: {
      display: 'inline-block',
      color: '#ffffff',
      width: '49px',
      height: '36px'
    },
    layOver: {
      padding: '2rem',
      color: '#1565D8'
    },
    HereLink: {
      fontFamily: "Public Sans",
      fontSize: "22px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.2px",
      color: '#1565D8',
      paddingLeft: '0.5rem'
    },
    flightTotalAmountText: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
        fontWeight: "600",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    flightPriceValue: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("md")]: {
        fontSize: "15px",
        fontWeight: "500",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "16px",
        fontWeight: "700",
      },
    },
    flightBox: {
      borderRadius: "5px",
      boxShadow: "6px 6px 24px rgba(0,0,0, 0.1)",
      backgroundColor: "rgba(255,255,255, 1)",
    },
    reviewFlightHeading: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px",
    },
    dividerStyle: {
      border: "0px solid rgba(195, 202, 217, 1)",
      color: "#C3CAD9"
    },
    flightBoxDetails: {
      padding: "20px",
    },
    flightImage: {
      width: "75px",
      height: "75px",
      marginRight: "30px",
      [theme.breakpoints.only("xs")]: {
        width: "60px",
        height: "60px",
        marginRight: "15px",
      },
    },
    flightTotalPrice: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "28px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
      },
    },
    departureSection: {
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    arrivalSection: {
      textAlign: "right",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    flightDate: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "22px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    flightPlaceMode: {
      color: "rgba(30, 57, 78, 1)",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "8px",
      marginTop: "10px",
    },
    flightTime: {
      color: "rgba(145, 163, 182, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      marginBottom: "20px",
    },
    flightAddress: {
      color: "rgba(104, 129, 154, 1)",
      fontSize: "16px",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: "0px",
    },
    layoverText: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "24px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      padding: "20px 0px"
    },
    flightDiffTime: {
      color: "rgba(21, 101, 216, 1)",
      fontSize: "18px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      lineHeight: 0,
      marginTop: "20px",
    },
    flightDiffStop: {
      color: "rgba(96, 108, 120, 1)",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0px",
      [theme.breakpoints.only("xs")]: {
        marginBottom: "20px",
      },
    },
    addAddonsPage: {
      paddingTop: "60px",
      paddingBottom: "100px",
      backgroundColor: '#F6F9FF',
    },
    seatMapButton: {
      display: "flex",
      justifyContent: "center",
      width:"auto",
      paddingTop:"10px",
      "& button":{
        width:"auto",
      },
    },
  })
)(AddAddonsWithFontStyles);
