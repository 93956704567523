//@ts-nocheck
import React from "react";
import AddNewPolicyController, { Props } from "./AddNewPolicyController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  ListItemText,
  Button,
  Hidden,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import InputFieled from "../../../components/src/InputFieldWithFormik.web";
import InputField from "../../../components/src/InputFiled.web";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import moment from "moment";

class AddNewPolicy extends AddNewPolicyController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    const authToken = await StorageProvider.get("adminToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          policyDescription: state?.policyData?.description,
          policyType: state?.policyData?.t_and_c_type,
          policyId: state?.policyData?.id,
        },
        () => {
          console.log(this.state, state, this.props);
          //   this.getAllAcceptedRequest();
        }
      );
    }
  }

  goToSelectedRoute = (routeName, data, id) => {
    const { history } = this.props;
    history.push({ pathname: routeName, state: { ...data, id: id } });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.pendIngRequestBox}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "70px" }}
          >
            <Grid item md={11}>
              <Typography className={classes.pendIngTite}>
                Add New Policy
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <InputField
            label="Title"
            fullWidth
            placeholder="Enter Title"
            value={this.state.policyTitle}
            onChange={(evt) => {
              console.log(evt.target.value);
              this.setState({ policyTitle: evt.target.value });
            }}
          />
        </Grid>
        <Grid item md={12}>
          <InputField
            label="Name"
            fullWidth
            multiline
            rows={8}
            placeholder="Enter details here"
            value={this.state.policyDescription}
            onChange={(evt) => {
              console.log(evt.target.value);
              this.setState({ policyDescription: evt.target.value });
            }}
          />
        </Grid>
        <Grid item md={12}>
          <InputField
            select
            label="Type"
            fullWidth
            value={this.state.policyType}
            onChange={(evt) => {
              console.log(evt.target.value);
              this.setState({ policyType: evt.target.value });
            }}
          >
            <MenuItem value="Customer">Customer</MenuItem>
            <MenuItem value="Agency">Agency</MenuItem>
            <MenuItem value="Subagency">Subagency</MenuItem>
          </InputField>
        </Grid>
        <Grid item md={6}></Grid>

        <Grid item md={3}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => this.props.history.push("/AdminDashboard/policies")}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item md={3}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ borderRadius: "8px", height: "50px" }}
            onClick={() => {
              this.props.history.location.pathname ===
              "/AdminDashboard/editPolicy"
                ? this.createAndUpdateTermsAndConditions("PUT")
                : this.createAndUpdateTermsAndConditions("POST");
            }}
          >
            <Typography className={classes.containedButton}>
              {this.props.history.location.pathname ===
              "/AdminDashboard/editPolicy"
                ? "Save"
                : "Add"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const AddNewPolicyWithRouter = withRouter(AddNewPolicy);
const AddNewPolicyAlertBox = withAlertBox(AddNewPolicyWithRouter);
const AddNewPolicyLoader = withLoader(AddNewPolicyAlertBox);
const AddNewPolicyToast = withToast(AddNewPolicyLoader);
const AddNewPolicyWithDialog = withDialog(AddNewPolicyToast);

export default withStyles((theme) =>
  createStyles({
    pendIngRequestBox: {
      borderRadius: "8px",
      backgroundColor: "#f8f8fa",
    },
    pendIngTite: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    subagencyContainer: {
      borderRadius: "8px",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1, 0),
    },
    formHeadingName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    pendIngStatus: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#fb940e",
      alignSelf: "center",
    },
    primartText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#183b56",
    },
    secondaryText: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    anancyInfo: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    viewSubAgancy: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#1565d8",
      cursor: "pointer",
    },
  })
)(AddNewPolicyWithDialog);
