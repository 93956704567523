//@ts-nocheck
import React from "react";
import AdminLoginController, { Props } from "./AdminLoginController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  DialogContent,
  Button,
  Box,
} from "@material-ui/core";
import { signUPPoster } from "./assets";
// import { arrow, } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { TextInput } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFieldWithFormik.web";

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("This field is required"),
});

class AdminLogin extends AdminLoginController {
  constructor(props: Props) {
    super(props);
  }

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  render() {
    const { classes } = this.props;
    return (
      <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            this.handleAdminLogin(values);
          }}
        >
          {(formikProps) => {
            const { values, setFieldValue, isValid, dirty } = formikProps;
            return (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2} justify="space-between">
                  <Grid
                    item
                    md={6}
                    xs={false}
                    className={classes.dialogImage}
                  />
                  <Grid item md={6} xs={12}>
                    <Box py={8}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h1"
                            align="center"
                            className={classes.welcomeBack}
                          >
                            Admin login
                          </Typography>
                        </Grid>

                        <Grid item xs={10}>
                          <Field
                            component={InputField}
                            fullWidth
                            label="Email Address"
                            name="email"
                            placeholder="Enter Email Address"
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <Field
                            type="password"
                            component={InputField}
                            fullWidth
                            label="Password"
                            name="password"
                            placeholder="Enter Password"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            fullWidth
                          >
                            Sign In
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    );
  }
}

const AdminLoginWithRouter = withRouter(AdminLogin);
const AdminLoginWithToast = withToast(AdminLoginWithRouter);
const AdminLoginWithLoader = withLoader(AdminLoginWithToast);
const AdminLoginWithAlertBox = withAlertBox(AdminLoginWithLoader);

export default withStyles((theme) =>
  createStyles({
    dialogStyle: {
      padding: theme.spacing(0),
      height: theme.spacing(60),
    },
    welcomeBack: {
      fontFamily: "Open Sans",
      fontSize: "32px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
    },
    labelText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    dialogImage: {
      backgroundImage: `url(${signUPPoster})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    buttonText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.38,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: "#1570dd",
      cursor: "pointer",
    },
  })
)(AdminLoginWithAlertBox);
