//@ts-nocheck
import React from "react";
import ReviewFlightController, { Props } from "./ReviewFlightController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  List,
  Button,
  Paper,
  Divider,
  MenuItem,
  Hidden,
  Container,
  Link,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { Redirect, RouterProps, Route } from "react-router-dom";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import InputField from "../../../components/src/InputFiled.web";
import withFontStyles from "./withFontStyles.web";
import Header from "./Header.web";
import Footer from "./Footer.web";
import {
  flighPlaceHolder,
  flightCover,
  alertIcon,
  covidIcon,
  suitcaseIcon,
} from "./assets";
import moment from "moment";
import _ from "underscore";

class FlightDetails extends ReviewFlightController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;
    
    let profileData = await StorageProvider.get("profileData");
    profileData = (profileData && JSON.parse(profileData)) || {};
    if (
      state?.data?.entityType &&
      state?.data?.fromCity &&
      state?.data?.toCity &&
      state?.data?.searchData
    ) {
      this.setState(
        {
          entityType: state?.data?.entityType,
          fromCity: state?.data?.fromCity,
          toCity: state?.data?.toCity,
          searchData: state?.data?.searchData,
          entityDetail: state?.data?.entityDetail,
          loading: false,
          role: state?.data?.role || "Customer",
          selectedTravellingType: state?.data?.selectedTravellingType,
          selectedFlight2: state?.data?.selectedFlight2,
          noOfTravellers: state?.noOfTravellers,
          currency: profileData.currency || "USD",
        },
        () => {
          if (this.state.selectedTravellingType === "oneway") {
            this.getFareSummary(this.state.searchData, 
              this.state.entityDetail?.mergeAirSagmentBookingFlightInfo,
              this.state.entityDetail?.FareBasisCode
            );
            // this.getFlightDetail();
          } else if (this.state.selectedTravellingType === "twoway") {
            this.getFareSummary(this.state.searchData, [
              this.state.entityDetail,
              this.state.selectedFlight2,
            ]);
          }
          // this.getFlightDetail();
          // if (this.state.selectedTravellingType === "twoway") {
          //   this.getReturnFlightDetail();
          // }
          // this.getPoliciesAndInformation();
          // this.getUserCoupons();
        }
      );
    } else {
      this.setState({
        loading: false,
        authToken: null,
      });
    }
  }

  goToSelectedRoute = (routeName, data) => {
    const { history } = this.props;
    history.replace({
      state: { data },
      pathname: routeName,
    });
  };
  timeDiff = function (date1 : any, date2 : any) {
    var a = new Date(date1).getTime(),
        b = new Date(date2).getTime(),
        diff = {
          milliseconds : 0,
          seconds : 0,
          minutes : 0,
          hours : 0
        };

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    const extraMin = diff.minutes % 60
    if(extraMin > 0){
      return Math.floor(diff.hours) + " h" + " " + extraMin + " " + "m"
    } else {
      return Math.floor(diff.hours) + " h"
    }
 }

  render() {
    const { classes } = this.props;
    let redirectRouteName = "/";
    if (this.state.role === "Admin") {
      redirectRouteName = "/AdminLogin";
    } else if (this.state.role === "Agency") {
      redirectRouteName = "/AgencyMarketing";
    }
    const { searchData } = this.state;

    const noOfTravellers =
      (+searchData.adults || 0) +
      (+searchData.children || 0) +
      (+searchData.infants || 0);
    return  <div className={classes.root}>
          <Paper
            className={classes.mainPost}
            style={{
              backgroundImage: `url(${flightCover})`,
              backgroundSize: "cover",
            }}
          >
            {
              <img
                style={{ display: "none" }}
                src={flightCover}
                alt={"aaaaaaaaaaaaaaaa"}
              />
            }
            <div className={classes.overlay} />
            {this.state.role && <Header role={this.state.role} />}
            <Grid container justify="center">
              <Grid item md={8} xs={10}>
                <Grid
                  container
                  spacing={4}
                  justify="center"
                  className={classes.mainPostContent}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.helloAdmin} align="center">
                      Review Flight
                    </Typography>
                  </Grid>
                  {this.props.history?.location?.state.data?.role === "Admin" ||
                  this.props.history?.location?.state?.data?.role ===
                    "Agency" ? (
                    <Grid item xs={12}>
                      <Typography
                        className={classes.progressText}
                        align="center"
                      >
                        {this.props.history?.location?.state.data?.searchData
                          .adults > 0
                          ? this.props.history?.location?.state.data?.searchData
                              .adults == 1
                            ? `${this.props.history?.location?.state.data?.searchData.adults} Adult`
                            : `${this.props.history?.location?.state.data?.searchData.adults} Adults`
                          : null}{" "}
                        {this.props.history?.location?.state.data?.searchData
                          .children > 0
                          ? this.props.history?.location?.state.data?.searchData
                              .children == 1
                            ? `${this.props.history?.location?.state.data?.searchData.children} Child`
                            : `${this.props.history?.location?.state.data?.searchData.children} Children`
                          : null}{" "}
                        {this.props.history?.location?.state.data?.searchData
                          .infants > 0
                          ? this.props.history?.location?.state.data?.searchData
                              .infants == 1
                            ? `${this.props.history?.location?.state.data?.searchData.infants} Infant`
                            : `${this.props.history?.location?.state.data?.searchData.infants} Infants`
                          : null}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item md={8} xs={12}>
                    <LinearProgress
                      classes={{ root: classes.linearProgressBar }}
                      variant="determinate"
                      value={50}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Flight Selection
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Review
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Traveller Details
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          align="justify"
                          className={classes.progressText}
                        >
                          Make Payment
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.fromCity?.city}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          to
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.sourceName}
                        >
                          {this.state?.toCity?.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.fromCity?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          ○─○
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography
                          align="center"
                          className={classes.airportName}
                        >
                          {this.state?.toCity?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justify="center" spacing={6}>
            <Grid item md={7} xs={10}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid container spacing={2} className={classes.flightBox}>
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        className={classes.groteskBold32}
                      >
                        {this.state.selectedTravellingType === "twoway"
                          ? "Depature Flight Information"
                          : "Flight Information"}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Divider variant="inset" />
                    </Grid>
                    {this.state.oneWayFlightList.length > 0 && this.state.oneWayFlightList.map((flight,index)=>{
                      return  <>
                      <Grid key={flight?.FlightNumber} item xs={12}>
                      <Grid container spacing={6}>
                        <Grid md={4} xs={12} item>
                          <ListItem style={{ padding: 0 }}>
                            <ListItemAvatar>
                              <img
                                className={classes.flightImage}
                                src={
                                  flight?.Flight_Image 
                                }
                                style={{ marginRight: "10px" }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              classes={{
                                primary: classes.groteskBold24,
                                secondary: classes.openSans18,
                              }}
                              primary={
                                flight?.Flight_Name || "NA"
                              }
                              secondary={`${
                                flight?.Flight_carrier || "-"
                              } | ${flight?.Flight_Id || "-"}`}
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={12} md={8} item>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="left"
                              >
                                {(flight?.Depature_DateTime &&
                                  moment(
                                    flight?.Depature_DateTime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="left"
                              >
                                {" "}
                                Departure{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="left"
                              >
                                {(flight?.Depature_DateTime &&
                                  moment(
                                    (
                                      flight?.Depature_DateTime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <div className={classes.linewraper}>
                                <Typography
                                  color="primary"
                                  className={classes.groteskBold18}
                                  style={{
                                    lineHeight: 0,
                                  }}
                                >
                                  {this.timeDiff(flight?.Depature_DateTime,flight?.Arrival_DateTime) || "NA"}
                                </Typography>
                                <ul className={classes.container}>
                                  <li className={classes.linkconnector} />
                                  <li className={classes.linkconnector} />
                                </ul>
                                
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                className={[
                                  classes.openSans20,
                                  classes.gutterBottom2,
                                ]}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                {(flight?.Arrival_DateTime &&
                                  moment(
                                    flight?.Arrival_DateTime
                                  ).format("ddd, DD MMM")) ||
                                  "NA"}
                              </Typography>
                              <Typography
                                className={classes.groteskBold18}
                                color="textPrimary"
                                align="right"
                              >
                                {" "}
                                Arrival{" "}
                              </Typography>
                              <Typography
                                className={classes.openSans16}
                                color="textSecondary"
                                align="right"
                              >
                                {(flight?.Arrival_DateTime &&
                                  moment(
                                    (
                                      flight?.Arrival_DateTime
                                    )
                                  ).format("hh:mm A")) ||
                                  "NA"}
                              </Typography>
                            </Grid>
                            <Grid container justify="space-between">
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.Boarding_Terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  {flight?.Depature_City ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterTop2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.Arriving_Terminal ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={[
                                    classes.openSans16,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {flight?.Arrival_City ||
                                    "NA"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container justify="space-between">
                              <Grid xs={12} item>
                                <Grid
                                  container
                                  alignItems="center"
                                  justify="space-between"
                                >
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Baggage"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.type || "NA"
                                            
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Check-In"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[0]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <ListItem
                                      style={{ padding: 0 }}
                                      alignItems="center"
                                    >
                                      <ListItemText
                                        primary="Cabin"
                                        secondary={
                                          this.state?.AirPriceInfo[0]?.baggage_details[0]?.BagDetails[1]?.BaggageRestriction?.TextInfo?.Text || "NA"
                                        }
                                        classes={{
                                          primary: classes.groteskBold18,
                                          secondary: classes.openSans16,
                                        }}
                                      />
                                    </ListItem>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                      {index < (this.state.oneWayFlightList.length -1) && <Grid container justify="flex-start" alignContent="center" alignItems="center" item xs={12}><Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid><Grid item md={6} xs={6}>
                      <Paper style={{backgroundColor : '#1565d8',padding : '10px',width : '100%'}}>
                          <Typography align="center" style={{color : '#fff'}}>Change of plane {this.state.entityDetail?.stops[index].stopDuration} in  {this.state.entityDetail?.stops[index].stopName}</Typography>           
                      </Paper>
                    </Grid>
                    <Grid item md={3} xs={3}>
                      <Divider variant="fullWidth" />
                    </Grid></Grid>} 
                 </> 
                    })}
                   </Grid>
                </Grid>
                {this.state.selectedTravellingType === "twoway" ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.flightBox}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          className={classes.groteskBold32}
                        >
                          Return Flight Information
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Divider variant="inset" />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={6}>
                          <Grid md={4} xs={12} item>
                            <ListItem style={{ padding: 0 }}>
                              <ListItemAvatar>
                                <img
                                  className={classes.flightImage}
                                  src={
                                    this.state.selectedFlight2?.Flight_Image 
                                  }
                                  style={{ marginRight: "10px" }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                classes={{
                                  primary: classes.groteskBold24,
                                  secondary: classes.openSans18,
                                }}
                                primary={
                                  this.state.selectedFlight2?.flight_Name ||
                                  "NA"
                                }
                                secondary={`${
                                  this.state.selectedFlight2?.flight_Carrier ||
                                  "-"
                                } | ${
                                  this.state.selectedFlight2?.flight_Id || "-"
                                }`}
                              />
                            </ListItem>
                          </Grid>
                          <Grid xs={12} md={8} item>
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={3}>
                                <Typography
                                  className={[
                                    classes.openSans20,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {(this.state.selectedFlight2?.Depature_Date &&
                                    moment(
                                      this.state.selectedFlight2?.Depature_Date
                                    ).format("ddd, DD MMM")) ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={classes.groteskBold18}
                                  color="textPrimary"
                                  align="left"
                                >
                                  {" "}
                                  Departure{" "}
                                </Typography>
                                <Typography
                                  className={classes.openSans16}
                                  color="textSecondary"
                                  align="left"
                                >
                                  {(this.state.selectedFlight2
                                    ?.Depature_DateTime &&
                                    moment(
                                      (
                                        this.state.selectedFlight2?.Depature_DateTime
                                      )
                                    ).format("hh:mm A")) ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <div className={classes.linewraper}>
                                  <Typography
                                    color="primary"
                                    className={classes.groteskBold18}
                                    style={{
                                      lineHeight: 0,
                                    }}
                                  >
                                    {this.state.selectedFlight2?.Duration ||
                                      "NA"}
                                  </Typography>
                                  <ul className={classes.container}>
                                    <li className={classes.linkconnector} />
                                    <li className={classes.linkconnector} />
                                  </ul>
                                  <Typography
                                    color="textSecondary"
                                    className={classes.openSans18}
                                    style={{
                                      lineHeight: 0,
                                    }}
                                  >
                                    {this.state.selectedFlight2
                                      ?.Mode_Of_Flight || "NA"}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  className={[
                                    classes.openSans20,
                                    classes.gutterBottom2,
                                  ]}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  {(this.state.selectedFlight2?.Arrival_Date &&
                                    moment(
                                      this.state.selectedFlight2?.Arrival_Date
                                    ).format("ddd, DD MMM")) ||
                                    "NA"}
                                </Typography>
                                <Typography
                                  className={classes.groteskBold18}
                                  color="textPrimary"
                                  align="right"
                                >
                                  {" "}
                                  Arrival{" "}
                                </Typography>
                                <Typography
                                  className={classes.openSans16}
                                  color="textSecondary"
                                  align="right"
                                >
                                  {(this.state.selectedFlight2
                                    ?.Arrival_DateTime &&
                                    moment(
                                      (
                                        this.state.selectedFlight2?.Arrival_DateTime
                                      )
                                    ).format("hh:mm A")) ||
                                    "NA"}
                                </Typography>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid item xs={6}>
                                  <Typography
                                    className={[
                                      classes.openSans16,
                                      classes.gutterTop2,
                                    ]}
                                    color="textPrimary"
                                    align="left"
                                  >
                                    {" "}
                                    {this.state.selectedFlight2
                                      ?.Boarding_Terminal || "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.openSans16,
                                      classes.gutterBottom2,
                                    ]}
                                    color="textPrimary"
                                    align="left"
                                  >
                                    {" "}
                                    {this.state.selectedFlight2
                                      ?.Depature_City || "NA"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    className={[
                                      classes.openSans16,
                                      classes.gutterTop2,
                                    ]}
                                    color="textPrimary"
                                    align="right"
                                  >
                                    {" "}
                                    {this.state.selectedFlight2
                                      ?.Arriving_Terminal || "NA"}
                                  </Typography>
                                  <Typography
                                    className={[
                                      classes.openSans16,
                                      classes.gutterBottom2,
                                    ]}
                                    color="textPrimary"
                                    align="right"
                                  >
                                    {" "}
                                    {this.state.selectedFlight2?.Arrival_City ||
                                      "NA"}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container justify="space-between">
                                <Grid xs={12} item>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <ListItem style={{ padding: 0 }}>
                                        <ListItemText
                                          primary="Baggage"
                                          secondary={
                                            this.state.selectedFlight2
                                              ?.Baggage_Type || "NA"
                                          }
                                          classes={{
                                            primary: classes.groteskBold18,
                                            secondary: classes.openSans16,
                                          }}
                                        />
                                      </ListItem>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <ListItem style={{ padding: 0 }}>
                                        <ListItemText
                                          primary="Check-In"
                                          secondary={
                                            this.state.selectedFlight2
                                              ?.Baggage_Check_In || "NA"
                                          }
                                          classes={{
                                            primary: classes.groteskBold18,
                                            secondary: classes.openSans16,
                                          }}
                                        />
                                      </ListItem>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <ListItem style={{ padding: 0 }}>
                                        <ListItemText
                                          primary="Cabin"
                                          secondary={
                                            this.state.selectedFlight2
                                              ?.Baggage_Cabin || "NA"
                                          }
                                          classes={{
                                            primary: classes.groteskBold18,
                                            secondary: classes.openSans16,
                                          }}
                                        />
                                      </ListItem>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justify="space-around"
                    className={classes.flightDetailBox}
                  >
                    <Grid item xs={12}>
                      {/* <Box px={2}> */}
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        className={classes.groteskBold32}
                      >
                        Flight Policy
                      </Typography>
                      {/* </Box> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="space-around">
                        <Grid
                          item
                          alignItems="center"
                          container
                          xs={12}
                          spacing={4}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item>
                            <ListItem style={{ padding: 0 }}>
                              <ListItemAvatar>
                                <img
                                  className={classes.flightImage}
                                  src={
                                    this.state.entityDetail?.flight_Image 
                                  }
                                  style={{ marginRight: "10px" }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                classes={{
                                  primary: classes.groteskBold24,
                                  secondary: classes.openSans18,
                                }}
                                primary={
                                  this.state.entityDetail?.flight_Name || "NA"
                                }
                                secondary={`${
                                  this.state.entityDetail?.flight_Carrier || "-"
                                } | ${
                                  this.state.entityDetail?.flight_Id || "-"
                                }`}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item>
                            <Typography display="inline">
                           {`To read ${this.state.entityDetail?.flight_Name} Terms and Conditions policy,`} 
                            </Typography>
                            <Link
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.getPoliciesAndInformation(
                                  this.state.entityDetail
                                );
                              }}
                            >
                              { `click here`}
                            </Link>
                          </Grid>
                        </Grid>
                        {this.state.selectedFlight2 &&
                          this.state.selectedTravellingType === "twoway" && (
                            <Grid
                              item
                              alignItems="center"
                              container
                              xs={12}
                              spacing={4}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item>
                                <ListItem style={{ padding: 0 }}>
                                  <ListItemAvatar>
                                    <img
                                      className={classes.flightImage}
                                      src={
                                        this.state.selectedFlight2
                                          ?.Flight_Image
                                      }
                                      style={{ marginRight: "10px" }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    classes={{
                                      primary: classes.groteskBold24,
                                      secondary: classes.openSans18,
                                    }}
                                    primary={
                                      this.state.selectedFlight2?.flight_Name ||
                                      "NA"
                                    }
                                    secondary={`${
                                      this.state.selectedFlight2
                                        ?.flight_Carrier || "-"
                                    } | ${
                                      this.state.selectedFlight2?.flight_Id ||
                                      "-"
                                    }`}
                                  />
                                </ListItem>
                              </Grid>
                              <Grid item>
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.getPoliciesAndInformation(
                                      this.state.selectedFlight2
                                    );
                                  }}
                                >
                                  {`To read ${this.state.selectedFlight2?.flight_Name} Terms and Conditions policy, click here`}
                                </Link>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={10}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    spacing={1}
                    className={classes.flightDetailBox}
                  >
                    <Grid item xs={12}>
                      <Box px={2}>
                        <Typography
                          color="textPrimary"
                          className={classes.groteskBold32}
                        >
                          Fare Summary
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid md={12} xs={12} item>
                      <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify={"space-between"} spacing={2}>
                        <Grid item xs={12}>
                          <List>
                            {this.state.fareSummaryLst.length > 0 &&
                              this.state.fareSummaryLst.map((fare, index) => {
                                return (
                                  <ListItem dense={true} key={index}>
                                    <ListItemText
                                      primary={`${fare.name} (${fare.count} X ${this.state.currency} ${fare.basePrice})`}
                                      secondary="Base Fare"
                                      classes={{
                                        primary: classes.flightCharge,
                                        secondary: classes.flightCharge,
                                      }}
                                    />
                                    <ListItemSecondaryAction>
                                      <Typography
                                        className={classes.flightValue}
                                      >
                                        {fare &&
                                          `${this.state.currency} ${fare.toalPrice || "Not Available"}`}
                                      </Typography>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                            
                            <ListItem dense={true}>
                              <ListItemText
                                primary="Fee & Surcharges"
                                secondary="Total fee & surcharges"
                                classes={{
                                  primary: classes.flightCharge,
                                  secondary: classes.flightCharge,
                                }}
                              />
                              <ListItemSecondaryAction>
                                <Typography className={classes.flightValue}>
                                  {this.state.fareSummaryDetail &&
                                    `${this.state.currency} ${this.state.fareSummaryDetail.total_tax || "Not Available"}`}
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItem>
                            
                            <Divider variant="middle" />
                            <ListItem dense={true}>
                              <ListItemText
                                classes={{
                                  primary: classes.flightValue,
                                }}
                                primary={
                                  this.props.history?.location?.state.data
                                    ?.role === "Customer"
                                    ? "Total Fare"
                                    : "Total Amount Without Markup"
                                }
                              />
                              <ListItemSecondaryAction>
                                {this.props.history?.location?.state.data
                                  ?.role === "Customer" ? (
                                  <Typography className={classes.flightValue}>
                                    {this.state.fareSummaryDetail &&
                                      `${
                                        this.state.currency
                                      } ${this.state.fareSummaryDetail.total_price?.toFixed(
                                        2
                                      ) || "Not Available"}`}
                                  </Typography>
                                ) : (
                                  <Typography className={classes.flightValue}>
                                    {this.state.fareSummaryDetail &&
                                      `${this.state.currency} ${this.state.fareSummaryDetail.total_price || "Not Available"}`}
                                  </Typography>
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    disabled={!this.state.fareSummaryDetail?.total_price}
                    onClick={() => {
                      this.goToSelectedRoute("/reviewBooking", {
                        searchData: { ...this.state.searchData },
                        entityDetail: { ...this.state.entityDetail },
                        fromCity: this.state.fromCity,
                        toCity: this.state.toCity,
                        entityType: this.state.entityType,
                        role: this.state.role,
                        selectedTravellingType:
                          this.state?.selectedTravellingType,
                        selectedFlight2: this.state?.selectedFlight2,
                      });
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Book Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer></Footer>
        </div>
      
  }
}

const FlightDetailsWithRouter = withRouter(FlightDetails);
const FlightDetailsAlertBox = withAlertBox(FlightDetailsWithRouter);
const FlightDetailsLoader = withLoader(FlightDetailsAlertBox);
const FlightDetailsToast = withToast(FlightDetailsLoader);
const FlightDetailsWithDialog = withDialog(FlightDetailsToast);
const FlightDetailsWithFontStyles = withFontStyles(FlightDetailsWithDialog);

export default withStyles((theme) =>
  createStyles({
    root: {
      overflowX: "hidden",
      height: "100vh",
    },
    gridItem1: {
      order: 1,
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },
    },
    gridItem2: {
      order: 2,
      [theme.breakpoints.down("xs")]: {
        order: 6,
      },
    },
    gridItem3: {
      order: 3,
      [theme.breakpoints.down("xs")]: {
        order: 2,
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(-40),
      },
    },
    gridItem4: {
      order: 4,
      [theme.breakpoints.down("xs")]: {
        order: 3,
      },
    },
    gridItem5: {
      order: 5,
      [theme.breakpoints.up("sm")]: {
        order: 5,
      },
    },
    gridItem6: {
      order: 5,
      [theme.breakpoints.up("sm")]: {
        order: 6,
      },
    },
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: `url(${flightCover})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: theme.spacing(55),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(85),
      },
    },
    flightImage: {
      width: theme.spacing(8),
      height: theme.spacing(7.5),
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(7),
        // paddingRight: 0,
      },
    },
    helloAdmin: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    link: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    linearProgressBar: {
      height: "10px",
    },
    progressText: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    sourceName: {
      fontFamily: "Public Sans",
      fontSize: "36px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    airportName: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    flightDetailBox: {
      padding: theme.spacing(2),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
    },
    flightBox: {
      padding: theme.spacing(4),
      borderRadius: "8px",
      border: "solid 1px #e5eaf4",
      backgroundColor: "#ffffff",
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
      // [theme.breakpoints.up('md')]: {
      //   height: '446px',
      // }
    },
    notchedOutline: {
      borderRadius: "9px",
    },
    container: {
      borderTop: "1px solid #1565d8",
      display: "flex",
      listStyle: "none",
      padding: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    linewraper: {
      textAlign: "center",
      width: "100%",
    },
    lineHeadingwraper: {
      textAlign: "center",
      width: "6%",
      display: "inline-block",
    },
    linkconnector: {
      position: "relative",
      marginTop: "2px",
      "&::before": {
        content: "''",
        width: "10px",
        height: "10px",
        background: "#fff",
        position: "absolute",
        borderRadius: "10px",
        top: "-8px",
        left: "50%",
        transform: "translatex(-50%)",
        border: "1px solid #1565d8",
      },
    },
    date: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    checkIn: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.1,
      letterSpacing: "normal",
    },
    time: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
    },
    lineHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#1565d8",
    },
    lineSubHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      textAlign: "center",
    },
    flightName: {
      fontFamily: "Public Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCode: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    flightInfo: {
      fontFamily: "Public Sans",
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightCharge: {
      fontFamily: "Public Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightAdditionChange: {
      fontFamily: "Public Sans",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    flightValue: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    couponCode: {
      backgroundColor: "#ddf6e3",
      borderRadius: "4px",
      border: "dashed 1px #5a7184",
      padding: "16px",
    },
    noCouponsAvailable: {
      backgroundColor: "#f8f8fa",
      borderRadius: "4px",
      padding: "16px",
    },
    selectedCouponCode: {
      backgroundColor: "#ddf6e3",
      borderRadius: "4px",
      border: "solid 2px #1565d8",
      padding: "16px",
      boxShadow: "0 15px 35px 5px rgba(0, 0, 0, 0.05)",
    },
    boxHeading: {
      fontFamily: "Public Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#183b56",
    },
    boxSubHeading: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: "normal",
      color: "#5a7184",
    },
    flightBoxDivider: {
      width: "611px",
      height: "1px",
      opacity: 0.15,
      backgroundColor: "#979797",
    },
  })
)(FlightDetailsWithFontStyles);
